import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import {
  ListContainer,
  ListItem,
  MainContainer,
  Navbar,
  Title,
  Divider
} from "./styles";

import chevronLeftIcon from "../../assets/faqs-icons/chevron-left.svg";
import chevronRightIcon from "../../assets/faqs-icons/chevron-right.svg";

const FaqSubTopic = () => {
  const { topic, subTopic } = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation("faqs");

  const subTopicContent = t(`faqs:${topic}.${subTopic}`, {
    returnObjects: true
  });

  if (!subTopicContent) return null;

  return (
    <>
      <Navbar>
        <div className="back-button" onClick={() => navigate(-1)}>
          <img src={chevronLeftIcon} alt="chevron left" />
          <p>{t("back")}</p>
        </div>
      </Navbar>
      <MainContainer>
        <Title>{t(`faqs:subTopics.${topic}.${subTopic}`)}</Title>
        <ListContainer>
          {Object.keys(subTopicContent).map((stc, index) => (
            <ListItem
              key={stc}
              minHeight={60}
              onClick={() =>
                navigate(`/faqs/${topic}/${subTopic}/${index + 1}`)
              }
            >
              <div className="item-content">
                <p>{subTopicContent[stc].question}</p>
                <img src={chevronRightIcon} alt="chevron right" />
                {index < Object.keys(subTopicContent).length - 1 && <Divider />}
              </div>
            </ListItem>
          ))}
        </ListContainer>
      </MainContainer>
    </>
  );
};

export default FaqSubTopic;
