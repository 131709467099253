import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";

import { getBalanceAndPin } from "../../features/balance-and-pin/balanceAndPinSlice";
import {
  MyEvent,
  Footer,
  GreenManifesto,
  Hero,
  MainContainer,
  NavBar,
  MyBluewater,
  OrderOfPlay
} from "./styles/styles";

import Spinner from "../../components/Spinner";
import ExpandingContainer from "./ExpandingContainer";

import { MasterCardDemoFonts } from "../../pages/mastercard-demo/styles/MasterCardDemoStyles";

import bluewaterLogo from "./img/bluewater-logo.webp";
import chevronRight from "./img/chevron-right.svg";
import digiseqLogo from "./img/digiseq-logo.png";
import youmoniLogo from "./img/youmoni-logo.png";
import bluewaterHero from "./img/bluewater-hero.webp";
import refillStationMapImg from "./img/refill-stations-map.webp";
import eventStatsImg from "./img/event-stats.webp";
import mcLogo from "./img/mastercard.svg";
import bwLogoWhite from "./img/bw-logo-white.png";
import ITflag from "./img/IT.webp";
import TNflag from "./img/TN.webp";
import BRflag from "./img/BR.webp";
import PLflag from "./img/PL.webp";
import FRflag from "./img/FR.webp";
import ESflag from "./img/ES.webp";
import ARflag from "./img/AR.webp";
import NoFlag from "./img/NONE.webp";

import playersData from "./data.json";

const FLAGS = {
  IT: ITflag,
  TN: TNflag,
  BR: BRflag,
  PL: PLflag,
  FR: FRflag,
  ES: ESflag,
  AR: ARflag,
  RU: NoFlag
};

const BluewaterPromoReady = () => {
  const [searchParams] = useSearchParams();
  const serialNumber = searchParams.get("serialNumber");
  const dispatch = useDispatch();

  const [readMore, setReadMore] = useState(false);

  const { balanceAndPin, isLoading } = useSelector(
    (state) => state.balanceAndPin
  );

  useEffect(() => {
    if (!balanceAndPin && serialNumber) {
      dispatch(
        getBalanceAndPin({ serial: serialNumber, query: "?provider=tokenised" })
      );
    }
  }, [balanceAndPin, serialNumber, dispatch]);

  const formattedBalance = (balanceDetails) => {
    const { availableBalance, currency } = balanceDetails;
    let iconPrefixed = true;
    let icon = "";

    switch (currency) {
      case "GBP":
        icon = "£";
        break;
      case "EUR":
        icon = "€";
        break;
      case "USD":
        icon = "$";
        break;
      default:
        icon = "";
    }

    if (iconPrefixed) {
      return icon + availableBalance;
    } else {
      return availableBalance + icon;
    }
  };

  if (isLoading) return <Spinner />;

  return (
    <>
      <MasterCardDemoFonts />
      <MainContainer>
        <NavBar>
          <img src={bluewaterLogo} alt="bluewater logo" />

          <div>
            Balance:{" "}
            {balanceAndPin?.balanceDetails?.availableBalance
              ? formattedBalance(balanceAndPin?.balanceDetails)
              : "N/A"}
          </div>
        </NavBar>
        <Hero>
          <img src={bluewaterHero} alt="wearable" />
        </Hero>
        <GreenManifesto>
          <h2>Hydration that doesn't cost the Earth.</h2>
          <p>{`Big changes start with a small step in the right direction.`}</p>
          {readMore && (
            <p>
              {`Please use your Bluewater SMART bottle to enjoy complimentary purified and chilled drinking water refills throughout the event, courtesy of Mastercard.`}
            </p>
          )}
          <p
            className="collapsable"
            onClick={() => setReadMore(!readMore)}
          >{`Read ${readMore ? "less" : "more"}`}</p>
        </GreenManifesto>
        <ExpandingContainer title="My Event">
          <MyEvent>
            <div className="location-container">
              <div className="court">
                <p>COURT PHILIPPE-CHATRIER</p>
                <p>TRIBUNE HENRI COCHET</p>
              </div>
              <div className="seat">
                <p>Escalier 2</p>
                <p>Rang 5</p>
                <p>Place 15</p>
              </div>
            </div>
            <div className="banners">
              <div className="refill">
                <img src={refillStationMapImg} alt="stationsMap" />
                <p>Refill stations map</p>
              </div>
              <div className="stats">
                <h2 className="stats-value">1535L</h2>
                <img src={eventStatsImg} alt="stationsMap" />
                <p>Refill stations map</p>
              </div>
            </div>
          </MyEvent>
        </ExpandingContainer>
        <ExpandingContainer title="My Bluewater">
          <MyBluewater>
            <p>Here you can find information about your Bluewater bottle</p>
            <div className="link">
              <p>Daily consumption</p>
              <div>
                <span>3.6L</span>
                <img src={chevronRight} alt="chevron right" />
              </div>
            </div>
            <div className="link">
              <p>Lifetime amount</p>
              <div>
                <span>413L</span>
                <img src={chevronRight} alt="chevron right" />
              </div>
            </div>
            <div className="link">
              <p>CO2 saving</p>
              <div>
                <span>8.12kg</span>
                <img src={chevronRight} alt="chevron right" />
              </div>
            </div>
            <div className="stats">
              <h3>Community statistics</h3>
              <div className="stats-container">
                <div>
                  <p>Water consumption</p>
                  <span>55712L</span>
                </div>
                <div>
                  <p>Carbon saving</p>
                  <span>65678kg</span>
                </div>
              </div>
            </div>

            <div className="link">
              <p>
                Payment information <img src={mcLogo} alt="mastercard" />
              </p>
              <img src={chevronRight} alt="chevron right" />
            </div>
            <div className="link">
              <p>Lifetime warranty registration </p>
              <img src={chevronRight} alt="chevron right" />
            </div>
            <div className="link">
              <p>
                About <img src={bwLogoWhite} alt="bluewater logo" />
              </p>
              <img src={chevronRight} alt="chevron right" />
            </div>
          </MyBluewater>
        </ExpandingContainer>
        <ExpandingContainer title={"Order of Play"}>
          <OrderOfPlay>
            <p className="court">Court Philippe Chatrier</p>
            {playersData.games.map((game, i) => (
              <div className="game" key={i}>
                <div className="col">
                  <p className="category">{game.category}</p>
                  <div>
                    <img src={FLAGS[game.player1.country]} alt="flag" />
                    <p>{game.player1.name}</p>
                  </div>
                </div>
                <div className="col">
                  <div></div>
                  <p>v.</p>
                </div>
                <div className="col">
                  <p>{game.day}</p>
                  <div>
                    <img src={FLAGS[game.player2.country]} alt="flag" />
                    <p>{game.player2.name}</p>
                  </div>
                </div>
              </div>
            ))}
          </OrderOfPlay>
        </ExpandingContainer>
        <Footer>
          <img
            src={bwLogoWhite}
            alt="bluewater"
            onClick={() =>
              window.open("https://www.bluewatergroup.com/", "_blank")
            }
          />
          <p>2023. All rights reserved.</p>
          <p>Powered by</p>
          <div className="companies">
            <img
              src={digiseqLogo}
              alt="digiseq-logo"
              onClick={() => window.open("https://www.digiseq.co.uk", "_blank")}
            />
            <img
              src={youmoniLogo}
              alt="youmoni-logo"
              onClick={() => window.open("https://youmoni.com/", "_blank")}
            />
          </div>
        </Footer>
      </MainContainer>
    </>
  );
};

export default BluewaterPromoReady;
