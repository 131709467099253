import React, { useState } from "react";
import { SectionContainer, SectionContent } from "./styles/Pay360.js";

import chevronDown from "./img/chevron-down-white.svg";
import chevronUp from "./img/chevron-up-white.svg";

const ExpandingContainer = ({ title, children, secondary }) => {
  const [expanded, setExpanded] = useState(true);
  return (
    <SectionContainer secondary={secondary}>
      <div className="title-bar" onClick={() => setExpanded(!expanded)}>
        <h2>{title}</h2>
        <img
          src={expanded ? chevronUp : chevronDown}
          alt={`chevron ${expanded ? "up" : "down"}`}
        />
      </div>

      {expanded && <SectionContent>{children}</SectionContent>}
    </SectionContainer>
  );
};

export default ExpandingContainer;
