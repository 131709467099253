import React, { useEffect, Suspense } from "react";
import { useLocation, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { StyledMain } from "../../styled/StyledMain";
import { MainContainer } from "../../styled/StyledMainContainer";

import {
  getDeviceByModel,
  getDevicesByBrand,
  resetDevice
} from "../../features/devices/deviceSlice";
import Wearable from "../products/components/Wearable";

import {
  MoreFromBrandSection,
  ProductContainer,
  ProductImageContainer,
  ProductDescription,
  VariantContainer,
  VariantsContainer,
  VariantsSideContainer,
  MoreProductsContainer,
  ProductPageContainer,
  VariantsCounter
} from "./components/styled/Product";

import { Image } from "../products/components/styled/Products";
import Spinner from "../../components/Spinner";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import { GoButton } from "../brands/components/styled/FeaturedBrand";

const Product = () => {
  const params = useParams();
  const dispatch = useDispatch();
  const { device, devicesByBrand } = useSelector((state) => state.devices);

  useEffect(() => {
    if (device?.brand && devicesByBrand.length === 0) {
      dispatch(getDevicesByBrand(device.brand));
    }
  }, [devicesByBrand, device?.brand, dispatch]);

  useEffect(() => {
    if (!device && params.brand && params.model && params.version) {
      const { brand, model, version } = params;
      dispatch(
        getDeviceByModel({
          brand,
          model,
          version
        })
      );
    }
  }, [dispatch, device, params]);

  useEffect(() => {
    return () => {
      dispatch(resetDevice());
    };
  }, [dispatch]);

  if (device)
    return (
      <React.Suspense fallback={<Spinner />}>
        <Header />
        <StyledMain>
          <MainContainer>
            <ProductPageContainer>
              <ProductContainer>
                <ProductImageContainer>
                  <Image
                    src={device.defaultImage}
                    alt="product"
                    loading="lazy"
                  />
                </ProductImageContainer>
                <VariantsSideContainer>
                  <h2>
                    {` ${device.brandDisplayName} ${device.modelDisplayName}`}
                  </h2>

                  <VariantsContainer>
                    {device.variants &&
                      device.variants
                        .filter((e) => !e.excludedFromWebsite)
                        .map((e, i) => (
                          <VariantContainer key={i}>
                            <Image
                              src={e.image}
                              alt="product variant"
                              loading="lazy"
                              onClick={() =>
                                window.open(
                                  e.storeLink ? e.storeLink : device.storeLink
                                )
                              }
                            />
                          </VariantContainer>
                        ))}
                  </VariantsContainer>

                  {device.variants && (
                    <VariantsCounter>{`${
                      device.variants.filter((e) => !e.excludedFromWebsite)
                        .length
                    } variations`}</VariantsCounter>
                  )}

                  <ProductDescription>
                    {device.description ||
                      "Payment accessories and payment jewelry are tied to your bank card and allow you to make quick and secure purchases wherever you see the contactless icon. Explore the store for a large selection of wearables"}
                  </ProductDescription>
                  {device.storeLink && (
                    <GoButton
                      isSmallScreen
                      onClick={() => window.open(device.storeLink)}
                    >
                      Go to product
                    </GoButton>
                  )}
                </VariantsSideContainer>
              </ProductContainer>

              {devicesByBrand && (
                <MoreFromBrandSection>
                  <h2>{`More from ${device.brandDisplayName}`}</h2>
                  <MoreProductsContainer>
                    {devicesByBrand.map((e, i) => (
                      <div key={i}>
                        <Suspense fallback={<>loading...</>}>
                          <Wearable device={e} />
                        </Suspense>
                      </div>
                    ))}
                  </MoreProductsContainer>
                </MoreFromBrandSection>
              )}
            </ProductPageContainer>
          </MainContainer>
        </StyledMain>
        <Footer />
      </React.Suspense>
    );
  return null;
};

export default Product;
