import React from "react";

import { StyledMain } from "../../styled/StyledMain";
import { MainContainer } from "../../styled/StyledMainContainer";

import Signin from "./components/Signin";
import { StyledContainer } from "./styled/Container";

const Account = () => {
  return (
    <StyledMain grayBg>
      <MainContainer>
        <StyledContainer>
          <h2>Welcome back</h2>
          <Signin />
        </StyledContainer>
      </MainContainer>
    </StyledMain>
  );
};

export default Account;
