import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { getDevices } from "../../../features/devices/deviceSlice";
import { ProductsContainer } from "./styled/Products";
import Spinner from "../../../components/Spinner";
import Wearable from "./Wearable";
import { useLocation } from "react-router-dom";

const FeaturedProducts = ({ brand }) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const [availableDevices, setAvailableDevices] = useState([]);
  const { devices, filteredDevices } = useSelector((state) => state.devices);
  const { entities } = useSelector((state) => state.entities);

  useEffect(() => {
    const entitiesIdsFilter = entities.map((ent) =>
      ent.id === "rosan_diamond"
        ? "rosan diamond"
        : ent.id === "stiss"
        ? "stiss augmented watches"
        : ent.id
    );
    if (entities.length > 0) {
      const devicesFiltereByEntities = filteredDevices.filter((e) =>
        entitiesIdsFilter.includes(e.brand)
      );
      setAvailableDevices(devicesFiltereByEntities);
    } else {
      setAvailableDevices(filteredDevices);
    }
  }, [entities, filteredDevices]);

  useEffect(() => {
    if (devices.length === 0) {
      dispatch(getDevices());
    }
  }, [dispatch, devices]);

  const isMobile = useMediaQuery({ query: "(max-width: 640px)" });

  return (
    <ProductsContainer featured data-testid="featured-products">
      <React.Suspense fallback={<Spinner />}>
        {availableDevices?.length > 0 &&
          availableDevices
            .filter((e) =>
              location.pathname.includes("products/") ||
              location.pathname.includes("brands/")
                ? e.brand === brand
                : true
            )
            .map(
              // TODO filter by featured bool when that is available
              (device, i) => {
                return (
                  i < (isMobile ? 1 : 2) &&
                  device?.featuredOnWebsite && (
                    <Wearable featured={true} device={device} key={i} />
                  )
                );
              }
            )}
      </React.Suspense>
    </ProductsContainer>
  );
};

export default FeaturedProducts;
