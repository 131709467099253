import { css } from "styled-components";

const H2 = css`
  font-family: "Oswald";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  color: #ffffff;
`;

const B1 = css`
  font-family: "Oswald";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #ffffff;
`;

const flexCenter = css`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const mixins = { H2, B1, flexCenter };

export default mixins;
