import React, { useEffect, useRef, useState } from "react";
import {
  AddTag,
  InputTag,
  Tag,
  TagsContainer
} from "../styled/CampaignDevices";

import deleteTagIcon from "../img/delete-tag.svg";
import { Button } from "./styled/SharedStyles";
import { useClickOutside } from "../../../hooks/outsideClick";

const Tags = ({ tags, onUpdatedTags }) => {
  const [tagsArr, setTagsArr] = useState([]);
  const [add, setAdd] = useState(false);
  const [newTag, setNewTag] = useState("");

  const wrapperRef = useRef();
  useClickOutside(wrapperRef, () => {
    if (add) {
      handleAdd();
    }
  });

  const handleAdd = () => {
    if (newTag) {
      setTagsArr((current) => [...current, newTag]);
      setNewTag("");
    }
    setAdd(false);
  };

  const handleDelete = (deleted) => {
    setTagsArr((current) => [...current].filter((e) => e !== deleted));
  };

  useEffect(() => {
    onUpdatedTags(tagsArr);
  }, [onUpdatedTags, tagsArr]);

  useEffect(() => {
    if (tags?.length > 0) setTagsArr(tags);
  }, [tags]);

  return (
    <>
      <TagsContainer>
        {tagsArr?.map((e, i) => (
          <Tag key={i}>
            <img
              src={deleteTagIcon}
              alt="delete"
              onClick={() => handleDelete(e)}
            />
            {e}
          </Tag>
        ))}

        {add && (
          <InputTag
            ref={wrapperRef}
            autoFocus
            size={Math.min(Math.max(newTag.length, 1), 20)}
            value={newTag}
            onChange={(ev) => setNewTag(ev.target.value)}
          />
        )}
        {!add && <AddTag onClick={() => setAdd(true)}>Add Tag</AddTag>}
      </TagsContainer>
      {add && (
        <Button disabled={!newTag} onClick={() => handleAdd()}>
          Save Tag
        </Button>
      )}
    </>
  );
};

export default Tags;
