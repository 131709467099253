import React from "react";
import { useLocation } from "react-router-dom";

import Logo from "./Logo";
import {
  LinkButton,
  LinksContainer,
  StyledHeader,
  StyledNav
} from "./styled/StyledHeader";

const Header = () => {
  const location = useLocation();
  const isCurrent = (pathname) => location.pathname.includes(pathname);

  return (
    <StyledHeader>
      <StyledNav>
        <Logo />
        <LinksContainer>
          <LinkButton active={isCurrent("/products").toString()} to="/products">
            PRODUCTS
          </LinkButton>
        </LinksContainer>
      </StyledNav>
    </StyledHeader>
  );
};

export default Header;
