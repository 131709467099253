import styled from "styled-components";

export const StyledFooter = styled.footer`
  grid-area: footer;
  padding: 40px;
  background-color: #f2f2f5;
  display: flex;
  flex-direction: column;
  * {
    color: #0000ac;
    font-weight: 400;
  }
`;

export const Widget = styled.div`
  > :first-child {
    margin-bottom: 10px;
    font-weight: 600;
  }
`;

export const LogoWidget = styled.div`
  @media (min-width: 768px) {
    grid-column: span 2;
  }
`;

export const Container = styled.div`
  display: grid;

  grid-template-columns: 1fr;
  row-gap: 10px;

  @media (min-width: 768px) {
    grid-template-columns: repeat(4, 1fr);
    row-gap: 0;
  }
  @media (min-width: 1440px) {
    grid-template-columns: repeat(5, 1fr);
  }
`;

export const FooterBottom = styled.div`
  display: flex;
  justify-content: space-between;
  border-top: 2px solid #0000ac;
  padding-top: 10px;
  margin-top: 110px;
`;

export const TermsSection = styled.div`
  display: flex;

  > :first-child {
    margin-right: 10px;
  }
`;

export const Contact = styled.h4`
  font-weight: 600;
  margin-top: 18px;

  @media (min-width: 1440px) {
    margin-top: 0;
  }
`;
