import styled from "styled-components";
import dotsBg from "../img/dots-bg.png";
import buttonBg from "../img/button-bg.svg";
import mixins from "./mixins";

export const MainContainer = styled.section`
  display: flex;
  flex-direction: column;
  max-width: 480px;
  width: 100%;
  min-height: 100vh;
  margin: auto;
  background-color: black;
  color: white;

  &&& div {
    -moz-user-select: none; /* firefox */
    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* IE*/
    user-select: none; /* Standard syntax */
  }

  h1 {
    background-color: white;
    color: black;
    padding: 8px 16px;
    font-family: "Oswald";
    font-style: normal;
    font-weight: 600;
    font-size: 40px;
    line-height: 48px;
  }
`;

export const Header = styled.div`
  position: relative;
  padding: 12px 16px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid #eb5c2a;

  ${mixins.B1}

  .balance-txt {
    color: #939393;
  }

  .logo {
    justify-self: start;
    cursor: pointer;
    img {
      max-width: 113px;
    }
  }

  .more {
    padding: 0 8px;
    background-color: #f26522;
    cursor: pointer;
    height: 24px;
  }

  .balance {
    color: #939393;
  }
`;

export const Dropdown = styled.div`
  position: absolute;
  top: 70px;
  left: 0;
  height: 100vh;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.5);
`;

export const MoreDetails = styled.div`
  background-color: #242424;
  padding: 24px;

  p {
    font-family: "Oswald";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.15px;
    margin-bottom: 16px;
  }

  .create-account-button {
    background-color: #fe7e45;
    display: inline-block;
    padding: 4px 8px;
    cursor: pointer;
  }
  img {
    margin-right: 8px;
    cursor: pointer;
  }
`;

export const Button = styled.button`
  margin: 16px 0;
  width: 100%;
  background-image: url(${buttonBg});
  height: 56px;
  background-color: #eb5c2a;
  ${mixins.flexCenter}
  ${mixins.H2}
  cursor: pointer;
  border: none;
  :active {
    background-color: #f0896a;
  }
  :disabled {
    opacity: 20%;
  }
`;

export const Footer = styled.div`
  background-color: black;
  height: 112px;
  ${mixins.flexCenter}
  ${mixins.B1}

  a {
    margin-left: 4px;
    color: white;
    font-size: 18px;
    text-decoration: underline;
    cursor: pointer;
  }
`;

export const VotingSection = styled.div`
  padding: 24px 16px;
  background-color: #242424;
  h2 {
    ${mixins.H2}
    margin-bottom:8px;
  }
`;

export const QuestionsContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-row-gap: 8px;
  margin-bottom: 16px;

  p {
    ${mixins.B1}
  }
`;

export const FightDescription = styled.div`
  padding: 0 16px 16px;
  p {
    ${mixins.B1}
  }
`;

export const Question = styled.div`
  background-image: url("${(props) => (props.disabled ? dotsBg : "none")}");
  background-size: contain;
  padding: 16px 0;
  ${mixins.flexCenter}
  ${mixins.B1}
  border: 2px solid #999999;
  width: 100%;
  background-color: ${({ selected }) => (selected ? "white" : "none")};
  color: ${({ selected }) => (selected ? "black" : "white")};

  color: ${({ disabled }) => disabled && "#999999"};
  cursor: pointer;

  @media (hover: hover) and (pointer: fine) {
    :hover {
      background-color: ${({ disabled }) => !disabled && "white"};
      color: ${({ disabled }) => !disabled && "black"};
      cursor: ${({ disabled, voted }) => (disabled || voted) && "default"};
    }
  }

  span {
    margin-left: 4px;
    color: ${({ disabled }) => (disabled ? "#999999" : "#eb5c2a")};
  }
`;

export const InputLabel = styled.div`
  ${mixins.H2}
`;
export const Input = styled.input`
  margin-bottom: 8px;
  border: 2px solid #999999;
  background-color: #242424;
  padding: 16px;
  ${mixins.B1}
  :focus {
    outline: none;
  }
`;

export const TextArea = styled.textarea`
  margin-bottom: 8px;
  border: 2px solid #999999;
  background-color: #242424;
  padding: 16px;
  ${mixins.B1}
  :focus {
    outline: none;
  }
`;

export const ErrorText = styled.div`
  padding: 0 40px;
  ${mixins.H2}
  text-align: center;
  color: #eb5c2a;
`;
