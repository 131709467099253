import styled from "styled-components";
import SuspenseImage from "../../../../components/SuspenseImage";

export const BrandsContainer = styled.section`
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  gap: 20px;
  width: 100%;
  align-content: center;
  padding: 20px;
  margin-bottom: 20px;

  @media (min-width: 768px) {
    gap: 40px;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    padding: 60px;
    margin-bottom: 0;
  }
`;

export const BrandContainer = styled.div`
  display: flex;
  flex-direction: column;
  cursor: pointer;

  h4 {
    text-align: left;
    margin-top: 10px;
  }
`;

export const ImageContainer = styled.div`
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  cursor: pointer;
`;

export const Image = styled(SuspenseImage)`
  width: 100%;
  height: 100%;
  aspect-ratio: 1;
  object-fit: cover;
  border-radius: 20px;
  margin-bottom: ${({ more }) => (more === "true" ? "30px" : 0)};
`;
