import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { composeErrorMessage } from "../../utils/utils";
import balanceAndPinService from "./balanceAndPinService";

const initialState = {
  balanceAndPin: null,
  isLoading: false,
  errorMessage: "",
  hasPersonalDetails: null,
  gettingPersonalDetails: false,
  errorGettingPersonalDetails: "",
  savingPersonalDetails: false,
  errorSavingPersonalDetails: ""
};

export const getBalanceAndPin = createAsyncThunk(
  "balanceAndPin/getBalanceAndPin",
  async ({ serial, query }, thunkAPI) => {
    try {
      const response = await balanceAndPinService.getBalanceAndPin(
        serial,
        query
      );

      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(composeErrorMessage(error));
    }
  }
);

export const hasPersonalDetailsAction = createAsyncThunk(
  "balanceAndPin/hasPersonalDetails",
  async (serial, thunkAPI) => {
    try {
      const response = await balanceAndPinService.hasPersonalDetails(serial);

      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(composeErrorMessage(error));
    }
  }
);

export const savePersonalDetailsAction = createAsyncThunk(
  "balanceAndPin/savePersonalDetails",
  async ({ serial, details }, thunkAPI) => {
    try {
      const response = await balanceAndPinService.savePersonalDetails({
        serial,
        details
      });

      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(composeErrorMessage(error));
    }
  }
);

export const balanceAndPinSlice = createSlice({
  name: "balaceAndPin",
  initialState,
  reducers: {
    reset: (state) => initialState
  },
  extraReducers: (builder) => {
    builder
      .addCase(getBalanceAndPin.pending, (state) => {
        state.isLoading = true;
        state.errorMessage = null;
      })
      .addCase(getBalanceAndPin.fulfilled, (state, action) => {
        state.isLoading = false;
        state.balanceAndPin = action.payload;
      })
      .addCase(getBalanceAndPin.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.errorMessage = action.payload;
      })
      .addCase(hasPersonalDetailsAction.pending, (state) => {
        state.gettingPersonalDetails = true;
        state.errorGettingPersonalDetails = null;
      })
      .addCase(hasPersonalDetailsAction.fulfilled, (state, action) => {
        state.gettingPersonalDetails = false;
        state.hasPersonalDetails = action.payload;
      })
      .addCase(hasPersonalDetailsAction.rejected, (state, action) => {
        state.gettingPersonalDetails = false;
        state.isError = true;
        state.errorGettingPersonalDetails = action.payload;
      })
      .addCase(savePersonalDetailsAction.pending, (state) => {
        state.savingPersonalDetails = true;
        state.errorSavingPersonalDetails = null;
      })
      .addCase(savePersonalDetailsAction.fulfilled, (state, action) => {
        state.savingPersonalDetails = false;
      })
      .addCase(savePersonalDetailsAction.rejected, (state, action) => {
        state.savingPersonalDetails = false;
        state.isError = true;
        state.errorSavingPersonalDetails = action.payload;
      });
  }
});

export const { reset } = balanceAndPinSlice.actions;
export default balanceAndPinSlice.reducer;
