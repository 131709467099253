import styled from "styled-components";

export const StyledApp = styled.div`
  max-width: 1920px;
  position: relative;
  margin: auto;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto 1fr auto;
  grid-template-areas:
    "header"
    "main"
    "footer";
`;
