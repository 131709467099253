import axios from "axios";
import { ENV } from "../../env";
import { sleep } from "../../utils/utils";

// https://www.dev-manage-mii.uk/api/das/tap2start/device/00006280015948985009
// https://www.manage-mii.uk/api/das/tap2start/device/00006280015948985009

export const DAS_API_URL = `https://www.${
  ENV !== "production" ? (ENV === "development" ? "dev" : ENV) + "-" : ""
}manage-mii.uk/api/das`;

export const ENROLMENT_API_URL = `https://www.${
  ENV !== "production" ? (ENV === "development" ? "dev" : ENV) + "-" : ""
}manage-mii.uk/api/enrollment`;

const fetchDevice = async (serial) => {
  const response = await axios.get(DAS_API_URL + `/tap2start/device/${serial}`);

  return response.data;
};

const checkBin = async (binNumber) => {
  const response = await axios.post(
    ENROLMENT_API_URL + `/payment/card/bin-check`,
    {
      binNumber,
    }
  );

  await sleep(1500);
  return response.data;
};

const tap2StartService = {
  fetchDevice,
  checkBin,
};

export default tap2StartService;
