import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import ProtectedRoute from "../../components/ProtectedRoute";
import { StyledSpinner } from "../../components/styled/Spinner";
import { resetCampaign } from "../../features/campaign-manager/campaignManagerSlice";
import { getDasDevicesByCampaign } from "../../features/das-device-manager/dasDeviceManagerSlice";
import NavBarComponent from "./components/NavBar";
import { Input } from "./components/styled/SharedStyles";
import { Body, ErrorMsg, MainContainer } from "./styled/SharedStyles";

const TransferDevices = () => {
  const params = useParams();
  const dispatch = useDispatch();

  const { isLoading, isError, message } = useSelector(
    (state) => state.campaigns
  );

  const {
    devicesTransferred,
    devices,
    isLoading: isLoadingDevices,
    isError: isErrorDevices,
    message: messageDevices
  } = useSelector((state) => state.dasDevices);

  useEffect(() => {
    if (!devices) {
      dispatch(getDasDevicesByCampaign(params.campaignId));
    }

    return () => {};
  }, [devices, dispatch, params.campaignId]);

  useEffect(() => {
    return () => {
      dispatch(resetCampaign());
    };
  }, [dispatch]);

  return (
    <MainContainer>
      <ProtectedRoute />
      <NavBarComponent
        leftText={"Campaign"}
        title="Trasfer devices  "
        to={`/campaign-manager/${params.campaignId}`}
      />

      <Body>
        {devicesTransferred && (
          <>
            <p>devices succesfully transffered</p>
          </>
        )}
        <>
          <p>Transfer devices from</p>
          <Input />
          <p>to</p>
          <Input />
        </>
        {(isError || isErrorDevices) && (
          <ErrorMsg>
            {message ||
              messageDevices ||
              "An error has occured, please try again later or contact support."}
          </ErrorMsg>
        )}
        {(isLoading || isLoadingDevices) && <StyledSpinner />}
      </Body>
    </MainContainer>
  );
};

export default TransferDevices;
