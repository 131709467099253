import styled from "styled-components";
import mixins from "../../styled/mixins";

export const MainContainer = styled.section`
  margin: auto;
  display: grid;
  width: 100%;
  padding: 20px;
  grid-template-columns: 1fr;
  max-width: 480px; //mobile view
`;

export const PageTitle = styled.div`
  text-align: center;
  font-size: 2rem;
  color: #1d1d1f;
  font-weight: 600;
  margin-bottom: 20px;
  margin-top: 8px;
`;

export const PageSubtitle = styled.div`
  text-align: center;
  font-size: 1.125rem;
  color: #8d8e99;
  font-weight: 500;
  margin-bottom: 30px;
`;

export const Label = styled.div`
  margin-bottom: 5px;
  color: #8d8e99;
`;

export const Error = styled.div`
  color: red;
  margin-bottom: 10px;
  margin-top: -10px;
`;

export const Input = styled.input`
  border-radius: 12px;
  border-bottom-left-radius: ${({ hasDropdown }) => hasDropdown && 0};
  border-bottom-right-radius: ${({ hasDropdown }) => hasDropdown && 0};
  height: 56px;
  width: 100%;
  color: #303033;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 18px;
  padding: 16px;
  border: none;
  margin-bottom: ${({ hasDropdown }) => (hasDropdown ? 0 : "16px")};
  :focus {
    outline: none;
  }
`;

export const TextArea = styled.textarea`
  border-radius: 12px;
  max-width: 460px;
  width: 100%;
  color: #303033;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 18px;
  padding: 16px;
  border: none;
  margin-bottom: 16px;
  :focus {
    outline: none;
  }
  ::placeholder {
    ${mixins.B1}
    color: #757575;
  }
`;

export const TransitionIcon = styled.img`
  margin-right: 6px;
`;

export const Button = styled.button`
background: ${({ isSecondary }) => (isSecondary ? "#FE7E45" : "#3E55F2")};
background: ${({ danger }) => danger && "#FFFFFF"};
background: ${({ disabled }) => disabled && "#A2A6B8"};
  background: ${({ disabled }) =>
    disabled && "#A2A6B8"};  font-family: "Noto Sans", sans-serif;
  border: none;
  border-radius: 10px;
  height: 56px;
  width: 100%;
  color: white;
  margin: 10px 0;
  text-align: center;
  font-weight: 700;
  font-size: 1.125rem;
  cursor: ${({ disabled }) => (disabled ? "default" : "pointer")};
  &:hover: ${({ disabled }) => (disabled ? "#2e78f7" : "green")};

  &:hover {
    background-color:  ${({ disabled }) => (disabled ? "#A2A6B8" : "#2e78f7")};
  }
  &:active {
    transform: scale(0.98);
  }
`;
