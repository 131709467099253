import styled from "styled-components";
import { GoButton } from "../../../brands/components/styled/FeaturedBrand";

export const BrandContainer = styled.section`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 30px 20px;
  @media (min-width: 768px) {
    padding: 40px 20px;
  }

  @media (min-width: 1440px) {
    padding: 80px 60px;
  }
`;
export const BrandImageContainer = styled.div`
  position: relative;
  margin-bottom: 20px;

  @media (min-width: 1440px) {
    margin-bottom: 40px;
  }

  img {
    object-fit: cover;
    width: 100%;
    max-width: 100%;
    max-height: 600px;
  }

  ${GoButton} {
    position: absolute;
    bottom: 30px;
    left: 20px;
    & > a {
      color: #0000ac;
      text-decoration: none;
    }

    @media (min-width: 768px) {
      bottom: 60px;
      left: 60px;
    }
  }
`;
export const BrandDescription = styled.div`
  h2 {
    font-style: normal;
    font-weight: 900;
    font-size: 3.75rem;
    line-height: 3.375rem;
    margin-bottom: 0.25rem;
  }
  p {
    color: #909199;
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.5rem;
  }

  @media (min-width: 768px) {
    h2 {
      font-style: normal;
      font-weight: 900;
      font-size: 7.5rem;
      line-height: 7.5rem;
      margin-bottom: 0.625rem;
    }
    p {
      color: #909199;
      font-weight: 400;
      font-size: 1rem;
      line-height: 1.5rem;
    }
  }

  @media (min-width: 1440px) {
    h2 {
      font-style: normal;
      font-weight: 900;
      font-size: 9.375rem;
      line-height: 7.5rem;
      margin-bottom: 1.25rem;
    }
    p {
      color: #909199;
      font-weight: 400;
      font-size: 1rem;
      line-height: 1.5rem;
    }
  }
`;
export const BrandFeatured = styled.div`
  h3 {
    text-align: left;
    font-weight: 400;
    font-size: 1.5rem;
    line-height: 1.875rem;
    margin-bottom: 1rem;
  }

  @media (min-width: 768px) {
    h3 {
      font-weight: 600;
      font-size: 4.375rem;
      line-height: 4.375rem;
      margin-bottom: 2.5rem;
    }
  }
`;

export const BrandMoreProducts = styled.div`
  width: 100%;
  display: grid;
  gap: 16px;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  padding: 16px;

  @media (min-width: 768px) {
    gap: 20px;
    padding: 20px;
  }

  @media (min-width: 1440px) {
    grid-template-columns: repeat(3, minmax(0, 1fr));
    gap: 40px;
    padding: 38px 64px;
  }
`;

export const Divider = styled.div`
  border-top: 1px solid #aeafb8;
  width: 100%;
  margin: 30px 0;
  @media (min-width: 768px) {
    margin: 40px 0;
  }
  @media (min-width: 768px) {
    margin: 70px 0;
  }
`;
