import styled from "styled-components";
import mixins from "./mixins";

export const EventNameContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
`;

export const EventHeaderContainer = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 22px;
`;

export const ImageContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100px;
  height: 100px;
  background: #f2f2f5;
  border-radius: 14px;
  padding: 10px;
`;

export const Image = styled.img`
  object-fit: contain;
  width: 100%;
  height: 100%;
`;

export const DetailsHeader = styled.div`
  color: #1d1d1f;
  font-weight: 600;
  font-size: 1.125rem;
  margin-left: 16px;
  margin-bottom: 10px;
`;

export const DetailsContainer = styled.div`
  background-color: white;
  border-radius: 16px;
  padding-left: 16px;
  margin-bottom: 16px;
`;

export const DetailsItem = styled.div`
  ${mixins.B1}
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 16px 16px 16px 0;
  height: ${({ lines }) => (lines ? `${lines}px` : "50px")};
  cursor: ${({ onClick }) => (onClick ? "pointer" : "inherit")};
  border-bottom: ${({ isLast }) => (!isLast ? "2px solid #f7f8fa" : "none")};

  div {
    color: #a2a6b8;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-left: 16px;
  }
`;
