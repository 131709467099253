import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";

import { getBalanceAndPin } from "../../features/balance-and-pin/balanceAndPinSlice";

import Spinner from "../../components/Spinner";
import {
  MainContainer,
  NavBar,
  B1,
  Wearable,
  Welcome,
  H1,
  InnerContainer,
  SectionContainer,
  SectionImg,
  H2,
  StyledLink,
  Footer,
  B2,
  Socials,
  ShowMore
} from "./styles/Infineon";

import logo from "./img/digiseq-logo.webp";
import ringImg from "./img/ring.webp";
import fobImg from "./img/fob.webp";
import nailImg from "./img/nail.webp";
import appStoreImg from "./img/appStore.svg";
import gplayImg from "./img/googleplay.svg";
import facebookIcon from "./img/facebook.svg";
import twitterIcon from "./img/twitter.svg";
import linkedinIcon from "./img/linkedin.svg";

import { INFINEON_DATA } from "./data";

const Infineon = () => {
  const [searchParams] = useSearchParams();
  const serialNumber = searchParams.get("serialNumber");
  const type = searchParams.get("type");
  const dispatch = useDispatch();

  const [showMore, setShowMore] = useState(false);
  const [showMore2, setShowMore2] = useState(false);

  const { balanceAndPin, isLoading } = useSelector(
    (state) => state.balanceAndPin
  );

  useEffect(() => {
    if (!balanceAndPin && serialNumber && type === "fob") {
      dispatch(
        getBalanceAndPin({ serial: serialNumber, query: "?provider=tokenised" })
      );
    }
  }, [balanceAndPin, serialNumber, type, dispatch]);

  const formattedBalance = (balanceDetails) => {
    const { availableBalance, currency } = balanceDetails;
    let iconPrefixed = true;
    let icon = "";

    switch (currency) {
      case "GBP":
        icon = "£";
        break;
      case "EUR":
        icon = "€";
        break;
      case "USD":
        icon = "$";
        break;
      default:
        icon = "";
    }

    if (iconPrefixed) {
      return icon + availableBalance;
    } else {
      return availableBalance + icon;
    }
  };

  if (isLoading) return <Spinner />;

  return (
    <MainContainer>
      <NavBar>
        <img src={logo} alt="hugo boss logo" className="logo" />
        {type === "fob" && (
          <div className="balance-container">
            <B1>
              Balance:{" "}
              {balanceAndPin?.balanceDetails?.availableBalance
                ? formattedBalance(balanceAndPin?.balanceDetails)
                : "N/A"}
            </B1>
          </div>
        )}
      </NavBar>
      <InnerContainer>
        <Wearable>
          {type === "ring" && <img src={ringImg} alt="wearable" />}
          {type === "fob" && <img src={fobImg} alt="wearable" />}
          {type === "nail" && <img src={nailImg} alt="wearable" />}
        </Wearable>
        <Welcome>
          <H1>{INFINEON_DATA.welcome[type].title}</H1>
          <B1>{INFINEON_DATA.welcome[type].description}</B1>
        </Welcome>
        <SectionContainer>
          <SectionImg src={INFINEON_DATA.hero.img} alt="hero img" />
          <div className="inner">
            <H2>{INFINEON_DATA.hero[type].title}</H2>
            {type === "fob" ? (
              <B1>{INFINEON_DATA.hero[type].download}</B1>
            ) : (
              <B1>{INFINEON_DATA.hero.common.download}</B1>
            )}
            <ul>
              <li>
                <B1 disc>{INFINEON_DATA.hero.common.p1}</B1>
              </li>
              <li>
                <B1 disc>{INFINEON_DATA.hero.common.p2}</B1>
              </li>
            </ul>
            <div className="apps">
              <img
                src={appStoreImg}
                alt="appstore"
                onClick={() =>
                  window.open(
                    "https://apps.apple.com/gb/app/manage-mii/id6446908503",
                    "_blank"
                  )
                }
              />
              <img
                src={gplayImg}
                alt="gplay"
                onClick={() =>
                  window.open(
                    "https://play.google.com/store/apps/details?id=com.digiseq.managemii",
                    "_blank"
                  )
                }
              />
            </div>
          </div>
        </SectionContainer>
        <SectionContainer>
          <div className="inner">
            <H2>
              {INFINEON_DATA.digiseq.title}
              <span className="digiseq">{INFINEON_DATA.digiseq.titleHl}</span>?
            </H2>
            <B1>{INFINEON_DATA.digiseq.p1}</B1>
            {showMore && (
              <>
                <B1 className="st">{INFINEON_DATA.digiseq.sectionTitle}</B1>
                <B1>{INFINEON_DATA.digiseq.p2}</B1>
                <B1>{INFINEON_DATA.digiseq.p3}</B1>
              </>
            )}
            <ShowMore onClick={() => setShowMore(!showMore)}>
              {`Show ${showMore ? "less" : "more"}`}
            </ShowMore>
          </div>
        </SectionContainer>
        <SectionContainer>
          <SectionImg src={INFINEON_DATA.whatsOn.img} alt="hero img" />
          <div className="inner">
            <H2>{INFINEON_DATA.whatsOn.title}</H2>
            <B1>
              {INFINEON_DATA.whatsOn.span1}
              <StyledLink
                hasMargin
                href="https://images.infineon-community.com/Web/InfineonTechnologiesAG/%7Be39e8719-dc22-48fd-84fb-d34f7db10a77%7D_Agenda_Banking_Day_2023_A4.pdf?elqTrackId=ae76265b9d334417b29adb7eccc7c312&elqaid=1693&elqat=2&_gl=1*f0mhgy*_ga*MTkyNTYzMjEzNi4xNjUzNDc3Mjc0*_ga_EG7GJJWTSH*MTY5NTI4ODU2NC42NTkuMC4xNjk1Mjg4NTY0LjYwLjAuMA..&_ga=2.154100529.828000239.1695280744-1925632136.1653477274"
                target="_blank"
                rel="noopener noreferrer"
              >
                {INFINEON_DATA.whatsOn.a}
              </StyledLink>
              {INFINEON_DATA.whatsOn.span2}
            </B1>
          </div>
        </SectionContainer>
        <SectionContainer>
          <div className="inner">
            <H2>{INFINEON_DATA.mcWallet.title}</H2>
            <B1>
              {INFINEON_DATA.mcWallet.p1}
              {showMore2 && <span>{INFINEON_DATA.mcWallet.p2}</span>}
              {!showMore2 && <span>...</span>}
              <ShowMore onClick={() => setShowMore2(!showMore2)}>
                {`Show ${showMore2 ? "less" : "more"}`}
              </ShowMore>
            </B1>
          </div>
        </SectionContainer>
        <SectionContainer>
          <SectionImg src={INFINEON_DATA.uefa.img} alt="hero img" />
          <div className="inner">
            <H2>{INFINEON_DATA.uefa.title}</H2>
            <B2>{INFINEON_DATA.uefa.p1}</B2>
            <StyledLink
              href="https://www.youtube.com/watch?v=xEp2JUOyIBg"
              target="_blank"
              rel="noopener noreferrer"
            >
              {INFINEON_DATA.uefa.a}
            </StyledLink>
          </div>
        </SectionContainer>
        <SectionContainer>
          <SectionImg src={INFINEON_DATA.wsr.img} alt="hero img" />
          <div className="inner">
            <H2>{INFINEON_DATA.wsr.title}</H2>
            <B2>{INFINEON_DATA.wsr.p1}</B2>
            <StyledLink
              href="https://www.digiseq.co.uk/news-and-insights/scp03"
              target="_blank"
              rel="noopener noreferrer"
            >
              {INFINEON_DATA.wsr.a}
            </StyledLink>
          </div>
        </SectionContainer>
        <SectionContainer>
          <SectionImg src={INFINEON_DATA.sca.img} alt="hero img" />
          <div className="inner">
            <H2>{INFINEON_DATA.sca.title}</H2>
            <B2>{INFINEON_DATA.sca.p1}</B2>
            <StyledLink
              href="https://www.digiseq.co.uk/whitepapers/2023/4/25/wearables-and-psd2-sca"
              target="_blank"
              rel="noopener noreferrer"
            >
              {INFINEON_DATA.sca.a}
            </StyledLink>
          </div>
        </SectionContainer>
      </InnerContainer>
      <Footer>
        <H2 className="digiseq">{INFINEON_DATA.footer.title}</H2>
        <H2>{INFINEON_DATA.footer.subtitle}</H2>
        <H2>{INFINEON_DATA.footer.subtitle2}</H2>
        <div className="links">
          <B2
            onClick={() =>
              window.open(INFINEON_DATA.footer.link1.url, "_blank")
            }
          >
            {INFINEON_DATA.footer.link1.title}
          </B2>
          <B2
            onClick={() =>
              window.open(INFINEON_DATA.footer.link2.url, "_blank")
            }
          >
            {INFINEON_DATA.footer.link2.title}
          </B2>
          <B2
            onClick={() =>
              window.open(INFINEON_DATA.footer.link3.url, "_blank")
            }
          >
            {INFINEON_DATA.footer.link3.title}
          </B2>
          <B2
            onClick={() =>
              window.open(INFINEON_DATA.footer.link4.url, "_blank")
            }
          >
            {INFINEON_DATA.footer.link4.title}
          </B2>
          <Socials>
            <img
              src={facebookIcon}
              alt="facebook"
              onClick={() =>
                window.open(INFINEON_DATA.footer.socials.facebook.url, "_blank")
              }
            />
            <img
              src={linkedinIcon}
              alt="linkedin"
              onClick={() =>
                window.open(INFINEON_DATA.footer.socials.linkedin.url, "_blank")
              }
            />
            <img
              src={twitterIcon}
              alt="twitter"
              onClick={() =>
                window.open(INFINEON_DATA.footer.socials.twitter.url, "_blank")
              }
            />
          </Socials>
        </div>
      </Footer>
    </MainContainer>
  );
};

export default Infineon;
