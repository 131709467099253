import React, { useEffect } from "react";

import { DropdownContainer, DropdownElement } from "./styled/FilterBar";
import { useDispatch, useSelector } from "react-redux";

import CheckboxIcon from "./images/checkbox.svg";
import CheckboxCheckedIcon from "./images/checkbox-checked.svg";
import { resetFilter, setFilter } from "../../../features/devices/deviceSlice";

const FilterDropdown = ({ visible }) => {
  const dispatch = useDispatch();
  const { filter } = useSelector((state) => state.devices);

  const filters = ["charms", "jewellery", "fobs", "rings", "nails", "watches"];

  useEffect(() => {
    return () => {
      dispatch(resetFilter());
    };
  }, [dispatch]);

  const handleSelect = (type) => {
    let newFilter = [...filter].filter((e) => e !== "All");

    if (!filter.some((e) => e === type)) {
      newFilter.push(type);
    } else {
      newFilter = newFilter.filter((e) => e !== type);
    }

    dispatch(setFilter(newFilter));
  };

  if (visible) {
    return (
      <DropdownContainer left>
        {filters.map((e, i) => (
          <DropdownElement onClick={() => handleSelect(e)} key={i}>
            <img
              src={
                filter.some((t) => t === e) ? CheckboxCheckedIcon : CheckboxIcon
              }
              alt="checkbox"
            />
            <h4>{e}</h4>
          </DropdownElement>
        ))}
      </DropdownContainer>
    );
  }

  return null;
};

export default FilterDropdown;
