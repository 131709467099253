export const formatIsoDate = (isoDate, option) => {
    const date = new Date(isoDate).toLocaleDateString("en-gb");
    const time = new Date(isoDate).toLocaleTimeString("en-gb", {
      timeStyle: "short",
      hour12: false,
      timeZone: "Europe/London"
    });
  
    switch (option) {
      case "date":
        return date;
      case "time":
        return time;
  
      default:
        return `${date} ${time}`;
    }
  };
  
  export const withoutMilliseconds = (isoStr) =>
    isoStr.includes(".") ? isoStr.split(".")[0] : isoStr;