import companyLogo from "./images/logo.svg";

const people = {
  colin_tanner: {
    name: "Colin",
    surname: "Tanner",
    title: "CTO",
    photo: require("./images/Colin-Tanner.jpg"),
    company: "Digiseq",
    companyLogo,
    email: "colin.tanner@digiseq.co.uk",
    phone: "+44(0)7790 337778",
    address: "69 Wilson Street;London;EC2A2BB;UK",
    social: {
      instagram: "https://www.instagram.com/digiseq_ltd",
      linkedin: "https://www.linkedin.com/company/digiseq",
      twitter: "https://twitter.com/digiseqUK",
      whatsapp: "https://wa.me/+447790337778"
    }
  },
  alex_komarov: {
    name: "Alex",
    surname: "Komarov",
    title: "Head of Products",
    photo: require("./images/Alex-Komarov.jpg"),
    company: "Digiseq",
    companyLogo,
    email: "alex.komarov@digiseq.co.uk",
    phone: "+44(0)7856 438198",
    address: "69 Wilson Street;London;EC2A2BB;UK",
    social: {
      instagram: "https://www.instagram.com/digiseq_ltd",
      linkedin: "https://www.linkedin.com/company/digiseq",
      twitter: "https://twitter.com/digiseqUK",
      whatsapp: "https://wa.me/+447856438198"
    }
  },
  andy_ramsden: {
    name: "Andy",
    surname: "Ramsden",
    title: "Business Development Consultant",
    photo: require("./images/Andy-Ramsden.jpg"),
    company: "Digiseq",
    companyLogo,
    email: "andy.ramsden@digiseq.co.uk",
    phone: "+44(0)7525 687168",
    address: "69 Wilson Street;London;EC2A2BB;UK",
    social: {
      instagram: "https://www.instagram.com/digiseq_ltd",
      linkedin: "https://www.linkedin.com/company/digiseq",
      twitter: "https://twitter.com/digiseqUK",
      whatsapp: "https://wa.me/+447525687168"
    }
  },
  nigel_palmer: {
    name: "Nigel",
    surname: "Palmer",
    title: "Account Manager",
    photo: require("./images/Nigel-Palmer.png"),
    company: "Digiseq",
    companyLogo,
    email: "nigel.palmer@digiseq.co.uk",
    phone: "+44(0)7969 026928",
    address: "69 Wilson Street;London;EC2A2BB;UK",
    social: {
      instagram: "https://www.instagram.com/digiseq_ltd",
      linkedin: "https://www.linkedin.com/company/digiseq",
      twitter: "https://twitter.com/digiseqUK",
      whatsapp: "https://wa.me/+447969026928"
    }
  },
  iain_case: {
    name: "Iain",
    surname: "Case",
    title: "Solution Integration Manager",
    photo: require("./images/Iain-Case.jpg"),
    company: "Digiseq",
    companyLogo,
    email: "iain.case@digiseq.co.uk",
    phone: "+44(0)7525 478250",
    address: "69 Wilson Street;London;EC2A2BB;UK",
    social: {
      instagram: "https://www.instagram.com/digiseq_ltd",
      linkedin: "https://www.linkedin.com/company/digiseq",
      twitter: "https://twitter.com/digiseqUK",
      whatsapp: "https://wa.me/+447525478250"
    }
  },
  lenny_davies: {
    name: "Lenny",
    surname: "Davies",
    title: "Product Marketing Manager",
    photo: require("./images/Lenny-Davies.jpg"),
    company: "Digiseq",
    companyLogo,
    email: "lenny.davies@digiseq.co.uk",
    phone: "+44(0)7437 014210",
    address: "69 Wilson Street;London;EC2A2BB;UK",
    social: {
      instagram: "https://www.instagram.com/digiseq_ltd",
      linkedin: "https://www.linkedin.com/company/digiseq",
      twitter: "https://twitter.com/digiseqUK",
      whatsapp: "https://wa.me/+447437014210"
    }
  },
  ido_kariti: {
    name: "Ido",
    surname: "Kariti",
    title: "CEO",
    photo: require("./images/ido-kariti.jpg"),
    company: "Digiseq",
    companyLogo,
    email: "ido.kariti@digiseq.co.uk",
    phone: "+972 54 247 6035",
    address: "69 Wilson Street;London;EC2A2BB;UK",
    social: {
      instagram: "",
      linkedin: "https://www.linkedin.com/in/ido-kariti/",
      twitter: "",
      whatsapp: "https://wa.me/+972542476035"
    }
  },
  terrie_smith: {
    name: "Terrie",
    surname: "Smith",
    title: "Ambassador",
    photo: require("./images/Terrie-Smith.jpg"),
    company: "Digiseq",
    companyLogo,
    email: "terrie.smith@digiseq.co.uk",
    phone: "+44(0)7850 05683",
    address: "69 Wilson Street;London;EC2A2BB;UK",
    social: {
      instagram: "https://www.instagram.com/digiseq_ltd",
      linkedin: "https://www.linkedin.com/company/digiseq",
      twitter: "https://twitter.com/digiseqUK",
      whatsapp: "https://wa.me/+44785005683"
    }
  },
  brad_blake: {
    name: "Brad",
    surname: "Blake",
    title: "CEO",
    photo: require("./images/Brad-Blake.png"),
    company: "Network B",
    companyLogo,
    email: "brad@networkb.uk",
    phone: "+44 7814 043138",
    address: "25 Emmer Green;Luton;LU2 8UH;UK",
    social: {
      instagram: "",
      linkedin: "https://www.linkedin.com/company/networkb/",
      twitter: "",
      whatsapp: "https://wa.me/+447814043138"
    }
  },
  lawrence_hill: {
    name: "Lawrence",
    surname: "Hill",
    title: "",
    photo: require("./images/lawrence_hill.png"),
    company: "Lawrence Hill Music",
    companyLogo,
    email: "Lawrence@proud2present.co.uk",
    phone: "+44 7814 043138",
    address: "",
    social: {
      instagram: "https://www.instagram.com/lawrencehillmusic/",
      linkedin: "",
      twitter: "",
      whatsapp: "https://wa.me/+447814043138"
    }
  },
  sander_koster: {
    name: "Sander",
    surname: "Koster",
    title: "Business Development Manager",
    photo: require("./images/sander_koster.webp"),
    company: "",
    companyLogo,
    email: "koster.se@gmail.com",
    phone: "+31621397783",
    address: "Iseldoks 107;Doetinchem;7005CX;Netherlands",
    social: {
      instagram: "https://www.instagram.com/sanderrkoster/",
      linkedin: "https://www.linkedin.com/in/koster-sander/",
      twitter: "",
      whatsapp: "https://wa.me/+31621397783"
    }
  },
  david_karney: {
    name: "David",
    surname: "Karney",
    title:
      "Head of Digital Assets and Member of the worldline scientific community",
    photo: require("./images/david-karney.webp"),
    company: "Worldline",
    companyLogo: require("./logos/worldline.png"),
    email: "david.karney@worldline.com",
    phone: "07506 088950",
    address: "Worldline;Summit House;Red Lion Square;London, WC1R 4HQ",
    social: {
      instagram: "",
      linkedin: "https://www.linkedin.com/in/davidkarney",
      twitter: "",
      whatsapp: "https://wa.me/+447506088950"
    }
  },
  adellet_sarkis: {
    name: "Adellet",
    surname: "Sarkis",
    title: "Strategic Partnership Manager",
    photo: require("./images/adellet-sarkis.webp"),
    company: "Guava Pay",
    companyLogo,
    email: "adellet.sarkis@guavapay.com",
    phone: "07475079740",
    address: "Guava Pay;Salisbury House;29 Finsbury Circus;London, EC2M 5QQ",
    social: {
      instagram: "",
      linkedin: "linkedin.com/in/adellet-sarkis-284426160",
      twitter: "",
      whatsapp: "https://wa.me/+447475079740"
    }
  },
  eyvaz_allahverdiyev: {
    name: "Eyvaz",
    surname: "Allahverdiyev",
    title: "Director of processing",
    photo: require("./images/generic-male-profile.webp"),
    company: "Guava Pay",
    companyLogo,
    email: "a_eyvaz@guavapay.com",
    phone: "07475079740",
    address: "Guava Pay;Salisbury House;29 Finsbury Circus;London, EC2M 5QQ",
    social: {
      instagram: "",
      linkedin: "linkedin.com/in/eyvaz-allahverdiyev-17ba8a112",
      twitter: "",
      whatsapp: "https://wa.me/+447475079740"
    }
  },
  fabrizio_gargiulo: {
    name: "Fabrizio",
    surname: "Gargiulo",
    title: "Regional Sales manager, North UK",
    photo: require("./images/fabrizio-gargiulo.webp"),
    company: "Inomed Neurocare LTD",
    companyLogo,
    email: "F.gargiulo@inomed.com",
    phone: "07566795594",
    address: "One Lyric Square;London, W6 0NB",
    social: {
      instagram: "",
      linkedin: "linkedin.com/in/fabrizio-gargiulo-01289530",
      twitter: "https://twitter.com/Fitfab21",
      whatsapp: "https://wa.me/+447566795594"
    }
  },
  chris_hill: {
    name: "Chris",
    surname: "Hill",
    title: "Head of Marketing",
    photo: require("./images/chris-hill.webp"),
    company: "Rubber Road LTD",
    companyLogo,
    email: "chris@numksull.com",
    phone: "07376006492",
    address:
      "Rubber Road LTD;Attimore Barn;Ridgeway;Welwyn Garden City;AL7 2AD",
    social: {
      instagram: "",
      linkedin: "linkedin.com/in/christophermarkhill",
      twitter: "",
      whatsapp: "https://wa.me/+447376006492"
    }
  },
  iginio_voorhorst: {
    name: "Iginio",
    surname: "Voorhorst",
    title: "DGA, Investeerder, Entrepreneur, Visionair en startup helper",
    photo: require("./images/iginio-voorhorst.webp"),
    company: "I-Invest BV",
    companyLogo,
    email: "voorhorst@ivoorhorstbeheer.nl",
    phone: "+31653182085",
    address: "Ulftseweg 112c;Silvolde;7064BG;Netherlands",
    social: {
      instagram: "",
      linkedin: "https://www.linkedin.com/in/iginio-voorhorst-695137179/",
      twitter: "",
      whatsapp: "https://wa.me/+31653182085"
    }
  },
  melvin_joseph: {
    name: "Melvin A",
    surname: "Joseph",
    title: "Head of Merchant Sales & Acquiring",
    photo: require("./images/melvin_joseph.webp"),
    company: "Visa UAE",
    companyLogo: require("./logos/visa.png"),
    email: "mjoseph@visa.com",
    phone: "+971 56 655 3857",
    address: "Visa;Middle East;FZ-LLC;Dubai Media City;Dubai;UAE",
    social: {
      instagram: "",
      linkedin: "https://www.linkedin.com/in/melvin-joseph-67154024",
      twitter: "",
      whatsapp: "https://wa.me/+971566553857"
    }
  },
  vinita_michael: {
    name: "Vinita",
    surname: "Michael",
    title: "",
    photo: require("./images/vinita_michael.webp"),
    company: "Vinita Michael LLC",
    companyLogo: require("./logos/vinita-michael.png"),
    email: "vinita@vinitamichael.com",
    phone: "+971 50 7092290",
    address: "Sharjah Media City;P.O Box 515000;Sharjah;UAE",
    social: {
      instagram: "https://www.instagram.com/vinitamichaeljewelcraft/",
      linkedin: "",
      twitter: "",
      whatsapp: "https://wa.me/+971507092290"
    }
  }
};

export default people;
