import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  getCampaigns,
  resetCampaign,
  resetError
} from "../../features/campaign-manager/campaignManagerSlice";
import {
  getDasDevicesByCampaign,
  resetDasDevices,
  transferTaggedDasDevices,
  resetError as resetDevicesError
} from "../../features/das-device-manager/dasDeviceManagerSlice";
import ProtectedRoute from "../../components/ProtectedRoute";
import NavBarComponent from "./components/NavBar";
import { StyledSpinner } from "../../components/styled/Spinner";
import { Button, Input } from "./components/styled/SharedStyles";
import DropdownSelect from "./components/DropdownSelect";
import { DevicesBadge, Tag, TagsContainer } from "./styled/CampaignDevices";
import { Body, ErrorMsg, MainContainer } from "./styled/SharedStyles";

const CampaignDevices = () => {
  const params = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { campaigns, isLoading, isError, message } = useSelector(
    (state) => state.campaigns
  );

  const {
    devices,
    isLoading: isLoadingDevices,
    isError: isErrorDevices,
    message: messageDevices,
    devicesTransferred
  } = useSelector((state) => state.dasDevices);

  const [selectedTag, setSelectedTag] = useState("");
  const [toCampaignName, setToCampaignName] = useState("");
  const [toCampaignId, setToCampaignId] = useState("");
  const [showCampaigns, setShowCampaigns] = useState(false);

  useEffect(() => {
    if (!campaigns) {
      dispatch(getCampaigns());
    }
  }, [campaigns, dispatch]);

  useEffect(() => {
    if (!devices?.length) {
      dispatch(getDasDevicesByCampaign(params.campaignId));
    }
  }, [devices?.length, dispatch, params.campaignId]);

  useEffect(() => {
    return () => {
      dispatch(resetDasDevices());
      dispatch(resetCampaign());
      dispatch(resetError());
      dispatch(resetDevicesError());
    };
  }, [dispatch]);

  const handleTransferDevices = () => {
    dispatch(
      transferTaggedDasDevices({
        fromCampaignId: params.campaignId,
        tag: selectedTag,
        toCampaignId: toCampaignId
      })
    );
  };

  if (devicesTransferred) {
    return (
      <MainContainer>
        <ProtectedRoute />
        <NavBarComponent leftText={"Campaign"} title={"Devices"} hasBackArrow />
        <Body>
          <p>Devices with selected tag were succesfully transferred!</p>
          <Button onClick={() => navigate(-1)}>Return to campaign</Button>
        </Body>
      </MainContainer>
    );
  }

  return (
    <MainContainer>
      <ProtectedRoute />
      <NavBarComponent leftText={"Campaign"} title={"Devices"} hasBackArrow />
      <Body>
        <DevicesBadge>{`${devices?.count} Devices`}</DevicesBadge>

        {devices?.tags?.length > 0 && (
          <>
            <p>Tags</p>
            <TagsContainer>
              {Array.from(new Set(devices?.tags)).map((e, i) => (
                <Tag
                  selected={selectedTag === e}
                  key={i}
                  onClick={() => {
                    if (selectedTag === e) {
                      setSelectedTag("");
                    } else setSelectedTag(e);
                  }}
                >
                  {e}
                </Tag>
              ))}
            </TagsContainer>
            <p>Transfer selected tag devices to a different campaign</p>
            <Input
              onFocus={() => setShowCampaigns(true)}
              hasDropdown={showCampaigns && !toCampaignId}
              placeholder="destination campaign id"
              value={toCampaignName}
              onChange={(ev) => {
                setToCampaignName(ev.target.value.toLowerCase());
                setToCampaignId("");
              }}
            />
            {campaigns?.length > 0 && showCampaigns && !toCampaignId && (
              <DropdownSelect
                onSelect={(e) => {
                  setToCampaignName(e.name);
                  setToCampaignId(e.id);
                }}
                listItems={campaigns.filter((e) =>
                  e.name.toLowerCase().includes(toCampaignName.toLowerCase())
                )}
              />
            )}
            <Button
              onClick={() => handleTransferDevices()}
              disabled={!selectedTag || !toCampaignId}
            >
              Transfer devices
            </Button>
          </>
        )}

        {(isLoading || isLoadingDevices) && <StyledSpinner />}
        {(isError || isErrorDevices) && (
          <ErrorMsg>
            {message ||
              messageDevices ||
              "An error has occured, please try again later or contact support."}
          </ErrorMsg>
        )}
      </Body>
    </MainContainer>
  );
};

export default CampaignDevices;
