import React, { useState } from "react";
import * as Yup from "yup";
import { withoutMilliseconds } from "../../../utils/dateTime";
import { validUrlRegex } from "../../../utils/utils";
import selectedIcon from "../img/selected-icon.svg";
import {
  Error,
  PageSubtitle,
  PageTitle,
  Button,
  Input
} from "./styled/SharedStyles";
import {
  InputDateTime,
  TransitionDescription,
  TransitionTypeContainer,
  Trigger
} from "./styled/LinkSettings";

const linkMessage = {
  NONE: "",
  TRANSITION_ON_READ:
    "The link will change to the next in the sequence once the current link has been opened.",
  TRANSITION_ON_FIXED_DATE_AND_TIME:
    "The link will change to the next in the sequence on the specified date",
  TRANSITION_ON_TIMER:
    "The link will change to the next in the sequence after the specified amount of time (seconds)."
};

const LinkSettings = ({
  onBack,
  linkId,
  onUpdateLink,
  onAddLink,
  onRemoveLink,
  campaignLinks
}) => {
  const [selectedTrigger, setSelectedTrigger] = useState(
    (campaignLinks?.successLinks &&
      campaignLinks?.successLinks[linkId]?.linkTransitionType) ||
      "NONE"
  );

  const [url, setUrl] = useState(
    campaignLinks?.successLinks[linkId]?.link || ""
  );
  const [seconds, setSeconds] = useState(
    campaignLinks?.successLinks[linkId]?.linkTransitionTimerSeconds || 0
  );

  const [dateTime, setDateTime] = useState(
    campaignLinks?.successLinks[linkId]?.linkTransitionDate
      ? withoutMilliseconds(
          campaignLinks?.successLinks[linkId]?.linkTransitionDate
        )
      : ""
  );

  const [error, setError] = useState({});

  const linkSchema = Yup.object({
    link: Yup.string()
      .matches(validUrlRegex, "Enter correct url!")
      .required("Please enter website"),
    seconds:
      selectedTrigger === "TRANSITION_ON_TIMER" &&
      Yup.number("Enter seconds as number")
        .integer("Seconds as integer number")
        .min(10)
        .required("Seconds required"),
    dateTime:
      selectedTrigger === "TRANSITION_ON_FIXED_DATE_AND_TIME" &&
      Yup.string().required("Date is required")
  });

  const validateEntries = async (entries) => {
    setError(null);
    try {
      await linkSchema.validate(entries);
      return true;
    } catch (e) {
      setError({ path: e.path, message: e.message });
      return false;
    }
  };

  const handleAddUpdateLink = async () => {
    if (linkId + 1 <= campaignLinks.successLinks.length) {
      const hasValidEntries = await validateEntries({
        link: url,
        seconds,
        dateTime
      });

      if (hasValidEntries) {
        onUpdateLink({
          position: linkId,
          link: url,
          linkTransitionType: selectedTrigger,
          linkTransitionDate:
            selectedTrigger === "TRANSITION_ON_FIXED_DATE_AND_TIME"
              ? dateTime + ":00.001"
              : null,
          linkTransitionTimerSeconds:
            selectedTrigger === "TRANSITION_ON_TIMER" ? Number(seconds) : null
        });
        onBack();
      }
    } else {
      const hasValidEntries = await validateEntries({
        link: url,
        seconds,
        dateTime
      });

      if (hasValidEntries) {
        onAddLink({
          position: linkId,
          link: url,
          linkTransitionType:
            campaignLinks.successLinks.length === 0 ? "NONE" : selectedTrigger,
          linkTransitionDate:
            selectedTrigger === "TRANSITION_ON_FIXED_DATE_AND_TIME"
              ? dateTime + ":00.001"
              : null,
          linkTransitionTimerSeconds:
            selectedTrigger === "TRANSITION_ON_TIMER" ? Number(seconds) : null
        });

        onBack();
      }
    }
  };

  const handleTriggerSelect = (trigger) => {
    setSelectedTrigger(trigger);
  };

  return (
    <div>
      <PageTitle>{`Link ${linkId + 1}`}</PageTitle>
      <Input
        id="input-url"
        type="url"
        autoFocus
        required
        value={url}
        onChange={(ev) => setUrl(ev.target.value)}
      />
      {error?.path === "link" && <Error>{error?.message}</Error>}
      <PageSubtitle>Transition type</PageSubtitle>
      <TransitionTypeContainer>
        <Trigger onClick={() => handleTriggerSelect("NONE")}>
          <div>None</div>
          {selectedTrigger === "NONE" && (
            <img src={selectedIcon} alt="selected" />
          )}
        </Trigger>

        <Trigger onClick={() => handleTriggerSelect("TRANSITION_ON_READ")}>
          <div>Read</div>
          {selectedTrigger === "TRANSITION_ON_READ" && (
            <img src={selectedIcon} alt="selected" />
          )}
        </Trigger>

        <Trigger
          onClick={() =>
            handleTriggerSelect("TRANSITION_ON_FIXED_DATE_AND_TIME")
          }
        >
          <div>Date</div>
          {selectedTrigger === "TRANSITION_ON_FIXED_DATE_AND_TIME" && (
            <img src={selectedIcon} alt="selected" />
          )}
        </Trigger>

        {campaignLinks.successLinks.length > 0 && (
          <>
            <Trigger onClick={() => handleTriggerSelect("TRANSITION_ON_TIMER")}>
              <div>Timer</div>
              {selectedTrigger === "TRANSITION_ON_TIMER" && (
                <img src={selectedIcon} alt="selected" />
              )}
            </Trigger>
          </>
        )}
      </TransitionTypeContainer>

      <TransitionDescription>
        {linkMessage[selectedTrigger]}
      </TransitionDescription>
      {selectedTrigger === "TRANSITION_ON_FIXED_DATE_AND_TIME" && (
        <div>
          <InputDateTime
            id="input-date"
            value={dateTime}
            onChange={(ev) => setDateTime(ev.target.value)}
            type="datetime-local"
            placeholder="DD/MM/YY HH:MM"
            required
          />
          {error?.path === "dateTime" && <Error>{error?.message}</Error>}
        </div>
      )}

      {selectedTrigger === "TRANSITION_ON_TIMER" && (
        <div>
          <InputDateTime
            value={seconds}
            onChange={(ev) => setSeconds(ev.target.value)}
            type="number"
            placeholder="0 seconds"
            required
          />
          {error?.path === "seconds" && <Error>{error?.message}</Error>}
        </div>
      )}

      <Button
        isPrimary
        onClick={() => {
          handleAddUpdateLink();
        }}
      >
        Apply
      </Button>
      {linkId <= campaignLinks.successLinks.length && (
        <Button
          onClick={() => {
            onRemoveLink(linkId);
            onBack();
          }}
        >
          Delete link
        </Button>
      )}
    </div>
  );
};

export default LinkSettings;
