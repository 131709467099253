import styled from "styled-components";

export const colors = {
  background: "#F4F3F1",
  sectionBg: "#F9F8F6",
  button: "#252525",
  primaryText: "#212121",
  secondaryText: "#666666",
  buttonText: "#FFFFFF",
  divider: "#E0E0E0",
  disabled: "#9E9E9E",
  border: "#1A2F5C"
};

export const fonts = {
  H1: {
    fontFamily: "soleil",
    fontSize: "32px",
    fontWeight: 600,
    lineHeight: "44px",
    textAlign: "center",
    color: colors.primaryText
  },
  H2: {
    fontFamily: "soleil",
    fontSize: "28px",
    fontWeight: 600,
    lineHeight: "36px",
    textAlign: "center",
    color: colors.primaryText
  },
  H3: {
    fontFamily: "soleil",
    fontSize: "24px",
    fontWeight: 600,
    lineHeight: "32px",
    textAlign: "center",
    color: colors.primaryText
  },
  B1: {
    fontFamily: "soleil",
    fontSize: "16px",
    lineHeight: "24px",
    fontWeight: 600,
    color: colors.primaryText
  },
  B2: {
    fontFamily: "soleil",
    fontSize: "16px",
    lineHeight: "24px",
    fontWeight: 400,
    color: colors.secondaryText
  },
  B3: {
    fontFamily: "soleil",
    fontSize: "16px",
    lineHeight: "24px",
    fontWeight: 700,
    color: colors.buttonText
  }
};

export const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 480px;
  margin: auto;
  min-height: 100vh;
  background-color: #ffffff;

  .logo {
    height: 16px;
  }
`;

export const NavBar = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid black;
  padding: 16px;

  .menu {
    height: 24px;
  }
`;

export const Footer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 240px;
  padding-top: 48px;
  background-color: ${colors.background};
`;
