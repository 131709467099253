import React, { useEffect, useState } from "react";
import { Content, MainContainer, Question, Questions } from "./styled/Location";
import { ActionButton, Box } from "./styled/SharedStyles";

import { useNavigate, useParams } from "react-router-dom";
import Alert from "./components/AlertComponent";
import Header from "./components/Header";
import PlayerBadge from "./components/PlayerBadge";
import { useDispatch, useSelector } from "react-redux";
import {
  resetLocationUpdate,
  updateLocation
} from "../../features/ascot/ascotSlice";

import q1 from "./img/q1.png";
import q2 from "./img/q2.png";
import q3 from "./img/q3.png";
import q4 from "./img/q4.png";
import q5 from "./img/q5.png";
import q6 from "./img/q6.png";

const mockedQuestions = [
  {
    id: 1,
    img: q1,
    question: "How many trees are there inside the Parade Ring?",
    clue: "You can see the horses walk around here before the race.",
    answers: [
      { id: 1, text: "Two", isCorrect: true },
      { id: 2, text: "Three", isCorrect: false },
      { id: 3, text: "Five", isCorrect: false }
    ]
  },
  {
    id: 2,
    img: q2,
    question: "What colour is the circle on the winning post?",
    clue: "You can see the horses finish the race here.",
    answers: [
      { id: 1, text: "Red", isCorrect: true },
      { id: 2, text: "Yellow", isCorrect: false },
      { id: 3, text: "Green", isCorrect: false }
    ]
  },
  {
    id: 3,
    img: q3,
    question: "What is on top of the Ascot Christmas tree?",
    clue: "Inside the Grandstand, you’ll find me standing tall, large, green and covered in lights and baubles.",
    answers: [
      { id: 1, text: "Brussel Sprout", isCorrect: false },
      { id: 2, text: "Bow", isCorrect: true },
      { id: 3, text: "Elf", isCorrect: false }
    ]
  },
  {
    id: 4,
    img: q4,
    question: "What is the symbol on top of the Ascot logo?",
    clue: "At the fairground I go round, taking you up and then back down.",
    answers: [
      { id: 1, text: "A crown", isCorrect: true },
      { id: 2, text: "A diamond", isCorrect: false },
      { id: 3, text: "A star", isCorrect: false }
    ]
  },
  {
    id: 5,
    img: q5,
    question: " Which one of us is known for having a red nose?",
    clue: "We help Santa pull his sleigh on Christmas Eve.",
    answers: [
      { id: 1, text: "Prancer", isCorrect: false },
      { id: 2, text: "Rudolph", isCorrect: true },
      { id: 3, text: "Comet", isCorrect: false }
    ]
  },
  {
    id: 6,
    img: q6,
    question: "Which of these songs is not a Christmas tune?",
    clue: "I’m round in shape, I have a pointy roof and bands play their songs on my stage.",
    answers: [
      { id: 1, text: "Baby Shark", isCorrect: true },
      { id: 2, text: "Jingle Bells", isCorrect: false },
      { id: 3, text: "Deck the Halls", isCorrect: false }
    ]
  }
];

const Location = () => {
  const [showAlert, setShowAlert] = useState(false);
  const [question, setQuestion] = useState(null);
  const [showQuestion, setShowQuestion] = useState(false);
  const params = useParams();

  const navigate = useNavigate();

  const dispatch = useDispatch();

  const { device, locationUpdated } = useSelector((state) => state.ascot);

  useEffect(() => {
    if (!device) {
      navigate(`/ascot-family-day/${params.serial}`);
    }
  }, [device, navigate, params.serial]);

  useEffect(() => {
    if (device?.location && device?.path.length > 0) {
      setQuestion(mockedQuestions[device.path[device.location - 1] - 1]);
    }
  }, [device?.location, device?.path]);

  useEffect(() => {
    if (locationUpdated) {
      navigate(`/ascot-family-day/${params.serial}/progress`);
    }

    return () => {
      dispatch(resetLocationUpdate());
    };
  }, [dispatch, locationUpdated, navigate, params.serial]);

  const handleCorrectAnswer = () => {
    dispatch(
      updateLocation({
        serial: params.serial,
        location: device.location + 1
      })
    );
  };

  return (
    <>
      <MainContainer>
        <Header>
          <PlayerBadge
            playerName={device?.playerName || ""}
            teamName={device?.groupName || ""}
          />
        </Header>

        {question && (
          <Content>
            <img src={question.img} alt="elf" />
            <Box>
              <h2>{`${device.location}/6`}</h2>
            </Box>
            <Box>
              <p>
                <b>Clue: </b>
                {`${question.clue} Go to the location then reveal your question.`}
              </p>
            </Box>
            {!showQuestion && (
              <div style={{ padding: "0 16px" }}>
                <ActionButton onClick={() => setShowQuestion(true)}>
                  REVEAL QUESTION
                </ActionButton>
              </div>
            )}
            {showQuestion && (
              <Box>
                <p style={{ marginBottom: "20px" }}>
                  <strong>Question:</strong> {question.question}
                </p>
                <Questions>
                  {question.answers.map((e) => (
                    <Question
                      onClick={() => {
                        if (e.isCorrect) {
                          handleCorrectAnswer();
                        } else {
                          window.scrollTo({
                            top: 0,
                            left: 0,
                            behavior: "auto"
                          });
                          setShowAlert(true);
                        }
                      }}
                      key={e.id}
                    >
                      {e.text}
                    </Question>
                  ))}
                </Questions>
              </Box>
            )}
          </Content>
        )}
        {showAlert && (
          <Alert
            title="OOPS!"
            subtitle="Not quite, try a different answer."
            onClose={() => setShowAlert(false)}
            buttonLabel="TRY AGAIN"
          />
        )}
      </MainContainer>
    </>
  );
};

export default Location;
