import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import entityService from "./entityService";

const initialState = {
  entities: [],
  entity: null,
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: ""
};

// get public entities
export const getEntities = createAsyncThunk(
  "entities/getAllEntities",
  async (_, thunkAPI) => {
    try {
      const response = await entityService.getEntities();

      return response;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getEntityById = createAsyncThunk(
  "entities/getEntityById",
  async (brand, thunkAPI) => {
    try {
      return await entityService.getEntityById(brand);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const entitySlice = createSlice({
  name: "entity",
  initialState,
  reducers: {
    reset: (state) => initialState,
    resetEntity: (state) => {
      state.entity = null;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getEntities.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getEntities.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.entities = action.payload.filter((e) => !e.excludedFromWebsite);
      })
      .addCase(getEntities.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(getEntityById.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getEntityById.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.entity = action.payload;
      })
      .addCase(getEntityById.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      });
  }
});

export const { reset, resetEntity } = entitySlice.actions;
export default entitySlice.reducer;
