import styled from "styled-components";

const B1 = {
  fontFamily: "Manrope",
  fontStyle: "normal",
  fontWeight: 400,
  fontSize: "16px",
  lineHeight: "24px",
  letterSpacing: "0.5px",
  color: "#CCCCCC"
};

const B2 = {
  fontFamily: "Manrope",
  fontStyle: "normal",
  fontWeight: 400,
  fontSize: "14px",
  lineHeight: "24px",
  letterSpacing: "0.25px",
  color: "#D8D9D9"
};

const B3 = {
  fontFamily: "Manrope",
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: "12px",
  lineHeight: "16px",
  letterSpacing: "0.4px",
  color: "#202020"
};

const B4 = {
  //button
  fontFamily: "Manrope",
  fontStyle: "normal",
  fontWeight: 700,
  fontSize: "16px",
  lineHeight: "24px",
  color: "#FFFFFF"
};

const H1 = {
  fontFamily: "Manrope",
  fontStyle: "normal",
  fontWeight: 600,
  fontSize: "57px",
  lineHeight: "64px",
  color: "#FFFFFF",
  leadingTrim: "both",
  textEdge: "cap"
};

const H2 = {
  fontFamily: "Manrope",
  fontStyle: "normal",
  fontWeight: 600,
  fontSize: "32px",
  lineHeight: "44px",
  color: "#FFFFFF"
};

const H3 = {
  fontFamily: "Manrope",
  fontStyle: "normal",
  fontWeight: 600,
  fontSize: "16px",
  lineHeight: "24px",
  color: "#FFFFFF",
  letterSpacing: "0.15px"
};

export const NavBar = styled.div`
  padding: 16px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: #282827;
  ${H3}
  img {
    height: 40px;
  }

  .view-balance {
    cursor: pointer;
  }
`;

export const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 480px;
  margin: auto;
  min-height: 100vh;
  background-color: #000000;

  .or {
    ${B1}
    text-align: center;
    margin-bottom: 16px;
  }
`;

export const Welcome = styled.div`
  display: flex;
  flex-direction: column;
  padding: 32px 24px;
  gap: 16px;
  text-align: left;
  background: #2a2a2a;
  margin-bottom: 16px;

  h1 {
    ${H2}
  }
  p {
    ${B1}
  }
`;

export const SectionTitle = styled.h2`
  margin: 16px 24px;
  ${H2}
`;

export const Step = styled.div`
  display: flex;
  flex-direction: row;
  gap: 16px;
  justify-content: flex-start;

  .order {
    ${H1}
  }
  .details {
    display: flex;
    flex-direction: column;
  }

  p {
    ${B1}
  }
  .title {
    color: white;
    margin-bottom: 8px;
  }
`;

export const SectionContainer = styled.section`
  padding: 24px 16px;
  background-color: #282827;
  margin: 0px 16px 16px;
  border-radius: 6px;
`;

export const PaymentAccount = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;

  .bb-logo {
    height: 16px;
  }

  h2 {
    ${H2}
  }

  p {
    ${B1}
  }

  .activation-code {
    padding: 12px 16px;
    background-color: #3d3d3c;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    img {
      cursor: pointer;
    }
  }
  .button {
    ${B2}
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 16px 40px;
    cursor: pointer;
    background: linear-gradient(90deg, #d8873a 0%, #895726 100%);
    border-radius: 6px;
    width: 100%;
    color: white;
    margin-bottom: 16px;
  }

  .stores {
    cursor: pointer;
    display: flex;
    flex-direction: row;
    gap: 8px;
  }

  .line {
    border: 1px solid #e0e0e0;
    width: 100%;
    height: 1px;
  }

  .or-separator {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    p {
      margin: 0px 16px;
    }
  }
`;

export const Footer = styled.div`
  background-color: #282827;
  margin-top: 8px;
  padding: 24px 16px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  justify-content: center;
  align-items: center;

  p {
    ${B4}
  }

  img {
    height: 24px;
    margin-bottom: 8px;
    cursor: pointer;
  }
`;

export const InfoAlert = styled.div`
  max-width: 480px;
  position: absolute;
  background: rgba(9, 10, 15, 0.64);
  height: 100%;
  width: 100%;
  text-align: center;

  .inner-container {
    position: fixed;
    max-width: 360px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 32px 24px;
    background-color: white;
    border-radius: 8px;
    width: 90%;
    z-index: 9999;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .close-button {
      width: 32px;
      height: 32px;
      align-self: flex-end;
      cursor: pointer;
    }

    h2 {
      ${H2};
      color: #202020;
      margin-bottom: 8px;
    }

    p {
      ${B1}
      color: #202020;
      margin-bottom: 24px;
    }

    .terms {
      ${B3}
      margin-bottom: 24px;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      /* align-items: center; */
      text-align: left;

      img {
        margin-right: 10px;
        cursor: pointer;
      }

      a {
        text-decoration: underline;
      }

      p {
        margin-bottom: 0;
        ${B3}
      }
    }

    .input-container {
      padding: 0 8px 8px;
      margin-bottom: 24px;
      border-bottom: 2px solid #b47131;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      label {
        margin-bottom: 8px;
        ${B1}
        color:#B47131
      }
    }

    .input-error {
      border: 1px solid red;
    }

    input {
      ${B1}
      border: none;
      width: 100%;
      background-color: inherit;
      color: #202020;
    }

    input:focus {
      outline: none;
    }

    .error-msg {
      ${B3}
      text-align: left;
      color: red;
      margin-top: -8px;
      margin-bottom: 16px;
    }

    .button {
      ${B4}
      width: 100%;
      background: linear-gradient(90deg, #d8873a 0%, #895726 100%);
      border-radius: 6px;
      cursor: pointer;
      color: white;
      padding: 16px 0;
    }

    .button-secondary {
      margin-top: 16px;
      background: #ffffff;
      border-radius: 6px;
      border: 2px solid #895726;
      color: #b47131;
    }
  }
`;
