import React from "react";

import { H2, B2, B1, ContentContainer, TextWithClickableLink, ClickableLink } from "./styles/SharedStyles";
import {
  StatusesContainer,
  Status,
  Button,
} from "./styles/DeviceStatus";

import checkIcon from "./img/check-icon.svg";
import nextIcon from "./img/next-icon.svg";

const DeviceStatus = ({ serialNumber, status, checkAnotherBin }) => {
  const registrationCompleted =
    status === "REGISTERED" ||
    status === "PERSONALISED" ||
    status === "ACTIVATED";

  const personalisationCompleted =
    status === "PERSONALISED" || status === "ACTIVATED";
  const activationCompleted = status === "ACTIVATED";

  return (
    <ContentContainer>
      <H2>Almost There!</H2>
      <StatusesContainer>
        <Status completed={registrationCompleted}>
          <B1>1. Register card</B1>
          {registrationCompleted && <img src={checkIcon} alt="check icon" />}
        </Status>
        <Status completed={personalisationCompleted}>
          <B1>2. Connect card</B1>
          {personalisationCompleted && <img src={checkIcon} alt="check icon" />}
        </Status>
        <Status completed={activationCompleted}>
          <B1>3. Activate payments</B1>
          {activationCompleted && <img src={checkIcon} alt="check icon" />}
        </Status>
        <B2>Resume payment card setup to enable contactless payments.</B2>
      </StatusesContainer>
      <Button
        onClick={() => {
          window.location.assign(
            " managemii://wearables/tap2start?serialNumber=" + serialNumber.toLowerCase()
          );
        }}
      >
        Resume setup
        <img src={nextIcon} alt="next icon" />
      </Button>
      <TextWithClickableLink>
          Want to add another payment card?{" "}
          <ClickableLink onClick={() => checkAnotherBin()}>
            check compatibility
          </ClickableLink>
        </TextWithClickableLink>
    </ContentContainer>
  );
};

export default DeviceStatus;
