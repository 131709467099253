import React, { useState } from "react";
import { H2, SectionContainer } from "./styled/ChesterRacecourse";

import chevronDown from "./img/chevron-down.svg";
import chevronUp from "./img/chevron-up.svg";

const ExpandingContainer = ({ title, children }) => {
  const [expanded, setExpanded] = useState(true);
  return (
    <SectionContainer>
      <div className="title-bar" onClick={() => setExpanded(!expanded)}>
        <H2>{title}</H2>
        <img
          src={expanded ? chevronUp : chevronDown}
          alt={`chevron ${expanded ? "up" : "down"}`}
        />
      </div>
      <div className="content">{expanded && children}</div>
    </SectionContainer>
  );
};

export default ExpandingContainer;
