import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { composeErrorMessage } from "../../utils/utils";
import cagedSteelService from "./cagedSteelService";

const initialState = {
  feedbackForm: null,
  votingOptions: null,
  gettingVotingOptions: false,
  errorGettingVotingOptions: "",
  submittingVote: false,
  errorSubmittingVote: "",
  isError: false,
  voteData: null,
  voteSubmitted: false,
  feedbackFormSubmited: false,
  isLoading: false,
  message: ""
};
export const getVotingOptions = createAsyncThunk(
  "cagedSteel/getVotingOptions",
  async (serial, thunkAPI) => {
    try {
      const response = await cagedSteelService.getVotingOptions(serial);

      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(composeErrorMessage(error));
    }
  }
);

export const getFeedbackForm = createAsyncThunk(
  "cagedSteel/getFeedbackForm",
  async (serial, thunkAPI) => {
    try {
      return await cagedSteelService.getFeedbackForm(serial);
    } catch (error) {
      return thunkAPI.rejectWithValue(composeErrorMessage(error));
    }
  }
);

export const submitVote = createAsyncThunk(
  "cagedSteel/submitVote",
  async (payload, thunkAPI) => {
    try {
      return await cagedSteelService.submitVote(payload);
    } catch (error) {
      return thunkAPI.rejectWithValue(composeErrorMessage(error));
    }
  }
);

export const submitFeedbackForm = createAsyncThunk(
  "cagedSteel/submitFeedbackForm",
  async (payload, thunkAPI) => {
    try {
      return await cagedSteelService.submitFeedbackForm(payload);
    } catch (error) {
      return thunkAPI.rejectWithValue(composeErrorMessage(error));
    }
  }
);

export const cagedSteelSlice = createSlice({
  name: "cagedSteel",
  initialState,
  reducers: {
    reset: (state) => initialState,
    resetVotingOptions: (state) => {
      state.votingOptions = null;
    },
    resetFeedbackForm: (state) => {
      state.feedbackForm = null;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getVotingOptions.pending, (state) => {
        state.gettingVotingOptions = true;
        state.errorGettingVotingOptions = "";
      })
      .addCase(getVotingOptions.fulfilled, (state, action) => {
        state.gettingVotingOptions = false;
        state.votingOptions = action.payload;

        if (action.payload?.votingResult?.position) {
          state.voteSubmitted = true;
        }
      })
      .addCase(getVotingOptions.rejected, (state, action) => {
        state.gettingVotingOptions = false;
        state.errorGettingVotingOptions = action.payload;
      })
      .addCase(getFeedbackForm.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getFeedbackForm.fulfilled, (state, action) => {
        state.isLoading = false;
        state.feedbackForm = action.payload;
      })
      .addCase(getFeedbackForm.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(submitVote.pending, (state, action) => {
        state.submittingVote = true;
        state.errorSubmittingVote = "";
        state.voteData = action.meta.arg.vote;
      })
      .addCase(submitVote.fulfilled, (state, action) => {
        state.submittingVote = false;
        state.voteSubmitted = true;
      })
      .addCase(submitVote.rejected, (state, action) => {
        state.submittingVote = false;
        state.errorSubmittingVote = action.payload;
      })
      .addCase(submitFeedbackForm.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(submitFeedbackForm.fulfilled, (state, action) => {
        state.isLoading = false;
        state.voteSubmitted = true;
        state.feedbackFormSubmited = true;
      })
      .addCase(submitFeedbackForm.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      });
  }
});

export const { reset, resetVotingOptions, resetFeedbackForm } =
  cagedSteelSlice.actions;
export default cagedSteelSlice.reducer;
