import styled from "styled-components";

export const LoopLinks = styled.div`
  display: flex;
  justify-content: space-between;
  background: #ffffff;
  border-radius: 12px;
  height: 54px;
  align-items: center;
  padding: 16px;
  margin-bottom: 32px;

  img {
    cursor: pointer;
  }
`;

export const LoopLinksTitle = styled.div`
  font-size: 1.125rem;
  color: #1d1d1f;
`;

export const LinkText = styled.div`
  font-size: 14px;
  margin-bottom: 4px;
`;

export const CampaignsContainer = styled.div`
  background: #f2f2f5;
  border-radius: 10px;
  margin-bottom: 10px;
`;

export const LinksContainer = styled.div`
  margin-bottom: 16px;
`;

export const Campaign = styled.div`
  border-radius: 12px;
  background: #ffffff;
  padding: 15px;
  color: #1d1d1f;
  font-size: 1.125rem;
  font-weight: 500;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  margin-bottom: 8px;
`;

export const CampaignLinkContainer = styled.div`
  display: flex;
`;

export const StatusIcon = styled.img`
  margin-right: 16px;
`;

export const LinkDetails = styled.div`
  color: #8d8e99;
  font-size: 1.125rem;
  display: flex;
  align-items: center;
`;

export const TransitionIcon = styled.img`
  margin-right: 6px;
`;
