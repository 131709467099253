import styled from "styled-components";
import { InputContainer } from "../../../components/styled/Input";

export const StyledContainer = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #ffffff;
  border: 0.5px solid #aeb1b8;
  border-radius: 12px;
  padding: 40px 0;
  margin: 60px 20px;
  width: 330px;

  @media (min-width: 758px) {
    width: 650px;
  }

  h2 {
    font-size: 2rem;
    font-weight: 500;
  }

  ${InputContainer} {
    margin-bottom: 20px;
  }
`;
