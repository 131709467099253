import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";

import { getBalanceAndPin } from "../../features/balance-and-pin/balanceAndPinSlice";
import { formattedBalance } from "../../features/balance-and-pin/utils";
import { getDeviceByModel } from "../../features/devices/deviceSlice";
import { getDasDeviceInfo } from "../../features/das-device-manager/dasDeviceManagerSlice";

import Spinner from "../../components/Spinner";
import {
  Footer,
  MainContainer,
  PaymentAccount,
  ViewBalance,
  Wearable,
  Intro
} from "./styles";

import mastercardLogo from "./img/mastercard-logo.svg";
import wearable from "./img/wearable.png";
import chevronRight from "./img/chevron-right.svg";
import appStoreLogo from "./img/app-store.svg";
import gplayLogo from "./img/play-store.svg";
import digiseqLogo from "./img/digiseq-logo.png";
import muchBetterLogo from "./img/muchbetter-logo.svg";

const TasteOfDublin = () => {
  const [searchParams] = useSearchParams();
  const serialNumber = searchParams.get("serialNumber");
  const dispatch = useDispatch();

  const { balanceAndPin, isLoading } = useSelector(
    (state) => state.balanceAndPin
  );

  const { device: discoveryDevice, isLoading: isLoadingDevices } = useSelector(
    (state) => state.devices
  );

  const { dasDeviceInfo, gettingDasDeviceInfo } = useSelector(
    (state) => state.dasDevices
  );

  useEffect(() => {
    if (!dasDeviceInfo) {
      dispatch(getDasDeviceInfo(serialNumber));
    }
  }, [dasDeviceInfo, dispatch, serialNumber]);

  useEffect(() => {
    if (
      dasDeviceInfo?.model?.model &&
      dasDeviceInfo?.model?.brand &&
      dasDeviceInfo?.model?.version
    ) {
      dispatch(
        getDeviceByModel({
          brand: dasDeviceInfo.model.brand,
          model: dasDeviceInfo.model.model,
          version: dasDeviceInfo.model.version
        })
      );
    }
  }, [
    dasDeviceInfo?.model?.brand,
    dasDeviceInfo?.model?.model,
    dasDeviceInfo?.model?.version,
    dispatch
  ]);

  useEffect(() => {
    if (!balanceAndPin && serialNumber) {
      dispatch(
        getBalanceAndPin({
          serial: serialNumber,
          query: "?provider=much_better"
        })
      );
    }
  }, [balanceAndPin, serialNumber, dispatch]);

  const getDeviceImage = () => {
    if (
      discoveryDevice?.variants?.length > 0 &&
      dasDeviceInfo?.model?.variant?.color
    ) {
      const deviceVariant = discoveryDevice.variants.find(
        (variant) => variant.color === dasDeviceInfo.model.variant.color
      );

      if (deviceVariant?.image) {
        return deviceVariant.image;
      }
    } else if (discoveryDevice?.defaultImage) {
      return discoveryDevice.defaultImage;
    }

    return null;
  };

  if (isLoading || isLoadingDevices || gettingDasDeviceInfo) return <Spinner />;

  return (
    <>
      <MainContainer>
        <Intro>
          <img src={mastercardLogo} alt="mastercard-logo" />
          <>
            <h1>Welcome to Taste of Dublin festival 2024! </h1>
            <div>
              <p>{`We hope you enjoy an evening tasting the highlights of Ireland’s top culinary talent and soak up the gourmet atmosphere of summer’s finest food and drink festival.`}</p>
              <p>{`Please use our Mastercard seating area to relax and don't forget to tag @mastercardireland if you post about your night.`}</p>
              <p>{`Tap, taste and enjoy!`}</p>
            </div>
          </>
        </Intro>
        <ViewBalance>
          <div className="balance-container">
            <p>My Balance</p>
            <div className="balance">
              {balanceAndPin?.balanceDetails?.availableBalance
                ? formattedBalance(
                    balanceAndPin?.balanceDetails?.availableBalance,
                    balanceAndPin?.balanceDetails?.currency,
                    true
                  )
                : "N/A"}
            </div>
          </div>
        </ViewBalance>
        <Wearable className="wearable">
          <img src={getDeviceImage() || wearable} alt="wearable" />
        </Wearable>

        <PaymentAccount>
          <h2>{"Payment Account"}</h2>
          <p>
            Want to continue using this keyfob? Please click below to upgrade to
            a dedicated wallet
          </p>
          <div
            className="button"
            onClick={() =>
              window.open(`https://get.muchbetter.com/digiseq-cl`, "_blank")
            }
          >
            {"Learn more"} <img src={chevronRight} alt="chevron right" />
          </div>

          <div className="or-separator">
            <span className="line"></span>
            <p>or</p>
            <span className="line"></span>
          </div>

          <h2>Link your card using the Manage-Mii App</h2>
          <p>
            Follow the instructions on the Manage-Mii app and use your card
            details when prompted.
          </p>
          <div className="stores">
            <img
              src={appStoreLogo}
              alt="app store logo"
              onClick={() =>
                window.open(
                  "https://apps.apple.com/gb/app/manage-mii/id6446908503",
                  "_blank"
                )
              }
            />
            <img
              src={gplayLogo}
              alt="google play logo"
              onClick={() =>
                window.open(
                  "https://play.google.com/store/apps/details?id=com.digiseq.managemii",
                  "_blank"
                )
              }
            />
          </div>
        </PaymentAccount>

        <Footer>
          <p>Powered by</p>
          <img
            src={digiseqLogo}
            alt="digiseq-logo"
            onClick={() => window.open("https://www.digiseq.co.uk", "_blank")}
          />
          <p>Payment services provided by</p>
          <img
            src={muchBetterLogo}
            alt="much-better-logo"
            onClick={() => window.open("https://muchbetter.com/", "_blank")}
          />
        </Footer>
      </MainContainer>
    </>
  );
};

export default TasteOfDublin;
