import styled from "styled-components";
import mixins from "../../styled/mixins";

export const MainContainer = styled.div`
  width: 100%;
  background: #ffffff;
  border-radius: 12px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  margin-bottom: 16px;
  height: 196px;
  overflow: auto;
`;

export const Option = styled.div`
  margin-left: 16px;
  padding: 16px 0;
  ${mixins.B1}
  color: ${({ disabled }) => (disabled ? "#A2A6B8" : "#303033")};
  color: ${({ isActionButton }) => isActionButton && "#FE7E45"};
  cursor: ${({ disabled }) => (disabled ? "default" : "pointer")};
  border-bottom: ${({ isLast }) => (isLast ? "none" : "2px solid #f7f8fa")};
`;
