import React from "react";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";

import {
  MainContainer,
  Title,
  // SearchBar,
  // SearchIcon,
  // SearchInput,
  Subtitle,
  ListContainer,
  ListItem,
  IconContainer,
  Divider
} from "./styles";
// import searchIcon from "../../assets/faqs-icons/search.svg";
import accountIcon from "../../assets/faqs-icons/account.svg";
import chevronRightIcon from "../../assets/faqs-icons/chevron-right.svg";
import settingsIcon from "../../assets/faqs-icons/settings.svg";
import paymentIcon from "../../assets/faqs-icons/payment.svg";
import wearableIcon from "../../assets/faqs-icons/wearable.svg";

const Faqs = () => {
  const { t } = useTranslation("faqs");
  const navigate = useNavigate();

  const topics = t("faqs:topics", { returnObjects: true });
  const mostAsked = t("faqs:mostAsked", { returnObjects: true });

  useEffect(() => {
    const script = document.createElement("script");
    script.src = "//eu.fw-cdn.com/12857626/826527.js";
    script.async = true;
    script.setAttribute("chat", "true");
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  const topicIcons = {
    account: accountIcon,
    informationSettings: settingsIcon,
    paymentsOtherServices: paymentIcon,
    wearableDevices: wearableIcon
  };

  return (
    <MainContainer>
      <Title>{t("howCanWeHelp")}</Title>
      {/* <SearchBar>
        <SearchIcon src={searchIcon} />
        <SearchInput
          placeholder="Search or ask us something"
          onChange={(e) => console.log(e.target.value)}
        />
      </SearchBar> */}
      <Subtitle>{t("helpTopics")}</Subtitle>

      <ListContainer>
        {Object.keys(topics).map((topic, index) => (
          <ListItem key={topic} onClick={() => navigate(`/faqs/${topic}`)}>
            <IconContainer>
              <img src={topicIcons[topic]} alt={topic} />
            </IconContainer>
            <div className="item-content">
              <p>{topics[topic]}</p>
              <img src={chevronRightIcon} alt="chevron right" />
              {index < Object.keys(topics).length - 1 && <Divider />}
            </div>
          </ListItem>
        ))}
      </ListContainer>

      <Subtitle>{t("frequentlyAsked")}</Subtitle>
      <ListContainer>
        {Object.keys(mostAsked).map((topic, index) => (
          <ListItem
            key={topic}
            minHeight={60}
            onClick={() => navigate(`/faqs/mostAsked/${topic}/${index}`)}
          >
            <div className="item-content">
              <p>{mostAsked[topic].question}</p>
              <img src={chevronRightIcon} alt="chevron right" />
              {index < Object.keys(mostAsked).length - 1 && <Divider />}
            </div>
          </ListItem>
        ))}
      </ListContainer>
    </MainContainer>
  );
};

export default Faqs;
