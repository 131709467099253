import styled from "styled-components";

export const MainContainer = styled.section`
  width: 100%;
  padding: 0 60px;
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  margin-bottom: 80px;
`;

export const FilterSide = styled.div`
  h2 {
    font-style: normal;
    font-weight: 600;
    font-size: 4.375rem;
    line-height: 4.375rem;
    max-width: 75%;
    margin-bottom: 30px;
  }
`;

export const ImageSide = styled.div`
  aspect-ratio: 1;
`;

export const ImageContainer = styled.div`
  width: 100%;
  height: 100%;
`;

export const BrandImage = styled.img`
  aspect-ratio: 1;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 20px;
`;
