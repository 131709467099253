import styled from "styled-components";
import mixins from "./mixins";

function selectBackground({ corner, player1, player2, playersBg }) {
  console.log({ corner });
  switch (corner) {
    case "blue":
      return player1;
    case "red":
      return player2;
    case "both":
      return playersBg;
    default:
      return null;
  }
}

export const MultiplePlayersEvent = styled.div`
  padding: 32px 16px;

  .title {
    color: #fff;
    font-feature-settings: "clig" off, "liga" off;
    /* H1 */
    font-family: Oswald;
    font-size: 40px;
    font-style: normal;
    font-weight: 600;
    line-height: 48px; /* 120% */
    margin-bottom: 16px;
  }
`;

export const Banner = styled.div`
  aspect-ratio: 1;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url(${(props) => selectBackground(props)});
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  padding-bottom: 24px;

  position: relative;

  h1 {
    position: absolute;
    text-align: center;
    bottom: ${({ corner }) =>
      corner === "red" || corner === "blue" ? null : "40px"};
    bottom: ${({ corner }) =>
      corner === "red" || corner === "blue" ? "24px" : null};
  }
`;

export const Fighters = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  padding: 24px 16px;
`;

export const FightCards = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px;
  gap: 8px;

  .card {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border-bottom: 1px solid white;
  }

  .no-border {
    border-bottom: none;
    margin-bottom: -16px;
  }

  .vs {
    color: #999;
  }
  p,
  .card {
    ${mixins.B1}
  }
  .intro {
    display: flex;
    flex-direction: column;
    gap: 16px;
    margin-bottom: 16px;
  }
`;

export const FightersCol = styled.div`
  display: flex;
  flex-direction: column;
  align-items: ${({ align }) => align || "center"};
  color: red;
  ${mixins.B1}
  /* identical to box height, or 150% */
  color: #999999;
`;

export const CornerBadge = styled.div`
  ${mixins.B1}
  background-color: ${({ first }) => (first ? "#EB5C2A" : "white")};
  color: #242424;
  padding: 0 8px;
  margin-bottom: 25px;
  text-transform: capitalize;
`;

export const DateBox = styled.div`
  color: white;
  border: 2px solid #999999;
  padding: 8px 0px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  line-height: 24px;
  margin-bottom: 32px;
`;

export const NameBadge = styled.div`
  display: flex;
  flex-direction: column;
  ${mixins.B1}
  font-size: 18px;
  color: #999999;

  & div:nth-child(2) {
    color: white;
    text-transform: uppercase;
  }
`;

export const RegistrationContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-row-gap: 8px;
  margin-bottom: 16px;
  p {
    ${mixins.B1}
  }
`;

export const FeedbackPage = styled.div``;

// curve

export const NextStepsContainer = styled.div`
  padding: 32px 16px 16px;
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const CurvelogoContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  .separator {
    height: 25px;
    object-fit: cover;
    width: 100%;
  }
  .title {
    ${mixins.H2}
    text-align: left;
  }

  .logos {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 20px;
    .flag {
      height: 26px;
    }
  }

  .curve-logo {
    height: 32px;
    width: 152px;
  }
`;

export const StepContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  h2 {
    ${mixins.H2}
    text-align: left;
    color: rgba(255, 255, 255, 0.92);
  }
  p {
    ${mixins.B1}
  }
  .pay-anywhere {
    margin: 24px auto 0;
    width: 100%;
    object-fit: contain;
    border-radius: 8px;
  }
`;

export const StepNumber = styled.div`
  display: flex;
  width: 40px;
  padding: 8px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 360px;
  border: 1px solid white;
  color: white;
`;

export const AppStoreLinks = styled.section`
  img {
    cursor: pointer;
    margin-right: 16px;
  }
`;
