import styled from "styled-components";
import { Button } from "./styled/SharedStyles";

const AlertContainer = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  width: 90%;
  max-width: 480px;
  transform: translate(-50%, -50%);
  background-color: #fff;
  border: 1px solid #ccc;
  padding: 32px;
  border-radius: 12px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  gap: 16px;
  p {
    text-align: center;
  }
`;

const Alert = ({ title, message, onConfirm, onCancel }) => {
  return (
    <AlertContainer>
      <p>{title}</p>
      <p>{message}</p>
      <div style={{ display: "flex", flexDirection: "row", gap: "16px" }}>
        <Button isSecondary onClick={onConfirm}>
          Confirm
        </Button>
        <Button onClick={onCancel}>Cancel</Button>
      </div>
    </AlertContainer>
  );
};

export default Alert;
