import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { composeErrorMessage } from "../../utils/utils";
import worlds24Service from "./worlds24Service";
import { updateLocalDasDeviceInfo } from "../das-device-manager/dasDeviceManagerSlice";

const initialState = {
  collectingItem: false,
  collectingItemError: ""
};

export const collectItem = createAsyncThunk(
  "worlds24/collectItem",
  async ({ serial, itemId }, thunkAPI) => {
    try {
      const response = await worlds24Service.collectItem(serial, itemId);
      thunkAPI.dispatch(updateLocalDasDeviceInfo(response));
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(composeErrorMessage(error));
    }
  }
);

export const worlds24Slice = createSlice({
  name: "worlds24",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(collectItem.pending, (state) => {
        state.collectingItem = true;
        state.collectingItemError = "";
      })
      .addCase(collectItem.fulfilled, (state) => {
        state.collectingItem = false;
      })
      .addCase(collectItem.rejected, (state, action) => {
        state.collectingItem = false;
        state.collectingItemError = action.payload;
      });
  }
});

export default worlds24Slice.reducer;
