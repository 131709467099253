import { useSearchParams } from "react-router-dom";
import {
  AppStoreLinks,
  CurvelogoContainer,
  Footer,
  MainContainer,
  NextStepsContainer,
  PaymentAccount,
  StepContainer,
  StepNumber,
  Separator
} from "./styles";

import chevronRight from "./img/chevron-right.svg";
import appStoreLogo from "./img/app-store.svg";
import gplayLogo from "./img/play-store.svg";
import curveLogo from "./img/curve_logo.png";
import euFlag from "./img/eu-flag.png";
import ukFlag from "./img/uk-flag.png";
import orSeparator from "./img/or.svg";
import digiseqLogo from "./img/digiseq-logo.png";
import copyToClipboard from "./img/copy-icon.svg";

const MBPaymentAccount = () => {
  const [searchParams] = useSearchParams();
  const serialNumber = searchParams.get("serialNumber");

  const appStoreLinks = (appStoreUrl, playStoreUrl) => {
    return (
      <AppStoreLinks>
        <img
          src={appStoreLogo}
          alt="app-store"
          onClick={() => window.open(appStoreUrl, "_blank")}
        />
        <img
          src={gplayLogo}
          alt="play-store"
          onClick={() => window.open(playStoreUrl, "_blank")}
        />
      </AppStoreLinks>
    );
  };

  return (
    <MainContainer>
      <PaymentAccount>
        <p className="title">Payment Account</p>

        <div className="inner">
          <p>
            Set up this tag for payments by choosing one of the following
            options:
          </p>
          <p className="subtitle">Prepaid</p>
          <p>To set up this tag for contactless payments please click below</p>
          <div
            className="button"
            onClick={() =>
              window.open(`https://get.muchbetter.com/mastercard/`, "_blank")
            }
          >
            {"Learn More"} <img src={chevronRight} alt="chevron right" />
          </div>
        </div>
      </PaymentAccount>
      <NextStepsContainer>
        <CurvelogoContainer>
          <img className="separator" src={orSeparator} alt="or" />
          <p className="subtitle">Link your card</p>
          <img className="curve-logo" src={curveLogo} alt="curve logo" />
          <div className="flags">
            <img src={euFlag} alt="eu flag" />{" "}
            <img src={ukFlag} alt="uk flag" />
          </div>
        </CurvelogoContainer>
        <StepContainer>
          <StepNumber>1</StepNumber>
          <h2>Download the Curve App</h2>
          <p>
            And link your preferred payment card to your new Curve account. 
          </p>

          {appStoreLinks(
            "https://apps.apple.com/gb/app/curve-supercharge-your-money/id1049397112",
            "https://play.google.com/store/apps/details?id=com.imaginecurve.curve.prd"
          )}
        </StepContainer>
        <StepContainer>
          <StepNumber>2</StepNumber>
          <h2>Download the Manage-Mii App</h2>
          <p>Link your Curve card to enable contactless payments.</p>
          <p>
            Set up a contactless business card and share your details with a
            tap!
          </p>
          {appStoreLinks(
            "https://apps.apple.com/app/manage-mii/id6446908503",
            "https://play.google.com/store/apps/details?id=com.digiseq.managemii"
          )}
        </StepContainer>
      </NextStepsContainer>
      <Separator isDark />

      <Footer>
        <div className="copyToClipboard">
          <img
            onClick={() => navigator.clipboard.writeText(serialNumber)}
            src={copyToClipboard}
            alt="copy to clipboard"
          />
          <p>ID: {serialNumber}</p>
        </div>
        <p>Powered by</p>
        <img
          src={digiseqLogo}
          alt="digiseq-logo"
          onClick={() => window.open("https://www.digiseq.co.uk", "_blank")}
        />
      </Footer>
    </MainContainer>
  );
};

export default MBPaymentAccount;
