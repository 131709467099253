import styled from "styled-components";

const B1 = {
  fontFamily: "Mark For MC Narrow W00 Book",
  fontStyle: "normal",
  fontWeight: 450,
  fontSize: "16px",
  lineHeight: "24px",
  letterSpacing: "0.5px",
  color: "#CCCCCC"
};

const B2 = {
  //bold/button
  fontFamily: "Mark For MC Narrow W00 Bold",
  fontStyle: "normal",
  fontWeight: 700,
  fontSize: "16px",
  lineHeight: "24px",
  letterSpacing: "0.25px",
  color: "#ffffff"
};

const B3 = {
  fontFamily: "Mark For MC Narrow W00 Bold",
  fontStyle: "normal",
  fontWeight: 700,
  fontSize: "14px",
  lineHeight: "24px",
  letterSpacing: "0.5px",
  color: "#ffffff"
};

const B4 = {
  //footer text
  fontFamily: "Mark For MC Narrow W00 Book",
  fontStyle: "normal",
  fontWeight: 450,
  fontSize: "12px",
  lineHeight: "24px",
  letterSpacing: "0.5px",
  color: "#FFFFFF"
};

const H1 = {
  fontFamily: "Mark For MC Narrow W00 Book",
  fontStyle: "normal",
  fontWeight: 450,
  fontSize: "57px",
  lineHeight: "64px",
  color: "#000000"
};

const H2 = {
  fontFamily: "Mark For MC Narrow W00 Book",
  fontStyle: "normal",
  fontWeight: 450,
  fontSize: "28px",
  lineHeight: "32px",
  color: "#FFFFFF"
};

export const NavBar = styled.div`
  color: white;
  padding: 8px 16px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: #282827;
  ${B1}
  img {
    height: 40px;
  }

  .view-balance {
    padding: 4px 8px;
    cursor: pointer;
  }
`;

export const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 480px;
  margin: auto;
  min-height: 100vh;
  background-color: #000000;
  padding-top: 24px;

  .can-donate {
    ${B1}
    color: white;
    text-align: center;
  }

  .pitches-banner {
    position: relative;

    .banner-img {
      width: 100%;
    }
    .back-icon {
      position: absolute;
      top: 16px;
      left: 16px;
      cursor: pointer;
    }
  }
`;

export const PitchesIntro = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 16px 24px;

  h2 {
    ${H2}
    margin-bottom: 16px;
  }

  p {
    ${B1}
    text-align: center;
    margin-bottom: 24px;
  }

  img {
    width: 70px;
    height: 70px;
    margin: auto;
  }
`;

export const HowToDonate = styled.div`
  background: #282827;
  border-radius: 6px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 16px;
  gap: 16px;
  margin: 16px;

  img {
    width: 40px;
    height: 40px;
  }

  .details {
    flex-direction: column;
    p {
      ${B1}
    }

    .title {
      color: white;
      margin-bottom: 4px;
    }
  }
`;

export const SectionContainer = styled.section`
  padding: 24px 16px;
  background-color: #282827;
  margin: 0px 16px 16px;
  border-radius: 6px;

  h2 {
    ${H2}
    color: white;
  }
`;

export const ViewBalance = styled.div`
  position: relative;
  background-color: #282827;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 22.5px 0;
  border-radius: 6px;
  margin: 0 16px;
  cursor: pointer;
  span {
    ${B2}
  }

  .balance-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 0 16px;
  }

  p {
    ${B1}
    text-align: center;
  }
  .balance {
    ${H2}
    text-align: center;
  }

  .info {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    right: 24px;
    width: 48px;
    height: 48px;
  }
`;

export const Wearable = styled.div`
  margin: 0 35px;
  border-radius: 6px;
  margin: 16px 16px 16px;
  background-color: #282827;
  img {
    width: 100%;
    height: auto;
    object-fit: contain;
    aspect-ratio: 1;
  }
`;

export const GreenManifesto = styled.div`
  padding: 24px 16px;
  background-color: #282827;
  margin: 0px 16px 16px;
  border-radius: 6px;
  text-align: center;

  h2 {
    ${H2};
    margin-bottom: 16px;
  }
  p {
    ${B1}
  }

  img {
    margin: 16px auto 24px;
  }
`;

export const Capabilities = styled.div`
  .capabilities-container {
    margin-top: 24px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 16px;
    justify-items: stretch;
  }
`;

export const Capability = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #3d3d3c;
  cursor: pointer;
  aspect-ratio: 1;
  border-radius: 6px;

  h3 {
    ${B1};
    color: #ffffff;
    margin: 12px auto;
    text-align: center;
  }

  img {
    width: 70px;
    height: 70px;
    object-fit: contain;
  }
`;

export const EventInformation = styled.div`
  padding: 20px 16px 8px;
  p {
    ${B1}
  }
  span {
    color: #f37439;
  }
`;

export const AfterEvent = styled.div`
  margin-top: 24px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;

  .bb-logo {
    height: 16px;
  }

  h2 {
    ${H2}
  }

  p {
    ${B1}
  }
  .note {
    font-size: 12px;
  }
  .activation-code {
    padding: 12px 16px;
    background-color: #3d3d3c;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    img {
      cursor: pointer;
    }
  }
  .button {
    ${B2}
    cursor: pointer;
    display: inline-flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 8px 8px 8px 16px;
    font-style: normal;
    background: #f37439;
    border-radius: 360px;
    margin-bottom: 8px;
    img {
      margin-bottom: -4px;
    }
  }

  .stores {
    cursor: pointer;
    display: flex;
    flex-direction: row;
    gap: 8px;
  }

  .line {
    border: 1px solid #e0e0e0;
    width: 100%;
    height: 1px;
  }

  .or-separator {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    p {
      margin: 0px 16px;
    }
  }
`;

export const Footer = styled.div`
  background-color: #282827;
  margin-top: 8px;
  padding: 24px 16px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  justify-content: center;
  align-items: center;

  p {
    ${B4}
  }

  img {
    height: 24px;
    margin-bottom: 8px;
    cursor: pointer;
  }
`;

export const InfoAlert = styled.div`
  max-width: 480px;
  position: absolute;
  background: rgba(9, 10, 15, 0.2);
  height: 100%;
  width: 100%;
  text-align: center;

  .inner-container {
    position: fixed;
    max-width: 360px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 32px 24px;
    background-color: white;
    border-radius: 16px;
    width: 90%;
    z-index: 9999;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .close-button {
      width: 24px;
      height: 24px;
      align-self: flex-end;
      cursor: pointer;
    }

    .top-icon {
      width: 70px;
      height: 70px;
      object-fit: contain;
      margin: auto;
      margin-bottom: 8px;
    }

    h1 {
      ${H1}
      padding: 24px;
      border: 1px solid #d8d9d9;
      border-radius: 6px;
      width: fit-content;
      margin: auto;
      margin-bottom: 16px;
    }

    h2 {
      ${H2};
      color: #000000;
      margin-bottom: 16px;
    }

    p {
      ${B1}
      color: #000000;
      margin-bottom: 24px;
    }

    .button {
      font-weight: bold;
      display: flex;
      height: 32px;
      min-width: 144px;
      padding: 16px 40px;
      justify-content: center;
      align-items: center;
      text-align: center;
      gap: 8px;
      border-radius: 8px;
      color: white;
      background: #f37439;
      margin: auto;
      cursor: pointer;
    }
  }
`;
