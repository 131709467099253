import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { composeErrorMessage } from "../../utils/utils";

import scavengerHuntService from "./scavengerHuntService";

const initialState = {
  gettingContestants: false,
  errorGettingContestants: "",
  contestants: null,
  resettingDevice: false,
  errorResettingDevice: "",
  resettingAllDevices: false,
  errorResettingAllDevices: "",
  deliveringPrizes: false,
  errorDeliveringPrizes: ""
};

export const getContestantsAction = createAsyncThunk(
  "scavengerHunt/getContestants",
  async (_, thunkAPI) => {
    try {
      const response = await scavengerHuntService.getContestants();
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(composeErrorMessage(error));
    }
  }
);

export const resetDeviceAction = createAsyncThunk(
  "scavengerHunt/resetDevice",
  async (playerId, thunkAPI) => {
    try {
      const response = await scavengerHuntService.resetDevice(playerId);
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(composeErrorMessage(error));
    }
  }
);

export const resetAllDevicesAction = createAsyncThunk(
  "scavengerHunt/resetAllDevices",
  async (_, thunkAPI) => {
    try {
      const response = await scavengerHuntService.resetAllDevices();
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(composeErrorMessage(error));
    }
  }
);

export const deliverPrizesAction = createAsyncThunk(
  "scavengerHunt/deliverPrizes",
  async (devices, thunkAPI) => {
    try {
      const response = await scavengerHuntService.deliverPrizes(devices);
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(composeErrorMessage(error));
    }
  }
);

export const scavengerHunt = createSlice({
  name: "scavengerHunt",
  initialState,
  reducers: {
    reset: () => initialState
  },
  extraReducers: (builder) => {
    builder
      .addCase(getContestantsAction.pending, (state) => {
        state.gettingContestants = true;
        state.errorGettingContestants = "";
      })
      .addCase(getContestantsAction.fulfilled, (state, action) => {
        state.gettingContestants = false;
        state.contestants = action.payload?.contestants;
      })
      .addCase(getContestantsAction.rejected, (state, action) => {
        state.gettingContestants = false;
        state.errorGettingContestants = action.payload;
      })
      .addCase(resetDeviceAction.pending, (state) => {
        state.resettingDevice = true;
        state.errorResettingDevice = "";
      })
      .addCase(resetDeviceAction.fulfilled, (state) => {
        state.resettingDevice = false;
      })
      .addCase(resetDeviceAction.rejected, (state, action) => {
        state.resettingDevice = false;
        state.errorResettingDevice = action.payload;
      })
      .addCase(resetAllDevicesAction.pending, (state) => {
        state.resettingAllDevices = true;
        state.errorResettingAllDevices = "";
      })
      .addCase(resetAllDevicesAction.fulfilled, (state) => {
        state.resettingAllDevices = false;
      })
      .addCase(resetAllDevicesAction.rejected, (state, action) => {
        state.resettingAllDevices = false;
        state.errorResettingAllDevices = action.payload;
      })
      .addCase(deliverPrizesAction.pending, (state) => {
        state.deliveringPrizes = true;
        state.errorDeliveringPrizes = "";
      })
      .addCase(deliverPrizesAction.fulfilled, (state) => {
        state.deliveringPrizes = false;
      })
      .addCase(deliverPrizesAction.rejected, (state, action) => {
        state.deliveringPrizes = false;
        state.errorDeliveringPrizes = action.payload;
      });
  }
});

export const { reset } = scavengerHunt.actions;
export default scavengerHunt.reducer;
