import styled from "styled-components";

export const MainContainer = styled.section`
  overflow-y: ${({ alertVisible }) => (alertVisible ? "hidden" : "auto")};
  position: relative;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 480px;
  width: 100%;
  margin: auto;
  padding: 0 16px 16px;

  *:not(input, textarea) {
    -moz-user-select: none; /* firefox */
    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* IE*/
    user-select: none; /* Standard syntax */
  }
`;
