import styled, { keyframes } from "styled-components";
import { Input } from "../components/styled/SharedStyles";
import mixins from "./mixins";

export const Select = styled.div`
  width: 100%;
  ${mixins.H2}
  text-align: center;
  background: #a2a6b8;
  border-radius: 0px 12px 12px 0px;
  padding: 12px 18px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;

  color: white;
  &:hover {
    cursor: pointer;
  }
`;

export const SelectOptions = styled.div`
  width: 100%;
  color: #303033;
  background: #ffffff;
  border-radius: 12px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  margin-bottom: 16px;
`;

export const Option = styled.div`
  ${mixins.B1}
  padding: 12px 16px;
  text-align: center;
  border-bottom: ${({ isLast }) => (!isLast ? "2px solid #dadbe6" : null)};
  &:hover {
    color: #fe7e45;
    font-weight: 700;
    cursor: pointer;
  }
`;

export const SelectContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 66%;
`;

export const SelectSection = styled.div`
  display: flex;
  flex-direction: row;

  ${Input} {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
`;
export const TagsContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 16px;
  margin-bottom: 16px;
`;

export const Tag = styled.div`
  ${mixins.B1}
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
  background: ${({ selected }) => (selected ? "#FE7E45" : "#ffffff")};
  border-radius: 10px;
  padding: 8px 16px;
  margin-right: 8px;
  margin-bottom: 8px;
  color: ${({ selected }) => (selected ? "#ffffff" : "#303033")};

  img {
    margin-right: 4px;
    width: 24px;
    height: 24px;
    cursor: pointer;
  }
`;

export const AddTag = styled.div`
  ${mixins.B1}
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 8px 24px;
  background: #fe7e45;
  border-radius: 10px;
  color: #ffffff;
  margin-right: 8px;
  margin-bottom: 8px;
  cursor: pointer;
`;

export const InputTag = styled.input`
  border-radius: 12px;
  border: none;
  background-color: #ffffff;
  caret-color: #fe7e45;
  height: 40px;
  padding: 8px 16px;
  width: auto;
  ${mixins.B1}
  color: #303033;
  :focus {
    outline: none;
  }
`;

export const DevicesBadge = styled.div`
  background: #a2a6b8;
  border-radius: 12px;
  padding: 12px 16px;
  margin-bottom: 16px;
  color: white;
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  display: inline-block;
`;

const slideDown = keyframes`
  from {
    transform: translateY(100%);
  }
  to {
    transform: translateY(0);
  }
`;

export const TransferDevices = styled.div`
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  height: 0;

  overflow: hidden;
  transition: height 0.5s ease-out;

  p {
    margin: 10px;
  }

  &.open {
    height: 400px;
  }

  &.slide-down {
    animation-name: ${slideDown};
    animation-duration: 0.5s;
  }
`;
