export const INFINEON_DATA = {
  welcome: {
    fob: {
      title: "Welcome to Infineon Banking Day Two",
      description:
        "We've put €10.00 on your key fob, so grab a drink on DIGISEQ by tapping it at any card terminal!"
    },
    ring: {
      title: "Welcome to Infineon Banking Day Two",
      description:
        "This ring was created with the world's first pre-certified payment concentric inlay, which will deliver unrivalled speed-to-market for brands and product creators. Its ultra-slim design supports the creation of more intricate and exquisite smart accessories."
    },
    nail: {
      title: "Wearables are About to Get Smarter",
      description:
        "We often carry many items daily, such as car/house keys, bank/access cards, smart phones and in some cases cash. All of which can be lost, damaged or stolen. What if, you could have a multi-use device that’s always with you and can securely take care of payments, access, have consumer engagement and more? Smart Chip, powered by DIGISEQ, has developed the world’s first ‘NFC manicure microchip with RFID’. The Smart Chip is a patented application that is applied to your fingernail at a Smart Chip registered nail salon. By adding the Smart Chip as an extra layer to the process, you have the options to mobile provision your nail for payments and more through DIGISEQ’s, Manage-Mii Mobile app."
    }
  },
  hero: {
    img: require("./img/hero.webp"),
    common: {
      download: "Download the Manage-Mii app to:",
      p1: "Link your payment card to enable contactless payments.",
      p2: "Set up a contactless business card and share your details with a tap!"
    },
    ring: { title: "Use Your Ring Anywhere!" },
    fob: {
      title: "Use Your Key Fob Anywhere!",
      download: "Once you’ve spent your €10 - download the Manage-Mii app to:"
    },
    nail: { title: "Use Your Fingernail Anywhere!" }
  },
  digiseq: {
    title: "Who Are",
    titleHl: "DIGISEQ",
    p1: "DIGISEQ is an award-winning IoT service platform that securely provisions data into everyday wearables; enabling items, from rings to clothing, to deliver NFC payment, customer engagement functionality, access control and digital identity. We connect the ecosystem, serving as a centrepiece between banks, product creators, chip manufacturers and card bureaux, so that wearables can be simply and securely delivered into the hands of consumers.",
    sectionTitle: "Delivering the highest level of Security",
    p2: "DIGISEQ’s RCOS™ over-the-air mobile personalisation and tokenisation service is leading the way in adopting the new SCP03 standard, ensuring all provisioned payment data is fully protected to the highest available standards, while continuing to deliver the fastest self-provisioning service in the industry. SCP03 protocol supports AES (Advanced Encryption Standard) based cryptography in lieu of TDEA (Triple Data Encryption Algorithm). The protocol protects bidirectional communication between the host and cards, mobile wallets and wearables. Click here to download our Press Release.",
    p3: "We also adhere to PSD2 directive for consumer initiated electronic payments, which mandates two factor authentication outside of the normal card present exemptions. With the support of online PIN, a transaction that exceeds the local contactless value or consecutive/cumulative transaction limit, will prompt the terminal to request the customer to enter the PIN (of the original card) therefore providing the exact same user experience as that of a card transaction. Click here to download our commissioned White Paper to."
  },
  whatsOn: {
    title: "What’s On Today?",
    span1: "Check out the",
    a: "event schedule",
    span2: "and don’t miss a thing.",
    img: require("./img/whatsOn.webp")
  },
  mcWallet: {
    title: "Mastercard Wallet Express",
    p2: "al wallets, offering consumers the flexibility to choose their preferred payment method. Other regions will be running their own programs to support this chose and digital wallet expansion. Reach out to DIGISEQ or directly to your Mastercard contact to find out more and discuss how we can help expedite the adoption of wearables across and beyond the region, creating great value for the whole industry.",
    p1: "Mastercard has recently implemented a Wallet Express Issuer Enablement Program for banks and issuers in the Europe and UK regions, with the objective of enabling them to support wearable and mobile payments. The program aims to provide issuers with a diverse range of digit"
  },
  uefa: {
    title: "UEFA Champions League 2023",
    p1: "Check out how our wearables were used at the Final!",
    a: "Take a look",
    img: require("./img/uefa.webp")
  },
  wsr: {
    title: "Wearable Security Reinforced",
    p1: "DIGISEQ collaborates with Infineon Technologies and Thales to reinforce wearable tech security.",
    a: "Read more",
    img: require("./img/wsr.webp")
  },
  sca: {
    title: "Strong Customer Authentication",
    p1: "Everything you need to know about SCA under PSD2 regulation.",
    a: "Read more",
    img: require("./img/sca.webp")
  },
  footer: {
    title: "DIGISEQ.",
    subtitle: "Revolutionising the",
    subtitle2: "Way We Pay.",
    link1: { title: "Our website", url: "https://www.digiseq.co.uk/" },
    link2: {
      title: "Privacy Policy",
      url: "https://www.digiseq.co.uk/privacy"
    },
    link3: {
      title: "Terms and Conditions",
      url: "https://s3.eu-west-2.amazonaws.com/www.digiseq.co.uk/ManageMiiTerms.html"
    },
    link4: {
      title: "Wearables and PSD2 SCA",
      url: "https://www.digiseq.co.uk/whitepapers/2023/4/25/wearables-and-psd2-sca"
    },
    socials: {
      facebook: {
        icon: require("./img/facebook.svg"),
        url: "https://www.instagram.com/digiseq_ltd/"
      },
      linkedin: {
        icon: require("./img/linkedin.svg"),
        url: "https://www.linkedin.com/company/digiseq/mycompany/"
      },
      twitter: {
        icon: require("./img/twitter.svg"),
        url: "https://twitter.com/digiseqUK"
      }
    }
  }
};
