import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import Select from "react-select";
import CreatableSelect from "react-select/creatable";

import { useNavigate } from "react-router-dom";
import { getCampaigns } from "../../features/campaign-manager/campaignManagerSlice";
import {
  createDasDevices,
  resetDasDevices,
  transferExistingDasDevices
} from "../../features/das-device-manager/dasDeviceManagerSlice";
import ProtectedRoute from "../../components/ProtectedRoute";
import { StyledSpinner } from "../../components/styled/Spinner";
import DetailsItem from "./components/DetailsItem";
import NavBarComponent from "./components/NavBar";
import { Button, TextArea } from "./components/styled/SharedStyles";
import { B2, CheckboxContainer, DetailsContainer } from "./styled/Campaign";
import { Body, ErrorMsg, MainContainer } from "./styled/SharedStyles";
import { getEvents } from "../../features/event-manager/eventManagerSlice";
import Counter from "../../components/Counter";
import Checkbox from "./components/Checkbox";

const CreateDevice = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [serials, setSerials] = useState("");
  const [tags, setTags] = useState([]);
  const [serialsError, setSerialsError] = useState(null);

  const [selectedAllowedEvents, setSelectedAllowedEvents] = useState([]);
  const [selectedCampaign, setSelectedCampaign] = useState(null);

  const [noOfVouchers, setNoOfVouchers] = useState(1);
  const [isUnlimited, setIsUnlimited] = useState(false);
  const [cryptogramSupported, setCryptogramSupported] = useState(true);
  const [vouchersEventInputDataMap, setVouchersEventInputDataMap] = useState(
    new Map()
  );

  const resetForm = () => {
    setSerials("");
    setSelectedCampaign(null);
    setTags([]);
    setSelectedAllowedEvents([]);
  };

  useEffect(() => {
    return () => {
      resetForm();
    };
  }, []);

  const { campaigns, isError, isLoading, message } = useSelector(
    (state) => state.campaigns
  );

  const { events } = useSelector((state) => state.events);

  const {
    nonExistingDevices,
    alreadyExistingSerialNumbers,
    isError: isErrorDevices,
    isLoading: isLoadingDevices,
    message: messageDevices
  } = useSelector((state) => state.dasDevices);

  const handleCreateDevice = () => {
    setSerialsError(null);
    const parsedSerials = serials.split("\n").filter((e) => e !== "");
    const invalidSerials = [];
    const validateSerials = () =>
      parsedSerials.forEach((e) => {
        if (e.length !== 20) {
          invalidSerials.push(e);
        }
      });

    validateSerials();

    if (invalidSerials.length > 0) {
      setSerialsError({
        message: "Some serial numbers are not valid, please check them",
        invalidSerials
      });
    } else {
      dispatch(
        createDasDevices({
          serialNumbers: parsedSerials,
          campaignId: selectedCampaign.value,
          tags: tags.map((tag) => tag.label),
          allowedEvents: selectedAllowedEvents.map(
            (allowedEvent) => allowedEvent.value
          ),
          vouchersEventInputDataMap: Object.fromEntries(
            vouchersEventInputDataMap
          ),
          cryptogramNotSupported: !cryptogramSupported
        })
      );
    }
  };

  const handleTransferExistingDevices = () => {
    dispatch(
      transferExistingDasDevices({
        serialNumbers: alreadyExistingSerialNumbers,
        toCampaign: selectedCampaign.value,
        tags: tags.map((tag) => tag.label),
        allowedEvents: selectedAllowedEvents.map(
          (allowedEvent) => allowedEvent.value
        ),
        cryptogramNotSupported: !cryptogramSupported,
        vouchersEventInputDataMap: Object.fromEntries(vouchersEventInputDataMap)
      })
    );
  };

  const handleSelectChange = (selectedOptions) => {
    setSelectedAllowedEvents(selectedOptions);
  };

  const handleSelectCampaignChange = (selectedOption) => {
    setSelectedCampaign(selectedOption);
  };

  const handleSelectTagsChange = (selectedOption) => {
    setTags(selectedOption);
  };

  const handleCountChange = (eventId, newCount) => {
    vouchersEventInputDataMap.set(eventId, newCount);
    setVouchersEventInputDataMap(vouchersEventInputDataMap);
    setNoOfVouchers(newCount);
  };

  const handleUnlimitedChange = (eventId, newIsUnlimited) => {
    if (newIsUnlimited) {
      vouchersEventInputDataMap.set(eventId, -1);
    } else {
      vouchersEventInputDataMap.set(eventId, noOfVouchers);
    }
    setVouchersEventInputDataMap(vouchersEventInputDataMap);

    setIsUnlimited(newIsUnlimited);
  };

  useEffect(() => {
    if (!campaigns) {
      dispatch(getCampaigns());
    } else {
      const defaultCampaign = campaigns.find(
        (campaign) => campaign.id === "digiseq_default"
      );
      if (defaultCampaign) {
        setSelectedCampaign({
          value: defaultCampaign.id,
          label: defaultCampaign.name
        });
      }
    }
    if (!events) {
      dispatch(getEvents());
    }
  }, [campaigns, events, dispatch]);

  useEffect(() => {
    if (alreadyExistingSerialNumbers?.length === 0) {
      navigate("/devices-manager");
    }
  }, [alreadyExistingSerialNumbers?.length, navigate]);

  useEffect(() => {
    return () => {
      dispatch(resetDasDevices());
      setSerialsError(null);
    };
  }, [dispatch]);

  if (nonExistingDevices?.length === 0) {
    return (
      <MainContainer>
        <ProtectedRoute />
        <NavBarComponent
          leftText={"Back"}
          hasBackArrow
          title={"New Device"}
          to="/devices-manager"
        />
        <Body>
          <p style={{ textAlign: "center" }}>Devices transferred</p>
          <br></br>
          <Button
            onClick={() => {
              resetForm();
              dispatch(resetDasDevices());
            }}
          >
            Add other devices
          </Button>
        </Body>
      </MainContainer>
    );
  }

  if (nonExistingDevices?.length > 0) {
    return (
      <MainContainer>
        <ProtectedRoute />
        <NavBarComponent
          leftText={"Back"}
          hasBackArrow
          title={"Add Devices"}
          to="/devices-manager"
        />
        <Body>
          <p style={{ textAlign: "center" }}>
            Some devices could not be found!
          </p>
          <br></br>
          <DetailsContainer>
            {nonExistingDevices.map((e, i) => (
              <DetailsItem key={i} name={e} />
            ))}
          </DetailsContainer>
        </Body>
      </MainContainer>
    );
  }

  return (
    <MainContainer>
      <ProtectedRoute />
      <NavBarComponent
        leftText={"Back"}
        hasBackArrow
        title={"Add Devices"}
        to="/devices-manager"
      />
      <Body>
        {!alreadyExistingSerialNumbers && (
          <>
            <p>Campaign</p>
            <B2>Select a campaign the device will be associated to</B2>
            {campaigns?.length > 0 && (
              <Select
                options={campaigns.map((campaign) => {
                  return {
                    value: campaign.id,
                    label: campaign.name
                  };
                })}
                value={selectedCampaign}
                onChange={handleSelectCampaignChange}
              />
            )}
            <p>Allowed Events</p>
            <B2>Select the events this device is allowed to attend</B2>
            <Select
              placeholder="None"
              isMulti
              options={events?.map((event) => {
                return {
                  capabilities: event.capabilities,
                  value: event.id,
                  label: event.name
                };
              })}
              value={selectedAllowedEvents}
              onChange={handleSelectChange}
            />
            <ul>
              {selectedAllowedEvents.map((allowedEvent) => {
                if (allowedEvent.capabilities.includes("VOUCHERS"))
                  return (
                    <>
                      <p key={allowedEvent.value}>
                        {allowedEvent.label} Vouchers Setup
                      </p>
                      <p>
                        Choose the number of redeemable vouchers you want the
                        devices to have
                      </p>
                      <Counter
                        onCountChange={(value) =>
                          handleCountChange(allowedEvent.value, value)
                        }
                        onUnlimitedChange={(isUnlimited) =>
                          handleUnlimitedChange(allowedEvent.value, isUnlimited)
                        }
                      />
                    </>
                  );
              })}
            </ul>
            <p>Optional Tags</p>
            <CreatableSelect
              placeholder="Start typing ..."
              isClearable
              isMulti
              options={tags}
              onChange={handleSelectTagsChange}
            />

            <CheckboxContainer>
              <p>Cryptogram Supported</p>
              <Checkbox
                value={cryptogramSupported}
                onChange={(state) => {
                  if (state) {
                    setCryptogramSupported(false);
                  }
                  setCryptogramSupported(state);
                }}
              />
            </CheckboxContainer>
            <p>Serial numbers</p>
            <TextArea
              rows="20"
              placeholder="paste or type devices serials (one line each)"
              value={serials}
              onChange={(ev) => {
                setSerials(ev.target.value);
                setSerialsError(null);
              }}
            />
            <Button
              disabled={!serials || !selectedCampaign}
              onClick={() => handleCreateDevice()}
            >
              Add Devices
            </Button>
          </>
        )}

        {alreadyExistingSerialNumbers?.length > 0 && (
          <>
            <p>Warning, some of the devices already exist:</p>
            <DetailsContainer>
              {alreadyExistingSerialNumbers.map((e, i) => (
                <DetailsItem
                  key={i}
                  name={e}
                  onClick={() =>
                    navigate(`/devices-manager/search/?serial=${e}`)
                  }
                />
              ))}
            </DetailsContainer>
            <Button onClick={() => handleTransferExistingDevices()}>
              Transfer existing devices to the same campaign
            </Button>
          </>
        )}
        {(isError || isErrorDevices) && (
          <ErrorMsg>
            {message ||
              messageDevices ||
              "An error has occured, please try again later or contact support."}
          </ErrorMsg>
        )}
        {serialsError?.message && <ErrorMsg>{serialsError.message}</ErrorMsg>}
        {(isLoading || isLoadingDevices) && <StyledSpinner />}
      </Body>
    </MainContainer>
  );
};

export default CreateDevice;
