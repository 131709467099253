import { useEffect, useState } from "react";
import boxChecked from "./img/box-checked.svg";
import boxUnchecked from "./img/box-unchecked.svg";
import { StyledCheckbox } from "../styled/Campaign";

const Checkbox = ({ onChange, value }) => {
  const [checked, setChecked] = useState(value);

  useEffect(() => {
    setChecked(value);
  }, [value]);

  useEffect(() => {
    onChange(checked);
  }, [checked, onChange]);

  return (
    <StyledCheckbox onClick={() => setChecked(!checked)}>
      <img src={checked ? boxChecked : boxUnchecked} alt="check icon" />
    </StyledCheckbox>
  );
};

export default Checkbox;
