import styled from "styled-components";
import mixins from "./mixins";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 8px;
  ${mixins.B1}
  color: #0D2A56;
  text-align: left;
`;
