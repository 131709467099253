import styled from "styled-components";

import heroImg from "../img/heroImg.webp";
import overviewImg from "../img/overview.webp";
import ehImg from "../img/ehImg.webp";
import paImg from "../img/payment-account.webp";

export const H1 = styled.h1`
  color: #fff;
  font-family: "Catchy Mager";
  font-size: 80px;
  font-style: normal;
  font-weight: 400;
  line-height: 60px;
`;

export const H2 = styled.h2`
  color: #591628;
  font-family: Georgia;
  font-size: 26px;
  font-style: normal;
  font-weight: 700;
  line-height: 38px;
`;

export const H3 = styled.h3`
  color: #a68549;
  text-align: right;
  font-family: Georgia;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 30px; /* 150% */
`;

export const Hero = styled.div`
  padding: 0 32px 8px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: start;
  background: linear-gradient(
      180deg,
      rgba(89, 22, 40, 0) 33.78%,
      rgba(89, 22, 40, 0.8) 93.78%
    ),
    url(${heroImg}) lightgray 50% / cover no-repeat;

  height: 497px;
  align-self: stretch;

  .date {
    color: #fff;
    font-family: "Catchy Mager";
    font-size: 29px;
    font-style: normal;
    font-weight: 400;
    line-height: 60px; /* 206.897% */
    text-transform: capitalize;
  }
`;

export const Balance = styled.div`
  display: flex;
  padding: 0px 16px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  border-top: 2px solid #a68549;
  margin: 17px 0;

  span {
    color: #a68549;
    text-align: right;
    font-family: Georgia;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 30px; /* 150% */
  }
`;

export const Intro = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 0 16px;
  .buttons {
    display: flex;
    gap: 16px;
  }
`;

export const Button = styled.div`
  display: flex;
  padding: 16px 21px 16px 22px;
  justify-content: center;
  align-items: center;
  flex: 1 0 0;
  align-self: stretch;
  background: #a68549;
  color: #fff;
  text-align: center;
  font-family: Georgia;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px; /* 100% */
  text-transform: uppercase;
  cursor: pointer;
`;

export const TopSection = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Overview = styled.div`
  display: flex;
  flex-direction: column;

  .chapter {
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
    border-bottom: 2px solid #a68549;

    span {
      color: #a68549;
      text-align: right;
      font-family: Georgia;
      font-size: 20px;
      font-style: normal;
      font-weight: 700;
      line-height: 30px; /* 150% */
      color: #a68549;
    }
  }
  .description-container {
    display: flex;
    padding: 16px 16px 32px 16px;
    flex-direction: column;
    align-items: center;
    gap: 24px;
    align-self: stretch;
    background: #591628;
  }

  .text-container {
    display: flex;
    padding: 0px 24px;
    align-items: center;
    gap: 9px;
    align-self: stretch;

    p {
      color: #fff;
      font-family: Georgia;
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: 27px; /* 150% */
    }
  }
`;

export const OverviewImg = styled.div`
  height: 272px;
  align-self: stretch;
  background: url(${overviewImg}) lightgray 50% / cover no-repeat;
`;

export const EventHighlights = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  align-self: stretch;

  .chapter {
    display: flex;
    padding: 0px 16px;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
    border-top: 2px solid #a68549;

    span {
      color: #a68549;
      text-align: right;
      font-family: Georgia;
      font-size: 20px;
      font-style: normal;
      font-weight: 700;
      line-height: 30px; /* 150% */
    }
  }

  .img {
    height: 270px;
    align-self: stretch;
    background: url(${ehImg}) lightgray 50% / cover no-repeat;
  }
`;

export const Schedule = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0px 16px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;

  .day {
    display: flex;
    align-self: stretch;
    width: 100%;
    justify-content: space-between;
    padding: 17px 24px 21px;
    background: #591628;
    span {
      color: #fff;
      font-family: Georgia;
      font-size: 20px;
      font-style: normal;
      font-weight: 400;
      line-height: 27px; /* 135% */
    }
  }
  .events {
    display: flex;
    padding: 24px;
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
    align-self: stretch;
    background: #a68549;
  }
  .event {
    display: flex;
    padding-top: 2px;
    flex-direction: column;
    align-items: flex-start;
    gap: 3px;
    align-self: stretch;
    border-top: 2px solid #591628;

    .time {
      color: #591628;
      font-family: Georgia;
      font-size: 18px;
      font-style: normal;
      font-weight: 700;
      line-height: 28px;
    }
    .description {
      color: #fff;
      font-family: Georgia;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 27px;
    }
  }
`;

export const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
  width: 100%;
  max-width: 480px;
  margin: auto;
  min-height: 100vh;
  background-color: #fff;

  p {
    font-family: "Inter", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
  }

  a {
    text-decoration: underline;
    cursor: pointer;
  }
`;

export const PaymentAccount = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 19px;
  align-self: stretch;
  background: #591628;
  padding-bottom: 19px;

  .splash {
    height: 270px;
    align-self: stretch;
    background: url(${paImg}) lightgray 50% / cover no-repeat;
  }

  .chapter {
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
    border-top: 2px solid #a68549;
    margin: 19px 16px;

    span {
      color: #a68549;
      text-align: right;
      font-family: Georgia;
      font-size: 20px;
      font-style: normal;
      font-weight: 700;
      line-height: 30px; /* 150% */
      color: #a68549;
    }
  }

  .inner {
    display: flex;
    padding: 14px 24px;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    align-self: stretch;
  }

  .button {
    color: white;
    cursor: pointer;
    display: inline-flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 8px 8px 8px 16px;
    font-style: normal;
    background: #f37439;
    border-radius: 360px;
    margin-bottom: 8px;
    img {
      margin-bottom: -4px;
    }
  }

  p {
    color: #fff;
    font-family: Georgia;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 27px;
  }

  .logo {
    width: 134px;
    height: 43px;
  }

  .stores {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    gap: 8px;
    img {
      cursor: pointer;
    }
  }
`;

export const ActivationCode = styled.div`
  display: flex;
  display: flex;
  padding: 10px 16px;
  justify-content: space-between;
  align-items: flex-start;
  align-self: stretch;
  background: #fff;

  span {
    color: #081634;
    font-family: Georgia;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
  }
`;

export const Footer = styled.div`
  margin-top: -32px;
  display: flex;
  padding: 4px 16px 3px 16px;
  justify-content: center;
  align-items: center;
  gap: 27px;
  align-self: stretch;

  .stores {
    display: flex;
    width: 132px;
    height: 91px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 9px;
    cursor: pointer;
  }
  .logos {
    width: 199px;
    height: 116px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 18px;

    img {
      height: 86px;
      width: 86px;
      cursor: pointer;
    }
  }
`;
