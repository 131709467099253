import React from "react";
import { Alert, AlertContainer } from "../styled/Location";
import { ActionButton } from "../styled/SharedStyles";
import ascotLogo from "../img/ascot-logo.svg";

const AlertComponent = ({ onClose, title, subtitle, buttonLabel }) => {
  return (
    <AlertContainer>
      <Alert>
        <img src={ascotLogo} alt="logo" />
        <h1>{title}</h1>
        <p>{subtitle}</p>
        {onClose && (
          <ActionButton inverted onClick={() => onClose()}>
            {buttonLabel}
          </ActionButton>
        )}
      </Alert>
    </AlertContainer>
  );
};

export default AlertComponent;
