import React from "react";
import { Footer, MainContainer, NavBar } from "./styles/SharedStyles";
import {
  ListContainer,
  ListItem,
  Product,
  Promotion
} from "./styles/ProductInformation";

import logo from "./img/logo.svg";
import menuIcon from "./img/menu-burger.svg";
import productImg from "./img/fossil-product.webp";
import promoImg from "./img/fossil-promo.webp";

const FossilProductInformation = () => {
  const PRODUCT_INFO = [
    { label: "Owner", info: "John Smith" },
    { label: "Owner ID", info: "018932791" },
    {
      label: "Model",
      info: "Limited Edition Star Wars™ Boba Fett™ Automatic Ventile Strap Watch"
    },
    {
      label: "Place of purchase",
      info: "FOSSIL Store Stratford, Unit SU1074, London, E20 1EJ"
    },
    { label: "Date of purchase", info: "14/06/2023" },
    {
      label: "About",
      info: "Celebrate 40 years of Star Wars™ Return Of The Jedi™ with this Star Wars x Fossil watch. This limited-edition design features...",
      link: "https://www.fossil.com/en-gb/products/limited-edition-star-wars-boba-fett-automatic-ventile-strap-watch/LE1173SET.html"
    }
  ];

  return (
    <MainContainer>
      <NavBar>
        <img className="logo" src={logo} alt="logo" />
        <img className="menu" src={menuIcon} alt="menu" />
      </NavBar>
      <Product>
        <h2>My Watch</h2>
        <img src={productImg} alt="product" />
      </Product>
      <ListContainer>
        {PRODUCT_INFO.map((item, i) => (
          <ListItem isLast={PRODUCT_INFO.length === i + 1} key={i}>
            <div className="label">
              {item.label}
              {":"}
              <span className="info">{item.info}</span>
              {item.link && (
                <a href={item.link} target="_blank" rel="noopener noreferrer">
                  read more
                </a>
              )}
            </div>
          </ListItem>
        ))}
      </ListContainer>
      <Promotion>
        <img src={promoImg} alt="promo" />
        <h2>Redeem Your Exclusive Watch Face.</h2>
        <p>
          As a special thank you for purchasing a Star Wars limited edition
          watch, we want you to have the limited Midnight Black watch face for
          your Fossil Smart watches.
        </p>
        <div className="button">REDEEM NOW</div>
      </Promotion>
      <Footer>
        <img className="logo" src={logo} alt="logo" />
      </Footer>
    </MainContainer>
  );
};

export default FossilProductInformation;
