import styled from "styled-components";
import mixins from "./mixins";

export const MainContainer = styled.section`
  margin: auto;
  background-color: #f7f8fa;
  width: 100%;
  max-width: 480px; //mobile view
  min-height: 100vh;
  padding-bottom: 40px;
  p {
    ${mixins.H3}
  }

  h2 {
    ${mixins.H2}
  }
`;
export const Nav = styled.div`
  cursor: pointer;
  ${mixins.H3}
  color: #FE7E45;
  background-color: #fff;
  height: 56px;
  padding: 0 16px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  align-items: center;
  border-top: 2px solid #f7f8fa;
  border-bottom: 2px solid #f7f8fa;
`;

export const Back = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

export const ErrorMsg = styled.h2`
  text-align: center;
  color: red;
  margin: 20px auto;
`;

export const NavTitle = styled.span`
  color: #303033;
  text-align: center;
`;

export const ListContainer = styled.div`
  background-color: white;
  padding-left: 16px;
  border-radius: 16px;
`;

export const ListItemContainer = styled.div`
  padding: 16px 16px 16px 0;
  border-bottom: 2px solid #f7f8fa;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;

  img {
    height: 24px;
    width: 24px;
  }
`;

export const Body = styled.div`
  padding: 16px;
  p {
    margin-top: 8px;
    margin-bottom: 8px;
  }
`;
