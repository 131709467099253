import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import Spinner from "../../components/Spinner";

import {
  AppStoreLinks,
  CurvelogoContainer,
  Footer,
  Wearable,
  MainContainer,
  Navbar,
  NextStepsContainer,
  PaymentAccount,
  StepContainer,
  StepNumber,
  Separator
} from "./styles/Money2020";

import chevronRight from "./img/chevron-right.svg";
import appStoreLogo from "./img/app-store.svg";
import gplayLogo from "./img/play-store.svg";
import curveLogo from "./img/curve_logo.png";
import payAnyWhereImage from "../tap2start/img/pay-anywhere-image.png";
import euFlag from "./img/eu-flag.png";
import ukFlag from "./img/uk-flag.png";
import orSeparator from "./img/or.svg";
import digiseqLogo from "./img/digiseq-logo.png";
import mastercardLogo from "./img/mastercard.svg";
import bluewaterLogo from "./img/bluewater_logo.svg";
import bottle from "./img/bottle.png";
import { getDasDeviceInfo } from "../../features/das-device-manager/dasDeviceManagerSlice";
import { getDeviceByModel } from "../../features/devices/deviceSlice";

const Money2020 = () => {
  const [searchParams] = useSearchParams();
  const serialNumber = searchParams.get("serialNumber");

  const dispatch = useDispatch();
  const { device: discoveryDevice, isLoading: isLoadingDevices } = useSelector(
    (state) => state.devices
  );

  const { dasDeviceInfo, gettingDasDeviceInfo } = useSelector(
    (state) => state.dasDevices
  );

  useEffect(() => {
    if (!dasDeviceInfo && serialNumber) {
      dispatch(getDasDeviceInfo(serialNumber));
    }
  }, [dasDeviceInfo, dispatch, serialNumber]);

  useEffect(() => {
    if (
      dasDeviceInfo?.model?.model &&
      dasDeviceInfo?.model?.brand &&
      dasDeviceInfo?.model?.version
    ) {
      dispatch(
        getDeviceByModel({
          brand: dasDeviceInfo.model.brand,
          model: dasDeviceInfo.model.model,
          version: dasDeviceInfo.model.version
        })
      );
    }
  }, [
    dasDeviceInfo?.model?.brand,
    dasDeviceInfo?.model?.model,
    dasDeviceInfo?.model?.version,
    dispatch
  ]);

  const getDeviceImage = () => {
    if (
      discoveryDevice?.variants?.length > 0 &&
      dasDeviceInfo?.model?.variant?.color
    ) {
      const deviceVariant = discoveryDevice.variants.find(
        (variant) => variant.color === dasDeviceInfo.model.variant.color
      );

      if (deviceVariant?.image) {
        return deviceVariant.image;
      }
    } else if (discoveryDevice?.defaultImage) {
      return discoveryDevice.defaultImage;
    }

    return null;
  };

  const appStoreLinks = (appStoreUrl, playStoreUrl) => {
    return (
      <AppStoreLinks>
        <img
          src={appStoreLogo}
          alt="app-store"
          onClick={() => window.open(appStoreUrl, "_blank")}
        />
        <img
          src={gplayLogo}
          alt="play-store"
          onClick={() => window.open(playStoreUrl, "_blank")}
        />
      </AppStoreLinks>
    );
  };

  if (isLoadingDevices || gettingDasDeviceInfo) return <Spinner />;

  return (
    <MainContainer>
      <Navbar>
        <img alt="logo" src={mastercardLogo} />
        <div className="balance">
          <img alt="logo" src={bluewaterLogo} />
        </div>
      </Navbar>
      <Separator isDark />
      <Wearable className="wearable">
        <img src={getDeviceImage() || bottle} alt="wearable" />
      </Wearable>

      <Separator isDark />
      <PaymentAccount>
        <div className="inner">
          <p>
            Congratulations on your new Bluewater SMART bottle! To activate your
            free lifetime Bluewater warranty, please register here.
          </p>
          <div
            className="button"
            onClick={() =>
              window.open(
                `https://www.bluewatergroup.com/en-gb/money-2020`,
                "_blank"
              )
            }
          >
            {"Warranty Registration"}{" "}
            <img src={chevronRight} alt="chevron right" />
          </div>
        </div>
      </PaymentAccount>
      <Separator isDark />

      <PaymentAccount>
        <p className="title">Payment Account</p>
        <p className="subtitle">Prepaid</p>
        <div className="inner">
          <p>
            To set up this bottle for contactless payments please click below
          </p>
          <div
            className="button"
            onClick={() =>
              window.open(`https://get.muchbetter.com/digiseq-cl`, "_blank")
            }
          >
            {"Learn More"} <img src={chevronRight} alt="chevron right" />
          </div>
        </div>
      </PaymentAccount>
      <NextStepsContainer>
        <CurvelogoContainer>
          <img className="separator" src={orSeparator} alt="or" />
          <p className="title">Link your card</p>
          <img className="curve-logo" src={curveLogo} alt="curve logo" />
          <div className="flags">
            <img src={euFlag} alt="eu flag" />{" "}
            <img src={ukFlag} alt="uk flag" />
          </div>
        </CurvelogoContainer>
        <StepContainer>
          <StepNumber>1</StepNumber>
          <h2>Create a Curve Account</h2>
          <p>
            Register your card details with Curve to receive a free compatible
            digital bank card.
          </p>
          <p>Download the Curve App:</p>
          {appStoreLinks(
            "https://apps.apple.com/gb/app/curve-supercharge-your-money/id1049397112",
            "https://play.google.com/store/apps/details?id=com.imaginecurve.curve.prd"
          )}
        </StepContainer>
        <StepContainer>
          <StepNumber>2</StepNumber>
          <h2>Link Your Curve Virtual Card Using the Manage-Mii App</h2>
          <p>
            Follow the instructions on the Manage-Mii app and use your new Curve
            Card details when prompted.
          </p>
          {appStoreLinks(
            "https://apps.apple.com/app/manage-mii/id6446908503",
            "https://play.google.com/store/apps/details?id=com.digiseq.managemii"
          )}
        </StepContainer>
        <StepContainer>
          <StepNumber>3</StepNumber>
          <h2>Pay Anywhere!</h2>
          <p>
            Tap your wearable at any card terminal where Mastercard Contactless
            is enabled.
          </p>
          <img
            className="pay-anywhere"
            src={payAnyWhereImage}
            alt="pay anywhere"
          />
        </StepContainer>
      </NextStepsContainer>
      <Separator isDark />

      <Footer>
        <p>Powered by</p>
        <img
          src={digiseqLogo}
          alt="digiseq-logo"
          onClick={() => window.open("https://www.digiseq.co.uk", "_blank")}
        />
      </Footer>
    </MainContainer>
  );
};

export default Money2020;
