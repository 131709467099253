import styled from "styled-components";

export const MainContainer = styled.div`
  padding: 32px 0;
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 480px;
  margin: auto;
  min-height: 100vh;
  background-color: #fff;

  h1,
  h2 {
    font-family: "Inter";
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 40px;
    color: #202020;
  }

  p {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.5px;
    color: #626262;
  }

  h1 {
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    margin: 32px 0 24px;
  }
`;

export const Separator = styled.div`
  margin: 0px 16px;
  border: 1px solid #e0e0e0;
`;

export const Hero = styled.section`
  padding: 0 16px 32px;
  display: flex;
  flex-direction: column;

  .wearable-container {
    margin: 16px 48px;
    padding: 16px;
    border-radius: 4px;
    background-color: #fafafa;
    box-shadow: 2px 6px 8px rgba(0, 0, 0, 0.35);
    img {
      width: 100%;
      aspect-ratio: 1;
      object-fit: contain;
    }
  }

  .logo {
    aspect-ratio: 346/226;
    object-fit: contain;
    max-height: 226px;
  }

  .welcome-badge {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 16px;
    gap: 8px;
    margin: 0 auto;
    background: #f5f5f6;
    border-radius: 8px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  }
`;

export const StepContainer = styled.section`
  padding: 32px 16px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  .video-container {
    width: 100%;
    video {
      width: 100%;
    }
  }
`;

export const StepIcon = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 8px;
  gap: 8px;
  width: 40px;
  height: 40px;
  background: #f6f6f7;
  border-radius: 360px;

  p {
    font-family: "Inter";
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    display: flex;
    align-items: center;
    letter-spacing: 0.15px;
    color: #202020;
  }
`;

export const Footer = styled.footer`
  padding: 32px 16px 64px;
  background-color: #f6f6f7;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  p {
    font-weight: 700;
    color: #000000;
  }

  a {
    cursor: pointer;
  }
`;
