import React, { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";

import { getBalanceAndPin } from "../../features/balance-and-pin/balanceAndPinSlice";

import Spinner from "../../components/Spinner";
import {
  MainContainer,
  NavBar,
  Header,
  Footer,
  B1,
  B2,
  H2,
  About,
  H1,
  AsahiFonts
} from "./styles/RugbyWcAsahi";

import logo from "./img/logo.webp";
import digiseqLogo from "./img/digiseq-logo.webp";

const ASAHI_DATA = {
  header: {
    image: require("./img/header.webp")
  },
  about: {
    title1: "Asahi Super Dry,",
    title2: "Official Beer of Rugby World Cup France 2023!",
    p: "As Official Beer of Rugby World Cup 2023, Asahi Super Dry will bring a taste of Modern Japan to the World Stage, bringing rugby fans together & elevating your experience by taking you Beyond Expected."
  }
};

const RugbyWcAsahi = () => {
  const [searchParams] = useSearchParams();
  const serialNumber = searchParams.get("serialNumber");
  const dispatch = useDispatch();

  const { balanceAndPin, isLoading } = useSelector(
    (state) => state.balanceAndPin
  );

  useEffect(() => {
    if (!balanceAndPin && serialNumber) {
      dispatch(
        getBalanceAndPin({ serial: serialNumber, query: "?provider=tokenised" })
      );
    }
  }, [balanceAndPin, serialNumber, dispatch]);

  const formattedBalance = (balanceDetails) => {
    const { availableBalance, currency } = balanceDetails;
    let iconPrefixed = true;
    let icon = "";

    switch (currency) {
      case "GBP":
        icon = "£";
        break;
      case "EUR":
        icon = "€";
        break;
      case "USD":
        icon = "$";
        break;
      default:
        icon = "";
    }

    if (iconPrefixed) {
      return icon + availableBalance;
    } else {
      return availableBalance + icon;
    }
  };

  if (isLoading) return <Spinner />;

  return (
    <MainContainer>
      <AsahiFonts />
      <NavBar>
        <img src={logo} alt="hugo boss logo" className="logo" />
        <div className="balance-container">
          <B1>
            Balance:{" "}
            {balanceAndPin?.balanceDetails?.availableBalance
              ? formattedBalance(balanceAndPin?.balanceDetails)
              : "N/A"}
          </B1>
        </div>
      </NavBar>
      <Header>
        <img src={ASAHI_DATA.header.image} alt="header" />
      </Header>
      <About>
        <H1>
          <span className="emph">{ASAHI_DATA.about.title1}</span>
          <span>{ASAHI_DATA.about.title2}</span>
        </H1>
        <B2>{ASAHI_DATA.about.p}</B2>
      </About>

      <Footer>
        <H2>{"Enjoy the Match!"}</H2>
        <B2>{"Your fob is subject to the local contactless limit."}</B2>
        <B2>
          {"Having issues? Please get in touch with your Asahi representative."}
        </B2>
        {/* <a href="tel:+447929673674">
          <B1>{"+44 (07929) 673674"}</B1>
        </a>
        <a href="mailto:rhiannon.davies@csm.com">
          <B1>{"rhiannon.davies@csm.com"}</B1>
        </a> */}
        <div className="powered-by">
          <B2>{"Powered by"}</B2>
          <img src={digiseqLogo} alt="digiseq logo" />
        </div>
        <B2
          className="serial"
          onClick={() => navigator.clipboard.writeText(serialNumber)}
        >{`SN: ${serialNumber}`}</B2>
      </Footer>
    </MainContainer>
  );
};

export default RugbyWcAsahi;
