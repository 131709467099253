import styled from "styled-components";

export const ManOfTheMatchSubmittedContainer = styled.section`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 50vh;

  justify-content: center;
  align-items: center;

  h2 {
    margin: 40px 0 60px;
  }

  h4 {
    margin: 0px 0 8px;
  }
`;
