import styled from "styled-components";
import {  theme } from "./SharedStyles";

export const ImageContainer = styled.section`
  border-radius: 16px;
  box-shadow: 0px 32px 80px rgba(16, 22, 40, 0.1);
  display: flex;
  width: 159.499px;
  height: 159.499px;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  img {
    width: 127.6px;
    height: 127.6px;
  }
`;

export const StatusesContainer = styled.div`
  display: flex;
  align-items: flex-start;
  align-content: flex-start;
  gap: 16px;
  align-self: stretch;
  flex-wrap: wrap;
  margin-top: 16px;
`;

export const Status = styled.div`
  display: flex;
  padding: 8px 8px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 8px;

  background: #f6f6f7;

  p {
    color: #000;
    ${({ completed }) => completed && `color: #fff;`}
  }

  ${({ completed }) => completed && `background: #32cc14;`}
`;

export const Button = styled.div`
  display: flex;
  flex: 1;
  margin-top: 16px;
  justify-content: center;
  align-items: center;
  background-color: ${theme.colors.primary};
  border-radius: 8px;
  cursor: pointer;
  color: white;
  padding: 16px 0;
  img {
    margin-left: 16px;
  }
`;
