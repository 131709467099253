import styled from "styled-components";
import mixins from "./mixins";

export const EventsContainer = styled.div`
  ${mixins.H3}

  padding: 8px 16px 60px;

  h1 {
    ${mixins.H1};
    margin-bottom: 16px;
  }
`;

export const ListContainer = styled.div`
  display: grid;
  border-radius: 12px;
  background-color: white;
`;

export const EventItem = styled.div`
  display: flex;
  flex-direction: row;
  height: 80px;
  cursor: pointer;

  img {
    margin: 20px;
  }
`;

export const EventItemDetails = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-grow: 1;
  border-bottom: 2px solid #f7f8fa;

  .event-code {
    ${mixins.B2}
  }
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  img {
    cursor: pointer;
    :hover {
      transform: scale(120%);
    }
  }
`;
