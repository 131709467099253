import React, { useState } from "react";
import { B1, ProductItem } from "./styles";

import chevronDown from "./img/chevron-down.svg";
import chevronUp from "./img/chevron-up.svg";

const ProductInfo = ({ title, children, isLast }) => {
  const [expanded, setExpanded] = useState(false);

  return (
    <ProductItem isLast={isLast}>
      <div className="item" onClick={() => setExpanded((prev) => !prev)}>
        <B1>{title}</B1>
        <img
          src={expanded ? chevronUp : chevronDown}
          alt={`chevron ${expanded ? "up" : "down"}`}
        />
      </div>
      {expanded && <div>{children}</div>}
    </ProductItem>
  );
};

export default ProductInfo;
