import React from "react";
import { Container, TextContainer } from "./styled/MessageSection";
import { useMediaQuery } from "react-responsive";

import mastercardLogoBig from "./images/mastercard-big.svg";
import mastercardLogoSmall from "./images/mastercard-small.png";

const MessageSection = ({ title, text, hasImage }) => {
  const isSmallScreen = useMediaQuery({ query: "(max-width: 1440px)" });
  return (
    <Container>
      <TextContainer>
        <h4>{title}</h4>
        <h2>{text}</h2>
      </TextContainer>
      {hasImage && (
        <div>
          <img
            src={isSmallScreen ? mastercardLogoSmall : mastercardLogoBig}
            alt="mastercard logo"
          />
        </div>
      )}
    </Container>
  );
};

export default MessageSection;
