import styled from "styled-components";

export const IncrementButton = styled.button`
  background: #3e55f2;
  font-family: "Noto Sans", sans-serif;
  border: none;
  border-radius: 8px;
  height: 24px;
  width: 24px;
  color: white;

  margin: 8px 0px;
  text-align: center;
  cursor: pointer;
  &:hover {
    background-color: #2e78f7;
  }
  &:active {
    transform: scale(0.98);
  }
`;

export const IncrementValue = styled.b`
  font-family: "Noto Sans", sans-serif;
  margin: 0px 8px;
  text-align: center;
`;

export const UnlimitedContainer = styled.b`
  font-family: "Noto Sans", sans-serif;
  margin: 0px 8px;
  text-align: center;
`;


