import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 16px;

  img {
    cursor: pointer;
    margin-top: 24px;
    max-height: 50px;
  }
`;
