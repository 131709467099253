export const formattedBalance = (availableBalance, currency, iconPrefixed) => {
  let icon = "";

  switch (currency) {
    case "GBP":
      icon = "£";
      break;
    case "EUR":
      icon = "€";
      break;
    case "USD":
      icon = "$";
      break;
    case "CHF":
      icon = "CHF";
      break;
    default:
      icon = "";
  }

  if (!availableBalance) {
    return "N/A";
  }

  if (iconPrefixed) {
    return availableBalance + icon;
  } else {
    return icon + " " + availableBalance;
  }
};
