import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { composeErrorMessage } from "../../utils/utils";
import campaignManagerService from "./campaignManagerService";

const initialState = {
  campaigns: null,
  campaign: null,
  votingResults: null,
  isError: false,
  isSuccess: false,
  isLoading: false,
  deviceCampaignReseted: false,
  message: "",
  newCampaign: null,
  updatedCampaign: null,
  deletedCampaign: null,
  devicesTransferred: false
};

// get public entities
export const getCampaigns = createAsyncThunk(
  "campaigns/getAllCampaigns",
  async (_, thunkAPI) => {
    try {
      const response = await campaignManagerService.getCampaigns();

      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(composeErrorMessage(error));
    }
  }
);

export const getCampaign = createAsyncThunk(
  "campaigns/getCampaign",
  async (campaignId, thunkAPI) => {
    try {
      return await campaignManagerService.getCampaign(campaignId);
    } catch (error) {
      return thunkAPI.rejectWithValue(composeErrorMessage(error));
    }
  }
);

export const patchCampaign = createAsyncThunk(
  "campaigns/patchCampaign",
  async ({ campaignId, key }, thunkAPI) => {
    try {
      return await campaignManagerService.patchCampaign(campaignId, key);
    } catch (error) {
      return thunkAPI.rejectWithValue(composeErrorMessage(error));
    }
  }
);

export const getCampaignVotingResults = createAsyncThunk(
  "campaigns/getCampaignVotingResults",
  async (campaignId, thunkAPI) => {
    try {
      return await campaignManagerService.getCampaignVotingResults(campaignId);
    } catch (error) {
      return thunkAPI.rejectWithValue(composeErrorMessage(error));
    }
  }
);

export const resetDeviceCampaign = createAsyncThunk(
  "campaigns/resetDeviceCampaign",
  async (payload, thunkAPI) => {
    try {
      return await campaignManagerService.resetDeviceCampaign(payload);
    } catch (error) {
      return thunkAPI.rejectWithValue(composeErrorMessage(error));
    }
  }
);

export const createCampaign = createAsyncThunk(
  "campaigns/createCampaign",
  async (payload, thunkAPI) => {
    try {
      return await campaignManagerService.createCampaign(payload);
    } catch (error) {
      return thunkAPI.rejectWithValue(composeErrorMessage(error));
    }
  }
);

export const editCampaign = createAsyncThunk(
  "campaigns/editCampaign",
  async (payload, thunkAPI) => {
    try {
      return await campaignManagerService.editCampaign(payload);
    } catch (error) {
      return thunkAPI.rejectWithValue(composeErrorMessage(error));
    }
  }
);

export const deleteCampaign = createAsyncThunk(
  "campaigns/deleteCampaign",
  async (payload, thunkAPI) => {
    try {
      return await campaignManagerService.deleteCampaign(payload);
    } catch (error) {
      return thunkAPI.rejectWithValue(composeErrorMessage(error));
    }
  }
);

export const campaignsSlice = createSlice({
  name: "campaigns",
  initialState,
  reducers: {
    reset: (state) => initialState,
    resetCampaigns: (state) => {
      state.campaigns = null;
    },
    resetCampaign: (state) => {
      state.campaign = null;
      state.newCampaign = null;
      state.updatedCampaign = null;
      state.deletedCampaign = null;
    },
    resetError: (state) => {
      state.isError = null;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCampaigns.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getCampaigns.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.campaigns = action.payload;
      })
      .addCase(getCampaigns.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(getCampaign.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getCampaign.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.campaign = action.payload;
      })
      .addCase(getCampaign.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(patchCampaign.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(patchCampaign.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.campaign = action.payload;
      })
      .addCase(patchCampaign.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(getCampaignVotingResults.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getCampaignVotingResults.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.votingResults = action.payload;
      })
      .addCase(getCampaignVotingResults.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(resetDeviceCampaign.pending, (state) => {
        state.isLoading = true;
        state.deviceCampaignReseted = false;
      })
      .addCase(resetDeviceCampaign.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.deviceCampaignReseted = true;
      })
      .addCase(resetDeviceCampaign.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(createCampaign.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(createCampaign.fulfilled, (state, action) => {
        state.isLoading = false;
        state.newCampaign = action.payload;
      })
      .addCase(createCampaign.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })

      .addCase(editCampaign.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(editCampaign.fulfilled, (state, action) => {
        state.isLoading = false;
        state.updatedCampaign = action.payload;
      })
      .addCase(editCampaign.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(deleteCampaign.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(deleteCampaign.fulfilled, (state, action) => {
        state.isLoading = false;
        state.deletedCampaign = true;
      })
      .addCase(deleteCampaign.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      });
  }
});

export const { reset, resetCampaigns, resetCampaign, resetError } =
  campaignsSlice.actions;
export default campaignsSlice.reducer;
