import React from "react";
import { ListItemContainer } from "../styled/SharedStyles";

import chevronRightLight from "../img/chevron-right.svg";

const ListItem = ({ text, ...other }) => {
  return (
    <ListItemContainer {...other}>
      {text}
      <img src={chevronRightLight} alt="right arrow"></img>
    </ListItemContainer>
  );
};

export default ListItem;
