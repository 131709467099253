import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { composeErrorMessage } from "../../utils/utils";
import tap2StartService from "./tap2StartService";

const initialState = {
  fetchingDevice: false,
  device: null,
  fetchingDeviceError: "",
  checkingBinNumber: false,
  checkBinNumberResponse: null,
  checkingBinNumberError: "",
};

export const fetchDeviceAction = createAsyncThunk(
  "tap2start/getDevice",
  async (serial, thunkAPI) => {
    try {
      const response = await tap2StartService.fetchDevice(serial);

      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(composeErrorMessage(error));
    }
  }
);

export const checkBinAction = createAsyncThunk(
  "tap2start/checkBin",
  async (binNumber, thunkAPI) => {
    try {
      const response = await tap2StartService.checkBin(binNumber);
      // TODO remove when real core api implemented
      if (binNumber === "123456") response.result = "NOT_SUPPORTED";
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(composeErrorMessage(error));
    }
  }
);

export const tap2StartSlice = createSlice({
  name: "tap2start",
  initialState,
  reducers: {
    reset: (state) => initialState,
    resetCheckBin: (state, action) => {
      state.checkBinNumberResponse = null;
      state.checkingBinNumberError = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchDeviceAction.pending, (state) => {
        state.fetchingDevice = true;
        state.fetchingDeviceError = null;
      })
      .addCase(fetchDeviceAction.fulfilled, (state, action) => {
        state.fetchingDevice = false;
        state.device = action.payload;
      })
      .addCase(fetchDeviceAction.rejected, (state, action) => {
        state.fetchingDevice = false;
        state.fetchingDeviceError = action.payload;
      })
      .addCase(checkBinAction.pending, (state) => {
        state.checkingBinNumber = true;
        state.checkingBinNumberError = null;
        state.checkBinNumberResponse = null;
      })
      .addCase(checkBinAction.fulfilled, (state, action) => {
        state.checkingBinNumber = false;
        state.checkBinNumberResponse = action.payload;
      })
      .addCase(checkBinAction.rejected, (state, action) => {
        state.checkingBinNumber = false;
        state.checkingBinNumberError = action.payload;
      });
  },
});

export const { reset, resetCheckBin } = tap2StartSlice.actions;
export default tap2StartSlice.reducer;
