import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";

import {
  getBalanceAndPin,
  savePersonalDetailsAction
} from "../../features/balance-and-pin/balanceAndPinSlice";
import { getDeviceInfo } from "../../features/hugo-boss/hugoBossSlice";
import usePrevious from "../../hooks/usePrevious";
import { AppStoreLinks } from "../tap2start/styles/BinChecker";
import { formattedBalance } from "../../features/balance-and-pin/utils";
import Spinner from "../../components/Spinner";

import { StyledSpinner } from "../../components/styled/Spinner";
import {
  Button,
  ContactDetails,
  Container,
  Footer,
  FormButton,
  InfoAlert,
  InnerContainer,
  MainContainer,
  Nav,
  Separator
} from "./styles/CampBestival24";

import campBestivalLogo from "./img/camp-bestival-logo.webp";
import chevronRightIcon from "./img/chevron-right.svg";
import campBestivalRoundLogo from "./img/camp-bestival-round-logo.webp";
import digiseqLogo from "./img/digiseq.webp";
import hyperjarLogo from "./img/hyperjar-logo.webp";
import personIcon from "./img/person-icon.svg";
import phoneIcon from "./img/phone-icon.svg";
import appStoreLogo from "../tap2start/img/app-store-badge.svg";
import gplayLogo from "../tap2start/img/play-store-badge.svg";

const CampBestival24 = () => {
  const [showForm, setShowForm] = useState(false);
  const [fullName, setFullName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [formError, setFormError] = useState("");
  const [searchParams] = useSearchParams();
  const serialNumber = searchParams.get("serialNumber");

  const dispatch = useDispatch();

  const {
    balanceAndPin,
    isLoading,
    savingPersonalDetails,
    errorSavingPersonalDetails
  } = useSelector((state) => state.balanceAndPin);

  const { device, gettingDeviceInfo, gettingDeviceInfoError } = useSelector(
    (state) => state.hugoBoss
  );

  const prevGettingDeviceInfo = usePrevious(gettingDeviceInfo);

  useEffect(() => {
    if (!balanceAndPin && serialNumber) {
      dispatch(
        getBalanceAndPin({ serial: serialNumber, query: "?provider=tokenised" })
      );
    }
  }, [balanceAndPin, serialNumber, dispatch]);

  useEffect(() => {
    if (
      prevGettingDeviceInfo &&
      !gettingDeviceInfoError &&
      device?.personalDetails?.fullName &&
      device?.personalDetails?.phoneNumber
    ) {
      setFullName(device?.personalDetails?.fullName);
      setPhoneNumber(device?.personalDetails?.phoneNumber);
    }
  }, [
    device?.personalDetails?.fullName,
    device?.personalDetails?.phoneNumber,
    gettingDeviceInfoError,
    prevGettingDeviceInfo
  ]);

  const prevSavingPersonalDetails = usePrevious(savingPersonalDetails);

  useEffect(() => {
    if (prevSavingPersonalDetails && !errorSavingPersonalDetails) {
      setShowForm(false);
    }
  }, [errorSavingPersonalDetails, prevSavingPersonalDetails]);

  useEffect(() => {
    dispatch(getDeviceInfo(serialNumber));
  }, [dispatch, serialNumber]);

  const handleSubmit = () => {
    setFormError("");

    if (fullName && phoneNumber) {
      dispatch(
        savePersonalDetailsAction({
          serial: serialNumber,
          details: { fullName, phoneNumber }
        })
      );
    } else {
      setFormError("Name and phone number are required!");
    }
  };

  const appStoreLinks = (appStoreUrl, playStoreUrl) => {
    return (
      <AppStoreLinks>
        <img
          src={appStoreLogo}
          alt="app-store"
          onClick={() => window.open(appStoreUrl, "_blank")}
        />
        <img
          src={gplayLogo}
          alt="play-store"
          onClick={() => window.open(playStoreUrl, "_blank")}
        />
      </AppStoreLinks>
    );
  };

  if (isLoading || gettingDeviceInfo) return <Spinner />;

  return (
    <MainContainer>
      <Nav>
        <img src={campBestivalLogo} alt="camp-bestival" />
        <span className="balance">
          <p>
            {`Balance: ${
              balanceAndPin?.balanceDetails?.availableBalance
                ? `£${balanceAndPin?.balanceDetails?.availableBalance}`
                : `N/A`
            }`}
          </p>
        </span>
      </Nav>
      <Separator />
      {fullName && phoneNumber && !showForm && (
        <ContactDetails>
          <h2>Emergency contact</h2>

          <div className="detail">
            <img src={personIcon} alt="contact name" />
            <p>{fullName}</p>
          </div>
          <div className="detail">
            <img src={phoneIcon} alt="contact number" />
            <p>{phoneNumber}</p>
          </div>
        </ContactDetails>
      )}
      <InnerContainer>
        <img
          className="round-logo"
          src={campBestivalRoundLogo}
          alt="Camp Bestival 25-28th July"
        />
        <h1>
          {
            "WELCOME TO CAMP BESTIVAL, THE UK’S ORIGINAL AND MOST SUCCESSFUL FAMILY FESTIVAL."
          }
        </h1>
        <p>
          {
            "Your keyfob comes preloaded with £20 to spend anywhere on site by just tapping like your contactless Mastercard. See below for additional functionality."
          }
        </p>
      </InnerContainer>
      <Separator />
      <InnerContainer>
        <Container>
          <h2>Emergency contact</h2>
          <p>Set up contact details here, and view them on tap.</p>
          <Button onClick={() => setShowForm(true)}>
            {`${fullName && phoneNumber ? "Change" : "Set up"} contact details`}
            <img src={chevronRightIcon} alt="chevron-right" />
          </Button>
        </Container>
        <Container>
          <h2>Event information</h2>
          <p>
            Get all the information you need in the Camp Bestival Information
            section
          </p>
          <Button
            onClick={() =>
              window.open("https://dorset.campbestival.net/info/", "_blank")
            }
          >
            Camp Bestival Information{" "}
            <img src={chevronRightIcon} alt="chevron-right" />
          </Button>
        </Container>
        <Container>
          <h2>{"Payment Account"}</h2>
          <img className="hyperjar-logo" src={hyperjarLogo} alt="hyperjar" />
          <p>
            {
              "Payment account is provided by HyperJar. To learn more - download HyperJar mobile application."
            }
          </p>
          {appStoreLinks(
            "https://apps.apple.com/gb/app/hyperjar-money-management-app/id1224672532",
            "https://play.google.com/store/apps/details?id=com.tractr.hyperjar&hl=en_GB"
          )}
        </Container>
      </InnerContainer>
      <Separator />
      <Footer>
        <div>
          <p>Powered by</p>
          <img src={digiseqLogo} alt="digiseq" />
        </div>
        <div>
          <p>Payment services provided by</p>
          <img src={hyperjarLogo} alt="hyperjar" />
        </div>
        {device?.itemNumber && <p>ID: {device.itemNumber}</p>}
      </Footer>
      {showForm && (
        <InfoAlert>
          <div className="inner-container">
            <h2>{"Emergency contact"}</h2>

            <form>
              <p>
                {fullName
                  ? "Update your details"
                  : "Please provide your details"}{" "}
                {" It will be displayed on homepage on tap"}
              </p>
              <div className="input-container">
                <img src={personIcon} alt="contact name" />
                <input
                  type="text"
                  name="fullName"
                  placeholder="Name"
                  value={fullName}
                  onChange={(ev) => setFullName(ev.target.value)}
                />
              </div>
              <div className="input-container">
                <img src={phoneIcon} alt="contact number" />
                <input
                  placeholder="Phone number"
                  type="text"
                  name="phoneNumber"
                  value={phoneNumber}
                  onChange={(ev) => setPhoneNumber(ev.target.value)}
                />
              </div>
              {formError && <p>{formError}</p>}
              <FormButton onClick={() => handleSubmit()}>Save</FormButton>
              <FormButton outlined onClick={() => setShowForm(false)}>
                Cancel
              </FormButton>
              {savingPersonalDetails && (
                <StyledSpinner
                  style={{ margin: "0 auto", width: 30, height: 30 }}
                />
              )}
            </form>
          </div>
        </InfoAlert>
      )}
    </MainContainer>
  );
};

export default CampBestival24;
