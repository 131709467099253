import httpClient from "../../axios";
import { ENV } from "../../env";

export const API_URL = `https://www.${
  ENV !== "production" ? (ENV === "development" ? "dev" : ENV) + "-" : ""
}manage-mii.uk/api/das/admin/`;

const getCampaigns = async () => {
  const response = await httpClient.get(
    API_URL + "campaigns?client=manage-mii-website"
  );

  return response.data;
};

const getCampaign = async (id) => {
  const response = await httpClient.get(API_URL + `campaigns/${id}`);
  return response.data;
};

const getCampaignVotingResults = async (id) => {
  const response = await httpClient.get(
    API_URL + `campaigns/${id}/voting-results`
  );
  return response.data;
};

const resetDeviceCampaign = async ({ serial, campaignId, resetType }) => {
  const response = await httpClient.post(
    API_URL + `devices/${serial}/campaign/${campaignId}/reset`,
    { resetType }
  );
  return response.data;
};

const patchCampaign = async (campaignId, key) => {
  const response = await httpClient(`${API_URL}/campaigns/${campaignId}`, {
    method: "patch",
    data: key
  });

  return response.data;
};

const createCampaign = async ({ id, name, description, assignedManagers }) => {
  const response = await httpClient.post(`${API_URL}/campaigns`, {
    id,
    name,
    description,
    assignedManagers
  });
  return response.data;
};

const editCampaign = async ({ originalId, id, name, description }) => {
  const response = await httpClient.put(`${API_URL}/campaigns/${originalId}`, {
    id,
    name,
    description
  });
  return response.data;
};

const deleteCampaign = async (id) => {
  const response = await httpClient.delete(`${API_URL}campaigns/${id}`);
  return response.data;
};

const campaignManagerService = {
  getCampaigns,
  getCampaign,
  patchCampaign,
  createCampaign,
  editCampaign,
  deleteCampaign,
  getCampaignVotingResults,
  resetDeviceCampaign
};

export default campaignManagerService;
