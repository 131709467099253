import axios from "axios";
import { ENV } from "../../env";

export const API_URL = `https://www.${
  ENV !== "production" ? (ENV === "development" ? "dev" : ENV) + "-" : ""
}manage-mii.uk/api/das/device`;

// GET device info
const getDeviceInfo = async (serial) => {
  const response = await axios.get(API_URL + `/${serial}/device-info`);

  return response.data;
};

// PUT claim device
const claimDevice = async ({
  serial,
  firstName,
  lastName,
  email,
  claimPinCode
}) => {
  const response = await axios.put(API_URL + `/${serial}/claim`, {
    firstName,
    lastName,
    email,
    claimPinCode
  });

  return response.data;
};

// GET activate ticket
const activateTicket = async ({ serialNumber, ticketId }) => {
  const response = await axios.get(
    API_URL + `/${serialNumber}/ticket/${ticketId}/activate`
  );

  return response.data;
};

const reauthenticate = async ({ serial, claimPinCode }) => {
  const response = await axios.post(API_URL + `/${serial}/reauthenticate`, {
    claimPinCode
  });

  return response.data;
};

const hugoBossService = {
  getDeviceInfo,
  claimDevice,
  activateTicket,
  reauthenticate
};

export default hugoBossService;
