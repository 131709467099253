import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { getBalanceAndPin } from "../../features/balance-and-pin/balanceAndPinSlice";
import { formattedBalance } from "../../features/balance-and-pin/utils";
import { getDeviceByModel } from "../../features/devices/deviceSlice";
import { getDasDeviceInfo } from "../../features/das-device-manager/dasDeviceManagerSlice";

import {
  AfterEvent,
  Capabilities,
  Capability,
  EventInformation,
  Footer,
  GreenManifesto,
  InfoAlert,
  MainContainer,
  SectionContainer,
  ViewBalance,
  Wearable,
} from "./styles/Uefa";

import Spinner from "../../components/Spinner";

import uefaMastercardLogo from "./img/uefa-mastercard-logo.svg";
import wearableImg from "./img/wearable.png";
import chevronRight from "./img/chevron-right.svg";
import appStoreLogo from "./img/app-store.svg";
import gplayLogo from "./img/play-store.svg";
import mmLogo from "./img/mm-logo.svg";
import digiseqLogo from "./img/digiseq-logo.png";
import muchBetterLogo from "./img/muchbetter-logo.svg";
import infoCloseIcon from "./img/info-close-icon.svg";
import infoIcon from "./img/info_icon.png";

const Uefa24 = () => {
  const [searchParams] = useSearchParams();
  const serialNumber = searchParams.get("serialNumber");
  const dispatch = useDispatch();

  const [info, setInfo] = useState("");

  const infoDetails = {
    shopping: {
      icon: require("./img/shopping.png"),
      title: "Shopping",
      subtitle:
        "We have pre-loaded your key fob with £20, so you can shop easily at our dedicated kiosks. This key fob can also be used after the UCL Final, everywhere Mastercard is accepted.",
    },
    donations: {
      icon: require("./img/donations.png"),
      title: "Donations",
      subtitle:
        "You can also use your key-fob to donate to Bloomsbury Football Foundation, a registered charity that uses football to improve the lives of over 5,000 underprivileged young people in London each week.",
      buttonLabel: "Donate Now",
      buttonLink: "https://donate.mastercard.com/widget/p2/#/donate/donation-type?program_id=485f7cf0-0de9-4ab7-a17a-c53c0e096e86&charity_id=bae06713-ee5c-4d25-a1c7-a3b9d442e41d&donations=%7B%22ONE-TIME%22:%7B%22minAmount%22:1,%22maxAmount%22:8000,%22defaultAmount%22:5,%22predefinedAmounts%22:%5B1,2,5,10,20%5D%7D%7D&lang=en-gb&aemResource=ucl_final&guestOnly=true&source_id=UCL_Final",
    },
    // quiz: {
    //   icon: require("./img/quiz.png"),
    //   title: "Quiz",
    //   subtitle:
    //     "Test your knowledge with our quiz celebrating 30 years of our UEFA Champions League partnership. You could win tickets to the UEFA Champions League 2024/25 group stage matches (and other great prizes/TBC)​",
    //   buttonLink: "https://www.google.co.uk",
    //   buttonLabel: "Start the quiz"
    // },
    photoMemory: {
      icon: require("./img/camera.png"),
      title: "Photo Memory",
      subtitle:
        "You can retrieve all the event photos with our AI-based Memento Photo Technology feature. All you need to do is to scan a QR code and take a selfie.​",
      buttonLink: `https://memento.photo/j/mMz7H-lh?eat=${serialNumber}`,
      buttonLabel: "Download my Photos",
    },
    pledgeBall: {
      icon: require("./img/pledgeball.png"),
      title: "Pledgeball",
      subtitle:
        "Mastercard has partnered with Pledgeball as part of UEFA’s Champions Innovate initiative, an accelerator programme focused on delivering a sustainable legacy beyond the final. Tackling climate change requires collective action and Pledgeball achieves this by uniting football fans to take and drive climate action.​",
      buttonLink: "https://pledgeball.org/pledgeleague/",
      buttonLabel: "Make a Pledge",
    },
    sustainability: {
      icon: require("./img/sustainability.png"),
      title: "Sustainability",
      subtitle:
        "Mastercard aims to prioritise sustainability and innovation in everything we do and this key fob is no exception. Made from apple skin sourced from Italian South Tyrol, every kilo of apple waste used saves 5.28 kg of CO2 compared to using oil derivatives.​",
    },
  };

  const eventInformation = [
    { time: "16:00", details: "Hospitality Area Opening" },
    { time: "18:00", details: "Official Welcoming" },
    { time: "18:10", details: "Uefa Legend Q&As" },
    { time: "18:55", details: "Last Order Before the Match" },
    { time: "19:15", details: "Leave for Seats" },
    { time: "19:45", details: "Opening Ceremony" },
    { time: "20:00", details: "Match Kick-off" },
    { time: "22:00", details: "Match Ends (estimated)" },
    { time: "22:45", details: "Beginning of Transfers to Hotel(s)" },
    {
      time: "00:00",
      details: "Hospitality Area Closes (or 2 hours after the final whistle)",
    },
  ];

  const { balanceAndPin, isLoading } = useSelector(
    (state) => state.balanceAndPin
  );

  const { device: discoveryDevice, isLoading: isLoadingDevices } = useSelector(
    (state) => state.devices
  );

  const { dasDeviceInfo, gettingDasDeviceInfo } = useSelector(
    (state) => state.dasDevices
  );

  useEffect(() => {
    if (!dasDeviceInfo) {
      dispatch(getDasDeviceInfo(serialNumber));
    }
  }, [dasDeviceInfo, dispatch, serialNumber]);

  useEffect(() => {
    if (
      dasDeviceInfo?.model?.model &&
      dasDeviceInfo?.model?.brand &&
      dasDeviceInfo?.model?.version
    ) {
      dispatch(
        getDeviceByModel({
          brand: dasDeviceInfo.model.brand,
          model: dasDeviceInfo.model.model,
          version: dasDeviceInfo.model.version,
        })
      );
    }
  }, [
    dasDeviceInfo?.model?.brand,
    dasDeviceInfo?.model?.model,
    dasDeviceInfo?.model?.version,
    dispatch,
  ]);

  useEffect(() => {
    if (!balanceAndPin && serialNumber) {
      dispatch(
        getBalanceAndPin({
          serial: serialNumber,
          query: "?provider=much_better",
        })
      );
    }
  }, [balanceAndPin, serialNumber, dispatch]);

  const getDeviceImage = () => {
    if (
      discoveryDevice?.variants?.length > 0 &&
      dasDeviceInfo?.model?.variant?.color
    ) {
      const deviceVariant = discoveryDevice.variants.find(
        (variant) => variant.color === dasDeviceInfo.model.variant.color
      );

      if (deviceVariant?.image) {
        return deviceVariant.image;
      }
    } else if (discoveryDevice?.defaultImage) {
      return discoveryDevice.defaultImage;
    }

    return null;
  };

  useEffect(() => {
    if (!balanceAndPin && serialNumber) {
      dispatch(getBalanceAndPin({ serial: serialNumber }));
    }
  }, [balanceAndPin, serialNumber, dispatch]);

  if (isLoading || isLoadingDevices || gettingDasDeviceInfo) return <Spinner />;

  return (
    <>
      <MainContainer>
        <GreenManifesto>
          <img src={uefaMastercardLogo} alt="uefa logo" />
          <>
            <h2>Being part of something bigger: priceless</h2>
            <p>{`Individuals can make a difference. But teams can make waves. Joining the other players on a pitch, standing shoulder to shoulder in a crowd, being part of a united community.`}</p>
            <p>{`Being more than the sum of our parts and achieving something truly special together.`}</p>
          </>
        </GreenManifesto>
        <ViewBalance onClick={() => setInfo("shopping")}>
          <div className="balance-container">
            <p>My Balance</p>
            <div className="balance">
              {balanceAndPin?.balanceDetails?.availableBalance
                ? formattedBalance(
                    balanceAndPin?.balanceDetails?.availableBalance,
                    balanceAndPin?.balanceDetails?.currency,
                    true
                  )
                : "N/A"}
            </div>
          </div>
          <div className="info">
            <img src={infoIcon} alt="info icon" />
          </div>
        </ViewBalance>
        <Wearable className="wearable">
          <img src={getDeviceImage() || wearableImg} alt="wearable" />
        </Wearable>
        <SectionContainer>
          <h2>{"Key fob Features"}</h2>
          <Capabilities>
            <div className="capabilities-container">
              {/* <Capability onClick={() => setInfo("shopping")}>
                <img src={require("./img/shopping.png")} alt="shopping" />
                <h3>Shopping</h3>
              </Capability> */}
              {/* <Capability onClick={() => setInfo("quiz")}>
                <img src={require("./img/quiz.png")} alt="quiz" />
                <h3>Quiz</h3>
              </Capability> */}
              <Capability onClick={() => setInfo("donations")}>
                <img src={require("./img/donations.png")} alt="donations" />
                <h3>Donations</h3>
              </Capability>
              <Capability onClick={() => setInfo("photoMemory")}>
                <img src={require("./img/camera.png")} alt="quiz" />
                <h3>Photo Memory</h3>
              </Capability>
              <Capability onClick={() => setInfo("pledgeBall")}>
                <img src={require("./img/pledgeball.png")} alt="donations" />
                <h3>Pledgeball</h3>
              </Capability>
              <Capability onClick={() => setInfo("sustainability")}>
                <img
                  src={require("./img/sustainability.png")}
                  alt="sustainability"
                />
                <h3>Sustainability</h3>
              </Capability>
            </div>
          </Capabilities>
        </SectionContainer>

        <SectionContainer>
          <h2>{"Today's Schedule"}</h2>
          <EventInformation>
            {eventInformation.map((evt, i) => (
              <p key={i}>
                <span>{evt.time}</span>
                {` - ${evt.details}`}
              </p>
            ))}
          </EventInformation>
        </SectionContainer>

        <SectionContainer>
          <h2>{"Payment Account"}</h2>
          <AfterEvent>
            <p>
              Want to continue using this keyfob? Please click below to upgrade
              to a dedicated wallet
            </p>
            <div
              className="button"
              onClick={() =>
                window.open(
                  `https://get.muchbetter.com/digiseq-cl`,
                  "_blank"
                )
              }
            >
              {"Learn more"} <img src={chevronRight} alt="chevron right" />
            </div>
          </AfterEvent>
        </SectionContainer>
        <Footer>
          <p>Powered by</p>
          <img
            src={digiseqLogo}
            alt="digiseq-logo"
            onClick={() => window.open("https://www.digiseq.co.uk", "_blank")}
          />
          <p>Payment services provided by</p>
          <img
            src={muchBetterLogo}
            alt="much-better-logo"
            onClick={() => window.open("https://muchbetter.com/", "_blank")}
          />
        </Footer>
        {info && (
          <InfoAlert>
            <div className="inner-container">
              <img
                className="close-button"
                src={infoCloseIcon}
                alt="info-close-icom"
                onClick={() => setInfo("")}
              />
              <img
                className="top-icon"
                src={infoDetails[info].icon}
                alt="icon"
              />
              <h2>{infoDetails[info].title}</h2>
              <p>{infoDetails[info].subtitle}</p>
              {infoDetails[info]?.buttonLink && (
                <div
                  onClick={() => {
                    window.open(infoDetails[info]?.buttonLink, "_blank");
                    setInfo("");
                  }}
                  className="button"
                >
                  {infoDetails[info]?.buttonLabel}
                </div>
              )}
            </div>
          </InfoAlert>
        )}
      </MainContainer>
    </>
  );
};

export default Uefa24;
