import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
import "react-phone-number-input/style.css";

import {
  getBalanceAndPin,
  hasPersonalDetailsAction,
  savePersonalDetailsAction
} from "../../features/balance-and-pin/balanceAndPinSlice";
import {
  Footer,
  InfoAlert,
  MainContainer,
  NavBar,
  PaymentAccount,
  SectionContainer,
  SectionTitle,
  Step,
  Welcome
} from "./styled/ScsPromoReady";

import usePrevious from "../../hooks/usePrevious";

import Spinner from "../../components/Spinner";

import { validateName } from "../../utils/utils";

import scsLogo from "./img/scs-logo.svg";
import scsBanner from "./img/scs-banner.png";
import bbLogo from "./img/bb.svg";
import appStoreLogo from "./img/app-store.svg";
import gplayLogo from "./img/play-store.svg";
import mmLogo from "./img/mm-logo.svg";
import digiseqLogo from "./img/digiseq-logo.png";
import paymeSwissLogo from "./img/payme-swiss-logo.png";
import errorIcon from "./img/errorIcon.svg";
import copyIcon from "./img/copy-icon.svg";
import checkTermsIcon from "./img/check-terms.svg";
import checkedTermsIcon from "./img/checked-terms.svg";

const ScsPromoReady = () => {
  const STEPS = [
    {
      id: "01",
      title: "Get Your Nails Done.",
      details: "Visit Yves Swiss to get your payment-enabled nail job."
    },
    {
      id: "02",
      title: "Activate",
      details:
        "If your nail came pre-loaded then you are good to go. If not - follow the activation methods below."
    },
    {
      id: "03",
      title: "Pay",
      details: " Tap and go with seamless transactions at your fingertips."
    }
  ];

  const [searchParams] = useSearchParams();
  const serialNumber = searchParams.get("serialNumber");
  const dispatch = useDispatch();

  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [nameError, setNameError] = useState("");
  const [phoneNotValid, setPhoneNotValid] = useState("");
  const [showUserRegistration, setShowUserRegistration] = useState(false);
  const [termsAccepted, setTermsAccepted] = useState(false);
  const [termsError, setTermsError] = useState(false);

  const {
    balanceAndPin,
    isLoading,
    hasPersonalDetails,
    gettingPersonalDetails,
    savingPersonalDetails,
    errorSavingPersonalDetails
  } = useSelector((state) => state.balanceAndPin);

  const prevSavingPersonalDetails = usePrevious(savingPersonalDetails);

  useEffect(() => {
    dispatch(hasPersonalDetailsAction(serialNumber));
  }, [dispatch, serialNumber]);

  useEffect(() => {
    if (
      prevSavingPersonalDetails &&
      !errorSavingPersonalDetails &&
      serialNumber
    ) {
      dispatch(hasPersonalDetailsAction(serialNumber));
    }
  }, [
    dispatch,
    errorSavingPersonalDetails,
    prevSavingPersonalDetails,
    serialNumber
  ]);

  useEffect(() => {
    if (!balanceAndPin && serialNumber) {
      dispatch(getBalanceAndPin({ serial: serialNumber }));
    }
  }, [balanceAndPin, serialNumber, dispatch, hasPersonalDetails]);

  const handleUserRegistration = () => {
    if (!name || !validateName(name)) {
      setNameError(true);
    } else if (!isValidPhoneNumber(phone)) {
      setPhoneNotValid(true);
    } else {
      setShowUserRegistration(false);
      dispatch(
        savePersonalDetailsAction({
          serial: serialNumber,
          details: { fullName: name, phoneNumber: phone }
        })
      );
    }
  };

  const formattedBalance = (balanceDetails) => {
    const { availableBalance, currency } = balanceDetails;
    let iconPrefixed = true;
    let icon = "";

    switch (currency) {
      case "GBP":
        icon = "£";
        break;
      case "EUR":
        icon = "€";
        break;
      case "USD":
        icon = "$";
        break;
      default:
        icon = "";
    }

    if (iconPrefixed) {
      return icon + availableBalance;
    } else {
      return availableBalance + icon;
    }
  };

  if (isLoading || gettingPersonalDetails || savingPersonalDetails)
    return <Spinner />;

  return (
    <>
      <MainContainer>
        <NavBar>
          <img src={scsLogo} alt="scs logo" />
          {hasPersonalDetails && (
            <div>
              Balance:{" "}
              {balanceAndPin?.balanceDetails?.availableBalance
                ? formattedBalance(balanceAndPin?.balanceDetails)
                : "N/A"}
            </div>
          )}
          {hasPersonalDetails === false && (
            <div
              className="view-balance"
              onClick={() => setShowUserRegistration(true)}
            >
              View Balance
            </div>
          )}
        </NavBar>
        <img src={scsBanner} alt="welcome banner" />
        <Welcome>
          <h1>Welcome to Your New Payment Nail Experience.</h1>
          <p>A unique product brought to you by SCS and DIGISEQ.</p>
        </Welcome>
        <SectionTitle>Getting Started.</SectionTitle>

        {STEPS.map((step) => (
          <SectionContainer>
            <Step key={step.id}>
              <div className="order">{step.id}</div>
              <div className="details">
                <p className="title">{step.title}</p>
                <p>{step.details}</p>
              </div>
            </Step>
          </SectionContainer>
        ))}

        <SectionTitle>Payment Account.</SectionTitle>
        <SectionContainer>
          <PaymentAccount>
            {true && (
              <>
                <img className="bb-logo" src={bbLogo} alt="bourgeois boheme" />
                <p>
                  Want to continue enjoying unrivalled payment experience?
                  Upgrade to Bourgeois Bohème wallet!
                </p>
                <p>Your wearable activation code</p>
                <div className="activation-code">
                  <h2>{balanceAndPin?.activationCode}</h2>
                  <img
                    src={copyIcon}
                    alt="copy icon"
                    onClick={() =>
                      navigator.clipboard.writeText(
                        balanceAndPin.activationCode
                      )
                    }
                  />
                </div>

                <div
                  className="button"
                  onClick={() =>
                    window.open(
                      `https://events.bobofin.com/?token=${balanceAndPin?.activationCode}`,
                      "_blank"
                    )
                  }
                >
                  {"Register"}
                </div>
                <div className="stores">
                  <img
                    src={appStoreLogo}
                    alt="app store logo"
                    onClick={() =>
                      window.open(
                        "https://apps.apple.com/us/app/bourgeois-boheme/id1574430730",
                        "_blank"
                      )
                    }
                  />
                  <img
                    src={gplayLogo}
                    alt="google play logo"
                    onClick={() =>
                      window.open(
                        "https://play.google.com/store/apps/details?id=com.paymeswiss.bobofin",
                        "_blank"
                      )
                    }
                  />
                </div>
              </>
            )}
          </PaymentAccount>
        </SectionContainer>

        <>
          <p className="or">or</p>
          <SectionContainer>
            <PaymentAccount>
              <img src={mmLogo} alt="manage-mii" />
              <p>Download the Manage-Mii app to connect your payment card.</p>
              <div className="stores">
                <img
                  src={appStoreLogo}
                  alt="app store logo"
                  onClick={() =>
                    window.open(
                      "https://apps.apple.com/gb/app/manage-mii/id6446908503",
                      "_blank"
                    )
                  }
                />
                <img
                  src={gplayLogo}
                  alt="google play logo"
                  onClick={() =>
                    window.open(
                      "https://play.google.com/store/apps/details?id=com.digiseq.managemii",
                      "_blank"
                    )
                  }
                />
              </div>
            </PaymentAccount>
          </SectionContainer>
        </>

        <Footer>
          <p>Powered by</p>
          <img
            src={digiseqLogo}
            alt="digiseq-logo"
            onClick={() => window.open("https://www.digiseq.co.uk", "_blank")}
          />
          <p>Payment services provided by</p>
          <img
            src={paymeSwissLogo}
            alt="payme-swiss-logo"
            onClick={() => window.open("https://paymeswiss.com/", "_blank")}
          />
        </Footer>
        {showUserRegistration && (
          <InfoAlert>
            <div className="inner-container">
              <h2>Enter Details</h2>
              <p>
                Please provide your details to view your balance. It will only
                be requested once.
              </p>

              <div className={`input-container ${nameError && "input-error"}`}>
                <label>Full name</label>
                <input
                  required
                  value={name}
                  onChange={(ev) => {
                    setNameError("");
                    setName(ev.target.value);
                  }}
                />
              </div>
              <div
                className={`input-container ${phoneNotValid && "input-error"}`}
              >
                <label>Phone number</label>
                <PhoneInput
                  required
                  maxLength="20"
                  defaultCountry="GB"
                  value={phone}
                  onChange={(value) => {
                    setPhoneNotValid(false);
                    setPhone(value);
                  }}
                />
                {phoneNotValid && <img src={errorIcon} alt="error icon" />}
              </div>
              {(nameError || phoneNotValid) && (
                <div className="error-msg">Please input valid information</div>
              )}
              <div className="terms">
                <img
                  onClick={() => {
                    setTermsError(false);
                    setTermsAccepted(!termsAccepted);
                  }}
                  src={termsAccepted ? checkedTermsIcon : checkTermsIcon}
                  alt="accept-terms-icon"
                />
                <p>
                  I accept the{" "}
                  <a
                    href="https://s3.eu-west-2.amazonaws.com/www.digiseq.co.uk/Terms_and_Conditions_Bourgeois_Boheme.pdf"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Terms and Conditions.
                  </a>
                </p>
              </div>
              {termsError && (
                <div className="error-msg">
                  Please accept terms and conditions.
                </div>
              )}
              <div
                className="button"
                onClick={() => {
                  if (!termsAccepted) {
                    setTermsError(true);
                  } else {
                    handleUserRegistration();
                  }
                }}
              >
                View Balance
              </div>
              <div
                onClick={() => setShowUserRegistration(false)}
                className="button button-secondary"
              >
                Cancel
              </div>
            </div>
          </InfoAlert>
        )}
      </MainContainer>
    </>
  );
};

export default ScsPromoReady;
