import React from "react";

import { H2, B2, ContentContainer } from "./styles/SharedStyles";
import {
  AppStoreLinks,
  DownloadAppContainer,
  WelcomeContainer
} from "./styles/BinChecker";

import appStoreBadge from "../tap2start/img/app-store-badge.svg";
import playStoreBadge from "../tap2start/img/play-store-badge.svg";

const SmartexWearableReady = ({ serialNumber }) => {
  const appStoreLinks = (appStoreUrl, playStoreUrl) => {
    return (
      <AppStoreLinks>
        <img
          src={appStoreBadge}
          alt="app-store"
          onClick={() => window.open(appStoreUrl, "_blank")}
        />
        <img
          src={playStoreBadge}
          alt="play-store"
          onClick={() => window.open(playStoreUrl, "_blank")}
        />
      </AppStoreLinks>
    );
  };

  return (
    <ContentContainer>
      <DownloadAppContainer>
        <WelcomeContainer>
          <H2>Welcome to HESCA</H2>
          <B2>Monday-Tuesday, 18-19th March</B2>
          <B2>
            You can check the event's schedule
            <a
              href={
                "https://s3.eu-west-2.amazonaws.com/www.digiseq.co.uk/Hesca_2024.pdf"
              }
              target="_blank"
              rel="noopener noreferrer"
            >
              here
            </a>
          </B2>
        </WelcomeContainer>
        <H2>Your keyfob is smart!</H2>
        <B2>
          Download the Manage-Mii app to connect your payment card to your
          Wearable.
        </B2>
        {appStoreLinks(
          "https://apps.apple.com/app/manage-mii/id6446908503",
          "https://play.google.com/store/apps/details?id=com.digiseq.managemii"
        )}
        <div className="label">
          Already have the app?
          <a
            href={
              "managemii://wearables/tap2start?serialNumber=" + serialNumber
            }
            target="_blank"
            rel="noopener noreferrer"
          >
            Open Manage-Mii
          </a>
        </div>
      </DownloadAppContainer>
    </ContentContainer>
  );
};

export default SmartexWearableReady;
