import React, { useState } from "react";
import { InputContainer, InputLabel, RevealPassword } from "./styled/Input";

import revealPassIcon from "./images/reveal-password-icon.svg";

const Input = ({ label, required, as, value, type = "text", ...rest }) => {
  const [inputType, setInputType] = useState(type);

  const toggleType = () =>
    inputType === "password" ? setInputType("text") : setInputType("password");

  return (
    <InputContainer>
      {value && label && (
        <InputLabel>
          {label} {required && <span>*</span>}
        </InputLabel>
      )}

      {as === "textarea" ? (
        <textarea
          rows={10}
          required={required}
          type={inputType}
          value={value}
          {...rest}
        />
      ) : (
        <input required={required} type={inputType} value={value} {...rest} />
      )}

      {type === "password" && (
        <RevealPassword
          onClick={() => toggleType()}
          src={revealPassIcon}
          alt="show password"
        />
      )}
    </InputContainer>
  );
};

export default Input;
