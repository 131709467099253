import { StyledNews } from "./styled/LatestNews";

const News = ({ image, title, subtitle }) => {
  // Image & title should come from portal via API

  return (
    <StyledNews>
      <img src={image} alt="news preview" draggable="false" />
      <h4>{title}</h4>
      <p>{subtitle}</p>
    </StyledNews>
  );
};

export default News;
