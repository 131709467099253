import httpClient from "../../axios";
import { ENV } from "../../env";

export const DAS_ADMIN = `https://www.${
  ENV !== "production" ? (ENV === "development" ? "dev" : ENV) + "-" : ""
}manage-mii.uk/api/das/admin/`;

export const DAS_DEVICE = `https://www.${
  ENV !== "production" ? (ENV === "development" ? "dev" : ENV) + "-" : ""
}manage-mii.uk/api/das/device`;

const createDasDevices = async ({
  serialNumbers,
  campaignId,
  tags,
  allowedEvents,
  vouchersEventInputDataMap,
  cryptogramNotSupported
}) => {
  const response = await httpClient.post(DAS_ADMIN + `devices/`, {
    serialNumbers,
    campaignId,
    tags,
    allowedEvents,
    vouchersEventInputDataMap,
    cryptogramNotSupported
  });
  return response.data;
};

const getDasDevice = async (serial) => {
  const response = await httpClient.get(DAS_ADMIN + `devices/${serial}`);
  return response.data;
};

const getDasDevicesByTag = async (tag) => {
  const response = await httpClient.get(DAS_ADMIN + `devices/tag/${tag}`);
  return response.data;
};

const getDasDevicesByCampaign = async (campaign) => {
  const response = await httpClient.get(
    DAS_ADMIN + `devices/campaign/${campaign}`
  );
  return response.data;
};

const editDasDevice = async ({
  serialNumber,
  tags,
  currentCampaign,
  campaignLinks
}) => {
  const response = await httpClient.put(DAS_ADMIN + `devices/${serialNumber}`, {
    currentCampaign,
    tags,
    campaignLinks
  });
  return response.data;
};

const transferTaggedDasDevices = async ({
  tag,
  toCampaignId,
  fromCampaignId
}) => {
  const fromCampaignQuery = fromCampaignId
    ? `?fromCampaign=${fromCampaignId}`
    : "";
  const response = await httpClient.put(
    DAS_ADMIN +
      `devices/tag/${tag}/campaign/${toCampaignId}/${fromCampaignQuery}`
  );
  return response.data;
};

const transferExistingDasDevices = async ({
  serialNumbers,
  toCampaign,
  tags,
  allowedEvents,
  vouchersEventInputDataMap,
  cryptogramNotSupported
}) => {
  const response = await httpClient.put(
    DAS_ADMIN + `devices/campaign/${toCampaign}`,
    {
      serialNumbers,
      tags,
      allowedEvents,
      vouchersEventInputDataMap,
      cryptogramNotSupported
    }
  );
  return response.data;
};

// GET device info
const getDasDeviceInfo = async (serial) => {
  const response = await httpClient.get(DAS_DEVICE + `/${serial}/device-info`);

  return response.data;
};

const deviceManagerService = {
  getDasDevice,
  getDasDeviceInfo,
  editDasDevice,
  createDasDevices,
  getDasDevicesByTag,
  getDasDevicesByCampaign,
  transferTaggedDasDevices,
  transferExistingDasDevices
};

export default deviceManagerService;
