import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { getBalanceAndPin } from "../../features/balance-and-pin/balanceAndPinSlice";
import { formattedBalance } from "../../features/balance-and-pin/utils";
import Spinner from "../../components/Spinner";

import {
  AppStoreLinks,
  CurvelogoContainer,
  Footer,
  HeroImg,
  Logos,
  MainContainer,
  Navbar,
  NextStepsContainer,
  PaymentAccount,
  StepContainer,
  StepNumber,
  Welcome
} from "./styles/Pepcity";

import logo from "./img/logo.svg";
import heroImg from "./img/citiart.svg";
import footerLogos from "./img/footer-logos.svg";
import boboLogo from "./img/bobo-logo.png";
import copyIcon from "./img/copy-icon.svg";
import chevronRight from "./img/chevron-right.svg";
import appStoreLogo from "./img/app-store.svg";
import gplayLogo from "./img/play-store.svg";
import curveLogo from "./img/curve_logo.png";
import payAnyWhereImage from "../tap2start/img/pay-anywhere-image.png";
import euFlag from "./img/eu-flag.png";
import ukFlag from "./img/uk-flag.png";
import orSeparator from "./img/or.svg";
import digiseqLogo from "./img/digiseq-logo.png";

const Pepcity = () => {
  const [searchParams] = useSearchParams();
  const serialNumber = searchParams.get("serialNumber");

  const dispatch = useDispatch();
  const { balanceAndPin, isLoading } = useSelector(
    (state) => state.balanceAndPin
  );

  useEffect(() => {
    if (!balanceAndPin && serialNumber) {
      dispatch(
        getBalanceAndPin({
          serial: serialNumber,
          query: "?provider=pannovate"
        })
      );
    }
  }, [balanceAndPin, serialNumber, dispatch]);

  const appStoreLinks = (appStoreUrl, playStoreUrl) => {
    return (
      <AppStoreLinks>
        <img
          src={appStoreLogo}
          alt="app-store"
          onClick={() => window.open(appStoreUrl, "_blank")}
        />
        <img
          src={gplayLogo}
          alt="play-store"
          onClick={() => window.open(playStoreUrl, "_blank")}
        />
      </AppStoreLinks>
    );
  };

  if (isLoading) return <Spinner />;

  return (
    <MainContainer>
      <Navbar>
        <img alt="logo" src={logo} />
        <div className="balance">
          <span>BALANCE:</span>
          <span className="ammount">
            {formattedBalance(
              balanceAndPin?.balanceDetails?.availableBalance,
              balanceAndPin?.balanceDetails?.currency
            )}
          </span>
        </div>
      </Navbar>
      <HeroImg src={heroImg} alt="city art" />
      <Welcome>
        <h1>WELCOME TO LONDON!</h1>
        <p>
          {
            "On behalf of PepsiCo, we are delighted to welcome you to the UEFA Champions League Final 2024 in London and the PepCity Guest Programme!"
          }
        </p>
        <p>
          {
            "Your PepCity Pass gives you access to travel London whilst you are in the city, giving you a chance to explore and sightsee at your own leisure. Just tap your PepCity Pass in and out of the contactless readers in London Underground Stations and on entry to buses. Please be advised that travel funds will be deactivated at 23:59 on Sunday 2nd June after which time it can no longer be used for travel."
          }
        </p>

        <p>
          {
            "When you return home you have the option to link your own personal payment card to your PepCity Pass and carry on using it for contactless payments. You can do this by following the links below."
          }
        </p>
        <p>
          {
            "Please visit the PepCity Welcome Desk in your hotel if you have any queries or call the PepCity Hotline on  +44  7741 682 428."
          }
        </p>
      </Welcome>
      <Logos>
        <img src={footerLogos} alt="footer-logos" />
        <p>{"ENJOY THE MATCH"}</p>
      </Logos>
      <PaymentAccount>
        <p className="title">Payment Account</p>
        <p className="subtitle">Prepaid</p>
        <div className="inner">
          <img className="logo" src={boboLogo} alt="bourgeois boheme" />
          <p>
            Want to continue enjoying unrivaled payment experience? Upgrade to
            Bourgeois Bohème wallet!
          </p>
          <p>Your wearable activation code</p>
          <div className="activation-code">
            <span>{balanceAndPin?.activationCode || "N/A"}</span>
            <img
              src={copyIcon}
              alt="copy"
              onClick={() =>
                navigator.clipboard.writeText(balanceAndPin?.activationCode)
              }
            />
          </div>
          <div
            className="button"
            onClick={() => window.open(`https://uefa.bobofin.com/`, "_blank")}
          >
            {"Join Bourgeois Bohème"}{" "}
            <img src={chevronRight} alt="chevron right" />
          </div>
        </div>
        {appStoreLinks(
          "https://apps.apple.com/us/app/bourgeois-boheme/id1574430730",
          "https://play.google.com/store/apps/details?id=com.paymeswiss.bobofin"
        )}
      </PaymentAccount>
      <NextStepsContainer>
        <CurvelogoContainer>
          <img className="separator" src={orSeparator} alt="or" />
          <p className="title">Link your card</p>
          <img className="curve-logo" src={curveLogo} alt="curve logo" />
          <div className="flags">
            <img src={euFlag} alt="eu flag" />{" "}
            <img src={ukFlag} alt="uk flag" />
          </div>
        </CurvelogoContainer>
        <StepContainer>
          <StepNumber>1</StepNumber>
          <h2>Create a Curve Account</h2>
          <p>
            Register your card details with Curve to receive a free compatible
            digital bank card.
          </p>
          <p>Download the Curve App:</p>
          {appStoreLinks(
            "https://apps.apple.com/gb/app/curve-supercharge-your-money/id1049397112",
            "https://play.google.com/store/apps/details?id=com.imaginecurve.curve.prd"
          )}
        </StepContainer>
        <StepContainer>
          <StepNumber>2</StepNumber>
          <h2>Link Your Curve Virtual Card Using the Manage-Mii App</h2>
          <p>
            Follow the instructions on the Manage-Mii app and use your new Curve
            Card details when prompted.
          </p>
          {appStoreLinks(
            "https://apps.apple.com/app/manage-mii/id6446908503",
            "https://play.google.com/store/apps/details?id=com.digiseq.managemii"
          )}
        </StepContainer>
        <StepContainer>
          <StepNumber>3</StepNumber>
          <h2>Pay Anywhere!</h2>
          <p>
            Tap your wearable at any card terminal where Mastercard Contactless
            is enabled.
          </p>
          <img
            className="pay-anywhere"
            src={payAnyWhereImage}
            alt="pay anywhere"
          />
        </StepContainer>
      </NextStepsContainer>

      <Footer>
        <p>Powered by</p>
        <div className="companies">
          <img
            src={digiseqLogo}
            alt="digiseq-logo"
            onClick={() => window.open("https://www.digiseq.co.uk", "_blank")}
          />
        </div>
      </Footer>
    </MainContainer>
  );
};

export default Pepcity;
