import styled from "styled-components";

export const MainContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding: 0 60px;
  border-top: 4px solid #e8e8ed;
  border-bottom: 4px solid #e8e8ed;
  padding: 10px 20px;
  margin: 40px;

  h4 {
    font-weight: 400;
    font-size: 1.4rem;
    line-height: 25px;
  }

  img {
    width: 30px;
    height: 30px;
    cursor: pointer;
  }
`;

export const SideContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 5px;

  @media (min-width: 768px) {
    margin: 0 40px;
  }
`;

export const DropdownContainer = styled.div`
  position: absolute;
  top: 35px;
  left: ${(props) => (props.left ? "0px" : "-70px")};
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background-color: white;
  border: 0.7px solid #aeb1b8;
  width: 168px;
  padding: 20px;
`;

export const DropdownElement = styled.div`
  cursor: pointer;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 10px;
  img {
    margin-right: 12px;
  }
`;

export const Counter = styled.div`
  h4 {
    margin-right: 10px;
    color: #797b80;
  }
  span {
    margin-left: 5px;
  }
`;

export const FilterSortButton = styled.div`
  display: flex;
  cursor: pointer;
  img {
    margin-top: -10px;
  }
`;
