import styled from "styled-components";

export const StyledHeading = styled.section`
  width: 100%;
  max-width: 600px;
  flex-direction: column;
  align-items: center;
  h1 {
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 400;
    text-align: center;
  }
  p {
    font-size: 0.875rem;
    font-weight: 400;
    color: #797b80;
    text-align: center;
    margin-bottom: 10px;
    padding: 0 20px;
  }
  @media (min-width: 640px) {
    display: flex;
    h1 {
      font-size: 1.75rem;
      line-height: 30px;
    }

    p {
      padding: 0;
    }
  }
`;

export const Registered = styled.div`
  display: inline-block;
  font-size: 2rem;
  vertical-align: text-top;
  @media (min-width: 640px) {
    display: inline-block;
    font-size: 2.5rem;
    vertical-align: text-top;
  }
`;
