import React, { useState, useEffect } from "react";
import VCard from "vcard-creator";
import QRCode from "qrcode";
import {
  MainContainer,
  Nav,
  NameSection,
  Photo,
  DetailsContainer,
  Item,
  QR,
  SocialIcons
} from "./styled/BusinessCard";

import downloadIcon from "./img/download.svg";
import instagramIcon from "./img/instagram.svg";
import twitterIcon from "./img/twitter.svg";
import linkedinIcon from "./img/linkedin.svg";
import whatsappIcon from "./img/whatsapp.svg";
import { useNavigate, useParams } from "react-router-dom";

import mockedData from "./mocked-data/people";

const BusinessCardMocked = () => {
  const [qrUrl, setQrUrl] = useState("");
  const [downloadData, setDownloadData] = useState("");

  const params = useParams();
  const navigate = useNavigate();

  function download(filename, text) {
    const element = document.createElement("a");
    element.setAttribute(
      "href",
      "data:text/x-vcard;charset=utf-8," + encodeURIComponent(text)
    );
    element.setAttribute("download", filename);

    element.style.display = "none";
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
  }

  useEffect(() => {
    const generateQR = async (text) => {
      try {
        const qr = await QRCode.toDataURL(text);
        setQrUrl(qr);
      } catch (err) {
        console.error(err);
      }
    };

    if (mockedData.hasOwnProperty(params.id)) {
      const { name, surname, title, company, email, phone, address } =
        mockedData[params.id];

      const [street, worktown, workpostcode, country] = address.split(";");
      const vCard = new VCard();
      vCard
        .addName(surname, name, "", "", "")
        .addCompany(company)
        .addJobtitle(title)
        .addEmail(email)
        .addPhoneNumber(phone, "WORK")
        .addAddress("", "", street, worktown, "", workpostcode, country);
      generateQR(vCard.toString());
      setDownloadData(vCard.buildVCard());
    } else navigate("/");
  }, [navigate, params.id]);

  if (mockedData.hasOwnProperty(params.id)) {
    return (
      <MainContainer>
        <Photo>
          <img src={mockedData[params.id].photo} alt="user" />
        </Photo>

        <DetailsContainer>
          <Nav>
            <img src={mockedData[params.id].companyLogo} alt="logo" />
            <img
              style={{ cursor: "pointer" }}
              src={downloadIcon}
              alt="download"
              onClick={() =>
                download(
                  `${mockedData[params.id].name}-${
                    mockedData[params.id].surname
                  }`,
                  downloadData
                )
              }
            />
          </Nav>
          <Item>
            <NameSection>
              <h2>
                {mockedData[params.id].name} {mockedData[params.id].surname}
              </h2>
              <p>
                {mockedData[params.id].title} • {mockedData[params.id].company}
              </p>
            </NameSection>
          </Item>
          <Item>
            <p>Email</p>
            <span>{mockedData[params.id].email}</span>
          </Item>
          <Item>
            <p>Phone number</p>
            <span>{mockedData[params.id].phone}</span>
          </Item>
          <Item>
            <p>Address</p>

            <div className="address-lines">
              {mockedData[params.id].address.split(";").map((e, i) => (
                <span key={i}>{e}</span>
              ))}
            </div>
          </Item>

          <Item>
            <p>Social</p>
            <SocialIcons>
              {mockedData[params.id].social.instagram && (
                <a
                  href={mockedData[params.id].social.instagram}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={instagramIcon} alt="instagram" />
                </a>
              )}
              {mockedData[params.id].social.linkedin && (
                <a
                  href={mockedData[params.id].social.linkedin}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={linkedinIcon} alt="linkedin" />
                </a>
              )}
              {mockedData[params.id].social.twitter && (
                <a
                  href={mockedData[params.id].social.twitter}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={twitterIcon} alt="twitter" />
                </a>
              )}
              {mockedData[params.id].social.whatsapp && (
                <a
                  href={mockedData[params.id].social.whatsapp}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={whatsappIcon} alt="whatsapp" />
                </a>
              )}
            </SocialIcons>
          </Item>

          {qrUrl && <QR alt="qr" src={qrUrl} />}
        </DetailsContainer>
      </MainContainer>
    );
  }
  return null;
};

export default BusinessCardMocked;
