import { css } from "styled-components";
import styled from "styled-components";

const colors = {
  primary: "#F2572F",
  secondary: "#FFBF6B",
  background: "#FADFB5",
  text: "#5A3500",
  nav: "#FFAA3B",
  footer: "#FFAA3B"
};

const buttonStyles = css`
  background-color: ${colors.primary};
  color: white;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  font-size: 1rem;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px 8px 8px 16px;

  &:hover {
    background-color: ${colors.secondary};
  }
`;

const Button = styled.div`
  ${buttonStyles}
`;

const MainContainer = styled.div`
  width: 100%;
  max-width: 480px;
  margin: auto;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  padding: 0;
  font-family: "Carme", sans-serif;
  font-weight: 400;
  font-style: normal;
  background-color: ${colors.background};
  color: ${colors.text};
  h1,
  h2,
  p {
    color: #5a3500;
  }

  h1 {
    text-transform: uppercase;
    font-family: "Carme", sans-serif;
    font-size: 20px;
    font-weight: 400;
    line-height: 32px;
    text-align: center;
  }

  h2 {
    font-family: "Carme", sans-serif;
    font-size: 28px;
    font-style: normal;
    font-weight: 400;
    line-height: 32px;
  }

  p {
    font-family: "Carme", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.5px;
  }
`;

const Nav = styled.nav`
  background-color: #ffaa3b;
  display: flex;
  align-items: center;
  padding: 8px 16px;
  justify-content: space-between;

  img {
    max-height: 42px;
  }
`;

const Separator = styled.div`
  border-bottom: 2px solid #f2572f;
  align-self: stretch;

  background: #fadfb5;
`;
const InnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;
  padding: 16px;

  p {
    text-align: center;
  }

  .round-logo {
    max-height: 142px;
    margin: auto;
    object-fit: contain;
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-self: stretch;
  gap: 16px;
  padding: 24px 16px;
  border-radius: 6px;
  background-color: ${colors.secondary};
  h2,
  p {
    text-align: left;
  }

  .hyperjar-logo {
    max-height: 48px;
    max-width: 171px;
  }
`;

const Footer = styled.footer`
  background-color: ${colors.footer};
  padding: 24px 16px 48px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;

  div {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  p {
    text-align: center;
    font-size: 12px;
  }

  img {
    max-height: 24px;
  }
`;

const ContactDetails = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  background-color: ${colors.secondary};
  padding: 24px 16px;
  .detail {
    display: flex;
    gap: 16px;
  }
`;

const InfoAlert = styled.div`
  max-width: 480px;
  position: absolute;
  background: rgba(9, 10, 15, 0.64);
  height: 100%;
  width: 100%;
  text-align: center;

  .inner-container {
    position: fixed;
    max-width: 360px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 40px 24px;
    border-radius: 8px;
    background-color: #fff;
    width: 90%;
    z-index: 9999;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 8px;

    form {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 24px;

      .input-container {
        display: flex;
        gap: 16px;
        width: 100%;
        border: 1px solid #5a3500;
        border-radius: 8px;
        padding: 16px;
      }

      input {
        width: 100%;
        border: none;

        font-size: 16px;
        &:focus {
          outline: none;
        }
      }
    }
  }
`;

const FormButton = styled.div`
  align-self: stretch;
  display: flex;
  min-width: 144px;
  padding: 16px 40px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  background: ${({ outlined }) => (outlined ? "white" : `${colors.primary}`)};
  border: ${({ outlined }) =>
    outlined ? `1px solid ${colors.primary}` : "none"};
  border-radius: 8px;
  color: ${({ outlined }) => (outlined ? `${colors.primary}` : "white")};
`;

export {
  Nav,
  Separator,
  ContactDetails,
  MainContainer,
  InnerContainer,
  Button,
  Container,
  colors,
  Footer,
  InfoAlert,
  FormButton
};
