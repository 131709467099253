import React, { useState } from "react";

import {
  MainContainer,
  SideContainer,
  FilterSortButton
} from "./styled/FilterBar";

import ArrowDownIcon from "./images/arrow-down.svg";
import ArrowUpIcon from "./images/arrow-up.svg";
import SortDropdown from "./SortDropdown";
import FilterDropdown from "./FilterDropdown";
import { Link, useLocation } from "react-router-dom";

const FilterBar = () => {
  const [showFilter, setShowFilter] = useState(false);
  const [showSort, setShowSort] = useState(false);

  const location = useLocation();
  const isCurrent = (pathname) => location.pathname.includes(pathname);

  return (
    <MainContainer>
      {isCurrent("products") && (
        <SideContainer>
          <FilterSortButton onClick={() => setShowFilter(!showFilter)}>
            <h4>All</h4>
            <img
              src={showFilter ? ArrowUpIcon : ArrowDownIcon}
              alt="menu arrow"
            />
          </FilterSortButton>

          <FilterDropdown
            visible={showFilter}
            setVisible={() => setShowFilter(false)}
          />
        </SideContainer>
      )}
      <Link
        to={isCurrent("brands") ? "/products" : "/brands"}
        style={{ margin: "auto" }}
      >
        <h4>{`Shop by ${isCurrent("brands") ? "products" : "brands"}`}</h4>
      </Link>
      {/* {isCurrent("products") && (
        <SideContainer>
          <FilterSortButton onClick={() => setShowSort(!showSort)}>
            <h4>Sort by</h4>
            <img
              src={showSort ? ArrowUpIcon : ArrowDownIcon}
              alt="menu arrow"
            />
          </FilterSortButton>
          <SortDropdown
            visible={showSort}
            setVisible={() => setShowSort(false)}
          />
        </SideContainer>
      )} */}
    </MainContainer>
  );
};

export default FilterBar;
