import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
  Banner,
  Content,
  MainContainer,
  Question,
  QuestionContainer,
  QuestionsContainer
} from "./styled/Feedback";
import {
  getFeedbackForm,
  submitFeedbackForm
} from "../../features/caged-steel/cagedSteelSlice";

import ascotLogo from "./img/ascot-logo-white.svg";
import { ActionButton, ErrorText } from "./styled/SharedStyles";
import AlertComponent from "./components/AlertComponent";
import { StyledSpinner } from "../../components/styled/Spinner";

const Feedback = () => {
  const params = useParams();
  const dispatch = useDispatch();

  const [answers, setAnswers] = useState([]);

  const { serial } = params;

  const { feedbackForm, feedbackFormSubmited, isError, isLoading } =
    useSelector((state) => state.cagedSteel);

  useEffect(() => {
    if (!feedbackForm) {
      dispatch(getFeedbackForm(serial));
    }
  }, [feedbackForm, dispatch, serial]);

  function handleSubmit() {
    dispatch(
      submitFeedbackForm({
        serial,
        answers
      })
    );

    window.scrollTo({ top: 0, behavior: "auto" });
  }

  if (isLoading)
    return (
      <MainContainer>
        <BannerTop />
        <StyledSpinner />
      </MainContainer>
    );

  if (isError)
    return (
      <MainContainer>
        <BannerTop />
        <ErrorText>
          We are experiencing a problem. Try refreshing the page or come back
          later.
        </ErrorText>
      </MainContainer>
    );

  return (
    <MainContainer>
      <BannerTop />
      <Content>
        <QuestionsContainer>
          {feedbackForm?.questions.map((e) => (
            <QuestionContainer key={e.position}>
              {e.answerType === "MULTIPLE_CHOICE" &&
                e.answerOptions.map((option, pos) => (
                  <div>
                    {pos === 1 && <p>or</p>}
                    <Question
                      selected={option === answers[e.position]?.selectedChoice}
                      onClick={() => {
                        const updatedAnswers = [...answers];
                        updatedAnswers[e.position] = {
                          position: e.position,
                          selectedChoice: option
                        };
                        setAnswers(updatedAnswers);
                      }}
                      key={pos}
                    >
                      {option}
                    </Question>
                  </div>
                ))}
            </QuestionContainer>
          ))}
        </QuestionsContainer>
        <ActionButton
          disabled={answers.length === 0}
          onClick={() => handleSubmit()}
        >
          SUBMIT
        </ActionButton>
      </Content>
      {feedbackFormSubmited && (
        <AlertComponent
          title={"THANK YOU!"}
          subtitle="Thank you for feedback. Enjoy your day at Royal Ascot."
        />
      )}
    </MainContainer>
  );
};

const BannerTop = () => (
  <Banner>
    <img src={ascotLogo} alt="ascot logo" />
    <h1>QUESTIONNAIRE</h1>
    <p>Parents questions. Let us know your preferences!</p>
  </Banner>
);

export default Feedback;
