import React, { Suspense } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  Image,
  Brand,
  Model,
  ImageContainer,
  WearableContainer
} from "./styled/Products";

import {
  resetDevice,
  resetDevicesByBrand
} from "../../../features/devices/deviceSlice";
import Spinner from "../../../components/Spinner";

const Wearable = ({ device = {}, featured }) => {
  const { brandDisplayName, modelDisplayName, defaultImage } = device;
  const dispatch = useDispatch();
  const navigate = useNavigate();

  if (device?.defaultImage)
    return (
      <WearableContainer
        data-testid="rendered-product"
        onClick={() => {
          dispatch(resetDevice());
          dispatch(resetDevicesByBrand());
          navigate(
            `/products/${device.brand}/${device.model.name}/${device.model.version}`
          );
        }}
      >
        <Suspense fallback={<Spinner />}>
          <ImageContainer data-testid="image-container">
            <Image src={defaultImage} alt="wearable" />
          </ImageContainer>
          <Model>{modelDisplayName}</Model>
          <Brand>{brandDisplayName}</Brand>
        </Suspense>
      </WearableContainer>
    );
  return null;
};

export default Wearable;
