import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { getBalanceAndPin } from "../../features/balance-and-pin/balanceAndPinSlice";
import { formattedBalance } from "../../features/balance-and-pin/utils";

import ExpandingContainer from "./ExpandingContainer";
import {
  DeviceItem,
  EventPartners,
  Footer,
  InfoAlert,
  ListContainer,
  ListItem,
  MainContainer,
  NavBar,
  Welcome
} from "./styles/Freemans";

import payingImg from "./img/paying.webp";
import accessImg from "./img/access.webp";
import freemansLogo from "./img/freemans-logo.webp";
import digiseqLogo from "./img/digiseq.webp";
import gpLogo from "./img/global-payments.svg";
import chatIcon from "./img/chat-icon.svg";
import mailIcon from "./img/mail-icon.svg";
import phoneIcon from "./img/phone-icon.svg";
import chevronRight from "./img/chevron-right.svg";

const Freemans = () => {
  const [searchParams] = useSearchParams();
  const serialNumber = searchParams.get("serialNumber");
  const dispatch = useDispatch();

  const { balanceAndPin, isLoading } = useSelector(
    (state) => state.balanceAndPin
  );

  const [showPaying, setShowPaying] = useState(false);
  const [showAccess, setShowAccess] = useState(false);

  useEffect(() => {
    if (!balanceAndPin && serialNumber) {
      dispatch(
        getBalanceAndPin({
          serial: serialNumber,
          query: "?provider=much_better"
        })
      );
    }
  }, [balanceAndPin, serialNumber, dispatch]);

  return (
    <MainContainer>
      <NavBar>
        <img src={freemansLogo} alt="freemans" />
        <p>{`Balance: ${formattedBalance(
          balanceAndPin?.balanceDetails?.availableBalance,
          balanceAndPin?.balanceDetails?.currency
        )}`}</p>
      </NavBar>
      <Welcome>
        <h1>Welcome to Freemans</h1>
      </Welcome>
      <ExpandingContainer title="Your device">
        <ListContainer>
          {/* <DeviceItem>
            <p>DeviceId</p>
            <p className="detail">1234567</p>
          </DeviceItem> */}
          <DeviceItem>
            <p>Balance</p>
            <p className="detail">{`${formattedBalance(
              balanceAndPin?.balanceDetails?.availableBalance,
              balanceAndPin?.balanceDetails?.currency
            )}`}</p>
          </DeviceItem>
          {/* <DeviceItem>
            <p>Daily limit remaining</p>
            <p className="detail">£10</p>
          </DeviceItem> */}
          {/* <DeviceItem>
            <p>Active until</p>
            <p className="detail">15 May 2024</p>
          </DeviceItem> */}
          <DeviceItem>
            <p>Type</p>
            <p className="detail">Freemans Keyfob</p>
          </DeviceItem>
          {/* <DeviceItem>
            <p>Location</p>
            <p className="detail">Lorem</p>
          </DeviceItem> */}
        </ListContainer>
      </ExpandingContainer>
      <ExpandingContainer title="Contact information">
        <ListContainer>
          {/* <ListItem>
            <div className="text-container">
              <p>Freemans Technical Team Chat</p>
              <p className="subtitle">
                Tap to start chat with Freemans Technical Team
              </p>
            </div>
            <img src={chatIcon} alt="chat" />
          </ListItem> */}
          <ListItem>
            <div className="text-container">
              <p>Freemans Technical Support E-mail</p>
              <p className="subtitle">Tap to submit request for support.</p>
            </div>
            <img src={mailIcon} alt="email" />
          </ListItem>
          <ListItem>
            <div className="text-container">
              <p>Call Freemans Technical Team</p>
              <p className="subtitle">
                Tap to call support team. Roaming fees may apply.{" "}
              </p>
            </div>
            <img src={phoneIcon} alt="phone" />
          </ListItem>
        </ListContainer>
      </ExpandingContainer>
      <ExpandingContainer title="How to use">
        <ListContainer>
          <p className="description">
            Check out the ways you can use your key fob
          </p>
          <ListItem onClick={() => setShowPaying(true)}>
            <div className="text-container">
              <p>Payment</p>
              <p className="subtitle">
                Use your fob to purchase your meals on site from a selection of
                FEP units
              </p>
            </div>
            <img src={chevronRight} alt="chevron right" />
          </ListItem>
          {/* <ListItem onClick={() => setShowAccess(true)}>
            <div className="text-container">
              <p>Access</p>
              <p className="subtitle">View my membership details.</p>
            </div>
            <img src={chevronRight} alt="chevron right" />
          </ListItem> */}
        </ListContainer>
      </ExpandingContainer>
      <EventPartners />
      <Footer>
        <p>Powered by</p>
        <img className="digiseq" src={digiseqLogo} alt="digiseq" />
        <img className="globalP" src={gpLogo} alt="globalpayments" />
      </Footer>
      {showPaying && (
        <InfoAlert>
          <div className="inner-container">
            <img src={payingImg} alt="paying" />
            <div className="wrapper">
              <h3>PAYING WITH KEYFOB</h3>
              <p>
                To pay with your Freemans Keyfob you need to tap it on any
                Payment Terminal at the Point of Sales and wait until the
                terminal reads the card and proceeds with transaction.
              </p>
              <div
                onClick={() => setShowPaying(false)}
                className="close-button"
              >
                Close
              </div>
            </div>
          </div>
        </InfoAlert>
      )}
      {showAccess && (
        <InfoAlert>
          <div className="inner-container">
            <img src={accessImg} alt="access" />
            <div className="wrapper">
              <h3>ON-PREMISE ACCESS</h3>
              <p>
                If the location is enabled with contactless access - tap your
                keyfob at the gates to enter the premise.
              </p>
              <div
                onClick={() => setShowAccess(false)}
                className="close-button"
              >
                Close
              </div>
            </div>
          </div>
        </InfoAlert>
      )}
    </MainContainer>
  );
};

export default Freemans;
