import axios from "axios";
import { ENV } from "../../env";

export const API_URL = `https://www.${
  ENV !== "production" ? (ENV === "development" ? "dev" : ENV) + "-" : ""
}manage-mii.uk/api/das`;

const collectItem = async (serial, itemId) => {
  const response = await axios.post(
    API_URL + `/scavenger-hunt/item-collector/${serial}/collected`,
    {
      itemCollected: itemId
    }
  );

  return response.data;
};

const worlds24Service = {
  collectItem
};

export default worlds24Service;
