import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getBalanceAndPin } from "../../features/balance-and-pin/balanceAndPinSlice";
import { formattedBalance } from "../../features/balance-and-pin/utils";

import Spinner from "../../components/Spinner";

import { MainContainer } from "./styles";

const McLear = () => {
  const [searchParams] = useSearchParams();
  const serialNumber = searchParams.get("serialNumber");

  const dispatch = useDispatch();
  const { balanceAndPin, isLoading } = useSelector(
    (state) => state.balanceAndPin
  );

  useEffect(() => {
    if (!balanceAndPin && serialNumber) {
      dispatch(
        getBalanceAndPin({
          serial: serialNumber,
          query: "?provider=much_better"
        })
      );
    }
  }, [balanceAndPin, serialNumber, dispatch]);

  if (isLoading) return <Spinner />;

  return (
    <MainContainer>
      <div className="evr-container">
        <div className="evr-section">
          <div className="evr-logo">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 298.15 50.06">
              <path
                className="cls-1"
                d="M31.83,44.9a23.26,23.26,0,1,0-17.13,0h0a.81.81,0,0,1-.17,1.54,2.79,2.79,0,0,1-.64.07H7.48a.25.25,0,0,0-.24.18,5.68,5.68,0,0,0-.19,1.6,5.8,5.8,0,0,0,.19,1.6.25.25,0,0,0,.24.17H39.05a.24.24,0,0,0,.24-.17,5.42,5.42,0,0,0,.19-1.6,5.31,5.31,0,0,0-.19-1.6.24.24,0,0,0-.24-.18H32.64a2.53,2.53,0,0,1-.61-.06A.81.81,0,0,1,31.83,44.9ZM5.07,23.27a18.2,18.2,0,1,1,18.2,18.2A18.21,18.21,0,0,1,5.07,23.27Z"
              ></path>
              <g className="logotype">
                <polygon
                  className="cls-1"
                  points="65.97 46.16 89.69 46.16 89.69 40.36 72.39 40.36 72.39 32.98 86.59 32.98 86.59 27.36 72.39 27.36 72.39 19.94 89.69 19.94 89.69 14.68 65.97 14.68 65.97 46.16"
                ></polygon>
                <polygon
                  className="cls-1"
                  points="113.98 37.87 106.45 14.68 99.9 14.68 110.1 46.16 117.5 46.16 127.93 14.68 121.51 14.68 113.98 37.87"
                ></polygon>
                <polygon
                  className="cls-1"
                  points="138.37 46.16 162.09 46.16 162.09 40.36 144.79 40.36 144.79 32.98 158.99 32.98 158.99 27.36 144.79 27.36 144.79 19.94 162.09 19.94 162.09 14.68 138.37 14.68 138.37 46.16"
                ></polygon>
                <rect
                  className="cls-1"
                  x="213.02"
                  y="14.68"
                  width="6.42"
                  height="31.48"
                ></rect>
                <polygon
                  className="cls-1"
                  points="253.44 36.08 240.73 14.68 234.13 14.68 234.13 46.16 240.01 46.16 240.01 24.49 252.9 46.16 259.28 46.16 259.28 14.68 253.44 14.68 253.44 36.08"
                ></polygon>
                <path
                  className="cls-1"
                  d="M191.54,34.39h-.88v-.62a10.77,10.77,0,0,0,2.94-.58A7.46,7.46,0,0,0,196.69,31,9.41,9.41,0,0,0,199,24.71a10.08,10.08,0,0,0-3.52-7.85c-2.85-2.23-6.55-2.18-9.36-2.18H175V46.16h6.47v-12h4.59l6.42,12h7.36l-5.8-10.29A2.89,2.89,0,0,0,191.54,34.39ZM185,29.08h-3.47V19.81h4.37a8.58,8.58,0,0,1,4.63,1,4,4,0,0,1,1.83,3.47,4.32,4.32,0,0,1-2.59,4.24A13,13,0,0,1,185,29.08Z"
                ></path>
                <path
                  className="cls-1"
                  d="M292.54,39.21A11.77,11.77,0,0,1,285.19,41c-5.95-.41-8.33-5.39-8.33-11,0-5.31,2.63-10.75,8.33-10.75a6.82,6.82,0,0,1,7.11,5.87h5.85a17.13,17.13,0,0,0-.3-2,12.39,12.39,0,0,0-12.15-9.39c-10.74,0-15.23,8.44-15.23,16.25,0,8,4.2,16.43,15,16.47a15.63,15.63,0,0,0,7-1.87v3.95h5.59V31.85h-5.59Z"
                ></path>
              </g>
            </svg>
          </div>
          <div className="evr-balance">
            <h3>Your Ring Balance</h3>
            <p>{`${formattedBalance(
              balanceAndPin?.balanceDetails?.availableBalance,
              balanceAndPin?.balanceDetails?.currency
            )}`}</p>
          </div>
        </div>
      </div>
    </MainContainer>
  );
};

export default McLear;
