import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  createCampaign,
  getCampaigns,
  resetCampaign
} from "../../features/campaign-manager/campaignManagerSlice";
import ProtectedRoute from "../../components/ProtectedRoute";
import { StyledSpinner } from "../../components/styled/Spinner";
import NavBarComponent from "./components/NavBar";
import { Button, Input } from "./components/styled/SharedStyles";
import { MainContainer, ErrorMsg, Body } from "./styled/SharedStyles";

const CreateCampaign = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [name, setName] = useState("");
  const [description, setDescription] = useState("");

  const { newCampaign, isLoading, isError, message } = useSelector(
    (state) => state.campaigns
  );

  useEffect(() => {
    if (newCampaign?.id) {
      dispatch(getCampaigns());
      navigate("/campaign-manager");
    }

    return () => {
      dispatch(resetCampaign());
    };
  }, [dispatch, navigate, newCampaign]);

  const handleCreateCampaign = () => {
    dispatch(
      createCampaign({
        id: name
          .toLowerCase()
          .replace(/[^a-z0-9\s]/g, "")
          .replace(/\s/g, "_"),
        name,
        description
      })
    );
  };
  return (
    <MainContainer>
      <ProtectedRoute />
      <NavBarComponent
        leftText={"Cancel"}
        title="New Campaign"
        to="/campaign-manager"
      />
      <Body>
        <Input
          id="input-name"
          type="name"
          placeholder="Name"
          autoFocus
          required
          value={name}
          onChange={(ev) => {
            setName(ev.target.value);
          }}
        />
        <Input
          id="input-description"
          type="description"
          placeholder="Description"
          required
          value={description}
          onChange={(ev) => setDescription(ev.target.value)}
        />
        <Button onClick={() => handleCreateCampaign()}>Create Campaign</Button>
        {isError && (
          <ErrorMsg>
            {message ||
              "An error has occured, please try again later or contact support."}
          </ErrorMsg>
        )}
        {isLoading && <StyledSpinner />}
      </Body>
    </MainContainer>
  );
};

export default CreateCampaign;
