import React from "react";
import Wearable from "../pages/products/components/Wearable";

import {
  BrandImage,
  FilterSide,
  ImageContainer,
  ImageSide,
  MainContainer
} from "./styled/TopSectionWithFilter";

const TopSectionWithFilter = ({ headerText, device, brand }) => {
  return (
    <MainContainer>
      <FilterSide>
        <h2>{headerText}</h2>
      </FilterSide>

      <ImageSide>
        <ImageContainer>
          {device && <Wearable device={device} featured />}
          {brand?.coverImage && (
            <BrandImage src={brand.coverImage} alt="brand cover" />
          )}
        </ImageContainer>
      </ImageSide>
    </MainContainer>
  );
};

TopSectionWithFilter.defaultProps = {
  device: {},
  brand: {}
};

export default TopSectionWithFilter;
