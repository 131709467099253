import styled from "styled-components";

export const theme = {
  colors: {
    error: "#D92B16",
    background: "#F6F6F7",
    divider: "#E0E0E0",
    highEmphasis: "#202020",
    medEmphasis: "#626262",
    surface: "#ffffff",
    primary: "#0D72FF"
  }
};

export const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
  max-width: 480px;
  margin: auto;
  min-height: 100vh;
  background-color: ${theme.colors.background};
  padding: 16px 16px 64px;

  h1 {
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px; /* 150% */
    letter-spacing: 0.15px;
    text-align: center;
    color: ${theme.colors.error};
  }
`;

export const Wearable = styled.div`
  padding: 16px;
  background-color: ${theme.colors.surface};
  border-radius: 16px;
  aspect-ratio: 1;
  width: 156px;
  height: 156px;
  margin: 0 auto;

  img {
    max-width: 100%;
    max-height: 100%;
  }
`;

export const ContactDetails = styled.div`
  backdrop-filter: blur(20px);
  border-radius: 16px;
  background-color: ${theme.colors.surface};
  padding: 16px 0 0;

  .header {
    padding: 16px;
    text-align: center;
    border-bottom: 1px solid ${theme.colors.divider};
  }

  p {
    color: ${theme.colors.primary};
  }

  .item {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-left: 16px;
    padding: 16px 16px 16px 0;
    border-bottom: 1px solid ${theme.colors.divider};

    & :nth-child(1) {
      color: ${theme.colors.medEmphasis};
    }
  }

  .last-item {
    border-bottom: 0;
  }
`;
