import axios from "axios";
import { ENV } from "../../env";

export const API_URL = `https://www.${
  ENV !== "production" ? (ENV === "development" ? "dev" : ENV) + "-" : ""
}manage-mii.uk/api/das`;

// Register device public POST
const registerAscotDevice = async (deviceData) => {
  const response = await axios.post(`${API_URL}/ascot`, { ...deviceData });
  return response.data;
};

// GET devices (public)
const getAscotDevices = async () => {
  const response = await axios.get(`${API_URL}/ascot`);

  return response.data;
};

// GET device by  (public)
const getAscotDevice = async (serial) => {
  const response = await axios.get(`${API_URL}/ascot/${serial}`);
  return response.data;
};

// PATCH device location  (public)
const updateLocation = async ({ serial, location }) => {
  const response = await axios.patch(`${API_URL}/ascot/${serial}`, {
    location
  });
  return response.data;
};

// GET group by name (public)
const getGroup = async (name) => {
  const response = await axios.get(API_URL + `/groups/${name}`);
  return response.data;
};

// GET groups (public)
const getGroups = async () => {
  const response = await axios.get(API_URL + `/groups`);
  return response.data;
};

const deviceService = {
  registerAscotDevice,
  getAscotDevices,
  getAscotDevice,
  getGroup,
  getGroups,
  updateLocation
};

export default deviceService;
