import { useState, useEffect } from "react";

import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import Input from "../../../components/Input";
import Spinner from "../../../components/Spinner";
import { PrimaryButton } from "../../../components/styled/PrimaryButton";
import { FormContainer } from "../styled/FormContainer";

import { login, reset } from "../../../features/auth/authSlice";

const CoachTrackerSignin = () => {
  const [formData, setFormData] = useState({
    email: "",
    password: ""
  });

  const { email, password } = formData;


  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { user, isLoading, isError, isSuccess, message } = useSelector(
    (state) => state.auth
  );

  useEffect(() => {
    if (isError) {
      console.log({ message });
    }

    if (isSuccess || user) {
      navigate("/coach-tracker"); // TO DO - > ADD REDIRECT TO PREVIOUS PAGE
    }

    dispatch(reset());
  }, [user, isError, isSuccess, message, navigate, dispatch]);

  const onChange = (e) => {
    setFormData((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value
    }));
  };

  const onSubmit = (e) => {
    e.preventDefault();

    const userData = {
      email,
      password
    };

    dispatch(login(userData));
  };

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <FormContainer onSubmit={onSubmit}>
      <Input
        id="email"
        name="email"
        type="email"
        label="Email"
        value={email}
        placeholder="Email"
        required
        onChange={onChange}
      />
      <Input
        type="password"
        id="password"
        name="password"
        label="Password"
        value={password}
        placeholder="Enter password"
        onChange={onChange}
      />
      <PrimaryButton>Sign in</PrimaryButton>
    </FormContainer>
  );
};

export default CoachTrackerSignin;
