import styled from "styled-components";

export const Container = styled.div`
  position: relative;
  width: 100%;
  padding: 40px 20px;
  padding-right: 0px;
  h4 {
    margin-top: 10px;
    font-weight: 400;
    font-size: 1.125rem;
    line-height: 1.375rem;
  }

  h2 {
    font-weight: 600;
    font-size: 3.75rem;
    line-height: 3.375rem;
    margin-bottom: 20px;
  }

  @media (min-width: 768px) {
    padding: 70px 20px;
    padding-right: 0px;
    h4 {
      font-weight: 400;
      font-size: 1rem;
      line-height: 1.25rem;
    }

    h2 {
      font-size: 4.375rem;
      line-height: 4.375rem;
    }
  }

  @media (min-width: 1920px) {
    h2 {
      margin-bottom: 30px;
    }
  }

  @media (min-width: 1440px) {
    padding: 70px 60px;
    padding-right: 0px;
  }
`;

export const SliderHeaderContainer = styled.div`
  display: flex;
  width: "100%";
  align-items: flex-end;
  justify-content: space-between;
`;

export const NewsContainer = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-column-gap: 20px;
  grid-template-rows: min-content; // TODO check this
  overflow-x: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
  -ms-overflow-style: none;
  scrollbar-width: none;
  padding-right: 20px;

  &::-webkit-scrollbar {
    display: none;
  }

  @media (min-width: 360px) {
    max-width: 328px;
    min-width: 100%;
  }

  @media (min-width: 768px) {
    min-width: 100%;
  }
`;

export const StyledNews = styled.div`
  img {
    height: 328px;
    width: 328px;
    border-radius: 20px;
    overflow: hidden;
    object-fit: cover;

    @media (min-width: 768px) {
      height: 542px;
      width: 728px;
      border-radius: 30px;
    }
    @media (min-width: 1440px) {
      height: 484px;
      width: 650px;
    }

    @media (min-width: 1920px) {
      height: 663px;
      width: 890px;
    }
  }
`;
