import { SpinnerContainer, StyledSpinner } from "./styled/Spinner";

function Spinner(props) {
  return (
    <SpinnerContainer>
      <StyledSpinner {...props} />
    </SpinnerContainer>
  );
}

export default Spinner;
