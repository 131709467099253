import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";

import { getBalanceAndPin } from "../../features/balance-and-pin/balanceAndPinSlice";
import { formattedBalance } from "../../features/balance-and-pin/utils";
import { getDeviceByModel } from "../../features/devices/deviceSlice";
import { getDasDeviceInfo } from "../../features/das-device-manager/dasDeviceManagerSlice";

import Spinner from "../../components/Spinner";
import {
  Footer,
  MainContainer,
  PaymentAccount,
  ViewBalance,
  Wearable,
  Intro,
  AppStoreLinks,
  NextStepsContainer,
  CurvelogoContainer,
  StepContainer,
  StepNumber
} from "./styles";

import CocaCola2024Logo from "./img/CocaCola_Euro2024.png";
import wearable from "./img/wearable.png";
import chevronRight from "./img/chevron-right.svg";
import appStoreLogo from "./img/app-store.svg";
import gplayLogo from "./img/play-store.svg";
import digiseqLogo from "./img/digiseq-logo.png";
import muchBetterLogo from "./img/muchbetter-logo.svg";
import balanceBg from "./img/balance-bg.png";
import curveLogo from "./img/curve_logo.png";
import payAnyWhereImage from "../tap2start/img/pay-anywhere-image.png";
import euFlag from "./img/eu-flag.png";
import ukFlag from "./img/uk-flag.png";
import orSeparator from "./img/or.svg";

const CocaCola2024 = () => {
  const [searchParams] = useSearchParams();
  const serialNumber = searchParams.get("serialNumber");
  const dispatch = useDispatch();

  const { balanceAndPin, isLoading } = useSelector(
    (state) => state.balanceAndPin
  );

  // const { device: discoveryDevice, isLoading: isLoadingDevices } = useSelector(
  //   (state) => state.devices
  // );

  // const { dasDeviceInfo, gettingDasDeviceInfo } = useSelector(
  //   (state) => state.dasDevices
  // );

  // useEffect(() => {
  //   if (!dasDeviceInfo) {
  //     dispatch(getDasDeviceInfo(serialNumber));
  //   }
  // }, [dasDeviceInfo, dispatch, serialNumber]);

  // useEffect(() => {
  //   if (
  //     dasDeviceInfo?.model?.model &&
  //     dasDeviceInfo?.model?.brand &&
  //     dasDeviceInfo?.model?.version
  //   ) {
  //     dispatch(
  //       getDeviceByModel({
  //         brand: dasDeviceInfo.model.brand,
  //         model: dasDeviceInfo.model.model,
  //         version: dasDeviceInfo.model.version
  //       })
  //     );
  //   }
  // }, [
  //   dasDeviceInfo?.model?.brand,
  //   dasDeviceInfo?.model?.model,
  //   dasDeviceInfo?.model?.version,
  //   dispatch
  // ]);

  useEffect(() => {
    if (!balanceAndPin && serialNumber) {
      dispatch(
        getBalanceAndPin({
          serial: serialNumber,
          query: "?provider=much_better"
        })
      );
    }
  }, [balanceAndPin, serialNumber, dispatch]);

  // const getDeviceImage = () => {
  //   if (
  //     discoveryDevice?.variants?.length > 0 &&
  //     dasDeviceInfo?.model?.variant?.color
  //   ) {
  //     const deviceVariant = discoveryDevice.variants.find(
  //       (variant) => variant.color === dasDeviceInfo.model.variant.color
  //     );

  //     if (deviceVariant?.image) {
  //       return deviceVariant.image;
  //     }
  //   } else if (discoveryDevice?.defaultImage) {
  //     return discoveryDevice.defaultImage;
  //   }

  //   return null;
  // };

  const appStoreLinks = (appStoreUrl, playStoreUrl) => {
    return (
      <AppStoreLinks>
        <img
          src={appStoreLogo}
          alt="app-store"
          onClick={() => window.open(appStoreUrl, "_blank")}
        />
        <img
          src={gplayLogo}
          alt="play-store"
          onClick={() => window.open(playStoreUrl, "_blank")}
        />
      </AppStoreLinks>
    );
  };

  if (isLoading) return <Spinner />;

  return (
    <>
      <MainContainer>
        <Intro>
          <img src={CocaCola2024Logo} alt="CocaCola2024Logo" />
          <>
            <h1>Text</h1>
            <div>
              <p>{`More text`}</p>
              <p>{`Even more text`}</p>
            </div>
          </>
        </Intro>
        <ViewBalance img={balanceBg}>
          <div className="balance-container">
            <p>My Balance</p>
            <div className="balance">
              {balanceAndPin?.balanceDetails?.availableBalance
                ? formattedBalance(
                    balanceAndPin?.balanceDetails?.availableBalance,
                    balanceAndPin?.balanceDetails?.currency,
                    true
                  )
                : "N/A"}
            </div>
          </div>
        </ViewBalance>
        {/* <Wearable className="wearable">
          <img src={getDeviceImage() || wearable} alt="wearable" />
        </Wearable> */}

        <PaymentAccount>
          <h2>{"Payment Account"}</h2>
          <p>
            Want to continue using this keyfob? Please click below to upgrade to
            a dedicated wallet
          </p>
          <div
            className="button"
            onClick={() =>
              window.open(`https://get.muchbetter.com/digiseq-cl`, "_blank")
            }
          >
            {"Learn more"} <img src={chevronRight} alt="chevron right" />
          </div>

          <NextStepsContainer>
            <CurvelogoContainer>
              <img className="separator" src={orSeparator} alt="or" />
              <p className="title">Link your card</p>
              <div className="logos">
                <img className="curve-logo" src={curveLogo} alt="curve logo" />
                <img className="flag" src={euFlag} alt="eu flag" />
                <img className="flag" src={ukFlag} alt="uk flag" />
              </div>
            </CurvelogoContainer>
            <StepContainer>
              <StepNumber>1</StepNumber>
              <h2>Create a Curve Account</h2>
              <p>
                Register your card details with Curve to receive a free
                compatible digital bank card.
              </p>
              <p>Download the Curve App:</p>
              {appStoreLinks(
                "https://apps.apple.com/gb/app/curve-supercharge-your-money/id1049397112",
                "https://play.google.com/store/apps/details?id=com.imaginecurve.curve.prd"
              )}
            </StepContainer>
            <StepContainer>
              <StepNumber>2</StepNumber>
              <h2>Link Your Curve Virtual Card Using the Manage-Mii App</h2>
              <p>
                Follow the instructions on the Manage-Mii app and use your new
                Curve Card details when prompted.
              </p>
              {appStoreLinks(
                "https://apps.apple.com/app/manage-mii/id6446908503",
                "https://play.google.com/store/apps/details?id=com.digiseq.managemii"
              )}
            </StepContainer>
            <StepContainer>
              <StepNumber>3</StepNumber>
              <h2>Pay Anywhere!</h2>
              <p>
                Tap your wearable at any card terminal where Mastercard
                Contactless is enabled.
              </p>
              <img
                className="pay-anywhere"
                src={payAnyWhereImage}
                alt="pay anywhere"
              />
            </StepContainer>
          </NextStepsContainer>
        </PaymentAccount>
        <Footer>
          <p>Powered by</p>
          <img
            src={digiseqLogo}
            alt="digiseq-logo"
            onClick={() => window.open("https://www.digiseq.co.uk", "_blank")}
          />
          <p>Payment services provided by</p>
          <img
            src={muchBetterLogo}
            alt="much-better-logo"
            onClick={() => window.open("https://muchbetter.com/", "_blank")}
          />
        </Footer>
      </MainContainer>
    </>
  );
};

export default CocaCola2024;
