import httpClient from "../../axios";
import { ENV } from "../../env";

export const API_URL = `https://www.${
  ENV !== "production" ? (ENV === "development" ? "dev" : ENV) + "-" : ""
}manage-mii.uk/api/das/admin/`;

const getEvents = async () => {
  const response = await httpClient.get(API_URL + "events");

  return response.data;
};

const getEvent = async (id) => {
   const response = await httpClient.get(API_URL + `events/${id}`);

  return response.data;
};

const createEvent = async ({ id, name, description, assignedManagers }) => {
  const response = await httpClient.post(`${API_URL}/events`, {
    id,
    name,
    description,
    assignedManagers
  });
  return response.data;
};

const editEvent = async ({ originalId, id, name, description }) => {
  const response = await httpClient.put(`${API_URL}/events/${originalId}`, {
    id,
    name,
    description
  });
  return response.data;
};

const deleteEvent = async (id) => {
  const response = await httpClient.delete(`${API_URL}events/${id}`);
  return response.data;
};

const hardResetEventData = async (id) => {
  const response = await httpClient.get(API_URL + `events/${id}/reset`);

 return response.data;
};

const eventManagerService = {
  getEvents,
  getEvent,
  createEvent,
  editEvent,
  deleteEvent,
  hardResetEventData
};

export default eventManagerService;
