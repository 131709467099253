import { useEffect } from "react";
import {
  set_configuration as setConfiguration,
  get_configuration as getConfiguration
} from "mp-common-js";

import { ENV } from "./env";

const Configuration = () => {
  useEffect(() => {
    setConfiguration({
      environment: ENV,
      clientId: "d57199b4-d51b-4a18-8cda-db33a3c5b582",
      entityId: "digiseq",
      type: "mobile"
    });
  }, []);

  return null;
};

export default Configuration;
