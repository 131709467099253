import React, { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { formattedBalance } from "../../features/balance-and-pin/utils";
import { getBalanceAndPin } from "../../features/balance-and-pin/balanceAndPinSlice";
import Spinner from "../../components/Spinner";
import {
  H1,
  Hero,
  MainContainer,
  PaymentAccount,
  ActivationCode,
  Footer,
  Balance,
  H2,
  Intro,
  Button,
  TopSection,
  OverviewImg,
  Overview,
  EventHighlights,
  Schedule
} from "./styles/Road2Milan";

import copyIcon from "./img/copy-icon.svg";
import appStoreLogo from "./img/app-store.svg";
import gplayLogo from "./img/play-store.svg";
import boboLogoWhite from "./img/bobo-logo-white.svg";
import clubLogo from "./img/club-logo.webp";
import boboIconGold from "./img/bobo-icon-gold.webp";

const Road2Milan = () => {
  const [searchParams] = useSearchParams();
  const serialNumber = searchParams.get("serialNumber");

  const dispatch = useDispatch();
  const { balanceAndPin, isLoading } = useSelector(
    (state) => state.balanceAndPin
  );

  useEffect(() => {
    if (!balanceAndPin && serialNumber) {
      dispatch(
        getBalanceAndPin({
          serial: serialNumber,
          query: "?provider=pannovate"
        })
      );
    }
  }, [balanceAndPin, serialNumber, dispatch]);

  if (isLoading) return <Spinner />;

  return (
    <MainContainer>
      <TopSection>
        <Hero>
          <H1>Road to Milan</H1>
          <div className="date">26th, 27th & 28th Of April</div>
        </Hero>
        <Balance>
          <span>Current balance</span>{" "}
          <span>
            {formattedBalance(
              balanceAndPin?.balanceDetails?.availableBalance,
              balanceAndPin?.balanceDetails?.currency
            )}
          </span>
        </Balance>
        <Intro>
          <H2>
            A memorable trip to Milan with the Lady Drivers Club & Bourgeois
            Bohème.
          </H2>
          <div className="buttons">
            <Button
              onClick={() =>
                window.open(
                  "https://s3.eu-west-2.amazonaws.com/www.digiseq.co.uk/milan_brochure.pdf",
                  "_blank"
                )
              }
            >
              Brochure
            </Button>
            <Button
              onClick={() =>
                window.open("mailto:client.relations@bobofin.com", "_self")
              }
            >
              Contact
            </Button>
          </div>
        </Intro>
      </TopSection>
      <Overview>
        <OverviewImg />
        <div className="description-container">
          <div className="chapter">
            <span>1</span>
            <span>Overview</span>
          </div>
          <div className="text-container">
            <p>
              The Lady Drivers Club and Bourgeois Bohème have the pleasure of
              inviting you to experience the wanders of Milan, as part of our
              unique events designed for like-minded women. Set right between
              Milan and the historic Monza race-track, you can enjoy a memorable
              retreat in the italian capital city.
            </p>
          </div>
        </div>
      </Overview>
      <EventHighlights>
        <div className="chapter">
          <span>2</span>
          <span>Event Highlights</span>
        </div>
        <div className="img"></div>
      </EventHighlights>
      <Schedule>
        <div className="day">
          <span>DAY 1</span>
          <span>April 26th</span>
        </div>
        <div className="events">
          <div className="event">
            <span className="time">9:00 AM</span>
            <span className="description">Road Trip from Geneva to Milan</span>
          </div>
          <div className="event">
            <span className="time">12:00 PM</span>
            <span className="description">Lunch on the italian road</span>
          </div>
          <div className="event">
            <span className="time">2:00 PM</span>
            <span className="description">Massage on Demand</span>
          </div>
          <div className="event">
            <span className="time">6:00 PM</span>
            <span className="description">Evening in Milan</span>
          </div>
        </div>
      </Schedule>
      <Schedule>
        <div className="day">
          <span>DAY 2</span>
          <span>April 27th</span>
        </div>
        <div className="events">
          <div className="event">
            <span className="time">9:00 AM</span>
            <span className="description">Visit of Monza Park Real</span>
          </div>
          <div className="event">
            <span className="time">12:00 PM</span>
            <span className="description">Experience at Monza Race Track</span>
          </div>
          <div className="event">
            <span className="time">2:00 PM</span>
            <span className="description">Dinner in Milan</span>
          </div>
        </div>
      </Schedule>
      <Schedule>
        <div className="day">
          <span>DAY 3</span>
          <span>April 28th</span>
        </div>
        <div className="events">
          <div className="event">
            <span className="time">9:00 AM</span>
            <span className="description">Drive back to Geneva</span>
          </div>
          <div className="event">
            <span className="time">12:00 PM</span>
            <span className="description">
              Lunch break on the road in a unique spot
            </span>
          </div>
        </div>
      </Schedule>
      {balanceAndPin?.activationCode && (
        <PaymentAccount>
          <div className="splash"></div>
          <div className="chapter">
            <span>3</span>
            <span>Your BoBo key fob</span>
          </div>
          <div className="inner">
            <img className="logo" src={boboLogoWhite} alt="bourgeois boheme" />
            <p>
              Love your current payment experience? Elevate it with Bourgeois
              Bohème membership!
            </p>
            <p>
              Upgrade your key fob payment account and explore additional
              services available exclusively to BoBo members.{" "}
            </p>
            <p>Here is the activation code for you key fob:</p>
            <ActivationCode>
              <span>{balanceAndPin?.activationCode || "123123123"}</span>
              <img
                src={copyIcon}
                alt="copy"
                onClick={() =>
                  navigator.clipboard.writeText(balanceAndPin?.activationCode)
                }
              />
            </ActivationCode>
            <Button
              onClick={() => window.open(`https://uefa.bobofin.com/`, "_blank")}
            >
              {"Join Bourgeois Bohème"}{" "}
            </Button>
          </div>
        </PaymentAccount>
      )}

      <Footer>
        <div className="stores">
          <img
            src={appStoreLogo}
            alt="app store logo"
            onClick={() =>
              window.open(
                "https://apps.apple.com/us/app/bourgeois-boheme/id1574430730",
                "_blank"
              )
            }
          />
          <img
            src={gplayLogo}
            alt="google play logo"
            onClick={() =>
              window.open(
                "https://play.google.com/store/apps/details?id=com.paymeswiss.bobofin",
                "_blank"
              )
            }
          />
        </div>
        <div className="logos">
          <img
            src={clubLogo}
            alt="the lady drivers club"
            onClick={() =>
              window.open("https://www.theladydriversclub.com/", "_blank")
            }
          />
          <img
            src={boboIconGold}
            alt="BOURGEOIS BOHÈME"
            onClick={() => window.open("https://bobofin.com/", "_blank")}
          />
        </div>
      </Footer>
    </MainContainer>
  );
};

export default Road2Milan;
