import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  checkBinAction,
  resetCheckBin
} from "../../features/tap2start/tap2StartSlice";
import usePrevious from "../../hooks/usePrevious";

import {
  H2,
  B2,
  Spinner,
  Divider,
  ContentContainer,
  DividerSmall
} from "./styles/SharedStyles";
import {
  CardCheckContainer,
  InputContainer,
  ResultTextContainer,
  NextStepsContainer,
  StepNumber,
  AppStoreLinks,
  StepContainer,
  CheckBinButton,
  FormContainer,
  ErrorMessage,
  DownloadAppContainer,
  Result
} from "./styles/BinChecker";

import cardImage from "./img/card.png";
import cardIcon from "./img/card-icon.svg";
import nextIcon from "./img/next-icon.svg";
import successIcon from "./img/success-icon.svg";
import failureIcon from "./img/failure-icon.svg";
import appStoreBadge from "./img/app-store-badge.svg";
import playStoreBadge from "./img/play-store-badge.svg";
import payAnyWhereImage from "./img/pay-anywhere-image.png";
import curveLogo from "./img/curve_logo.png";
import bourgeoisBohemeLogo from "./img/bourgeois_boheme_logo.png";
import mygauvaLogo from "./img/myguava_logo.png";
import hypjarLogo from "./img/hyperjar_logo.png";
import vimpayLogo from "./img/vimpay_logo.png";
import ExpandingContainer from "./ExpandingContainer";

const BinChecker = ({
  serialNumber,
  showAllOptions,
  skipCardCheck,
  issuer
}) => {
  const dispatch = useDispatch();

  const [binNumber, setBinNumber] = useState("");
  const [binNumberError, setBinNumberError] = useState("");

  const ref = useRef(null);

  const { checkingBinNumber, checkBinNumberResponse, checkingBinNumberError } =
    useSelector((state) => state.tap2Start);

  const prevCheckingBinNumber = usePrevious(checkingBinNumber);

  const scrollToElement = () => {
    ref.current?.scrollIntoView({ behavior: "smooth" });
  };

  const checkBin = () => {
    if (binNumber.length !== 6) {
      setBinNumberError("Please enter the first 6 digits");
    } else {
      dispatch(checkBinAction(binNumber));
    }
  };

  useEffect(() => {
    if (prevCheckingBinNumber && checkBinNumberResponse) {
      scrollToElement();
    }
  }, [prevCheckingBinNumber, checkBinNumberResponse]);

  const appStoreLinks = (appStoreUrl, playStoreUrl) => {
    return (
      <AppStoreLinks>
        <img
          src={appStoreBadge}
          alt="app-store"
          onClick={() => window.open(appStoreUrl, "_blank")}
        />
        <img
          src={playStoreBadge}
          alt="play-store"
          onClick={() => window.open(playStoreUrl, "_blank")}
        />
      </AppStoreLinks>
    );
  };

  const supportedApp = ({
    message,
    image,
    iosAppUrl,
    androidAppUrl,
    accountProvider,
    supportedCountries,
    fullWidthLogo
  }) => {
    return (
      <>
        <ExpandingContainer
          image={image}
          message={message}
          supportedCountries={supportedCountries}
          fullWidth={fullWidthLogo}
        >
          <NextStepsContainer>
            <StepContainer>
              <Divider />
              <StepNumber>1</StepNumber>
              <H2>{`Create your ${accountProvider} Account`}</H2>
              <B2>
                {`Register your card details with ${accountProvider} to receive a free compatible
              digital bank card.`}
              </B2>
              <B2>{`Download the ${accountProvider} App`}</B2>
              {appStoreLinks(iosAppUrl, androidAppUrl)}
            </StepContainer>
            <Divider />
            <StepContainer>
              <StepNumber>2</StepNumber>
              <H2>{`Link Your ${accountProvider} Card Using the Manage-Mii App`}</H2>
              <B2>
                {`Follow the instructions on the Manage-Mii app and use your new ${accountProvider} Card details when prompted`}
              </B2>
              {appStoreLinks(
                "https://apps.apple.com/app/manage-mii/id6446908503",
                "https://play.google.com/store/apps/details?id=com.digiseq.managemii"
              )}
            </StepContainer>
            <Divider />
            <StepContainer>
              <StepNumber>3</StepNumber>
              <H2>Pay Anywhere!</H2>
              <B2>
                Tap your wearable at any card terminal where Mastercard
                Contactless is enabled.
              </B2>
              <img src={payAnyWhereImage} alt="pay anywhere" />
            </StepContainer>
          </NextStepsContainer>
        </ExpandingContainer>
        <DividerSmall />
      </>
    );
  };

  const prepaidOptions = () => {
    return (
      <>
        <>
          {supportedApp({
            image: hypjarLogo,
            androidAppUrl:
              "https://play.google.com/store/apps/details?id=com.tractr.hyperjar",
            iosAppUrl:
              "https://apps.apple.com/gb/app/hyperjar-money-management-app/id1224672532",
            accountProvider: "HyperJar",
            supportedCountries: ["gb"]
          })}
          <>
            {supportedApp({
              image: vimpayLogo,
              androidAppUrl:
                "https://play.google.com/store/apps/details?id=net.petafuel.mobile.vimpay",
              iosAppUrl:
                "https://apps.apple.com/lv/app/vimpay-the-way-to-pay/id1049311425",
              accountProvider: "VIMpay",
              supportedCountries: ["de", "at"]
            })}
          </>
        </>
        {showAllOptions && (
          <>
            <>
              {supportedApp({
                image: bourgeoisBohemeLogo,
                androidAppUrl:
                  "https://play.google.com/store/apps/details?id=com.paymeswiss.bobofin",
                iosAppUrl:
                  "https://apps.apple.com/us/app/bourgeois-boheme/id1574430730",
                accountProvider: "Bourgeois Bohème",
                supportedCountries: ["eu", "gb", "ch"],
                fullWidthLogo: true
              })}
            </>
            <>
              {supportedApp({
                image: mygauvaLogo,
                androidAppUrl:
                  "https://play.google.com/store/apps/details?id=com.guavapay.mobile",
                iosAppUrl:
                  "https://apps.apple.com/us/app/myguava-all-things-payments/id1628100419",
                accountProvider: "MyGuava",
                supportedCountries: ["gb"]
              })}
            </>
          </>
        )}
      </>
    );
  };

  return (
    <ContentContainer>
      <H2>Experience Payment-Ready Wearables!</H2>
      {skipCardCheck && (
        <>
          <ResultTextContainer ref={ref}>
            <Result>
              <img src={successIcon} alt="card icon" />
              <B2>
                {`You can connect your ${issuer} card to your wearable! Follow the steps
                below to set up contactless payment.`}
              </B2>
            </Result>
          </ResultTextContainer>
          <Divider />
        </>
      )}
      {!skipCardCheck && (
        <>
          <CardCheckContainer>
            <B2>
              Enter the first six digits of your payment card number to check
              your bank’s compatibility.
            </B2>
            <img src={cardImage} alt="card_image" />
          </CardCheckContainer>
          <FormContainer>
            <InputContainer error={binNumberError}>
              <img src={cardIcon} alt="card icon" />
              <input
                required
                readOnly={checkingBinNumber}
                disabled={checkingBinNumber}
                placeholder="Card number"
                value={binNumber}
                maxLength={6}
                inputMode="numeric"
                onChange={(ev) => {
                  dispatch(resetCheckBin());
                  setBinNumberError("");
                  const value = ev.target.value.replace(/\D/g, "");
                  setBinNumber(value);
                }}
              />
              {checkingBinNumber && (
                <div>
                  <Spinner />
                </div>
              )}
            </InputContainer>
            <CheckBinButton
              disabled={checkingBinNumber}
              onClick={() => checkBin()}
            >
              <img src={nextIcon} alt="card icon" />
            </CheckBinButton>
          </FormContainer>
        </>
      )}
      {binNumberError && <ErrorMessage>{binNumberError}</ErrorMessage>}
      {!checkBinNumberResponse && !skipCardCheck && (
        <DownloadAppContainer>
          <Divider />
          <H2>Start Using Contactless Payments With Manage-Mii.</H2>
          <B2>
            Download the Manage-Mii app to connect your payment card to your
            Wearable.
          </B2>
          {appStoreLinks(
            "https://apps.apple.com/app/manage-mii/id6446908503",
            "https://play.google.com/store/apps/details?id=com.digiseq.managemii"
          )}
          <div className="label">
            Already have the app?
            <a
              href={
                "managemii://wearables/tap2start?serialNumber=" + serialNumber
              }
              target="_blank"
              rel="noopener noreferrer"
            >
              Open Manage-Mii
            </a>
          </div>
        </DownloadAppContainer>
      )}
      {checkBinNumberResponse?.result && (
        <>
          <ResultTextContainer ref={ref}>
            <Result>
              <img
                src={
                  checkBinNumberResponse?.result === "SUPPORTED"
                    ? successIcon
                    : failureIcon
                }
                alt="card icon"
              />
              <B2>
                {(() => {
                  switch (checkBinNumberResponse?.result) {
                    case "SUPPORTED":
                      return `You can connect your card to your wearable! Follow the steps below to set up contactless payment.`;
                    case "IN_PROGRESS":
                      return `We are in the process of adding support for your card. More details are available by contacting your Issuer. Meanwhile you can follow one the steps below to set up contactless payment.`;
                    case "ALTERNATIVES_SUPPORTED":
                      if (checkBinNumberResponse?.cardType === "VISA")
                        return "VISA cards currently are not compatible with Manage-Mii yet, BUT there is another way! Follow one of the steps below to set up contactless payment.";
                      return `Your card is not compatible with Manage-Mii just yet, you could try the one options below or contact your issuer to enable your card.`;
                    default:
                      return `Your card is not compatible with Manage-Mii just yet BUT there is another way! Follow one of the steps below to set up contactless payment.`;
                  }
                })()}
              </B2>
            </Result>
          </ResultTextContainer>
          <Divider />
        </>
      )}
      {(checkBinNumberResponse?.result === "SUPPORTED" || skipCardCheck) && (
        <NextStepsContainer>
          <StepContainer>
            <StepNumber>1</StepNumber>
            <H2>Download the Manage-Mii App</H2>
            <B2>Download the easy to use app and create an account.</B2>
            {appStoreLinks(
              "https://apps.apple.com/app/manage-mii/id6446908503",
              "https://play.google.com/store/apps/details?id=com.digiseq.managemii"
            )}
          </StepContainer>
          <Divider />
          <StepContainer>
            <StepNumber>2</StepNumber>
            <H2>Link Your Card to Your Manage-Mii App</H2>
            <B2>
              Add your wearable to your account and register your card details.
            </B2>
          </StepContainer>
          <Divider />
          <StepContainer>
            <StepNumber>3</StepNumber>
            <H2>Pay Anywhere!</H2>
            <B2>
              Tap your wearable at any card terminal where Mastercard
              Contactless is enabled.
            </B2>
            <img src={payAnyWhereImage} alt="pay anywhere" />
          </StepContainer>
        </NextStepsContainer>
      )}
      {(checkBinNumberResponse?.result === "ALTERNATIVES_SUPPORTED" ||
        checkBinNumberResponse?.result === "IN_PROGRESS") && (
        <>
          {supportedApp({
            image: curveLogo,
            androidAppUrl:
              "https://play.google.com/store/apps/details?id=com.imaginecurve.curve.prd",
            iosAppUrl:
              "https://apps.apple.com/gb/app/curve-supercharge-your-money/id1049397112",
            accountProvider: "Curve",
            supportedCountries: ["eu", "gb"]
          })}
          {prepaidOptions()}
        </>
      )}
      {checkBinNumberResponse?.result === "NOT_SUPPORTED" && (
        <>{prepaidOptions()}</>
      )}
    </ContentContainer>
  );
};

export default BinChecker;
