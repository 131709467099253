import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";

import { getBalanceAndPin } from "../../features/balance-and-pin/balanceAndPinSlice";
import { formattedBalance } from "../../features/balance-and-pin/utils";
import Spinner from "../../components/Spinner";

import {
  Navbar,
  MainContainer,
  CurvelogoContainer,
  ExpandedContainer,
  Footer,
  NextStepsContainer,
  StepContainer,
  StepNumber,
  InnerContainer
} from "./styles";

import curveLogo from "../../assets/curve_logo.png";
import payAnyWhereImage from "../tap2start/img/pay-anywhere-image.png";
import euFlag from "../../assets/eu-flag.png";
import ukFlag from "../../assets/uk-flag.png";
import digiseqLogo from "../../assets/digiseq-logo.png";
import expandDown from "../../assets/expand-down.svg";
import expandUp from "../../assets/expand-up.svg";
import AppStoreLinks from "../../components/AppStoreLinks";
import openBankingExpoLogo from "./img/open-banking-expo-logo.svg";

const OpenBankingYaspa = () => {
  const [searchParams] = useSearchParams();
  const serialNumber = searchParams.get("serialNumber");

  const dispatch = useDispatch();
  const { balanceAndPin, isLoading } = useSelector(
    (state) => state.balanceAndPin
  );

  const [expanded, setExpanded] = useState(true);

  useEffect(() => {
    if (!balanceAndPin && serialNumber) {
      dispatch(
        getBalanceAndPin({
          serial: serialNumber,
          query: "?provider=much_better"
        })
      );
    }
  }, [balanceAndPin, serialNumber, dispatch]);

  if (isLoading) return <Spinner />;

  return (
    <MainContainer>
      <Navbar>
        <img src={openBankingExpoLogo} alt="open banking expo" />
        <div className="balance">
          <p>BALANCE</p>
          <p>
            {formattedBalance(
              balanceAndPin?.balanceDetails?.availableBalance,
              balanceAndPin?.balanceDetails?.currency
            )}
          </p>
        </div>
      </Navbar>
      <InnerContainer>
        <div className="intro">
          <h1>Welcome to Open Banking Expo!</h1>
          <p>
            Thank you for purchasing a pre-paid food voucher. We’re excited to
            offer this in a wearable tech format, which is sponsored by Yaspa!
          </p>
          <p>
            To use your voucher, simply tap the fob at any catering point
            throughout the Show, including Jack ’s Café upstairs. Your balance
            will automatically reset to £0 once the Show closes.
          </p>
          <p>
            To check your balance during the event, tap the fob on the back of
            your phone. A notification will appear - just tap it to visit this
            page, which displays your balance in the top right corner.
          </p>
          <p>Enjoy the Show!</p>
          <p
            onClick={() =>
              window.open(
                "https://www.openbankingexpo.com/uk/agenda/",
                "_blank"
              )
            }
            className="call-to-action"
          >
            Event Schedule
          </p>
        </div>
        <NextStepsContainer>
          <div className="header">
            <h2>After the event</h2>
            <img
              src={expanded ? expandUp : expandDown}
              alt="chevron-right"
              onClick={() => setExpanded((state) => !state)}
            />
          </div>
          {expanded && (
            <ExpandedContainer>
              <CurvelogoContainer>
                <p>
                  After the event you can continue to use your wearable by
                  loading funds onto it. Simply follow the steps below.
                </p>
                <h3>Link your own card </h3>
                <img className="curve-logo" src={curveLogo} alt="curve logo" />
                <div className="flags">
                  <img src={euFlag} alt="eu flag" />{" "}
                  <img src={ukFlag} alt="uk flag" />
                </div>
              </CurvelogoContainer>
              <StepContainer>
                <StepNumber>1</StepNumber>
                <h2>Download the Curve App</h2>
                <p>
                  And link your preferred payment card to your new Curve
                  account.
                </p>
                <p>Download the Curve App:</p>

                <AppStoreLinks
                  appStoreUrl="https://apps.apple.com/gb/app/curve-supercharge-your-money/id1049397112"
                  playStoreUrl="https://play.google.com/store/apps/details?id=com.imaginecurve.curve.prd"
                />
              </StepContainer>
              <StepContainer>
                <StepNumber>2</StepNumber>
                <h2>Download the Manage-Mii app to:</h2>
                <p>Link your Curve card to enable contactless payments.</p>
                <p>
                  Set up a contactless business card and share your details with
                  a tap!
                </p>

                <AppStoreLinks
                  appStoreUrl="https://apps.apple.com/app/manage-mii/id6446908503"
                  playStoreUrl="https://play.google.com/store/apps/details?id=com.digiseq.managemii"
                />
              </StepContainer>
              <StepContainer>
                <StepNumber>3</StepNumber>
                <h2>Pay Anywhere!</h2>
                <p>
                  Tap your wearable at any card terminal where Mastercard
                  Contactless is enabled.
                </p>
                <img
                  className="pay-anywhere"
                  src={payAnyWhereImage}
                  alt="pay anywhere"
                />
              </StepContainer>
            </ExpandedContainer>
          )}
        </NextStepsContainer>
      </InnerContainer>

      <Footer>
        <p>Powered by</p>
        <div className="companies">
          <img
            src={digiseqLogo}
            alt="digiseq-logo"
            onClick={() => window.open("https://www.digiseq.co.uk", "_blank")}
          />
        </div>
      </Footer>
    </MainContainer>
  );
};

export default OpenBankingYaspa;
