import React, { useState } from "react";
import { MainContainer } from "./styled/DeleteConfirmation";
import { Button, Input } from "./styled/SharedStyles";

const DeleteConfirmation = ({ onConfirmed, buttonText }) => {
  const [inputText, setInputText] = useState("");

  return (
    <MainContainer>
      <p>Are you sure? please type: yes</p>
      <Input
        autoFocus
        value={inputText}
        onChange={(ev) => setInputText(ev.target.value)}
      />
      {inputText === "yes" && (
        <Button danger onClick={() => onConfirmed()}>
          {buttonText}
        </Button>
      )}
    </MainContainer>
  );
};

export default DeleteConfirmation;
