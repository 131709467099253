import styled from "styled-components";

import feedbackBannerBg from "../img/feedback-banner-bg.png";
import mixins from "./mixins";

export const Banner = styled.div`
  ${mixins.flexCenter}

  flex-direction: column;
  text-align: center;
  aspect-ratio: 390/204;
  background-size: contain;
  background-image: url(${feedbackBannerBg});
  background-color: #eb5c2a;
  align-items: center;

  h1 {
    margin-bottom: 16px;
  }
  p {
    ${mixins.B1}
  }
`;
