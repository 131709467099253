import React from "react";
import Heading from "../../components/Heading";
import { StyledMain } from "../../styled/StyledMain";
import { MainContainer } from "../../styled/StyledMainContainer";
import { ManOfTheMatchSubmittedContainer } from "./components/styled/ManOfTheMatchSubmitted";

const ManOfTheMatchSubmitted = () => {
  return (
    <StyledMain>
      <MainContainer>
        <Heading />
        <ManOfTheMatchSubmittedContainer>
          <h4>Thank you, for nominating your man of the match.</h4>
          <h4>We’ll text you within 24 hrs if you’re a WINNER.</h4>
          <h2>GOOD LUCK!</h2>
        </ManOfTheMatchSubmittedContainer>
      </MainContainer>
    </StyledMain>
  );
};

export default ManOfTheMatchSubmitted;
