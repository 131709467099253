import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { composeErrorMessage } from "../../utils/utils";
import hugoBossService from "./hugoBossService";

const initialState = {
  device: null,
  gettingDeviceInfo: false,
  gettingDeviceInfoError: "",
  claimingDevice: false,
  claimingDeviceError: "",
  activatingTicket: false,
  activatingTicketError: "",
  reauthenticating: false,
  reauthenticatingError: ""
};

export const getDeviceInfo = createAsyncThunk(
  "hugoBoss/getDeviceInfo",
  async (serial, thunkAPI) => {
    try {
      const response = await hugoBossService.getDeviceInfo(serial);

      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(composeErrorMessage(error));
    }
  }
);

export const claimDevice = createAsyncThunk(
  "hugoBoss/claimDevice",
  async (requestBody, thunkAPI) => {
    try {
      return await hugoBossService.claimDevice(requestBody);
    } catch (error) {
      return thunkAPI.rejectWithValue(composeErrorMessage(error));
    }
  }
);

export const activateTicket = createAsyncThunk(
  "hugoBoss/activateTicket",
  async (requestBody, thunkAPI) => {
    try {
      return await hugoBossService.activateTicket(requestBody);
    } catch (error) {
      return thunkAPI.rejectWithValue(composeErrorMessage(error));
    }
  }
);

export const reauthenticate = createAsyncThunk(
  "hugoBoss/reauthenticate",
  async (requestBody, thunkAPI) => {
    try {
      return await hugoBossService.reauthenticate(requestBody);
    } catch (error) {
      return thunkAPI.rejectWithValue(composeErrorMessage(error));
    }
  }
);

export const hugoBossSlice = createSlice({
  name: "hugoBoss",
  initialState,
  reducers: {
    reset: (state) => initialState,
    resetActivatingTicketError: (state) => {
      state.activatingTicketError = "";
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getDeviceInfo.pending, (state) => {
        state.gettingDeviceInfo = true;
        state.gettingDeviceInfoError = "";
      })
      .addCase(getDeviceInfo.fulfilled, (state, action) => {
        state.gettingDeviceInfo = false;
        state.device = action.payload;
      })
      .addCase(getDeviceInfo.rejected, (state, action) => {
        state.gettingDeviceInfo = false;
        state.gettingDeviceInfoError = action.payload;
      })
      .addCase(claimDevice.pending, (state) => {
        state.claimingDevice = true;
        state.claimingDeviceError = "";
      })
      .addCase(claimDevice.fulfilled, (state, action) => {
        state.claimingDevice = false;
      })
      .addCase(claimDevice.rejected, (state, action) => {
        state.claimingDevice = false;
        state.claimingDeviceError = action.payload;
      })
      .addCase(activateTicket.pending, (state) => {
        state.activatingTicket = true;
        state.activatingTicketError = "";
      })
      .addCase(activateTicket.fulfilled, (state, action) => {
        state.device = action.payload;
        state.activatingTicket = false;
      })
      .addCase(activateTicket.rejected, (state, action) => {
        state.activatingTicket = false;
        state.activatingTicketError = action.payload;
      })
      .addCase(reauthenticate.pending, (state) => {
        state.reauthenticating = true;
        state.reauthenticatingError = "";
      })
      .addCase(reauthenticate.fulfilled, (state, action) => {
        state.device = action.payload;
        state.reauthenticating = false;
      })
      .addCase(reauthenticate.rejected, (state, action) => {
        state.reauthenticating = false;
        state.reauthenticatingError = action.payload;
      });
  }
});

export const { reset, resetActivatingTicketError } = hugoBossSlice.actions;

export default hugoBossSlice.reducer;
