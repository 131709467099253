import httpClient from "../../axios";
import { ENV } from "../../env";

export const API_URL = `https://www.${
  ENV !== "production" ? (ENV === "development" ? "dev" : ENV) + "-" : ""
}manage-mii.uk/api/das/business-card`;

const getBusinessCard = async (serial) => {
  const response = await httpClient.get(API_URL + `/${serial}`);
  return response.data?.promoReadyData;
};

const businessCardService = {
  getBusinessCard
};

export default businessCardService;
