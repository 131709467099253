import styled, { css } from "styled-components";

// Color variables
const colors = {
  black: "#000000",
  white: "#FFFFFF",
  gray: "#242424",
  lightGray: "#F5F5F5",
  blue: "#0000FF"
};

// Common styles
const commonStyles = css`
  font-family: "Inter", sans-serif;
  font-style: normal;
`;

const textStyles = css`
  ${commonStyles}
  line-height: 1.5;
  letter-spacing: 0.5px;
`;

export const Navbar = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-right: 16px;
  .balance {
    text-align: right;
  }
`;

export const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 480px;
  margin: auto;
`;

export const InnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0 24px;

  p {
    ${textStyles}
    margin-top: 16px;
    font-size: 16px;
    font-weight: 400;
    color: ${colors.gray};
  }

  .text-center {
    text-align: center;
  }

  h1,
  h2 {
    ${textStyles}
    margin-top: 16px;
    font-size: 32px;
    font-weight: 700;
    color: ${colors.black};
  }

  h3 {
    ${textStyles}
    margin-top: 16px;
    font-size: 24px;
    font-weight: 700;
    color: ${colors.black};
  }

  .intro {
    display: flex;
    flex-direction: column;
    gap: 16px;
    text-align: center;
  }
  .call-to-action {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 8px 16px;
    background-color: ${colors.gray};
    color: ${colors.white};
    border-radius: 360px;
    margin: 24px auto;
    cursor: pointer;
  }
`;

export const NextStepsContainer = styled.div`
  margin-top: -1px;
  display: flex;
  flex: 1;
  flex-direction: column;

  background-color: white;
  .header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  img {
    cursor: pointer;
  }
`;

export const StepContainer = styled.div`
  padding: 32px 0;

  .pay-anywhere {
    margin: 24px auto;
    width: 100%;
    object-fit: contain;
  }
`;
export const ExpandedContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

export const CurvelogoContainer = styled.div`
  margin-bottom: 16px;
  padding: 24px 0px;
  display: flex;
  flex-direction: column;
  gap: 16px;

  .separator {
    height: 25px;
    width: 100%;
    object-fit: cover;
  }

  .curve-logo {
    height: 32px;
    width: 152px;
  }
  .flags {
    display: flex;
    gap: 8px;
    img {
      height: 26px;
    }
  }
`;

export const StepNumber = styled.div`
  display: flex;
  width: 40px;
  padding: 8px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 360px;
  background: #f6f6f7;
`;

export const AppStoreLinks = styled.section`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 16px;

  img {
    cursor: pointer;
    margin-top: 24px;
    max-height: 50px;
  }
`;

export const Footer = styled.footer`
  ${textStyles}
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 24px 16px 48px;
  gap: 16px;
  border-top: 1px solid ${colors.black};
  margin-top: 24px;
  text-align: center;
`;
