import React from "react";

import {
  Action,
  Actions,
  Container,
  StyledLink,
  ImgContainer,
  TextContainer,
  ImageText,
  Image
} from "./styled/AboutSection";

import exploreImg from "./images/explore-image.png";
import addImg from "./images/add-image.png";
import payImg from "./images/pay-image.png";

const AboutSection = () => {
  return (
    <Container>
      <TextContainer>
        <h4>About</h4>
        <h2>The Home of Payment Ready Wearables.</h2>
      </TextContainer>
      <Actions>
        <Action>
          <ImgContainer>
            <Image src={exploreImg} alt="explore" />
          </ImgContainer>
          <ImageText>
            <h4>Explore</h4>
            <p>
              Browse our <StyledLink to="/products">Products</StyledLink> page
              to choose from a wide range of timeless pieces.
            </p>
          </ImageText>
        </Action>
        <Action>
          <ImgContainer>
            <Image src={addImg} alt="add" />
          </ImgContainer>
          <ImageText>
            <h4>Add</h4>
            <p>
              Add your wearable to the Manage-Mii app to enable a variety of
              functions.
            </p>
          </ImageText>
        </Action>
        <Action>
          <ImgContainer>
            <Image src={payImg} alt="pay" />
          </ImgContainer>
          <ImageText>
            <h4>Pay</h4>
            <p>Make payments on the go with a flick of the wrist.</p>
          </ImageText>
        </Action>
      </Actions>
    </Container>
  );
};

export default AboutSection;
