import { Link } from "react-router-dom";
import styled from "styled-components";

export const Container = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  padding: 40px 20px;

  @media (min-width: 768px) {
    padding: 20px;
  }

  @media (min-width: 1440px) {
    padding: 74px 60px;
    flex-direction: row;
    align-items: center;
  }

  h4 {
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    margin-bottom: 6px;
  }
  p {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
  }
`;

export const StyledLink = styled(Link)`
  color: #2e78f7;
  font-style: normal;
  font-weight: 400;
`;

export const Action = styled.div`
  display: flex;
  flex-direction: row;
  text-align: left;
  width: 100%;
  height: 100%;

  @media (min-width: 768px) {
    margin-bottom: 0;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
  }
`;

export const ImgContainer = styled.div`
  border-radius: 18px;
  overflow: hidden;
  width: 107px;
  height: 107px;
  margin-right: 15px;

  @media (min-width: 768px) {
    border-radius: 30px;
    margin-bottom: 18px;
    margin-right: 0;
    width: 100%;
    height: 100%;
  }
`;

export const Image = styled.img`
  object-fit: cover;
  width: 100%;
  height: 100%;
  max-width: 100%;
  max-height: 100%;
`;

export const ImageText = styled.div`
  width: 100%;
  border-top: 0.35px solid #aeafb8;
  padding-top: 10px;
  max-width: 50%;
  @media (min-width: 480px) {
    max-width: 100%;
  }

  @media (min-width: 768px) {
    border-top: none;
    max-width: inherit;
    padding-top: 0;
    height: 90px;
  }
`;

export const TextContainer = styled.div`
  align-self: flex-start;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  max-width: 590px;
  margin-bottom: 30px;

  @media (min-width: 1440px) {
    max-width: 361px;
    margin-right: 107px;
    margin-bottom: 0;
  }

  h2 {
    font-style: normal;
    margin: 0;
    font-weight: 600;
    font-size: 3.75rem;
    line-height: 70px;
    letter-spacing: 0.01em;

    @media (min-width: 768px) {
      font-size: 4.375rem;
    }
  }

  h4 {
    font-weight: 400;
    font-size: 1rem;
    line-height: 20px;
  }
`;

export const Actions = styled.div`
  display: grid;
  grid-template-columns: repeat(1, minmax(0, 1fr));
  grid-row-gap: 20px;
  @media (min-width: 768px) {
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    grid-column-gap: 21px;
    grid-row-gap: 0px;
  }
`;
