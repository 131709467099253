import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import giftingService from "./giftingService";

const initialState = {
  redirectUrl: "",
  creatingGiftingCard: false,
  errorCreatingGiftingCard: ""
};

export const createGiftingCard = createAsyncThunk(
  "gifting/createGiftingCard",
  async ({ serialNumber, data }, thunkAPI) => {
    try {
      const response = await giftingService.createGiftingCard({
        serialNumber,
        data
      });
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);
export const setCardViewed = createAsyncThunk(
  "gifting/setCardViewed",
  async ({ serialNumber, dontShowAgain }, thunkAPI) => {
    try {
      const response = await giftingService.setCardViewed({
        serialNumber,
        dontShowAgain
      });
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const giftingSlice = createSlice({
  name: "gifting",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(createGiftingCard.pending, (state) => {
        state.creatingGiftingCard = true;
        state.errorCreatingGiftingCard = "";
      })
      .addCase(createGiftingCard.fulfilled, (state, action) => {
        state.creatingGiftingCard = false;
        state.giftingCard = action.payload;
      })
      .addCase(createGiftingCard.rejected, (state, action) => {
        state.creatingGiftingCard = false;
        state.errorCreatingGiftingCard = action.payload;
      })
      .addCase(setCardViewed.pending, (state) => {
        state.viewingCard = true;
        state.errorViewingCard = "";
        state.redirectUrl = "";
      })
      .addCase(setCardViewed.fulfilled, (state, action) => {
        state.viewingCard = false;
        state.redirectUrl = action.payload.redirectUrl;
      })
      .addCase(setCardViewed.rejected, (state, action) => {
        state.viewingCard = false;
        state.errorViewingCard = action.payload;
      });
  }
});

export default giftingSlice.reducer;
