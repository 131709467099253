import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";

import { getBalanceAndPin } from "../../features/balance-and-pin/balanceAndPinSlice";
import {
  MasterCardDemoFonts,
  MainContainer,
  SpinnerContainer,
  Footer,
  Header,
  Banner,
  OptionContainer,
  OptionHeader,
  OptionText,
  BalanceTitle,
  Divider,
  IssuersContainer,
  IssuerContainer,
  AppStoresContainer,
  BalanceContainer,
  ViewPinButton,
  TitleTextContainer,
  TitleText,
  SubtitleText,
  BrowseWearablesContainer,
  OptionTextContainer,
  Button,
  OpenAppButtonContainer
} from "./styles/MasterCardDemoStyles";

import ContactForm from "./contact-form/ContactForm";

import Spinner from "../../components/Spinner";
import manageMiiLogo from "./img/manage-mii-logo.png";
import mbLogo from "./img/mb-logo.png";
import mbLogo2 from "./img/mb-logo-2.png";

import banner from "./img/banner.png";
import footer from "./img/footer.png";
import mcLogo from "./img/mc-logo.svg";

import curve from "./img/curve.png";
import swedbank from "./img/swedbank.png";
import nordea from "./img/nordea.png";
import seb from "./img/seb.png";
import appstore from "./img/appstore.png";
import playstore from "./img/playstore.png";

import openIcon from "./img/down-icon.svg";
import closeIcon from "./img/up-icon.svg";
import arrowRight from "./img/arrow-right.svg";

const MasterCardDemo = () => {
  const [searchParams] = useSearchParams();
  const serialNumber = searchParams.get("serialNumber");
  const isExpanded = searchParams.get("isExpanded") === "true";

  const [anonymousPrepaid, setAnonymousPrepaid] = useState(
    searchParams.get("anonymous") === "true"
  );

  const [showEmailSent, setShowEmailSent] = useState(false);
  const [email, setEmail] = useState("");

  const [showManageMiiDetails, setShowManageMiiDetails] = useState(
    isExpanded || false
  );
  const [showMBDetails, setShowMBDetails] = useState(isExpanded || false);

  const dispatch = useDispatch();
  const { balanceAndPin, isLoading } = useSelector(
    (state) => state.balanceAndPin
  );

  useEffect(() => {
    if (!balanceAndPin && serialNumber && anonymousPrepaid) {
      dispatch(getBalanceAndPin({ serial: serialNumber }));
    }
  }, [balanceAndPin, serialNumber, anonymousPrepaid, dispatch]);

  const onEmailSent = (email) => {
    setEmail(email);
    setShowEmailSent(true);
    window.scrollTo(0, 0);
  };

  if (isLoading)
    return (
      <SpinnerContainer>
        <Spinner />
      </SpinnerContainer>
    );

  if (showEmailSent)
    return (
      <MainContainer>
        <MasterCardDemoFonts />
        <Header>
          <img src={mcLogo} alt="mc_logo" />
          <BalanceContainer>
            <BalanceTitle>Balance</BalanceTitle>
            <BalanceTitle>
              {balanceAndPin?.balanceDetails?.availableBalance
                ? balanceAndPin?.balanceDetails?.availableBalance
                : "N/A"}
            </BalanceTitle>
          </BalanceContainer>
          <ViewPinButton
            onClick={() =>
              alert("PIN: " + (balanceAndPin?.pinDetails?.pin || "N/A"))
            }
          >
            View PIN
          </ViewPinButton>
        </Header>
        <Banner>
          <img src={banner} alt="banner" />
        </Banner>
        <TitleTextContainer>
          <TitleText>Thank you!</TitleText>
          <SubtitleText>
            {`We will be in touch soon at ${email} with your next steps`}
          </SubtitleText>
        </TitleTextContainer>
      </MainContainer>
    );

  return (
    <MainContainer>
      <MasterCardDemoFonts />
      <Header>
        <img src={mcLogo} alt="mc_logo" />
        {/*
        <BalanceContainer>
          <BalanceTitle>Balance</BalanceTitle>
          <BalanceTitle>
            {balanceAndPin?.balanceDetails?.availableBalance
              ? balanceAndPin?.balanceDetails?.availableBalance
              : "N/A"}
          </BalanceTitle>
        </BalanceContainer>
        <ViewPinButton
          onClick={() =>
            alert("PIN: " + (balanceAndPin?.pinDetails?.pin || "N/A"))
          }
        >
          View PIN
        </ViewPinButton>
        */}
      </Header>
      <Banner>
        <img src={!showEmailSent ? banner : banner} alt="banner" />
      </Banner>
      <TitleTextContainer>
        <TitleText>Make Your Wearable Your Own</TitleText>
        <SubtitleText>Get started by choosing an option below:</SubtitleText>
      </TitleTextContainer>
      <>
        <OptionContainer>
          <OptionHeader
            onClick={() => setShowManageMiiDetails(!showManageMiiDetails)}
          >
            <img src={manageMiiLogo} alt="managemii logo" />
            <img src={showManageMiiDetails ? closeIcon : openIcon} alt="icon" />
          </OptionHeader>
          <Divider />
          <OptionTextContainer>
            <span>If you have a compatible card, download the </span>{" "}
            <b>Manage-Mii</b>
            <span> app to tokenise your payment card.</span>
          </OptionTextContainer>

          {showManageMiiDetails && (
            <>
              <OptionText>Compatible with:</OptionText>
              <IssuersContainer>
                <IssuerContainer>
                  <img src={curve} alt="mc_logo" />
                </IssuerContainer>
                <IssuerContainer>
                  <img src={nordea} alt="mc_logo" />
                </IssuerContainer>
                <IssuerContainer>
                  <img src={mbLogo2} alt="mc_logo" />
                </IssuerContainer>
                <IssuerContainer>
                  <img src={seb} alt="mc_logo" />
                </IssuerContainer>
                <IssuerContainer>
                  <img src={swedbank} alt="mc_logo" />
                </IssuerContainer>
              </IssuersContainer>
              <AppStoresContainer>
                <img
                  src={appstore}
                  onClick={() =>
                    window.open(
                      "https://apps.apple.com/gb/app/manage-mii/id6446908503",
                      "_blank"
                    )
                  }
                  alt="app store"
                />
                <img
                  src={playstore}
                  onClick={() =>
                    window.open(
                      "https://play.google.com/store/apps/details?id=com.digiseq.managemii",
                      "_blank"
                    )
                  }
                  alt="play store"
                />
              </AppStoresContainer>
            </>
          )}
        </OptionContainer>
        {/*
        <OptionContainer>
          <OptionHeader onClick={() => setShowMBDetails(!showMBDetails)}>
            <img src={mbLogo} alt="mbLogo" />
            <img src={showMBDetails ? closeIcon : openIcon} alt="icon" />
          </OptionHeader>
          <Divider />

          <OptionTextContainer>
            <span>Create a </span> <b>MuchBetter</b>
            <span> pre-paid account to load money onto your wearable.</span>
          </OptionTextContainer>
          {showMBDetails && (
            <>
              {anonymousPrepaid ? (
                <ContactForm
                  activationCode={balanceAndPin?.activationCode}
                  onEmailSent={(email) => onEmailSent(email)}
                />
              ) : (
                <OpenAppButtonContainer>
                  <Button
                    onClick={() =>
                      window.open(
                        "https://get.muchbetter.com/mastercard",
                        "_blank"
                      )
                    }
                  >
                    Open MuchBetter App
                  </Button>
                </OpenAppButtonContainer>
              )}
            </>
          )}
        </OptionContainer>
*/}
        <Banner>
          <img src={footer} alt="banner" />
        </Banner>
        <TitleTextContainer>
          <TitleText>Wearables on the Market</TitleText>
          <SubtitleText>
            Manage-Mii supports a wide range of wearables created by fashion
            brands.
          </SubtitleText>
          <BrowseWearablesContainer
            onClick={() =>
              window.open("https://www.manage-mii.co.uk", "_blank")
            }
          >
            Browse wearables
            <img src={arrowRight} alt="arrow" />
          </BrowseWearablesContainer>
        </TitleTextContainer>
      </>

      <Footer>
        <img src={mcLogo} alt="mc_logo" />
      </Footer>
    </MainContainer>
  );
};

export default MasterCardDemo;
