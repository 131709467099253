export const shuffleArr = (arr) =>
  arr
    .map((x) => [Math.random(), x])
    .sort(([a], [b]) => a - b)
    .map(([_, x]) => x);

export const composeErrorMessage = (error) => {
  return (
    (error.response && error.response.data && error.response.data.message) ||
    error.message ||
    error.toString()
  );
};

export const validateEmail = (email) => {
  // https://stackoverflow.com/questions/46155/how-can-i-validate-an-email-address-in-javascript
  return String(email)
    .toLowerCase()
    .match(
      /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/
    );
};

export const validateText = (text) => {
  const test = String(text)
    .toLowerCase()
    .match(/[A-Za-z0-9 _.,!"'-?;:£]*/);

  return test[0] === text.toLowerCase();
};

export const validUrlRegex =
  /(?:http[s]?:\/\/.)?(?:www\.)?[-a-zA-Z0-9@%._\+~#=]{2,256}\.[a-z]{2,6}\b(?:[-a-zA-Z0-9@:%_\+.~#?&\/\/=]*)/gm;

export function validateName(name) {
  const regex = /^[a-zA-Z]+(([',. -][a-zA-Z ])?[a-zA-Z]*)*$/;
  return regex.test(name);
}

export function sleep(time) {
  return new Promise((resolve) => setTimeout(resolve, time));
}

export const convertArrayToCSV = (array) => {
  const keys = Object.keys(array[0]);
  const csvRows = [];

  // Add the header row
  csvRows.push(keys.join(","));

  // Add the data rows
  for (const row of array) {
    const values = keys.map((key) => {
      const escaped = ("" + row[key]).replace(/"/g, '\\"');
      return `"${escaped}"`;
    });
    csvRows.push(values.join(","));
  }

  return csvRows.join("\n");
};

export const downloadCSV = (csvContent, fileName) => {
  const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
  const link = document.createElement("a");

  if (link.download !== undefined) {
    const url = URL.createObjectURL(blob);
    link.setAttribute("href", url);
    link.setAttribute("download", fileName);
    link.style.visibility = "hidden";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
};
