import React, { useState, useEffect } from "react";
import { MainContainer } from "./styled/Registration";

import {
  Content,
  ActionButton,
  Input,
  Label,
  InputGroup,
  Separator,
  ErrorText,
  ErrorLabel
} from "./styled/SharedStyles";
import { useNavigate, useParams } from "react-router-dom";
import Header from "./components/Header";
import Checkbox from "./components/Checkbox";

import arrowRight from "./img/arrow-right.svg";
import AlertComponent from "./components/AlertComponent";
import { registerAscotDevice } from "../../features/ascot/ascotSlice";
import { useDispatch, useSelector } from "react-redux";
import { validateEmail, validateText } from "../../utils/utils";
import Spinner from "../../components/Spinner";

const Registration = () => {
  const [parentFirstName, setParentName] = useState("");
  const [parentFirstNameError, setParentNameError] = useState("");
  const [parentSurname, setParentSurname] = useState("");
  const [parentSurnameError, setParentSurnameError] = useState("");
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");
  const [playerName, setPlayerName] = useState("");
  const [playerNameError, setPlayerNameError] = useState("");
  const [groupName, setTeam] = useState("");
  const [policyAccepted, setPolicyAccepted] = useState(false);
  const [privacyError, setPrivacyError] = useState(false);
  const [showAlert, setShowAlert] = useState(false);

  const params = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { deviceRegistered, isLoading } = useSelector((state) => state.ascot);

  useEffect(() => {
    const localRegistration = localStorage.getItem("ascotFamilyRegistration");

    if (localRegistration) {
      const registration = JSON.parse(localRegistration);

      setParentName(registration.parentFirstName);
      setParentSurname(registration.parentSurname);
      setEmail(registration.email);
      setTeam(registration.groupName);
    }
  }, []);

  useEffect(() => {
    if (deviceRegistered) {
      window.scrollTo({ top: 0, left: 0, behavior: "auto" });
      setShowAlert(true);
    }
  }, [deviceRegistered, dispatch, params.serial]);

  const handleRegister = () => {
    if (parentFirstName && parentSurname && email && playerName) {
      const deviceModel = {
        serialNumber: params.serial,
        parentFirstName,
        parentLastName: parentSurname,
        playerName,
        email,
        groupName,
        privacyPolicyAccepted: policyAccepted
      };

      const regData = {
        parentFirstName,
        parentSurname,
        email,
        groupName
      };

      localStorage.setItem("ascotFamilyRegistration", JSON.stringify(regData));

      dispatch(registerAscotDevice(deviceModel));
    } else {
      if (!parentFirstName) setParentNameError("First name is required");
      if (!parentSurname) setParentSurnameError("Surname is required");
      if (!email) setEmailError("Email is required");
      if (!validateEmail(email)) {
        setEmailError("Please enter a valid email");
      }

      if (!validateText(parentFirstName)) {
        setParentNameError("Please dont use special characters");
      }
      if (!validateText(parentSurname)) {
        setParentSurnameError("Please dont use special characters");
      }
      if (!validateText(playerName)) {
        setPlayerNameError("Please dont use special characters");
      }
      if (!playerName) setPlayerNameError("Player name is required");
    }
  };

  if (isLoading) return <Spinner />;

  return (
    <MainContainer>
      <Header />
      <Content>
        <h1>TELL US ABOUT YOURSELF</h1>
        <Separator isDark />
        <InputGroup>
          <Label>Parent first name*</Label>
          <Input
            name="parentFirstName"
            value={parentFirstName}
            onChange={(ev) => {
              setParentNameError("");
              setParentName(ev.target.value);
            }}
            type="text"
            required
          />
        </InputGroup>
        <ErrorLabel>{parentFirstNameError}</ErrorLabel>
        <InputGroup>
          <Label>Parent surname*</Label>
          <Input
            name="parentSurname"
            value={parentSurname}
            onChange={(ev) => {
              setParentSurnameError("");
              setParentSurname(ev.target.value);
            }}
            type="text"
            required
          />
        </InputGroup>
        <ErrorLabel>{parentSurnameError}</ErrorLabel>
        <InputGroup>
          <Label>Parent email address*</Label>
          <Input
            name="email"
            value={email}
            onChange={(ev) => {
              setEmailError("");
              setEmail(ev.target.value);
            }}
            type="email"
            required
          />
        </InputGroup>
        <ErrorLabel>{emailError}</ErrorLabel>
        <InputGroup>
          <Label>Player name*</Label>
          <Input
            name="playerName"
            value={playerName}
            onChange={(ev) => {
              setPlayerNameError("");
              setPlayerName(ev.target.value);
            }}
            type="text"
            required
          />
        </InputGroup>
        <ErrorLabel>{playerNameError}</ErrorLabel>
        <InputGroup>
          <Label>
            Team name. Put the same name as your family and friends to work
            together!
          </Label>
          <Input
            name="groupName"
            value={groupName}
            onChange={(ev) => setTeam(ev.target.value.toUpperCase())}
            type="text"
            required
            placeholder="e.g. REINDEER"
          />
        </InputGroup>
        <Label>
          <div style={{ marginBottom: "8px" }}>
            Your child’s name is captured for the purposes of the game only, and
            will not be retained on any systems after the event.
          </div>

          <div>
            If you would like to receive information about events, new products
            and special offers from Ascot Racecourse, please tick this box. All
            use of personal data shall be in line with our{" "}
            <a
              href="https://www.ascot.com/ascot-policies/privacy-policy"
              target="_blank"
              rel="noopener noreferrer"
            >
              privacy notice
            </a>
            .
            <Checkbox
              onChange={(state) => {
                if (state) {
                  setPrivacyError(false);
                }
                setPolicyAccepted(state);
              }}
            />
          </div>
        </Label>

        {privacyError && (
          <ErrorText>
            Please confirm that you have read and understood the Privacy Policy
          </ErrorText>
        )}
        <Separator />

        <ActionButton onClick={() => handleRegister()}>
          NEXT <img src={arrowRight} alt="arrow right" />
        </ActionButton>
      </Content>
      {showAlert && (
        <AlertComponent
          title="WORKING AS A TEAM?"
          subtitle="Tap a new fob to add another player or continue to the scavenger hunt."
          buttonLabel="START GAME"
          onClose={() => {
            setShowAlert(false);
            navigate(`/ascot-family-day/${params.serial}/1`);
          }}
        />
      )}
    </MainContainer>
  );
};

export default Registration;
