import { useMediaQuery } from "react-responsive";
import { StyledMain } from "../../styled/StyledMain";
import { MainContainer } from "../../styled/StyledMainContainer";

import AboutSection from "./components/AboutSection";
import Container from "./components/Container";
import ExploreTheBrands from "./components/ExploreTheBrands";
import MessageSection from "./components/MessageSection";
import LatestNews from "./components/LatestNews";
import TopSection from "./components/TopSection";

import { Link } from "react-router-dom";
import Header from "../../components/Header";
import Footer from "../../components/Footer";

const Homepage = () => {
  const isMobile = useMediaQuery({ query: "(max-width: 390px)" });
  return (
    <>
      <Header />
      <StyledMain>
        <MainContainer>
          <TopSection />
          <AboutSection />
          <Link to="/brands">
            <MessageSection
              title="DIGISEQ"
              text="Together with Mastercard®, we enabled the world’s first tokenised payment service for passive wearables. "
              hasImage
            />
          </Link>
          <ExploreTheBrands />
          <Link to="/products">
            <MessageSection
              title={`Safe & discreet`}
              text={
                isMobile
                  ? "Payment-ready wearables are unassuming to onlookers and completely secure thanks to tokenisation."
                  : "Payment-ready wearables are everyday accessories. They are unassuming to onlookers and completely secure thanks to tokenisation."
              }
            />
          </Link>
          <LatestNews />
        </MainContainer>
      </StyledMain>
      <Footer />
    </>
  );
};

export default Homepage;
