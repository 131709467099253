import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { composeErrorMessage } from "../../utils/utils";
import dasDeviceManagerService from "./dasDeviceManagerService";

const initialState = {
  devices: null,
  device: null,
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: "",
  alreadyExistingSerialNumbers: null,
  nonExistingDevices: null,
  updatedCampaign: null,
  updatedDevice: null,
  deletedCampaign: null,
  devicesTransferred: false,
  dasDeviceInfo: null,
  gettingDasDeviceInfo: false,
  errorGettingDasDeviceInfo: ""
};

export const editDasDevice = createAsyncThunk(
  "devices/editDasDevice",
  async (payload, thunkAPI) => {
    try {
      return await dasDeviceManagerService.editDasDevice(payload);
    } catch (error) {
      return thunkAPI.rejectWithValue(composeErrorMessage(error));
    }
  }
);

export const createDasDevices = createAsyncThunk(
  "devices/createDasDevices",
  async (payload, thunkAPI) => {
    try {
      return await dasDeviceManagerService.createDasDevices(payload);
    } catch (error) {
      return thunkAPI.rejectWithValue(composeErrorMessage(error));
    }
  }
);

export const getDasDevicesByTag = createAsyncThunk(
  "devices/getDasDevicesByTag",
  async (tag, thunkAPI) => {
    try {
      return await dasDeviceManagerService.getDasDevicesByTag(tag);
    } catch (error) {
      return thunkAPI.rejectWithValue(composeErrorMessage(error));
    }
  }
);

export const getDasDevicesByCampaign = createAsyncThunk(
  "devices/getDasDevicesByCampaign",
  async (campaignId, thunkAPI) => {
    try {
      return await dasDeviceManagerService.getDasDevicesByCampaign(campaignId);
    } catch (error) {
      return thunkAPI.rejectWithValue(composeErrorMessage(error));
    }
  }
);

export const getDasDevicesBySerial = createAsyncThunk(
  "devices/getDasDevicesBySerial",
  async (serial, thunkAPI) => {
    try {
      return await dasDeviceManagerService.getDasDevice(serial);
    } catch (error) {
      return thunkAPI.rejectWithValue(composeErrorMessage(error));
    }
  }
);

export const transferTaggedDasDevices = createAsyncThunk(
  "devices/transferTaggedDasDevices",
  async (payload, thunkAPI) => {
    try {
      return await dasDeviceManagerService.transferTaggedDasDevices(payload);
    } catch (error) {
      return thunkAPI.rejectWithValue(composeErrorMessage(error));
    }
  }
);
export const transferExistingDasDevices = createAsyncThunk(
  "devices/transferExistingDasDevices",
  async (payload, thunkAPI) => {
    try {
      return await dasDeviceManagerService.transferExistingDasDevices(payload);
    } catch (error) {
      return thunkAPI.rejectWithValue(composeErrorMessage(error));
    }
  }
);

export const getDasDeviceInfo = createAsyncThunk(
  "devices/getDasDeviceInfo",
  async (serial, thunkAPI) => {
    try {
      const response = await dasDeviceManagerService.getDasDeviceInfo(serial);

      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(composeErrorMessage(error));
    }
  }
);

export const dasDevicesSlice = createSlice({
  name: "daseDevices",
  initialState,
  reducers: {
    reset: () => initialState,
    resetDasDevices: (state) => {
      state.devices = null;
      state.device = null;
      state.updatedDevice = null;
      state.alreadyExistingSerialNumbers = null;
      state.nonExistingDevices = null;
      state.devicesTransferred = false;
    },
    resetError: (state) => {
      state.isError = null;
    },
    updateLocalDasDeviceInfo: (state, action) => {
      state.dasDeviceInfo = action.payload;
    },
    updateLocalDasDeviceInfoPersonalDetails: (state, action) => {
      state.dasDeviceInfo.personalDetails = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder

      .addCase(createDasDevices.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(createDasDevices.fulfilled, (state, action) => {
        state.isLoading = false;
        state.alreadyExistingSerialNumbers =
          action.payload.alreadyExistingSerialNumbers;
      })
      .addCase(createDasDevices.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(editDasDevice.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(editDasDevice.fulfilled, (state, action) => {
        state.isLoading = false;
        state.updatedDevice = action.payload;
      })
      .addCase(editDasDevice.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(getDasDevicesByCampaign.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getDasDevicesByCampaign.fulfilled, (state, action) => {
        state.isLoading = false;
        state.devices = action.payload;
      })
      .addCase(getDasDevicesByCampaign.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(getDasDevicesByTag.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getDasDevicesByTag.fulfilled, (state, action) => {
        state.isLoading = false;
        state.devices = action.payload;
      })
      .addCase(getDasDevicesByTag.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(getDasDevicesBySerial.pending, (state) => {
        state.isLoading = true;
        state.updatedDevice = null;
      })
      .addCase(getDasDevicesBySerial.fulfilled, (state, action) => {
        state.isLoading = false;
        state.device = action.payload;
      })
      .addCase(getDasDevicesBySerial.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(transferTaggedDasDevices.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(transferTaggedDasDevices.fulfilled, (state, action) => {
        state.isLoading = false;
        state.devicesTransferred = true;
      })
      .addCase(transferTaggedDasDevices.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(transferExistingDasDevices.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(transferExistingDasDevices.fulfilled, (state, action) => {
        state.isLoading = false;
        state.devicesTransferred = true;
        state.nonExistingDevices = action.payload.nonExistingDevices;
      })
      .addCase(transferExistingDasDevices.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(getDasDeviceInfo.pending, (state) => {
        state.gettingDasDeviceInfo = true;
        state.errorGettingDasDeviceInfo = "";
      })
      .addCase(getDasDeviceInfo.fulfilled, (state, action) => {
        state.gettingDasDeviceInfo = false;
        state.dasDeviceInfo = action.payload;
      })
      .addCase(getDasDeviceInfo.rejected, (state, action) => {
        state.gettingDasDeviceInfo = false;
        state.errorGettingDasDeviceInfo = action.payload;
      });
  }
});

export const { reset, resetError, resetDasDevices, updateLocalDasDeviceInfo, updateLocalDasDeviceInfoPersonalDetails } =
  dasDevicesSlice.actions;
export default dasDevicesSlice.reducer;
