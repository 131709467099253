import React, { useState, useEffect, useRef, Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import {
  getDasDeviceInfo,
  updateLocalDasDeviceInfoPersonalDetails
} from "../../features/das-device-manager/dasDeviceManagerSlice";
import { collectItem } from "../../features/worlds24/worlds24Slice";
import { savePersonalDetailsAction } from "../../features/balance-and-pin/balanceAndPinSlice";

import usePrevious from "../../hooks/usePrevious";
import LanguageSelector from "./LanguageSelect";
import { StyledSpinner } from "../../components/styled/Spinner";

import {
  MainContainer,
  NavBar,
  HeroContainer,
  FeaturesContainer,
  FeatureItem,
  TeamCodeContainer,
  Footer,
  InnerContainer,
  InfoAlert,
  ScheduleList,
  ScheduleItem,
  KeyFobDesigns,
  Button,
  GameItem,
  BountyGame,
  ChampionInputContainer,
  PersonalDetailsForm,
  AfterEvent,
  WelcomeContainer
} from "./styles";

import logo from "./img/logo.png";
import hero from "./img/mc-mercedes-logo.svg";
import copyToClipboard from "./img/copy-icon.svg";
import digiseqLogo from "../../assets/digiseq-logo.png";
import infoCloseIcon from "./img/close-icon.svg";
import keyFobDesigns from "./img/keyfobs.webp";
import completedIcon from "./img/completed.png";
import miniGamesIcon from "./img/mini_games_icon.svg";
import keyfobDesignIcon from "./img/keyfobs_icon.svg";
import scheduleIcon from "./img/scedule_icon.svg";
import infoIcon from "./img/info_icon.svg";
import chevronRight from "../../assets/chevron-right.svg";
import chevronDown from "./img/expand-down.svg";
import chevronUp from "./img/expand-up.svg";

import packageJson from "../../../package.json";

const Worlds24 = () => {
  const [searchParams] = useSearchParams();
  const serialNumber = searchParams.get("serialNumber");
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [info, setInfo] = useState("");
  const [savingItem, setSavingItem] = useState(null);
  const [copiedToClipboard, setCopiedToClipboard] = useState(false);
  const [fullName, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [showForm, setShowForm] = useState(false);
  const [errors, setErrors] = useState({});
  const [showSkinCode, setShowSkinCode] = useState(false);

  const inputRefs = useRef([]);

  const { collectingItem, collectingItemError } = useSelector(
    (state) => state.worlds24
  );

  const { savingPersonalDetails, errorSavingPersonalDetails } = useSelector(
    (state) => state.balanceAndPin
  );

  const { dasDeviceInfo, gettingDasDeviceInfo, errorGettingDasDeviceInfo } =
    useSelector((state) => state.dasDevices);

  const prevGettingDeviceInfo = usePrevious(gettingDasDeviceInfo);
  const prevSavingPersonalDetails = usePrevious(savingPersonalDetails);

  useEffect(() => {
    if (prevSavingPersonalDetails && !errorSavingPersonalDetails) {
      dispatch(
        updateLocalDasDeviceInfoPersonalDetails({
          fullName,
          email
        })
      );
      window.scrollTo({ top: 0, left: 0, behavior: "auto" });
      setShowForm(false);
    }
  }, [
    dasDeviceInfo,
    dispatch,
    email,
    errorSavingPersonalDetails,
    fullName,
    prevSavingPersonalDetails
  ]);

  useEffect(() => {
    if (prevGettingDeviceInfo && !errorGettingDasDeviceInfo) {
      if (
        dasDeviceInfo?.personalDetails?.fullName &&
        dasDeviceInfo?.personalDetails?.email
      ) {
        setFullName(dasDeviceInfo?.personalDetails?.fullName);
        setEmail(dasDeviceInfo?.personalDetails?.email);
        setShowForm(false);
      } else {
        setShowForm(true);
      }
    }
  }, [
    dasDeviceInfo?.personalDetails?.fullName,
    dasDeviceInfo?.personalDetails?.email,
    errorGettingDasDeviceInfo,
    prevGettingDeviceInfo
  ]);

  const validateForm = () => {
    let formErrors = {};

    const namePattern = /^[A-Za-zÀ-ÖØ-öø-ÿ\s'-]+$/;
    if (!fullName.trim()) {
      formErrors.fullName = t("worlds24.personalDetails.fullNameRequired");
    } else if (!namePattern.test(fullName)) {
      formErrors.fullName = t("worlds24.personalDetails.fullNameError");
    }

    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!email.trim()) {
      formErrors.email = t("worlds24.personalDetails.emailRequired");
    } else if (!emailPattern.test(email)) {
      formErrors.email = t("worlds24.personalDetails.emailError");
    }

    setErrors(formErrors);

    return Object.keys(formErrors).length === 0;
  };

  const handleSubmit = () => {
    if (validateForm()) {
      dispatch(
        savePersonalDetailsAction({
          serial: serialNumber,
          details: { fullName, email }
        })
      );
    }
  };

  const [item, setItem] = useState({
    1: "",
    2: "",
    3: "",
    4: "",
    5: "",
    6: ""
  });

  const handleSetItem = (item) => {
    setSavingItem(null);
    setItem(item);
  };

  useEffect(() => {
    if (collectingItemError && savingItem !== null) {
      inputRefs.current[savingItem - 1]?.focus();
    }
  }, [collectingItemError, savingItem]);

  const handleCollectItem = (itemId) => {
    setSavingItem(itemId);
    dispatch(collectItem({ serial: serialNumber, itemId: item[itemId] })).then(
      () => {
        setItem({
          1: "",
          2: "",
          3: "",
          4: "",
          5: "",
          6: ""
        });
      }
    );
  };

  useEffect(() => {
    if (!dasDeviceInfo) {
      dispatch(getDasDeviceInfo(serialNumber));
    }
  }, [dasDeviceInfo, dispatch, serialNumber]);

  const schedule = [
    { time: "13:00", event: t("worlds24.schedule.1300") },
    { time: "14:00", event: t("worlds24.schedule.1400") },
    { time: "14:15", event: t("worlds24.schedule.1415") },
    { time: "15:00", event: t("worlds24.schedule.1500") },
    { time: "19:30", event: t("worlds24.schedule.1930") }
  ];

  const londonEvents = [
    "Matches in London",
    "Analyse",
    "Mini Games",
    "3v3 Matches"
  ];

  if (gettingDasDeviceInfo) return <StyledSpinner />;

  return (
    <MainContainer>
      <NavBar>
        <img className="logo" src={logo} alt={t("worlds24.altText.logo")} />
        <LanguageSelector />
      </NavBar>
      <InnerContainer>
        {dasDeviceInfo?.personalDetails?.fullName && (
          <WelcomeContainer>
            <h2>
              {t("worlds24.welcome")} {dasDeviceInfo?.personalDetails?.fullName}
            </h2>
          </WelcomeContainer>
        )}
        <HeroContainer>
          <img src={hero} alt={t("worlds24.altText.hero")} />
          <h1>{t("worlds24.hero.title")}</h1>
          <div>
            <p>{t("worlds24.hero.description1")}</p>
            <p>{t("worlds24.hero.description2")}</p>
            <p>{t("worlds24.hero.description3")}</p>
          </div>
        </HeroContainer>
        <FeaturesContainer>
          <h2>{t("worlds24.features.title")}</h2>
          <div className="features-grid">
            <FeatureItem onClick={() => setInfo("miniGames")}>
              <img src={miniGamesIcon} alt="minigames" />
              <p>{t("worlds24.features.miniGames")}</p>
            </FeatureItem>
            <FeatureItem onClick={() => setInfo("schedule")}>
              <img src={scheduleIcon} alt="schedule" />
              <p>{t("worlds24.features.schedule")}</p>
            </FeatureItem>
            <FeatureItem onClick={() => setInfo("designs")}>
              <img src={keyfobDesignIcon} alt="keyfobs" />
              <p>{t("worlds24.features.keyFobDesigns")}</p>
            </FeatureItem>
            <FeatureItem onClick={() => setInfo("reuse")}>
              <img src={infoIcon} alt="info" />
              <p>{t("worlds24.features.keyFobReuse")}</p>
            </FeatureItem>
          </div>
        </FeaturesContainer>

        {dasDeviceInfo?.eventData?.worlds_24?.ticketManager?.redeemableCode && (
          <TeamCodeContainer>
            <div>
              <h2>{t("worlds24.teamCode.title1")}</h2>
              <h2>{t("worlds24.teamCode.title2")}</h2>
              <img
                className="chevron"
                src={!showSkinCode ? chevronDown : chevronUp}
                alt={t("worlds24.altText.showSkinCode")}
                onClick={() => setShowSkinCode(!showSkinCode)}
              />
            </div>
            {showSkinCode && (
              <div className="code-container">
                <h2 className="code-text">
                  {
                    dasDeviceInfo?.eventData?.worlds_24?.ticketManager
                      ?.redeemableCode
                  }
                </h2>
                <img
                  src={copyToClipboard}
                  alt={t("worlds24.altText.copy")}
                  onClick={() => {
                    navigator?.clipboard?.writeText(
                      dasDeviceInfo?.eventData?.worlds_24?.ticketManager
                        ?.redeemableCode
                    );
                    setCopiedToClipboard(true);
                    setTimeout(() => {
                      setCopiedToClipboard(false);
                    }, 2000);
                  }}
                />
              </div>
            )}
            {copiedToClipboard && <p>{t("worlds24.teamCode.copied")}</p>}
          </TeamCodeContainer>
        )}
        <AfterEvent>
          <h2>{t("worlds24.afterEvent.title")}</h2>
          <p>{t("worlds24.afterEvent.description")}</p>

          <div
            className="button"
            onClick={() =>
              window.open(
                `https://www.manage-mii.co.uk/mb-payment-account/?serialNumber=${serialNumber}`,
                "_blank"
              )
            }
          >
            {t("worlds24.afterEvent.learnMore")}{" "}
            <img src={chevronRight} alt="chevron right" />
          </div>
        </AfterEvent>
      </InnerContainer>
      <Footer>
        <p>{t("worlds24.footer.poweredBy")}</p>
        <img
          onClick={() => {
            window.open("https://digiseq.co.uk", "_blank");
          }}
          src={digiseqLogo}
          alt={t("worlds24.altText.digiseq")}
        />
        <div
          className="copyToClipboard"
          onClick={() => navigator.clipboard.writeText(serialNumber)}
        >
          <p>ID: {serialNumber}</p>
        </div>
        <div className="version">
          <p>{packageJson.version}</p>
        </div>
      </Footer>
      {info === "schedule" && (
        <InfoAlert>
          <div className="inner-container">
            <img
              className="close-button"
              src={infoCloseIcon}
              alt={t("worlds24.altText.closeIcon")}
              onClick={() => setInfo("")}
            />
            <h2>{t("worlds24.schedule.title")}</h2>
            <ScheduleList>
              {schedule.map((item, index) => (
                <Fragment key={index}>
                  <ScheduleItem key={index}>
                    <span className="time">{item.time}</span>
                    <span>
                      <p> - </p>
                    </span>
                    <span>
                      <p>{item.event}</p>
                    </span>
                  </ScheduleItem>
                  {index === 3 && (
                    <div className="london-events">
                      {londonEvents.map((event, i) => (
                        <span key={i}>
                          <p>{event}</p>
                        </span>
                      ))}
                    </div>
                  )}
                </Fragment>
              ))}
            </ScheduleList>
          </div>
        </InfoAlert>
      )}
      {info === "designs" && (
        <InfoAlert>
          <div className="inner-container">
            <img
              className="close-button"
              src={infoCloseIcon}
              alt={t("worlds24.altText.closeIcon")}
              onClick={() => setInfo("")}
            />
            <h2>{t("worlds24.designs.title")}</h2>
            <p>{t("worlds24.designs.subtitle")}</p>
            <KeyFobDesigns>
              <img
                src={keyFobDesigns}
                alt={t("worlds24.altText.keyFobDesigns")}
              />
            </KeyFobDesigns>
          </div>
        </InfoAlert>
      )}
      {info === "reuse" && (
        <InfoAlert>
          <div className="inner-container">
            <img
              className="close-button"
              src={infoCloseIcon}
              alt={t("worlds24.altText.closeIcon")}
              onClick={() => setInfo("")}
            />
            <h2>{t("worlds24.reuse.title")}</h2>
            <p>{t("worlds24.reuse.description")}</p>
            <Button
              onClick={() =>
                window.open(
                  `https://www.manage-mii.co.uk/mb-payment-account/?serialNumber=${serialNumber}`,
                  "_blank"
                )
              }
            >
              {t("worlds24.reuse.learnMore")}
            </Button>
          </div>
        </InfoAlert>
      )}

      {info === "miniGames" && (
        <InfoAlert>
          {!errorGettingDasDeviceInfo ? (
            <div className="inner-container">
              <img
                className="close-button"
                src={infoCloseIcon}
                alt={t("worlds24.altText.closeIcon")}
                onClick={() => setInfo("")}
              />
              <h2>{t("worlds24.miniGames.title")}</h2>
              <p className="text-center">{t("worlds24.miniGames.subtitle")}</p>
              <GameItem>
                <p className="text-center">{t("worlds24.miniGames.game1")}</p>
                {dasDeviceInfo?.eventData?.worlds_24?.pointsManager
                  ?.pointsByCategory?.[
                  "CAITLYN'S VS JINX LONGSHOT CHALLENGE"
                ] ? (
                  <img
                    src={completedIcon}
                    alt={t("worlds24.altText.completed")}
                  />
                ) : null}
              </GameItem>
              <GameItem>
                <p className="text-center">{t("worlds24.miniGames.game2")}</p>
                {dasDeviceInfo?.eventData?.worlds_24?.pointsManager
                  ?.pointsByCategory?.["OLAF AXE THROWING"] ? (
                  <img
                    src={completedIcon}
                    alt={t("worlds24.altText.completed")}
                  />
                ) : null}
              </GameItem>
              <GameItem>
                <p className="text-center">{t("worlds24.miniGames.game3")}</p>
                {dasDeviceInfo?.eventData?.worlds_24?.pointsManager
                  ?.pointsByCategory?.["EZREAL'S MYSTIC SHOT"] ? (
                  <img
                    src={completedIcon}
                    alt={t("worlds24.altText.completed")}
                  />
                ) : null}
              </GameItem>
              {dasDeviceInfo?.campaignResults?.worlds_24
                ?.scavengerHuntCollectedItems?.itemsCollected?.length === 6 &&
              Object.keys(
                dasDeviceInfo?.eventData?.worlds_24?.pointsManager
                  ?.pointsByCategory
              )?.length === 3 ? (
                <>
                  <GameItem>
                    <p>{t("worlds24.miniGames.championBountyHunt")}</p>
                    <img
                      src={completedIcon}
                      alt={t("worlds24.altText.completed")}
                    />
                  </GameItem>
                  <p>{t("worlds24.miniGames.completedAllGames")}</p>
                </>
              ) : (
                <BountyGame>
                  <p className="title">
                    {t("worlds24.miniGames.bountyHunt.title")}
                  </p>
                  <p>{t("worlds24.miniGames.bountyHunt.instruction")}</p>
                  {[1, 2, 3, 4, 5, 6].map((num) => (
                    <React.Fragment key={num}>
                      <ChampionInputContainer
                        key={num}
                        guessed={
                          dasDeviceInfo?.campaignResults?.worlds_24
                            ?.scavengerHuntCollectedItems.itemsCollected[
                            num - 1
                          ]
                        }
                      >
                        {dasDeviceInfo?.campaignResults?.worlds_24
                          ?.scavengerHuntCollectedItems.itemsCollected[
                          num - 1
                        ] ? (
                          <p className="champion-name">
                            {
                              dasDeviceInfo?.campaignResults?.worlds_24
                                ?.scavengerHuntCollectedItems.itemsCollected[
                                num - 1
                              ]
                            }
                          </p>
                        ) : (
                          <input
                            autoCorrect="off"
                            autoComplete="off"
                            autoCapitalize="off"
                            ref={(el) => (inputRefs.current[num - 1] = el)}
                            value={item[num]}
                            onChange={(e) =>
                              handleSetItem({ ...item, [num]: e.target.value })
                            }
                            placeholder={t(
                              "worlds24.miniGames.bountyHunt.championPlaceholder",
                              {
                                number: num
                              }
                            )}
                            type="text"
                          />
                        )}

                        {dasDeviceInfo?.campaignResults?.worlds_24
                          ?.scavengerHuntCollectedItems.itemsCollected[
                          num - 1
                        ] ? (
                          <img
                            src={completedIcon}
                            alt={t("worlds24.altText.completed")}
                          />
                        ) : (
                          <div
                            className={`button ${
                              savingItem === num || !item[num]
                                ? "disabled button"
                                : "button"
                            }`}
                            onClick={() =>
                              savingItem === num || !item[num]
                                ? null
                                : handleCollectItem(num)
                            }
                          >
                            {savingItem === num && collectingItem
                              ? t("worlds24.miniGames.bountyHunt.saving")
                              : t("worlds24.miniGames.bountyHunt.save")}
                          </div>
                        )}
                      </ChampionInputContainer>
                      {collectingItemError && savingItem === num && (
                        <p className="error-message">
                          {t("worlds24.miniGames.bountyHunt.error")}
                        </p>
                      )}
                    </React.Fragment>
                  ))}
                </BountyGame>
              )}
            </div>
          ) : (
            <div className="inner-container">
              <img
                className="close-button"
                src={infoCloseIcon}
                alt={t("worlds24.altText.closeIcon")}
                onClick={() => setInfo("")}
              />
              <h2>{t("worlds24.miniGames.title")}</h2>
              <p>{t("worlds24.miniGames.error")}</p>
            </div>
          )}
        </InfoAlert>
      )}

      {showForm && (
        <InfoAlert>
          <PersonalDetailsForm>
            <LanguageSelector />
            <h2>{t("worlds24.personalDetails.title")}</h2>
            <p>{t("worlds24.personalDetails.instruction")}</p>
            <div className="form-group">
              <input
                autoCorrect="off"
                type="text"
                required
                placeholder={t("worlds24.personalDetails.fullNamePlaceholder")}
                value={fullName}
                onChange={(e) => {
                  setFullName(e.target.value);
                  setErrors({ ...errors, fullName: "" });
                }}
                className={errors.fullName ? "error" : ""}
              />
              {errors.fullName && <p className="error">{errors.fullName}</p>}
              <input
                autoCorrect="off"
                autoCapitalize="off"
                type="email"
                required
                placeholder={t("worlds24.personalDetails.emailPlaceholder")}
                value={email}
                onChange={(e) => {
                  setEmail(e.target.value);
                  setErrors({ ...errors, email: "" });
                }}
                className={errors.email ? "error" : ""}
                onBlur={() => {
                  setErrors({ ...errors, email: "" });
                }}
              />
              {errors.email && <p className="error">{errors.email}</p>}
            </div>
            {!savingPersonalDetails && (
              <Button onClick={handleSubmit} fullWidth>
                {t("worlds24.personalDetails.submit")}
              </Button>
            )}

            {errorGettingDasDeviceInfo ||
              (errorSavingPersonalDetails && (
                <p className="error">{t("worlds24.personalDetails.error")}</p>
              ))}
            {savingPersonalDetails && (
              <div className="spinner-container">
                <StyledSpinner width="32px" height="32px" />
              </div>
            )}
          </PersonalDetailsForm>
        </InfoAlert>
      )}
    </MainContainer>
  );
};

export default Worlds24;
