import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import FeaturedProducts from "../products/components/FeaturedProducts";
import { StyledMain } from "../../styled/StyledMain";
import { MainContainer } from "../../styled/StyledMainContainer";
import {
  BrandContainer,
  BrandDescription,
  BrandFeatured,
  BrandImageContainer,
  BrandMoreProducts,
  Divider,
} from "./components/styled/Brand";
import Wearable from "../products/components/Wearable";

import {
  getDevicesByBrand,
  resetDevicesByBrand,
} from "../../features/devices/deviceSlice";
import Spinner from "../../components/Spinner";
import {
  getEntityById,
  resetEntity,
} from "../../features/entities/entitySlice";
import { GoButton } from "../brands/components/styled/FeaturedBrand";
import Header from "../../components/Header";
import Footer from "../../components/Footer";

const Brand = () => {
  const params = useParams();
  const dispatch = useDispatch();
  const [paragraphedDescription, setParagraphedDescription] = useState(null);
  const { devicesByBrand } = useSelector((state) => state.devices);

  const { entity } = useSelector((state) => state.entities);

  useEffect(() => {
    if (!entity) {
      dispatch(getEntityById(params.brand));
    }
  }, [dispatch, entity, params.brand]);

  useEffect(() => {
    return () => {
      dispatch(resetEntity());
      dispatch(resetDevicesByBrand());
    };
  }, [dispatch]);

  useEffect(() => {
    if (devicesByBrand.length === 0) {
      let formattedBrand = params.brand;
      if (params.brand === "stiss") {
        formattedBrand = "stiss augmented watches";
      } else if (params.brand === "rosan_pay") {
        formattedBrand = "rosan diamond";
      }

      dispatch(getDevicesByBrand(formattedBrand));
    }
  }, [devicesByBrand.length, dispatch, params.brand]);

  useEffect(() => {
    if (entity?.description) {
      const splitAt = (index, xs = "") => [xs.slice(0, index), xs.slice(index)];

      const halfDescriptionIndex = entity.description?.indexOf(
        ".",
        entity.description.length / 2 || ""
      );

      const paragraphedDescription = splitAt(
        halfDescriptionIndex + 1,
        entity.description
      );

      setParagraphedDescription(paragraphedDescription);
    }
  }, [entity?.description]);

  return (
    <>
      <Header />
      <StyledMain>
        <MainContainer>
          {entity && (
            <BrandContainer>
              {entity.coverImage && (
                <BrandImageContainer>
                  <img src={entity.coverImage} alt="brand img" />
                  {entity.storeLink && (
                    <GoButton>
                      <a
                        href={entity.storeLink}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Go to store
                      </a>
                    </GoButton>
                  )}
                </BrandImageContainer>
              )}
              <BrandDescription>
                {entity.name && <h2>{entity.name}</h2>}
                {paragraphedDescription &&
                  paragraphedDescription.map((e, i) => <p key={i}>{e}</p>)}
              </BrandDescription>
              <Divider />
              <BrandFeatured>
                <h3>{`${entity.name} Products`}</h3>
              </BrandFeatured>
              <FeaturedProducts brand={params.brand} />
              <BrandMoreProducts>
                <React.Suspense fallback={<Spinner />}>
                  {devicesByBrand.map((e, i) => (
                    <div key={i}>
                      <Wearable device={e} />
                    </div>
                  ))}
                </React.Suspense>
              </BrandMoreProducts>
            </BrandContainer>
          )}
        </MainContainer>
      </StyledMain>
      <Footer />
    </>
  );
};

export default Brand;
