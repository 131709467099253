import imageComingSoon from "./image-coming-soon.svg";

function createResource(asyncFn) {
  let status = "pending";

  let result;

  const promise = asyncFn().then(
    (r) => {
      status = "success";
      result = r;
    },
    (e) => {
      status = "error";
      result = imageComingSoon; // TODO replace with default/generic image for error/fail to load
    }
  );

  return {
    read() {
      switch (status) {
        case "pending":
          throw promise;
        case "error":
          return result;
        case "success":
          return result;
        default:
          return null;
      }
    }
  };
}

const cache = new Map();
export function loadImage(source) {
  let resource = cache.get(source);
  if (resource) return resource;
  resource = createResource(
    () =>
      new Promise((resolve, reject) => {
        const img = new window.Image();
        img.src = source;
        img.addEventListener("load", () => resolve(source));
        img.addEventListener("error", () =>
          reject(new Error(`Failed to load image ${source}`))
        );
      })
  );

  cache.set(source, resource);

  return resource;
}
