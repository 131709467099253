import axios from "axios";
import { Services } from "mp-common-js";
import { ENV } from "../../env";

export const API_URL = `https://www.${
  ENV !== "production" ? (ENV === "development" ? "dev" : ENV) + "-" : ""
}manage-mii.uk/api/discover/devices`;

// Get devices (public)
const getDevices = async () => {
  const response = await axios.get(API_URL);

  return response.data;
};

// Get device by brand (public)
const getDevicesByBrand = async (brand) => {
  const response = await axios.get(API_URL + `/${brand.replace("_", " ")}`);

  return response.data;
};

// Get device by brand (public)
const getDeviceByModel = async (brand, model, version) => {
  const response = await axios.get(
    API_URL + `/brand/${brand}/model/${model}/version/${version}`
  );

  return response.data;
};

// Get devices (privat)
const getUserDevices = async () => {
  const response = await Services.DeviceService.getAllDevices();

  return response.data;
};

const deviceService = {
  getUserDevices,
  getDevices,
  getDevicesByBrand,
  getDeviceByModel
};

export default deviceService;
