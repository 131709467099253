import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";

import {
  Divider,
  IconContainer,
  ListContainer,
  ListItem,
  MainContainer,
  Navbar,
  Title
} from "./styles";

import questionIcon from "../../assets/faqs-icons/question.svg";
import manageIcon from "../../assets/faqs-icons/manage.svg";
import informationIcon from "../../assets/faqs-icons/information.svg";
import chevronRightIcon from "../../assets/faqs-icons/chevron-right.svg";
import chevronLeftIcon from "../../assets/faqs-icons/chevron-left.svg";
import settingsIcon from "../../assets/faqs-icons/settings.svg";
import devicesIcon from "../../assets/faqs-icons/devices.svg";
import paymentsIcon from "../../assets/faqs-icons/payments.svg";
import curveIcon from "../../assets/faqs-icons/curve.svg";
import bankCardsIcon from "../../assets/faqs-icons/bank-cards.svg";
import otherServicesIcon from "../../assets/faqs-icons/other-services.svg";
import setupIcon from "../../assets/faqs-icons/setup.svg";
import problemIcon from "../../assets/faqs-icons/problem.svg";

const FaqsTopic = () => {
  const { topic } = useParams();
  const { t } = useTranslation("faqs");
  const navigate = useNavigate();
  const topicContent = t(`faqs:${topic}`, { returnObjects: true });

  const subTopicIcons = {
    information: informationIcon,
    manageDelete: manageIcon,
    whyHow: questionIcon,
    settings: settingsIcon,
    devices: devicesIcon,
    payments: paymentsIcon,
    curve: curveIcon,
    bankCards: bankCardsIcon,
    otherServices: otherServicesIcon,
    setup: setupIcon,
    problem: problemIcon
  };

  if (!topic) return null;
  return (
    <>
      <Navbar>
        <div className="back-button" onClick={() => navigate(-1)}>
          <img src={chevronLeftIcon} alt="chevron left" />
          <p>{t("back")}</p>
        </div>
      </Navbar>

      <MainContainer>
        <Title>{t(`faqs:topics.${topic}`)}</Title>
        <ListContainer>
          {Object.keys(topicContent).map((subTopic, index) => (
            <ListItem
              key={index}
              subTopic={subTopic}
              onClick={() => navigate(`/faqs/${topic}/${subTopic}`)}
            >
              <IconContainer>
                <img src={subTopicIcons[subTopic]} alt={subTopic} />
              </IconContainer>
              <div className="item-content">
                <p>{t(`faqs:subTopics.${topic}.${subTopic}`)}</p>
                <img src={chevronRightIcon} alt="chevron right" />
                {index < Object.keys(topicContent).length - 1 && <Divider />}
              </div>
            </ListItem>
          ))}
        </ListContainer>
      </MainContainer>
    </>
  );
};

export default FaqsTopic;
