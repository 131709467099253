import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";

const Callback3ds = () => {
  const [searchParams] = useSearchParams();
  const status = searchParams.get("status");
  const proofOfAuthentication = searchParams.get("proofOfAuthentication");
  const reason = searchParams.get("reason");
  const baseUrl = "managemii://3dsActivationResult?status=";

  useEffect(() => {
    if (status) {
      let url = `${baseUrl}${status}`;

      if (proofOfAuthentication) {
        url += `&proofOfAuthentication=${proofOfAuthentication}`;
      } else if (reason) {
        url += `&reason=${reason}`;
      }

      window.location.assign(url);
    }
  }, [status, proofOfAuthentication, reason]);

  return <div></div>;
};

export default Callback3ds;
