import styled from "styled-components";

import partnersImg from "../img/partners.webp";

export const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 480px;
  margin: auto;
  min-height: 100vh;
  background-color: #40494d;

  h1,
  h2 {
    color: #fff;
    font-family: Inter;
    text-align: left;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px;
  }

  p {
    color: #fff;
    font-feature-settings: "clig" off, "liga" off;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px; /* 150% */
    letter-spacing: 0.15px;
    margin: 0;
  }
`;

export const SectionContainer = styled.section`
  border-top: 1px solid #fff;

  .title-bar {
    display: flex;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 16px;

    img {
      cursor: pointer;
    }
  }
`;

export const NavBar = styled.nav`
  padding: 8px 16px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  img {
    width: 41px;
    height: 41px;
    flex-shrink: 0;
  }
`;

export const Welcome = styled.div`
  display: flex;
  padding: 16px;
  flex-direction: column;
  align-items: flex-start;
  border-top: 1px solid #fff;
`;

export const ListContainer = styled.ul`
  display: flex;
  flex-direction: column;
  list-style: none;
  gap: 2px;
  padding: 0;
  margin: 0;
  background-color: #000;

  .description {
    font-weight: 400;
    padding: 24px 16px 16px;
    letter-spacing: 0.5px;
  }
`;

export const DeviceItem = styled.li`
  padding: 16px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: #1c1c1c;
  gap: 8px;

  p.detail {
    font-weight: 400;
    letter-spacing: 0.5px;
  }
`;

export const ListItem = styled.li`
  padding: 16px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: #1c1c1c;
  gap: 8px;
  .text-container {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  p {
    letter-spacing: 0.5px;
  }
  p.subtitle {
    font-weight: 400;
  }
`;

export const EventPartners = styled.div`
  height: 132px;
  width: 100%;
  background: url(${partnersImg}) lightgray 0px / 100% no-repeat;
`;

export const Footer = styled.footer`
  border-top: 1px solid #000;

  display: flex;
  padding: 24px 16px 64px 16px;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  align-self: stretch;

  p {
    color: #fff;
    font-feature-settings: "clig" off, "liga" off;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 200% */
    letter-spacing: 0.4px;
  }

  .digiseq {
    width: 106px;
    height: 24px;
  }
  .globalP {
    width: 200px;
    height: 29.535px;
  }
`;

export const InfoAlert = styled.div`
  max-width: 480px;
  position: absolute;
  background: rgba(9, 10, 15, 0.64);
  height: 100%;
  width: 100%;
  text-align: center;

  .inner-container {
    position: fixed;
    max-width: 450px;
    width: 90%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 0 0 24px;
    background-color: white;
    z-index: 9999;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .wrapper {
      display: flex;
      flex-direction: column;
      justify-content: center;
      gap: 16px;
      padding: 32px 16px;
    }

    h3 {
      color: #000;
      font-feature-settings: "clig" off, "liga" off;
      font-family: Inter;
      font-size: 28px;
      font-style: normal;
      font-weight: 800;
      line-height: 36px; /* 128.571% */
    }

    p {
      color: #000;
      font-feature-settings: "clig" off, "liga" off;
      font-family: Inter;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 25px; /* 156.25% */
      margin-bottom: 48px;
    }

    .close-button {
      padding: 16px;
      background-color: black;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      color: #fff;

      text-align: center;
      font-feature-settings: "clig" off, "liga" off;
      font-family: Inter;
      font-size: 15.951px;
      font-style: normal;
      font-weight: 700;
      line-height: 16.79px; /* 105.263% */
    }
  }
`;
