import React, { useState, useEffect } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import {
  getFeedbackForm,
  submitFeedbackForm
} from "../../features/caged-steel/cagedSteelSlice";
import { validateText } from "../../utils/utils";

import Navbar from "./Navbar";

import {
  Button,
  Footer,
  MainContainer,
  VotingSection,
  QuestionsContainer,
  Question,
  TextArea,
  ErrorText
} from "./styled/SharedStyles";
import { Banner } from "./styled/FeedbackForm";

import { StyledSpinner } from "../../components/styled/Spinner";

const FeedbackForm = () => {
  const [answers, setAnswers] = useState([]);

  const [freeformError, setFreeformError] = useState("");

  const [searchParams] = useSearchParams();
  const serialNumber = searchParams.get("serialNumber");
  const dispatch = useDispatch();

  const { feedbackForm, feedbackFormSubmited, isError, isLoading } =
    useSelector((state) => state.cagedSteel);

  useEffect(() => {
    if (!feedbackForm) {
      dispatch(getFeedbackForm(serialNumber));
    }
  }, [feedbackForm, dispatch, serialNumber]);

  function handleSubmit() {
    const [textarea] = answers.filter((e) =>
      e?.hasOwnProperty("freeformAnswer")
    );

    if (!textarea?.freeformAnswer) {
      dispatch(
        submitFeedbackForm({
          serialNumber,
          answers
        })
      );
    }

    if (textarea?.freeformAnswer && validateText(textarea?.freeformAnswer)) {
      dispatch(
        submitFeedbackForm({
          serialNumber,
          answers
        })
      );
    } else setFreeformError("Please don't use special characters");
  }

  if (isError)
    return (
      <MainContainer>
        <Navbar />
        <ErrorText>
          We are experiencing a problem. Try refreshing the page or come back
          later.
        </ErrorText>
        <Footer>
          Powered by
          <a
            href="https://www.digiseq.co.uk"
            target="_blank"
            rel="noopener noreferrer"
          >
            DIGISEQ
          </a>
        </Footer>
      </MainContainer>
    );

  if (isLoading)
    return (
      <MainContainer>
        <Navbar />
        <StyledSpinner />
        <Footer>
          Powered by
          <a
            href="https://www.digiseq.co.uk"
            target="_blank"
            rel="noopener noreferrer"
          >
            DIGISEQ
          </a>
        </Footer>
      </MainContainer>
    );

  return (
    <MainContainer>
      <Navbar />

      {feedbackFormSubmited ? (
        <Banner>
          <h1>THANK YOU!</h1>
          <p>
            We’ll notify all questionnaire participants of the BONUS PRIZE draw
            winner on 09/12/2022.
          </p>
        </Banner>
      ) : (
        <Banner>
          <h1>BONUS PRIZE</h1>
          <p>Fill in the questionnaire to be in with a chance to win!</p>
        </Banner>
      )}

      {!feedbackFormSubmited && (
        <VotingSection>
          {feedbackForm?.questions.map((e) => (
            <QuestionsContainer key={e.position}>
              <h2>{e.question}</h2>
              {e.answerType === "MULTIPLE_CHOICE" &&
                e.answerOptions.map((a, p) => (
                  <Question
                    selected={a === answers[e.position]?.selectedChoice}
                    onClick={() => {
                      const updatedAnswers = [...answers];
                      updatedAnswers[e.position] = {
                        position: e.position,
                        selectedChoice: a
                      };
                      setAnswers(updatedAnswers);
                    }}
                    key={p}
                  >
                    {a}
                  </Question>
                ))}
              {e.answerType === "FREEFORM" && (
                <>
                  <TextArea
                    maxLength={200}
                    value={answers[e.position]?.freeformAnswer || ""}
                    onChange={(ev) => {
                      setFreeformError("");
                      const updatedAnswers = [...answers];
                      updatedAnswers[e.position] = {
                        position: e.position,
                        freeformAnswer: ev.target.value
                      };

                      setAnswers(updatedAnswers);
                    }}
                  />
                  {freeformError && <ErrorText>{freeformError}</ErrorText>}
                </>
              )}
            </QuestionsContainer>
          ))}
          <Button
            disabled={answers.length === 0}
            onClick={() => handleSubmit()}
          >
            Submit
          </Button>
        </VotingSection>
      )}

      <Footer>
        Powered by
        <a
          href="https://www.digiseq.co.uk"
          target="_blank"
          rel="noopener noreferrer"
        >
          DIGISEQ
        </a>
      </Footer>
    </MainContainer>
  );
};

export default FeedbackForm;
