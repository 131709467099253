import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { animated, useSpring } from "react-spring";

import { fetchDeviceAction } from "../../features/tap2start/tap2StartSlice";
import usePrevious from "../../hooks/usePrevious";

import BinChecker from "./BinChecker";
import DeviceStatus from "./DeviceStatus";
import SmartexWearableReady from "./SmartexWearableReady";

import { Footer, MainContainer, VerticalDivider } from "./styles/SharedStyles";
import {
  ContentSkeletonContainer,
  DigiseqLogoContainer,
  ImageContainer,
  NavBar,
  ProductContainer,
  ProductImageSkeletonContainer,
  SkeletonContainer,
  SmartexLogoContainer,
} from "./styles/Tap2StartSmartex";

import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

import noImage from "../tap2start/img/no-image.svg";
import digiseqLogo from "./img/digiseq_logo.png";
import smartexLogo from "./img/smartex_logo.png";

const Tap2StartSmartex = () => {
  const [searchParams] = useSearchParams();
  const serialNumber = searchParams.get("serialNumber");
  const showAllOptions = searchParams.get("showAll");
  const skipCardCheck = searchParams.get("skipCardCheck");

  const [checkAnotherBin, setCheckAnotherBin] = useState(false);

  const fadeInImageProps = useSpring({
    opacity: 1,
    from: { opacity: 0 },
    config: { duration: 1000 }, // You can adjust the duration as needed
  });

  const dispatch = useDispatch();

  const { fetchingDevice, device } = useSelector((state) => state.tap2Start);

  const previousFetchingDevice = usePrevious(fetchingDevice);

  useEffect(() => {
    async function effect() {
      if (previousFetchingDevice && device && device?.redirectOnCompleteUrl) {
        window.location.replace(device?.redirectOnCompleteUrl);
      }
    }

    effect();
  }, [previousFetchingDevice, device]);

  useEffect(() => {
    if (serialNumber) dispatch(fetchDeviceAction(serialNumber));
  }, [dispatch, serialNumber]);

  if (fetchingDevice || device?.redirectOnCompleteUrl)
    return (
      <MainContainer>
        <ProductImageSkeletonContainer>
          <Skeleton width={160} height={160} />
        </ProductImageSkeletonContainer>
        <ContentSkeletonContainer>
          <Skeleton height={100} />
          <SkeletonContainer>
            <Skeleton height={24} />
          </SkeletonContainer>
          <SkeletonContainer>
            <Skeleton height={24} width={250} />
          </SkeletonContainer>
          <SkeletonContainer>
            <Skeleton height={24} width={300} />
          </SkeletonContainer>
        </ContentSkeletonContainer>
      </MainContainer>
    );

  return (
    <animated.div style={fadeInImageProps}>
      <MainContainer>
        <NavBar>
          <SmartexLogoContainer>
            <img
              src={smartexLogo}
              alt="smartex logo"
              onClick={() => window.open("https://smartex.com", "_blank")}
            />
          </SmartexLogoContainer>
          <DigiseqLogoContainer>
            <img
              src={digiseqLogo}
              alt="digiseq logo"
              onClick={() => window.open("https://www.digiseq.co.uk", "_blank")}
            />
          </DigiseqLogoContainer>
        </NavBar>
        <ProductContainer>
          <ImageContainer noImage={!device?.wearableImageURL}>
            <img src={device?.wearableImageURL || noImage} alt="product" />
          </ImageContainer>
        </ProductContainer>
        {(!device ||
          device?.status === "NOT_REGISTERED" ||
          checkAnotherBin) && (
          <BinChecker
            serialNumber={serialNumber}
            showAllOptions={showAllOptions}
            skipCardCheck={skipCardCheck}
          />
        )}
        {(device?.status === "REGISTERED" ||
          (device?.status === "PERSONALISED" && !checkAnotherBin)) && (
          <DeviceStatus
            serialNumber={serialNumber}
            status={device?.status}
            checkAnotherBin={() => setCheckAnotherBin(true)}
          />
        )}
        {device?.status === "ACTIVATED" && (
          <SmartexWearableReady serialNumber={serialNumber} />
        )}
        <Footer>
          <a
            href={"https://www.manage-mii.co.uk/"}
            target="_blank"
            rel="noopener noreferrer"
          >
            Manage-Mii
          </a>
          <a
            href={"https://www.digiseq.co.uk/"}
            target="_blank"
            rel="noopener noreferrer"
          >
            DIGISEQ
          </a>
          <a
            href={
              "https://s3.eu-west-2.amazonaws.com/www.digiseq.co.uk/ManageMiiTerms.html/"
            }
            target="_blank"
            rel="noopener noreferrer"
          >
            Terms and conditions
          </a>
        </Footer>
      </MainContainer>
    </animated.div>
  );
};

export default Tap2StartSmartex;
