import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { composeErrorMessage } from "../../utils/utils";
import businessCardService from "./businessCardService";

const initialState = {
  businessCard: undefined,
  errorGettingBusinessCard: false,
  isGettingBusinessCard: false,
  errorMessageGettingBusinessCard: ""
};

export const getBusinessCardAction = createAsyncThunk(
  "businessCard/getBusinessCard",
  async (serial, thunkAPI) => {
    try {
      return await businessCardService.getBusinessCard(serial);
    } catch (error) {
      return thunkAPI.rejectWithValue(composeErrorMessage(error));
    }
  }
);

export const businessCardSlice = createSlice({
  name: "businessCard",
  initialState,
  reducers: {
    reset: () => initialState,
    resetError: (state) => {
      state.errorGettingBusinessCard = null;
    },
    replaceBusinessCard: (state, action) => {
      state.businessCard = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getBusinessCardAction.pending, (state) => {
        state.isGettingBusinessCard = true;
        state.errorGettingBusinessCard = false;
      })
      .addCase(getBusinessCardAction.fulfilled, (state, action) => {
        state.isGettingBusinessCard = false;
        state.businessCard = action.payload;
      })
      .addCase(getBusinessCardAction.rejected, (state, action) => {
        state.isGettingBusinessCard = false;
        state.errorGettingBusinessCard = true;
        state.errorMessageGettingBusinessCard = action.payload;
      });
  }
});

export const { reset, resetError, replaceBusinessCard } =
  businessCardSlice.actions;
export default businessCardSlice.reducer;
