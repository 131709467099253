import axios from "axios";
import { Services } from "mp-common-js";
import { isActiveJwt } from "./utils/cryptography";
import { store } from "./store";
import { logout } from "./features/auth/authSlice";

const httpClient = axios;

axios.defaults.headers.common["Accept"] = "application/json";
axios.defaults.headers.post["Content-Type"] = "application/json";

async function refreshAccessToken() {
  const storedTokenDetails = localStorage.getItem("token");

  if (storedTokenDetails) {
    const tokenDetails = JSON.parse(storedTokenDetails);

    return Services.SecurityService.refreshToken(
      tokenDetails.refresh_token
    ).then(async (res) => {
      localStorage.setItem("token", JSON.stringify(res.data));
      return res.data.access_token;
    });
  }
}

const axiosOnSuccessRequestInterceptor = async (config) => {
  const storedTokenDetails = localStorage.getItem("token");

  if (storedTokenDetails) {
    const tokenDetails = JSON.parse(storedTokenDetails);

    if (
      isActiveJwt(tokenDetails.access_token) &&
      config.url !== undefined &&
      !config.url.includes("s3.eu-west-2.amazonaws.com") &&
      !config.url.includes("discover")
    )
      config.headers.Authorization = `Bearer ${tokenDetails.access_token}`;
  }

  return config;
};

export const axiosOnErrorRequestInterceptor = (error) => Promise.reject(error);

export const axiosOnSuccessResponseInterceptor = (response) => {
  return response;
};

let isRefreshing = false;
let failedQueue;

const processQueue = (error, token = null) => {
  if (failedQueue !== undefined)
    failedQueue.forEach((prom) => {
      if (error) {
        prom.reject(error);
      } else {
        prom.resolve(token);
      }
    });

  failedQueue = [];
};

const axiosOnErrorResponseInterceptor = async (error) => {
  const originalRequest = error.config;

  if (error.response.status === 403) {
    store.dispatch(logout());
  }

  if (error.response.status === 401 && !originalRequest._retry) {
    if (isRefreshing) {
      return new Promise(function (resolve, reject) {
        failedQueue.push({ resolve, reject });
      })
        .then((token) => {
          originalRequest.headers["Authorization"] = "Bearer " + token;
          return axios(originalRequest);
        })
        .catch((err) => {
          return Promise.reject(err);
        });
    }

    originalRequest._retry = true;
    isRefreshing = true;

    return new Promise(function (resolve, reject) {
      refreshAccessToken()
        .then(({ access_token }) => {
          axios.defaults.headers.common["Authorization"] =
            "Bearer " + access_token;
          originalRequest.headers["Authorization"] = "Bearer " + access_token;
          processQueue(null, access_token);
          resolve(axios(originalRequest));
        })
        .catch((err) => {
          processQueue(error, null);
          reject(error);
          store.dispatch(logout());
        })
        .finally(() => {
          isRefreshing = false;
        });
    });
  }

  return Promise.reject(error);
};

// Interceptors
axios.interceptors.request.use(
  axiosOnSuccessRequestInterceptor,
  axiosOnErrorRequestInterceptor
);

axios.interceptors.response.use(
  axiosOnSuccessResponseInterceptor,
  axiosOnErrorResponseInterceptor
);

export default httpClient;
