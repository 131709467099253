import React from "react";
import { useNavigate } from "react-router-dom";
import { Back, Nav, NavTitle } from "../styled/SharedStyles";

import arrowBack from "../img/arrow-back.svg";
import { useDispatch } from "react-redux";
import { logout } from "../../../features/auth/authSlice";
import { resetError } from "../../../features/campaign-manager/campaignManagerSlice";
import { resetError as resetDevicesError } from "../../../features/das-device-manager/dasDeviceManagerSlice";

const NavBarComponent = ({ leftText, title, hasBackArrow, to }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  function handleLogout() {
    dispatch(logout());
  }

  return (
    <Nav>
      <Back
        onClick={() => {
          dispatch(resetError());
          dispatch(resetDevicesError());
          navigate(to || -1);
        }}
      >
        {hasBackArrow && <img src={arrowBack} alt="back" />}
        <span>{leftText}</span>
      </Back>
      <NavTitle>{title}</NavTitle>
      <div style={{ textAlign: "end" }} onClick={() => handleLogout()}>
        Logout
      </div>
    </Nav>
  );
};

export default NavBarComponent;
