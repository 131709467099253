import React from "react";
import { MainContainer, Option } from "./styled/DropdownSelect";

const DropdownSelect = ({ listItems, onSelect }) => {
  if (!listItems || listItems.length === 0)
    return (
      <MainContainer>
        <Option disabled>No matches</Option>
      </MainContainer>
    );
  return (
    <MainContainer>
      {listItems
        ?.sort((a, b) => a.name.localeCompare(b.name))
        .map((e, i) => (
          <Option
            key={i}
            isLast={i === listItems?.length - 1}
            onClick={() => onSelect(e)}
          >
            {e.name}
          </Option>
        ))}
    </MainContainer>
  );
};

export default DropdownSelect;
