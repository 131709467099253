import styled from "styled-components";

export const ListContainer = styled.div`
  background-color: white;
  padding: 16px 0 16px 16px;
  margin: 32px 16px;
  border-radius: 16px;
`;

export const SectionContainer = styled.div`
  padding: 32px 16px;
`;

export const SearchBar = styled.input`
  border: none;
  height: 30px;
  width: 100%;
  font-size: 1.063rem;
  color: #797b80;

  &:focus {
    outline: none;
  }
`;

export const ListItem = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 55px;
  padding: 8px 16px;
  border-bottom: ${({ isLast }) => (!isLast ? "2px solid #f7f8fa" : "none")};

  img {
    cursor: pointer;
    width: 30px;
    height: 30px;
  }
  .details {
    .email {
      font-size: 14px;
      font-weight: bold;
    }
  }

  .tags {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 16px;
  }
`;
