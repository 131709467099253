import React, { useEffect, useState } from "react";
import { ErrorMsg, MainContainer } from "./styled/SharedStyles";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
  getCampaign,
  resetError
} from "../../features/campaign-manager/campaignManagerSlice";

import Spinner from "../../components/Spinner";
import NavBarComponent from "./components/NavBar";
import ProtectedRoute from "../../components/ProtectedRoute";

import { ListContainer, ListItem, SearchBar } from "./styled/CoachTracker";

import goingIcon from "./img/going.svg";
import returningIcon from "./img/returning.svg";
import downloadIcon from "./img/download.svg";
import { convertArrayToCSV, downloadCSV } from "../../utils/utils";
import { getDasDevicesByCampaign } from "../../features/das-device-manager/dasDeviceManagerSlice";
import CoachTrackerProtectedRoute from "../../components/CoachTrackerProtectedRoute";

const CoachTrackerCampaign = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const { campaign, isLoading, isError, message } = useSelector(
    (state) => state.campaigns
  );

  const {
    devices,
    isLoading: isLoadingDevices,
    isError: isErrorDevices,
    message: messageDevices
  } = useSelector((state) => state.dasDevices);

  const [searchQuery, setSearchQuery] = useState("");

  useEffect(() => {
    if (!campaign) {
      dispatch(getCampaign(params.campaignId));
      dispatch(getDasDevicesByCampaign(params.campaignId));
    }
  }, [campaign, dispatch, params.campaignId]);

  useEffect(() => {
    return () => {
      dispatch(resetError());
    };
  }, [dispatch]);

  const handleSearch = (e) => {
    setSearchQuery(e.target.value);
  };

  const filterDevices = (device) => {
    const query = searchQuery.toLowerCase();
    const { firstName, lastName, email } = device.personalDetails || {};
    const serialNumber = device.serialNumber || "";
    return (
      (firstName && firstName.toLowerCase().includes(query)) ||
      (lastName && lastName.toLowerCase().includes(query)) ||
      (email && email.toLowerCase().includes(query)) ||
      serialNumber.toLowerCase().includes(query)
    );
  };

  const handleExport = (data, filename) => {
    const csvContent = convertArrayToCSV(data);
    downloadCSV(csvContent, filename);
  };

  return (
    <MainContainer>
      <CoachTrackerProtectedRoute />
      <NavBarComponent hasBackArrow={true} title={"Coach Tracker"} />

      {devices?.devices.length > 0 && !isLoadingDevices && (
        <>
          <ListContainer>
            <ListItem isLast>
              <p>{devices?.devices.length} passenger(s) found</p>
              <img
                src={downloadIcon}
                alt="download"
                onClick={() =>
                  handleExport(
                    devices?.devices.map((device) => ({
                      serialNumber: device.serialNumber || "",
                      firstName: device.personalDetails.firstName || "",
                      lastName: device.personalDetails.lastName || "",
                      outgoingCoach: device.coachTracker.outgoingCoach || "",
                      returningCoach: device.coachTracker.returningCoach || ""
                    })),
                    "passengers.csv"
                  )
                }
              />
            </ListItem>
          </ListContainer>

          <ListContainer>
            <SearchBar
              type="text"
              placeholder="Search passenger by name, email or id"
              value={searchQuery}
              onChange={handleSearch}
            />
          </ListContainer>
        </>
      )}

      {searchQuery &&
        devices?.devices.filter((c) => filterDevices(c)).length > 0 && (
          <ListContainer>
            {devices?.devices
              .filter((c) => filterDevices(c))
              .map((device, i, arr) => (
                <ListItem key={i} isLast={i === arr.length - 1}>
                  <div className="details">
                    <div>{`${device.personalDetails?.firstName} ${device?.personalDetails?.lastName}`}</div>

                    <div className="email">{device.personalDetails?.email}</div>
                  </div>
                  <div>
                    <div className="coach">
                      <img src={goingIcon} alt="right" />
                      {device.coachTracker.outgoingCoach}
                    </div>
                    <div className="coach">
                      <img src={returningIcon} alt="left" />
                      {device.coachTracker.returningCoach}
                    </div>
                  </div>
                </ListItem>
              ))}
          </ListContainer>
        )}

      {searchQuery &&
        devices?.devices.filter((c) => filterDevices(c)).length === 0 && (
          <ListContainer>
            <p>Can't find any passenger with this search query</p>
          </ListContainer>
        )}

      {isLoading && <Spinner />}

      {(isErrorDevices || isError) && (
        <ErrorMsg>
          {message ||
            messageDevices ||
            "Oh no, we have an error, please refresh page or try again later"}
        </ErrorMsg>
      )}
    </MainContainer>
  );
};

export default CoachTrackerCampaign;
