import { Link } from "react-router-dom";
import styled from "styled-components";

export const StyledLogo = styled(Link)`
  font-family: "acumin-pro-extra-condensed";
  font-style: normal;
  font-weight: 600;
  font-size: ${({ isfooter }) => (isfooter ? "3.75rem" : "1.375rem")};
  line-height: 1.25rem;
  color: ${({ isfooter }) => (isfooter ? "#0000AC" : "#1d1d1f")};
`;
