import styled, { css } from "styled-components";
import forestImage from "./assets/forest-image.webp";

const colors = {
  hotPink: "#FF2980",
  orange: "#FF8000",
  midnightBlue: "#1b1e64",
  whisperWhite: "#f4f7f9",
  paleSky: "#f9fafc",
  midBlue: "#009be7",
  white: "#ffffff",
  button: "rgb(27, 30, 100)",
  red: "#FF0000"
};

const textStyles = css`
  font-family: "Inter", sans-serif;
  font-style: normal;
  text-align: left;
  letter-spacing: 0.5px;
`;

const headerStyles = css`
  ${textStyles}
  font-weight: 700;
  color: ${colors.white};
`;
const bodyStyles = css`
  ${textStyles}
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  color: ${colors.midnightBlue};
`;

const Container = styled.div`
  font-family: Arial, sans-serif;
  max-width: 375px;
  margin: 16px auto;
  background-color: ${colors.white};

  h1,
  h2 {
    ${headerStyles}
  }

  h1 {
    font-size: 40px;
    line-height: 48px;
  }

  h2 {
    font-size: 28px;
    line-height: 36px;
  }
  p {
    ${bodyStyles}
  }
`;

const Header = styled.header`
  background-image: url(${forestImage});
  background-size: cover;
  color: white;
  padding: 40px 16px 20px;
  text-align: center;
  margin: 0 8px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  gap: 32px;
`;

const Title = styled.h1`
  font-size: 28px;
`;

const CarbonFootprintCard = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 24px;
  gap: 8px;
  background: rgba(255, 255, 255, 0.08);
  border: 1px solid rgba(255, 255, 255, 0.2);
  backdrop-filter: blur(15px);
  border-radius: 8px;

  p {
    text-align: left;
    color: ${colors.whisperWhite};
  }

  a {
    color: ${colors.whisperWhite};
    text-decoration: underline;
    text-align: left;
  }
`;

const PoweredBy = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;

  p {
    color: ${colors.whisperWhite};
    font-size: 12px;
    font-weight: 500;
    line-height: 14.5px;
  }

  .powered-by-logos {
    display: flex;
    gap: 36px;
  }

  img {
    height: 28px;
  }
`;

const Main = styled.main`
  padding: 32px 8px 8px;
`;

const FAQSection = styled.section`
  margin-bottom: 20px;
  background-color: ${colors.whisperWhite};
  border-radius: 8px;
  padding: 24px;
  gap: 20px;
`;

const FAQItem = styled.div`
  width: 100%;
  flex-direction: column;
  padding: 15px;
  margin-bottom: 10px;
  border-radius: 5px;
  gap: 16px;
  display: flex;
  align-items: center;

  .faq-item-header img {
    cursor: pointer;
  }

  .faq-item-header {
    width: 100%;
    display: flex;
    flex-direction: row;
    gap: 16px;
    justify-content: space-between;
    align-items: center;
  }
`;

const Button = styled.button`
  background-color: ${({ disabled }) => (disabled ? "#ccc" : colors.white)};
  color: ${({ disabled }) => (disabled ? "#666" : colors.midnightBlue)};
  display: flex;
  font-weight: 600;
  font-size: 16px;
  justify-content: center;
  align-items: center;
  padding: 12px 18px;
  border-radius: 6px;
  width: 100%;
  border: none;
  margin: 16px auto;
`;

const ErrorContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 24px 0;

  p {
    color: ${colors.red};
    text-align: center;
  }
`;

export {
  Container,
  Header,
  Title,
  CarbonFootprintCard,
  PoweredBy,
  Main,
  FAQSection,
  FAQItem,
  Button,
  ErrorContainer
};
