import styled from "styled-components";

export const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 480px;
  margin: auto;
  min-height: 100vh;

  .title {
    font-family: Inter;
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: 40px;
    margin-bottom: 16px;
  }
  .subtitle {
    font-family: Inter;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 40px; /* 166.667% */
    margin-bottom: 8px;
  }
`;

export const Separator = styled.div`
  border-bottom: ${({ isDark }) =>
    isDark ? "1px solid #202020" : "1px solid #f5f6f8"}; // dark 8695aa
`;

export const PaymentAccount = styled.section`
  padding: 32px 24px;
  background-color: #fff;
  align-items: flex-start;
  gap: 16px;

  .inner {
    display: flex;
    gap: 8px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
  }

  .button {
    color: white;
    cursor: pointer;
    display: inline-flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 8px 8px 8px 16px;
    background: #000;
    border-radius: 360px;
    margin: 16px 0;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 150% */
    letter-spacing: 0.5px;
  }

  p {
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 150% */
    letter-spacing: 0.5px;
    color: #000;
  }
`;

export const Logos = styled.div`
  padding: 24px 0;
  background-color: #db3c8e;
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: center;

  p {
    color: #fff;
    text-align: center;
    font-feature-settings: "clig" off, "liga" off;
    font-family: "Noka";
    font-size: 32px;
    font-style: normal;
    font-weight: 800;
    line-height: 40px; /* 125% */
  }
`;

export const NextStepsContainer = styled.div`
  margin-top: -1px;
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 16px;
  background-color: white;
`;

export const StepContainer = styled.div`
  padding: 32px 16px;
  h2 {
    margin-top: 16px;
    font-family: Inter;
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: 40px;
  }
  p {
    margin-top: 16px;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 150% */
    letter-spacing: 0.5px;
    columns: #626262;
  }
  .pay-anywhere {
    margin: 24px auto;
    width: 100%;
    object-fit: contain;
  }
`;

export const CurvelogoContainer = styled.div`
  margin-bottom: 16px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin-left: 16px;

  .separator {
    height: 25px;
    object-fit: cover;
    width: 100%;
  }

  .curve-logo {
    height: 32px;
    width: 152px;
  }
  .flags {
    display: flex;
    gap: 8px;
    img {
      height: 26px;
    }
  }
`;

export const StepNumber = styled.div`
  display: flex;
  width: 40px;
  padding: 8px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 360px;
  background: #f6f6f7;
`;

export const AppStoreLinks = styled.section`
  img {
    cursor: pointer;
    margin-top: 24px;
    margin-right: 16px;
  }
`;

export const Footer = styled.footer`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 24px 16px;
  gap: 16px;
  p {
    color: black;
    text-align: center;
    font-family: "Inter";
    font-size: 16px;
    font-style: normal;
    font-weight: 300;
    line-height: 24px; /* 150% */
    letter-spacing: 0.5px;
  }
  img {
    width: 35%;
    height: auto;
    object-fit: contain;
  }
  .copyToClipboard {
    display: flex;
    flex-direction: row;
    gap: 8px;
    align-items: center;

    img {
      width: 24px;
      height: 24px;
      cursor: pointer;
    }
  }
`;
