import styled from "styled-components";

export const ManOfTheMatchContainer = styled.section`
  display: flex;
  flex-direction: column;
  width: 100%;

  h2,
  h4 {
    margin: 24px;
  }
`;
export const ManOfTheMatchImageContainer = styled.div`
  img {
    aspect-ratio: 3/2;
    object-fit: cover;
    width: 100%;
    max-width: 100%;
    max-height: 600px;
    margin-top: 70px;

    @media (max-width: 768px) {
      transform: scale(1.6);
      aspect-ratio: 9/2;
      max-height: 240px;
      object-position: 0 -32px;
      margin: 70px 0;
    }
  }
`;

export const ShirtImageContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  img {
    object-fit: fill;
    cursor: pointer;
    height: 400px;
    margin: 24px 0 24px;
  }
`;
