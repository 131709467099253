import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";

import { getBalanceAndPin } from "../../features/balance-and-pin/balanceAndPinSlice";

import Spinner from "../../components/Spinner";
import { AppStoreLinks } from "../pepcity/styles/Pepcity";

import {
  BottomSection,
  Button,
  Explore,
  Footer,
  Hero,
  Highlights,
  Item,
  List,
  MainContainer,
  Navbar,
  Schedule,
  YourJourney
} from "./styles/TourDeFrance";

import heroImg from "./img/intro-bikes.webp";
import appStoreLogo from "./img/app-store.svg";
import gplayLogo from "./img/play-store.svg";
import bbLogo from "./img/bb-logo.svg";
import glassesImg from "./img/splash-glasses.webp";
import boatsImg from "./img/splash-boats.webp";
import umbrellasImg from "./img/splash-umbrellas.webp";
import bikesImg from "./img/splash-bikes.webp";
import fobsImg from "./img/fobs.webp";
import copyIcon from "./img/copy-icon.svg";
import footerLogos from "./img/logos-footer.webp";
import { formattedBalance } from "../../features/balance-and-pin/utils";

const TourDeFrance = () => {
  const [searchParams] = useSearchParams();
  const serialNumber = searchParams.get("serialNumber");

  const SCHEDULE = [
    { time: "14:00", ev: "Guest arrival" },
    { time: "14:40", ev: "First rider departs" },
    { time: "15:00", ev: "Lunch" },
    { time: "17:00", ev: "Athlete Live Q+A" },
    { time: "18:00", ev: "Dessert" },
    { time: "18:45", ev: "Last rider departs" },
    { time: "20:00", ev: "Event concludes" }
  ];

  const dispatch = useDispatch();
  const { balanceAndPin, isLoading } = useSelector(
    (state) => state.balanceAndPin
  );

  useEffect(() => {
    if (!balanceAndPin && serialNumber) {
      dispatch(
        getBalanceAndPin({
          serial: serialNumber,
          query: "?provider=pannovate"
        })
      );
    }
  }, [balanceAndPin, serialNumber, dispatch]);

  const appStoreLinks = (appStoreUrl, playStoreUrl) => {
    return (
      <AppStoreLinks>
        <img
          src={appStoreLogo}
          alt="app-store"
          onClick={() => window.open(appStoreUrl, "_blank")}
        />
        <img
          src={gplayLogo}
          alt="play-store"
          onClick={() => window.open(playStoreUrl, "_blank")}
        />
      </AppStoreLinks>
    );
  };

  if (isLoading) return <Spinner />;
  return (
    <MainContainer>
      <Navbar>
        <img src={bbLogo} alt="bb-logo" />
        <div className="balance">
          <p>{"CURRENT BALANCE"}</p>
          <p>
            {formattedBalance(
              balanceAndPin?.balanceDetails?.availableBalance,
              balanceAndPin?.balanceDetails?.currency
            )}
          </p>
        </div>
      </Navbar>
      <Hero>
        <div className="img-container">
          <img src={heroImg} alt="Tour de France" />
          <h1>{"THE SPIRIT OF TOUR DE FRANCE"}</h1>
        </div>

        <BottomSection height={116} margin={90} color="#C87B35" />
      </Hero>

      <Explore>
        <div className="inner">
          <p>
            {
              "It is our pleasure to introduce you today to Bourgeois Bohème - our financial and lifestyle service tailored specifically to the needs of high-net-worth and ultra-high-net worth individuals."
            }
          </p>
          <p>
            {
              "Scroll below to learn more about us and activate your payment device."
            }
          </p>
          <Button
            onClick={() =>
              window.open(`https://officesuite.bobofin.com/`, "_blank")
            }
          >
            {"EXPLORE MORE"}
          </Button>
          <Button
            secondary
            onClick={() =>
              window.open(
                `https://s3.eu-west-2.amazonaws.com/www.digiseq.co.uk/bobo-brochure-07-24.pdf`,
                "_blank"
              )
            }
          >
            {"BOBO BROCHURE"}
          </Button>
          <div className="img-container">
            <img src={glassesImg} alt="glasses" />
          </div>
        </div>
        <BottomSection height={196} color="#FFF" margin={210} />
      </Explore>

      <Highlights>
        <div className="text-container">
          <h2>{"VIP Hospitality highlights"}</h2>
          <p>
            {
              "An unforgettable event with BoBo, indulging in luxury at the fully privatized restaurant on the Hotel Miramar Rooftop in Monaco, just 150 meters from the Tour de France start line."
            }
          </p>
          <p>
            {
              "Enjoying exquisite 5-star Italian cuisine paired with a curated selection of champagne, cocktails, and fine wines while watching the race from the front row."
            }
          </p>
        </div>
        {/* <div className="images">
          <img src={boatsImg} alt="boats" />
          <img src={umbrellasImg} alt="umbrellas" />
        </div> */}
      </Highlights>
      <Schedule>
        <div className="header">
          <p>{"SCHEDULE OF THE DAY:"}</p>
        </div>
        <List>
          {SCHEDULE.map((entry, i) => (
            <Item key={entry.time} isLast={SCHEDULE.length === i + 1}>
              <p className="time">{entry.time}</p>
              <p>{entry.ev}</p>
            </Item>
          ))}
        </List>
        <BottomSection height={198} color={"#C87B35"} margin={150} />
      </Schedule>

      <YourJourney>
        <img src={bikesImg} alt="bikes" />
        <div className="intro">
          <img className="logo" src={bbLogo} alt="bb-logo" />
          <h2>{"JOURNEY WITH US CONTINUES"}</h2>
          <p>
            {
              "Elevate lifestyle with seamless payments and Bourgeois Bohème membership."
            }
          </p>
          <p>
            {
              "As part of your welcome package, we are gifting key fob payment devices to our guests preloaded with a 10 EUR balance. These devices offer a convenient and secure way to manage your day-to-day expenses."
            }
          </p>
        </div>
        <div className="connect">
          <h3>{"Connect your key fob to BoBo account"}</h3>
          <p>
            {
              "Download the BoBo app and choose a membership. You’ll be able to load money to your key fob from European and UK account. Contact support at support@bobofin.com or via chat in the app for questions."
            }
          </p>
          <img src={fobsImg} alt="fobs" />
        </div>
        <div className="activation">
          <p>{"Here is your activation code:"}</p>
          <div className="code">
            <span
              className="activation-code"
              onClick={() =>
                navigator.clipboard.writeText(balanceAndPin?.activationCode)
              }
            >
              {balanceAndPin?.activationCode || "N/A"}
            </span>
            <img className="copy" src={copyIcon} alt="copy-icon" />
          </div>
          {appStoreLinks(
            "https://apps.apple.com/us/app/bourgeois-boheme/id1574430730",
            "https://play.google.com/store/apps/details?id=com.paymeswiss.bobofin"
          )}
          <Button
            secondary
            onClick={() =>
              window.open(
                `https://bobo-website.webflow.io/become-a-member`,
                "_blank"
              )
            }
          >
            {"Join Bourgeois BohÈme"}
          </Button>
        </div>
      </YourJourney>
      <Footer>
        <p
          onClick={() =>
            window.open(
              "https://uploads-ssl.webflow.com/647fa0d6b958d955f59c628e/64807a2c0e353e906c3ca876_UEFA_BoBo_TC_v1.pdf",
              "_blank"
            )
          }
        >
          {"Terms and Conditions "}
        </p>
        <img src={footerLogos} alt="footer-logos" />
      </Footer>
    </MainContainer>
  );
};

export default TourDeFrance;
