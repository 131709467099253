import styled, { css } from "styled-components";

// Color variables
const colors = {
  black: "#000000",
  white: "#FFFFFF",
  gray: "#282827",
  mediumGray: "#3d3d3c",
  darkGray: "#141414",
  lightGray: "#c4c4c4",
  placeholderGray: "#444444",
  orange: "#f26522",
  bodyTextDark: "#ccc",
  red: "#ff0000"
};

// Common styles
const commonStyles = css`
  font-family: "Mark For MC Narrow W00 Light";
  font-style: normal;
`;

const headerStyles = css`
  ${commonStyles}
  font-size: 28px;
  line-height: 32px;
  font-weight: 400;
  color: ${colors.white};
  text-align: center;
  letter-spacing: 0px;
`;

const bodyStyles = css`
  ${commonStyles}
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  color: ${colors.white};
  text-align: center;
  letter-spacing: 0.5px;
`;

const flexCenter = css`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 480px;
  margin: auto;
  min-height: 100vh;
  background-color: #000000;
  gap: 16px;

  h1,
  h2 {
    ${headerStyles}
  }

  p {
    ${bodyStyles}
  }
`;

export const InnerContainer = styled.div`
  padding: 0 16px;
  width: 100%;
  ${flexCenter}
  flex-direction: column;
  gap: 16px;
`;

export const NavBar = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 16px;
  background-color: ${colors.gray};
  .logo {
    width: 118px;
  }
`;

export const LanguageSelector = styled.div`
  .language-select {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    cursor: pointer;
    align-items: center;
  }
  span {
    color: white;
  }
  img {
    margin-left: 8px;
    width: 16px;
    height: 16px;
  }

  .language-select-dropdown {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }
`;

export const WelcomeContainer = styled.div`
  ${flexCenter}
  flex-direction: column;
  background-color: ${colors.gray};
  width: 100%;
  border-radius: 6px;
  gap: 16px;
  padding: 24px 16px;

  h2 {
    font-size: 24px;
  }
`;

export const HeroContainer = styled.div`
  ${flexCenter}

  flex-direction: column;
  border-radius: 6px;
  background-color: ${colors.gray};
  padding: 24px 16px;
  gap: 16px;
  width: 100%;
  img {
    max-width: 180px;
  }

  p {
    color: ${colors.bodyTextDark};
    padding: 0 24px;
  }
`;

export const FeaturesContainer = styled.div`
  ${flexCenter}
  flex-direction: column;
  border-radius: 6px;
  background-color: ${colors.gray};
  padding: 24px 16px;
  gap: 24px;
  width: 100%;

  .features-grid {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 16px;
  }
`;

export const FeatureItem = styled.div`
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  ${flexCenter}
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  background-color: ${colors.mediumGray};
  padding: 16px 8px;
  min-height: 130px;
  text-align: center;
  cursor: pointer;
  p {
    padding: 0 8px;
  }
`;

export const TeamCodeContainer = styled.div`
  position: relative;
  ${flexCenter}
  flex-direction: column;
  border-radius: 6px;
  background-color: ${colors.gray};
  padding: 24px 16px;
  margin-bottom: 8px;
  gap: 24px;
  width: 100%;
  .chevron {
    position: absolute;
    right: 16px;
    top: 32px;
    width: 40px;
    height: 40px;
  }
  .code-container {
    ${flexCenter}

    width: 100%;
    justify-content: space-between;
    align-items: center;
    border-radius: 4px;
    background-color: ${colors.darkGray};
    padding: 12px 16px;
    .code-text {
      font-size: 24px;
      color: ${colors.orange};
    }
    img {
      cursor: pointer;
    }
  }
`;

export const AfterEvent = styled.div`
  ${flexCenter}
  flex-direction: column;
  gap: 16px;
  background-color: ${colors.gray};
  padding: 24px 16px;
  border-radius: 6px;
  p {
    color: ${colors.bodyTextDark};
  }
  .button {
    min-height: 40px;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
    cursor: pointer;
    background-color: ${colors.orange};
    color: ${colors.white};
    padding: 8px 16px 8px 24px;
    border-radius: 360px;
  }
`;

export const Footer = styled.div`
  ${flexCenter}
  flex-direction: column;
  background-color: ${colors.gray};
  padding: 24px 16px 48px;
  gap: 8px;
  width: 100%;
  p {
    font-size: 12px;
  }
  img {
    cursor: pointer;
    max-width: 106px;
  }

  .copyToClipboard {
    margin-top: 8px;
    cursor: pointer;
  }
`;

export const ScheduleList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;

  .london-events {
    display: flex;
    flex-direction: column;
    gap: 4px;
    p {
      color: ${colors.orange};
    }
  }
`;

export const ScheduleItem = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
  .time {
    color: ${colors.orange};
    min-width: 12%;
  }
  p {
    font-size: 16px;
    color: ${colors.bodyTextDark};
  }
`;

export const KeyFobDesigns = styled.div`
  img {
    width: 100%;
  }
`;

export const Button = styled.button`
  margin-top: 16px;
  ${({ fullWidth }) =>
    fullWidth &&
    css`
      width: 100%;
    `};
  background-color: ${colors.orange};
  color: ${colors.white};
  min-height: 40px;
  border: none;
  padding: 8px 16px;
  border-radius: 360px;
  cursor: pointer;
  align-self: center;
  font-size: 16px;
`;

export const GameItem = styled.div`
  position: relative;
  display: flex;
  padding: 8px 24px;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 4px;
  background-color: ${colors.mediumGray};

  img {
    position: absolute;
    right: 12px;
    width: 24px;
    height: 24px;
  }
  p {
    color: ${colors.bodyTextDark};
    text-transform: uppercase;
  }
`;

export const BountyGame = styled.div`
  background-color: ${colors.mediumGray};
  flex-direction: column;
  padding: 8px 24px;
  border-radius: 4px;
  width: 100%;
  display: flex;
  p {
    color: ${colors.bodyTextDark};
  }
  gap: 8px;

  .title {
    text-transform: uppercase;
  }
`;

export const ChampionInputContainer = styled.div`
  .champion-name {
    color: ${colors.white};
    padding: 8px 16px;
  }
  background-color: ${colors.darkGray};
  padding: 0px 16px 0px 0px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-radius: 4px;
  gap: 8px;
  border: ${({ error, guessed }) =>
    guessed
      ? "none"
      : !error
      ? `1px solid ${colors.orange}`
      : `1px solid ${colors.orange}`};

  p {
    color: ${({ guessed }) => (guessed ? `${colors.white}` : `${colors.red}`)};
  }
  img {
    width: 24px;
    height: 24px;
  }

  input {
    ${bodyStyles}
    text-align: left;
    background-color: ${colors.darkGray};
    border: none;
    padding: 8px 16px;
    border-radius: 4px;
    color: ${colors.white};
    width: 60%;

    &:focus {
      outline: none;
    }

    &::placeholder {
      color: ${colors.placeholderGray};
    }
  }
  .button {
    color: ${colors.orange};
    cursor: pointer;
  }
  .disabled {
    color: ${colors.mediumGray};
    cursor: not-allowed;
  }
`;

export const InfoAlert = styled.div`
  max-width: 480px;
  position: absolute;
  background: rgba(9, 10, 15, 0.7);
  height: 100%;
  width: 100%;
  text-align: center;

  .text-left {
    text-align: left;
  }

  .inner-container {
    position: fixed;
    overflow-y: auto;
    max-height: 80vh;
    max-width: 360px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 24px 16px;
    background-color: ${colors.gray};
    border-radius: 6px;
    width: 90%;
    z-index: 9999;
    display: flex;
    flex-direction: column;
    gap: 16px;
    h2 {
      font-size: 24px;
      max-width: 80%;
      margin: 0 auto;
    }
  }

  .close-button {
    position: absolute;
    top: 16px;
    right: 16px;
    cursor: pointer;
    width: 24px;
    height: 24px;
    z-index: 9999;
    background-color: #d0d0d0;
    border-radius: 50%;
    padding: 4px;
  }
`;

export const PersonalDetailsForm = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  max-width: 360px;
  transform: translate(-50%, -50%);
  padding: 24px 16px 64px;
  background-color: ${colors.gray};
  border-radius: 6px;
  width: 90%;
  z-index: 9999;
  display: flex;
  flex-direction: column;
  gap: 24px;
  h2 {
    font-size: 24px;
  }
  input {
    padding: 8px 16px;
    border-radius: 4px;
    border: 1px solid ${colors.lightGray};
    background: ${colors.darkGray};
    color: ${colors.white};
    font-size: 16px;

    &.error {
      border: 1px solid ${colors.red};
    }
    &.error::placeholder {
      color: ${colors.red};
    }
    &.error:focus {
      border: 1px solid ${colors.red};
    }
    &:focus {
      outline: none;
    }
  }
  button {
    align-self: center;
  }
  .form-group {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
  .error {
    color: ${colors.red};
  }
  .spinner-container {
    ${flexCenter}
    height: 50px;
    width: 100%;
    padding: 16px;
  }
`;
