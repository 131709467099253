import React, { useEffect, useState } from "react";
import { ErrorMsg, MainContainer } from "./styled/SharedStyles";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
  getCampaign,
  resetError
} from "../../features/campaign-manager/campaignManagerSlice";
import {
  getContestantsAction,
  resetAllDevicesAction,
  resetDeviceAction
} from "../../features/campaign-manager/scavengerHuntSlice";
import Spinner from "../../components/Spinner";
import NavBarComponent from "./components/NavBar";
import ProtectedRoute from "../../components/ProtectedRoute";
import Alert from "./components/AlertConfirmation";

import { ListContainer, ListItem, SearchBar } from "./styled/ScavengerHunt";

import resetIcon from "./img/reset.png";
import chevronRight from "./img/chevron-right.svg";
import downloadIcon from "./img/download.svg";
import { convertArrayToCSV, downloadCSV } from "../../utils/utils";

const ScavengerHunt = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const { campaign, isLoading, isError, message } = useSelector(
    (state) => state.campaigns
  );

  const [alert, setAlert] = useState(null);

  const {
    gettingContestants,
    errorGettingContestants,
    contestants,
    resettingDevice,
    errorResettingDevice,
    resettingAllDevices,
    errorResettingAllDevices
  } = useSelector((state) => state.scavengerHunt);

  const [searchQuery, setSearchQuery] = useState("");

  useEffect(() => {
    if (!campaign) {
      dispatch(getCampaign(params.campaignId));
    }
  }, [campaign, dispatch, params.campaignId]);

  useEffect(() => {
    return () => {
      dispatch(resetError());
    };
  }, [dispatch]);

  useEffect(() => {
    if (!contestants) {
      dispatch(getContestantsAction());
    }
  }, [contestants, dispatch]);

  const handleSearch = (e) => {
    setSearchQuery(e.target.value);
  };

  const filterContestants = (contestant) => {
    const query = searchQuery.toLowerCase();
    const { firstName, lastName, email } = contestant.personalDetails || {};
    const serialNumber = contestant.serialNumber || "";
    return (
      (firstName && firstName.toLowerCase().includes(query)) ||
      (lastName && lastName.toLowerCase().includes(query)) ||
      (email && email.toLowerCase().includes(query)) ||
      serialNumber.toLowerCase().includes(query)
    );
  };

  const handleExport = (data, filename) => {
    const csvContent = convertArrayToCSV(data);
    downloadCSV(csvContent, filename);
  };

  return (
    <MainContainer>
      <ProtectedRoute />
      <NavBarComponent hasBackArrow={true} />
      {alert === "RESET_ALL" && (
        <Alert
          title={"This will RESET all players. Are you sure?"}
          onConfirm={() => {
            dispatch(resetAllDevicesAction());
            setAlert(null);
          }}
          onCancel={() => setAlert(null)}
        />
      )}
      {alert?.serialNumber && (
        <Alert
          title={"The following player will reset. Are you sure?"}
          message={`${alert.playerName} | ${alert.email}`}
          onConfirm={() => {
            dispatch(resetDeviceAction(alert.serialNumber));
            setAlert(null);
          }}
          onCancel={() => setAlert(null)}
        />
      )}
      <ListContainer>
        <ListItem>
          <div>Reset All Players</div>
          <img
            src={resetIcon}
            alt="reset"
            onClick={() => setAlert("RESET_ALL")}
          />
        </ListItem>
        <ListItem
          isLast
          onClick={() =>
            navigate(
              `/campaign-manager/${params.campaignId}/scavenger-hunt/deliver-prizes`
            )
          }
        >
          <div>Deliver Prizes</div>
          <img src={chevronRight} alt="deliver prizes" />
        </ListItem>
      </ListContainer>
      {contestants?.length > 0 && !gettingContestants && (
        <>
          <ListContainer>
            <ListItem>
              <p>{contestants.length} player(s) registered</p>
              <img
                src={downloadIcon}
                alt="download"
                onClick={() =>
                  handleExport(
                    contestants.map((c) => ({
                      serialNumber: c.serialNumber || "",
                      email: c.personalDetails.email || "",
                      firstName: c.personalDetails.firstName || "",
                      lastName: c.personalDetails.lastName || "",
                      mobileNumber: c.personalDetails.phoneNumber || "",
                      tagsCollected: Object.keys(
                        c.mobileScavengerHunt?.collectedTags
                      ).length,
                      prizeSent: c.mobileScavengerHunt.prizeDetails?.prizeSent
                        ? "Yes"
                        : "No"
                    })),
                    "contestants.csv"
                  )
                }
              />
            </ListItem>
            <ListItem isLast>
              <p>
                {
                  contestants.filter(
                    (c) =>
                      c.mobileScavengerHunt?.prizeDetails?.prizeSent === true
                  ).length
                }{" "}
                prizes delivered
              </p>
              <img
                src={downloadIcon}
                alt="download"
                onClick={() =>
                  handleExport(
                    contestants
                      .filter(
                        (c) =>
                          c.mobileScavengerHunt?.prizeDetails?.prizeSent ===
                          true
                      )
                      .map((c) => ({
                        serialNumber: c.serialNumber || "",
                        email: c.personalDetails.email || "",
                        firstName: c.personalDetails.firstName || "",
                        lastName: c.personalDetails.lastName || "",
                        tagsCollected: Object.keys(
                          c.mobileScavengerHunt?.collectedTags
                        ).length,
                        prizeSent: c.mobileScavengerHunt.prizeDetails?.prizeSent
                          ? "Yes"
                          : "No"
                      })),
                    "contestants.csv"
                  )
                }
              />
            </ListItem>
          </ListContainer>

          <ListContainer>
            <SearchBar
              type="text"
              placeholder="Search player by name, email or id"
              value={searchQuery}
              onChange={handleSearch}
            />
          </ListContainer>
        </>
      )}

      {searchQuery &&
        contestants.filter((c) => filterContestants(c)).length > 0 && (
          <ListContainer>
            {contestants
              .filter((c) => filterContestants(c))
              .map((contestant, i, arr) => (
                <ListItem key={i} isLast={i === arr.length - 1}>
                  <div className="details">
                    <div>{`${contestant.personalDetails?.firstName} ${contestant?.personalDetails?.lastName}`}</div>

                    <div className="email">
                      {contestant.personalDetails?.email}
                    </div>
                  </div>
                  <div className="tags">
                    <div>
                      {
                        Object.keys(
                          contestant?.mobileScavengerHunt?.collectedTags
                        )?.length
                      }
                    </div>
                    <img
                      src={resetIcon}
                      alt="reset"
                      onClick={() =>
                        setAlert({
                          serialNumber: contestant.serialNumber,
                          playerName: `${contestant.personalDetails?.firstName} ${contestant?.personalDetails?.lastName}`,
                          email: contestant.personalDetails?.email
                        })
                      }
                    />
                  </div>
                </ListItem>
              ))}
          </ListContainer>
        )}

      {searchQuery &&
        contestants.filter((c) => filterContestants(c)).length === 0 && (
          <ListContainer>
            <p>Can't find any player with this search query</p>
          </ListContainer>
        )}

      {(isLoading ||
        gettingContestants ||
        resettingDevice ||
        resettingAllDevices) && <Spinner />}

      {(isError ||
        errorGettingContestants ||
        errorResettingDevice ||
        errorResettingAllDevices) && (
        <ErrorMsg>
          {message ||
            errorGettingContestants ||
            errorResettingDevice ||
            errorResettingAllDevices ||
            "Oh no, we have an error, please refresh page or try again later"}
        </ErrorMsg>
      )}
    </MainContainer>
  );
};

export default ScavengerHunt;
