import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import i18n from "i18next";

import {
  getBalanceAndPin,
  savePersonalDetailsAction
} from "../../features/balance-and-pin/balanceAndPinSlice";
import usePrevious from "../../hooks/usePrevious";
import { getDeviceInfo } from "../../features/hugo-boss/hugoBossSlice";
import { formattedBalance } from "../../features/balance-and-pin/utils";

import Spinner from "../../components/Spinner";
import { AppStoreLinks } from "../pepcity/styles/Pepcity";
import LanguageSelect from "./LanguageSelect";
import {
  Alert,
  BottomSection,
  Button,
  Explore,
  Footer,
  Hero,
  Item,
  ItemHeader,
  List,
  MainContainer,
  Navbar,
  Schedule,
  YourJourney
} from "./styles/ArosaClassicCar";

import heroImg from "./img/hero.png";
import appStoreLogo from "./img/app-store.svg";
import gplayLogo from "./img/play-store.svg";
import bbLogo from "./img/bb-logo.svg";
import journeyImg from "./img/journey.png";
import fobArosa from "./img/fob-arosa.png";
import fobBobo from "./img/fob-bobo.png";
import copyIcon from "./img/copy-icon.svg";
import footerLogos from "./img/logos-footer.webp";
import instaIcon from "./img/instagram.svg";
import inIcon from "./img/linkedin.svg";
import shopIcon from "./img/Shop.svg";
import checkedIcon from "./img/checked.svg";
import uncheckedIcon from "./img/unchecked.svg";

const ArosaClassicCar = () => {
  const [searchParams] = useSearchParams();
  const serialNumber = searchParams.get("serialNumber");
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [showForm, setShowForm] = useState(false);
  const [acceptTerms, setAcceptTerms] = useState(false);
  const [fullName, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [errors, setErrors] = useState({});

  const {
    balanceAndPin,
    isLoading,
    savingPersonalDetails,
    errorSavingPersonalDetails
  } = useSelector((state) => state.balanceAndPin);

  const { device, gettingDeviceInfo, gettingDeviceInfoError } = useSelector(
    (state) => state.hugoBoss
  );

  const prevGettingDeviceInfo = usePrevious(gettingDeviceInfo);

  useEffect(() => {
    if (prevGettingDeviceInfo && !gettingDeviceInfoError) {
      if (device?.personalDetails?.fullName && device?.personalDetails?.email) {
        setFullName(device?.personalDetails?.fullName);
        setEmail(device?.personalDetails?.email);
        setShowForm(false);
      } else {
        setShowForm(true);
      }
    }
  }, [
    device?.personalDetails?.fullName,
    device?.personalDetails?.email,
    gettingDeviceInfoError,
    prevGettingDeviceInfo
  ]);

  const prevSavingPersonalDetails = usePrevious(savingPersonalDetails);

  useEffect(() => {
    if (prevSavingPersonalDetails && !errorSavingPersonalDetails) {
      setShowForm(false);
    }
  }, [errorSavingPersonalDetails, prevSavingPersonalDetails]);

  useEffect(() => {
    dispatch(getDeviceInfo(serialNumber));
  }, [dispatch, serialNumber]);

  useEffect(() => {
    if (!balanceAndPin && serialNumber) {
      dispatch(
        getBalanceAndPin({
          serial: serialNumber,
          query: "?provider=pannovate"
        })
      );
    }
  }, [balanceAndPin, serialNumber, dispatch]);

  const validateForm = () => {
    let formErrors = {};

    const namePattern = /^[A-Za-zÀ-ÖØ-öø-ÿ\s'-]+$/;
    if (!fullName.trim()) {
      formErrors.fullName = t("arosa.nameRequired");
    } else if (!namePattern.test(fullName)) {
      formErrors.fullName = t("arosa.nameError");
    }

    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!email.trim()) {
      formErrors.email = t("arosa.emailRequired");
    } else if (!emailPattern.test(email)) {
      formErrors.email = t("arosa.emailError");
    }

    setErrors(formErrors);

    return Object.keys(formErrors).length === 0;
  };

  const handleSubmit = () => {
    if (validateForm()) {
      dispatch(
        savePersonalDetailsAction({
          serial: serialNumber,
          details: { fullName, email }
        })
      );
    }
  };

  const SCHEDULE = [
    {
      day: t("arosa.thursday"),
      date: "AUGUST 29, 2024",
      events: [
        { time: "09:00 - 18:00", ev: t("arosa.eventHallOpen") },
        { time: "09:30 - 16:45", ev: t("arosa.vehicleInspections") },
        { time: "18:00", ev: t("arosa.carParadeThroughArosa") }
      ]
    },
    {
      day: t("arosa.friday"),
      date: "AUGUST 30, 2024",
      events: [
        { time: "06:30 - 18:00", ev: t("arosa.eventHallOpen") },
        {
          time: "08:25 - 17:15",
          ev: t("arosa.trainingRunsForRaceFields")
        },
        { time: "18:30", ev: t("arosa.weisshornDinnerApero") },
        { time: "21:00", ev: t("arosa.megawattConcertAtPostplatz") }
      ]
    },
    {
      day: t("arosa.saturday"),
      date: "AUGUST 31, 2024",
      events: [
        { time: "06:30 - 16:00", ev: t("arosa.eventHallOpen") },
        {
          time: "08:25 - 17:15",
          ev: t("arosa.competitions")
        },
        { time: "19:00", ev: t("arosa.aperitifFoyer") },
        { time: "19:30 - 24:00", ev: t("arosa.anniversaryDinnerTicketed") }
      ]
    },
    {
      day: t("arosa.sunday"),
      date: "SEPTEMBER 1, 2024",
      events: [
        { time: "06:30 - 18:00", ev: t("arosa.eventHallOpen") },
        {
          time: "08:25 - 17:15",
          ev: t("arosa.competitions")
        },
        { time: "18:00", ev: t("arosa.awardCeremonyGrandPrixOfArosa") }
      ]
    }
  ];

  const appStoreLinks = (appStoreUrl, playStoreUrl) => {
    return (
      <AppStoreLinks>
        <img
          src={appStoreLogo}
          alt="app-store"
          onClick={() => window.open(appStoreUrl, "_blank")}
        />
        <img
          src={gplayLogo}
          alt="play-store"
          onClick={() => window.open(playStoreUrl, "_blank")}
        />
      </AppStoreLinks>
    );
  };

  if (isLoading || gettingDeviceInfo) return <Spinner />;
  return (
    <MainContainer>
      <div>
        <LanguageSelect />
      </div>
      <Navbar>
        <img src={bbLogo} alt="bb-logo" />
        <div className="balance">
          <p>{t("arosa.currentBalance")}</p>
          <p>
            {formattedBalance(
              balanceAndPin?.balanceDetails?.availableBalance,
              balanceAndPin?.balanceDetails?.currency
            )}
          </p>
        </div>
      </Navbar>
      <Hero>
        <div className="img-container">
          <img src={heroImg} alt="classic cars" />
          <h1>{t("arosa.celebratingClassicCars")}</h1>
        </div>

        <BottomSection height={116} margin={90} color="#C87B35" />
      </Hero>

      <Explore>
        <div className="inner">
          <p>{t("arosa.exploreIntro")}</p>
          <p>{t("arosa.scrollBelow")}</p>
          <Button
            onClick={() => window.open(`http://www.bobofin.com/`, "_blank")}
          >
            {t("arosa.exploreMore")}
          </Button>
          <Button
            secondary
            onClick={() =>
              window.open(
                `https://s3.eu-west-2.amazonaws.com/www.digiseq.co.uk/bobo/bobo-email-brochure.pdf`,
                "_blank"
              )
            }
          >
            {t("arosa.boboBrochure")}
          </Button>
          <div className="video-container">
            <video
              src={
                "https://s3.eu-west-2.amazonaws.com/www.digiseq.co.uk/bobo/arosa-video.mp4"
              }
              autoPlay
              loop
              muted
              playsInline
              preload="auto"
            />
          </div>
        </div>
        <BottomSection height={210} color="#FFF" margin={200} />
      </Explore>
      <Schedule>
        <div className="header">
          <p>{"Arosa ClassicCar 2024 -"}</p>
          <p>{t("arosa.mainEventsSummary")}</p>
        </div>
        <List>
          {SCHEDULE.map((entry, i) => {
            return (
              <div key={i}>
                <ItemHeader>
                  <p className="weekday">
                    {entry.day}
                    {","}
                  </p>
                  <p>{entry.date}</p>
                </ItemHeader>
                {entry.events.map((event, j) => (
                  <Item key={j}>
                    <p className="time">{event.time}</p>
                    <p>{event.ev}</p>
                  </Item>
                ))}
              </div>
            );
          })}
          <div className="buttons">
            <Button
              secondary
              onClick={() =>
                window.open(
                  `${
                    i18n.language === "en"
                      ? "https://s3.eu-west-2.amazonaws.com/www.digiseq.co.uk/bobo/acc2024-drivers-schedule.pdf"
                      : "https://s3.eu-west-2.amazonaws.com/www.digiseq.co.uk/bobo/arosa-fahrer-zeitplan.pdf"
                  }`,
                  "_blank"
                )
              }
            >
              {t("arosa.downloadFullSchedule")}
            </Button>
            <Button
              onClick={() =>
                window.open(
                  `https://www.arosaclassiccar.ch/de/Mitfahren/ranglisten`,
                  "_blank"
                )
              }
            >
              {t("arosa.rankingsLists")}
            </Button>
          </div>
        </List>
        <BottomSection height={198} color={"#C87B35"} margin={150} />
      </Schedule>
      <YourJourney>
        <div className="intro">
          <h2>{t("arosa.yourJourneyWithBobo")}</h2>
          <p>{t("arosa.elevateLifestyle")}</p>
          <p>{t("arosa.welcomePackage")}</p>
        </div>
        <img src={journeyImg} alt="journey" />
        <div className="connect">
          <h3>{t("arosa.connectKeyfob")}</h3>
          <p>{t("arosa.downloadApp")}</p>
          <p>
            <span>{t("arosa.contactSupport")}</span>
            <a href="mailto:support@bobofin.com">support@bobofin.com</a>
            <span>{t("arosa.orViaChat")}</span>
          </p>
          <div className="fobs">
            <img src={fobArosa} alt="fob Arosa" />
            <img src={fobBobo} alt="fob Bobo" />
          </div>
          <h3 className="small">{t("arosa.additionalInfo")}</h3>
          <p>{t("arosa.keyfobUse")}</p>
          <Button
            onClick={() =>
              window.open(
                `${
                  i18n.language === "en"
                    ? "https://s3.eu-west-2.amazonaws.com/www.digiseq.co.uk/bobo/hotels-arosa-en.pdf"
                    : "https://s3.eu-west-2.amazonaws.com/www.digiseq.co.uk/bobo/hotels-arosa-de.pdf"
                }`,
                "_blank"
              )
            }
          >
            {t("arosa.hotelList")}
          </Button>
        </div>

        <div className="activation">
          <p>{t("arosa.activationCode")}</p>
          <div className="code">
            <span
              className="activation-code"
              onClick={() =>
                navigator.clipboard.writeText(balanceAndPin?.activationCode)
              }
            >
              {balanceAndPin?.activationCode || "N/A"}
            </span>
            <img className="copy" src={copyIcon} alt="copy-icon" />
          </div>
          <p>{t("arosa.downloadOurApp")}</p>
          {appStoreLinks(
            "https://apps.apple.com/us/app/bourgeois-boheme/id1574430730",
            "https://play.google.com/store/apps/details?id=com.paymeswiss.bobofin"
          )}
          <p>{t("arosa.additionalInformationClickBelow")}</p>
          <Button
            onClick={() =>
              window.open(`https://www.bobofin.com/become-a-member`, "_blank")
            }
          >
            {t("arosa.joinBourgeoisBoheme")}
          </Button>
        </div>
      </YourJourney>
      <Footer>
        <div className="socials">
          <img
            src={instaIcon}
            alt="instagram"
            onClick={() =>
              window.open(
                `https://www.instagram.com/bobo_network?igsh=MTk0N3hmZWc5dnNmZg==`,
                "_blank"
              )
            }
          />
          <img
            src={inIcon}
            alt="linkedin"
            onClick={() =>
              window.open(
                `https://www.linkedin.com/company/bourgeoisboheme/`,
                "_blank"
              )
            }
          />
          <img
            src={shopIcon}
            alt="shop"
            onClick={() =>
              window.open(
                `https://www.bobofin.com/payment-solution/cards-devices`,
                "_blank"
              )
            }
          />
        </div>
        <a
          href="https://s3.eu-west-2.amazonaws.com/www.digiseq.co.uk/bobo/tc-arosa.pdf"
          rel="noopener noreferrer"
          target="_blank"
        >
          {t("arosa.termsAndConditions")}
        </a>
        <a
          href="https://www.bobofin.com/privacy-policy-cookies-policy"
          rel="noopener noreferrer"
          target="_blank"
        >
          {t("arosa.privacyPolicy")}
        </a>
        <img src={footerLogos} alt="footer-logos" />
        <p className="version">{"1.0.0"}</p>
      </Footer>
      {showForm && (
        <Alert>
          <div className="inner-container">
            <div className="form">
              <div className="nav">
                <h2 className="title">{t("arosa.welcome")}</h2>
                <LanguageSelect />
              </div>
              <p className="subtitle">{t("arosa.provideDetails")}</p>
              <div className="inputs">
                <div className="form-group">
                  <input
                    value={fullName}
                    id="fullName"
                    type="text"
                    onChange={(ev) => setFullName(ev.target.value)}
                    placeholder={t("arosa.fullName")}
                  />
                  {errors.fullName && (
                    <p className="error">{errors.fullName}</p>
                  )}
                </div>
                <div className="form-group">
                  <input
                    value={email}
                    id="email"
                    type="email"
                    onChange={(ev) => setEmail(ev.target.value)}
                    placeholder={t("arosa.email")}
                  />
                  {errors.email && <p className="error">{errors.email}</p>}
                </div>
              </div>
              <div
                className="terms"
                onClick={() => setAcceptTerms((prev) => !prev)}
              >
                <img
                  src={acceptTerms ? checkedIcon : uncheckedIcon}
                  alt="accept"
                />{" "}
                <p>
                  {t("arosa.acceptTerms")}{" "}
                  <a
                    href="https://s3.eu-west-2.amazonaws.com/www.digiseq.co.uk/bobo/tc-arosa.pdf"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    {t("arosa.tcForm")}
                  </a>
                </p>
              </div>
              <button disabled={!acceptTerms} onClick={() => handleSubmit()}>
                {t("arosa.proceed")}
              </button>
            </div>
            <img className="logos" src={footerLogos} alt="logos" />
          </div>
        </Alert>
      )}
    </MainContainer>
  );
};

export default ArosaClassicCar;
