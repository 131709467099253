import styled from "styled-components";

export const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  padding: 40px 0;
  padding-right: 0;

  h4 {
    font-weight: 400;
    font-size: 1.125rem;
    line-height: 22px;
    margin: 0 16px 10px;
  }
  h2 {
    font-style: normal;
    font-weight: 600;
    font-size: 60px;
    line-height: 54px;
    margin-bottom: 20px;
  }

  @media (min-width: 768px) {
    h2 {
      font-weight: 600;
      font-size: 70px;
      line-height: 70px;
      margin-bottom: 20px;
    }
    padding-right: 0;
  }
  @media (min-width: 1920px) {
    padding: 60px;
    padding-right: 0;
  }

  @media (min-width: 1440px) {
    padding: 80px 60px;
    padding-right: 0;
  }
`;

export const SliderHeaderContainer = styled.div`
  padding: 0 16px;
  display: flex;
  width: "100%";
  align-items: flex-end;
  justify-content: space-between;
`;

export const Brands = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-column-gap: 20px;
  grid-template-rows: min-content; // TODO check this
  overflow-x: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
  -ms-overflow-style: none;
  scrollbar-width: none;
  padding: 0 16px;
  max-width: 360px;
  &::-webkit-scrollbar {
    display: none;
  }

  @media (min-width: 375px) {
    max-width: 370px;
    min-width: 100%;
  }
`;

export const Brand = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  h4 {
    /* text-transform: none; */
    align-self: flex-start;
    margin-top: 10px;
  }
`;

export const BrandImage = styled.div`
  img {
    object-fit: cover;
    border-radius: 20px;
    /* height: 468px; */
    width: 156px;
    height: 156px;

    @media (min-width: 768px) {
      border-radius: 20px;
      height: 481px;
      width: 359px;
    }

    @media (min-width: 1920px) {
      height: 785px;
      width: 586px;
      margin-bottom: 20px;
    }
  }
`;

export const LogoContainer = styled.div`
  width: 80px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    max-width: 100%;
    max-height: 100%;
    -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */

    filter: grayscale(100%) contrast(500%);
  }
`;
