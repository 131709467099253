import React, { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import "react-phone-number-input/style.css";

import { getBalanceAndPin } from "../../features/balance-and-pin/balanceAndPinSlice";
import {
  EventInformation,
  Footer,
  Help,
  Download,
  ImageContainer,
  ImagesContainer,
  MainContainer,
  Membership,
  NavBar,
  Welcome,
  Details,
  Item,
  B1,
  B4,
  B3,
  Link,
  B2
} from "./styled/ChesterRacecourse";

import Spinner from "../../components/Spinner";
import ExpandingContainer from "./ExpandingContainer";

import chesterLogo from "./img/chester-logo.webp";
import digiseqLogo from "./img/digiseq-logo.png";
import headerImg from "./img/header.webp";
import chevronRight from "./img/chevron-right.svg";
import appStore from "./img/app-store-badge.svg";
import playStore from "./img/play-store-badge.svg";
import globalPayments from "./img/global-payments.svg";

const CHESTER_RACECOURSE_DATA = {
  details: [
    {
      title: "Membership Type",
      detail: "Free"
    },
    {
      title: "Season ticket",
      detail: "Main Stand"
    },
    {
      title: "Purchase date",
      detail: "21/06/2023"
    },
    { title: "Seat for next event", detail: "Main Stand, E17" }
  ],
  todays_schedule: [
    { time: "15:00", details: "Race 1" },
    { time: "18:00", details: "Hospitality Area Opening" },
    { time: "19:30", details: "Official Welcoming" },
    { time: "19:45", details: "Ambassadors Q&As" },
    { time: "21:00", details: "Race 2" },
    { time: "21:20", details: "Cocktail Party" },
    { time: "23:00", details: "Event closes" },
    { time: "00:00", details: "VIP After Party" }
  ],
  membership: {
    p1: "As a valued member of Chester Racecourse, please accept this £20 gift towards any purchase of food and beverage across any of our race days.",
    p2: "Plus, you can reload to any further value by clicking on the balance at the top of the page.",
    links: [
      {
        title: "My Membership",
        subtitle: "View my membership details.",
        url: "https://www.chester-races.com/race-days/2023-chester-membership/"
      },
      {
        title: "My Shop",
        subtitle: "Visit Chester Racecourse official online store",
        url: "https://www.chester-races.com/"
      }
    ]
  },
  support: {
    details:
      "Feel free to contact our customer support team at support@chester-racecourse.com  or 01244 304 600."
  }
};

const ChesterRacecourse = () => {
  const [searchParams] = useSearchParams();
  const serialNumber = searchParams.get("serialNumber");
  const dispatch = useDispatch();

  const { balanceAndPin, isLoading } = useSelector(
    (state) => state.balanceAndPin
  );

  useEffect(() => {
    if (!balanceAndPin && serialNumber) {
      dispatch(
        getBalanceAndPin({ serial: serialNumber, query: "?provider=tokenised" })
      );
    }
  }, [balanceAndPin, serialNumber, dispatch]);

  const formattedBalance = (balanceDetails) => {
    const { availableBalance, currency } = balanceDetails;
    let iconPrefixed = true;
    let icon = "";

    switch (currency) {
      case "GBP":
        icon = "£";
        break;
      case "EUR":
        icon = "€";
        break;
      case "USD":
        icon = "$";
        break;
      default:
        icon = "";
    }

    if (iconPrefixed) {
      return icon + availableBalance;
    } else {
      return availableBalance + icon;
    }
  };

  if (isLoading) return <Spinner />;

  return (
    <MainContainer>
      <NavBar>
        <img src={chesterLogo} alt="chestera logo" />
        <div>
          Balance:{" "}
          {balanceAndPin?.balanceDetails?.availableBalance
            ? formattedBalance(balanceAndPin?.balanceDetails)
            : "N/A"}
        </div>
      </NavBar>
      <Welcome>
        <h1>HI JOHN SMITH</h1>
        <img src={headerImg} alt="welcome" />
      </Welcome>
      <ExpandingContainer title="Details">
        <Details>
          {CHESTER_RACECOURSE_DATA.details.map((item, i) => (
            <Item
              key={i}
              isLast={i === CHESTER_RACECOURSE_DATA.details.length - 1}
            >
              <B4>{item.title}</B4>
              <B3>{item.detail}</B3>
            </Item>
          ))}
        </Details>
      </ExpandingContainer>
      <ExpandingContainer title="TODAY’S SCHEDULE">
        <EventInformation>
          {CHESTER_RACECOURSE_DATA.todays_schedule.map((evt, i) => (
            <Item
              key={i}
              isLast={i === CHESTER_RACECOURSE_DATA.todays_schedule.length - 1}
            >
              <B4>{evt.time}</B4>
              <B3>{`${evt.details}`}</B3>
            </Item>
          ))}
          <br />
          <B1>
            Be in the right place at the right time with the{" "}
            <Link
              href="https://www.chester-races.com/planyourvisit2023/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Chester Racecourse Map.
            </Link>
          </B1>
        </EventInformation>
      </ExpandingContainer>
      <ExpandingContainer title="MEMBERSHIP">
        <Membership>
          <div className="intro">
            <B1>{CHESTER_RACECOURSE_DATA.membership.p1}</B1>
            <B1>{CHESTER_RACECOURSE_DATA.membership.p2}</B1>
          </div>
          {CHESTER_RACECOURSE_DATA.membership.links.map((link, i) => (
            <div
              key={i}
              className="link-container"
              onClick={() => window.open(link.url, "_blank")}
            >
              <div className="details">
                <B2>{link.title}</B2>
                <B1>{link.subtitle}</B1>
              </div>
              <img src={chevronRight} alt="chevron right" />
            </div>
          ))}
        </Membership>
      </ExpandingContainer>

      <ExpandingContainer title="SUPPORT">
        <Help>
          <B1>
            Feel free to contact our customer support team at{" "}
            <Link href="mailto:support@chester-racecourse.com">
              support@chester-racecourse.com
            </Link>{" "}
            or <Link href="tel:+441244304600">01244 304 600</Link>.
          </B1>
        </Help>
      </ExpandingContainer>
      <ExpandingContainer title="DOWNLOAD THE APP">
        <Download>
          <B1>
            Enable contactless payments on your wearable and more by downloading
            the Manage-Mii app!{" "}
          </B1>
          <ImagesContainer>
            <img
              src={appStore}
              alt="app store badge"
              onClick={() =>
                window.open(
                  "https://apps.apple.com/us/app/manage-mii/id6446908503",
                  "_blank"
                )
              }
            />

            <img
              src={playStore}
              alt="play store badge"
              onClick={() =>
                window.open(
                  "https://play.google.com/store/apps/details?id=com.digiseq.managemii&hl=en_GB&gl=US&pli=1",
                  "_blank"
                )
              }
            />
          </ImagesContainer>
          <B1>
            Already have the app?{" "}
            <Link
              href="https://www.manage-mii.co.uk/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Open Manage-Mii
            </Link>
          </B1>
        </Download>
      </ExpandingContainer>

      <Footer>
        <B1>Powered by</B1>
        <img
          src={digiseqLogo}
          alt="digiseq-logo"
          className="digiseq"
          onClick={() => window.open("https://www.digiseq.co.uk", "_blank")}
        />

        <ImageContainer ImageContainer>
          <img
            src={globalPayments}
            alt="GlobalPayments Logo"
            onClick={() =>
              window.open("https://www.globalpayments.com/en-gb", "_blank")
            }
          />
        </ImageContainer>
      </Footer>
    </MainContainer>
  );
};

export default ChesterRacecourse;
