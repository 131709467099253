import styled from "styled-components";

export const InputContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  font-size: 17px;
  width: 100%;
  border: 1px solid #dadbe6;
  box-sizing: border-box;
  border-radius: 6px;
  padding: 12px 16px;
  position: relative;

  input,
  textarea {
    width: 100%;
    border: none;
    overflow: auto;
    outline: none;
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    color: #8d8e99;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 20px;
    font-family: "Noto Sans", sans-serif;
    padding-right: 10px;
    resize: none;

    ::placeholder,
    ::-webkit-input-placeholder {
      color: #8d8e99;
    }
    :-ms-input-placeholder {
      color: #8d8e99;
    }
  }

  input:focus {
    outline: none;
  }
`;

export const InputLabel = styled.div`
  font-size: 15px;
  position: absolute;
  top: -12px;
  left: 8px;
  background-color: white;
  padding: 0 10px;
  font-family: "Noto Sans", sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #8d8e99;
`;

export const RevealPassword = styled.img`
  cursor: pointer;
  width: 26px;
  height: 22px;
  align-self: center;
`;
