import React from "react";
import { StyledHeading } from "./styled/StyledHeading";

const Heading = ({ title, subtitle }) => {
  return (
    <StyledHeading>
      <h1>{title}</h1>
      <p>{subtitle}</p>
    </StyledHeading>
  );
};

export default Heading;
