import styled from "styled-components";

export const H1 = styled.div`
  color: #fff;
  /* H1 */
  font-family: Oswald;
  font-size: 40px;
  font-style: normal;
  font-weight: 600;
  line-height: 48px; /* 120% */
`;

export const H2 = styled.div`
  color: #fff;
  font-feature-settings: "clig" off, "liga" off;
  font-family: Oswald;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 32px; /* 133.333% */
`;

export const B1 = styled.span`
  color: rgba(255, 255, 255, 0.8);
  font-feature-settings: "clig" off, "liga" off;
  font-family: Oswald;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px; /* 166.667% */
  letter-spacing: 0.4px;
`;

export const InnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 32px 16px;
  background-color: #000;
  gap: 16px;
`;

export const HeroImage = styled.img`
  width: 100%;
  object-fit: contain;
`;

export const Header = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: stretch;
  gap: 12px;
  margin-bottom: 8px;
`;

export const ContactlessPayments = styled.section`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  background: #1a1a1a;

  ${H2} {
    margin-bottom: 8px;
  }

  .manage-mii {
    display: flex;
    padding: 24px 16px;
    flex-direction: column;
    align-items: flex-start;
    align-self: stretch;
  }

  .store-apps {
    margin-top: 16px;
    display: flex;
    align-items: flex-start;
    gap: 8px;
    cursor: pointer;
  }
`;

export const MerchStore = styled.section`
  .store {
    margin-top: -5px;
    display: flex;
    padding: 24px 16px;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    align-self: stretch;

    ${H2} {
      margin-bottom: 8px;
    }

    background-color: #242424;
    a {
      color: rgba(255, 255, 255, 0.8);
      cursor: pointer;
    }
  }
`;
