import styled from "styled-components";
import mixins from "./mixins";

export const MainContainer = styled.section`
  position: relative;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 480px;
  margin: auto;
  padding-bottom: 80px;

  *:not(input, textarea) {
    -moz-user-select: none; /* firefox */
    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* IE*/
    user-select: none; /* Standard syntax */
  }
`;

export const Content = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  row-gap: 16px;
  img {
    width: 100%;
    aspect-ratio: 4/3;
  }
`;

export const Questions = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-row-gap: 8px;
`;
export const Question = styled.div`
  height: 56px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background: #f2f3f5;
  font-family: "Lora";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: -0.3px;
  color: #0d2a56;
  cursor: pointer;

  &:hover {
    background-color: #e3eff9;
  }
`;

export const AlertContainer = styled.div`
  padding-top: 150px;
  position: absolute;
  width: 100%;
  min-height: 100%;
  max-width: 480px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  background: rgba(242, 243, 245, 0.9);
`;

export const Alert = styled.div`
  margin: 0 16px;
  background-color: #0d2a56;
  color: white;
  padding: 40px 32px;
  width: 100%;
  display: grid;
  grid-template-columns: 1fr;
  grid-row-gap: 16px;

  h1 {
    ${mixins.H1}
    color:white;
  }

  img {
    width: 70px;
    margin: auto;
  }

  p {
    ${mixins.B1}
    color:white;
    margin-bottom: 16px;
  }
`;
