import { ENV } from "../../env";
import axios from "axios";

export const WEARABLES_API_URL = `https://www.${
  ENV !== "production" ? (ENV === "development" ? "dev" : ENV) + "-" : ""
}manage-mii.uk/api/wearables`;

const getTransactions = async (serial) => {
  const response = await axios.get(
    WEARABLES_API_URL + `/transactions/${serial}`
  );

  return response.data;
};

const getCarbonImpact = async (serial) => {
  const response = await axios.get(
    WEARABLES_API_URL + `/carbon-impact/${serial}`
  );

  return response.data;
};

const transactionsService = {
  getTransactions,
  getCarbonImpact
};

export default transactionsService;
