import styled from "styled-components";
import mixins from "./mixins";
import { ActionButton } from "./SharedStyles";

export const MainContainer = styled.section`
  position: relative;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 480px;
  margin: auto;

  *:not(input, textarea) {
    -moz-user-select: none; /* firefox */
    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* IE*/
    user-select: none; /* Standard syntax */
  }
`;

export const Content = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: 1fr;
  row-gap: 16px;
  img {
    width: 100%;
  }
`;

export const BottomSection = styled.div`
  width: 100%;
  padding: 0 32px;
  text-align: center;
  display: grid;
  grid-template-columns: 1fr;
  grid-row-gap: 8px;

  h1 {
    ${mixins.H1}
  }

  p {
    ${mixins.B1}
    color:#7A8799;
  }

  ${ActionButton} {
    margin-top: 12px;
  }
`;
