import React from "react";
import { Container } from "./styled/SliderArrows";

import arrowLeft from "./images/slider-arrow-left.svg";
import arrowRight from "./images/slider-arrow-right.svg";

const SliderArrows = ({ onBack, onNext }) => {
  return (
    <Container>
      <img src={arrowLeft} alt="arrow left" onClick={onBack} />
      <img src={arrowRight} alt="arrow right" onClick={onNext} />
    </Container>
  );
};

export default SliderArrows;
