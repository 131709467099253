import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const CoachTrackerProtectedRoute = () => {
  const navigate = useNavigate();
  const { user } = useSelector((state) => state.auth);

  useEffect(() => {
    if (!user) navigate("/coach-tracker-login");
  }, [user, navigate]);

  return null;
};

export default CoachTrackerProtectedRoute;
