import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { composeErrorMessage } from "../../utils/utils";

import coachTrackerService from "./coachTrackerService";

const initialState = {
  gettingPassengers: false,
  errorGettingPassengers: "",
  passengers: [],

  importingPassengers: false,
  errorImportingPassengers: ""
};

export const getPassengersAction = createAsyncThunk(
  "scavengerHunt/getContestants",
  async (_, thunkAPI) => {
    try {
      const response = await coachTrackerService.getPassengers();
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(composeErrorMessage(error));
    }
  }
);

export const importPassengersAction = createAsyncThunk(
  "campaigns/importPassengers",
  async (payload, thunkAPI) => {
    try {
      const response = await coachTrackerService.importPassengers(payload);
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(composeErrorMessage(error));
    }
  }
);

export const scavengerHunt = createSlice({
  name: "scavengerHunt",
  initialState,
  reducers: {
    reset: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(getPassengersAction.pending, (state) => {
        state.passengers = [];
        state.gettingPassengers = true;
        state.errorGettingPassengers = "";
      })
      .addCase(getPassengersAction.fulfilled, (state, action) => {
        state.gettingPassengers = false;
        state.passengers = action.payload;
      })
      .addCase(getPassengersAction.rejected, (state, action) => {
        state.gettingPassengers = false;
        state.errorGettingPassengers = action.payload;
      })
      .addCase(importPassengersAction.pending, (state) => {
        state.passengers = [];
        state.importingPassengers = true;
        state.errorImportingPassengers = "";
      })
      .addCase(importPassengersAction.fulfilled, (state, action) => {
        state.importingPassengers = false;
        state.passengers = action.payload;
      })
      .addCase(importPassengersAction.rejected, (state, action) => {
        state.importingPassengers = false;
        state.errorImportingPassengers = action.payload;
      })
      ;
  },
});

export const { reset } = scavengerHunt.actions;
export default scavengerHunt.reducer;
