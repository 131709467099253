import styled from "styled-components";
import { colors, fonts } from "./SharedStyles";

export const Product = styled.div`
  display: flex;
  flex-direction: column;
  h2 {
    ${fonts.H2}
    padding: 24px 16px 16px;
    text-align: left;
  }
  img {
    width: 100%;
    object-fit: contain;
  }
`;

export const ListContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr;
`;

export const ListItem = styled.div`
  display: flex;
  flex-direction: row;
  padding: 16px;
  .label {
    ${fonts.B1}
    margin-right: 8px;
  }

  .info {
    ${fonts.B2}
    margin-left: 8px;
  }

  a {
    text-decoration: underline;
    margin-left: 8px;
  }

  border-bottom: ${({ isLast }) => (isLast ? "none" : `1px solid #E0E0E0`)};
`;

export const Promotion = styled.div`
  background-color: ${colors.background};
  padding: 32px 16px;
  gap: 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 80px;

  img {
    max-width: 230px;
    object-fit: contain;
  }
  h2 {
    ${fonts.H2}
  }
  p {
    ${fonts.B2}
  }
  .button {
    padding: 16px 40px 16px 40px;
    border-radius: 360px;
    gap: 8px;
    background-color: ${colors.button};
    color: white;
    display: flex;
    justify-content: center;
  }
`;
