import { css } from "styled-components";

const H1 = css`
  font-family: "Arimo", sans-serif;
  font-weight: 600;
  font-style: normal;
  font-size: 30px;
  line-height: 40px;
  color: #303033;
`;

const H3 = css`
  font-family: "Arimo", sans-serif;
  font-weight: 600;
  font-style: normal;
  font-size: 18px;
  line-height: 24px;
  color: #1d1d1f;
`;

const H2 = css`
  font-family: "Arimo";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  text-align: center;
  line-height: 32px;
`;

const B1 = css`
  font-family: "Arimo", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  color: #1d1d1f;
`;

const B2 = css`
  font-family: "Arimo", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #1d1d1f;
`;

const flexCenter = css`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const mixins = { H1, H2, H3, B1, B2, flexCenter };

export default mixins;
