import React, { Suspense, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import Spinner from "../../../components/Spinner";
import { getEntities } from "../../../features/entities/entitySlice";
import { shuffleArr } from "../../../utils/utils";
import {
  BrandImage,
  Container,
  DetailsContainer,
  DetailsContainerMobile,
  GoButton
} from "./styled/FeaturedBrand";

const FeaturedBrand = () => {
  const [featuredBrand, setFeaturedBrand] = useState([]);
  const isSmallScreen = useMediaQuery({ query: "(max-width: 1439px)" });
  const dispatch = useDispatch();
  const { entities } = useSelector((state) => state.entities);

  useEffect(() => {
    if (entities.length === 0) {
      dispatch(getEntities());
    } else {
      const featuredEntities = shuffleArr(entities);

      setFeaturedBrand(featuredEntities[0]);
    }
  }, [dispatch, entities]);

  /* TODO handle more featured entities
    when more featured entities available pick a random one, 
    now we just display the first one in array
  */

  if (featuredBrand?.name) {
    return (
      <Container>
        <Suspense fallback={<Spinner />}>
          <BrandImage src={featuredBrand.coverImage} alt="cover img" />
        </Suspense>
        <DetailsContainer>
          <h2>{`${featuredBrand.name}: Wearables Collection`}</h2>
          <GoButton>
            <a
              href={featuredBrand.storeLink}
              target="_blank"
              rel="noopener noreferrer"
            >
              Go to store
            </a>
          </GoButton>
        </DetailsContainer>

        {isSmallScreen && (
          <DetailsContainerMobile>
            <h2>{`${featuredBrand.name}: Wearables Collection`}</h2>
            <p>
              Wearables are convenient method of payment. Here are some options
              to consider.
            </p>
            <GoButton isSmallScreen>
              <a
                href={featuredBrand.storeLink}
                target="_blank"
                rel="noopener noreferrer"
              >
                Go to store
              </a>
            </GoButton>
          </DetailsContainerMobile>
        )}
      </Container>
    );
  }

  return null;
};

export default FeaturedBrand;
