import React from "react";
import { useNavigate } from "react-router-dom";
import ProtectedRoute from "../../components/ProtectedRoute";
import ListItem from "./components/ListItem";
import NavBarComponent from "./components/NavBar";
import { Body, ListContainer, MainContainer } from "./styled/SharedStyles";

const PromoReadyAdmin = () => {
  const navigate = useNavigate();
  return (
    <MainContainer>
      <ProtectedRoute />
      <NavBarComponent title="Promo Ready" />
      <Body>
        <ListContainer>
          <ListItem
            text="Devices Manager"
            onClick={() => navigate("/devices-manager")}
          />
          <ListItem
            text="Campaign Manager"
            onClick={() => navigate("/campaign-manager")}
          />
          <ListItem
            text="Event Manager"
            onClick={() => navigate("/event-manager")}
          />
        </ListContainer>
      </Body>
    </MainContainer>
  );
};

export default PromoReadyAdmin;
