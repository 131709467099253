import styled from "styled-components";
import {  theme } from "./SharedStyles";

export const ProductContainer = styled.section`
  display: flex;
  padding: 0px 24px 40px 24px;
  flex-direction: column;
  align-items: center;
  gap: 40px;
  align-self: stretch;
`;

export const ProductImageSkeletonContainer = styled.section`
  display: flex;
  padding: 40px 24px 40px 24px;
  flex-direction: column;
  align-items: center;
  gap: 40px;
  align-self: stretch;
`;

export const ContentSkeletonContainer = styled.section`
  padding: 0px 16px 16px 16px;
`;

export const SkeletonContainer = styled.section`
  margin-top: 8px;
`;

export const LogoContainer = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
`;


export const Logo = styled.section`
  display: flex;
  width: 160px;
  height: 160px;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  img {
    width: 150px;
    height: 150px;
  }
`;

export const ImageContainer = styled.section`
  border-radius: 16px;
  background-color: ${theme.colors.backgroundOptions};

  //box-shadow: 0px 32px 80px rgba(16, 22, 40, 0.1);
  display: flex;
  width: 160px;
  height: 160px;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  img {
    ${({ noImage }) =>
      noImage
        ? ` width:  #82px;  height: 82px;`
        : ` width:  #127.6px;  height: 127.6px;`}
  }
`;

