import styled from "styled-components";

import bannerBg from "../img/banner-bg.webp";
import { colors, fonts } from "./SharedStyles";

export const Banner = styled.div`
  background-image: url(${bannerBg});
  aspect-ratio: 1;
  background-size: cover;
  background-repeat: no-repeat;

  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  padding-bottom: 24px;

  h1 {
    padding: 8px 16px;
    background-color: white;
    ${fonts.H1}
  }
`;

export const Contestants = styled.div`
  background-color: ${colors.background};
  padding: 24px 16px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  img {
    width: 40px;
    height: 40px;
    margin-bottom: 16px;
  }

  .date-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    text-align: center;

    & > :nth-child(2) {
      ${fonts.B2}
    }
  }

  .date {
    padding: 8px 16px;
    border: 1px solid ${colors.divider};
    ${fonts.B1}
  }
`;

export const Player = styled.div`
  flex-direction: column;
  justify-content: space-between;
  text-align: ${({ left }) => (left ? "start" : "end")};
  p {
    ${fonts.B1}
  }
`;

export const Question = styled.div`
  padding: 24px 16px;
  h2 {
    ${fonts.H2}
    margin-bottom: 16px;
    text-align: left;
  }

  .logos {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 16px;
  }

  .logo {
    height: 171px;
    border: 1px solid ${colors.border};
    /* aspect-ratio: 1; */
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    img {
      width: 80px;
      height: 80px;
    }
  }
`;

export const Form = styled.div`
  padding: 0 16px 16px;
  display: grid;
  grid-template-columns: 1fr;
  row-gap: 16px;
  margin-bottom: 80px;

  p {
    ${fonts.B1}
  }

  input {
    height: 56px;
    border: 1px solid ${colors.border};
    padding: 16px;

    &::placeholder {
      ${fonts.B2}
    }

    &:focus {
      outline: none;
    }
  }

  .button {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 56px;
    background-color: ${colors.button};
    color: white;
    cursor: pointer;
    ${fonts.B3}
  }
`;
