import styled from "styled-components";

export const Container = styled.section`
  background: #f2f2f5;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 40px 20px;

  @media (min-width: 1440px) {
    flex-direction: row;
    padding: 40px 60px;
  }

  img {
    display: none;
    width: 109px;
    margin-top: 20px;
    @media (min-width: 768px) {
      display: inline-block;
    }
    @media (min-width: 1440px) {
      width: 564px;
      margin-top: 0px;
    }
  }

  h2 {
    font-weight: 600;
    font-size: 3.75rem;
    line-height: 3.375rem;
    max-width: 100%;

    @media (min-width: 768px) {
      font-size: 4.375rem;
      line-height: 4.375rem;
    }

    @media (min-width: 1440px) {
      max-width: 80%;
    }
    @media (min-width: 1920px) {
      max-width: 60%;
    }
  }

  h4 {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
  }
`;

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
`;
