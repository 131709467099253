import React, { useEffect } from "react";
import { MainContainer, Welcome } from "./styled/AscotFamilyDay";
import ascotLogo from "./img/ascot-logo.png";
import { ActionButton } from "./styled/SharedStyles";
import { useNavigate, useParams } from "react-router-dom";
import Header from "./components/Header";
import { useDispatch, useSelector } from "react-redux";
import { getAscotDevice } from "../../features/ascot/ascotSlice";
import Spinner from "../../components/Spinner";

const AscotFamilyDay = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const params = useParams();

  const { device, isError, isLoading, message } = useSelector(
    (state) => state.ascot
  );

  useEffect(() => {
    dispatch(getAscotDevice(params.serial));
  }, [params.serial, dispatch]);

  useEffect(() => {
    if (device?.location) {
      if (device.location === 7) {
        navigate(`/ascot-family-day/${params.serial}/progress`);
      } else {
        navigate(`/ascot-family-day/${params.serial}/${device.location}`);
      }
    }
  }, [device, navigate, params.serial]);

  if (isLoading) return <Spinner />;

  if (isError && message !== "Device not found") {
    return (
      <MainContainer>
        <Header />
        <p
          style={{ color: "red", margin: "0 20px 100px", textAlign: "center" }}
        >
          An error has occurred, please try again or comeback later
        </p>
      </MainContainer>
    );
  }

  if (!device?.location)
    return (
      <>
        <MainContainer>
          <Header />
          <Welcome>
            <img src={ascotLogo} alt="ascot" />
            <h1>CHRISTMAS SCAVENGER HUNT</h1>
            <p>Follow the clues to win a Christmas prize!</p>
            <ActionButton
              onClick={() =>
                navigate(`/ascot-family-day/${params.serial}/registration`)
              }
            >
              GET STARTED
            </ActionButton>
          </Welcome>
        </MainContainer>
      </>
    );
  else return null;
};

export default AscotFamilyDay;
