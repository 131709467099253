import styled, { keyframes, createGlobalStyle } from "styled-components";

import AdamBold from "../fonts/Adam-Bold.ttf"
import AdamLight from "../fonts/Adam-Light.ttf"
import AdamMedium from "../fonts/Adam-Medium.ttf"


export const Tap2FontStyle = createGlobalStyle`


@font-face {
  font-family: 'Adam Bold';
  src: url(${AdamBold}) format('truetype');
  font-weight: 325;
  font-style: normal;
  font-display: auto;
}

@font-face {
  font-family: 'Adam Light';
  src: url(${AdamLight}) format('truetype');
  font-weight: 375;
  font-style: normal;
  font-display: auto;
}

@font-face {
  font-family: 'Adam Medium';
  src: url(${AdamMedium}) format('truetype');
  font-weight: 400;
  font-style: normal;
  font-display: auto;
}
`;

export const theme = {
  colors: {
    primary: "#00ce1c",
    backgroundOptions: "#494746",
    background: "#000000",
    divider: "#d8d8d9",
    highEmphasis: "#FFFFFF",
    medEmphasis: "#FFFFFF",
    surface: "#ffffff",
  },
};

export const fonts = {
  H2: {
    fontFamily: "Adam Bold",
    fontSize: "32px",
    fontWeight: 700,
    lineHeight: "40px",
    color: theme.colors.highEmphasis,
  },
  H3: {
    fontFamily: "Adam Bold",
    fontSize: "24px",
    fontWeight: 600,
    lineHeight: "32px",
    color: theme.colors.highEmphasis,
  },
  H4: {
    fontFamily: "Adam Bold",
    fontSize: "16",
    fontWeight: 600,
    lineHeight: "32px",
    color: theme.colors.highEmphasis,
  },
  B1: {
    fontFamily: "Adam Medium",
    fontSize: "16px",
    lineHeight: "24px",
    fontWeight: 700,
    color: theme.colors.highEmphasis,
  },
  B2: {
    fontFamily: "Adam Medium",
    fontSize: "16px",
    lineHeight: "24px",
    fontWeight: 500,
    color: theme.colors.medEmphasis,
  },
};

export const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 480px;
  margin: auto;
  min-height: 100vh;
  background-color: ${theme.colors.background};
`;

export const ContentContainer = styled.section`
  padding: 0px 16px 16px 16px;
`;

export const H2 = styled.h2`
  ${fonts.H2}
`;

export const H3 = styled.h3`
  ${fonts.H3}
`;

export const H4 = styled.h4`
  ${fonts.H4}
`;

export const B1 = styled.p`
  ${fonts.B1}
`;

export const B2 = styled.p`
  ${fonts.B2}
`;

export const B3 = styled.p`
  ${fonts.B3}
`;

export const Footer = styled.div`
  margin-top: 64px;
  align-items: center;
  border: 1px none;
  display: flex;
  flex-direction: column;
  gap: 8px;
  justify-content: center;
  padding: 32px 16px 64px;
  background-color: ${theme.colors.background};
  a {
    color: ${theme.colors.medEmphasis};
    font-family: "Adam Bold";
    font-size: 16px;
    font-weight: 700;
    line-height: 40px;
    margin-left: 8px;
  }
`;

export const Divider = styled.div`
  border: 1px solid #f6f6f7;
  margin-top: 32px;
  margin-bottom: 32px;
`;

const rotate360 = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

export const Spinner = styled.div`
  animation: ${rotate360} 1s linear infinite;
  transform: translateZ(0);

  border-top: 2px solid grey;
  border-right: 2px solid grey;
  border-bottom: 2px solid grey;
  border-left: 4px solid black;
  background: transparent;
  width: 24px;
  height: 24px;
  border-radius: 50%;
`;


export const TextWithClickableLink = styled.div`
  margin-top: 16px;
  font-family: "Adam Bold";
  font-size: "16px";
  line-height: "24px";
  font-weight: 500;
`;

export const ClickableLink = styled.span`
  text-decoration: underline;
  font-weight: bold;
`;


