import React, { useEffect, useState } from "react";
import { ErrorMsg, MainContainer } from "./styled/SharedStyles";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
  getCampaign,
  getCampaignVotingResults,
  resetError,
  resetDeviceCampaign
} from "../../features/campaign-manager/campaignManagerSlice";

import {
  Reset,
  UsersList,
  UsersListItem,
  VoteOptionsList,
  VoteOptionsListItem,
  VotingResultsContainer
} from "./styled/VotingResults";
import Spinner from "../../components/Spinner";
import NavBarComponent from "./components/NavBar";
import ProtectedRoute from "../../components/ProtectedRoute";

const VotingResults = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const {
    campaign,
    votingResults,
    isLoading,
    isError,
    message,
    deviceCampaignReseted
  } = useSelector((state) => state.campaigns);

  const [selectedOption, setSelectedOption] = useState(null);

  useEffect(() => {
    if (deviceCampaignReseted) {
      dispatch(getCampaign(params.campaignId));
      dispatch(getCampaignVotingResults(campaign?.id));
      setSelectedOption(null);
    }
  }, [campaign?.id, deviceCampaignReseted, dispatch, params.campaignId]);

  useEffect(() => {
    if (!campaign) {
      dispatch(getCampaign(params.campaignId));
    } else {
      dispatch(getCampaignVotingResults(campaign.id));
    }
  }, [campaign, dispatch, params.campaignId]);

  useEffect(() => {
    return () => {
      dispatch(resetError());
    };
  }, [dispatch]);

  function handleReset(serial) {
    dispatch(
      resetDeviceCampaign({
        serial,
        campaignId: campaign.id,
        resetType: "VOTING"
      })
    );
  }

  if (isLoading) return <Spinner />;

  return (
    <MainContainer>
      <ProtectedRoute />
      <NavBarComponent hasBackArrow={true} />
      <VotingResultsContainer>
        <p>
          {votingResults && Object.keys(votingResults?.results).length === 0
            ? "No votes available"
            : "Voted options"}
        </p>

        <VoteOptionsList>
          {votingResults?.results &&
            Object.keys(votingResults.results).map((e, i) => {
              return (
                <VoteOptionsListItem
                  key={i}
                  selected={selectedOption?.option === e}
                  onClick={() =>
                    setSelectedOption({
                      option: e,
                      votes: votingResults.results[e]
                    })
                  }
                >
                  {e}
                </VoteOptionsListItem>
              );
            })}
        </VoteOptionsList>

        <br />
        {selectedOption?.option && <h2>{selectedOption.option}</h2>}

        {selectedOption?.votes && (
          <UsersList>
            {selectedOption.votes.map((e, i) => (
              <UsersListItem key={i}>
                {`${i + 1}. ${e.fullName} - ${e.email}`}
                <Reset onClick={() => handleReset(e.serialNumber)}>RESET</Reset>
              </UsersListItem>
            ))}
          </UsersList>
        )}

        {isError && (
          <ErrorMsg>
            {message ||
              "Oh no, we have an error, please refresh page or try again later"}
          </ErrorMsg>
        )}
      </VotingResultsContainer>
    </MainContainer>
  );
};

export default VotingResults;
