import styled from "styled-components";
import { createGlobalStyle } from "styled-components";
import Neutraface2TextBookTTF from "../fonts/Neutraface2Text-Book.ttf";
import LoraRegularTTF from "../fonts/Lora-Regular.ttf";
import ChronicleRoman from "../fonts/Chronicle-Display-Roman.otf";
import ChronicleSemibold from "../fonts/Chronicle-Display-Semibold.otf";
import mixins from "./mixins";

export const AscotFontStyle = createGlobalStyle`
@font-face {
  font-family: 'Chronicle Display';
  src: url(${ChronicleRoman}) format('opentype');
  font-weight: 325;
  font-style: normal;
  font-display: auto;
}

@font-face {
  font-family: 'Chronicle Display ';
  src: url(${ChronicleSemibold}) format('opentype');
  font-weight: 375;
  font-style: normal;
  font-display: auto;
}

@font-face {
  font-family: 'Neutraface2TextBook';
  src: url(${Neutraface2TextBookTTF}) format('truetype');
  font-weight: 400;
  font-style: normal;
  font-display: auto;
}

@font-face {
    font-family:"Lora" ;
    src: url(${LoraRegularTTF}) format('truetype');
    font-weight: 400;
  font-style: normal;
  font-display: auto;
}

h1 {
  font-family: 'Chronicle Display';
  font-style: normal;
  font-weight: 375; 
  font-size: 24px;
  line-height: 32px;
  color: #0d2a56;
  letter-spacing: 2px;
  }

`;

export const AscotCrown = styled.div`
  position: absolute;
  background-color: white;
  left: 0;
  top: 0;
  display: flex;
  img {
    border-right: 1px solid #d7dbe2;
    border-bottom: 1px solid #d7dbe2;
  }
`;

export const Content = styled.div`
  margin-top: 80px;
  display: grid;
  grid-template-columns: 1fr;
  row-gap: 8px;

  h1 {
    ${mixins.H1}
    text-align: left;
  }
`;

export const Label = styled.div`
  ${mixins.B1}
  text-align: left;
  margin-bottom: 8px;
  color: #0d2a56;

  a {
    text-decoration: underline;
    color: #0d2a56;
  }
`;

export const ErrorText = styled.div`
  ${mixins.B1}
  text-align: center;
  margin-bottom: 8px;
  color: red;
  margin: 8px 10px;
`;

export const InputGroup = styled.div`
  width: 100%;
`;
export const Input = styled.input`
  border: none;
  width: 100%;
  height: 56px;
  background: #f2f3f5;
  font-family: "Lora";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: -0.3px;
  color: #0d2a56;
  padding-left: 16px;

  &:focus {
    outline: none;
    background-color: white;
    outline: 1px solid #0d2a56;
  }
`;

export const Button = styled.div`
  width: 100%;
  height: 56px;
  background: ${(props) => (props.secondary ? "#F2F3F5" : "#0D2A56")};
  font-family: "Lora";
  color: ${(props) => (props.secondary ? "#0D2A56" : "#FFFFFF")};
  display: flex;
  justify-content: center;
  align-items: center;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  text-align: center;
  letter-spacing: -0.3px;
  cursor: pointer;
`;

export const ActionButton = styled.button`
  width: 100%;
  height: 56px;
  background: ${({ inverted }) => (inverted ? "#fff" : "#0d2a56")};
  ${mixins.H2}
  ${mixins.flexCenter}
  color: ${({ inverted }) => (inverted ? "#0d2a56" : "#fff")};
  cursor: pointer;

  img {
    margin-left: 8px;
  }

  :active {
    background-color: #f0896a;
  }
  :disabled {
    opacity: 20%;
  }
`;

export const ButtonsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 16px;
  margin-bottom: 16px;
`;

export const Separator = styled.div`
  border-bottom: ${({ isDark }) =>
    isDark ? "2px solid #8695aa" : "2px solid #f5f6f8"}; // dark 8695aa
  margin: 8px 0;
`;

export const Box = styled.div`
  border: 1px solid #7a8799;
  padding: 14px;
  margin: 0 16px;
  color: #0d2a56;

  h2 {
    ${mixins.H2}
  }

  p {
    ${mixins.B1}
    text-align: left;
    color: "#0d2a56";
    margin-bottom: 8px;
  }
`;

export const ErrorLabel = styled.div`
  ${mixins.B1}
  font-size: 16px;
  text-align: left;
  color: red;
`;
