import styled, { createGlobalStyle } from "styled-components";
import BritishGasBody from "../font/BritishGas-Body.woff2";
import BritishGasMedium from "../font/BritishGas-Medium.woff2";

import bgImg from "../img/background.webp";

export const BritishGasFontStyle = createGlobalStyle`
@font-face {
  font-family: 'British Gas';
  src: url(${BritishGasBody}) format('woff2');
  font-style: normal;
  font-display: auto;
}

@font-face {
  font-family: 'British Gas Medium';
  src: url(${BritishGasMedium}) format('woff2');
  font-style: normal;
  font-display: auto;
 
}
`;

export const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 480px;
  margin: auto;
  min-height: 100vh;

  .button {
    min-width: 165px;
    color: white;
    cursor: pointer;
    display: inline-flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 8px 16px;
    font-style: normal;
    background: #000;
    border-radius: 360px;
    margin: 16px 0;
  }
`;

export const Navbar = styled.nav`
  padding: 8px 16px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  background: #0093f5;

  img {
    height: 44px;
    object-fit: contain;
  }

  .balance {
    p {
      color: white;
      text-align: right;
      font-family: "British Gas";
      font-size: 18px;
      font-style: normal;
      font-weight: 390;
      line-height: normal;
      text-transform: uppercase;
      line-height: 24px; /* 133.333% */
      letter-spacing: 0.15px;
    }
  }
`;

export const Intro = styled.div`
  padding: 25px 25px 48px;
  align-self: stretch;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  gap: 16px;

  background: url(${bgImg}) lightgray 50% / cover no-repeat;
  h1,
  a,
  p {
    color: #fff;
  }

  h1 {
    text-align: center;
    font-feature-settings: "clig" off, "liga" off;
    font-family: "British Gas Medium";
    font-size: 32px;
    font-style: normal;
    font-weight: 650;
    line-height: 40px; /* 125% */
    letter-spacing: normal;
  }

  p {
    font-family: "British Gas";
    font-size: 16px;
    font-style: normal;
    font-weight: 390;
    line-height: 24px; /* 150% */
    letter-spacing: 0.5px;
  }
  .link,
  a {
    font-family: "British Gas Medium";
    &:hover {
      cursor: pointer;
    }
  }

  a {
    text-decoration: underline;
  }
`;

export const PaymentAccount = styled.section`
  padding: 32px 24px 0px;
  background-color: #fff;
  align-items: flex-start;
  gap: 16px;

  .inner {
    display: flex;
    gap: 8px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
  }

  .title {
    font-family: "Inter";
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: 40px;
    margin-bottom: 16px;
  }
  .subtitle {
    font-family: "Inter";
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 40px; /* 166.667% */
    margin-bottom: 8px;
  }
  p {
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 150% */
    letter-spacing: 0.5px;
    color: #000;
  }

  .logo {
    width: 189px;
    height: 60px;
  }

  .activation-code {
    display: flex;
    padding: 12px 16px;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
    border-radius: 6px;
    border: 1px solid #000;
    background: #fff;
    color: #000;
    /* Headline/Medium */
    font-family: "Inter";
    font-size: 28px;
    font-style: normal;
    font-weight: 700;
    line-height: 36px; /* 128.571% */
    color: #000;
  }
`;

export const Logos = styled.div`
  padding: 24px 0;
  background-color: #db3c8e;
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: center;

  p {
    color: #fff;
    text-align: center;
    font-feature-settings: "clig" off, "liga" off;
    font-family: "Noka";
    font-size: 32px;
    font-style: normal;
    font-weight: 800;
    line-height: 40px; /* 125% */
  }
`;

export const NextStepsContainer = styled.div`
  margin-top: -1px;
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 16px;
  background-color: white;
`;

export const StepContainer = styled.div`
  padding: 32px 16px;
  h2 {
    margin-top: 16px;
    font-family: "Inter";
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: 40px;
  }
  p {
    margin-top: 16px;
    font-family: "Inter";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 150% */
    letter-spacing: 0.5px;
    columns: #626262;
  }
  .pay-anywhere {
    margin: 24px auto;
    width: 100%;
    object-fit: contain;
  }
`;

export const CurvelogoContainer = styled.div`
  margin-bottom: 16px;
  padding: 24px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  .separator {
    height: 25px;
    object-fit: cover;
    width: 100%;
  }
  .title {
    font-family: "Inter";
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 40px;
  }

  .curve-logo {
    height: 32px;
    width: 152px;
  }
  .flags {
    display: flex;
    gap: 8px;
    img {
      height: 26px;
    }
  }
`;

export const StepNumber = styled.div`
  display: flex;
  width: 40px;
  padding: 8px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 360px;
  background: #f6f6f7;
`;

export const AppStoreLinks = styled.section`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 16px;

  img {
    cursor: pointer;
    margin-top: 24px;
    max-height: 50px;
  }
`;

export const Footer = styled.footer`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 24px 16px 48px;
  gap: 16px;
  border-top: 1px solid #000;
  p {
    color: #000;
    text-align: center;
    font-family: "British Gas";
    font-size: 16px;
    font-style: normal;
    font-weight: 390;
    line-height: 24px; /* 150% */
    letter-spacing: 0.5px;
  }
`;

export const Alert = styled.div`
  max-width: 480px;
  position: absolute;
  background: rgba(24, 24, 24, 0.9);
  height: 100%;
  width: 100%;
  text-align: center;

  .inner-container {
    position: fixed;
    max-width: 343px;
    width: 90%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 40px 22px;
    border-radius: 6px;
    background-color: #fff;
    z-index: 9999;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 40px;

    h2 {
      text-align: center;
      font-feature-settings: "clig" off, "liga" off;
      font-family: "British Gas Medium";
      font-size: 24px;
      font-style: normal;
      font-weight: 650;
      line-height: 40px; /* 125% */
      letter-spacing: normal;
    }

    p,
    button,
    input {
      font-family: "British Gas";
      font-size: 16px;
      font-style: normal;
      font-weight: 390;
      line-height: 16px; /* 150% */
      letter-spacing: 0.5px;
    }

    .form {
      display: flex;
      flex-direction: column;
      gap: 16px;
    }

    .pin {
      width: 116px;
      padding: 0 8px;
      font-size: 24px;
      border: 1px solid #202020;
      border-radius: 5px;
      margin: auto;
    }

    input {
      ::placeholder {
      }
      :focus {
        outline: none;
      }
      border: none;
      border-bottom: 1px solid black;
      padding: 22px 0;
      margin-bottom: 24px;
    }

    .error {
      color: red;
      font-size: 14px;
      margin-top: 8px;
    }
  }
`;

export const FormButton = styled.button`
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ secondary }) => (secondary ? "#fff" : "#202020")};
  border: ${({ secondary }) => (secondary ? "1px solid #202020" : "none")};
  color: ${({ secondary }) => (!secondary ? "#fff" : "#202020")};
  padding: 8px;
  border-radius: 360px;
`;
