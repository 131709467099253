import styled from "styled-components";

export const theme = {
  colors: {
    highEmphasis: "#202020",
    surface: "#ffffff",
    medEmphasis: "#626262",
    backgroundOptions: "#f9f9fa",
    background: "#F6F6F7",
    divider: "#E0E0E0",
    primary: "#BA131D",
    orange: "#FF6400",
    blue1: "#0099FF",
    blue2: "#1877F2",
    blue3: "#2867B2",
    blueGradient: "#00A0FF, #00A1FF, #00BEFF, #00D2FF, #00DFFF, #00E3FF",
    greenGradient: "#32A071, #2DA771, #15CF74, #06E775, #00F076",
    redGradient: "#FF3A44, #C31162",
    yellowGradient: "#FFE000, #FFBD00, #FFA500, #FF9C00"
  }
};

const fonts = {
  H1: {
    fontFamily: "Inter",
    fontSize: "36px",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "44px" /* 122.222% */,
    letterSpacing: "-0.5px",
    color: theme.colors.surface
  },
  H2: {
    fontFamily: "Inter",
    fontSize: "28px",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "36px",
    color: theme.colors.highEmphasis
  },
  B1: {
    fontFamily: "Inter",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "24px",
    letterSpacing: "0.5px",
    color: theme.colors.highEmphasis
  },
  B2: {
    fontFamily: "Inter",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "24px",
    letterSpacing: "0.5px",
    color: theme.colors.medEmphasis
  }
};

export const H1 = styled.h1`
  ${fonts.H1}
`;

export const H2 = styled.h1`
  ${fonts.H2}
`;

export const B1 = styled.p`
  ${fonts.B1}
`;

export const B2 = styled.p`
  ${fonts.B2}
`;

export const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 480px;
  margin: auto;
  background-color: ${theme.colors.background};
`;

export const InnerContainer = styled.main`
  padding: 0 16px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 16px;
  padding-top: 24px;
`;

export const NavBar = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: ${theme.colors.surface};
  border-bottom: 1px solid ${theme.colors.divider};
  padding: 8px 16px;
  .logo {
    height: 40px;
  }
  .balance-container {
    display: flex;
    gap: 8px;
  }
`;

export const ShowMore = styled.span`
  font-family: "Inter";
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0.15px;
  text-decoration-line: underline;
  cursor: pointer;
`;

export const Wearable = styled.div`
  margin: auto;

  img {
    border-radius: 24px;
    background: ${theme.colors.surface};
    width: 176px;
    height: 176px;
    object-fit: contain;
  }
`;

export const Welcome = styled.header`
  border-radius: 16px;
  background: ${theme.colors.blue1};
  padding: 32px 24px;
  display: flex;
  flex-direction: column;
  gap: 12px;

  ${B1} {
    color: ${theme.colors.surface};
  }
`;

export const SectionContainer = styled.section`
  display: flex;
  flex-direction: column;
  gap: 12px;
  border-radius: 16px;
  background-color: ${theme.colors.surface};
  overflow: hidden;
  width: 100%;
  .inner {
    display: flex;
    flex-direction: column;
    padding: 24px 24px 32px;
    gap: 12px;
    .apps {
      display: flex;
      gap: 8px;
      cursor: pointer;
    }
    .digiseq {
      color: ${theme.colors.orange};
      margin-left: 8px;
    }

    .st {
      font-weight: 700;
    }
  }

  ul {
    margin-top: 0;
    margin-left: 24px;
  }
`;

export const SectionImg = styled.img`
  width: 100%;
  height: 180px;
`;

export const StyledLink = styled.a`
  font-family: "Inter", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px; /* 150% */
  letter-spacing: 0.15px;
  text-decoration-line: underline;
  cursor: pointer;
  margin: ${({ hasMargin }) => (hasMargin ? "0px 8px" : "0px")};
`;

export const Footer = styled.footer`
  margin-top: 24px;
  padding: 32px 16px 64px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  background-color: ${theme.colors.surface};
  width: 100%;

  .digiseq {
    color: ${theme.colors.orange};
  }
  .links {
    display: flex;
    flex-direction: column;
    gap: 12px;
    margin: 24px 0 32px;
  }
`;

export const Socials = styled.div`
  margin-top: 16px;
  display: flex;
  align-items: center;
  gap: 16px;

  img {
    cursor: pointer;
  }
`;
