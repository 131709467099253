import styled from "styled-components";

const B1 = {
  fontFamily: "Mark For MC Narrow W00 Book",
  fontStyle: "normal",
  fontWeight: 450,
  fontSize: "16px",
  lineHeight: "24px",
  letterSpacing: "0.5px",
  color: "#CCCCCC"
};

const B2 = {
  //bold/button
  fontFamily: "Mark For MC Narrow W00 Bold",
  fontStyle: "normal",
  fontWeight: 700,
  fontSize: "16px",
  lineHeight: "24px",
  letterSpacing: "0.25px",
  color: "#ffffff"
};

const B3 = {
  fontFamily: "Mark For MC Narrow W00 Bold",
  fontStyle: "normal",
  fontWeight: 700,
  fontSize: "14px",
  lineHeight: "24px",
  letterSpacing: "0.5px",
  color: "#ffffff"
};

const B4 = {
  //footer text
  fontFamily: "Mark For MC Narrow W00 Book",
  fontStyle: "normal",
  fontWeight: 450,
  fontSize: "12px",
  lineHeight: "24px",
  letterSpacing: "0.5px",
  color: "#FFFFFF"
};

const H2 = {
  fontFamily: "Mark For MC Narrow W00 Book",
  fontStyle: "normal",
  fontWeight: 450,
  fontSize: "28px",
  lineHeight: "32px",
  color: "#FFFFFF"
};

export const NavBar = styled.div`
  color: white;
  padding: 8px 16px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: #282827;
  ${B1}
  img {
    height: 40px;
  }

  .view-balance {
    padding: 4px 8px;
    cursor: pointer;
  }
`;

export const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 480px;
  margin: auto;
  min-height: 100vh;
  background-color: #000000;
`;

export const SectionContainer = styled.section`
  padding: 24px 16px;
  background-color: #282827;
  margin: 0px 16px 16px;
  border-radius: 6px;

  .title-bar {
    display: flex;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    h2 {
      ${H2}
      color: white;
    }
    img {
      cursor: pointer;
    }
  }
`;

export const MastercardIntro = styled.div`
  background-color: #141414;
  padding: 40px;
  display: flex;
  flex-direction: column;

  align-items: center;
  img {
    height: 40px;
    margin-bottom: 24px;
  }

  h2,
  p {
    text-align: center;
  }

  h2 {
    ${H2};
    margin-bottom: 16px;
  }
  p {
    ${B1}
  }
`;

export const Wearable = styled.div`
  margin: 0 35px;

  img {
    width: 100%;
    height: auto;
    object-fit: contain;
    aspect-ratio: 1;
  }
`;

export const GreenManifesto = styled.div`
  padding: 24px 16px;
  background-color: #282827;
  margin: 0px 16px 16px;
  border-radius: 6px;

  h2 {
    ${H2};
    margin-bottom: 16px;
  }
  p {
    ${B1}
    margin-bottom: 16px;
  }

  .collapsable {
    text-decoration: underline;
    cursor: pointer;
  }
`;

export const EventInformation = styled.div`
  p {
    ${B1}
    margin: 16px 0;
  }

  .stores {
    cursor: pointer;
    display: flex;
    flex-direction: row;
    gap: 8px;
  }

  .button {
    ${B2}
    cursor: pointer;
    display: inline-flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 8px 8px 8px 16px;
    font-style: normal;
    background: #f37439;
    border-radius: 360px;
    img {
      margin-bottom: -4px;
    }
  }
`;

export const AfterEvent = styled.div`
  margin-top: 24px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;

  .bb-logo {
    height: 16px;
  }

  h2 {
    ${H2}
  }

  p {
    ${B1}
  }

  .activation-code {
    padding: 12px 16px;
    background-color: #3d3d3c;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    img {
      cursor: pointer;
    }
  }
  .button {
    ${B2}
    cursor: pointer;
    display: inline-flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 8px 8px 8px 16px;
    font-style: normal;
    background: #f37439;
    border-radius: 360px;
    margin-bottom: 8px;
    img {
      margin-bottom: -4px;
    }
  }

  .stores {
    cursor: pointer;
    display: flex;
    flex-direction: row;
    gap: 8px;
  }

  .line {
    border: 1px solid #e0e0e0;
    width: 100%;
    height: 1px;
  }

  .or-separator {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    p {
      margin: 0px 16px;
    }
  }
`;

export const Footer = styled.div`
  background-color: #282827;
  margin-top: 8px;
  padding: 24px 16px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  justify-content: center;
  align-items: center;

  p {
    ${B4}
  }

  img {
    height: 24px;
    margin-bottom: 8px;
    cursor: pointer;
  }
`;

export const InfoAlert = styled.div`
  max-width: 480px;
  position: absolute;
  background: rgba(9, 10, 15, 0.2);
  height: 100%;
  width: 100%;
  text-align: center;

  .inner-container {
    position: fixed;
    max-width: 360px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 32px 24px;
    background-color: white;
    border-radius: 16px;
    width: 90%;
    z-index: 9999;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .close-button {
      width: 32px;
      height: 32px;
      align-self: flex-end;
      cursor: pointer;
    }

    h2 {
      ${H2};
      color: #001479;
      margin-bottom: 8px;
    }

    p {
      ${B1}
      color: #001479;
      margin-bottom: 24px;
    }

    .terms {
      ${B3}
      margin-bottom: 24px;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;

      img {
        margin-right: 10px;
        cursor: pointer;
      }

      a {
        text-decoration: underline;
      }

      p {
        margin-bottom: 0;
      }
    }

    .input-container {
      padding: 16px;
      margin-bottom: 16px;
      border: 2px solid #f1f3f7;
      display: flex;
      flex-direction: row;
      gap: 16px;
      border-radius: 8px;
    }

    .input-error {
      border: 1px solid red;
    }

    input {
      border: none;
      width: 100%;
      background-color: inherit;
    }

    input:focus {
      outline: none;
    }

    .error-msg {
      ${B3}
      text-align: left;
      color: red;
      margin-top: -8px;
      margin-bottom: 16px;
    }

    .button {
      width: 100%;
      background-color: #f37439;
      border-radius: 360px;
      cursor: pointer;
      color: white;
      padding: 16px 0;
    }

    .button-secondary {
      margin-top: 16px;
      background-color: white;
      border: 2px solid #f37439;
      color: #f37439;
    }
  }
`;
