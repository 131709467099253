import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import {
  getBalanceAndPin,
  savePersonalDetailsAction
} from "../../features/balance-and-pin/balanceAndPinSlice";
import { getDeviceInfo } from "../../features/hugo-boss/hugoBossSlice";
import usePrevious from "../../hooks/usePrevious";
import { formattedBalance } from "../../features/balance-and-pin/utils";

import Spinner from "../../components/Spinner";

import {
  Alert,
  AppStoreLinks,
  CurvelogoContainer,
  Footer,
  FormButton,
  Intro,
  MainContainer,
  Navbar,
  NextStepsContainer,
  PaymentAccount,
  StepContainer,
  StepNumber
} from "./styles/BritishGas";

import bgLogo from "./img/logos.webp";
import appStoreLogo from "../../assets/app-store.svg";
import gplayLogo from "../../assets/play-store.svg";
import curveLogo from "../../assets/curve_logo.png";
import payAnyWhereImage from "../tap2start/img/pay-anywhere-image.png";
import euFlag from "../../assets/eu-flag.png";
import ukFlag from "../../assets/uk-flag.png";
import orSeparator from "../../assets/or.svg";
import digiseqLogo from "../../assets/digiseq-logo.png";
import chevronRight from "../../assets/chevron-right.svg";

const BritishGas = () => {
  const [searchParams] = useSearchParams();
  const serialNumber = searchParams.get("serialNumber");

  const dispatch = useDispatch();
  const {
    balanceAndPin,
    isLoading,
    savingPersonalDetails,
    errorSavingPersonalDetails
  } = useSelector((state) => state.balanceAndPin);

  const { device, gettingDeviceInfo, gettingDeviceInfoError } = useSelector(
    (state) => state.hugoBoss
  );

  const [showForm, setShowForm] = useState(false);
  const [showPin, setShowPin] = useState(false);
  const [email, setEmail] = useState("");
  const [errors, setErrors] = useState({});

  const prevSavingPersonalDetails = usePrevious(savingPersonalDetails);

  useEffect(() => {
    if (!balanceAndPin && serialNumber) {
      dispatch(
        getBalanceAndPin({
          serial: serialNumber,
          query: "?provider=much_better"
        })
      );
    }
  }, [balanceAndPin, serialNumber, dispatch]);

  useEffect(() => {
    const claimedSerial = localStorage.getItem("bg-claimed");

    if (claimedSerial === serialNumber) {
      setShowPin(true);
    } else {
      setShowPin(false);
      localStorage.removeItem("bg-claimed");
    }
  }, [serialNumber]);

  useEffect(() => {
    dispatch(getDeviceInfo(serialNumber));
  }, [dispatch, serialNumber]);

  useEffect(() => {
    if (prevSavingPersonalDetails && !errorSavingPersonalDetails) {
      localStorage.setItem("bg-claimed", serialNumber);
      setShowForm(false);
      setShowPin(true);
      dispatch(getDeviceInfo(serialNumber));
    }
  }, [
    dispatch,
    errorSavingPersonalDetails,
    prevSavingPersonalDetails,
    serialNumber
  ]);

  const appStoreLinks = (appStoreUrl, playStoreUrl) => {
    return (
      <AppStoreLinks>
        <img
          src={appStoreLogo}
          alt="app-store"
          onClick={() => window.open(appStoreUrl, "_blank")}
        />
        <img
          src={gplayLogo}
          alt="play-store"
          onClick={() => window.open(playStoreUrl, "_blank")}
        />
      </AppStoreLinks>
    );
  };

  const validateForm = () => {
    let formErrors = {};

    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!email.trim()) {
      formErrors.email = "Email is required";
    } else if (!emailPattern.test(email)) {
      formErrors.email = "Please enter a valid email";
    }
    setErrors(formErrors);
    return Object.keys(formErrors).length === 0;
  };

  const handleSubmit = () => {
    if (validateForm()) {
      dispatch(
        savePersonalDetailsAction({
          serial: serialNumber,
          details: { email }
        })
      );
    }
  };

  if (isLoading || gettingDeviceInfo) return <Spinner />;

  return (
    <MainContainer>
      <Navbar>
        <img src={bgLogo} alt="british gas" />
        <div className="balance">
          <p>{"BALANCE"}</p>
          <p>
            {formattedBalance(
              balanceAndPin?.balanceDetails?.availableBalance,
              balanceAndPin?.balanceDetails?.currency
            )}
          </p>
        </div>
      </Navbar>
      <Intro>
        <h1>{"Here’s €230 to spend in Paris"}</h1>
        <p>
          {
            "We hope you’re as excited as we are about going to Paris 2024 this summer! As part of your package, we’re giving you € 230 (about £200) to spend while you’re there…."
          }
        </p>
        <p className="link">{"All about your contactless fob"}</p>
        <p>
          {
            "It’s really easy. We’ve already pre-loaded the fob with € 230 and you can use it anywhere that accepts Mastercard. Just tap your fob where you’d normally tap your debit card and you’re all good to go!"
          }
        </p>
        <div className="button" onClick={() => setShowForm(true)}>
          {device?.personalDetails?.email ? "Show PIN" : "Claim wearable"}
        </div>
        <p className="link">{"Need Help?"}</p>
        <p>
          {
            "If you have any questions about your fob, we're here to help! Just drop us an email at "
          }
          <a href="mailto:britishgas.paris2024@fuseint.com">
            {"britishgas.paris2024@fuseint.com"}
          </a>
        </p>

        <p>{"We hope you enjoy your stay! 🏅"}</p>
      </Intro>
      <PaymentAccount>
        <p className="title">Payment Account</p>
        <p className="subtitle">Prepaid</p>
        <div className="inner">
          <p>To set up this fob for contactless payments please click below</p>
        </div>
        <div
          className="button"
          onClick={() =>
            window.open(
              `https://a.api.muchbetter.com/merchant/user?trackingCode=digiseqcl`,
              "_blank"
            )
          }
        >
          {"Learn More"} <img src={chevronRight} alt="chevron right" />
        </div>
      </PaymentAccount>
      <NextStepsContainer>
        <CurvelogoContainer>
          <img className="separator" src={orSeparator} alt="or" />
          <p className="title">Link your own card</p>
          <img className="curve-logo" src={curveLogo} alt="curve logo" />
          <div className="flags">
            <img src={euFlag} alt="eu flag" />{" "}
            <img src={ukFlag} alt="uk flag" />
          </div>
        </CurvelogoContainer>
        <StepContainer>
          <StepNumber>1</StepNumber>
          <h2>Create a Curve Account</h2>
          <p>
            Register your card details with Curve to receive a free compatible
            digital bank card.
          </p>
          <p>Download the Curve App:</p>
          {appStoreLinks(
            "https://apps.apple.com/gb/app/curve-supercharge-your-money/id1049397112",
            "https://play.google.com/store/apps/details?id=com.imaginecurve.curve.prd"
          )}
        </StepContainer>
        <StepContainer>
          <StepNumber>2</StepNumber>
          <h2>Link Your Curve Card Using the Manage-Mii App</h2>
          <p>
            Follow the instructions on the Manage-Mii app and use your new Curve
            Card details when prompted.
          </p>
          {appStoreLinks(
            "https://apps.apple.com/app/manage-mii/id6446908503",
            "https://play.google.com/store/apps/details?id=com.digiseq.managemii"
          )}
        </StepContainer>
        <StepContainer>
          <StepNumber>3</StepNumber>
          <h2>Pay Anywhere!</h2>
          <p>
            Tap your wearable at any card terminal where Mastercard Contactless
            is enabled.
          </p>
          <img
            className="pay-anywhere"
            src={payAnyWhereImage}
            alt="pay anywhere"
          />
        </StepContainer>
      </NextStepsContainer>

      <Footer>
        <p>Powered by</p>
        <div className="companies">
          <img
            src={digiseqLogo}
            alt="digiseq-logo"
            onClick={() => window.open("https://www.digiseq.co.uk", "_blank")}
          />
        </div>
      </Footer>
      {showForm && (
        <Alert>
          <div className="inner-container">
            {device?.personalDetails?.email ? (
              <div className="form">
                <h2>Your PIN</h2>
                {showPin && balanceAndPin?.pinDetails?.pin ? (
                  <div className="pin">{balanceAndPin.pinDetails.pin}</div>
                ) : (
                  <p>
                    {
                      "Current phone cannot display PIN for this wearable. If you have cleared your browser cache or changed the phone - please contact customer support."
                    }
                  </p>
                )}
                <FormButton secondary onClick={() => setShowForm(false)}>
                  Close
                </FormButton>
              </div>
            ) : (
              <div className="form">
                <h2>Claim Wearable</h2>
                <p>Please provide your email to view your PIN.</p>
                <p>The pin will be viewable only on this phone.</p>
                <input
                  value={email}
                  id="email"
                  type="email"
                  onChange={(ev) => {
                    setEmail(ev.target.value);
                    setErrors({});
                  }}
                  placeholder={"Email"}
                />
                {errors.email && <p className="error">{errors.email}</p>}

                <FormButton onClick={() => handleSubmit()}>Claim</FormButton>
                <FormButton secondary onClick={() => setShowForm(false)}>
                  Cancel
                </FormButton>
              </div>
            )}
          </div>
        </Alert>
      )}
    </MainContainer>
  );
};

export default BritishGas;
