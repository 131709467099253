import styled from "styled-components";

export const theme = {
  colors: {
    backgroundOptions: "#f9f9fa",
    background: "#F6F6F7",
    divider: "#E0E0E0",
    highEmphasis: "#202020",
    medEmphasis: "#626262",
    surface: "#ffffff",
    primary: "#002D50"
  }
};

export const fonts = {
  B1: {
    fontFamily: "Manrope",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "16px",
    lineHeight: "24px",
    letterSpacing: "0.5px",
    color: theme.colors.highEmphasis
  },
  B2: {
    fontFamily: "Manrope",
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "16px",
    lineHeight: "24px",
    letterSpacing: "0.25px",
    color: theme.colors.highEmphasis
  },
  B3: {
    fontFamily: "soleil",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "16px",
    lineHeight: "24px",
    color: theme.colors.highEmphasis
  },
  B4: {
    fontFamily: "soleil",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "16px",
    lineHeight: "24px",
    color: theme.colors.highEmphasis
  },
  H2: {
    fontFamily: "Manrope",
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "24px",
    lineHeight: "32px",
    color: theme.colors.surface
  }
};

export const NavBar = styled.div`
  padding: 16px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: ${theme.colors.primary};
  img {
    height: 40px;
  }
  .view-balance {
    cursor: pointer;
  }

  color: ${theme.colors.surface};
`;

export const H2 = styled.h2`
  ${fonts.H2}
`;

export const B1 = styled.p`
  ${fonts.B1}
`;

export const B2 = styled.p`
  ${fonts.B2}
`;

export const B3 = styled.p`
  ${fonts.B3}
`;

export const B4 = styled.p`
  ${fonts.B4}
`;

export const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 480px;
  margin: auto;
  min-height: 100vh;
  background-color: ${theme.colors.surface};

  .or {
    ${fonts.B1}
    text-align: center;
    margin-bottom: 16px;
  }
`;

export const SectionContainer = styled.section`
  background-color: ${theme.colors.primary};
  .title-bar {
    padding: 16px;
    display: flex;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    text-transform: uppercase;

    img {
      cursor: pointer;
    }
  }
  .content {
    background-color: ${theme.colors.surface};
  }
`;

export const Welcome = styled.div`
  text-align: left;
  margin-bottom: -5px;
  h1 {
    background-color: ${theme.colors.primary};
    ${fonts.H2}
    color:${theme.colors.surface};
    padding: 16px;
  }

  img {
    width: 100%;
    object-fit: contain;
  }
`;

export const Details = styled.div`
  display: flex;
  flex-direction: column;
  padding: 24px 16px;
`;

export const Link = styled.a`
  ${fonts.B4}
  text-decoration: underline;
  cursor: pointer;
`;

export const Item = styled.div`
  background-color: ${theme.colors.background};
  padding: 16px;
  border-bottom: ${({ isLast }) =>
    isLast ? "none" : `1px solid ${theme.colors.divider}`};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  .title {
    ${fonts.B2}
  }

  color: ${theme.colors.highEmphasis};
`;

export const ImageContainer = styled.div`
  padding-top: 0px;
  padding-bottom: 0px;
  margin: 0;

  img {
    display: block;
    max-width: 100%;
    height: auto;
  }
`;

export const ImagesContainer = styled.div`
  padding-top: 20px;
  padding-bottom: 20px;
`;

export const Hospitality = styled.div`
  padding: 24px 16px;
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const HospitalityItem = styled.div`
  border-radius: 4px;
  overflow: hidden;
  padding: 30px 24px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  cursor: pointer;
  background-image: url(${({ img }) => img});
  background-size: cover;
  background-repeat: no-repeat;

  .title {
    ${B2}
    color: ${theme.colors.surface};
  }
`;

export const EventInformation = styled.div`
  padding: 16px;
`;

export const Membership = styled.div`
  .intro {
    padding: 16px;
  }
  .link-container {
    padding: 16px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border-top: 1px solid ${theme.colors.divider};
    cursor: pointer;
  }

  .details {
    display: flex;
    flex-direction: column;
    gap: 4px;
  }
`;

export const Help = styled.div`
  padding: 16px;
`;

export const Download = styled.div`
  padding: 16px;

  img {
    cursor: pointer;
    display: block;
    margin-bottom: 10px;
  }
`;

export const Footer = styled.div`
  background: ${theme.colors.primary};
  padding: 24px 16px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  justify-content: center;
  align-items: center;

  p {
    margin-bottom: 8px;
    color: white;
  }

  img {
    height: auto;
    margin-bottom: 8px;
    cursor: pointer;
    width: auto;
  }

  .digiseq {
    height: 24px;
  }
`;
