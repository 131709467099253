import styled from "styled-components";

export const Container = styled.div`
  height: 50px;
  margin-bottom: 16px;
  margin-right: 30px;
  display: flex;
  flex-direction: row;
  & :nth-child(1) {
    margin-right: 16px;
  }
  img {
    cursor: pointer;
  }
`;
