import React, { useState } from "react";
import { Dropdown, Header, MoreDetails } from "./styled/SharedStyles";

import cagedSteelLogo from "./img/cs37/cs37-logo.webp";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getBalanceAndPin } from "../../features/balance-and-pin/balanceAndPinSlice";

import chevronDown from "./img/chevron-down.svg";
import chevronUp from "./img/chevron-up.svg";
import appStore from "./img/app-store.svg";
import playStore from "./img/playstore.svg";

const Navbar = () => {
  const params = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { serial } = params;

  const [showMore, setShowMore] = useState(false);

  const { balanceAndPin, isLoading } = useSelector(
    (state) => state.balanceAndPin
  );

  /*
  useEffect(() => {
    if (!balanceAndPin && serial) {
      dispatch(getBalanceAndPin(serial));
    }
  }, [balanceAndPin, serial, dispatch]);
*/

  const formattedBalance = (balanceDetails) => {
    const { availableBalance, currency } = balanceDetails;
    let iconPrefixed = true;
    let icon = "";

    switch (currency) {
      case "GBP":
        icon = "£";
        break;
      case "EUR":
        icon = "€";
        break;
      case "USD":
        icon = "$";
        break;
      default:
        icon = "";
    }

    if (iconPrefixed) {
      return icon + availableBalance;
    } else {
      return availableBalance + icon;
    }
  };

  return (
    <Header>
      {showMore && (
        <Dropdown>
          <MoreDetails>
            {/* <p>Create a Prepaid Account to load money on to your wearable.</p>
            <p
              className="create-account-button"
              onClick={() =>
                window.open("https://get.muchbetter.com/mastercard", "_blank")
              }
            >
              Create prepaid account
            </p>
           */}
            <p>Download the Manage-Mii app to start managing your wearables.</p>
            <div className="app-store-links">
              <img
                src={appStore}
                alt="app-store"
                onClick={() =>
                  window.open(
                    "https://apps.apple.com/gb/app/manage-mii/id6446908503",
                    "_blank"
                  )
                }
              />
              <img
                src={playStore}
                alt="play-store"
                onClick={() =>
                  window.open(
                    "https://play.google.com/store/apps/details?id=com.digiseq.managemii",
                    "_blank"
                  )
                }
              />
            </div>
          </MoreDetails>
        </Dropdown>
      )}
      <div
        className="logo"
        onClick={() => navigate(`/caged-steel-promo/${serial}`)}
      >
        <img src={cagedSteelLogo} alt="logo" />
      </div>
      {/* <div>
        <div className="balance-txt">Balance</div>
        <div>
          {balanceAndPin?.balanceDetails?.availableBalance
            ? formattedBalance(balanceAndPin?.balanceDetails)
            : "N/A"}
        </div>
      </div> */}
      {/* <div className="more" onClick={() => setShowMore(!showMore)}>
        More <img src={showMore ? chevronUp : chevronDown} alt="chevron-down" />
      </div> */}
    </Header>
  );
};

export default Navbar;
