import styled from "styled-components";

const B1 = {
  fontFamily: "Mark For MC Narrow W00 Book",
  fontStyle: "normal",
  fontWeight: 400,
  fontSize: "16px",
  lineHeight: "24px",
  letterSpacing: "0.5px",
  color: "#CCCCCC"
};

const B2 = {
  //bold/button
  fontFamily: "Mark For MC Narrow W00 Bold",
  fontStyle: "normal",
  fontWeight: 700,
  fontSize: "16px",
  lineHeight: "24px",
  letterSpacing: "0.25px",
  color: "#ffffff"
};

const B3 = {
  fontFamily: "Mark For MC Narrow W00 Book",
  fontStyle: "normal",
  fontWeight: 400,
  fontSize: "20px",
  lineHeight: "24px",
  letterSpacing: "130%",
  color: "#ffffff"
};

const B4 = {
  //footer text
  fontFamily: "Mark For MC Narrow W00 Book",
  fontStyle: "normal",
  fontWeight: 450,
  fontSize: "12px",
  lineHeight: "24px",
  letterSpacing: "0.5px",
  color: "#FFFFFF"
};

const H2 = {
  fontFamily: "Mark For MC Narrow W00 Book",
  fontStyle: "normal",
  fontWeight: 400,
  fontSize: "28px",
  lineHeight: "32px",
  color: "#FFFFFF"
};

export const NavBar = styled.div`
  color: white;
  padding: 8px 16px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: #282827;
  margin-bottom: -16px;
  ${B1}
  img {
    height: 40px;
  }

  .view-balance {
    padding: 4px 8px;
    cursor: pointer;
  }
`;

export const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 480px;
  margin: auto;
  min-height: 100vh;
  background-color: #000000;
  gap: 16px;
`;

export const SectionContainer = styled.section`
  padding: 24px 16px;
  background-color: #282827;
  margin: 0px 16px;
  border-radius: 6px;

  .title-bar {
    display: flex;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    h2 {
      ${H2}
      color: white;
    }
    img {
      cursor: pointer;
    }
  }
`;

export const Hero = styled.div`
  img {
    width: 100%;
    height: auto;
    object-fit: contain;
    aspect-ratio: 390/320;
  }
`;

export const GreenManifesto = styled.div`
  padding: 24px 16px;
  background-color: #282827;
  margin: 0px 16px;
  border-radius: 6px;

  h2 {
    ${H2};
    margin-bottom: 16px;
  }
  p {
    ${B1}
    margin-bottom: 16px;
  }

  .collapsable {
    text-decoration: underline;
    cursor: pointer;
  }
`;

export const MyEvent = styled.div`
  padding-top: 24px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  p {
    ${B1}
    margin: 16px 0;
  }

  .location-container {
    background-color: #3d3d3c;
    border-radius: 5px;
    display: grid;
    grid-template-columns: 55% 45%;
    text-align: center;
    .court,
    .seat {
      padding: 8px 32px;
    }
    .court {
      border-right: 1px solid #ccc;
    }
  }

  .banners {
    display: flex;
    flex-direction: row;
    gap: 16px;
  }

  .refill,
  .stats {
    text-align: center;
    border-radius: 5px;
    overflow: hidden;
    position: relative;
    background-color: #3d3d3c;
    img {
      object-fit: contain;
      width: 100%;
    }

    p {
      margin: 0 0 8px;
    }

    .stats-value {
      position: absolute;
      top: 8px;
      left: 50%;
      transform: translate(-50%, 0%);
      ${H2}
    }
  }
`;

export const MyBluewater = styled.div`
  padding-top: 16px;
  display: flex;
  flex-direction: column;
  gap: 16px;

  p {
    ${B1}
  }

  .link {
    cursor: pointer;
    background-color: #3d3d3c;
    padding: 16px;
    border-radius: 4px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    span {
      color: red;
      vertical-align: 20%;
      margin-right: 16px;
      ${B3}
    }

    p {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      gap: 8px;
      img {
        object-fit: contain;
        height: 32px;
      }
    }
  }
  .stats {
    background-color: #3d3d3c;
    padding: 16px;
    border-radius: 4px;

    h3 {
      ${B3}
    }
    .stats-container {
      display: flex;
      text-align: center;

      & > div {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        padding: 16px 0px;

        span {
          ${H2}
        }
      }
    }
  }
`;

export const OrderOfPlay = styled.div`
  padding-top: 16px;
  display: flex;
  flex-direction: column;
  gap: 16px;

  .court {
    ${B1}
    color: white;
  }

  .game {
    padding: 8px 16px;
    display: flex;
    justify-content: space-between;
    background-color: #3d3d3c;
    flex-direction: row;

    p {
      ${B1}
    }

    .category {
      color: #f37439;
    }

    .col {
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      align-items: center;
      gap: 16px;
      text-align: center;

      img {
        width: 27px;
        height: 18px;
      }
    }
  }
`;

export const Footer = styled.div`
  background-color: #282827;
  margin-top: 8px;
  padding: 24px 16px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  justify-content: center;
  align-items: center;

  p {
    ${B4}
  }

  img {
    height: 24px;
    margin-bottom: 8px;
    cursor: pointer;
  }

  .companies {
    padding: 0 40px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
  }
`;
