import React, { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";

import { getBalanceAndPin } from "../../features/balance-and-pin/balanceAndPinSlice";

import Spinner from "../../components/Spinner";
import {
  MainContainer,
  NavBar,
  Footer,
  B1,
  B2,
  H2
} from "./styles/RugbyWcLandRover";

import logo from "./img/logo.webp";
import digiseqLogo from "./img/digiseq-logo.webp";

const RugbyWcLandRover = () => {
  const [searchParams] = useSearchParams();
  const serialNumber = searchParams.get("serialNumber");
  const dispatch = useDispatch();

  const { balanceAndPin, isLoading } = useSelector(
    (state) => state.balanceAndPin
  );

  useEffect(() => {
    if (!balanceAndPin && serialNumber) {
      dispatch(
        getBalanceAndPin({ serial: serialNumber, query: "?provider=tokenised" })
      );
    }
  }, [balanceAndPin, serialNumber, dispatch]);

  const formattedBalance = (balanceDetails) => {
    const { availableBalance, currency } = balanceDetails;
    let iconPrefixed = true;
    let icon = "";

    switch (currency) {
      case "GBP":
        icon = "£";
        break;
      case "EUR":
        icon = "€";
        break;
      case "USD":
        icon = "$";
        break;
      default:
        icon = "";
    }

    if (iconPrefixed) {
      return icon + availableBalance;
    } else {
      return availableBalance + icon;
    }
  };

  if (isLoading) return <Spinner />;

  return (
    <MainContainer>
      <NavBar>
        <img src={logo} alt="hugo boss logo" className="logo" />
        <div className="balance-container">
          <B1>
            Balance:{" "}
            {balanceAndPin?.balanceDetails?.availableBalance
              ? formattedBalance(balanceAndPin?.balanceDetails)
              : "N/A"}
          </B1>
        </div>
      </NavBar>

      <Footer>
        <H2>{"ENJOY THE MATCH!"}</H2>
        <B2>{"Your fob is subject to the local contactless limit."}</B2>
        <B2>
          {
            "Having issues? Please get in touch with your assigned DEFENDER chaperone."
          }
        </B2>
        {/* <a href="tel:+447929673674">
          <B1>{"+44 (07929) 673674"}</B1>
        </a>
        <a href="mailto:rhiannon.davies@csm.com">
          <B1>{"rhiannon.davies@csm.com"}</B1>
        </a> */}
        <div className="powered-by">
          <B2>{"Powered by"}</B2>
          <img src={digiseqLogo} alt="digiseq logo" />
        </div>
      </Footer>
    </MainContainer>
  );
};

export default RugbyWcLandRover;
