import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  getCampaigns,
  resetCampaign,
  resetError
} from "../../features/campaign-manager/campaignManagerSlice";
import {
  getDasDevicesBySerial,
  getDasDevicesByTag,
  resetDasDevices,
  transferTaggedDasDevices,
  resetError as resetDevicesError
} from "../../features/das-device-manager/dasDeviceManagerSlice";
import { useClickOutside } from "../../hooks/outsideClick";
import ProtectedRoute from "../../components/ProtectedRoute";
import NavBarComponent from "./components/NavBar";
import DetailsItem from "./components/DetailsItem";
import DropdownSelect from "./components/DropdownSelect";
import { Button, Input } from "./components/styled/SharedStyles";
import {
  DevicesBadge,
  Option,
  Select,
  SelectContainer,
  SelectOptions,
  SelectSection,
  TransferDevices
} from "./styled/CampaignDevices";
import { Body, ErrorMsg, MainContainer } from "./styled/SharedStyles";
import { DetailsContainer } from "./styled/Campaign";
import { StyledSpinner } from "../../components/styled/Spinner";

import chevronDown from "./img/chevron-down-white.svg";

const SearchDevices = () => {
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const paramsSerial = searchParams.get("serial");
  const [selectedOption, setSelectedOption] = useState("Serial");
  const [showOptions, setShowOptions] = useState(false);
  const [serial, setSerial] = useState(paramsSerial || "");
  const [tag, setTag] = useState("");

  const [toCampaignId, setToCampaignId] = useState("");
  const [toCampaignName, setToCampaignName] = useState("");
  const [showCampaigns, setShowCampaigns] = useState(false);
  const [showTransferDevices, setShowTransferDevices] = useState(false);

  const wrapperRef = useRef("select");
  useClickOutside(wrapperRef, () => {
    if (showOptions) {
      setShowOptions(false);
    }
  });

  const { campaigns, isLoading, isError, message } = useSelector(
    (state) => state.campaigns
  );

  const {
    devicesTransferred,
    devices,
    device,
    isLoading: isLoadingDevices,
    isError: isErrorDevices,
    message: messageDevices
  } = useSelector((state) => state.dasDevices);

  const handleTransferDevices = () => {
    dispatch(transferTaggedDasDevices({ tag, toCampaignId }));
  };

  const handleFindDevices = () => {
    setShowTransferDevices(false);
    dispatch(resetDasDevices());
    dispatch(resetError());
    dispatch(resetDevicesError());
    switch (selectedOption) {
      case "Serial":
        dispatch(getDasDevicesBySerial(serial));
        return;
      case "Tag":
        dispatch(getDasDevicesByTag(tag));
        return;
      default:
        return;
    }
  };

  const getValue = () => {
    switch (selectedOption) {
      case "Serial":
        return serial;
      case "Tag":
        return tag;
      default:
        return null;
    }
  };

  const handleOnChange = (ev) => {
    switch (selectedOption) {
      case "Serial":
        setSerial(ev.target.value);
        return;
      case "Tag":
        setTag(ev.target.value);
        return;
      default:
        return;
    }
  };

  useEffect(() => {
    if (!campaigns) {
      dispatch(getCampaigns());
    }
  }, [campaigns, dispatch]);

  useEffect(() => {
    return () => {
      dispatch(resetDasDevices());
      setSerial("");
      setTag("");
      dispatch(resetError());
      dispatch(resetDevicesError());
    };
  }, [dispatch]);

  useEffect(() => {
    if (devicesTransferred) {
      setShowTransferDevices(false);
      dispatch(resetDasDevices());
      setToCampaignName("");
      setToCampaignId("");
      dispatch(resetCampaign());
      dispatch(getDasDevicesByTag(tag));
    }
  }, [devicesTransferred, dispatch, tag]);

  useEffect(() => {
    if (paramsSerial && !device?.serial) {
      dispatch(getDasDevicesBySerial(paramsSerial));
    }
  }, [device?.serial, dispatch, paramsSerial]);

  return (
    <MainContainer>
      <ProtectedRoute />
      <NavBarComponent
        leftText={"Back"}
        hasBackArrow
        title={"Search"}
        to={"/devices-manager/"}
      />
      <Body>
        <SelectSection>
          <Input
            placeholder={`Enter ${selectedOption}`}
            value={getValue()}
            onChange={(ev) => {
              handleOnChange(ev);
            }}
          />
          <SelectContainer ref={wrapperRef}>
            <Select
              onClick={() => {
                setShowOptions(!showOptions);
                setShowCampaigns(false);
                dispatch(resetError());
                dispatch(resetDevicesError());
              }}
            >
              {selectedOption}
              <img src={chevronDown} alt="chevron down" />
            </Select>

            {showOptions && (
              <SelectOptions>
                <Option
                  onClick={() => {
                    setTag("");
                    setSelectedOption("Serial");
                    setShowOptions(!showOptions);
                  }}
                >
                  Serial
                </Option>
                <Option
                  isLast
                  onClick={() => {
                    setSerial("");
                    setSelectedOption("Tag");
                    setShowOptions(!showOptions);
                  }}
                >
                  Tag
                </Option>
              </SelectOptions>
            )}
          </SelectContainer>
        </SelectSection>

        {(serial || tag) && (
          <Button onClick={() => handleFindDevices()}>Search</Button>
        )}
        <br />
        {devices?.count >= 0 && !serial && devices?.tag === tag && (
          <DevicesBadge>{`${devices?.count} Devices`}</DevicesBadge>
        )}

        {(device?.currentCampaign || device?.tag) &&
          serial.toLowerCase() === device.serialNumber.toLowerCase() && (
            <>
              <p>Serial No: {device.serialNumber}</p>
              <DetailsContainer>
                <DetailsItem
                  name="Current Campaign"
                  detail={device?.currentCampaign}
                  onClick={() =>
                    navigate(`/devices-manager/${device.serialNumber}/edit`, {
                      state: { field: "Campaign" }
                    })
                  }
                />
                <DetailsItem
                  name="Tags"
                  detail={device?.tag}
                  onClick={() =>
                    navigate(`/devices-manager/${device.serialNumber}/edit`, {
                      state: { field: "Tags" }
                    })
                  }
                />
                {device?.currentCampaign === "manual" && (
                  <DetailsItem
                    name="Campaign Links"
                    onClick={() =>
                      navigate(
                        `/devices-manager/${device.serialNumber}/links`,
                        {
                          state: {
                            links: device.campaignLinks
                          }
                        }
                      )
                    }
                  />
                )}
              </DetailsContainer>
            </>
          )}

        {devices?.campaigns?.length > 0 && tag === devices?.tag && (
          <>
            <p>
              Campaigns with <b>{devices.tag}</b> tag
            </p>
            <DetailsContainer>
              {devices?.campaigns.map((e, i) => (
                <DetailsItem key={i} name="Campaign Id" detail={e} />
              ))}
            </DetailsContainer>
            {!showTransferDevices && (
              <Button onClick={() => setShowTransferDevices(true)}>
                Transfer Devices
              </Button>
            )}
            {showTransferDevices && (
              <TransferDevices
                className={showTransferDevices && "open slide-down"}
              >
                <p>To Campaign:</p>
                <Input
                  placeholder="Destination Campaign"
                  onFocus={() => {
                    setShowCampaigns(true);
                  }}
                  hasDropdown={showCampaigns && !toCampaignId}
                  value={toCampaignName}
                  onChange={(ev) => {
                    setToCampaignName(ev.target.value.toLowerCase());
                    setToCampaignId("");
                  }}
                />
                {campaigns?.length > 0 && showCampaigns && !toCampaignId && (
                  <DropdownSelect
                    onSelect={(e) => {
                      setToCampaignName(e.name);
                      setToCampaignId(e.id);
                    }}
                    listItems={campaigns.filter((e) =>
                      e.name
                        .toLowerCase()
                        .includes(toCampaignName.toLowerCase())
                    )}
                  />
                )}
                <Button
                  disabled={!toCampaignId}
                  onClick={() => handleTransferDevices()}
                >
                  Transfer devices
                </Button>
              </TransferDevices>
            )}
          </>
        )}

        {(isError || isErrorDevices) && (
          <ErrorMsg>
            {message ||
              messageDevices ||
              "An error has occured, please try again later or contact support."}
          </ErrorMsg>
        )}
        {(isLoading || isLoadingDevices) && <StyledSpinner />}
      </Body>
    </MainContainer>
  );
};

export default SearchDevices;
