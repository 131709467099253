export const SCS_DATA = {
  digiseq: {
    title: "Who Are",
    titleHl: "DIGISEQ",
    p1: "DIGISEQ is an award-winning IoT service platform that securely provisions data into everyday wearables; enabling items, from rings to clothing, to deliver NFC payment, customer engagement functionality, access control and digital identity. We connect the ecosystem, serving as a centrepiece between banks, product creators, chip manufacturers and card bureaux, so that wearables can be simply and securely delivered into the hands of consumers.",
    sectionTitle: "Delivering the highest level of Security",
    p2: "DIGISEQ’s RCOS™ over-the-air mobile personalisation and tokenisation service is leading the way in adopting the new SCP03 standard, ensuring all provisioned payment data is fully protected to the highest available standards, while continuing to deliver the fastest self-provisioning service in the industry. SCP03 protocol supports AES (Advanced Encryption Standard) based cryptography in lieu of TDEA (Triple Data Encryption Algorithm). The protocol protects bidirectional communication between the host and cards, mobile wallets and wearables. Click here to download our Press Release.",
    p3: "We also adhere to PSD2 directive for consumer initiated electronic payments, which mandates two factor authentication outside of the normal card present exemptions. With the support of online PIN, a transaction that exceeds the local contactless value or consecutive/cumulative transaction limit, will prompt the terminal to request the customer to enter the PIN (of the original card) therefore providing the exact same user experience as that of a card transaction. Click here to download our commissioned White Paper to."
  },
  smartchip: {
    title: "Who Are",
    titleHl: "Smart Chip",
    p1: "Smart Chip, powered by DIGISEQ, has developed, and patented the world’s first NFC manicure with an RFID chip and is preparing to launch in 2024. The Smart Chip is applied to your nail at an affiliated salon. With your Smart Chip you embody embedded finance. Link your nail to your payment card using the DIGISEQ Manage-Mii mobile app and you’re ready to go.",
},
  footer: {
    title: "DIGISEQ.",
    subtitle: "Revolutionising the",
    subtitle2: "Way We Pay.",
    link1: { title: "Our website", url: "https://www.digiseq.co.uk/" },
    link2: {
      title: "Privacy Policy",
      url: "https://www.digiseq.co.uk/privacy"
    },
    link3: {
      title: "Terms and Conditions",
      url: "https://s3.eu-west-2.amazonaws.com/www.digiseq.co.uk/ManageMiiTerms.html"
    },
    link4: {
      title: "Wearables and PSD2 SCA",
      url: "https://www.digiseq.co.uk/whitepapers/2023/4/25/wearables-and-psd2-sca"
    },
    socials: {
      facebook: {
        icon: require("./img/facebook.svg"),
        url: "https://www.instagram.com/digiseq_ltd/"
      },
      linkedin: {
        icon: require("./img/linkedin.svg"),
        url: "https://www.linkedin.com/company/digiseq/mycompany/"
      },
      twitter: {
        icon: require("./img/twitter.svg"),
        url: "https://twitter.com/digiseqUK"
      }
    }
  }
};
