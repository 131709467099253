import React, { useState, useEffect } from "react";

import {
  IncrementButton,
  IncrementValue,
  UnlimitedContainer,
} from "./styled/Counter";

const Counter = ({ onCountChange, onUnlimitedChange }) => {
  const [count, setCount] = useState(1);
  const [isUnlimited, setIsUnlimited] = useState(false);

  useEffect(() => {
    onCountChange(count);
  }, [count, onCountChange]);

  useEffect(() => {
    onUnlimitedChange(isUnlimited);
  }, [isUnlimited, onUnlimitedChange]);

  const increment = () => {
    if (!isUnlimited) {
      setCount(count + 1);
    }
  };

  const decrement = () => {
    if (!isUnlimited && count > 0) {
      setCount(count - 1);
    }
  };

  const handleCheckboxChange = () => {
    setIsUnlimited(!isUnlimited);
  };

  return (
    <div>
      <IncrementButton
        onClick={decrement}
        disabled={isUnlimited || count === 0}
      >
        -
      </IncrementButton>
      <IncrementValue>{isUnlimited ? "\u221E" : count}</IncrementValue>
      <IncrementButton onClick={increment} disabled={isUnlimited}>
        +
      </IncrementButton>
      <UnlimitedContainer>
        <label>
          <input
            type="checkbox"
            checked={isUnlimited}
            onChange={handleCheckboxChange}
          />
          Unlimited
        </label>
      </UnlimitedContainer>
    </div>
  );
};

export default Counter;
