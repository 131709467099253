import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
  getEvent,
  hardResetEventData,
  resetEvent,
} from "../../features/event-manager/eventManagerSlice";

import Spinner from "../../components/Spinner";
import NavBarComponent from "../campaign-manager/components/NavBar";
import ProtectedRoute from "../../components/ProtectedRoute";
import {
  EventNameContainer,
  DetailsHeader,
  EventHeaderContainer,
  ImageContainer,
  Image,
  DetailsContainer,
} from "./styled/Event";

import {
  Body,
  ErrorMsg,
  MainContainer,
  ActionButton,
} from "./styled/SharedStyles";
import eventDefaultIcon from "./img/campaign-default-icon.svg";
import DetailsItem from "../campaign-manager/components/DetailsItem";
import { Button } from "../campaign-manager/components/styled/SharedStyles";

const Event = () => {
  const dispatch = useDispatch();
  const params = useParams();

  const { event, isLoading, isError, message } = useSelector(
    (state) => state.events
  );

  useEffect(() => {
    if (!event) {
      dispatch(getEvent(params.eventId));
    }
  }, [event, dispatch, params.eventId]);

  useEffect(() => {
    return () => {
      dispatch(resetEvent());
    };
  }, [dispatch]);

  function handleHardReset() {
    dispatch(hardResetEventData(params.eventId));

    window.scrollTo({ top: 0, behavior: "auto" });
  }

  if (isLoading) return <Spinner />;

  return (
    <MainContainer>
      <ProtectedRoute />
      <NavBarComponent leftText="Events" hasBackArrow to="/event-manager" />
      <Body>
        <EventHeaderContainer>
          <ImageContainer>
            <Image src={eventDefaultIcon} alt="event icon" />
          </ImageContainer>
        </EventHeaderContainer>
        <EventNameContainer>
          <div>{event?.name}</div>
        </EventNameContainer>
        <DetailsHeader>Details</DetailsHeader>
        {event?.id && (
          <DetailsContainer>
            <DetailsItem name="Id" detail={event.entityId} />
            <DetailsItem name="Name" detail={event.name} />
            <DetailsItem name="Event Code" detail={event.eventCode} />
            <DetailsItem name="Type" detail={event.type} />
            <DetailsItem
              isLast
              name="Description"
              detail={event?.description}
              lines={(event?.description?.length / 35) * 50}
            />
          </DetailsContainer>
        )}
        <Button onClick={() => handleHardReset()}>Hard Reset</Button>
        {isError && (
          <ErrorMsg>
            {message ||
              "Oh no, we have an error, please refresh page or try again later"}
          </ErrorMsg>
        )}
      </Body>
    </MainContainer>
  );
};

export default Event;
