import React, { useState, useEffect } from "react";
import i18n from "i18next";
import { LanguageSelector } from "./styles/ArosaClassicCar";

import chevronDownWhite from "./img/chevron-down-white.svg";
import chevronUpWhite from "./img/chevron-up-white.svg";
import invChevronUp from "./img/chevron-up.svg";

const LanguageSelect = () => {
  const [lang, setLang] = useState(i18n.language || "de");
  const [select, setSelect] = useState(false);

  useEffect(() => {
    i18n.changeLanguage("de");
    setLang("de");
  }, []);

  useEffect(() => {
    const handleLanguageChanged = (lng) => {
      setLang(lng);
    };

    i18n.on("languageChanged", handleLanguageChanged);

    return () => {
      i18n.off("languageChanged", handleLanguageChanged);
    };
  }, []);

  const handleLanguageChange = (language) => {
    setLang(language);
    i18n.changeLanguage(language);
    setSelect(false);
  };

  return (
    <LanguageSelector>
      {!select ? (
        <div onClick={() => setSelect(!select)} role="button" tabIndex={0}>
          <span>{lang === "en" ? "EN" : "DE"}</span>
          <img src={select ? chevronUpWhite : chevronDownWhite} alt="chevron" />
        </div>
      ) : (
        <div>
          <div
            onClick={() => handleLanguageChange("en")}
            role="button"
            tabIndex={0}
          >
            <span>{"EN"}</span>
            <img src={chevronUpWhite} alt="chevron" />
          </div>
          <div
            onClick={() => handleLanguageChange("de")}
            role="button"
            tabIndex={0}
          >
            <span>{"DE"}</span>
            <img src={invChevronUp} alt="chevron" />
          </div>
        </div>
      )}
    </LanguageSelector>
  );
};

export default LanguageSelect;
