import styled from "styled-components";

export const H1 = styled.h1`
  text-align: center;
  font-feature-settings: "clig" off, "liga" off;
  /* Headline/Large */
  font-family: "Inter";
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 40px; /* 125% */
`;

export const Navbar = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 8px 16px;
  border-bottom: 1px solid #000;

  img {
    width: 37px;
    height: 22.664px;
    flex-shrink: 0;
  }

  .balance {
    color: #004976;
    font-feature-settings: "clig" off, "liga" off;
    /* Title */
    font-family: "Inter";
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px; /* 150% */
    letter-spacing: 0.15px;
  }
`;

export const Hero = styled.div`
  padding: 32px 24px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;
  border-bottom: 1px solid #000;

  h1,
  p {
    text-align: center;
    color: #004976;
  }

  img {
    width: 100%;
  }
`;

export const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 480px;
  margin: auto;
  min-height: 100vh;
  background-color: #fff;

  p {
    font-family: "Inter", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
  }

  a {
    text-decoration: underline;
    cursor: pointer;
  }
`;

export const PaymentAccount = styled.div`
  padding: 32px 24px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 16px;
  border-bottom: 1px solid #000;

  .inner {
    padding: 24px 16px;
    display: flex;
    gap: 8px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
  }

  .button {
    color: white;
    cursor: pointer;
    display: inline-flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 8px 8px 8px 16px;
    font-style: normal;
    background: #f37439;
    border-radius: 360px;
    margin-bottom: 8px;
    img {
      margin-bottom: -4px;
    }
  }

  h1,
  p {
    text-align: left;
    color: #000;
  }

  .logo {
    width: 189px;
    height: 60px;
  }

  .stores {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    gap: 8px;
    img {
      cursor: pointer;
    }
  }
`;

export const ManageMii = styled.div`
  padding: 32px 24px 64px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  border-bottom: 1px solid black;

  h1,
  p {
    text-align: left;
    color: #000;
  }

  .stores {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    img {
      cursor: pointer;
    }
  }
`;

export const ActivationCode = styled.div`
  display: flex;
  padding: 12px 16px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  border-radius: 6px;
  border: 1px solid #000;
  background: #fff;
  color: #000;
  font-feature-settings: "clig" off, "liga" off;
  /* Headline/Medium */
  font-family: "Inter";
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: 36px; /* 128.571% */
  color: #000;
`;

export const Footer = styled.div`
  padding: 24px 16px 48px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  justify-content: center;
  align-items: center;

  p {
    font-size: 12px;
    font-weight: 400;
  }
  img {
    margin-bottom: 8px;
    height: 24px;
  }
`;
