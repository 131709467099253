import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";

import { getBalanceAndPin } from "../../features/balance-and-pin/balanceAndPinSlice";

import {
  MainContainer,
  SpinnerContainer,
  Header,
  TitleText,
  MessageText,
  DisplayGroup,
  DisplayField,
  ChristmasGlobe
} from "../balance-and-pin-mb-christmas/styled/BalanceAndPinMuchBetterChristmasStyles";

import Spinner from "../../components/Spinner";

import digiseqLogo from "./img/digiseq-logo.svg";
import christmasGlobe from "../balance-and-pin-mb-christmas/img/christmas-globe.png";
import Eye from "./img/eye.svg";
import EyeOff from "./img/eye-off.svg";

const BalanceAndPin = () => {
  const [searchParams] = useSearchParams();
  const serialNumber = searchParams.get("serialNumber");

  const [showPin, setShowPin] = useState(false);

  const dispatch = useDispatch();
  const { balanceAndPin, isLoading } = useSelector(
    (state) => state.balanceAndPin
  );

  useEffect(() => {
    if (!balanceAndPin && serialNumber) {
      dispatch(getBalanceAndPin({ serial: serialNumber }));
    }
  }, [balanceAndPin, serialNumber, dispatch]);

  if (isLoading)
    return (
      <SpinnerContainer>
        <Spinner />
      </SpinnerContainer>
    );

  const maskedPin = (pin, showPin) => {
    if (showPin) {
      return " " + pin;
    } else return " ****";
  };

  const formattedBalance = (balanceDetails) => {
    const { availableBalance, currency } = balanceDetails;
    let iconPrefixed = true;
    let icon = "";

    switch (currency) {
      case "GBP":
        icon = "£";
        break;
      case "EUR":
        icon = "€";
        break;
      case "USD":
        icon = "$";
        break;
      default:
        icon = "";
    }

    if (iconPrefixed) {
      return icon + availableBalance;
    } else {
      return availableBalance + icon;
    }
  };

  return (
    <div>
      <MainContainer>
        <Header>
          <img src={digiseqLogo} alt="logo" />
        </Header>
        <ChristmasGlobe>
          <img src={christmasGlobe} alt="logo" />
        </ChristmasGlobe>

        <TitleText>Season’s Greetings</TitleText>
        <MessageText>
          Please enjoy this festive fob as a one-off payment to help with the
          cost of living this holiday season
        </MessageText>

        <DisplayGroup>
          <b>Balance</b>
          <DisplayField>
            {balanceAndPin?.balanceDetails?.availableBalance
              ? formattedBalance(balanceAndPin?.balanceDetails)
              : "N/A"}
          </DisplayField>
        </DisplayGroup>

        <DisplayGroup>
          <b>PIN Number</b>
          <DisplayField>
            {balanceAndPin?.pinDetails?.pin
              ? maskedPin(balanceAndPin?.pinDetails?.pin, showPin)
              : "  N/A"}
            {showPin ? (
              <img
                src={EyeOff}
                alt="logo"
                onClick={() => setShowPin(!showPin)}
              />
            ) : (
              <img src={Eye} alt="logo" onClick={() => setShowPin(!showPin)} />
            )}
          </DisplayField>
        </DisplayGroup>
      </MainContainer>
    </div>
  );
};

export default BalanceAndPin;
