import React, { useEffect, useRef } from "react";

import { useMediaQuery } from "react-responsive";
import { useSelector, useDispatch } from "react-redux";

import {
  Brand,
  BrandImage,
  Brands,
  Container,
  SliderHeaderContainer
} from "./styled/ExploreTheBrands";

import { getEntities } from "../../../features/entities/entitySlice";
import SliderArrows from "../../../components/SliderArrows";
import { Link } from "react-router-dom";

const BrandPreview = ({ image, name }) => {
  return (
    <Brand>
      <BrandImage>
        <img src={image} alt={`${name} preview`} draggable="false" />
      </BrandImage>
      <h4>{name}</h4>
    </Brand>
  );
};

const ExploreTheBrands = () => {
  const isNotMobile = useMediaQuery({ query: "(min-width: 640px)" });
  const rowRef = useRef(null);

  const prevScroll = useRef(rowRef.current?.scrollLeft);

  const dispatch = useDispatch();
  const { entities } = useSelector((state) => state.entities);

  useEffect(() => {
    if (entities.length === 0) {
      dispatch(getEntities());
    }
  }, [dispatch, entities]);

  const handleClick = (direction) => {
    if (rowRef.current) {
      const { scrollLeft, clientWidth } = rowRef.current;

      const scrollTo =
        direction === "left"
          ? scrollLeft - clientWidth
          : scrollLeft + clientWidth;

      rowRef.current.scrollTo({ left: scrollTo, behavior: "smooth" });
    }
  };

  useEffect(() => {
    let interval = setInterval(() => {
      rowRef.current.scrollLeft =
        rowRef.current.scrollLeft + rowRef.current.clientWidth;

      if (rowRef.current.scrollLeft === prevScroll.current) {
        rowRef.current.scrollLeft = 0;
      }

      prevScroll.current = rowRef.current.scrollLeft;
    }, 3000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <Container>
      {entities.length > 0 ? (
        <>
          <h4>SUPPORTED BRANDS</h4>
          <SliderHeaderContainer
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "space-between"
            }}
          >
            <h2>
              {isNotMobile
                ? "Explore the brands behind the wearables"
                : "Explore the brands."}
            </h2>
            <SliderArrows
              onBack={() => handleClick("left")}
              onNext={() => handleClick("right")}
            />
          </SliderHeaderContainer>

          <Brands ref={rowRef}>
            {entities.map((e, i) => {
              return (
                <Link to={`/brands/${e.id}`} key={i}>
                  <BrandPreview name={e.name} image={e.coverImage} />
                </Link>
              );
            })}
          </Brands>
        </>
      ) : null}
    </Container>
  );
};

export default ExploreTheBrands;
