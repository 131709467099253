import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  getVotingOptions,
  submitVote
} from "../../features/caged-steel/cagedSteelSlice";
import usePrevious from "../../hooks/usePrevious";
import { validateEmail, validateText } from "../../utils/utils";

import Navbar from "./Navbar";

import {
  Banner,
  DateBox,
  Fighters,
  FightersCol,
  CornerBadge,
  NameBadge,
  RegistrationContainer,
  NextStepsContainer,
  CurvelogoContainer,
  StepContainer,
  StepNumber,
  AppStoreLinks,
  FightCards
} from "./styled/CagedSteelPromo";
import {
  Button,
  Footer,
  MainContainer,
  QuestionsContainer,
  Question,
  VotingSection,
  InputLabel,
  Input,
  ErrorText,
  FightDescription
} from "./styled/SharedStyles";
import { StyledSpinner } from "../../components/styled/Spinner";

import players from "./data/cs37.json";
import playersImgs from "./data/cs37images.json";

import curveLogo from "./img/curve_logo.png";
import payAnyWhereImage from "./img/pay-anywhere-image.png";
import euFlag from "./img/eu-flag.png";
import ukFlag from "./img/uk-flag.png";
import appStoreLogo from "./img/app-store.svg";
import gplayLogo from "./img/play-store.svg";

const CagedSteelPromo = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");
  const [nameError, setNameError] = useState("");
  const [votedPosition, setVotedPosition] = useState(null);
  const [votedQuestion, setVotedQuestion] = useState(null);

  const [searchParams] = useSearchParams();
  const serialNumber = searchParams.get("serialNumber");

  const dispatch = useDispatch();
  const {
    votingOptions,
    voteSubmitted,
    errorGettingVotingOptions,
    gettingVotingOptions,
    submittingVote,
    errorSubmittingVote
  } = useSelector((state) => state.cagedSteel);

  console.log({ votingOptions });

  console.log({ voteSubmitted, gettingVotingOptions });

  const prevSubmittingVote = usePrevious(submittingVote);

  const FIGHTS_DESCRIPTIONS = [
    "Yonatan Francisco (3-1) vs Jack Terry (3-0) - Amateur Bantamweight Title Fight. Huge moment for Yonatan to prove he's worth of the title as he defends against home fighter Jack Terry. Representing Iceland and El Salvador, Yonatan yields an explosive striking style. This matches Jack Terry's fight style, known as 'The Yorkshire Terrier'. Don't blink for this fight. Who will leave with the title?",
    "Aron Kevinsson (4-4) vs Cain Morrow (5-4) - Amateur Super Lightweight Title Fight. Both of these guys have fought multiple times on Caged Steel and always been in exciting battles. Aron is fighting to defend his belt that he won on our last event. It was an epic bout that went the distance. This will be Cain Morrow's first title Fight on Caged Steel. He's going to put on a show for you all and as determined to keep the belt in England. Where do you think the belt will go?",
    "Joe Lister (15-5) vs Duane Smith (12-6). This is a middleweight bout of caged boxing in 4oz MMA gloves. Our exciting hybrid of boxing. Joe Lister hail from Doncaster whereas Duane resides from Manchester. Both guys have predicted knockouts in the first round, Using smaller gloves raises the chances of this happening too. The fight will take place over 4x2 minute rounds. Who have you got to win?",
    "Gav Lofts (2-1) vs Aron Leo (1-0). This is a professional MMA welterweight fight, over 3x5 minute rounds. Both athletes will be returning to Caged Steel. Aron Leo made a stellar debut on our last event with a 10 second knockout in round 1. The video went viral. Gav Lofts plans to put on an entertaining battle and come away with the win. He wants tough fights, we think this is a great 50/50 fight. We can't call it. Can you?",
    "Faz Ali is an undefeated professional super lightweight from Manchester/Liverpool. At 7-0 his dreams are set on becoming the first  Pakistani UFC champion. He is currently the only undefeated Pakistani in the UK and Europe. He's set to face Allef Jordelly flying in from Portugal, with Brazillian origin, Allef holds a 5-2 record. He's fought some of the toughest athletes around the world, and he determined to ruin Faz's dreams. With both guys aiming to get the knockout and go viral, this could be our best main event yet!"
  ];

  useEffect(() => {
    if (prevSubmittingVote && !errorSubmittingVote) {
      dispatch(getVotingOptions(serialNumber));
      document.getElementById("votes").scrollIntoView({ behavior: "smooth" });
    }
  }, [dispatch, errorSubmittingVote, prevSubmittingVote, serialNumber]);

  useEffect(() => {
    if (
      votingOptions?.votingResult?.position &&
      votingOptions?.votingResult?.option
    ) {
      setVotedPosition(votingOptions.votingResult.position);
      setVotedQuestion(votingOptions.votingResult.option);
    }
  }, [
    votingOptions?.votingResult?.option,
    votingOptions?.votingResult?.position
  ]);

  useEffect(() => {
    if (!votingOptions) {
      dispatch(getVotingOptions(serialNumber));
    }
  }, [votingOptions, dispatch, serialNumber]);

  function handleSubmitVote() {
    if (votingOptions?.previousVotingResults?.length > 0) {
      dispatch(
        submitVote({
          serialNumber,
          vote: {
            fullName: votingOptions.previousVotingResults[0].fullName,
            email: votingOptions.previousVotingResults[0].email,
            position: votedPosition,
            option: votedQuestion
          }
        })
      );
    } else {
      if (!validateEmail(email)) {
        setEmailError("Please enter a valid email");
      }

      if (!validateText(name)) {
        setNameError("Please dont use special characters");
      }

      if (validateEmail(email) && validateText(name)) {
        dispatch(
          submitVote({
            serialNumber,
            vote: {
              fullName: name,
              email,
              position: votedPosition,
              option: votedQuestion
            }
          })
        );

        // window.scrollTo({ top: 0, left: 0 });
      }
    }
  }

  function getContestantCornerByVotedPosition(position) {
    const [votedContestant] = votingOptions?.votingOptions.filter(
      (e) => e.position === position
    );

    const [contestant] = votingOptions?.contestants.filter(
      (e) => e.id === votedContestant?.contestantId
    );

    return contestant?.additionalInfo.corner;
  }

  function getContestantNameByCorner(corner) {
    const [contestant] = votingOptions.contestants.filter(
      (e) => e.additionalInfo.corner === corner
    );

    return contestant;
  }

  const appStoreLinks = (appStoreUrl, playStoreUrl) => {
    return (
      <AppStoreLinks>
        <img
          src={appStoreLogo}
          alt="app-store"
          onClick={() => window.open(appStoreUrl, "_blank")}
        />
        <img
          src={gplayLogo}
          alt="play-store"
          onClick={() => window.open(playStoreUrl, "_blank")}
        />
      </AppStoreLinks>
    );
  };

  if (errorGettingVotingOptions)
    return (
      <MainContainer>
        <Navbar />
        <ErrorText>
          We are experiencing a problem. Try refreshing the page or come back
          later.
        </ErrorText>
        <Footer>
          Powered by
          <a
            href="https://www.digiseq.co.uk"
            target="_blank"
            rel="noopener noreferrer"
          >
            DIGISEQ
          </a>
        </Footer>
      </MainContainer>
    );

  if (gettingVotingOptions || submittingVote)
    return (
      <MainContainer>
        <Navbar />
        <StyledSpinner />
        <Footer>
          Powered by
          <a
            href="https://www.digiseq.co.uk"
            target="_blank"
            rel="noopener noreferrer"
          >
            DIGISEQ
          </a>
        </Footer>
      </MainContainer>
    );

  return (
    <MainContainer>
      <Navbar />

      <FightCards>
        <div className="intro">
          <p>
            {
              "An international promotion based in Doncaster, UK. Showcasing Professional and Amateur MMA, as well as featuring our hybrid, Caged Boxing. Athletes from around the UK and Europe will battle against each other to either defend or fight for the prestigious Caged Steel Title belt or to increase their rank in the combat sport scene."
            }
          </p>
          <p>
            {
              "Considered a mini Las Vegas style show, expect the best feel good music and a dazzling production. VIP’s gain access to the VIP lounge and all bars around the venue. Prepare to watch an action packed fight card, with all the fight night drama. An epic night out awaits."
            }
          </p>
        </div>
        <div className="card no-border">
          <CornerBadge first>Red</CornerBadge>
          <div className="vs">vs</div>
          <CornerBadge>Blue</CornerBadge>
        </div>
        {players?.map((player, i) => (
          <div className="card" key={i}>
            <span>{player.red}</span>
            <span>{player.blue}</span>
          </div>
        ))}
      </FightCards>
      {
        <Banner
          player2={require(`./img/cs37/${
            votingOptions?.previousVotingResults
              ? playersImgs[votingOptions?.previousVotingResults?.length].red
              : playersImgs[1].red
          }`)}
          player1={require(`./img/cs37/${
            votingOptions?.previousVotingResults
              ? playersImgs[votingOptions?.previousVotingResults?.length].blue
              : playersImgs[1].blue
          }`)}
          playersBg={require(`./img/cs37/${
            playersImgs[votingOptions?.previousVotingResults?.length + 1]
              ?.both || playersImgs[1]?.both
          }`)}
          id="votes"
          corner={
            !voteSubmitted && votingOptions?.contestants?.length > 0
              ? "both"
              : votedPosition
              ? getContestantCornerByVotedPosition(votedPosition)
              : null
          }
        >
          <h1>{`${
            votingOptions?.previousVotingResults?.length === 5
              ? "ALL VOTES RECEIVED"
              : voteSubmitted
              ? "VOTE RECEIVED"
              : "VOTE NOW!"
          }`}</h1>
        </Banner>
      }
      {votingOptions && !voteSubmitted && (
        <Fighters>
          <FightersCol align="flex-start">
            <CornerBadge first>Red</CornerBadge>
            <NameBadge>
              <div>{getContestantNameByCorner("red").firstName}</div>
              <div>{getContestantNameByCorner("red").lastName}</div>
            </NameBadge>
          </FightersCol>
          <FightersCol>
            <DateBox>7th September</DateBox> <div>vs</div>
          </FightersCol>
          <FightersCol align="flex-end">
            <CornerBadge>Blue</CornerBadge>
            <NameBadge>
              <div>{getContestantNameByCorner("blue").firstName}</div>
              <div style={{ textAlign: "right" }}>
                {getContestantNameByCorner("blue").lastName}
              </div>
            </NameBadge>
          </FightersCol>
        </Fighters>
      )}
      {!voteSubmitted && (
        <FightDescription>
          {votingOptions?.previousVotingResults ? (
            <p>
              {
                FIGHTS_DESCRIPTIONS[
                  votingOptions?.previousVotingResults?.length
                ]
              }
            </p>
          ) : (
            <p>{FIGHTS_DESCRIPTIONS[0]}</p>
          )}
        </FightDescription>
      )}
      {votingOptions && (
        <VotingSection>
          {!voteSubmitted ? (
            <h2>{votingOptions.message}</h2>
          ) : (
            <h2>{`Your predicted winner${
              votingOptions?.previousVotingResults?.length > 1
                ? "s are:"
                : " is:"
            }`}</h2>
          )}
          <QuestionsContainer>
            {voteSubmitted &&
              votingOptions.previousVotingResults?.length > 0 &&
              votingOptions.previousVotingResults.map((vote, i) => {
                const splitAfterSecondSpace = (str) => [
                  str.split(" ").slice(0, 2).join(" "),
                  str.split(" ").slice(2).join(" ")
                ];

                return (
                  <Question key={i} selected>
                    {splitAfterSecondSpace(vote.option)[0]}
                    <span>{splitAfterSecondSpace(vote.option)[1]}</span>
                  </Question>
                );
              })}
            {votingOptions.previousVotingResults?.length < 5 &&
              voteSubmitted && (
                <Button onClick={() => window.location.reload()}>
                  {`${5 - votingOptions.previousVotingResults.length} ${
                    5 - votingOptions.previousVotingResults.length === 1
                      ? "VOTE"
                      : "VOTES"
                  } REMAINING - VOTE AGAIN!`}
                </Button>
              )}
            {!voteSubmitted &&
              votingOptions.votingOptions.map((e, i) => {
                const contestantName = `${
                  votingOptions.contestants.filter(
                    (c) => c.id === e.contestantId
                  )[0].firstName
                } ${
                  votingOptions.contestants.filter(
                    (c) => c.id === e.contestantId
                  )[0].lastName
                }`;

                const optionMessage = e.option.split("}}")[1];

                return (
                  <Question
                    voted={voteSubmitted}
                    disabled={voteSubmitted && votedPosition !== e.position}
                    key={e.position}
                    selected={votedPosition === e.position}
                    onClick={() => {
                      if (!voteSubmitted) {
                        setVotedPosition(e.position);
                        setVotedQuestion(`${contestantName}${optionMessage}`);
                      }
                    }}
                  >
                    {contestantName}
                    <span>{optionMessage}</span>
                  </Question>
                );
              })}
          </QuestionsContainer>
          {!voteSubmitted && (
            <>
              {!votingOptions?.previousVotingResults?.length > 0 && (
                <RegistrationContainer>
                  {!voteSubmitted && (
                    <>
                      <p>
                        {
                          "Enter our prize draw and claim your Caged Steel key fob by entering your details below."
                        }
                      </p>
                      <p>
                        {
                          "Don’t forget, your key fob is required to redeem your free meal in the VIP Lounge."
                        }
                      </p>
                    </>
                  )}
                  <InputLabel>Full name*</InputLabel>
                  <Input
                    value={name}
                    type="text"
                    onChange={(ev) => {
                      setNameError("");
                      setName(ev.target.value);
                    }}
                  />
                  {nameError && <ErrorText>{nameError}</ErrorText>}
                  <InputLabel>Email address*</InputLabel>
                  <Input
                    value={email}
                    type="email"
                    onChange={(ev) => {
                      setEmailError("");
                      setEmail(ev.target.value);
                    }}
                  />
                  {emailError && <ErrorText>{emailError}</ErrorText>}
                </RegistrationContainer>
              )}
              <Button
                disabled={
                  (!votingOptions?.previousVotingResults?.length &&
                    (!name || !email || votedPosition === null)) ||
                  !votedPosition
                }
                onClick={() => handleSubmitVote()}
              >
                Submit
              </Button>
            </>
          )}
        </VotingSection>
      )}
      <NextStepsContainer>
        <CurvelogoContainer>
          {/* <img className="separator" src={orSeparator} alt="or" /> */}
          <p className="title">Link your card</p>
          <div className="logos">
            <img className="curve-logo" src={curveLogo} alt="curve logo" />
            <img className="flag" src={euFlag} alt="eu flag" />
            <img className="flag" src={ukFlag} alt="uk flag" />
          </div>
        </CurvelogoContainer>
        <StepContainer>
          <StepNumber>1</StepNumber>
          <h2>Create a Curve Account</h2>
          <p>
            Register your card details with Curve to receive a free compatible
            digital bank card.
          </p>
          <p>Download the Curve App:</p>
          {appStoreLinks(
            "https://apps.apple.com/gb/app/curve-supercharge-your-money/id1049397112",
            "https://play.google.com/store/apps/details?id=com.imaginecurve.curve.prd"
          )}
        </StepContainer>
        <StepContainer>
          <StepNumber>2</StepNumber>
          <h2>Link Your Curve Virtual Card Using the Manage-Mii App</h2>
          <p>
            Follow the instructions on the Manage-Mii app and use your new Curve
            Card details when prompted.
          </p>
          {appStoreLinks(
            "https://apps.apple.com/app/manage-mii/id6446908503",
            "https://play.google.com/store/apps/details?id=com.digiseq.managemii"
          )}
        </StepContainer>
        <StepContainer>
          <StepNumber>3</StepNumber>
          <h2>Pay Anywhere!</h2>
          <p>
            Tap your wearable at any card terminal where Mastercard Contactless
            is enabled.
          </p>
          <img
            className="pay-anywhere"
            src={payAnyWhereImage}
            alt="pay anywhere"
          />
        </StepContainer>
      </NextStepsContainer>
      <Footer>
        Powered by
        <a
          href="https://www.digiseq.co.uk"
          target="_blank"
          rel="noopener noreferrer"
        >
          DIGISEQ
        </a>
      </Footer>
    </MainContainer>
  );
};

export default CagedSteelPromo;
