import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
  deleteCampaign,
  getCampaign,
  getCampaigns,
  resetCampaign
} from "../../features/campaign-manager/campaignManagerSlice";
import { getDasDevicesByCampaign } from "../../features/das-device-manager/dasDeviceManagerSlice";
import { convertArrayToCSV, downloadCSV } from "../../utils/utils";

import Spinner from "../../components/Spinner";
import NavBarComponent from "./components/NavBar";
import ProtectedRoute from "../../components/ProtectedRoute";
import { Button } from "./components/styled/LinkSettings";
import DeleteConfirmation from "./components/DeleteConfirmation";
import DetailsItem from "./components/DetailsItem";

import {
  CampaignNameContainer,
  DetailsHeader,
  CampaignHeaderContainer,
  ImageContainer,
  Image,
  DetailsContainer
} from "./styled/Campaign";
import { Body, ErrorMsg, MainContainer } from "./styled/SharedStyles";

import campaignDefaultIcon from "./img/campaign-default-icon.svg";
import downloadIcon from "./img/download.svg";

const Campaign = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();

  const handleExport = (data, filename) => {
    const csvContent = convertArrayToCSV(data);
    downloadCSV(csvContent, filename);
  };

  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);

  const { campaign, isLoading, isError, message, deletedCampaign } =
    useSelector((state) => state.campaigns);

  const {
    devices,
    isLoading: isLoadingDevices,
    isError: isErrorDevices,
    message: messageDevices
  } = useSelector((state) => state.dasDevices);

  useEffect(() => {
    if (!campaign) {
      dispatch(getCampaign(params.campaignId));
      dispatch(getDasDevicesByCampaign(params.campaignId));
    }
  }, [campaign, dispatch, params.campaignId]);

  useEffect(() => {
    return () => {
      dispatch(resetCampaign());
    };
  }, [dispatch]);

  useEffect(() => {
    if (deletedCampaign) {
      dispatch(getCampaigns());
      navigate(`/campaign-manager/`);
    }

    return () => {
      dispatch(resetCampaign());
    };
  }, [deletedCampaign, dispatch, navigate]);

  if (isLoading || isLoadingDevices) return <Spinner />;

  console.log(devices?.devices);

  return (
    <MainContainer>
      <ProtectedRoute />
      <NavBarComponent
        leftText="Campaigns"
        hasBackArrow
        to="/campaign-manager"
      />
      <Body>
        <CampaignHeaderContainer>
          <ImageContainer>
            <Image src={campaignDefaultIcon} alt="campaign icon" />
          </ImageContainer>
        </CampaignHeaderContainer>
        <CampaignNameContainer>
          <div>{campaign?.name}</div>
        </CampaignNameContainer>
        <DetailsHeader>Details</DetailsHeader>
        <DetailsContainer>
          <DetailsItem
            name="Campaign Name"
            detail={campaign?.name}
            onClick={() =>
              navigate(`/campaign-manager/${campaign.id}/edit`, {
                state: { field: "Name" }
              })
            }
          />
          <DetailsItem
            name="Description"
            detail={campaign?.description}
            lines={(campaign?.description?.length / 35) * 50}
            onClick={() =>
              navigate(`/campaign-manager/${campaign.id}/edit`, {
                state: { field: "Description" }
              })
            }
          />

          <DetailsItem
            name="Campaign Links"
            onClick={() =>
              navigate(`/campaign-manager/${campaign.id}/links`, {
                state: { links: campaign.campaignLinks }
              })
            }
          ></DetailsItem>
          {campaign?.mobileScavengerHuntCampaign && (
            <DetailsItem
              onClick={() =>
                navigate(`/campaign-manager/${campaign.id}/scavenger-hunt`)
              }
              name="Scavenger Hunt"
            />
          )}

          {campaign?.id.includes("coach_tracker") && (
            <DetailsItem
              onClick={() =>
                navigate(`/campaign-manager/${campaign.id}/coach-tracker`)
              }
              name="Coach Tracker"
            />
          )}

          {campaign?.votingCampaign?.message && (
            <DetailsItem
              onClick={() =>
                navigate(`/campaign-manager/${campaign.id}/voting-results`)
              }
              name="Voting Campaign"
            >
              <div>Voting Campaign</div>
            </DetailsItem>
          )}
          <DetailsItem
            name="No. Devices"
            detail={devices?.count || "N/A"}
            isLast={true}
            icon={
              <img
                src={downloadIcon}
                alt="download"
                onClick={() =>
                  devices?.devices?.length > 0
                    ? handleExport(
                        devices.devices.map((device) => ({
                          serialNumber: String(device.serialNumber),
                          email: device.personalDetails?.email
                            ? device.personalDetails.email
                            : "",
                          fullName: device.personalDetails?.fullName
                            ? device.personalDetails.fullName
                            : ""
                        })),
                        "devices.csv"
                      )
                    : null
                }
              />
            }
          />

          {/* <DetailsItem
            name="Campaign Devices"
            isLast={!campaign?.votingCampaign?.message}
            onClick={() =>
              navigate(`/campaign-manager/${campaign.id}/devices`, {
                // state: { links: campaign.campaignLinks }
              })
            }
          ></DetailsItem> */}
        </DetailsContainer>

        {!showDeleteConfirmation && devices?.count === 0 && (
          <Button danger onClick={() => setShowDeleteConfirmation(true)}>
            Delete campaign
          </Button>
        )}
        {showDeleteConfirmation && (
          <DeleteConfirmation
            buttonText={"Delete campaign"}
            onConfirmed={() => {
              setShowDeleteConfirmation(false);
              dispatch(deleteCampaign(params.campaignId));
            }}
          />
        )}
        {(isError || isErrorDevices) && (
          <ErrorMsg>
            {message ||
              messageDevices ||
              "Oh no, we have an error, please refresh page or try again later"}
          </ErrorMsg>
        )}
      </Body>
    </MainContainer>
  );
};

export default Campaign;
