import styled from "styled-components";

export const ProductPageContainer = styled.div`
  margin-top: 60px;
  padding: 0 16px;
  @media (min-width: 768px) {
    padding: 0 20px;
    margin-top: 80px;
  }

  @media (min-width: 1440px) {
    padding: 0 60px;
  }
`;

export const ProductContainer = styled.section`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(1, minmax(0, 1fr));
  grid-column-gap: 20px;
  margin-bottom: 40px;

  @media (min-width: 768px) {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
`;

export const ProductImageContainer = styled.div`
  background-color: #f2f3f5;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  border-radius: 30px;

  img {
    max-width: 100%;
    max-height: 100%;
    aspect-ratio: 1;
    object-fit: contain;
  }

  @media (min-width: 640px) {
    padding: 40px;
  }
`;

export const ProductDescription = styled.div`
  display: none;
  @media (min-width: 640px) {
    display: block;
    color: #797b80;
    font-size: 0.875rem;
    margin-bottom: 32px;
  }
`;

export const VariantsSideContainer = styled.div`
  h2 {
    font-style: normal;
    font-weight: 600;
    font-size: 70px;
    line-height: 70px;
    text-align: left;
    margin-bottom: 20px;
  }

  @media (min-width: 640px) {
    display: block;
  }
`;

export const VariantsContainer = styled.div`
  display: none;
  margin-bottom: 20px;
  @media (min-width: 640px) {
    display: grid;
    grid-template-columns: repeat(auto-fill, 99px);
    gap: 10px;
  }
`;

export const VariantContainer = styled.div`
  width: 99px;
  height: 99px;
  padding: 5px;
  background-color: #f2f3f5;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;

  img {
    cursor: pointer;
    max-width: 100%;
    max-height: 100%;
  }
`;

export const AboutBrandSection = styled.section`
  h2 {
    text-align: left;
    font-style: normal;
    font-weight: 600;
    font-size: 70px;
    line-height: 70px;
  }
  p {
    color: #797b80;
    margin: 20px 0;
  }
`;

export const MoreFromBrandSection = styled.section`
  width: 100%;
  margin-bottom: 60px;
  h2 {
    text-align: left;
    margin-bottom: 40px;
    font-style: normal;
    font-weight: 600;
    font-size: 70px;
    line-height: 70px;
  }
`;

export const MoreProductsContainer = styled.div`
  display: grid;
  gap: 20px;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  @media (min-width: 768px) {
    gap: 40px;
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
`;

export const VariantsCounter = styled.div`
  border-top: 2px solid #e8e8ed;
  border-bottom: 2px solid #e8e8ed;
  padding: 12px 0;
  margin-bottom: 20px;
`;
