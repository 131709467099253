import styled from "styled-components";

export const PaginationContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 52px;
  margin-bottom: 79px;
  img {
    cursor: pointer;
  }

  span {
    cursor: pointer;
  }
`;

export const PageNumber = styled.span`
  margin: 0 20px;
  font-weight: ${({ current }) => (current ? "bold" : "normal")};
  cursor: pointer;
`;
