import axios from "axios";
import { ENV } from "../../env";

export const WEARABLES_API_URL = `https://www.${
  ENV !== "production" ? (ENV === "development" ? "dev" : ENV) + "-" : ""
}manage-mii.uk/api/wearables`;

const fetchDevice = async (serial) => {
  const response = await axios.get(
    WEARABLES_API_URL + `/serial/${serial}/lost-mode-details`
  );

  return response.data;
};

const lostModeService = {
  fetchDevice
};

export default lostModeService;
