import styled, { createGlobalStyle } from "styled-components";
import UniformFont from "./fonts/Uniform.ttf";

export const CocaColaFontStyle = createGlobalStyle`
@font-face {
  font-family: 'Uniform-Regular3';
  src: url(${UniformFont}) format('truetype');
  font-weight: 400;
  font-style: normal;
  font-display: auto;
}
`;

const B1 = {
  fontFamily: "Uniform-Regular3",
  fontStyle: "normal",
  fontWeight: 400,
  fontSize: "16px",
  lineHeight: "24px",
  letterSpacing: "0.5px",
  color: "#CCCCCC"
};

const HEADER = {
  fontFamily: "Uniform-Regular3",
  fontStyle: "normal",
  fontSize: "24px",
  lineHeight: "32px",
  fontWeight: 400,
  color: "#FFF",
  textAlign: "center"
};

export const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 480px;
  margin: auto;
  min-height: 100vh;
  background-color: #f40009;
  padding-top: 24px;
  gap: 16px;
`;

export const Intro = styled.section`
  background-color: #282827;
  margin: 0px 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 40px 16px;
  gap: 40px;
  border-radius: 6px;

  h1 {
    ${HEADER}
  }

  p {
    ${B1}
    text-align: center;
  }

  img {
    height: 106px;
    margin: auto;
  }
`;

export const ViewBalance = styled.div`
  position: relative;
  background-color: #282827;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 22.5px 0;
  border-radius: 6px;
  margin: 0 16px;

  &::before {
    content: " ";
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0.3;
    background-image: url(${({ img }) => img});
    background-size: cover;
    background-position: 20%;
    background-repeat: no-repeat;
  }

  .balance-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 0 16px;
  }

  p {
    ${B1}
    text-align: center;
  }
  .balance {
    text-align: center;
    ${HEADER}
  }
`;

export const Wearable = styled.div`
  margin: 0 35px;
  border-radius: 6px;
  margin: 16px 16px 16px;
  background-color: #282827;
  img {
    width: 100%;
    height: auto;
    object-fit: contain;
    aspect-ratio: 1;
  }
`;

export const PaymentAccount = styled.section`
  padding: 24px 16px;
  gap: 16px;
  background-color: #282827;
  display: flex;
  flex-direction: column;
  margin: 0 16px;
  border-radius: 6px;

  h2 {
    ${HEADER}
    text-align: left;
  }

  p {
    ${B1}
    color: white;
  }

  .button {
    ${B1}
    color: white;
    cursor: pointer;
    display: inline-flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 8px 8px 8px 16px;
    background: #f37439;
    border-radius: 360px;
    margin-bottom: 8px;
    align-self: flex-start;
  }

  .stores {
    cursor: pointer;
    display: flex;
    flex-direction: row;
    gap: 8px;
  }

  .line {
    border: 1px solid #e0e0e0;
    width: 100%;
    height: 1px;
  }

  .or-separator {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    p {
      margin: 0px 16px;
    }
  }
`;

export const NextStepsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const CurvelogoContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  .separator {
    height: 25px;
    object-fit: cover;
    width: 100%;
  }
  .title {
    ${HEADER}
    text-align: left;
  }

  .logos {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 20px;
    .flag {
      height: 26px;
    }
  }

  .curve-logo {
    height: 32px;
    width: 152px;
  }
`;

export const StepContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  h2 {
    ${HEADER}
    text-align: left;
    color: rgba(255, 255, 255, 0.92);
  }
  p {
    ${B1}
  }
  .pay-anywhere {
    margin: 24px auto 0;
    width: 100%;
    object-fit: contain;
    border-radius: 8px;
  }
`;

export const StepNumber = styled.div`
  display: flex;
  width: 40px;
  padding: 8px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 360px;
  border: 1px solid white;
  color: white;
`;

export const AppStoreLinks = styled.section`
  img {
    cursor: pointer;
    margin-right: 16px;
  }
`;

export const Footer = styled.div`
  background-color: #282827;
  margin-top: 8px;
  padding: 24px 16px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  justify-content: center;
  align-items: center;

  p {
    ${B1}
  }

  img {
    height: 34px;
    margin-bottom: 8px;
    cursor: pointer;
  }
`;
