import { Link } from "react-router-dom";
import styled from "styled-components";

export const StyledHeader = styled.header`
  grid-area: header;
  padding: 0 20px;
  position: fixed;
  display: flex;
  z-index: 999;
  justify-self: center;
  width: 100%;
  height: 50px;
  max-width: 1920px;
  background: rgba(242, 242, 245, 0.6);
  backdrop-filter: blur(16px);

  @media (min-width: 1440px) {
    padding: 0 60px;
  }
`;

export const StyledNav = styled.nav`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const LinksContainer = styled.div`
  display: flex;
  font-size: 0.875rem;
`;

export const LinkButton = styled(Link)`
  color: #0000ac;
  font-family: "acumin-pro-extra-condensed";
  font-weight: 600;
  font-size: 1.375rem;
`;

export const StyledHeaderMobile = styled.header`
  @media (min-width: 640px) {
    display: none;
  }
`;

export const NavContainerMobile = styled.div`
  position: fixed;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  width: 100vw;
  z-index: 999;
`;

export const NavMobile = styled.div`
  border-bottom: 1px solid #aeb1b8;
  display: flex;
  justify-content: space-between;
  padding: 20px 20px 20px;
`;

export const LinksContainerMobile = styled.div`
  background-color: #fff;
  margin-top: 10px;
  border-top: 2px solid #aeb2b8;
`;

export const LinkButtonMobile = styled(Link)`
  height: 50px;
  border-bottom: 2px solid #aeb2b8;
  padding: 16px 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
