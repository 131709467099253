import styled from "styled-components";

export const MainContainer = styled.div`
  padding: 20px 20px;
  display: grid;
  justify-items: center;
  grid-template-columns: 1fr;
  grid-gap: 10px;
  background-color: white;
  max-width: 650px;
  margin: 100px 10px;
  border-radius: 14px;
  text-align: center;

  h2 {
    font-family: "Noto Sans", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 32px;
    line-height: 30px;
    color: #1d1d1f;
  }

  p {
    width: 80%;
    font-family: "Noto Sans", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    text-align: center;
    color: #8d8e99;
    margin: 0 auto 12px;
  }

  form {
    width: 100%;
    display: grid;
    justify-items: center;
    grid-template-columns: 1fr;
    grid-gap: 10px;
  }

  @media (min-width: 670px) {
    padding: 40px 60px;
    margin: 100px auto;
  }
`;

export const InvalidField = styled.div`
  color: red;
`;

export const AlertButtonContainer = styled.div`
  width: 304px;
`;

export const Select = styled.select`
  box-sizing: border-box;
  -webkit-appearance: button;
  -moz-appearance: button;
  -webkit-user-select: none;
  -moz-user-select: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  background: transparent;
  width: 100%;
  background-image: url("data:image/svg+xml;utf8,<svg fill='black' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
  background-repeat: no-repeat;
  background-position-x: 95%;
  background-position-y: 50%;
  width: 100%;
  border: 1px solid #dadbe6;
  border-radius: 6px;
  padding: 12px 18px;
  color: #8d8e99;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 20px;
  font-family: "Noto Sans", sans-serif;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  &:focus {
    outline: none;
  }
`;
