import React from "react";
import Heading from "../../components/Heading";
import { StyledMain } from "../../styled/StyledMain";
import { MainContainer } from "../../styled/StyledMainContainer";
import { useNavigate } from "react-router-dom";
import {
  ManOfTheMatchContainer,
  ManOfTheMatchImageContainer,
  ShirtImageContainer
} from "./components/styled/ManOfTheMatch";

import heroImage from "./images/hero_image.webp";

const ManOfTheMatch = () => {
  const navigate = useNavigate();

  return (
    <StyledMain>
      <MainContainer>
        <Heading />
        <ManOfTheMatchContainer>
          <ManOfTheMatchImageContainer>
            <img src={heroImage} alt="brand img" />
          </ManOfTheMatchImageContainer>
          <h2>Pick to WIN...</h2>
          <h4>
            …a signed shirt and meet the player before the next home game
            against Osasuna.
          </h4>

          <ShirtImageContainer>
            {Array(11)
              .fill()
              .map((item, i) => (
                <img
                  onClick={() => {
                    navigate(`/nomination-submitted`);
                  }}
                  src={require(`./images/players/${i + 1}.png`)}
                  alt="player shirt img"
                />
              ))}
          </ShirtImageContainer>
        </ManOfTheMatchContainer>
      </MainContainer>
    </StyledMain>
  );
};

export default ManOfTheMatch;
