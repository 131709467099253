import React, { useState } from "react";
import { SectionContainer } from "./styles/BinChecker";

import chevronDown from "./img/chevron-down.svg";
import chevronUp from "./img/chevron-up.svg";

const ExpandingContainer = ({
  message,
  children,
  supportedCountries,
  image,
  fullWidth,
}) => {
  const [expanded, setExpanded] = useState(false);

  return (
    <SectionContainer fullWidth={fullWidth}>
      <div className="title-bar" onClick={() => setExpanded(!expanded)}>
        <div className="content-text">
          <img src={image} alt="logo" />

          <div className="country-logo">
            {supportedCountries?.map((countryCode, i) => (
              <img
                key={i}
                src={require(`./img/countries/${countryCode}_logo.png`)}
                alt="countryicon"
              ></img>
            ))}
          </div>
        </div>
        <img
          src={expanded ? chevronUp : chevronDown}
          alt={`chevron ${expanded ? "up" : "down"}`}
        />
      </div>
      {expanded && children}
    </SectionContainer>
  );
};

export default ExpandingContainer;
