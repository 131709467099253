import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { composeErrorMessage } from "../../utils/utils";
import eventManagerService from "./eventManagerService";

const initialState = {
  events: null,
  event: null,
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: ""
};

export const getEvents = createAsyncThunk(
  "events/getAllEvents",
  async (_, thunkAPI) => {
    try {
      const response = await eventManagerService.getEvents();

      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(composeErrorMessage(error));
    }
  }
);

export const getEvent = createAsyncThunk(
  "events/getEvent",
  async (eventId, thunkAPI) => {
    try {
      return await eventManagerService.getEvent(eventId);
    } catch (error) {
      return thunkAPI.rejectWithValue(composeErrorMessage(error));
    }
  }
);

export const createEvent = createAsyncThunk(
  "events/createEvent",
  async (payload, thunkAPI) => {
    try {
      return await eventManagerService.createEvent(payload);
    } catch (error) {
      return thunkAPI.rejectWithValue(composeErrorMessage(error));
    }
  }
);

export const editEvent = createAsyncThunk(
  "events/editEvent",
  async (payload, thunkAPI) => {
    try {
      return await eventManagerService.editEvent(payload);
    } catch (error) {
      return thunkAPI.rejectWithValue(composeErrorMessage(error));
    }
  }
);

export const deleteEvent = createAsyncThunk(
  "events/deleteEvent",
  async (payload, thunkAPI) => {
    try {
      return await eventManagerService.deleteEvent(payload);
    } catch (error) {
      return thunkAPI.rejectWithValue(composeErrorMessage(error));
    }
  }
);

export const hardResetEventData = createAsyncThunk(
  "events/hardResetEventData",
  async (eventId, thunkAPI) => {
    try {
      return await eventManagerService.hardResetEventData(eventId);
    } catch (error) {
      return thunkAPI.rejectWithValue(composeErrorMessage(error));
    }
  }
);

export const eventsSlice = createSlice({
  name: "events",
  initialState,
  reducers: {
    reset: (state) => initialState,
    resetEvents: (state) => {
      state.events = null;
    },
    resetEvent: (state) => {
      state.event = null;
      state.newEvent = null;
      state.updatedEvent = null;
      state.deletedEvent = null;
    },
    resetError: (state) => {
      state.isError = null;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getEvents.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getEvents.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.events = action.payload;
      })
      .addCase(getEvents.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(getEvent.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getEvent.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.event = action.payload;
      })
      .addCase(getEvent.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(createEvent.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(createEvent.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(createEvent.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })

      .addCase(editEvent.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(editEvent.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(editEvent.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(deleteEvent.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(deleteEvent.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(deleteEvent.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(hardResetEventData.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(hardResetEventData.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(hardResetEventData.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      });
  }
});

export const { reset, resetEvents, resetEvent, resetError } =
  eventsSlice.actions;
export default eventsSlice.reducer;
