import { Container } from "./styles/AppStoreLinks";

import appStoreLogo from "./img/app-store.svg";
import gPlayLogo from "./img/play-store.svg";

const AppStoreLinks = ({ appStoreUrl, gPlayUrl }) => {
  return (
    <Container>
      <img
        src={appStoreLogo}
        alt="app-store"
        onClick={() => window.open(appStoreUrl, "_blank")}
      />
      <img
        src={gPlayLogo}
        alt="play-store"
        onClick={() => window.open(gPlayUrl, "_blank")}
      />
    </Container>
  );
};

export default AppStoreLinks;
