import React, { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { formattedBalance } from "../../features/balance-and-pin/utils";
import { getBalanceAndPin } from "../../features/balance-and-pin/balanceAndPinSlice";
import Spinner from "../../components/Spinner";
import {
  H1,
  Hero,
  MainContainer,
  Navbar,
  PaymentAccount,
  ActivationCode,
  ManageMii,
  Footer
} from "./styles/Planica24";

import mastercardLogo from "./img/mastercard.svg";
import planicaSplash from "./img/planicaSplash.svg";
import boboLogo from "./img/bobo-logo.png";
import copyIcon from "./img/copy-icon.svg";
import chevronRight from "./img/chevron-right.svg";
import appStoreLogo from "./img/app-store.svg";
import gplayLogo from "./img/play-store.svg";
import digiseqLogo from "./img/digiseq-footer.png";
import paymeLogo from "./img/payme-footer.png";

const Planica24 = () => {
  const [searchParams] = useSearchParams();
  const serialNumber = searchParams.get("serialNumber");

  const dispatch = useDispatch();
  const { balanceAndPin, isLoading } = useSelector(
    (state) => state.balanceAndPin
  );

  useEffect(() => {
    if (!balanceAndPin && serialNumber) {
      dispatch(
        getBalanceAndPin({
          serial: serialNumber,
          query: "?provider=pannovate"
        })
      );
    }
  }, [balanceAndPin, serialNumber, dispatch]);

  if (isLoading) return <Spinner />;

  return (
    <MainContainer>
      <Navbar>
        <img src={mastercardLogo} alt="mastercard" />
        <div className="balance">
          {balanceAndPin?.balanceDetails?.availableBalance ? (
            <p>
              {formattedBalance(
                balanceAndPin.balanceDetails.availableBalance,
                balanceAndPin?.balanceDetails.currency
              )}
            </p>
          ) : (
            <p>{"N/A"}</p>
          )}
        </div>
      </Navbar>
      <Hero>
        <img src={planicaSplash} alt="planica2024" />
        <H1>Welcome to Planica 2024</H1>
        <p>
          Treat this bracelet as cash. It has been pre-loaded{" "}
          {balanceAndPin?.balanceDetails.currency
            ? `with ${formattedBalance(
                10,
                balanceAndPin.balanceDetails.currency
              )} `
            : ""}
          for you to spend anywhere Mastercard is accepted.
        </p>
      </Hero>
      {balanceAndPin?.activationCode && (
        <PaymentAccount>
          <H1>Payment Account</H1>
          <div className="inner">
            <img className="logo" src={boboLogo} alt="bourgeois boheme" />
            <p>
              Want to continue enjoying unrivaled payment experience? Upgrade to
              Bourgeois Bohème wallet!
            </p>
            <p>Your wearable activation code</p>
            <ActivationCode>
              <span>{balanceAndPin.activationCode}</span>
              <img
                src={copyIcon}
                alt="copy"
                onClick={() =>
                  navigator.clipboard.writeText(balanceAndPin.activationCode)
                }
              />
            </ActivationCode>
          </div>
          <div
            className="button"
            onClick={() => window.open(`https://uefa.bobofin.com/`, "_blank")}
          >
            {"Join Bourgeois Bohème"}{" "}
            <img src={chevronRight} alt="chevron right" />
          </div>
          <div className="stores">
            <img
              src={appStoreLogo}
              alt="app store logo"
              onClick={() =>
                window.open(
                  "https://apps.apple.com/us/app/bourgeois-boheme/id1574430730",
                  "_blank"
                )
              }
            />
            <img
              src={gplayLogo}
              alt="google play logo"
              onClick={() =>
                window.open(
                  "https://play.google.com/store/apps/details?id=com.paymeswiss.bobofin",
                  "_blank"
                )
              }
            />
          </div>
        </PaymentAccount>
      )}
      <ManageMii>
        <H1>Manage-Mii</H1>
        <p>
          Enable contactless payments on your wearable and more by downloading
          the Manage-Mii app!
        </p>
        <div className="stores">
          <img
            src={appStoreLogo}
            alt="app store logo"
            onClick={() =>
              window.open(
                "https://apps.apple.com/gb/app/manage-mii/id6446908503",
                "_blank"
              )
            }
          />
          <img
            src={gplayLogo}
            alt="google play logo"
            onClick={() =>
              window.open(
                "https://play.google.com/store/apps/details?id=com.digiseq.managemii",
                "_blank"
              )
            }
          />
          <p>
            Already have the app?{" "}
            <a href={"managemii://"} target="_blank" rel="noopener noreferrer">
              Open Manage-Mii
            </a>
          </p>
        </div>
      </ManageMii>
      <Footer>
        <p>Powered by</p>
        <img src={digiseqLogo} alt="digiseq" />
        <p>Payment services provided by</p>
        <img src={paymeLogo} alt="payme swiss" />
      </Footer>
    </MainContainer>
  );
};

export default Planica24;
