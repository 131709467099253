import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  editCampaign,
  getCampaign,
  getCampaigns,
  resetCampaign
} from "../../features/campaign-manager/campaignManagerSlice";
import ProtectedRoute from "../../components/ProtectedRoute";
import NavBarComponent from "./components/NavBar";
import { Button, Input, TextArea } from "./components/styled/SharedStyles";
import { Body, ErrorMsg, MainContainer } from "./styled/SharedStyles";
import { StyledSpinner } from "../../components/styled/Spinner";

const EditCampaign = () => {
  const params = useParams();
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [name, setName] = useState("");
  const [description, setDescription] = useState("");

  const { campaign, isLoading, isError, message, updatedCampaign } =
    useSelector((state) => state.campaigns);

  useEffect(() => {
    if (!campaign?.id && params?.campaignId) {
      dispatch(getCampaign(params.campaignId));
    } else {
      setName(campaign.name);
      setDescription(campaign.description);
    }
  }, [
    campaign?.description,
    campaign?.id,
    campaign?.name,
    dispatch,
    params.campaignId
  ]);

  useEffect(() => {
    if (updatedCampaign?.id) {
      dispatch(getCampaigns());
      navigate("/campaign-manager");
    }

    return () => {
      dispatch(resetCampaign());
    };
  }, [dispatch, navigate, updatedCampaign]);

  const handleUpdateCampaign = () => {
    dispatch(
      editCampaign({
        originalId: params.campaignId,
        id: name
          .toLowerCase()
          .replace(/[^a-z0-9\s]/g, "")
          .replace(/\s/g, "_"),
        name,
        description
      })
    );
  };
  return (
    <MainContainer>
      <ProtectedRoute />
      <NavBarComponent
        leftText={"Cancel"}
        title={location.state?.field || ""}
        to={`/campaign-manager/${params.campaignId}`}
      />

      <Body>
        {location?.state?.field === "Name" && (
          <Input
            id="input-name"
            type="name"
            placeholder="Name"
            autoFocus
            required
            value={name}
            onChange={(ev) => {
              setName(ev.target.value);
            }}
          />
        )}
        {location?.state?.field === "Description" && (
          <TextArea
            rows={5}
            id="input-description"
            type="description"
            placeholder="Description"
            required
            value={description}
            onChange={(ev) => setDescription(ev.target.value)}
          />
        )}
        {location?.state?.field && (
          <Button onClick={() => handleUpdateCampaign()}>Save Changes</Button>
        )}
        {isError && (
          <ErrorMsg>
            {message ||
              "An error has occured, please try again later or contact support."}
          </ErrorMsg>
        )}
        {isLoading && <StyledSpinner />}
      </Body>
    </MainContainer>
  );
};

export default EditCampaign;
