import React, { useState, useEffect } from "react";
import boxChecked from "../img/box-checked.svg";
import boxUnchecked from "../img/box-unchecked.svg";

const Checkbox = ({ onChange }) => {
  const [checked, setChecked] = useState(false);

  useEffect(() => {
    onChange(checked);
  }, [checked, onChange]);

  return (
    <span
      style={{
        marginLeft: "8px",
        position: "relative",
        top: 4,
        cursor: "pointer"
      }}
      onClick={() => setChecked(!checked)}
    >
      <img src={checked ? boxChecked : boxUnchecked} alt="check icon" />
    </span>
  );
};

export default Checkbox;
