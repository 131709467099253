import styled from "styled-components";
import SuspenseImage from "../../../../components/SuspenseImage";

export const Container = styled.div`
  position: relative;
  width: 100%;

  margin-bottom: 59px;
`;
export const BrandImage = styled(SuspenseImage)`
  width: 100%;
  max-width: 100%;
  max-height: 100%;
  object-fit: cover;

  min-height: 390px;
  margin-top: 50px;
  @media (min-width: 768px) {
    margin-top: 0;
    min-height: 768px;
  }

  @media (min-width: 1440px) {
    min-height: 810px;
  }

  @media (min-width: 1920px) {
    min-height: 1080px;
  }
`;

export const DetailsContainer = styled.div`
  position: absolute;
  bottom: 60px;
  left: 60px;
  display: none;

  h2 {
    color: white;
    font-weight: 900;
    margin-bottom: 30px;
  }

  @media (min-width: 1440px) {
    display: block;
    h2 {
      font-size: 7.5rem;
      line-height: 7.5rem;
    }
  }
`;

export const DetailsContainerMobile = styled.div`
  padding: 30px 20px;

  h2 {
    font-weight: 600;
    font-size: 4.375rem;
    line-height: 4.375rem;
    margin-bottom: 10px;
  }

  p {
    font-style: normal;
    font-weight: 400;
    font-size: 1.125rem;
    line-height: 22px;
    color: #1d1d1f;
    margin-bottom: 30px;
  }

  @media (min-width: 768px) {
    padding: 40px 20px;
  }
`;

export const GoButton = styled.div`
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 160px;
  height: 40px;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.25rem;
  background: ${({ isSmallScreen }) =>
    isSmallScreen ? "#0000AC" : "rgba(249, 249, 252, 0.6)"};
  backdrop-filter: blur(16px);
  /* Note: backdrop-filter has minimal browser support */
  border-radius: 90px;
  color: ${({ isSmallScreen }) => (isSmallScreen ? "white" : "#0000ac")};
  a {
    text-decoration: none;
    color: ${({ isSmallScreen }) => (isSmallScreen ? "white" : "#0000ac")};
  }
`;
