import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";

import { getBalanceAndPin } from "../../features/balance-and-pin/balanceAndPinSlice";
import {
  activateTicket,
  claimDevice,
  getDeviceInfo,
  resetActivatingTicketError,
  reauthenticate
} from "../../features/hugo-boss/hugoBossSlice";
import usePrevious from "../../hooks/usePrevious";

import Spinner from "../../components/Spinner";
import { StyledSpinner } from "../../components/styled/Spinner";
import ProductInfo from "./ProductInfo";
import LanguageSelect from "./LanguageSelect";

import {
  MainContainer,
  NavBar,
  H1,
  H2,
  B1,
  Hero,
  Product,
  H3,
  ProductList,
  ServiceList,
  ServiceItem,
  Button,
  Schedule,
  B2,
  Footer,
  Specs,
  InfoAlert,
  FormContainer,
  Input,
  InputGroup,
  HeroTextContainer,
  ClaimBanner,
  HugoBossFonts,
  B3,
  EventInfo
} from "./styles";

import hugoLogo from "./img/hugo-logo.svg";
import heroImg from "./img/hero.webp";
import service1bg from "./img/service1.webp";
import service2bg from "./img/service2.webp";
import service3bg from "./img/service3.webp";
import service4bg from "./img/service4.webp";
import sleeveContactless from "./img/sleeve-contactless.png";
import hkrLogo from "./img/hkr2024.webp";
import closeIcon from "./img/closeIcon.svg";
import certifiedLogo from "./img/certified.svg";
import eventInfo from "./img/event-information.webp";
import facebook from "./img/facebook.svg";
import instagram from "./img/instagram.svg";
import pinterest from "./img/pinterest.svg";
import youtube from "./img/youtube.svg";
import appstore from "./img/appstore.svg";
import playstore from "./img/playstore.svg";

import { getDeviceByModel } from "../../features/devices/deviceSlice";

const HugoBossExperience = () => {
  const [searchParams] = useSearchParams();
  const serialNumber = searchParams.get("serialNumber");
  const type = searchParams.get("type");
  const dispatch = useDispatch();

  const [showClaimForm, setShowClaimForm] = useState(false);
  const [showLoginForm, setShowLoginForm] = useState(false);
  const [showActivateSki, setShowActivateSki] = useState(false);
  const [showActivateParty, setShowActivateParty] = useState(false);
  const [showActivateRace, setShowActivateRace] = useState(false);
  const [showPaymentBalance, setShowPaymentBalance] = useState(false);

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [pinCode, setPinCode] = useState("");
  const [formSubmitError, setFormSubmitError] = useState("");

  const { balanceAndPin, isLoading } = useSelector(
    (state) => state.balanceAndPin
  );

  const {
    device,
    gettingDeviceInfo,
    gettingDeviceInfoError, // TODO a global alert to display first get errors (no serial, no device info etc)
    claimingDevice,
    claimingDeviceError,
    activatingTicket,
    activatingTicketError,
    reauthenticating,
    reauthenticatingError
  } = useSelector((state) => state.hugoBoss);

  const { device: discoveryDevice } = useSelector((state) => state.devices);

  const prevClaimingDevice = usePrevious(claimingDevice);
  const preReauthenticating = usePrevious(reauthenticating);

  const { t } = useTranslation();

  function hasAuthExpired(lastAuthDate) {
    const currentDate = new Date();
    const inputDate = new Date(lastAuthDate);
    const timeDifference = currentDate - inputDate;
    const hoursDifference = timeDifference / (1000 * 60 * 60);
    return hoursDifference > 2;
  }

  useEffect(() => {
    if (!balanceAndPin && serialNumber) {
      dispatch(getBalanceAndPin({ serial: serialNumber, query: "" }));
    }
  }, [balanceAndPin, serialNumber, type, dispatch]);

  useEffect(() => {
    if (!device) {
      dispatch(getDeviceInfo(serialNumber));
    }
  }, [device, dispatch, serialNumber]);

  useEffect(() => {
    if (
      device?.model?.model &&
      device?.model?.brand &&
      device?.model?.version
    ) {
      dispatch(
        getDeviceByModel({
          brand: device.model.brand,
          model: device.model.model,
          version: device.model.version
        })
      );
    }
  }, [
    device?.model?.brand,
    device?.model?.model,
    device?.model?.version,
    dispatch
  ]);

  useEffect(() => {
    const wasItClaimed = localStorage.getItem("claimed");

    if (
      (device?.lastAuthenticatedTime &&
        hasAuthExpired(device?.lastAuthenticatedTime)) ||
      (!wasItClaimed && device?.claimedDate)
    ) {
      setShowLoginForm(true);
    }
  }, [device?.claimedDate, device?.lastAuthenticatedTime]);

  useEffect(() => {
    if (prevClaimingDevice && !claimingDeviceError) {
      localStorage.setItem("claimed", JSON.stringify(serialNumber));
      setShowClaimForm(false);
      dispatch(getDeviceInfo(serialNumber));
    }
  }, [claimingDeviceError, dispatch, prevClaimingDevice, serialNumber]);

  useEffect(() => {
    if (preReauthenticating && !reauthenticatingError) {
      localStorage.setItem("claimed", JSON.stringify(serialNumber));
      setShowLoginForm(false);
    }
  }, [preReauthenticating, reauthenticatingError, serialNumber]);

  const handleReauthenticate = () => {
    if (pinCode && serialNumber) {
      dispatch(
        reauthenticate({
          serial: serialNumber,
          claimPinCode: pinCode
        })
      );
    }
  };

  const formattedBalance = (availableBalance, currency) => {
    let iconPrefixed = true;
    let icon = "";

    switch (currency) {
      case "GBP":
        icon = "£";
        break;
      case "EUR":
        icon = "€";
        break;
      case "USD":
        icon = "$";
        break;
      default:
        icon = "";
    }

    if (iconPrefixed) {
      return icon + availableBalance;
    } else {
      return availableBalance + icon;
    }
  };

  const handleClaimFormSubmit = () => {
    setFormSubmitError("");

    if (firstName && lastName && email && pinCode) {
      dispatch(
        claimDevice({
          serial: serialNumber,
          firstName,
          lastName,
          email,
          claimPinCode: pinCode
        })
      );
    } else setFormSubmitError(t("hugoBossExperience.form.fillAllFields"));
  };

  if (isLoading || gettingDeviceInfo) return <Spinner />;

  const tickets = {
    PARTY: {
      backgroundImg: service1bg,
      title: t("hugoBossExperience.activateStore")
    },
    RACE: {
      backgroundImg: service2bg,
      title: t("hugoBossExperience.activateRace")
    },
    SKI: {
      backgroundImg: service3bg,
      title: t("hugoBossExperience.activateSki")
    }
  };

  const handleTicketActivation = (type) => {
    dispatch(resetActivatingTicketError());
    switch (type) {
      case "PARTY":
        setShowActivateParty(true);
        break;
      case "SKI":
        setShowActivateSki(true);
        break;
      case "RACE":
        setShowActivateRace(true);
        break;

      default:
        break;
    }
  };

  const getDeviceImage = () => {
    if (
      discoveryDevice?.variants?.length > 0 &&
      device?.model?.variant?.color
    ) {
      const deviceVariant = discoveryDevice.variants.find(
        (variant) => variant.color === device.model.variant.color
      );

      if (deviceVariant?.image) {
        return deviceVariant.image;
      }
    } else if (discoveryDevice?.defaultImage) {
      return discoveryDevice.defaultImage;
    }

    return null;
  };

  return (
    <>
      <HugoBossFonts />
      <MainContainer>
        <NavBar>
          <div />
          <img src={hugoLogo} alt="hugo boss logo" className="logo" />
          <LanguageSelect />
        </NavBar>
        {device?.personalDetails?.firstName && device?.claimedDate && (
          <H3 className="welcome-title">{`${t("hugoBossExperience.welcome")} ${
            device?.personalDetails?.firstName
          }`}</H3>
        )}
        <Hero bg={heroImg}>
          <div className="inner">
            <H1>{t("hugoBossExperience.welcomeTo")}</H1>
          </div>
        </Hero>
        {!device?.claimedDate && (
          <HeroTextContainer>
            <B1>{t("hugoBossExperience.product.description")}</B1>
            <B1>{t("hugoBossExperience.product.description2")}</B1>
          </HeroTextContainer>
        )}
        {!device?.claimedDate && (
          <ClaimBanner>
            <H2>{t("hugoBossExperience.claimTheJacket")}</H2>

            <Button onClick={() => setShowClaimForm(true)}>
              {t("hugoBossExperience.claim")}
            </Button>
          </ClaimBanner>
        )}

        {device?.claimedDate && (
          <>
            <Product>
              <H2>{t("hugoBossExperience.myJacket")}</H2>
              {discoveryDevice?.defaultImage && (
                <div className="img-container">
                  <img src={getDeviceImage()} alt="product" />
                </div>
              )}

              {discoveryDevice?.modelDisplayName && (
                <H3>{discoveryDevice?.modelDisplayName}</H3>
              )}
              {!device?.claimedDate && (
                <Button onClick={() => setShowClaimForm(true)}>
                  {t("hugoBossExperience.claim")}
                </Button>
              )}
            </Product>
            <ProductList>
              <ProductInfo title={t("hugoBossExperience.specification")}>
                <Specs>
                  <ul>
                    {device?.personalDetails?.firstName &&
                      device?.personalDetails?.lastName && (
                        <li>
                          <B2>{t("hugoBossExperience.product.owner")}</B2>
                          <B1>{`${device.personalDetails.firstName} ${device.personalDetails.lastName}`}</B1>
                        </li>
                      )}
                    {device?.size && (
                      <li>
                        <B2>{t("hugoBossExperience.product.size")}</B2>
                        <B1>{device.size}</B1>
                      </li>
                    )}
                    <li>
                      <B2>{t("hugoBossExperience.product.purchaseDate")}</B2>
                      <B1>{`${new Date(
                        device.claimedDate
                      ).toLocaleDateString()}`}</B1>
                    </li>
                    <li>
                      <B2>{t("hugoBossExperience.product.serialNumber")}</B2>
                      <B1>{`${serialNumber}`}</B1>
                    </li>
                    <li>
                      <B2>{t("hugoBossExperience.product.itemNo")}</B2>
                      <B1>{`${device.itemNumber}`}</B1>
                    </li>
                    <li>
                      <B2>{t("hugoBossExperience.product.authenticity")}</B2>
                      <div className="certified">
                        <img src={certifiedLogo} alt="certified" />
                        <B1>{t("hugoBossExperience.product.certified")}</B1>
                      </div>
                    </li>
                  </ul>
                </Specs>
              </ProductInfo>
              <ProductInfo title={t("hugoBossExperience.details")}>
                <B2>{t("hugoBossExperience.product.detail1")}</B2>
                <B2>{t("hugoBossExperience.product.detail2")}</B2>
                <B2>{t("hugoBossExperience.product.filling")} </B2>
                <B2>{t("hugoBossExperience.product.doNotWash")}</B2>
                <B2>{t("hugoBossExperience.product.doNotBleach")}</B2>
                <B2>{t("hugoBossExperience.product.doNotTumbleDry")}</B2>
                <B2>{t("hugoBossExperience.product.ironLowHeat")}</B2>
                <B2>{t("hugoBossExperience.product.doNotChemClean")}</B2>
                <B2>{t("hugoBossExperience.product.wetClean")}</B2>
              </ProductInfo>
            </ProductList>
            <ServiceList>
              <H2>{t("hugoBossExperience.myBoss")}</H2>
              {device?.promoReadyTickets?.length > 0 &&
                [
                  ...new Set(
                    device.promoReadyTickets.map((item) => item.ticketType)
                  )
                ].map((e, i) => (
                  <ServiceItem
                    key={i}
                    bgImg={tickets[e].backgroundImg}
                    onClick={() => handleTicketActivation(e)}
                  >
                    <H3>{tickets[e].title}</H3>
                  </ServiceItem>
                ))}
              <ServiceItem
                bgImg={service4bg}
                onClick={() =>
                  balanceAndPin?.balanceDetails?.availableBalance
                    ? setShowPaymentBalance(true)
                    : null
                }
              >
                <H3>{t("hugoBossExperience.useForPayments")}</H3>
                {balanceAndPin?.balanceDetails?.availableBalance && (
                  <H3>
                    {formattedBalance(
                      balanceAndPin.balanceDetails.availableBalance,
                      balanceAndPin?.balanceDetails.currency
                    )}
                  </H3>
                )}
              </ServiceItem>
            </ServiceList>
            <Schedule>
              <img src={hkrLogo} alt="hkr 2024" />
              <EventInfo
                bgImg={eventInfo}
                onClick={() => window.open("https://hahnenkamm.com/", "_blank")}
              >
                <H3>{t("hugoBossExperience.eventInformation")}</H3>
              </EventInfo>
            </Schedule>
          </>
        )}

        <Footer>
          <B2>{t("hugoBossExperience.followUs")}</B2>
          <div className="socials">
            <img src={instagram} alt="instagram" />
            <img src={facebook} alt="facebook" />
            <img src={pinterest} alt="pinterest" />
            <img src={youtube} alt="youtube" />
          </div>
          <B2>{t("hugoBossExperience.hugoBossApp")}</B2>
          <div className="app-stores">
            <img src={appstore} alt="appstore" />
            <img src={playstore} alt="playstore" />
          </div>
          <div className="links">
            <B3
              onClick={() =>
                window.open(
                  "https://www.hugoboss.com/de/en/about-hugo-boss/privacy-statement-experience/privacy-statement-experience.html",
                  "_blank"
                )
              }
            >
              {t("hugoBossExperience.privacyHBExperience")}
            </B3>
            <B3>{t("hugoBossExperience.privacyNewsletter")}</B3>
            <B3
              onClick={() =>
                window.open(
                  "https://www.hugoboss.com/de/en/about-hugo-boss/terms-and-conditions-experience/terms-conditions-experience.html",
                  "_blank"
                )
              }
            >
              {t("hugoBossExperience.termsAndConditions")}
            </B3>
            <B3 className="copyright">
              {t("hugoBossExperience.allRightsReserved")}
            </B3>
          </div>
        </Footer>

        {showClaimForm && (
          <InfoAlert>
            <div className="inner-container">
              <img
                className="close-button"
                src={closeIcon}
                alt="close-icon"
                onClick={() => setShowClaimForm(false)}
              />
              <H2>{t("hugoBossExperience.form.claimMyJacket")}</H2>
              {discoveryDevice?.modelDisplayName && (
                <B1>{discoveryDevice?.modelDisplayName}</B1>
              )}
              <FormContainer>
                <InputGroup row>
                  <Input
                    placeholder={`*${t("hugoBossExperience.form.firstName")}`}
                    value={firstName}
                    onChange={(ev) => setFirstName(ev.target.value)}
                  />
                  <Input
                    placeholder={`*${t("hugoBossExperience.form.lastName")}`}
                    value={lastName}
                    onChange={(ev) => setLastName(ev.target.value)}
                  />
                </InputGroup>
                <InputGroup>
                  <Input
                    placeholder={`*${t("hugoBossExperience.form.email")}`}
                    value={email}
                    onChange={(ev) => setEmail(ev.target.value)}
                    type="email"
                  />
                  <Input
                    placeholder={`*${t("hugoBossExperience.form.passCode")}`}
                    value={pinCode}
                    onChange={(ev) => setPinCode(ev.target.value)}
                  />
                </InputGroup>
                {!claimingDevice && (
                  <Button type="button" onClick={() => handleClaimFormSubmit()}>
                    {t("hugoBossExperience.claim")}
                  </Button>
                )}
                <div className="error">{formSubmitError}</div>
                <div className="error">{claimingDeviceError}</div>

                {claimingDevice && <StyledSpinner />}
              </FormContainer>
            </div>
          </InfoAlert>
        )}
        {showLoginForm && (
          <InfoAlert>
            <div className="inner-container">
              <H2>
                {`${t("hugoBossExperience.welcome")} 
                  ${device?.personalDetails?.firstName?.toUpperCase()}`}
              </H2>
              <FormContainer>
                <InputGroup row>
                  <Input
                    placeholder="Enter your 6 digits passcode"
                    value={pinCode}
                    onChange={(ev) => setPinCode(ev.target.value)}
                  />
                </InputGroup>
                {/* <B1 className="forgot-password">Forgot your passcode?</B1> */}
                <Button type="button" onClick={() => handleReauthenticate()}>
                  {t("hugoBossExperience.login")}
                </Button>
              </FormContainer>
              {reauthenticatingError && (
                <div className="error">{reauthenticatingError}</div>
              )}
              {reauthenticating && <StyledSpinner />}
            </div>
          </InfoAlert>
        )}
        {showActivateSki && (
          <InfoAlert>
            <div className="inner-container text-left">
              <img
                className="close-button"
                src={closeIcon}
                alt="close-icon"
                onClick={() => setShowActivateSki(false)}
              />
              <img src={service3bg} alt="ski" />
              <H2>{t("hugoBossExperience.activateSki")}</H2>
              {device?.promoReadyTickets?.length > 0 &&
                device.promoReadyTickets.map((ticket) => {
                  if (ticket.ticketType === "SKI")
                    return (
                      <div className="ski-ticket" key={ticket.ticketId}>
                        <B1>{t("hugoBossExperience.superSkiCard1day")}</B1>
                        <B1>
                          {t("hugoBossExperience.validForDay")}{" "}
                          {ticket.validForDayNumber}
                        </B1>
                        {ticket.ticketStatus === "NON_ACTIVE" ? (
                          <Button
                            onClick={() =>
                              dispatch(
                                activateTicket({
                                  serialNumber,
                                  ticketId: ticket.ticketId
                                })
                              )
                            }
                          >
                            {t("hugoBossExperience.activate")}
                          </Button>
                        ) : (
                          <div className="ticket-activated">
                            {t("hugoBossExperience.ticketActivated")}
                          </div>
                        )}
                        <br />
                        <div className="ski-warning">
                          <B2>
                            {t("hugoBossExperience.skiActivationWarning")}
                          </B2>
                          <img
                            src={sleeveContactless}
                            alt="contactless symbol"
                          />
                        </div>
                      </div>
                    );
                  return null;
                })}
              <div className="error">{activatingTicketError}</div>
              {activatingTicket && <StyledSpinner />}
              {/*  TODO replace with a small on on the button */}
            </div>
          </InfoAlert>
        )}
        {showActivateParty && (
          <InfoAlert>
            <div className="inner-container text-left">
              <img
                className="close-button"
                src={closeIcon}
                alt="close-icon"
                onClick={() => setShowActivateParty(false)}
              />
              <img src={service1bg} alt="party" />
              <H2>{t("hugoBossExperience.activateStore")}</H2>
              <B1>{t("hugoBossExperience.storeDetails")}</B1>
              {device?.promoReadyTickets?.length > 0 &&
                device.promoReadyTickets.map((ticket) => {
                  if (ticket.ticketType === "PARTY")
                    return (
                      <div className="ski-ticket" key={ticket.ticketId}>
                        {ticket.ticketStatus === "NON_ACTIVE" ? (
                          <Button
                            onClick={() =>
                              dispatch(
                                activateTicket({
                                  serialNumber,
                                  ticketId: ticket.ticketId
                                })
                              )
                            }
                          >
                            {t("hugoBossExperience.activate")}
                          </Button>
                        ) : (
                          <div className="ticket-activated">
                            {t("hugoBossExperience.ticketActivated")}
                          </div>
                        )}
                      </div>
                    );
                  return null;
                })}
              <div className="error">{activatingTicketError}</div>
              {activatingTicket && <StyledSpinner />}
            </div>
          </InfoAlert>
        )}
        {showActivateRace && (
          <InfoAlert>
            <div className="inner-container text-left">
              <img
                className="close-button"
                src={closeIcon}
                alt="close-icon"
                onClick={() => setShowActivateRace(false)}
              />
              <img src={service2bg} alt="race" />
              <H2>{t("hugoBossExperience.activateRace")}</H2>
              <B1>{t("hugoBossExperience.activateRaceDescription")}</B1>
              {device?.promoReadyTickets?.length > 0 &&
                device.promoReadyTickets.map((ticket) => {
                  if (ticket.ticketType === "RACE")
                    return (
                      <div className="ski-ticket" key={ticket.ticketId}>
                        {ticket.ticketStatus === "NON_ACTIVE" ? (
                          <Button
                            onClick={() =>
                              dispatch(
                                activateTicket({
                                  serialNumber,
                                  ticketId: ticket.ticketId
                                })
                              )
                            }
                          >
                            {t("hugoBossExperience.activate")}
                          </Button>
                        ) : (
                          <div className="ticket-activated">
                            {t("hugoBossExperience.ticketActivated")}
                          </div>
                        )}
                      </div>
                    );
                  return null;
                })}
              <div className="error">{activatingTicketError}</div>
              {activatingTicket && <StyledSpinner />}
              {/*  TODO replace with a small on on the button */}
            </div>
          </InfoAlert>
        )}
        {showPaymentBalance && (
          <InfoAlert>
            <div className="inner-container text-left">
              <H2>
                {balanceAndPin?.balanceDetails?.availableBalance
                  ? formattedBalance(
                      balanceAndPin.balanceDetails.availableBalance,
                      balanceAndPin?.balanceDetails.currency
                    )
                  : ""}
              </H2>
              <B1>{t("hugoBossExperience.availableCredit")}</B1>

              <B2>{t("hugoBossExperience.paymentDescription")}</B2>
              <B2
                className="link"
                onClick={() =>
                  window.open(
                    "https://bobofin.com/wp-content/uploads/2024/01/TCs-Bourgeois-Boheme-Events.pdf",
                    "_blank"
                  )
                }
              >
                {t("hugoBossExperience.paymentTerms")}
              </B2>

              <Button onClick={() => setShowPaymentBalance(false)}>
                {t("hugoBossExperience.close")}
              </Button>
            </div>
          </InfoAlert>
        )}
      </MainContainer>
    </>
  );
};

export default HugoBossExperience;
