import { StyledLogo } from "./styled/StyledLogo";

const Logo = ({ isfooter }) => {
  return (
    <StyledLogo isfooter={isfooter} to="/">
      MANAGE-MII
    </StyledLogo>
  );
};

export default Logo;
