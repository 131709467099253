import styled from "styled-components";

export const MainContainer = styled.div`
  padding: 16px 0;
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 480px;
  margin: auto;
  min-height: 100vh;
  background-color: #000;

  h1 {
    font-family: "Gotham Rounded Light";
    font-size: 36px;
    font-style: normal;
    font-weight: 400;
    line-height: 110%; /* 39.6px */
    text-transform: uppercase;
  }
`;

export const Navbar = styled.nav`
  padding: 0 22px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  padding-bottom: 30px;

  img {
    height: 42px;
    object-fit: contain;
  }

  .balance {
    p {
      color: white;
      text-align: right;
      font-family: "Gotham Rounded Light";
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      text-transform: uppercase;
    }
  }
`;

export const Hero = styled.div`
  .img-container {
    margin: 0 22px;
    position: relative;
  }
  img {
    object-fit: contain;
    width: 100%;
    aspect-ratio: 346/394;
  }

  h1 {
    color: white;
    position: absolute;
    bottom: 20px;
    left: 20px;
    width: 80%;
  }
`;

export const BottomSection = styled.div`
  z-index: 1;
  width: 100%;
  height: ${({ height }) => `${height}px`};
  margin-top: ${({ margin }) => `-${margin}px`};
  background-color: ${({ color }) => color};
`;

export const Explore = styled.div`
  width: 100%;
  background-color: #081634;
  p {
    color: white;
    font-family: "Gotham Rounded Light";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 130%;
    margin-bottom: 20px;
  }
  .inner {
    padding: 40px 22px 0;
  }
  .img-container {
    width: 100%;
  }
  img {
    object-fit: contain;
    width: 100%;
    aspect-ratio: 346/394;
  }
`;

export const Button = styled.div`
  display: flex;
  height: 59px;
  justify-content: center;
  align-items: center;
  align-self: stretch;
  border-radius: 37.5px;
  background-color: ${({ secondary }) => (secondary ? "#C87B35" : "#FFF")};
  text-align: center;
  font-family: "Gotham Rounded Medium";
  font-size: 20px;
  font-style: normal;
  line-height: 100%; /* 20px */
  text-transform: uppercase;
  color: ${({ secondary }) => (secondary ? "#fff" : "#000")};
  margin-bottom: ${({ secondary }) => (secondary ? 0 : "16px")};
`;

export const Highlights = styled.div`
  background-color: #081634;
  width: 100%;
  .text-container {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 40px 22px;
    p,
    h2 {
      color: white;
    }
    p {
      font-family: "Gotham Rounded Light";
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 130%;
    }
    h2 {
      font-family: "Gotham Rounded Light";
      font-size: 32px;
      font-style: normal;
      font-weight: 400;
      line-height: 110%; /* 35.2px */
      text-transform: uppercase;
    }
  }
  .images {
    background-color: white;
    display: flex;
    flex-direction: column;
    padding: 60px 22px 42px;
    gap: 16px;
  }
  img {
    width: 100%;
  }
`;

export const Schedule = styled.div`
  background-color: white;

  .header {
    padding: 40px 22px 22px;
    background-color: #c87b35;
    margin: 60px 22px 0;
    p {
      color: white;
      font-family: "Gotham Rounded Light";
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 110%; /* 17.6px */
      text-transform: uppercase;
    }
  }
`;

export const List = styled.ul`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 14px;
  padding: 40px 22px;
  background-color: #081634;
  margin: 0 22px;
`;

export const Item = styled.li`
  display: flex;
  flex-direction: column;
  border-bottom: ${({ isLast }) => (isLast ? "none" : "1px solid #C87B35")};

  p {
    font-family: "Gotham Rounded Light";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 130%;
    color: white;
  }
  .time {
    color: #c87b35;
  }
`;

export const YourJourney = styled.div`
  width: 100%;
  .logo {
    height: 42px;
    width: 133px;
  }

  img {
    width: 100%;
  }

  .intro,
  .connect,
  .activation {
    padding: 30px 22px;
    display: flex;
    flex-direction: column;
    justify-content: left;
    gap: 30px;
    p,
    h2,
    h3 {
      color: white;
      font-family: "Gotham Rounded Light";
    }

    p {
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 130%;
    }

    h2 {
      font-size: 32px;
      font-style: normal;
      font-weight: 400;
      line-height: 110%;
      text-transform: uppercase;
    }
  }

  .intro,
  .activation {
    background-color: #081634;
  }
  .connect {
    background-color: #c87b35;
    h3 {
      font-family: "Gotham Rounded Medium";
      font-size: 20px;
      font-style: normal;
      font-weight: 500;
      line-height: 110%;
    }

    img {
      max-width: 300px;
      margin: -24px auto 0;
    }
  }
  .activation {
    padding: 40px 22px;
    text-align: center;
    .copy {
      height: 21px;
      width: 21px;
      cursor: pointer;
    }
    .code {
      display: flex;
      padding: 16.5px 0px;
      justify-content: center;
      align-items: center;
      gap: 16px;
      flex: 1 0 0;
      align-self: stretch;
      border-radius: 15px;
      border: 1px solid #fff;
      color: #fff;
      span {
        font-family: "Gotham Rounded Medium";
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        line-height: 110%;
      }
    }
  }
`;

export const Footer = styled.footer`
  padding: 40px 22px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
  p {
    color: white;
    font-family: "Gotham Rounded Light";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 130%;
    text-decoration: underline;
    cursor: pointer;
  }
  img {
    width: 215px;
  }
`;
