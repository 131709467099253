import React from "react";
import { useMediaQuery } from "react-responsive";
import { StyledMain } from "../../styled/StyledMain";
import { MainContainer } from "../../styled/StyledMainContainer";

import ProductsTable from "./components/ProductsTable";
import FeaturedProducts from "./components/FeaturedProducts";
import FeaturedBrand from "../brands/components/FeaturedBrand";
import TopSectionWithFilter from "../../components/TopSectionWithFilter";
import { useSelector } from "react-redux";
import FilterBar from "./components/FilterBar";
import Header from "../../components/Header";
import Footer from "../../components/Footer";

const Products = () => {
  const isSmallScreen = useMediaQuery({ query: "(max-width: 1440px)" });
  const { devices } = useSelector((state) => state.devices);

  return (
    <>
      <Header />
      <StyledMain>
        <MainContainer data-testid="products">
          <FeaturedBrand />

          {!isSmallScreen && (
            <TopSectionWithFilter
              headerText={
                "We support over one hundred payment-ready wearables designed by fashion brands."
              }
              device={devices[Math.floor(Math.random() * devices.length)]}
            />
          )}

          <FilterBar />
          <FeaturedProducts />
          <ProductsTable />
        </MainContainer>
      </StyledMain>
      <Footer />
    </>
  );
};

export default Products;
