import styled from "styled-components";
import { fonts } from "../../tap2start/styles/SharedStyles";

export const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 480px;
  margin: auto;
  min-height: 100vh;
  background-color: #f6f6f7;
`;

export const DigiseqLogoContainer = styled.div`
  background-color: #fff;
  border-bottom: 1px solid #e0e0e0;

  img {
    margin-left: 16px;
    margin-top: 16px;
    margin-bottom: 8px;
    height: 40px;
  }
`;

export const CurvelogoContainer = styled.div`
  margin-bottom: 16px;
  img {
    height: 32px;
  }
`;

export const ProductContainer = styled.section`
  display: flex;
  padding: 40px 24px 40px 24px;
  flex-direction: column;
  align-items: center;
  gap: 40px;
  align-self: stretch;
`;

export const ProductImageSkeletonContainer = styled.section`
  display: flex;
  padding: 40px 24px 40px 24px;
  flex-direction: column;
  align-items: center;
  gap: 40px;
  align-self: stretch;
`;

export const ContentSkeletonContainer = styled.section`
  padding: 0px 16px 16px 16px;
`;

export const SkeletonContainer = styled.section`
  margin-top: 8px;
`;

export const ImageContainer = styled.section`
  border-radius: 16px;
  box-shadow: 0px 32px 80px rgba(16, 22, 40, 0.1);
  display: flex;
  width: 160px;
  height: 160px;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  img {
    ${({ noImage }) =>
      noImage
        ? ` width:  #82px;  height: 82px;`
        : ` width:  #127.6px;  height: 127.6px;`}
  }
`;

export const NextStepsContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  margin-left: 16px;
  margin-right: 16px;
  padding: 16px;
  background-color: white;
  border-radius: 16px;
`;

export const StepContainer = styled.div`
  h2 {
    margin-top: 16px;
  }
  p {
    margin-top: 16px;
  }
  img {
    margin-top: 24px;
    max-width: 70%;
    max-height: 90%;
  }
`;

export const StepNumber = styled.div`
  display: flex;
  width: 40px;
  padding: 8px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 360px;
  background: #f6f6f7;
`;

export const SectionContainer = styled.section`
  display: flex;
  flex-direction: column;
  gap: 12px;
  border-radius: 16px;
  background-color: white;
  overflow: hidden;
  margin: 16px;
  .inner {
    display: flex;
    flex-direction: column;
    padding: 24px 24px 32px;
    gap: 12px;
    .apps {
      display: flex;
      gap: 8px;
      cursor: pointer;
    }
    .digiseq {
      color: #ff6400;
      margin-left: 8px;
    }
    .smart-chip {
      margin-left: 8px;
    }
    .st {
      font-weight: 700;
    }
  }

  ul {
    margin-top: 0;
    margin-left: 24px;
  }
`;

export const ShowMore = styled.span`
  font-family: "Inter";
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0.15px;
  text-decoration-line: underline;
  cursor: pointer;
`;

export const Socials = styled.div`
  margin-top: 16px;
  display: flex;
  align-items: center;
  gap: 16px;

  img {
    cursor: pointer;
  }
`;

export const Link = styled.a`
  ${fonts.B4}
  text-decoration: underline;
  cursor: pointer;
  color: #ff6400;
`;

export const Footer = styled.footer`
  margin-top: 24px;
  padding: 32px 16px 64px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  background-color: white;
  width: 100%;

  .digiseq {
    color: #ff6400;
  }
  .links {
    display: flex;
    flex-direction: column;
    gap: 12px;
    margin: 24px 0 32px;
  }
`;
