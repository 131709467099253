import styled from "styled-components";
import SuspenseImage from "../../../../components/SuspenseImage";

export const MainContainer = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

export const ProductsContainer = styled.div`
  padding: 0 16px;
  display: grid;
  width: 100%;
  align-content: center;
  justify-items: center;
  gap: 16px;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  margin-bottom: ${(props) => (props.featured ? "40px" : 0)};

  @media (min-width: 768px) {
    padding: 0 20px;
    display: grid;
    gap: 20px;
  }

  @media (min-width: 1440px) {
    padding: 0 60px;
    gap: 40px;
    grid-template-columns: ${(props) =>
      props.featured
        ? "repeat(2, minmax(0, 1fr))"
        : "repeat(3, minmax(0, 1fr))"};
  }
`;

export const WearableContainer = styled.div`
  display: flex;
  flex-direction: column;
  cursor: pointer;
  width: 100%;
  justify-content: center;
  align-items: center;
`;

export const ImageContainer = styled.div`
  border-radius: 30px;
  background-color: #f5f6f7;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 30px;
  overflow: hidden;

  @media (min-width: 640px) {
  }
`;

export const Image = styled(SuspenseImage)`
  width: 100%;
  height: 100%;
  max-width: 100%;
  max-height: 100%;
  aspect-ratio: 1;
  object-fit: contain;
`;

export const Brand = styled.div`
  align-self: flex-start;
  color: #797b80;
  margin-bottom: 5px;
  font-size: 0.875rem;
  @media (min-width: 640px) {
  }
`;

export const Model = styled.div`
  align-self: flex-start;
  margin-top: 10px;
  font-size: 0.875rem;
  @media (min-width: 640px) {
  }
`;

export const DetailsButton = styled.div`
  position: relative;
  cursor: pointer;
  top: 12%;
  left: 80%;
  @media (min-width: 640px) {
    top: 10%;
    left: 135%;
  }
`;
