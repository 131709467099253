import React, { useState } from "react";
import i18n from "i18next";
import gbFlag from "./img/GB-flag.svg";
import deFlag from "./img/DE-flag.svg";
import chevronDownWhite from "./img/chevron-down-white.svg";
import chevronUpWhite from "./img/chevron-up-white.svg";
import invChevronUp from "./img/chevron-up.svg";
import { B2, LanguageSelector } from "./styles";

const LanguageSelect = () => {
  const [lang, setLang] = useState("en");
  const [select, setSelect] = useState(false);

  const handleLanguageChange = (language) => {
    setLang(language);
    i18n.changeLanguage(language);
    setSelect(false);
  };

  return (
    <LanguageSelector>
      {!select ? (
        <div
          className="lang-select"
          onClick={() => setSelect(!select)}
          role="button"
          tabIndex={0}
        >
          <img src={lang === "en" ? gbFlag : deFlag} alt="flag" />
          <B2>{lang === "en" ? "GB" : "DE"}</B2>
          <img src={select ? chevronUpWhite : chevronDownWhite} alt="chevron" />
        </div>
      ) : (
        <div>
          <div
            className="lang-select"
            onClick={() => handleLanguageChange("en")}
            role="button"
            tabIndex={0}
          >
            <img src={gbFlag} alt="flag" />
            <B2>{"GB"}</B2>
            <img src={chevronUpWhite} alt="chevron" />
          </div>
          <div
            className="lang-select"
            onClick={() => handleLanguageChange("de")}
            role="button"
            tabIndex={0}
          >
            <img src={deFlag} alt="flag" />
            <B2>{"DE"}</B2>
            <img src={invChevronUp} alt="chevron" />
          </div>
        </div>
      )}
    </LanguageSelector>
  );
};

export default LanguageSelect;
