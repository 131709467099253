import styled from "styled-components";
import { B3, H2, theme } from "./SharedStyles";

export const DownloadAppContainer = styled.div`
  p {
    margin-top: 16px;
  }
  .label {
    margin-top: 16px;
    margin-right: 8px;
    font-family: "Adam Medium";
    font-size: "16px";
    line-height: "24px";
    font-weight: 500;
    color: ${theme.colors.medEmphasis};
  }

  a {
    text-decoration: underline;
    font-weight: bold;
    margin-left: 8px;
    color: ${theme.colors.medEmphasis};
  }
`;

export const ResultTextContainer = styled.div`
  display: flex;
  flex: 1;
  padding: 16px 0px 0px 0px;
  border-radius: 8px;
`;

export const Result = styled.div`
  display: flex;
  flex: 1;
  padding: 16px;
  background-color: #f6f6f7;
  gap: 16px;
  border-radius: 8px;
  align-items: flex-start;
  p {
    color: #626262;
  }
`;

export const NextStepsContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  margin-top: 8px;
`;

export const StepContainer = styled.div`
  h2 {
    margin-top: 16px;
  }
  p {
    margin-top: 16px;
  }
  img {
    margin-top: 24px;
    max-width: 70%;
    max-height: 90%;
  }
`;

export const StepNumber = styled.div`
  display: flex;
  width: 40px;
  padding: 8px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 360px;
  background: #f6f6f7;
`;

export const CardCheckContainer = styled.section`
  margin-top: 16px;
  img {
    margin-top: 24px;
    width: 158.4px;
    height: 96px;
  }
`;

export const AppStoreLinks = styled.section`
  img {
    margin-top: 24px;
    margin-right: 16px;
  }
`;

export const BinEntryContainer = styled.div`
  display: flex;
  margin-top: 16px;
`;

export const FormContainer = styled.div`
  display: flex;
  margin-top: 16px;
`;

export const InputContainer = styled.div`
  display: flex;
  flex: 1;
  padding: 16px;
  background-color: #f6f6f7;
  gap: 16px;
  border-radius: 8px;

  ${({ error }) => error && `border:  1px solid red;`}

  input {
    font-family: Adam Medium;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 150% */
    letter-spacing: 0.5px;
    border: none;
    width: 100%;
    background-color: inherit;
  }

  input:focus {
    outline: none;
  }

  .error-msg {
    ${B3}
    text-align: left;
    color: red;
    margin-top: -8px;
    margin-bottom: 16px;
  }
`;

export const InputField = styled.div`
  font-family: Adam Medium;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.5px;
  border: none;
  width: 100%;
  background-color: inherit;

  &:focus {
    outline: none;
  }
`;

export const ErrorMessage = styled.div`
  ${B3}
  text-align: left;
  color: red;
  margin-top: 8px;
`;

export const CheckBinButton = styled.div`
  width: 56px;
  height: 56px;
  text-align: center;
  background-color: ${theme.colors.primary};
  border-radius: 8px;
  cursor: pointer;
  margin-left: 16px;
  color: white;
  padding: 16px 0;

  ${({ disabled }) =>
    disabled &&
    ` background-color:  #ccc;  cursor: not-allowed; pointer-events: none;`}
`;

export const SectionContainer = styled.section`
  padding: 16px 16px;
  background-color: #282827;
  margin: 0px 0px 8px;
  border-radius: 6px;

  .title-bar {
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    h2 {
      ${H2}
      color: white;
    }
    img {
      cursor: pointer;
    }
  }

  .content-text {
    display: flex;
    width: 100%;
    flex-direction: column;
    margin-top: 8px;
    justify-content: flex-start; /* Set this property */

    img {
      width: 150px;
      height: 30px;
      ${({ fullWidth }) => fullWidth && `width:  280px;`}
      object-fit: contain;
      justify-content: flex-start; /* Set this property */
      justify-self: "flex-start";

      margin-bottom: 8px;
    }

    .country-logo {
      img {
        width: 40px;
        margin-top: 8px;
        margin-right: 8px;
      }
    }
  }
`;
