import Navbar from "./Navbar";

import { Footer, MainContainer } from "./styled/SharedStyles";

import {
  B1,
  ContactlessPayments,
  H1,
  H2,
  Header,
  HeroImage,
  InnerContainer,
  MerchStore
} from "./styled/AfterEvent";
import {
  AppStoreLinks,
  CurvelogoContainer,
  NextStepsContainer,
  StepContainer,
  StepNumber
} from "./styled/CagedSteelPromo";

import bannerImg from "./img/after-event-banner.webp";
import manageMiiApp from "./img/manage-mii-app.webp";
import storeBanner from "./img/store-banner.webp";
import appStore from "./img/app-store.svg";
import googlePlay from "./img/playstore.svg";
import curveLogo from "./img/curve_logo.png";
import payAnyWhereImage from "./img/pay-anywhere-image.png";
import euFlag from "./img/eu-flag.png";
import ukFlag from "./img/uk-flag.png";
import appStoreLogo from "./img/app-store.svg";
import gplayLogo from "./img/play-store.svg";

const AfterEvent = () => {
  const appStoreLinks = (appStoreUrl, playStoreUrl) => {
    return (
      <AppStoreLinks>
        <img
          src={appStoreLogo}
          alt="app-store"
          onClick={() => window.open(appStoreUrl, "_blank")}
        />
        <img
          src={gplayLogo}
          alt="play-store"
          onClick={() => window.open(playStoreUrl, "_blank")}
        />
      </AppStoreLinks>
    );
  };
  return (
    <MainContainer>
      <Navbar />

      <HeroImage src={bannerImg} alt="caged steel banner" />

      <InnerContainer>
        <Header>
          <H1>WE HOPE YOU ENJOYED THE CAGED STEEL EXPERIENCE!</H1>
          <B1>
            Your support means the world to us. Make sure to come back and
            relive the thrill of live MMA action once again.
          </B1>
        </Header>
        <ContactlessPayments>
          <HeroImage src={manageMiiApp} />
          <div className="manage-mii">
            <H2>USE YOUR CAGED STEEL KEY FOB FOR CONTACTLESS PAYMENTS</H2>
            <B1>
              Download the Manage-Mii app to connect your payment card to your
              key fob.
            </B1>
            <div className="store-apps">
              <img
                src={appStore}
                alt="app-store"
                onClick={() =>
                  window.open(
                    "https://apps.apple.com/gb/app/manage-mii/id6446908503",
                    "_blank"
                  )
                }
              />
              <img
                src={googlePlay}
                alt="app-store"
                onClick={() =>
                  window.open(
                    "https://play.google.com/store/apps/details?id=com.digiseq.managemii",
                    "_blank"
                  )
                }
              />
            </div>
          </div>
        </ContactlessPayments>
        <MerchStore>
          <HeroImage src={storeBanner} />
          <div className="store">
            <H2>VISIT OUR MERCH STORE</H2>
            <B1>
              Explore our latest products at{" "}
              <a
                href="https://bit.ly/cs-merchandise"
                target="_blank"
                rel="noopener noreferrer"
              >
                https://bit.ly/cs-merchandise
              </a>
              .
            </B1>
          </div>
        </MerchStore>
      </InnerContainer>
      <NextStepsContainer>
        <CurvelogoContainer>
          {/* <img className="separator" src={orSeparator} alt="or" /> */}
          <p className="title">Link your card</p>
          <div className="logos">
            <img className="curve-logo" src={curveLogo} alt="curve logo" />
            <img className="flag" src={euFlag} alt="eu flag" />
            <img className="flag" src={ukFlag} alt="uk flag" />
          </div>
        </CurvelogoContainer>
        <StepContainer>
          <StepNumber>1</StepNumber>
          <h2>Create a Curve Account</h2>
          <p>
            Register your card details with Curve to receive a free compatible
            digital bank card.
          </p>
          <p>Download the Curve App:</p>
          {appStoreLinks(
            "https://apps.apple.com/gb/app/curve-supercharge-your-money/id1049397112",
            "https://play.google.com/store/apps/details?id=com.imaginecurve.curve.prd"
          )}
        </StepContainer>
        <StepContainer>
          <StepNumber>2</StepNumber>
          <h2>Link Your Curve Virtual Card Using the Manage-Mii App</h2>
          <p>
            Follow the instructions on the Manage-Mii app and use your new Curve
            Card details when prompted.
          </p>
          {appStoreLinks(
            "https://apps.apple.com/app/manage-mii/id6446908503",
            "https://play.google.com/store/apps/details?id=com.digiseq.managemii"
          )}
        </StepContainer>
        <StepContainer>
          <StepNumber>3</StepNumber>
          <h2>Pay Anywhere!</h2>
          <p>
            Tap your wearable at any card terminal where Mastercard Contactless
            is enabled.
          </p>
          <img
            className="pay-anywhere"
            src={payAnyWhereImage}
            alt="pay anywhere"
          />
        </StepContainer>
      </NextStepsContainer>

      <Footer>
        Powered by
        <a
          href="https://www.digiseq.co.uk"
          target="_blank"
          rel="noopener noreferrer"
        >
          DIGISEQ
        </a>
      </Footer>
    </MainContainer>
  );
};

export default AfterEvent;
