import styled from "styled-components";

export const FormContainer = styled.form`
  padding: 30px;
  width: 100%;

  @media (min-width: 768px) {
    padding: 30px 125px;
  }
`;
