import styled from "styled-components";

export const Backdrop = styled.div`
  padding-top: 16px;
  background-color: rgba(9, 9, 15, 0.5);
  margin-top: 50px;
`;

export const BackdropContainer = styled.section`
  max-width: 600px;
  margin: auto;
  background-color: white;
  border-radius: 14px 14px 0px 0px;
  padding-bottom: 100px;
`;

export const Trigger = styled.div`
  padding: 16px;
  color: #1d1d1f;
  background: #ffffff;
  font-size: 1.125rem;
  font-weight: 500;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  border-bottom: 2px solid #f7f8fa;
`;

export const LinkInput = styled.div`
  border: 1px solid #dadbe6;
  border-radius: 10px;
  padding: 16px 0 16px 20px;
  height: 54px;
  width: 100%;
  margin-bottom: 20px;
  font-size: 1.125rem;
  color: #1d1d1f;
  display: flex;
  justify-content: flex-start;
  align-items: baseline;

  input {
    flex-grow: 1;
    border: none;
  }
`;

export const TransitionTypeContainer = styled.div`
  margin-bottom: 16px;
  border-radius: 12px;
  overflow: hidden;
  left: 20px;
`;

export const TransitionDescription = styled.div`
  text-align: left;
  font-size: 1rem;
  color: #8d8e99;
  font-weight: 500;
  margin-bottom: 10px;
`;

export const InputDateTime = styled.input`
  border: 1px solid #dadbe6;
  border-radius: 10px;
  padding: 16px 20px;
  height: 66px;
  width: 100%;
  margin-bottom: 20px;
  font-size: 1.125rem;
`;

export const Button = styled.div`
  background: ${({ secondary }) => (secondary ? "#3E55F2" : "#f2f2f5")};
  background: ${({ danger }) => danger && "#FFFFFF"};
  border-radius: 10px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${({ secondary }) => (secondary ? "white" : "#E87166")};
  color: ${({ danger }) => danger && "#E54700"};
  font-size: 1.125rem;
  font-weight: 600;
  cursor: pointer;
  margin: 0 16px 10px;
`;
