import { useRef } from "react";
import { useMediaQuery } from "react-responsive";
import useDraggableScroll from "use-draggable-scroll";
import News from "./News";
import { NewsContainer, Container } from "./styled/LatestNews";

import news1img from "./news-images/news1.png";
import news2img from "./news-images/news2.png";
import news3img from "./news-images/news3.png";
import news4img from "./news-images/news4.png";
import SliderArrows from "../../../components/SliderArrows";
import { SliderHeaderContainer } from "./styled/LatestNews";

const LatestNews = () => {
  const isMobile = useMediaQuery({ query: "(max-width: 360px)" });
  const rowRef = useRef(null);

  const { onMouseDown } = useDraggableScroll(rowRef, {
    direction: "horizontal"
  });

  const handleScroll = (direction) => {
    if (rowRef.current) {
      const { scrollLeft, clientWidth } = rowRef.current;

      const scrollTo =
        direction === "left"
          ? scrollLeft - clientWidth
          : scrollLeft + clientWidth;

      rowRef.current.scrollTo({ left: scrollTo, behavior: "smooth" });
    }
  };

  return (
    <Container>
      <h4>Latest news</h4>
      <SliderHeaderContainer>
        <h2>The Latest in the World of Wearables</h2>
        <SliderArrows
          onBack={() => handleScroll("left")}
          onNext={() => handleScroll("right")}
        />
      </SliderHeaderContainer>
      <NewsContainer ref={rowRef} onMouseDown={onMouseDown}>
        <News
          image={news1img}
          title="The best personal safety devices on the market"
          subtitle="Your smartphone or wearable could help you out in a dangerous situation"
        />
        <News
          image={news2img}
          title="Aeklys: The smart ring"
          subtitle={"Introduction to the most adaptable ring money can buy"}
        />
        {!isMobile && (
          <>
            <News
              image={news3img}
              title="Contactless transactions surge in the UK"
              subtitle="91.1% of all eligible card transactions in 2021 were made contactlessly, with the total value of contactless payments made increasing by 40.2 per cent, year-on-year."
            />
            <News
              image={news4img}
              title="Contactless payments, security and fashion"
              subtitle={
                "What does a day in the life wearing a CashCuff shirt really look like?"
              }
            />
          </>
        )}
      </NewsContainer>
    </Container>
  );
};

export default LatestNews;
