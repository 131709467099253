import styled from "styled-components";
import backgroundImage from "../img/background.png";
import snowAnimation from "../img/snow.gif";

import { createGlobalStyle } from "styled-components";

import GothamRoundedBold from "../fonts/GothamRounded-Bold.otf";
import GothamRoundLight from "../fonts/GothamRounded-Light.otf";
import GothamRoundMedium from "../fonts/GothamRounded-Medium.otf";

export const MuchBetterFontStyle = createGlobalStyle`
@font-face {
  font-family: 'Gotham Rounded Bold';
  src: url(${GothamRoundedBold}) format('opentype');
  font-weight: 325;
  font-style: normal;
  font-display: auto;
}

@font-face {
  font-family: 'Gotham Rounded Light';
  src: url(${GothamRoundLight}) format('opentype');
  font-weight: 375;
  font-style: normal;
  font-display: auto;
}

@font-face {
  font-family: 'Gotham Rounded Medium';
  src: url(${GothamRoundMedium}) format('opentype');
  font-weight: 250;
  font-style: normal;
  font-display: auto;
}

`;

export const MainContainer = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
  align-self: center;
  max-width: 480px;
  min-height: 100vh;
  width: 100%;
  margin: auto;

  background: url(${snowAnimation}), url(${backgroundImage});

  -webkit-background-size: contain;
  -moz-background-size: contain;
  -o-background-size: contain;
  background-size: contain;
  background-repeat: no-repeat, repeat-y;
`;

export const SpinnerContainer = styled.section`
  display: flex;
  flex-direction: column;
  height: 100vh;
  justify-content: center;
  align-items: center;
`;

export const Header = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 32px;
  img {
    max-width: 250px;
    max-height: 250px;
  }
`;

export const ChristmasGlobe = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 16px;
  img {
    max-width: 180px;
    max-height: 180px;
  }
`;

export const DisplayGroup = styled.div`
  width: 80%;
  b {
    font-size: 20px;
    font-family: "Gotham Rounded Medium";
    color: white;
  }
  margin-top: 16px;
`;

export const DisplayField = styled.div`
  font-family: "Gotham Rounded Light";
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 56px;
  background: white;
  border-radius: 5px;
  margin-top: 8px;
  padding-left: 16px;
  img {
    margin-right: 20px;
    max-width: 24px;
    max-height: 24px;
  }
`;

export const TitleText = styled.section`
  font-family: "Gotham Rounded Bold";
  font-size: 32px;
  font-weight: 400;
  text-align: center;
  color: white;
  margin-bottom: 20px;
`;

export const MessageText = styled.section`
  font-family: "Gotham Rounded Light";
  font-size: 16px;
  font-weight: 400;
  color: white;
  margin-left: 38px;
  margin-right: 38px;
  text-align: center;
  margin-bottom: 20px;
`;

export const BalanceTitle = styled.section`
  font-family: "Gotham Rounded Bold";
  font-size: 16px;
  font-weight: 400;
  color: #8d8e99;
`;
