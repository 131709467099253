import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";

import { getBalanceAndPin } from "../../features/balance-and-pin/balanceAndPinSlice";
import Spinner from "../../components/Spinner";
import { MainContainer, MuchBetterFonts } from "./styles/IceMuchbetter";

import mbLogo from "./img/logo-mb.svg";
import splashImg from "./img/splash.png";

const IceMuchbetter = () => {
  const [searchParams] = useSearchParams();
  const serialNumber = searchParams.get("serialNumber");
  const dispatch = useDispatch();

  const { balanceAndPin, isLoading } = useSelector(
    (state) => state.balanceAndPin
  );

  useEffect(() => {
    if (!balanceAndPin && serialNumber) {
      dispatch(
        getBalanceAndPin({
          serial: serialNumber,
          query: "?provider=much_better"
        })
      );
    }
  }, [balanceAndPin, serialNumber, dispatch]);

  const formattedBalance = (availableBalance, currency) => {
    let iconPrefixed = true;
    let icon = "";

    switch (currency) {
      case "GBP":
        icon = "£";
        break;
      case "EUR":
        icon = "€";
        break;
      case "USD":
        icon = "$";
        break;
      default:
        icon = "";
    }

    if (iconPrefixed) {
      return icon + availableBalance;
    } else {
      return availableBalance + icon;
    }
  };

  if (isLoading) return <Spinner />;

  return (
    <>
      <MuchBetterFonts />
      <MainContainer>
        <img className="logo" src={mbLogo} alt="logo" />
        <img className="splash" src={splashImg} alt="splash" />
        <h1>Thanks for being a valued partner!</h1>
        <p>We appreciate you testing our new product</p>
        <p className="label">Balance</p>
        <div className="value">
          {balanceAndPin?.balanceDetails?.availableBalance ? (
            <p>
              {formattedBalance(
                balanceAndPin.balanceDetails.availableBalance,
                balanceAndPin?.balanceDetails.currency
              )}
            </p>
          ) : (
            <p>{"N/A"}</p>
          )}
        </div>
        <p className="label">PIN Number</p>
        <div className="value">
          {balanceAndPin?.pinDetails?.pin ? (
            <p>{balanceAndPin?.pinDetails?.pin}</p>
          ) : (
            <p>{"N/A"}</p>
          )}
        </div>
        <p className="footer">
          If you want to pair the fob to your MuchBetter Wallet, or if you have
          technical question please contact{" "}
          <a href="mailto:support@muchbetter.com">support@muchbetter.com</a>
        </p>
      </MainContainer>
    </>
  );
};

export default IceMuchbetter;
