import styled from "styled-components";
import mixins from "./mixins";

export const VotingResultsContainer = styled.div`
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const VoteOptionsList = styled.div`
  display: grid;
  margin: 0 16px;
  grid-template-columns: 1fr;
  background: white;
  border-radius: 10px;
  & div:last-child {
    border-bottom: none;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
  }

  & div:first-child {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }
`;

export const VoteOptionsListItem = styled.div`
  ${mixins.B1}
  padding: 16px;
  border-bottom: 1px solid #aeafb8;
  background-color: ${({ selected }) => selected && "#D9D9DC"};
  cursor: pointer;
`;

export const UsersList = styled.div`
  display: grid;
  margin: 0 16px;
  grid-template-columns: 1fr;
  border: 1px solid #aeafb8;
  border-radius: 10px;
  & div:last-child {
    border-bottom: none;
  }
`;

export const UsersListItem = styled.div`
  ${mixins.B1}
  padding: 16px;
  border-bottom: 1px solid #aeafb8;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const Reset = styled.div`
  color: red;
  cursor: pointer;
  font-weight: 600;
`;
