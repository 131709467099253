import { Link } from "react-router-dom";
import styled from "styled-components";

export const PrimaryButton = styled.button`
  background: #3e55f2;
  font-family: "Noto Sans", sans-serif;
  border: none;
  border-radius: 90px;
  height: 56px;
  width: 100%;
  color: white;

  margin: 10px 0;
  text-align: center;
  font-weight: 700;
  font-size: 1.125rem;
  cursor: pointer;
  &:hover {
    background-color: #2e78f7;
  }
  &:active {
    transform: scale(0.98);
  }
`;

export const LinkButton = styled(Link)`
  background-color: #f2f3f5;
  width: 144px;
  padding: 10px 20px;
  text-align: center;
  border-radius: 40px;
  cursor: pointer;
  font-size: 1.125rem;
`;

export const HrefButton = styled.div`
  background-color: #f2f3f5;
  width: 144px;
  padding: 10px 20px;
  text-align: center;
  border-radius: 40px;
  cursor: pointer;
  font-size: 1.125rem;
`;
