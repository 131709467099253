import styled from "styled-components";

export const MainContainer = styled.section`
  background-color: #f7f8fa;
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 480px;
  margin: auto;
  min-height: 100vh;
`;

export const Header = styled.div`
  position: relative;
  img {
    aspect-ratio: 390/312;
    object-fit: cover;
    width: 100%;
    max-width: 100%;
  }
`;

export const TextContainer = styled.div`
  position: absolute;
  bottom: 0;
  padding: 16px;
  h1 {
    font-family: "Arimo";
    font-style: normal;
    font-weight: 600;
    font-size: 30px;
    line-height: 32px;
    color: #ffffff;

    margin-bottom: 8px;
    @media (min-width: 380px) {
      max-width: 75%;
    }
  }

  p {
    font-family: "Arimo";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: #ffffff;

    @media (min-width: 380px) {
      max-width: 90%;
    }
  }
`;

export const AdviceList = styled.div`
  padding: 16px;
  display: grid;
  grid-template-columns: 1fr;
  grid-row-gap: 8px;
`;

export const AdviceItem = styled.div`
  padding: 8px;
  background: #ffffff;
  border-radius: 14px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;

  img {
    height: 40px;
    width: 40px;
    margin-right: 8px;
  }

  font-family: "Arimo";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #303033;
`;

export const Footer = styled.div`
  background: #e6e8ed;
  padding: 16px 20px;
  min-height: 160px;
  h2 {
    font-family: "Arimo";
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    margin-bottom: 8px;
  }
`;
