import styled from "styled-components";
import { B1, B2, theme } from "./SharedStyles";

export const Header = styled.header`
  position: relative;
  h2 {
    position: absolute;
    bottom: 24px;
    left: 16px;
    color: ${theme.colors.surface};
    max-width: 255px;
  }
  img {
    width: 100%;
  }
`;

export const ProductContainer = styled.section`
  h2 {
    padding: 24px 16px 16px;
  }

  img {
    width: 100%;
  }
  ${B2} {
    padding: 24px 16px;
  }
  span {
    font-weight: 700;
    color: ${theme.colors.highEmphasis};
    text-decoration: underline;
    cursor: pointer;
    margin-left: 8px;
  }
`;

export const Ownership = styled.section`
  display: flex;
  flex-direction: column;
  border-top: 1px solid ${theme.colors.divider};
`;

export const OwnershipItem = styled.div`
  padding: 24px 16px;
  border-bottom: 1px solid ${theme.colors.divider};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const Features = styled.section`
  padding: 24px 16px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  row-gap: 16px;
`;

export const Feature = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
  cursor: ${({ isButton }) => (isButton ? "pointer" : "initial")};
`;

export const EventInformation = styled.section`
  border-top: 1px solid ${theme.colors.divider};
  padding: 24px 16px 16px;

  img {
    height: 41px;
  }
`;

export const EventList = styled.ul`
  background-color: ${theme.colors.backgroundOptions};
  margin: 16px;
  list-style: none;
`;

export const EventItem = styled.li`
  padding: 16px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-bottom: 1px solid ${theme.colors.divider};
  border-bottom: ${({ isLast }) => isLast && "none"};
`;

export const PaymentAccount = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 24px 16px;
  border-bottom: 1px solid ${theme.colors.divider};
  border-top: 1px solid ${theme.colors.divider};
  cursor: pointer;
`;

export const InfoAlert = styled.div`
  max-width: 480px;
  position: absolute;
  background: rgba(9, 10, 15, 0.64);
  height: 100%;
  width: 100%;
  text-align: center;

  .inner-container {
    position: fixed;
    max-width: 360px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 40px 32px;
    background-color: ${theme.colors.surface};
    width: 90%;
    z-index: 9999;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 8px;
  }

  .button {
    cursor: pointer;
    margin-top: 8px;
    padding: 16px;
    background-color: ${theme.colors.highEmphasis};
    ${B2} {
      color: ${theme.colors.surface};
    }
  }
`;
