import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  getCampaigns,
  resetCampaigns
} from "../../features/campaign-manager/campaignManagerSlice";
import {
  editDasDevice,
  getDasDevicesBySerial,
  resetDasDevices
} from "../../features/das-device-manager/dasDeviceManagerSlice";
import ProtectedRoute from "../../components/ProtectedRoute";
import { StyledSpinner } from "../../components/styled/Spinner";
import DropdownSelect from "./components/DropdownSelect";
import NavBarComponent from "./components/NavBar";
import Tags from "./components/Tags";
import { Button, Input } from "./components/styled/SharedStyles";
import { Body, ErrorMsg, MainContainer } from "./styled/SharedStyles";

const EditDevice = () => {
  const params = useParams();
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [tags, setTags] = useState(null);
  const [campaignName, setCampaignName] = useState("");
  const [campaignId, setCampaignId] = useState("");
  const [showCampaigns, setShowCampaigns] = useState(false);

  const { campaigns, isLoading, isError, message } = useSelector(
    (state) => state.campaigns
  );
  const {
    device,
    updatedDevice,
    isLoading: isLoadingDevices,
    isError: isErrorDevices,
    message: messageDevices
  } = useSelector((state) => state.dasDevices);

  useEffect(() => {
    if (updatedDevice) {
      navigate(-1);
    }

    return () => {};
  }, [navigate, updatedDevice]);

  useEffect(() => {
    return () => {
      dispatch(resetCampaigns());
      dispatch(resetDasDevices());
    };
  }, [dispatch]);

  useEffect(() => {
    if (!campaigns) {
      dispatch(getCampaigns());
    }
  }, [campaigns, dispatch]);

  useEffect(() => {
    if (!device?.serialNumber && params.serial) {
      dispatch(getDasDevicesBySerial(params.serial));
    } else {
      setCampaignName(
        campaigns?.filter((e) => e.id === device.currentCampaign)[0].name
      );
      setCampaignId(device.currentCampaign);
    }
  }, [
    campaigns,
    device?.currentCampaign,
    device?.serialNumber,
    device?.tags,
    dispatch,
    params.serial
  ]);

  const handleUpdateDevice = () => {
    dispatch(
      editDasDevice({
        serialNumber: params.serial,
        tags,
        currentCampaign: campaignId
      })
    );
  };

  return (
    <MainContainer>
      <ProtectedRoute />
      <NavBarComponent
        leftText={"Cancel"}
        title={location.state?.field || ""}
        to={`/devices-manager/search?serial=${params.serial}`}
      />

      <Body>
        {location?.state?.field === "Tags" && (
          <Tags
            tags={device?.tags}
            onUpdatedTags={(payload) => setTags(payload)}
          />
        )}
        {location?.state?.field === "Campaign" && (
          <>
            <Input
              hasDropdown={showCampaigns && !campaignId}
              onFocus={() => setShowCampaigns(true)}
              id="input-campaign"
              type="campaign"
              placeholder="campaign name"
              required
              value={campaignName}
              onChange={(ev) => {
                setCampaignName(ev.target.value);
                setCampaignId("");
              }}
            />
            {campaigns?.length > 0 && showCampaigns && !campaignId && (
              <DropdownSelect
                onSelect={(e) => {
                  setCampaignName(e.name);
                  setCampaignId(e.id);
                }}
                listItems={campaigns.filter((e) =>
                  e.name.toLowerCase().includes(campaignName.toLowerCase())
                )}
              />
            )}
          </>
        )}
        {location?.state?.field && (
          <Button
            // disabled={!campaignId || campaignId === device?.currentCampaign}
            onClick={() => handleUpdateDevice()}
          >
            Save Changes
          </Button>
        )}
        {(isError || isErrorDevices) && (
          <ErrorMsg>
            {message ||
              messageDevices ||
              "An error has occured, please try again later or contact support."}
          </ErrorMsg>
        )}
        {(isLoading || isLoadingDevices) && <StyledSpinner />}
      </Body>
    </MainContainer>
  );
};

export default EditDevice;
