import axios from "axios";
import { ENV } from "../../env";

export const API_URL = `https://www.${
  ENV !== "production" ? (ENV === "development" ? "dev" : ENV) + "-" : ""
}manage-mii.uk/api/das`;

const createGiftingCard = async ({ serialNumber, data }) => {
  const { title, message, templateId, senderDetails } = data;
  const { firstName, lastName, email } = senderDetails;
  const response = await axios.post(
    API_URL + `/gifting/card/setup/${serialNumber}`,
    {
      title,
      message,
      templateId,
      senderDetails: { firstName, lastName, email }
    }
  );
  return response.data;
};

const setCardViewed = async ({ serialNumber, dontShowAgain }) => {
  const response = await axios.post(
    API_URL + `/gifting/card/${serialNumber}/viewed`,
    {
      dontShowAgain
    }
  );
  return response.data;
};

const giftingService = {
  createGiftingCard,
  setCardViewed
};

export default giftingService;
