import httpClient from "../../axios";
import { ENV } from "../../env";

export const API_URL = `https://www.${
  ENV !== "production" ? (ENV === "development" ? "dev" : ENV) + "-" : ""
}manage-mii.uk/api/das/coach-tracker`;

const getPassengers = async () => {
  const response = await httpClient.get(API_URL + "/passengers");

  return response.data;
};

const importPassengers = async (passengers) => {
  const response = await httpClient.post(`${API_URL}/passengers/import`, {
    devices: passengers,
  });
  return response.data;
};

const coachTrackerService = {
  getPassengers,
  importPassengers
};

export default coachTrackerService;
