import styled from "styled-components";

export const StyledTopSection = styled.section`
  width: 100%;
  margin-bottom: 16px;

  @media (min-width: 640px) {
    margin-bottom: 0;
  }
`;

export const Banner = styled.div`
  margin-top: 50px;
  position: relative;
  display: block;
  width: 100%;
  background-color: black;

  img {
    width: 100%; // added for full width
    max-width: 100%;
    height: 450px;
    opacity: 0.8;
    margin-bottom: -5px;
    object-fit: cover;

    @media (min-width: 768px) {
      height: 690px;
    }
  }
`;

export const Details = styled.div`
  position: absolute;
  bottom: 26px;
  left: 20px;
  display: flex;
  flex-direction: column;

  @media (min-width: 768px) {
    font-weight: 900;
    font-size: 8.375rem;
    line-height: 6.875rem;
  }

  @media (min-width: 1440px) {
    bottom: 44px;
    left: 59px;
  }

  @media (min-width: 1920px) {
    bottom: 60px;
    left: 60px;
  }
`;

export const Title = styled.div`
  color: white;
  font-family: "acumin-pro-extra-condensed";
  font-style: normal;
  font-weight: 900;
  font-size: 4rem;
  line-height: 4rem;

  @media (min-width: 768px) {
    font-weight: 900;
    font-size: 8.375rem;
    line-height: 6.875rem;
    max-width: 90%;
  }

  @media (min-width: 1440px) {
    font-weight: 900;
    font-size: 9.375rem;
    line-height: 7.5rem;
    max-width: 75%;
  }

  @media (min-width: 1920px) {
    font-weight: 900;
    font-size: 12.5rem;
    line-height: 10rem;
  }
`;

export const AppStoreImgs = styled.div`
  height: 51px;
  display: none;
  gap: 13px;
  @media (min-width: 768px) {
    display: flex;
  }
  img {
    cursor: pointer;
    max-width: 100%;
    max-height: 100%;
  }
`;

export const ButtonsContainer = styled.div`
  padding-right: 60px;
  margin-top: 40px;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
`;
