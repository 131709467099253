import React from "react";
import { Container } from "../styled/PlayerBadge";

const PlayerBadge = ({ playerName, teamName }) => {
  return (
    <Container>
      <div>
        <strong>{playerName}</strong>
      </div>
      <div>{teamName}</div>
    </Container>
  );
};

export default PlayerBadge;
