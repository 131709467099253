import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getBalanceAndPin } from "../../features/balance-and-pin/balanceAndPinSlice";
import { formattedBalance } from "../../features/balance-and-pin/utils";

import Spinner from "../../components/Spinner";

import {
  AppStoreLinks,
  CurvelogoContainer,
  Footer,
  Wearable,
  MainContainer,
  Navbar,
  AfterEventContainer,
  StepContainer,
  StepNumber,
  HeroContainer,
  InnerContainer,
  InfoContainer
} from "./styles";

import appStoreLogo from "../../assets/app-store.svg";
import gplayLogo from "../../assets/play-store.svg";
import curveLogo from "../../assets/curve_logo.png";
import euFlag from "../../assets/eu-flag.png";
import ukFlag from "../../assets/uk-flag.png";
import chevronUp from "../../assets/chevron-up.svg";
import chevronDown from "../../assets/chevron-down.svg";
import copyToClipboard from "../../assets/copy-icon.svg";
import payAnyWhereImage from "./img/payanywhere.webp";
import infineonLogo from "./img/infineon.png";
import linkedinLogo from "../../assets/linkedin.svg";
import twitterLogo from "../../assets/twitter.svg";
import facebookLogo from "../../assets/facebook.svg";
import fingernailImage from "./img/fingernail.webp";

import packageJson from "../../../package.json";

const Singapore24 = () => {
  const [searchParams] = useSearchParams();
  const serialNumber = searchParams.get("serialNumber");
  const [showPaymentAccount, setShowPaymentAccount] = useState(true);
  const [copied, setCopied] = useState(false);

  const dispatch = useDispatch();
  const { balanceAndPin, isLoading } = useSelector(
    (state) => state.balanceAndPin
  );

  useEffect(() => {
    setTimeout(() => {
      setCopied(false);
    }, 2000);
  }, [copied]);

  useEffect(() => {
    if (!balanceAndPin && serialNumber) {
      dispatch(
        getBalanceAndPin({
          serial: serialNumber,
          query: "?provider=much_better"
        })
      );
    }
  }, [balanceAndPin, serialNumber, dispatch]);

  const appStoreLinks = (appStoreUrl, playStoreUrl) => {
    return (
      <AppStoreLinks>
        <img
          src={appStoreLogo}
          alt="app-store"
          onClick={() => window.open(appStoreUrl, "_blank")}
        />
        <img
          src={gplayLogo}
          alt="play-store"
          onClick={() => window.open(playStoreUrl, "_blank")}
        />
      </AppStoreLinks>
    );
  };

  if (isLoading) return <Spinner />;

  return (
    <MainContainer>
      <Navbar>
        <img src={infineonLogo} alt="infineon-logo" />
        <div className="balance">
          <p>Balance</p>
          <p>{`${formattedBalance(
            balanceAndPin?.balanceDetails?.availableBalance,
            balanceAndPin?.balanceDetails?.currency
          )}`}</p>
        </div>
      </Navbar>

      <Wearable className="wearable">
        <img src={fingernailImage} alt="wearable" />
      </Wearable>

      <InnerContainer>
        <HeroContainer>
          <p>
            Congratulations. You are now the proud wearer of one of the
            world-first NFC manicure smart fingernails, powered by Infineon,
            Smart Chip and DIGISEQ.
          </p>
          <p>
            With the preloaded funds (US$24), you can make contactless payments
            at any Mastercard-accepting retail store, bus, or MRT, giving you
            the freedom to pay with a simple tap of your nail. Experience the
            future of payments, today!
          </p>
          <p>Your balance is shown above at the top right corner.</p>
        </HeroContainer>
        <InfoContainer>
          <h2>Visit Infineon</h2>
          <p>
            Explore how Infineon’s technologies are shaping the future of
            payments.
          </p>
          <a
            href="https://www.infineon.com/cms/en/applications/security-solutions/payment-solutions/"
            target="_blank"
            rel="noreferrer"
          >
            Learn more
          </a>
        </InfoContainer>
        <AfterEventContainer>
          <CurvelogoContainer>
            <div
              className="expand-collapse"
              onClick={() => setShowPaymentAccount(!showPaymentAccount)}
            >
              <h2>After the event</h2>

              <img
                src={showPaymentAccount ? chevronUp : chevronDown}
                alt="chevron up"
              />
            </div>
            {showPaymentAccount && (
              <>
                <p>
                  Want to keep paying with your smart fingernail (EEA/ UK
                  residents only) or set up your contactless business card?
                  Follow the steps below and stay connected.
                </p>
                <p className="title">Link your own card</p>
                <img className="curve-logo" src={curveLogo} alt="curve logo" />
                <div className="flags">
                  <img src={euFlag} alt="eu flag" />{" "}
                  <img src={ukFlag} alt="uk flag" />
                </div>
              </>
            )}
          </CurvelogoContainer>
          {showPaymentAccount && (
            <>
              <StepContainer>
                <StepNumber>1</StepNumber>
                <h2>Download the Curve App</h2>
                <p>
                  And link your preferred payment card to your new Curve
                  account.
                </p>
                <p>Download the Curve App:</p>

                {appStoreLinks(
                  "https://apps.apple.com/gb/app/curve-supercharge-your-money/id1049397112",
                  "https://play.google.com/store/apps/details?id=com.imaginecurve.curve.prd"
                )}
              </StepContainer>
              <StepContainer>
                <StepNumber>2</StepNumber>
                <h2>Download the Smart Chip app to:</h2>

                <p>Link your Curve card to enable contactless payments.</p>
                <p>
                  Set up a contactless business card and share your details with
                  a tap!
                </p>
                {appStoreLinks(
                  "https://apps.apple.com/gb/app/sc-smart-chip/id6651851137",
                  "https://play.google.com/store/apps/details?id=com.kore.smartchip"
                )}
              </StepContainer>
              <StepContainer>
                <StepNumber>3</StepNumber>
                <h2>Pay Anywhere!</h2>
                <p>
                  Tap your Smart Chip at any card terminal where Mastercard
                  Contactless is enabled.
                </p>

                <img
                  className="pay-anywhere"
                  src={payAnyWhereImage}
                  alt="pay anywhere"
                />
                <p>Need help? Check FAQ in Smart Chip app!</p>
              </StepContainer>
            </>
          )}
        </AfterEventContainer>
        <InfoContainer>
          <h2>Who are Smart Chip?</h2>
          <p>
            Smart Chip, powered by DIGISEQ, has developed, and patented the
            world’s first NFC manicure with an RFID chip and is preparing to
            launch in 2024. The Smart Chip is applied to your nail at an
            affiliated salon. With your Smart Chip you embody embedded finance.
            Link your nail to your payment card using Smart Chip mobile app and
            you're ready to go.
          </p>
          <a href="https://smart-chip.com/de/" target="_blank" rel="noreferrer">
            Learn more
          </a>
        </InfoContainer>
        <InfoContainer>
          <h2>
            Who are <span className="emphasized">DIGISEQ?</span>
          </h2>
          <p>
            DIGISEQ is an award-winning IoT service platform that securely
            provisions data into everyday wearables; enabling items, from rings
            to clothing, to deliver NFC payment, customer engagement
            functionality, access control and digital identity.
          </p>
          <a href="https://www.digiseq.co.uk" target="_blank" rel="noreferrer">
            Learn more
          </a>
        </InfoContainer>
      </InnerContainer>

      <Footer>
        <div>
          <h2 className="emphasized">DIGISEQ</h2>
          <h2>Revolutionising the Way We Pay.</h2>
        </div>
        <ul className="links">
          <li>
            <a
              href="https://www.digiseq.co.uk"
              target="_blank"
              rel="noreferrer"
            >
              <p>Our Website</p>
            </a>
          </li>
          {/* <li>
            <a
              href="https://www.digiseq.co.uk/privacy-policy"
              target="_blank"
              rel="noreferrer"
            >
              Privacy Policy
            </a>
          </li> */}
          <li>
            <a
              href="https://digiseq.co.uk/terms-conditions/"
              target="_blank"
              rel="noreferrer"
            >
              <p>Terms and Conditions</p>
            </a>
          </li>
          <li>
            <a
              href="https://www.digiseq.co.uk/wearables-and-psd2-sca"
              target="_blank"
              rel="noreferrer"
            >
              <p>Wearables and PSD2 SCA</p>
            </a>
          </li>
        </ul>
        <ul className="socials">
          <li>
            <a
              href="https://www.linkedin.com/company/digiseq"
              target="_blank"
              rel="noreferrer"
            >
              <img src={linkedinLogo} alt="linkedin logo" />
            </a>
          </li>
          <li>
            <a href="https://x.com/digisequk" target="_blank" rel="noreferrer">
              <img src={twitterLogo} alt="twitter logo" />
            </a>
          </li>
          <li>
            <a
              href="https://www.facebook.com/Digiseq/"
              target="_blank"
              rel="noreferrer"
            >
              <img src={facebookLogo} alt="facebook logo" />
            </a>
          </li>
        </ul>
        <div className="copyToClipboard">
          <img
            onClick={() => {
              navigator.clipboard.writeText(serialNumber);
              setCopied(true);
            }}
            src={copyToClipboard}
            alt="copy to clipboard"
          />
          <p>ID: {serialNumber}</p>
        </div>
        <div className="version">
          <p>v.{packageJson.version}</p>
        </div>
        {copied && (
          <p>
            <b>Copied to clipboard</b>
          </p>
        )}
      </Footer>
    </MainContainer>
  );
};

export default Singapore24;
