import { useEffect, useState } from "react";
import * as yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";

import usePrevious from "../../hooks/usePrevious";
import { getDasDeviceInfo } from "../../features/das-device-manager/dasDeviceManagerSlice";
import {
  createGiftingCard,
  setCardViewed
} from "../../features/gifting/giftingSlice";

import Checkbox from "./Checkbox";

import {
  Button,
  ChristmasCard,
  ConfirmCard,
  Footer,
  FormSection,
  InnerContainer,
  IntroSection,
  MainContainer,
  Messages,
  Navbar,
  SectionContainer,
  SectionHeader,
  TemplateCard,
  Templates
} from "./styles";

import logo from "../../assets/digiseq-logo-square.png";

import chevronDown from "./assets/chevron-down.svg";
import chevronUp from "./assets/chevron-up.svg";
import templatesSectionBackground from "./assets/templates-section-background.png";
import messagesSectionBackground from "./assets/messages-section-background.png";
import closeCardBackground from "./assets/close-card-background.png";
import template1 from "./assets/template-1.png";
import template2 from "./assets/template-2.png";
import template3 from "./assets/template-3.png";
import preview1 from "./assets/preview-1.png";
import preview2 from "./assets/preview-2.png";
import preview3 from "./assets/preview-3.png";
import closeIcon from "./assets/close-icon.svg";
import { StyledSpinner } from "../../components/styled/Spinner";

const ChristmasWizard24 = () => {
  const [searchParams] = useSearchParams();
  const serialNumber = searchParams.get("serialNumber");
  const dispatch = useDispatch();

  const [expandedSection, setExpandedSection] = useState({
    templates: true,
    messages: true
  });
  const [selectedTemplate, setSelectedTemplate] = useState({
    backgroundImage: template1,
    name: "christmas_01"
  });
  const [formErrors, setFormErrors] = useState({
    title: "",
    message: ""
  });

  const [confirmingCard, setConfirmingCard] = useState(false);
  const [showPreviewCard, setShowPreviewCard] = useState(false);
  const [dontShowAgain, setDontShowAgain] = useState(false);
  const [showCloseAlert, setShowCloseAlert] = useState(false);
  const [showDisableCard, setShowDisableCard] = useState(false);

  const [processing, setProcessing] = useState(true);
  const [showErrorCreatingGiftingCard, setShowErrorCreatingGiftingCard] =
    useState("");

  const [form, setForm] = useState({
    title: "",
    message: "",
    firstName: "",
    email: ""
  });

  const {
    creatingGiftingCard,
    errorCreatingGiftingCard,
    viewingCard,
    errorViewingCard,
    redirectUrl
  } = useSelector((state) => state.gifting);

  const { dasDeviceInfo, gettingDasDeviceInfo, errorGettingDasDeviceInfo } =
    useSelector((state) => state.dasDevices);

  const prevCreatingGiftingCard = usePrevious(creatingGiftingCard);
  const prevViewingCard = usePrevious(viewingCard);
  const prevGettingDasDeviceInfo = usePrevious(gettingDasDeviceInfo);

  useEffect(() => {
    if (prevGettingDasDeviceInfo && !errorGettingDasDeviceInfo) {
      if (
        dasDeviceInfo?.senderDetails?.firstName &&
        dasDeviceInfo?.senderDetails?.email
      ) {
        setForm({
          firstName: dasDeviceInfo?.senderDetails?.firstName,
          email: dasDeviceInfo?.senderDetails?.email
        });
      }
      if (dasDeviceInfo?.giftingDetails?.templateId) {
        const image =
          dasDeviceInfo?.giftingDetails?.templateId === "christmas_01"
            ? template1
            : dasDeviceInfo?.giftingDetails?.templateId === "christmas_02"
            ? template2
            : template3;
        setSelectedTemplate({
          backgroundImage: image,
          name: dasDeviceInfo?.giftingDetails?.templateId
        });
      }

      if (
        dasDeviceInfo?.giftingDetails?.message &&
        dasDeviceInfo?.giftingDetails?.title
      ) {
        setForm({
          title: dasDeviceInfo?.giftingDetails?.title,
          message: dasDeviceInfo?.giftingDetails?.message
        });
      }
      setProcessing(false);
      setShowPreviewCard(true);
    } else {
      setProcessing(false);
    }
  }, [
    dasDeviceInfo?.giftingDetails?.message,
    dasDeviceInfo?.giftingDetails?.title,
    dasDeviceInfo?.senderDetails?.firstName,
    dasDeviceInfo?.senderDetails?.lastName,
    dasDeviceInfo?.senderDetails?.email,
    errorGettingDasDeviceInfo,
    prevGettingDasDeviceInfo,
    dasDeviceInfo?.giftingDetails?.templateId
  ]);

  useEffect(() => {
    if (!dasDeviceInfo) {
      dispatch(getDasDeviceInfo(serialNumber));
    }
  }, [dasDeviceInfo, dispatch, serialNumber]);

  useEffect(() => {
    if (prevCreatingGiftingCard && !errorCreatingGiftingCard) {
      setConfirmingCard(false);
      setShowPreviewCard(true);
    } else if (errorCreatingGiftingCard) {
      setShowErrorCreatingGiftingCard(
        "We couldn't save your card, please try later or contact support"
      );
    }
  }, [
    errorCreatingGiftingCard,
    prevCreatingGiftingCard,
    setShowErrorCreatingGiftingCard
  ]);

  useEffect(() => {
    if (prevViewingCard && !errorViewingCard) {
      setShowPreviewCard(false);
      setProcessing(true);
      if (redirectUrl) {
        window.location.href = redirectUrl;
      }
    }
  }, [errorViewingCard, prevViewingCard, redirectUrl]);

  const validateForm = () => {
    setFormErrors({
      title: "",
      message: ""
    });

    const schema = yup.object().shape({
      title: yup
        .string()
        .required("Title is required")
        .min(3, "Title must be at least 3 characters")
        .max(20, "Title must be less than 20 characters"),
      message: yup
        .string()
        .required("Message is required")
        .min(10, "Message must be at least 10 characters")
        .max(350, "Message must be less than 350 characters")
    });

    try {
      schema.validateSync(form, { abortEarly: false });
      setShowErrorCreatingGiftingCard("");
      setConfirmingCard(true);
    } catch (err) {
      const errors = {};
      err.inner.forEach((error) => {
        errors[error.path] = error.message;
      });
      setFormErrors(errors);
      return false;
    }

    return true;
  };

  const handleCloseCard = ({ dontShowAgain }) => {
    setShowCloseAlert(false);
    dispatch(setCardViewed({ serialNumber, dontShowAgain }));
  };

  const handleSaveCard = (event) => {
    event.preventDefault();
    dispatch(
      createGiftingCard({
        serialNumber,
        data: {
          title: form.title,
          message: form.message,
          templateId: selectedTemplate.name,
          senderDetails: {
            firstName: form.firstName,
            lastName: form.lastName,
            email: form.email
          }
        }
      })
    );
  };

  if (gettingDasDeviceInfo || processing) return <StyledSpinner />;

  if (showPreviewCard) {
    return (
      <MainContainer>
        <Navbar>
          <img src={logo} alt="logo" />
          <div>
            <p>
              {form.firstName ||
              dasDeviceInfo?.giftingDetails?.senderDetails?.firstName
                ? `${
                    form.firstName ||
                    dasDeviceInfo?.giftingDetails?.senderDetails?.firstName
                  } sent you a Christmas Card!`
                : "A Christmas Card has been sent to you!"}
            </p>
          </div>
        </Navbar>

        <ChristmasCard backgroundImage={selectedTemplate.backgroundImage}>
          <div className="message">
            <h1>{form.title || "Merry Christmas!"}</h1>
            <p>{form.message || "Wishing you a very Merry Christmas!"}</p>
          </div>
          <SectionContainer>
            <Button
              large
              onClick={() =>
                typeof showPreviewCard === "boolean"
                  ? setShowCloseAlert(true)
                  : setShowPreviewCard(false)
              }
            >
              Close card
            </Button>
          </SectionContainer>
        </ChristmasCard>
        {viewingCard && <StyledSpinner />}
        <Footer>
          <p>Global Privacy Policy</p>
          <p>Terms of Use</p>
          <p>{`© ${new Date().getFullYear()} Digiseq`}</p>
        </Footer>
        {showCloseAlert && (
          <ConfirmCard backgroundImage={closeCardBackground}>
            <div className="inner-container">
              <img className="logo" src={logo} alt="logo" />
              <h2>What’s next?</h2>
              <p>
                Your wearable is more than just a Christmas Card! It can be used
                for payments like your normal Mastercard. If you tap Close Card
                below - the Christmas Card will be closed and next time you tap
                - you will see the guide how to continue using it!
              </p>
              <p className="learn-more-link">
                <a
                  href="https://www.digiseq.co.uk/"
                  target="_blank"
                  rel="noreferrer"
                >
                  Learn more
                </a>
              </p>
              <div className="checkbox-container">
                <Checkbox
                  label="Don't show this card again"
                  checked={dontShowAgain}
                  onChange={() => setDontShowAgain(!dontShowAgain)}
                />
              </div>
              <Button large onClick={() => handleCloseCard({ dontShowAgain })}>
                Confirm
              </Button>
            </div>
          </ConfirmCard>
        )}
      </MainContainer>
    );
  }

  return (
    <MainContainer>
      <Navbar>
        <img src={logo} alt="logo" />
        <div>
          <p>Christmas Card Wizard</p>
        </div>
      </Navbar>
      <IntroSection>
        <div className="text-container">
          <h1>Christmas!</h1>
          <p>
            <i>
              Snowflakes dance, lights softly gleam, Joy fills hearts like a
              sweet dream. Laughter, love, and cheer abide, Christmas magic far
              and wide.
            </i>
          </p>
          <p>
            Use the Wizard below to share the Christmas magic with a Christmas
            e-Card on this wearable
          </p>
          <div className="disable-card-container">
            <p>Don’t want to use this feature?</p>
            <p
              onClick={() => setShowDisableCard(true)}
              className="disable-card-button"
            >
              Disable Christmas e-Card
            </p>
          </div>
        </div>
      </IntroSection>
      {showDisableCard && (
        <ConfirmCard backgroundImage={closeCardBackground}>
          <div className="inner-container">
            <img
              className="close-icon"
              src={closeIcon}
              alt="close"
              onClick={() => setShowDisableCard(false)}
            />
            <img className="logo" src={logo} alt="logo" />
            <div>
              <h2>Disable</h2>
              <h2> Christmas e-Card</h2>
            </div>
            <p>
              If you don’t want to set up your wearable as Christmas e-Card, you
              can disable it, and next time you tap - you will be taken to the
              default website.
            </p>
            <br />
            <Button
              large
              onClick={() => handleCloseCard({ dontShowAgain: true })}
            >
              Confirm
            </Button>
          </div>
        </ConfirmCard>
      )}
      <InnerContainer>
        <SectionContainer backgroundImage={templatesSectionBackground}>
          <SectionHeader>
            <h2>Card Background</h2>
            <img
              src={!expandedSection.templates ? chevronDown : chevronUp}
              alt={!expandedSection.templates ? "chevron down" : "chevron up"}
              onClick={() => {
                setExpandedSection({ templates: !expandedSection.templates });
              }}
            />
          </SectionHeader>
          {expandedSection.templates && (
            <Templates>
              <p>Tap to select your favourite background</p>
              <TemplateCard
                backgroundImage={preview1}
                selected={selectedTemplate.name === "christmas_01"}
                onClick={() =>
                  setSelectedTemplate({
                    backgroundImage: template1,
                    name: "christmas_01"
                  })
                }
              >
                <div className="template-name">
                  <p>Background 1</p>
                </div>
              </TemplateCard>
              <TemplateCard
                backgroundImage={preview2}
                selected={selectedTemplate.name === "christmas_02"}
                onClick={() =>
                  setSelectedTemplate({
                    backgroundImage: template2,
                    name: "christmas_02"
                  })
                }
              >
                <div className="template-name">
                  <p>Background 2</p>
                </div>
              </TemplateCard>
              <TemplateCard
                backgroundImage={preview3}
                selected={selectedTemplate.name === "christmas_03"}
                onClick={() =>
                  setSelectedTemplate({
                    backgroundImage: template3,
                    name: "christmas_03"
                  })
                }
              >
                <div className="template-name">
                  <p>Background 3</p>
                </div>
              </TemplateCard>
            </Templates>
          )}
        </SectionContainer>
        <SectionContainer backgroundImage={messagesSectionBackground}>
          <SectionHeader>
            <h2>Messages</h2>

            <img
              src={!expandedSection.messages ? chevronDown : chevronUp}
              alt={!expandedSection.messages ? "chevron down" : "chevron up"}
              onClick={() => {
                setExpandedSection({ messages: !expandedSection.messages });
              }}
            />
          </SectionHeader>
          {expandedSection.messages && (
            <Messages>
              <p>Enter Title and Message to send</p>
              <FormSection>
                <label htmlFor="firstName">Sender’s name (optional)</label>
                <input
                  name="firstName"
                  type="text"
                  placeholder="First Name"
                  value={form.firstName}
                  onChange={(e) => {
                    setForm({ ...form, firstName: e.target.value });
                  }}
                />
              </FormSection>
              <FormSection>
                <label htmlFor="title">Title</label>
                <input
                  name="title"
                  type="text"
                  placeholder="Dear... "
                  value={form.title}
                  onChange={(e) => {
                    setFormErrors({ ...formErrors, title: "" });
                    setForm({ ...form, title: e.target.value });
                  }}
                />
                {formErrors.title && (
                  <p className="error">{formErrors.title}</p>
                )}
              </FormSection>
              <FormSection>
                <label htmlFor="message">Message</label>
                <textarea
                  rows={4}
                  name="message"
                  placeholder="Message"
                  value={form.message}
                  onChange={(e) => {
                    setFormErrors({ ...formErrors, message: "" });
                    setForm({ ...form, message: e.target.value });
                  }}
                />
                {formErrors.message && (
                  <p className="error">{formErrors.message}</p>
                )}
              </FormSection>
              <div className="buttons-container">
                <Button onClick={validateForm}>Submit</Button>
                <Button secondary onClick={() => setShowPreviewCard("preview")}>
                  Preview
                </Button>
              </div>
            </Messages>
          )}
        </SectionContainer>
      </InnerContainer>
      {confirmingCard && (
        <ConfirmCard>
          <div className="inner-container">
            <img
              className="close-icon"
              src={closeIcon}
              alt="close"
              onClick={() => setConfirmingCard(false)}
            />
            <h2>Save your Christmas Card</h2>

            <p>
              When you press Save below the wizard will be disabled and on the
              next tap of the Keyring on a phone the Christmas Card will be
              displayed.{" "}
            </p>
            <p>
              Optionally you can leave your E-mail and we can notify you the
              first time the Christmas Card is opened by the recipient.
            </p>

            <form>
              <div>
                <input
                  className="email-input"
                  type="email"
                  placeholder="Email"
                  value={form.email}
                  onChange={(e) => {
                    setForm({ ...form, email: e.target.value });
                  }}
                />
              </div>
              <div>
                <Button large onClick={handleSaveCard}>
                  Save Christmas Card
                </Button>
              </div>
              {showErrorCreatingGiftingCard && (
                <p className="error">{showErrorCreatingGiftingCard}</p>
              )}
              <p className="terms-and-conditions">
                By clicking “Save Christmas Card”, I confirm that I have read
                and agree to the{" "}
                <a
                  href="https://www.digiseq.co.uk/terms-conditions"
                  target="_blank"
                  rel="noreferrer"
                >
                  Terms and Conditions
                </a>{" "}
                of DIGISEQ data & services portal.
              </p>
            </form>
          </div>
        </ConfirmCard>
      )}

      <Footer>
        <p>Global Privacy Policy</p>
        <p>Terms of Use</p>
        <p>{`© ${new Date().getFullYear()} Digiseq`}</p>
      </Footer>
    </MainContainer>
  );
};

export default ChristmasWizard24;
