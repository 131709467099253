import styled from "styled-components";

export const MainContainer = styled.div`
  //padding: 20px 20px;
  display: grid;
  justify-items: center;
  grid-template-columns: 1fr;
  grid-gap: 10px;
  background-color: white;
  max-width: 650px;
  margin: 16px 16px;
  border-radius: 14px;
  text-align: center;

  form {
    width: 100%;
    display: grid;
    justify-items: center;
    grid-template-columns: 1fr;
    grid-gap: 16px;
  }
`;

export const InvalidFieldText = styled.div`
  margin-left: 4px;
  justify-self: flex-start;
  font-family: "Mark For MC Narrow W00 Book";
  font-size: 14px;
  color: red;
`;

export const AlertTitle = styled.div`
  font-family: "Mark For MC Narrow W00 Bold";
  font-size: 18px;
  font-weight: 350;
`;

export const AlertButtonContainer = styled.div`
  width: 304px;
`;

export const SendMessageButton = styled.button`
  background: #141413;
  font-family: "Mark For MC Narrow W00 Bold";
  border: none;
  border-radius: 90px;
  height: 56px;
  width: 100%;
  color: white;

  margin: 10px 0;
  text-align: center;
  font-weight: 700;
  font-size: 1.125rem;
  cursor: pointer;
  &:hover {
    background-color: #282a2d;
  }
  &:active {
    transform: scale(0.98);
  }
`;
