import React, { useState } from "react";
import emailjs from "@emailjs/browser";
import ReCAPTCHA from "react-google-recaptcha";
import { useNavigate } from "react-router-dom";
import Input from "../../components/Input";
import { StyledMain } from "../../styled/StyledMain";
import { StyledSpinner } from "../../components/styled/Spinner";
import { PrimaryButton } from "../../components/styled/PrimaryButton";
import {
  AlertButtonContainer,
  InvalidField,
  MainContainer,
  Select
} from "./styled/Contact";

import errorIcon from "./img/error.svg";
import successIcon from "./img/success.svg";
import Header from "../../components/Header";
import Footer from "../../components/Footer";

const AlertMessage = ({ type, title, subtitle, onClick }) => {
  return (
    <>
      <img
        src={type === "error" ? errorIcon : successIcon}
        alt={type === "error" ? "error" : "success"}
      />
      <h2>{title}</h2>
      <p>{subtitle}</p>
      <AlertButtonContainer>
        <PrimaryButton onClick={onClick}>
          {type === "error" ? "Try again" : "Go to Homepage"}
        </PrimaryButton>
      </AlertButtonContainer>
    </>
  );
};

const Contact = () => {
  const [name, setName] = useState("");
  const [topic, setTopic] = useState("Generic Enquiry");
  const [email, setEmail] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [captchaVal, setCaptchaVal] = useState(null);
  const [captchaErr, setCaptchaErr] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [result, setResult] = useState(false);

  const navigate = useNavigate();

  const sendEmail = async (e) => {
    e.preventDefault();
    setError(false);

    const resetForm = () => {
      setEmail("");
      setSubject("");
      setMessage("");
      setCaptchaErr("");
    };

    if (!captchaVal) {
      setCaptchaErr("Please prove that you are not a robot!");
    } else {
      try {
        setLoading(true);
        await emailjs.send(
          "service_lsispij",
          "template_5iraubn",
          {
            user_name: name,
            user_email: email,
            topic,
            subject,
            message,
            "g-recaptcha-response": captchaVal
          },
          "LfDh7YJFlzKxALeuF"
        );
        setLoading(false);
        setError(false);
        setResult(true);
        resetForm();
      } catch (error) {
        setLoading(false);
        setError(true);
        console.log(error);
      }
    }
  };

  return (
    <>
      <Header />
      <StyledMain grayBg>
        <MainContainer>
          {loading && <StyledSpinner />}
          {!error && !result && !loading && (
            <>
              <h2>Contact Us</h2>
              <p>
                Want to get in touch? Send us a message and our team will be in
                touch as soon as possible.
              </p>
              <form onSubmit={sendEmail}>
                <Input
                  placeholder="Name"
                  label="Name"
                  value={name}
                  type="text"
                  name="user_name"
                  onChange={(ev) => setName(ev.target.value)}
                />
                <Input
                  required
                  type="email"
                  value={email}
                  onChange={(ev) => setEmail(ev.target.value)}
                  placeholder="Email address"
                  label="Email address"
                  name="user_email"
                />
                <Input
                  placeholder="Subject"
                  label="Subject"
                  value={subject}
                  name="user_subject"
                  onChange={(ev) => setSubject(ev.target.value)}
                />

                <Select
                  placeholder="Please select a topic"
                  label="Topic"
                  value={topic}
                  name="user_topic"
                  onChange={(ev) => setTopic(ev.target.value)}
                >
                  <option value="Generic Enquiry">Generic Enquiry</option>
                  <option value="Manage-Mi App Enquiries">
                    Manage-Mii App Related
                  </option>
                  <option value="Payment Issue Related">
                    Payment Issue Related
                  </option>
                </Select>

                <Input
                  label="Message"
                  required
                  name="message"
                  as="textarea"
                  onChange={(ev) => setMessage(ev.target.value)}
                  value={message}
                  placeholder="Message"
                  rows={4}
                />
                <ReCAPTCHA
                  sitekey="6LewmxgiAAAAAI6kFjV_tNYCK4tjoPT5WtIeXiPn"
                  onChange={(c) => setCaptchaVal(c)}
                />
                {captchaErr && <InvalidField>{captchaErr}</InvalidField>}
                <PrimaryButton type="submit">Send message</PrimaryButton>
              </form>
            </>
          )}
          {error && (
            <AlertMessage
              type="error"
              title="Message failed to send."
              subtitle="Please send an email to support@manage-mii.co.uk or try again"
              onClick={() => setError(false)}
            />
          )}
          {result && (
            <AlertMessage
              type="success"
              title="Message sent!"
              subtitle="We have received your email and will get in touch as soon as possible."
              onClick={() => navigate("/")}
            />
          )}
        </MainContainer>
      </StyledMain>
      <Footer />
    </>
  );
};

export default Contact;
