import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";

import {
  getBalanceAndPin,
  savePersonalDetailsAction
} from "../../features/balance-and-pin/balanceAndPinSlice";
import usePrevious from "../../hooks/usePrevious";
import { getDeviceInfo } from "../../features/hugo-boss/hugoBossSlice";
import { formattedBalance } from "../../features/balance-and-pin/utils";

import Spinner from "../../components/Spinner";
import { AppStoreLinks } from "../pepcity/styles/Pepcity";

import {
  Alert,
  BottomSection,
  Button,
  Explore,
  Footer,
  Hero,
  Item,
  List,
  MainContainer,
  Navbar,
  Schedule,
  YourJourney
} from "./styles/TencoBobo";

import heroImg from "./img/hero.png";
import duckImg from "./img/duck.png";
import starwarsImg from "./img/starwars.png";
import appStoreLogo from "./img/app-store.svg";
import gplayLogo from "./img/play-store.svg";
import bbLogo from "./img/bb-logo.svg";
import fobsImg from "./img/fobs.png";
import copyIcon from "./img/copy-icon.svg";
import footerLogos from "./img/logos-footer.webp";
import instaIcon from "./img/instagram.svg";
import inIcon from "./img/linkedin.svg";
import shopIcon from "./img/Shop.svg";
import checkedIcon from "./img/checked.svg";
import uncheckedIcon from "./img/unchecked.svg";

const TencoBobo = () => {
  const [searchParams] = useSearchParams();
  const serialNumber = searchParams.get("serialNumber");

  const [showForm, setShowForm] = useState(false);
  const [acceptTerms, setAcceptTerms] = useState(false);
  const [fullName, setFullName] = useState("");
  const [email, setEmail] = useState("");

  const [errors, setErrors] = useState({});

  const dispatch = useDispatch();
  const {
    balanceAndPin,
    isLoading,
    savingPersonalDetails,
    errorSavingPersonalDetails
  } = useSelector((state) => state.balanceAndPin);

  const { device, gettingDeviceInfo, gettingDeviceInfoError } = useSelector(
    (state) => state.hugoBoss
  );

  const prevGettingDeviceInfo = usePrevious(gettingDeviceInfo);

  useEffect(() => {
    if (prevGettingDeviceInfo && !gettingDeviceInfoError) {
      if (device?.personalDetails?.fullName && device?.personalDetails?.email) {
        setFullName(device?.personalDetails?.fullName);
        setEmail(device?.personalDetails?.email);
        setShowForm(false);
      } else {
        setShowForm(true);
      }
    }
  }, [
    device?.personalDetails?.fullName,
    device?.personalDetails?.email,
    gettingDeviceInfoError,
    prevGettingDeviceInfo
  ]);

  const prevSavingPersonalDetails = usePrevious(savingPersonalDetails);

  useEffect(() => {
    if (prevSavingPersonalDetails && !errorSavingPersonalDetails) {
      setShowForm(false);
    }
  }, [errorSavingPersonalDetails, prevSavingPersonalDetails]);

  useEffect(() => {
    dispatch(getDeviceInfo(serialNumber));
  }, [dispatch, serialNumber]);

  useEffect(() => {
    if (!balanceAndPin && serialNumber) {
      dispatch(
        getBalanceAndPin({
          serial: serialNumber,
          query: "?provider=pannovate"
        })
      );
    }
  }, [balanceAndPin, serialNumber, dispatch]);

  const validateForm = () => {
    let formErrors = {};

    const namePattern = /^[A-Za-zÀ-ÖØ-öø-ÿ\s'-]+$/;
    if (!fullName.trim()) {
      formErrors.fullName = "Full name is required";
    } else if (!namePattern.test(fullName)) {
      formErrors.fullName =
        "Full name can only contain letters, spaces, apostrophes, and hyphens";
    }

    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!email.trim()) {
      formErrors.email = "Email is required";
    } else if (!emailPattern.test(email)) {
      formErrors.email = "Please enter a valid email address";
    }

    setErrors(formErrors);

    return Object.keys(formErrors).length === 0;
  };

  const handleSubmit = () => {
    if (validateForm()) {
      dispatch(
        savePersonalDetailsAction({
          serial: serialNumber,
          details: { fullName, email }
        })
      );
    }
  };

  const SCHEDULE = [
    { ev: "Networking opportunities with other members" },
    { ev: "Cocktail reception" },
    { ev: "Art exhibition by The Ugly Duck & Sasha Vessel" }
  ];

  const appStoreLinks = (appStoreUrl, playStoreUrl) => {
    return (
      <AppStoreLinks>
        <img
          src={appStoreLogo}
          alt="app-store"
          onClick={() => window.open(appStoreUrl, "_blank")}
        />
        <img
          src={gplayLogo}
          alt="play-store"
          onClick={() => window.open(playStoreUrl, "_blank")}
        />
      </AppStoreLinks>
    );
  };

  if (isLoading) return <Spinner />;
  return (
    <MainContainer>
      <Navbar>
        <img src={bbLogo} alt="bb-logo" />
        {/* <div className="balance">
          <p>{"CURRENT BALANCE"}</p>
          <p>
            {formattedBalance(
              balanceAndPin?.balanceDetails?.availableBalance,
              balanceAndPin?.balanceDetails?.currency
            )}
          </p>
        </div> */}
      </Navbar>
      <Hero>
        <div className="img-container">
          <img src={heroImg} alt="tenco people" />
          <h1>{"Bespoke Financial and Lifestyle Services"}</h1>
        </div>

        <BottomSection height={116} margin={90} color="#C87B35" />
      </Hero>

      <Explore>
        <div className="inner">
          {/* <h2>{"Join Us in Celebrating Our New Partnership:"}</h2> */}
          <p>
            {
              "TENco is proud to announce a strategic alliance with Bourgeois Bohème (BoBo), a trailblazer in financial solutions for ultra-high-net-worth individuals and premium businesses. Since 2019, TENco has been dedicated to creating an exclusive circle of genuine connections and inspiration, and with BoBo’s cutting-edge payment solutions, we are poised to elevate your financial experience."
            }
          </p>
          <p>
            {
              "Get detailed insights into BoBo’s exclusive services and offerings here."
            }
          </p>
          <Button
            secondary
            onClick={() =>
              window.open(
                `https://s3.eu-west-2.amazonaws.com/www.digiseq.co.uk/bobo/bobo-email-brochure.pdf`,
                "_blank"
              )
            }
          >
            {"BOBO BROCHURE"}
          </Button>
          <div className="video-container">
            <video
              src={
                "https://s3.eu-west-2.amazonaws.com/www.digiseq.co.uk/bobo/boat-video.mp4"
              }
              autoPlay
              loop
              muted
              playsInline
              preload="auto"
            />
          </div>
          <h2>{"Join Us and Experience the Best in Financial Management"}</h2>
          <p>
            {
              "To mark this significant collaboration, TENco and BoBo will host an exclusive one-day event for our members. This private gathering will provide a platform to explore and engage with our innovative financial solutions, ensuring a comprehensive understanding of the bespoke services we offer."
            }
          </p>
          <p>
            {
              "Elevate your lifestyle with seamless payments and comprehensive financial solutions."
            }
          </p>
          <Button
            onClick={() => window.open(`http://www.bobofin.com/`, "_blank")}
          >
            {"EXPLORE MORE"}
          </Button>
          <div className="img-container">
            <img src={duckImg} alt="ugly duck" />
          </div>
        </div>
        <BottomSection height={210} color="#FFF" margin={240} />
      </Explore>
      <Schedule>
        <div className="header">
          <p>{"EVENT SCHEDULE HIGHLIGHTS:"}</p>
        </div>
        <List>
          {SCHEDULE.map((entry, i) => {
            return (
              <Item key={i}>
                <p>{entry.ev}</p>
              </Item>
            );
          })}
        </List>
        <BottomSection height={198} color={"#C87B35"} margin={150} />
      </Schedule>
      <Explore>
        <div className="inner">
          <h2>{"Join Us in Celebrating Our New Partnership:"}</h2>
          <p>
            {
              "TENco is proud to announce a strategic alliance with Bourgeois Bohème (BoBo), a trailblazer in financial solutions for ultra-high-net-worth individuals and premium businesses. Since 2019, TENco has been dedicated to creating an exclusive circle of genuine connections and inspiration, and with BoBo’s cutting-edge payment solutions, we are poised to elevate your financial experience."
            }
          </p>
          <p>
            {
              "Get detailed insights into BoBo’s exclusive services and offerings here."
            }
          </p>

          <div className="img-container">
            <img src={starwarsImg} alt="trooper" />
          </div>
        </div>
        <BottomSection height={210} color="#FFF" margin={240} />
      </Explore>
      <YourJourney>
        <div className="intro">
          <h2>
            {
              "Don't miss out on this unique opportunity to elevate your financial journey"
            }
          </h2>
        </div>
        <video
          src={
            "https://s3.eu-west-2.amazonaws.com/www.digiseq.co.uk/bobo/people-video.mp4"
          }
          autoPlay
          loop
          muted
          playsInline
          preload="auto"
        />
        <div className="connect">
          <h3>{"Connect your keyfob to your BoBo account."}</h3>
          <p>{"Download the BoBo app and choose a membership."}</p>
          <p>
            <span>
              {"For further information, please contact support at  "}
            </span>
            <a href="mailto:support@bobofin.com">support@bobofin.com</a>
            <span>{" or via chat in the app if you have any questions."}</span>
          </p>
          <div className="fobs">
            <img src={fobsImg} alt="fob Arosa" />
          </div>
        </div>

        <div className="activation">
          <p>{"Here is your activation code:"}</p>
          <div className="code">
            <span
              className="activation-code"
              onClick={() =>
                navigator.clipboard.writeText(balanceAndPin?.activationCode)
              }
            >
              {balanceAndPin?.activationCode || "N/A"}
            </span>
            <img className="copy" src={copyIcon} alt="copy-icon" />
          </div>
          <p>{"Download our app:"}</p>
          {appStoreLinks(
            "https://apps.apple.com/us/app/bourgeois-boheme/id1574430730",
            "https://play.google.com/store/apps/details?id=com.paymeswiss.bobofin"
          )}
          <p>{"For additional information click below:"}</p>
          <Button
            onClick={() =>
              window.open(`https://www.bobofin.com/become-a-member`, "_blank")
            }
          >
            {"Join Bourgeois Bohème"}
          </Button>
        </div>
      </YourJourney>
      <Footer>
        <div className="socials">
          <img
            src={instaIcon}
            alt="instagram"
            onClick={() =>
              window.open(
                `https://www.instagram.com/bobo_network?igsh=MTk0N3hmZWc5dnNmZg==`,
                "_blank"
              )
            }
          />
          <img
            src={inIcon}
            alt="linkedin"
            onClick={() =>
              window.open(
                `https://www.linkedin.com/company/bourgeoisboheme/`,
                "_blank"
              )
            }
          />
          <img
            src={shopIcon}
            alt="shop"
            onClick={() =>
              window.open(
                `https://www.bobofin.com/payment-solution/cards-devices`,
                "_blank"
              )
            }
          />
        </div>
        <a
          href="https://s3.eu-west-2.amazonaws.com/www.digiseq.co.uk/bobo/tc-arosa.pdf"
          rel="noopener noreferrer"
          target="_blank"
        >
          {"Terms and Conditions "}
        </a>
        <a
          href="https://www.bobofin.com/privacy-policy-cookies-policy"
          rel="noopener noreferrer"
          target="_blank"
        >
          {"Privacy Policy & Cookies Policy"}
        </a>
        <img src={footerLogos} alt="footer-logos" />
      </Footer>
      {showForm && (
        <Alert>
          <div className="inner-container">
            <div className="form">
              <h2 className="title">{"Welcome"}</h2>
              <p className="subtitle">
                {
                  "Please provide your details for a personalized journey with us."
                }
              </p>
              <div className="inputs">
                <div className="form-group">
                  <input
                    value={fullName}
                    id="fullName"
                    type="text"
                    onChange={(ev) => setFullName(ev.target.value)}
                    placeholder={"Full name"}
                  />
                  {errors.fullName && (
                    <p className="error">{errors.fullName}</p>
                  )}
                </div>
                <div className="form-group">
                  <input
                    value={email}
                    id="email"
                    type="email"
                    onChange={(ev) => setEmail(ev.target.value)}
                    placeholder={"Email"}
                  />
                  {errors.email && <p className="error">{errors.email}</p>}
                </div>
              </div>
              <div
                className="terms"
                onClick={() => setAcceptTerms((prev) => !prev)}
              >
                <img
                  src={acceptTerms ? checkedIcon : uncheckedIcon}
                  alt="accept"
                />{" "}
                <p>
                  {"I accept "}{" "}
                  <a
                    href="https://s3.eu-west-2.amazonaws.com/www.digiseq.co.uk/bobo/tc-arosa.pdf"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    {"Terms and Conditions"}
                  </a>
                </p>
              </div>
              <button disabled={!acceptTerms} onClick={() => handleSubmit()}>
                {"Proceed"}
              </button>
            </div>
            <img className="logos" src={footerLogos} alt="logos" />
          </div>
        </Alert>
      )}
    </MainContainer>
  );
};

export default TencoBobo;
