import axios from "axios";
import { ENV } from "../../env";

// http://www.dev-manage-mii.uk/api/das/campaign//{{serialNumber}}/voting-options  //GET
// http://www.dev-manage-mii.uk/api/das/campaign/{{serialNumber}}/vote //POST
// http://www.dev-manage-mii.uk/api/das/campaign/{{serialNumber}}/feedback-form  //GET
// http://www.dev-manage-mii.uk/api/das/campaign/{{serialNumber}}/feedback POST

export const API_URL = `https://www.${
  ENV !== "production" ? (ENV === "development" ? "dev" : ENV) + "-" : ""
}manage-mii.uk/api/das/campaign`;

// GET voting options (public)
const getVotingOptions = async (serialNumber) => {
  const response = await axios.get(API_URL + `/${serialNumber}/voting-options`);

  return response.data;
};

// GET feedback form (public)
const getFeedbackForm = async (serialNumber) => {
  const response = await axios.get(API_URL + `/${serialNumber}/feedback-form`);

  return response.data;
};

// POST vote (public)
const submitVote = async (payload) => {
  const { serialNumber, vote } = payload;
  const response = await axios.post(API_URL + `/${serialNumber}/vote`, vote);

  return response.data;
};

// POST feedback form (public)
const submitFeedbackForm = async (payload) => {
  const response = await axios.post(
    API_URL + `/${payload.serialNumber}/feedback`,
    {
      feedbackResponses: payload.answers
    }
  );

  return response.data;
};

const cagedSteelService = {
  getFeedbackForm,
  getVotingOptions,
  submitFeedbackForm,
  submitVote
};

export default cagedSteelService;
