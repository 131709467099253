import styled, { createGlobalStyle } from "styled-components";
import GilroyLight from "../font/Gilroy-Light.otf";

export const PepsicoFontStyle = createGlobalStyle`
@font-face {
  font-family: 'Gilroy Light';
  src: url(${GilroyLight}) format('opentype');
  font-weight: 300;
  font-style: normal;
  font-display: auto;
}
`;

export const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 480px;
  margin: auto;
  min-height: 100vh;
  background-color: #001369;
`;

export const Navbar = styled.nav`
  background-color: #0124d0;
  padding: 8px 16px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  .balance {
    display: flex;
    flex-direction: row;
    gap: 8px;
    text-align: right;
    font-feature-settings: "clig" off, "liga" off;
    font-family: "Noka";
    font-size: 18px;
    font-style: normal;
    font-weight: 300;
    line-height: 24px; /* 133.333% */
    letter-spacing: 0.15px;
    color: white;
    .ammount {
      font-size: 24px;
      font-weight: 600;
    }
  }
`;

export const HeroImg = styled.img`
  padding-top: 32px;
  width: 100%;
`;

export const Welcome = styled.section`
  padding: 24px 24px 48px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  text-align: center;

  h1 {
    color: #fff;
    text-align: center;
    font-feature-settings: "clig" off, "liga" off;
    font-family: "Noka";
    font-size: 32px;
    font-style: normal;
    font-weight: 800;
    line-height: 40px; /* 125% */
  }

  p {
    margin-bottom: 24px;
    color: #fff;
    text-align: center;
    font-feature-settings: "clig" off, "liga" off;
    font-family: "Gilroy Light";
    font-size: 16px;
    font-style: normal;
    font-weight: 300;
    line-height: 24px; /* 150% */
    letter-spacing: 0.5px;
  }
`;

export const PaymentAccount = styled.section`
  padding: 32px 24px;
  background-color: #fff;
  align-items: flex-start;
  gap: 16px;

  .inner {
    display: flex;
    gap: 8px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
  }

  .button {
    color: white;
    cursor: pointer;
    display: inline-flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 8px 8px 8px 16px;
    font-style: normal;
    background: #000;
    border-radius: 360px;
    margin: 16px 0;
    img {
      margin-bottom: -4px;
    }
  }

  .title {
    font-family: Inter;
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: 40px;
    margin-bottom: 16px;
  }
  .subtitle {
    font-family: Inter;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 40px; /* 166.667% */
    margin-bottom: 8px;
  }
  p {
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 150% */
    letter-spacing: 0.5px;
    color: #000;
  }

  .logo {
    width: 189px;
    height: 60px;
  }

  .activation-code {
    display: flex;
    padding: 12px 16px;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
    border-radius: 6px;
    border: 1px solid #000;
    background: #fff;
    color: #000;
    /* Headline/Medium */
    font-family: "Inter";
    font-size: 28px;
    font-style: normal;
    font-weight: 700;
    line-height: 36px; /* 128.571% */
    color: #000;
  }
`;

export const Logos = styled.div`
  padding: 24px 0;
  background-color: #db3c8e;
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: center;

  p {
    color: #fff;
    text-align: center;
    font-feature-settings: "clig" off, "liga" off;
    font-family: "Noka";
    font-size: 32px;
    font-style: normal;
    font-weight: 800;
    line-height: 40px; /* 125% */
  }
`;

export const NextStepsContainer = styled.div`
  margin-top: -1px;
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 16px;
  background-color: white;
`;

export const StepContainer = styled.div`
  padding: 32px 16px;
  h2 {
    margin-top: 16px;
    font-family: Inter;
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: 40px;
  }
  p {
    margin-top: 16px;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 150% */
    letter-spacing: 0.5px;
    columns: #626262;
  }
  .pay-anywhere {
    margin: 24px auto;
    width: 100%;
    object-fit: contain;
  }
`;

export const CurvelogoContainer = styled.div`
  margin-bottom: 16px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  .separator {
    height: 25px;
    object-fit: cover;
    width: 100%;
  }
  .title {
    font-family: Inter;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 40px;
  }

  .curve-logo {
    height: 32px;
    width: 152px;
  }
  .flags {
    display: flex;
    gap: 8px;
    img {
      height: 26px;
    }
  }
`;

export const StepNumber = styled.div`
  display: flex;
  width: 40px;
  padding: 8px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 360px;
  background: #f6f6f7;
`;

export const AppStoreLinks = styled.section`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 16px;

  img {
    cursor: pointer;
    margin-top: 24px;

    height: 50px;
  }
`;

export const Footer = styled.footer`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 24px 16px;
  gap: 16px;
  p {
    color: white;
    text-align: center;
    font-family: "Inter";
    font-size: 16px;
    font-style: normal;
    font-weight: 300;
    line-height: 24px; /* 150% */
    letter-spacing: 0.5px;
  }
`;
