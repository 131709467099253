import axios from "axios";
import { ENV } from "../../env";

export const API_URL = `https://www.${
  ENV !== "production" ? (ENV === "development" ? "dev" : ENV) + "-" : ""
}manage-mii.uk/api/das/business-card`;

// GET device info
const addBusinessCard = async (businessCard) => {
  const response = await axios.post(API_URL, businessCard);

  return response.data;
};

//PATCH
const updateBusinessCard = async (businessCard, serialNumber) => {
  const response = await axios.patch(
    `${API_URL}/${serialNumber}`,
    businessCard
  );

  return response.data;
};

// PUT claim device
// const claimDevice = async ({
//   serial,
//   firstName,
//   lastName,
//   email,
//   claimPinCode
// }) => {
//   const response = await axios.put(API_URL + `/${serial}/claim`, {
//     firstName,
//     lastName,
//     email,
//     claimPinCode
//   });

//   return response.data;
// };

// GET activate ticket
// const activateTicket = async ({ serialNumber, ticketId }) => {
//   const response = await axios.get(
//     API_URL + `/${serialNumber}/ticket/${ticketId}/activate`
//   );

//   return response.data;
// };

const pay360Service = {
  addBusinessCard,
  updateBusinessCard
};

export default pay360Service;
