import React, { Suspense, useEffect } from "react";
import { useMediaQuery } from "react-responsive";
import { useDispatch, useSelector } from "react-redux";
import { StyledMain } from "../../styled/StyledMain";
import { MainContainer } from "../../styled/StyledMainContainer";
import {
  BrandsContainer,
  Image,
  ImageContainer
} from "./components/styled/Brands";
import BrandPreview from "./components/BrandPreview";

import { getEntities } from "../../features/entities/entitySlice";
import FeaturedBrand from "./components/FeaturedBrand";
import FilterBar from "../products/components/FilterBar";
import TopSectionWithFilter from "../../components/TopSectionWithFilter";
import Spinner from "../../components/Spinner";
import Header from "../../components/Header";
import Footer from "../../components/Footer";

import moreComingSoonImg from "./img/more-coming-soon.png";

const Brands = () => {
  const isSmallScreen = useMediaQuery({ query: "(max-width: 1440px)" });
  const dispatch = useDispatch();
  const { entities } = useSelector((state) => state.entities);

  useEffect(() => {
    if (entities.length === 0) {
      dispatch(getEntities());
    }
  }, [dispatch, entities]);

  return (
    <>
      <Header />
      <StyledMain>
        <MainContainer>
          <FeaturedBrand />
          {!isSmallScreen && (
            <TopSectionWithFilter
              headerText={
                "We support over one hundred payment-ready wearables designed by fashion brands."
              }
              brand={entities[Math.floor(Math.random() * entities.length)]}
            />
          )}
          <FilterBar />
          <BrandsContainer>
            {entities?.length > 0
              ? entities.map((e, i) => <BrandPreview key={i} data={e} />)
              : null}

            {entities?.length % 3 !== 0 && (
              <ImageContainer>
                <Suspense fallback={<Spinner />}>
                  <Image
                    more="true"
                    src={moreComingSoonImg}
                    alt="more coming soon"
                  />
                </Suspense>
              </ImageContainer>
            )}
          </BrandsContainer>
        </MainContainer>
      </StyledMain>
      <Footer />
    </>
  );
};

export default Brands;
