import styled from "styled-components";

export const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 480px;
  margin: auto;
  min-height: 100vh;
  background: #f5f5f7;

  h3,
  p {
    font-family: "Noto Sans", sans-serif;
    font-optical-sizing: auto;
    font-weight: 300;
    font-style: normal;
    font-variation-settings: "wdth" 100;
    margin: 0 auto;
  }

  .evr-container {
    background: #fff;
    text-align: center;
    width: 100%;
  }
  .evr-section {
    padding: 25px;
    margin-bottom: 25px;

    .evr-balance {
      h3 {
        margin-bottom: 16px;
        text-transform: uppercase;
        display: block;
        font-size: 1.17em;
        margin-block-start: 1em;
        margin-block-end: 1em;
        margin-inline-start: 0px;
        margin-inline-end: 0px;
        font-weight: bold;
        unicode-bidi: isolate;
      }

      p {
        font-size: 230%;
      }
    }
  }
  .evr-logo {
    margin: 30px auto 50px auto;
    max-width: 320px;
  }
  .evr-logo svg {
    fill: #000;
  }
`;
