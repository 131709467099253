import axios from "axios";
import { ENV } from "../../env";

export const API_URL = `https://www.${
  ENV !== "production" ? (ENV === "development" ? "dev" : ENV) + "-" : ""
}manage-mii.uk/api/discover/entities`;

// Get entities (public)
const getEntities = async () => {
  const response = await axios.get(API_URL);

  return response.data;
};

// Get entity by id (public)
const getEntityById = async (id) => {
  const response = await axios.get(API_URL + `/${id}`);
  return response.data;
};

const deviceService = {
  getEntities,
  getEntityById
};

export default deviceService;
