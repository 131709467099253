import React, { Suspense } from "react";
import { Image, ImageContainer, BrandContainer } from "./styled/Brands";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  resetDevice,
  resetDevicesByBrand
} from "../../../features/devices/deviceSlice";
import Spinner from "../../../components/Spinner";

const BrandPreview = ({ data }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id, name, description, coverImage, storeLink } = data;
  return (
    <BrandContainer
      onClick={() => {
        dispatch(resetDevice());
        dispatch(resetDevicesByBrand());
        navigate(`/brands/${id}`, {
          state: {
            id,
            brand: name,
            coverImage,
            description,
            storeLink
          }
        });
      }}
    >
      <ImageContainer>
        <Suspense fallback={<Spinner />}>
          <Image src={coverImage} alt="brand" />
        </Suspense>
      </ImageContainer>
      <h4>{name}</h4>
    </BrandContainer>
  );
};

export default BrandPreview;
