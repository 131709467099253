import React from "react";
import { Footer, MainContainer, NavBar } from "./styles/SharedStyles";
import { Banner, Contestants, Form, Player, Question } from "./styles/Vote";

import logo from "./img/logo.svg";
import menuIcon from "./img/menu-burger.svg";
import playerOne from "./img/wizards.png";
import playerTwo from "./img/bulls.png";

const FossilVote = () => {
  return (
    <MainContainer>
      <NavBar>
        <img className="logo" src={logo} alt="logo" />
        <img className="menu" src={menuIcon} alt="menu" />
      </NavBar>
      <Banner>
        <h1>Vote Now!</h1>
      </Banner>
      <Contestants>
        <Player left>
          <img src={playerOne} alt="player one" />
          <p>Washington</p>
          <p>Wizards</p>
        </Player>
        <div className="date-container">
          <p className="date">24th September</p>
          <p>vs</p>
        </div>
        <Player>
          <img src={playerTwo} alt="player two" />
          <p>Chicago</p>
          <p>Bulls</p>
        </Player>
      </Contestants>
      <Question>
        <h2>
          Who’s going to win? Predict the outcome for a shot at winning X.
        </h2>
        <div className="logos">
          <div className="logo">
            <img src={playerOne} alt="player one" />
          </div>
          <div className="logo">
            <img src={playerTwo} alt="player two" />
          </div>
        </div>
      </Question>
      <Form>
        <p>Your details:</p>
        <input placeholder="Full name*" />
        <input placeholder="Email*" />
        <div className="button">SUBMIT</div>
      </Form>
      <Footer>
        <img className="logo" src={logo} alt="logo" />
      </Footer>
    </MainContainer>
  );
};

export default FossilVote;
