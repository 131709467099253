import React from "react";
import { BottomSection, Content, MainContainer } from "./styled/Progress";
import { ActionButton } from "./styled/SharedStyles";

import { useNavigate, useParams } from "react-router-dom";
import PlayerBadge from "./components/PlayerBadge";
import Header from "./components/Header";
import { useSelector } from "react-redux";

import tree1 from "./img/tree1.png";
import tree2 from "./img/tree2.png";
import tree3 from "./img/tree3.png";
import tree4 from "./img/tree4.png";
import tree5 from "./img/tree5.png";
import tree6 from "./img/tree6.png";

const Progress = () => {
  const navigate = useNavigate();

  const params = useParams();

  const { device } = useSelector((state) => state.ascot);

  const treeImage = {
    1: tree1,
    2: tree2,
    3: tree3,
    4: tree4,
    5: tree5,
    6: tree6
  };

  return (
    <>
      <MainContainer>
        <Header>
          <PlayerBadge
            playerName={device?.playerName || ""}
            teamName={device?.groupName || ""}
          />
        </Header>
        <div style={{ height: 45 }}></div>
        <Content>
          <img
            style={{ width: "75%", margin: "auto" }}
            src={treeImage[device.location === 7 ? 6 : device.location]}
            alt="xmas tree"
          />
          <BottomSection>
            <h1>{device.location >= 6 ? `WELL DONE!` : `NICE WORK!`}</h1>
            <div style={{ marginBottom: 16 }}>
              {device.location >= 6 ? (
                <p>
                  {`You’ve decorated the whole tree! Go back to base to get your prize.`}{" "}
                </p>
              ) : (
                <>
                  <p>{`You added a bauble to the tree.`} </p>
                  <p>{`${6 - device.location} more ${
                    6 - device.location > 1 ? "clues" : "clue"
                  } to go!`}</p>
                </>
              )}
            </div>
            {device.location < 6 && (
              <ActionButton
                onClick={() => navigate(`/ascot-family-day/${params.serial}`)}
              >
                {device.location === 6 ? "DONE" : "NEXT CLUE"}
              </ActionButton>
            )}
          </BottomSection>
        </Content>
      </MainContainer>
    </>
  );
};

export default Progress;
