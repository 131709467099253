import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { animated, useSpring } from "react-spring";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

import { fetchDeviceAction } from "../../features/tap2start/tap2StartSlice";
import usePrevious from "../../hooks/usePrevious";

import {
  MainContainer,
  ContentSkeletonContainer,
  DigiseqLogoContainer,
  ImageContainer,
  ProductContainer,
  ProductImageSkeletonContainer,
  SkeletonContainer,
  NextStepsContainer,
  StepContainer,
  StepNumber,
  SectionContainer,
  ShowMore,
  Socials,
  Footer,
  Link,
  CurvelogoContainer,
} from "./styles/Tap2StartScs";

import { AppStoreLinks } from "../tap2start/styles/BinChecker";
import { B2, Divider, H2, B1 } from "../tap2start/styles/SharedStyles";


import noImage from "../tap2start/img/no-image.svg";
import scsWearableImage from "./img/scs_wearable.png"
import appStoreBadge from "../tap2start/img/app-store-badge.svg";
import playStoreBadge from "../tap2start/img/play-store-badge.svg";
import payAnyWhereImage from "../tap2start/img/pay-anywhere-image.png";
import digiseqLogo from "./img/digiseq_logo.png";
import facebookIcon from "./img/facebook.svg";
import twitterIcon from "./img/twitter.svg";
import linkedinIcon from "./img/linkedin.svg";
import curveLogo from "./img/curve_logo.png";

import { SCS_DATA } from "./data";

const Tap2StartScs = () => {
  const [searchParams] = useSearchParams();
  const serialNumber = searchParams.get("serialNumber");
  const [showMore, setShowMore] = useState(false);

  const fadeInImageProps = useSpring({
    opacity: 1,
    from: { opacity: 0 },
    config: { duration: 1000 }, // You can adjust the duration as needed
  });

  const dispatch = useDispatch();

  const { fetchingDevice, device } = useSelector((state) => state.tap2Start);

  const previousFetchingDevice = usePrevious(fetchingDevice);

  useEffect(() => {
    async function effect() {
      if (previousFetchingDevice && device && device?.redirectOnCompleteUrl) {
        window.location.replace(device?.redirectOnCompleteUrl);
      }
    }

    effect();
  }, [previousFetchingDevice, device]);

  useEffect(() => {
    if (serialNumber) dispatch(fetchDeviceAction(serialNumber));
  }, [dispatch, serialNumber]);

  const appStoreLinks = (appStoreUrl, playStoreUrl) => {
    return (
      <AppStoreLinks>
        <img
          src={appStoreBadge}
          alt="app-store"
          onClick={() => window.open(appStoreUrl, "_blank")}
        />
        <img
          src={playStoreBadge}
          alt="play-store"
          onClick={() => window.open(playStoreUrl, "_blank")}
        />
      </AppStoreLinks>
    );
  };

  if (fetchingDevice || device?.redirectOnCompleteUrl)
    return (
      <MainContainer>
        <ProductImageSkeletonContainer>
          <Skeleton width={160} height={160} />
        </ProductImageSkeletonContainer>
        <ContentSkeletonContainer>
          <Skeleton height={100} />
          <SkeletonContainer>
            <Skeleton height={24} />
          </SkeletonContainer>
          <SkeletonContainer>
            <Skeleton height={24} width={250} />
          </SkeletonContainer>
          <SkeletonContainer>
            <Skeleton height={24} width={300} />
          </SkeletonContainer>
        </ContentSkeletonContainer>
      </MainContainer>
    );

  return (
    <animated.div style={fadeInImageProps}>
      <MainContainer>
        <DigiseqLogoContainer>
          <img
            src={digiseqLogo}
            alt="digiseq logo"
            onClick={() => window.open("https://www.digiseq.co.uk", "_blank")}
          />
        </DigiseqLogoContainer>
        <ProductContainer>
          <ImageContainer noImage={!device?.wearableImageURL}>
            <img src={device?.wearableImageURL || scsWearableImage} alt="product" />
          </ImageContainer>
        </ProductContainer>
        <NextStepsContainer>
          <CurvelogoContainer>
            <img src={curveLogo} alt="logo" />
          </CurvelogoContainer>
          <StepContainer>
            <StepNumber>1</StepNumber>
            <H2>Create a Curve Account</H2>
            <B2>
              Register your card details with Curve to receive a free compatible
              virtual bank card.
            </B2>
            <B2>Download the Curve App</B2>
            {appStoreLinks(
              "https://apps.apple.com/gb/app/curve-supercharge-your-money/id1049397112",
              "https://play.google.com/store/apps/details?id=com.imaginecurve.curve.prd"
            )}
          </StepContainer>
          <Divider />
          <StepContainer>
            <StepNumber>2</StepNumber>
            <H2>Link Your Curve Virtual Card Using the Manage-Mii App</H2>
            <B2>
              Follow the instructions on the Manage-Mii app and use your new
              Curve Card details when prompted.
            </B2>
            {appStoreLinks(
              "https://apps.apple.com/app/manage-mii/id6446908503",
              "https://play.google.com/store/apps/details?id=com.digiseq.managemii"
            )}
          </StepContainer>
          <Divider />
          <StepContainer>
            <StepNumber>3</StepNumber>
            <H2>Use your fingernail anywhere Mastercard is accepted!</H2>
            <B2>
              Tap your finger at any card terminal where Mastercard Contactless
              is enabled.
            </B2>
            <img src={payAnyWhereImage} alt="pay anywhere" />
          </StepContainer>
        </NextStepsContainer>
        <SectionContainer>
          <div className="inner">
            <H2>
              {SCS_DATA.smartchip.title}
              <span className="smart-chip">{SCS_DATA.smartchip.titleHl}</span>?
            </H2>
            <B1>{SCS_DATA.smartchip.p1}</B1>
          </div>
        </SectionContainer>
        <SectionContainer>
          <div className="inner">
            <H2>
              {SCS_DATA.digiseq.title}
              <span className="digiseq">{SCS_DATA.digiseq.titleHl}</span>?
            </H2>
            <B1>{SCS_DATA.digiseq.p1}</B1>
            {showMore && (
              <>
                <B1 className="st">{SCS_DATA.digiseq.sectionTitle}</B1>
                <B1>{SCS_DATA.digiseq.p2}</B1>
                <B1>{SCS_DATA.digiseq.p3}</B1>
              </>
            )}
            <ShowMore onClick={() => setShowMore(!showMore)}>
              {`Show ${showMore ? "less" : "more"}`}
            </ShowMore>
          </div>
        </SectionContainer>
        <Footer>
          <H2 className="digiseq">{SCS_DATA.footer.title}</H2>
          <H2>{SCS_DATA.footer.subtitle}</H2>
          <H2>{SCS_DATA.footer.subtitle2}</H2>
          <div className="links">
            <B2
              onClick={() => window.open(SCS_DATA.footer.link1.url, "_blank")}
            >
              {SCS_DATA.footer.link1.title}
            </B2>
            <B2
              onClick={() => window.open(SCS_DATA.footer.link2.url, "_blank")}
            >
              {SCS_DATA.footer.link2.title}
            </B2>
            <B2
              onClick={() => window.open(SCS_DATA.footer.link3.url, "_blank")}
            >
              {SCS_DATA.footer.link3.title}
            </B2>
            <B2>
              Contact us with any queries at{" "}
              <Link href="mailto:support@chester-racecourse.com">
                partnerships@digiseq.co.uk
              </Link>
            </B2>
            <Socials>
              <img
                src={facebookIcon}
                alt="facebook"
                onClick={() =>
                  window.open(SCS_DATA.footer.socials.facebook.url, "_blank")
                }
              />
              <img
                src={linkedinIcon}
                alt="linkedin"
                onClick={() =>
                  window.open(SCS_DATA.footer.socials.linkedin.url, "_blank")
                }
              />
              <img
                src={twitterIcon}
                alt="twitter"
                onClick={() =>
                  window.open(SCS_DATA.footer.socials.twitter.url, "_blank")
                }
              />
            </Socials>
          </div>
        </Footer>
      </MainContainer>
    </animated.div>
  );
};

export default Tap2StartScs;
