import styled from "styled-components";

export const StyledContainer = styled.section`
  width: 100%;
  margin-bottom: 16px;
  background-color: white;
  background-image: ${({ imageMobile }) => `url(${imageMobile})`};
  background-size: contain;
  background-repeat: no-repeat;
  background-position: bottom;

  @media (min-width: 640px) {
    margin: 160px 0;
    background-position: 90% 0%;
    background-image: ${({ imageWeb }) => `url(${imageWeb})`};
    padding: 0 50px;
  }
`;
