import httpClient from "../../axios";
import { ENV } from "../../env";

export const API_URL = `https://www.${
  ENV !== "production" ? (ENV === "development" ? "dev" : ENV) + "-" : ""
}manage-mii.uk/api/das/mobile-scavenger-hunt/`;

const getContestants = async () => {
  const response = await httpClient.get(
    API_URL + "contestants/campaign/mastercard_cl_final_scavenger_hunt_2024"
  );

  return response.data;
};

const resetDevice = async (playerId) => {
  const response = await httpClient.post(
    API_URL + `reset/mobile-device/${playerId}`
  );

  return response.data;
};

const resetAllDevices = async () => {
  const response = await httpClient.post(
    API_URL + `reset/campaign/mastercard_cl_final_scavenger_hunt_2024`
  );

  return response.data;
};

const deliverPrizes = async (devices) => {
  const response = await httpClient.post(API_URL + `deliver-prizes`, {
    devices,
    prizeType: "PREPAID_CARD"
  });

  return response.data;
};

const scavengerHuntService = {
  getContestants,
  resetDevice,
  resetAllDevices,
  deliverPrizes
};

export default scavengerHuntService;
