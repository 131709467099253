import styled from "styled-components";

export const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
`;

export const Th = styled.th`
  padding: 10px;
  background: #f4f4f4;
  border: 1px solid #ddd;
`;

export const Td = styled.td`
  padding: 10px;
  border: 1px solid #ddd;
`;

export const SearchInput = styled.input`
  padding: 10px;
  //margin-bottom: 20px;
  width: 100%;
  box-sizing: border-box;
  font-size: 12px;
`;


export const DownloadContainer = styled.section`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
`;