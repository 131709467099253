import React from "react";

import { StyledMain } from "../../styled/StyledMain";
import { MainContainer } from "../../styled/StyledMainContainer";

import { StyledContainer } from "./styled/Container";
import CoachTrackerSignin from "./components/CoachTrackerSignin";

const CoachTrackerLogin = () => {
  return (
    <StyledMain grayBg>
      <MainContainer>
        <StyledContainer>
          <h2>Coach Tracker Login</h2>
          <CoachTrackerSignin />
        </StyledContainer>
      </MainContainer>
    </StyledMain>
  );
};

export default CoachTrackerLogin;
