import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getCampaigns,
  resetError
} from "../../features/campaign-manager/campaignManagerSlice";
import { ErrorMsg, MainContainer } from "./styled/SharedStyles";
import {
  CampaignItem,
  CampaignItemDetails,
  CampaignsContainer,
  Header,
  ListContainer
} from "./styled/CampaignManager";
import arrowRight from "./img/arrow-right.svg";
import campaignDefaultIcon from "./img/campaign-default-icon.svg";
import { useNavigate } from "react-router-dom";
import Spinner from "../../components/Spinner";
import NavBarComponent from "./components/NavBar";
import ProtectedRoute from "../../components/ProtectedRoute";
import addButtonIcon from "./img/add-button.svg";

const CampaignManager = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { campaigns, error, isLoading, isError, message } = useSelector(
    (state) => state.campaigns
  );

  useEffect(() => {
    if (!campaigns) {
      dispatch(getCampaigns());
    }
    return () => {
      dispatch(resetError());
    };
  }, [campaigns, dispatch]);

  if (isLoading) return <Spinner />;

  return (
    <MainContainer>
      <ProtectedRoute />
      <NavBarComponent leftText={"Back"} hasBackArrow to="/das-admin" />

      <CampaignsContainer>
        <Header>
          <h1>Campaigns</h1>
          <img
            src={addButtonIcon}
            onClick={() => navigate(`/campaign-manager/new-campaign`)}
            alt="add campaign"
          />
        </Header>
        <ListContainer>
          {campaigns?.length > 0 &&
            campaigns.map((e, i) => (
              <CampaignItem
                key={e.id}
                onClick={() => navigate(`/campaign-manager/${e.id}`)}
              >
                <img src={campaignDefaultIcon} alt="campaign icon" />
                <CampaignItemDetails>
                  <div>{e.name}</div>

                  <img src={arrowRight} alt="arrow right" />
                </CampaignItemDetails>
              </CampaignItem>
            ))}
        </ListContainer>

        {campaigns?.length === 0 && <p>No campaings available</p>}
        {error?.message && <p style={{ color: "red" }}>{error.message}</p>}
      </CampaignsContainer>
      {isError && (
        <ErrorMsg>
          {message ||
            "Oh no, we have an error, please refresh page or try again later"}
        </ErrorMsg>
      )}
    </MainContainer>
  );
};

export default CampaignManager;
