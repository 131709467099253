import { css } from "styled-components";

const colors = {
  blue: "#0D2A56",
  secondaryText: "#7A8799"
};

const H1 = css`
  font-family: "Chronicle Display";
  font-style: normal;
  font-weight: 375;
  font-size: 24px;
  line-height: 32px;
  text-align: center;
  letter-spacing: 3px;
  color: ${colors.blue};
`;

export const H2 = css`
  font-family: "Chronicle Display";
  font-style: normal;
  font-weight: 375;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: 2px;
`;

const B1 = css`
  font-family: "Chronicle Display";
  font-style: normal;
  font-weight: 325;
  font-size: 18px;
  line-height: 24px;
  text-align: center;
  letter-spacing: -0.3px;
`;

const flexCenter = css`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const mixins = { H1, H2, B1, flexCenter };

export default mixins;
