import React from "react";
import {
  ListContainer,
  StyledListItem,
  MainContainer,
  NavBar,
  ProductContainer,
  ProductDetails,
  ItemDetailsContainer,
  ItemDetails,
  Separator,
  Footer
} from "./styles/LeoSchachter";

import logo from "./img/logo.webp";
import ring from "./img/ring.webp";
import chevronUp from "./img/chevron-up.svg";
import chevronDown from "./img/chevron-down.svg";
import { useState } from "react";

const ListItem = ({ title, children }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <StyledListItem onClick={() => setIsOpen(!isOpen)}>
        <div className="title">{title}</div>
        <div>
          <img
            src={isOpen ? chevronUp : chevronDown}
            alt={`chevron ${isOpen ? "up" : "down"}`}
          />
        </div>
      </StyledListItem>
      {isOpen && <div>{children}</div>}
    </>
  );
};

const LeoSchachter = () => {
  return (
    <MainContainer>
      <NavBar>
        <img className="logo" src={logo} alt="logo" />
      </NavBar>
      <ProductContainer>
        <h1>18K White Gold Diamond Hidden Halo Engagement Ring</h1>
      </ProductContainer>
      <ProductDetails>
        <img src={ring} alt="ring" />
        <ul>
          <li>50 West 47th St.</li>
          <li> Suite 2100</li>
          <li>New York, NY 10036</li>
          <li>12:45; 01/03/2023</li>
          <li>SKU: FRVA0361</li>
          <li>LEO-0193829</li>
        </ul>
      </ProductDetails>

      <ListContainer>
        <ListItem title={"Ownership information"}>
          <ItemDetailsContainer>
            <ItemDetails>
              <div>
                <p>Place of purchase</p>
              </div>
              <div className="rightColumn">
                <p>50 West 47th St. </p>
                <p>Suite 2100</p>
                <p>New York</p>
                <p>NY 10036</p>
              </div>
            </ItemDetails>
            <Separator />
            <ItemDetails>
              <div>
                <p>Date of Purchase</p>
              </div>
              <div className="rightColumn">
                <p>01/03/2023 </p>
              </div>
            </ItemDetails>
            <Separator />
            <ItemDetails>
              <div>
                <p>Buyer's name</p>
              </div>
              <div className="rightColumn">
                <p>John Smith</p>
              </div>
            </ItemDetails>
            <Separator />
            <ItemDetails>
              <div>
                <p>Buyer's ID</p>
              </div>
              <div className="rightColumn">
                <p>018932791</p>
              </div>
            </ItemDetails>
          </ItemDetailsContainer>
        </ListItem>
        <ListItem title={"Service information"}>
          <ItemDetailsContainer>
            <ItemDetails>
              <div>
                <p>Service centre</p>
              </div>
              <div className="rightColumn">
                <p>50 West 47th St.</p>
                <p>Suite 2100</p>
                <p>New York, NY 10036</p>
              </div>
            </ItemDetails>
            <Separator />
            <p>How to care:</p>
            <p>
              To care for a diamond ring, it's important to clean it regularly
              using a soft-bristled brush and a mild detergent, avoiding any
              harsh chemicals or abrasives that can damage the diamond or its
              setting. Additionally, it's a good idea to have the ring
              professionally inspected and cleaned at least once a year to
              ensure the diamond is secure and free from damage.
            </p>
          </ItemDetailsContainer>
        </ListItem>
      </ListContainer>
      <Footer>
        <img src={logo} alt="logo" />
      </Footer>
    </MainContainer>
  );
};

export default LeoSchachter;
