import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { composeErrorMessage } from "../../utils/utils";
import pay360Service from "./pay360Service";
import { replaceBusinessCard } from "../business-card/businessCardSlice";

const initialState = {
  businessCard: null,
  addingBusinessCard: false,
  addBusinessCardError: "",
  updatingBusinessCard: false,
  updateBusinessCardError: ""
};

export const addBusinessCard = createAsyncThunk(
  "hugoBoss/addBusinessCard",
  async (businessCard, { rejectWithValue, dispatch }) => {
    try {
      const response = await pay360Service.addBusinessCard(businessCard);
      dispatch(replaceBusinessCard(response.promoReadyData));
      return response;
    } catch (error) {
      return rejectWithValue(composeErrorMessage(error));
    }
  }
);

export const updateBusinessCard = createAsyncThunk(
  "hugoBoss/updateBusinessCard",
  async ({ serialNumber, businessCard }, { rejectWithValue, dispatch }) => {
    try {
      const response = await pay360Service.updateBusinessCard(
        businessCard,
        serialNumber
      );
      dispatch(replaceBusinessCard(response.promoReadyData));
      return response;
    } catch (error) {
      return rejectWithValue(composeErrorMessage(error));
    }
  }
);

export const pay360Slice = createSlice({
  name: "pay360",
  initialState,
  reducers: {
    reset: (state) => initialState
  },
  extraReducers: (builder) => {
    builder
      .addCase(addBusinessCard.pending, (state) => {
        state.addingBusinessCard = true;
        state.addBusinessCardError = "";
      })
      .addCase(addBusinessCard.fulfilled, (state, action) => {
        state.addingBusinessCard = false;
        state.businessCard = action.payload?.promoReadyData;
      })
      .addCase(addBusinessCard.rejected, (state, action) => {
        state.addingBusinessCard = false;
        state.addBusinessCardError = action.payload;
      })
      .addCase(updateBusinessCard.pending, (state) => {
        state.updatingBusinessCard = true;
        state.updateBusinessCardError = "";
      })
      .addCase(updateBusinessCard.fulfilled, (state, action) => {
        state.updatingBusinessCard = false;
        state.businessCard = action.payload;
      })
      .addCase(updateBusinessCard.rejected, (state, action) => {
        state.updatingBusinessCard = false;
        state.updateBusinessCardError = action.payload;
      });
  }
});

export const { reset } = pay360Slice.actions;

export default pay360Slice.reducer;
