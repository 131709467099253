import React from "react";
import { AscotCrown } from "../styled/SharedStyles";

import ascotCrown from "../img/ascot-crown.svg";

const Header = ({ children }) => {
  return (
    <AscotCrown>
      <img src={ascotCrown} alt="ascot crown" />
      {children}
    </AscotCrown>
  );
};

export default Header;
