import React from "react";
import { useNavigate } from "react-router-dom";
import ProtectedRoute from "../../components/ProtectedRoute";
import ListItem from "./components/ListItem";
import NavBarComponent from "./components/NavBar";
import { Body, ListContainer, MainContainer } from "./styled/SharedStyles";

const DevicesManager = () => {
  const navigate = useNavigate();
  return (
    <MainContainer>
      <ProtectedRoute />
      <NavBarComponent
        leftText={"Back"}
        hasBackArrow
        title="Devices Manager"
        to="/das-admin"
      />
      <Body>
        <ListContainer>
          <ListItem
            text="Search Devices"
            onClick={() => navigate("/devices-manager/search")}
          />
          <ListItem
            text="Add Devices"
            onClick={() => navigate("/devices-manager/create")}
          />
        </ListContainer>
      </Body>
    </MainContainer>
  );
};

export default DevicesManager;
