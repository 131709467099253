import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";

import { fetchDeviceAction } from "../../features/lost-mode/lostModeSlice";
import Spinner from "../../components/Spinner";

import { ContactDetails, MainContainer, Wearable } from "./styles/LostMode";

const LostModePage = () => {
  const [searchParams] = useSearchParams();
  const serialNumber = searchParams.get("serialNumber");
  const dispatch = useDispatch();

  const { fetchingDevice, device } = useSelector((state) => state.lostMode);

  useEffect(() => {
    if (serialNumber) {
      dispatch(fetchDeviceAction(serialNumber));
    }
  }, [dispatch, serialNumber]);

  if (fetchingDevice) return <Spinner />;

  return (
    <MainContainer>
      <h1>THIS DEVICE IS DISABLED</h1>
      {device?.photo && (
        <Wearable>
          <img src={device.photo} alt="wearable" />
        </Wearable>
      )}
      {(device?.email || device?.phoneNumber) && (
        <ContactDetails>
          <div className="header">
            <p>
              {device?.message
                ? device.message
                : "This wearable has been lost. If found please contact me using the details below."}
            </p>
          </div>
          {device?.email && (
            <div className={`item ${!device?.phoneNumber && "last-item"}`}>
              <p>Email</p>
              <p>{device.email}</p>
            </div>
          )}
          {device?.phoneNumber && (
            <div className={`item last-item`}>
              <p>Phone</p>
              <p>{device.phoneNumber}</p>
            </div>
          )}
        </ContactDetails>
      )}
    </MainContainer>
  );
};

export default LostModePage;
