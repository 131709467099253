import { loadImage } from "../utils/loadImage";

function SuspenseImage(props) {
  let loadedSrc = ""; // TODO add fallback here

  if (props.src) {
    loadedSrc = loadImage(props.src).read();
  }

  // TODO check if if is neccessary...
  return <img alt={props.alt} {...props} src={loadedSrc || props.src} />;
}

export default SuspenseImage;
