import styled from "styled-components";

import bannerBg from "../img/questionnaire-bg.png";
import mixins from "./mixins";

export const MainContainer = styled.div`
  position: relative;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 480px;
  margin: auto;
  padding-bottom: 80px;
  *:not(input, textarea) {
    -moz-user-select: none; /* firefox */
    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* IE*/
    user-select: none; /* Standard syntax */
  }
`;

export const Banner = styled.div`
  background-image: url(${bannerBg});
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 16px 16px 40px;

  h1 {
    ${mixins.H1}
    color: white;
    margin-bottom: 8px;
  }

  p {
    ${mixins.B1}
    color: white;
  }

  img {
    max-width: 80px;
    margin-bottom: 16px;
  }
`;

export const QuestionsContainer = styled.div`
  display: grid;
  row-gap: 16px;
  width: 100%;
  margin-bottom: 16px;
  & * {
    ${mixins.B1}
    color:#7A8799;
  }
`;

export const QuestionContainer = styled.div`
  display: grid;
  row-gap: 8px;
  border: 1px solid #7a8799;
  padding: 16px;

  p{
    margin-bottom: 8px;
  }
`;

export const Question = styled.div`
  background: ${({ selected }) => (selected ? "#46975A" : "#f2f3f5")};
  height: 64px;
  ${mixins.flexCenter}
  color: ${({ selected }) => (selected ? "#ffffff" : "#0D2A56")};
  cursor: pointer;
`;

export const Content = styled.div`
  width: 100%;
  padding: 16px;
`;
