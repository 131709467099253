import React from "react";
import { PageNumber, PaginationContainer } from "./styled/Pagination";

import arrowLeftIcon from "./images/pag-arrow-left.svg";
import arrowRightIcon from "./images/pag-arrow-right.svg";

const Pagination = ({ noPages = 1, page, setPage }) => {
  return (
    <PaginationContainer>
      <img
        src={arrowLeftIcon}
        alt="arrow left"
        onClick={() => {
          setPage((currentPage) =>
            currentPage > 1 ? currentPage - 1 : currentPage
          );
          window.scrollTo({ top: 0, left: 0 });
        }}
      />
      {Array(noPages)
        .fill()
        .map((_, i) => (
          <PageNumber
            current={i + 1 === page}
            key={i}
            onClick={() => {
              setPage(i + 1);
              window.scrollTo({ top: 0, left: 0 });
            }}
          >
            {i + 1}
          </PageNumber>
        ))}

      <img
        src={arrowRightIcon}
        alt="arrow right"
        onClick={() => {
          setPage((currentPage) =>
            currentPage < noPages ? currentPage + 1 : currentPage
          );
          window.scrollTo({ top: 0, left: 0 });
        }}
      />
    </PaginationContainer>
  );
};

export default Pagination;
