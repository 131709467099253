import styled, { css } from "styled-components";
import introBackground from "./assets/intro-background.png";

export const colors = {
  white: "#FFFFFF",
  black: "#000000",
  background: "#E9E5E1",
  orange: "#CF4500",
  darkGray: "#3D3D3C",
  lightGray: "#F6F6F6",
  formBackground: "rgba(61, 61, 60, 0.80)",
  footerText: "rgba(74, 74, 74, 1)"
};

export const commonStyles = css`
  font-family: "Lato", sans-serif;
  font-style: normal;
`;

export const headerStyles = css`
  ${commonStyles}
  font-size: 28px;
`;

export const bodyStyles = css`
  ${commonStyles}
  font-size: 16px;
  color: ${colors.white};
`;

export const buttonStyles = css`
  ${commonStyles}
  font-size: 16px;
  border: none;
  cursor: pointer;
  border-radius: 20px;
  height: 32px;
  width: 100%;
  color: ${colors.white};
  &:active {
    filter: brightness(0.8);
  }
`;

export const inputStyles = css`
  ${commonStyles}
  font-size: 14px;
  min-height: 46px;
  background-color: ${colors.white};
  border: none;
  border-radius: 6px;
  padding: 8px 16px;
  font-weight: 400;
  font-style: italic;
  line-height: 24px;

  &:focus {
    outline: none;
  }

  &::placeholder {
    color: rgba(0, 0, 0, 0.5);
    font-weight: 300;
    font-style: italic;
    line-height: 16px;
  }

  color: rgba(0, 0, 0, 0.5);
`;

export const CheckboxContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 8px;

  input {
    width: 20px;
    height: 20px;
  }
`;

export const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 480px;
  margin: auto;
  min-height: 100vh;
  background-color: ${colors.background};

  h1 {
    font-size: 38px;
    font-style: normal;
    font-weight: 400;
    line-height: 40px;
  }

  h2 {
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 32px;
  }

  h1,
  h2 {
    ${headerStyles}
    color: ${colors.white};
  }

  p {
    ${bodyStyles}
  }
`;

export const Navbar = styled.nav`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 16px;
  background-color: ${colors.black};

  img {
    position: absolute;
    left: 16px;
    width: 32px;
    height: 32px;
    object-fit: contain;
  }
`;

export const IntroSection = styled.section`
  display: flex;
  flex-direction: column;
  padding: 16px 24px;
  background-image: url(${introBackground});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;

  .text-container {
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding: 0 18px;
  }

  h1,
  p {
    font-size: 38px;
    text-align: center;
  }
  p {
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 26px;
  }

  .disable-card-container {
    padding: 4px 0;
    border-top: 1px solid ${colors.white};
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  .disable-card-button {
    ${buttonStyles}
    text-decoration: underline;
    font-weight: 700;
  }
`;

export const InnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 16px;
`;

export const SectionContainer = styled.section`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 24px 16px;
  border-radius: 6px;
  background-image: ${({ backgroundImage }) =>
    backgroundImage ? `url(${backgroundImage})` : "none"};
  background-size: cover;
  background-repeat: no-repeat;
`;

export const SectionHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  img {
    width: 32px;
    height: 32px;
    object-fit: contain;
    cursor: pointer;
  }
`;

export const Templates = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  p {
    ${bodyStyles}
    text-align: center;
  }
`;

export const TemplateCard = styled.div`
  display: flex;
  flex-direction: column;
  height: 138px;
  border-radius: 6px;
  cursor: pointer;
  background-image: ${({ backgroundImage }) =>
    backgroundImage ? `url(${backgroundImage})` : "none"};
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;

  .template-name {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 4px;
    background-color: ${({ selected }) =>
      selected ? colors.orange : "rgba(61, 61, 60, 0.70)"};
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
  }
`;

export const Button = styled.button`
  ${buttonStyles}
  background-color: ${({ secondary }) =>
    secondary ? "transparent" : colors.orange};
  border: ${({ secondary }) =>
    secondary ? `1px solid rgba(255, 255, 255, 1)` : "none"};
  ${({ large }) =>
    large &&
    css`
      height: 48px;
    `}
`;

export const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const Messages = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;

  p {
    ${bodyStyles}
    text-align: center;
  }

  .buttons-container {
    display: flex;
    flex-direction: row;
    gap: 16px;
  }
`;

export const FormSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  background-color: ${colors.formBackground};
  border-radius: 6px;
  padding: 16px;

  input,
  textarea {
    ${inputStyles}
    width: 100%;
  }

  textarea {
    resize: none;
  }

  label {
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 150% */
    letter-spacing: 0.5px;
    color: rgba(255, 255, 255, 1);
  }

  .error {
    color: ${colors.white};
  }
`;

export const ChristmasCard = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 20px;
  background-image: ${({ backgroundImage }) =>
    backgroundImage ? `url(${backgroundImage})` : "none"};

  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  min-height: 80vh;

  .message {
    border-radius: 20px;
    background-color: rgba(0, 0, 0, 0.5);
    padding: 16px;
    display: flex;
    flex-direction: column;
    gap: 16px;

    h1,
    p {
      text-align: center;
    }

    h1 {
      font-size: 38px;
      font-style: normal;
      font-weight: 400;
      line-height: 40px;
    }
    p {
      color: ${colors.white};
      font-size: 20px;
      font-style: normal;
      font-weight: 400;
      line-height: 26px;
    }

    .buttons-container {
      padding: 0 36px;
      display: flex;
      flex-direction: row;
      gap: 16px;
    }
  }
`;
export const ConfirmCard = styled.div`
  max-width: 480px;
  position: absolute;
  background: rgba(9, 10, 15, 0.7);
  height: 100%;
  width: 100%;

  .inner-container {
    background-image: ${({ backgroundImage }) =>
      backgroundImage ? `url(${backgroundImage})` : "none"};
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    display: flex;
    flex-direction: column;
    gap: 16px;
    position: fixed;
    top: 50%;
    left: 50%;
    max-width: 360px;
    transform: translate(-50%, -50%);
    padding: 40px 24px;
    background-color: ${colors.white};
    border-radius: 6px;
    width: 90%;
    z-index: 9999;

    .logo {
      width: 100%;
      height: 57px;
      object-fit: contain;
      margin: 16px auto;
    }

    h2 {
      ${headerStyles}
      text-align: center;
      color: ${colors.black};
    }
    p {
      ${bodyStyles}
      text-align: center;
      color: ${colors.black};
    }

    .close-icon {
      position: absolute;
      top: 16px;
      right: 16px;
      width: 20px;
      height: 20px;
      object-fit: contain;
      cursor: pointer;
      background-color: #695454;
      border-radius: 50%;
      display: flex;
      padding: 2px;
      align-items: flex-start;
    }

    form {
      display: flex;

      flex-direction: column;
      justify-content: flex-start;
      gap: 16px;
      width: 100%;
    }

    .input-name {
      display: flex;
      flex-direction: row;
      gap: 16px;
    }

    input.email-input {
      ${inputStyles}
      width: 100%;
      border: 1px solid ${colors.black};
      &::placeholder {
        color: ${colors.black};
      }
      background-color: rgba(246, 246, 246, 1);
    }

    .terms-and-conditions {
      margin-top: 16px;
      text-align: center;
      font-size: 12px;
      color: ${colors.black};
      line-height: 16px;
      letter-spacing: 0.5px;

      a {
        text-decoration: underline;
      }
    }
    .error {
      color: ${colors.orange};
    }
  }

  .learn-more-link {
    text-decoration: underline;
    font-weight: 700;
  }
`;

export const Footer = styled.footer`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 12px;
  padding: 30px 0px;

  p {
    ${bodyStyles}
    color: ${colors.footerText};
    font-weight: 700;
  }
`;
