import styled from "styled-components";

export const MainContainer = styled.section`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 480px;
  margin: auto;
  min-height: 100vh;
`;

export const NavBar = styled.div`
  height: 56px;
  background-color: white;
  padding: 8px 16px;

  .logo {
    height: 40px;
  }
`;

export const ProductContainer = styled.div`
  background-color: #fafafa;
  h1 {
    font-family: "questa-grande", serif;
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 32px;
    color: #252525;
    padding: 16px;
    letter-spacing: normal;
  }
`;

export const ProductDetails = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: start;

  img {
    max-width: 100%; /* make sure the image resizes to fit the column */
    aspect-ratio: 1;
  }

  ul {
    margin: 0;
    list-style: none;
    background-color: #252525;
    padding: 16px;
    align-self: stretch;
  }

  li {
    margin-bottom: 4px;
    font-family: "adelle-sans", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: rgba(255, 255, 255, 0.6);
  }
`;

export const ListContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr;
`;

export const StyledListItem = styled.div`
  padding: 16px;
  display: flex;
  justify-content: space-between;
  background: #fafafa;
  border-bottom: 2px solid #ffffff;

  .title {
    font-family: "adelle-sans", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #000000;
  }
  img {
    margin-right: 8px;
  }
`;

export const ItemDetailsContainer = styled.div`
  background-color: #f5f5f5;
  padding: 16px;
  border-bottom: 2px solid #ffffff;
  p {
    font-family: "adelle-sans", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: rgba(37, 37, 37, 0.6);
  }
`;

export const ItemDetails = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;

  .rightColumn {
    text-align: right;
  }
`;

export const Separator = styled.div`
  margin: 16px 0;
  border: 1px solid #e2e2e2;
`;

export const Footer = styled.footer`
  margin-top: 80px;
  padding: 24px 0 152px;
  background-color: #fafafa;
  display: flex;
  justify-content: center;
  img {
    height: 64px;
  }
`;
