import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useParams, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import {
  getCampaign,
  patchCampaign
} from "../../../features/campaign-manager/campaignManagerSlice";
import { formatIsoDate } from "../../../utils/dateTime";
import { validUrlRegex } from "../../../utils/utils";
import LinkSettings from "./LinkSettings";
import NavBarComponent from "./NavBar";
import ProtectedRoute from "../../../components/ProtectedRoute";
import Spinner from "../../../components/Spinner";
import {
  Campaign,
  CampaignLinkContainer,
  LinkDetails,
  LinksContainer,
  LoopLinks,
  LoopLinksTitle,
  LinkText
} from "./styled/CampaignLinks";
import { Button, Error, TransitionIcon } from "./styled/SharedStyles";
import { Body, MainContainer } from "../styled/SharedStyles";
import chevronRight from "../img/chevron-right.svg";
import dateIcon from "../img/date-icon.svg";
import timeIcon from "../img/time-icon.svg";
import viewIcon from "../img/view-icon.svg";
import noTransitionIcon from "../img/no-transition-icon.svg";
import loopIconOff from "../img/loop-icon-off.svg";
import loopIconOn from "../img/loop-icon-on.svg";

const CampaignLinks = ({ onClose }) => {
  const dispatch = useDispatch();
  const { state } = useLocation();
  const params = useParams();
  const navigate = useNavigate();

  const { campaignId } = params;

  const { isError, wasPatched, isLoading, campaign, message } = useSelector(
    (state) => state.campaigns
  );

  const { links } = state;

  const linkTypes = {
    NONE: { icon: noTransitionIcon, text: "No transition" },
    TRANSITION_ON_READ: { icon: viewIcon, text: "Transitions when visited" },
    TRANSITION_ON_FIXED_DATE_AND_TIME: {
      icon: dateIcon,
      text: "Transitions at"
    },
    TRANSITION_ON_TIMER: { icon: timeIcon, text: "Transitions in" }
  };

  const [invalidCryptogramLink, setInvalidCryptogramLink] = useState(
    campaign?.campaignLinks?.invalidCryptogramLink ||
      "https://www.manage-mii.co.uk"
  );

  const [noCryptogramLink, setNoCryptogramLink] = useState(
    campaign?.campaignLinks?.noCryptogramLink || "https://www.manage-mii.co.uk"
  );

  const [validationError, setValidationError] = useState(null);

  const [linkId, setLinkId] = useState(null);
  const [shouldLoopLocal, setShouldLoopLocal] = useState(
    campaign?.campaignLinks?.shouldLoop
  );
  const [linksArr, setLinksArr] = useState(links?.successLinks || []);
  const [changesPending, setChangesPending] = useState(false);

  const linkSchema = Yup.object({
    invalidCryptogramLink: Yup.string()
      .matches(validUrlRegex, "Enter correct url!")
      .required("Please enter website"),
    noCryptogramLink: Yup.string()
      .matches(validUrlRegex, "Enter correct url!")
      .required("Please enter website")
  });

  const validateEntries = async (entries) => {
    setValidationError(null);
    try {
      await linkSchema.validate(entries);
      return true;
    } catch (e) {
      setValidationError({ path: e.path, message: e.message });
      return false;
    }
  };

  const handleUpdateLink = (linkDetails) => {
    setChangesPending(true);
    const newLinks = [...linksArr];
    newLinks[linkDetails.position] = linkDetails;
    setLinksArr(newLinks);
  };

  const handleAddLink = (linkDetails) => {
    setChangesPending(true);
    const newLinks = [...linksArr];
    newLinks.push(linkDetails);
    setLinksArr(newLinks);
  };

  const handleRemoveLink = (index) => {
    setChangesPending(true);
    const newLinks = [...linksArr];
    newLinks.splice(index, 1);

    const newLinksRefreshedOrder = newLinks.map((e, i) => ({
      ...e,
      position: i
    }));

    setLinksArr(newLinksRefreshedOrder);
  };

  const handleSaveChanges = async () => {
    const hasValidCryptogramLinks = await validateEntries({
      invalidCryptogramLink: invalidCryptogramLink,
      noCryptogramLink: noCryptogramLink
    });

    if (hasValidCryptogramLinks) {
      dispatch(
        patchCampaign({
          campaignId,
          key: {
            campaignLinks: {
              ...campaign?.campaignLinks,
              invalidCryptogramLink: invalidCryptogramLink,
              noCryptogramLink: noCryptogramLink,
              successLinks: linksArr,
              shouldLoop: shouldLoopLocal
            }
          }
        })
      );
    }
  };

  useEffect(() => {
    if (wasPatched) {
      dispatch(getCampaign(campaignId));
    }
  }, [wasPatched, campaign, dispatch, campaignId]);

  if (isLoading) return <Spinner />;

  return (
    <MainContainer>
      <ProtectedRoute />
      <NavBarComponent
        leftText={linkId ? null : "Back"}
        hasBackArrow={!linkId}
        title={"Links"}
        to={`/campaign-manager/${campaignId}`}
      />
      <Body>
        {linkId !== null ? (
          <LinkSettings
            campaignLinks={{
              ...campaign?.campaignLinks,
              successLinks: linksArr
            }}
            linkId={linkId}
            onAddLink={(link) => handleAddLink(link)}
            onUpdateLink={(link) => handleUpdateLink(link)}
            onRemoveLink={(index) => handleRemoveLink(index)}
            onBack={() => setLinkId(null)}
          />
        ) : (
          <div>
            <p>
              Links can be triggered after the previous link has been used or
              after a specified amount of time.
            </p>
            <p>Input links in the prefered sequence order</p>
            <div>
              <br />
              {linksArr?.length > 0 && (
                <LinksContainer>
                  {linksArr.map((e, i) => {
                    return (
                      <Campaign key={i} onClick={() => setLinkId(i)}>
                        <CampaignLinkContainer>
                          <div>
                            <LinkText>{e.link}</LinkText>
                            <LinkDetails>
                              <TransitionIcon
                                src={linkTypes[e.linkTransitionType].icon}
                                alt="icon"
                              />
                              <span>
                                {linkTypes[e.linkTransitionType].text}
                                {e.linkTransitionDate &&
                                  formatIsoDate(e.linkTransitionDate)}
                                {e.linkTransitionTimerSeconds > 0 &&
                                  `${e.linkTransitionTimerSeconds} seconds`}
                              </span>
                            </LinkDetails>
                          </div>
                        </CampaignLinkContainer>
                        <div>
                          <img src={chevronRight} alt="chevron right" />
                        </div>
                      </Campaign>
                    );
                  })}
                </LinksContainer>
              )}

              {linksArr?.length > 1 && (
                <LoopLinks>
                  <LoopLinksTitle>Loop links</LoopLinksTitle>
                  <img
                    onClick={() => setShouldLoopLocal(!shouldLoopLocal)}
                    src={shouldLoopLocal ? loopIconOn : loopIconOff}
                    alt="loop switch"
                  />
                </LoopLinks>
              )}
              <Button onClick={() => setLinkId(linksArr?.length)}>
                Add link
              </Button>
              {changesPending && (
                <Button onClick={() => handleSaveChanges()}>
                  Save changes
                </Button>
              )}
            </div>

            {isError && <Error>Error: {message}</Error>}
          </div>
        )}
      </Body>
    </MainContainer>
  );
};

export default CampaignLinks;
