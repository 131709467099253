import styled from "styled-components";

const H2 = {
  fontFamily: "Inter",
  fontSize: "24px",
  fontStyle: "normal",
  fontWeight: 700,
  lineHeight: "32px"
};

export const Navbar = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 8px 16px;
  img {
    width: 107px;
    height: 55px;
    flex-shrink: 0;
  }

  .balance {
    color: #3f2360;
    font-feature-settings: "clig" off, "liga" off;
    /* Title */
    font-family: "Inter";
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px; /* 150% */
    letter-spacing: 0.15px;
    color: #3f2360;
  }
`;

export const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 480px;
  margin: auto;
  min-height: 100vh;
  background-color: #fff;

  p {
    color: #202020;
    font-family: "Inter", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 300;
    line-height: 24px;
  }

  a {
    text-decoration: underline;
    cursor: pointer;
  }

  .stores {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    img {
      cursor: pointer;
    }
  }

  .show-more {
    display: inline;
    text-decoration: underline;
    font-weight: bold;
    cursor: pointer;
  }
`;

export const SectionContainer = styled.section`
  border-bottom: 1px solid #d8d8d9;
  .title-bar {
    padding: 16px;
    background-color: ${({ secondary }) => (secondary ? "#FF6400" : "#06a6ff")};
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    h2 {
      ${H2}
      color: white;
    }
    img {
      cursor: pointer;
    }
  }
`;

export const SectionContent = styled.div`
  padding: 24px 16px;
  display: flex;
  flex-direction: column;
  gap: 16px;

  h2 {
    ${H2}
    span {
      color: #ff6400;
    }
  }
`;

export const Footer = styled.footer`
  padding: 24px 16px 64px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
  background-color: #06a6ff;

  p {
    font-family: "Inter";
    font-weight: 100;
    font-size: 12px;
    color: white;
  }

  img {
    width: 117px;
    height: 33.724px;
    object-fit: contain;
  }
`;

export const ButtonWithShadow = styled.div`
  display: flex;
  padding: 16px;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  border-radius: var(--Spacing-4, 4px);
  background: #fff;
  box-shadow: 0px 12px 16px -4px rgba(0, 0, 0, 0.08),
    0px 0px 6px -2px rgba(0, 0, 0, 0.05);
  font-family: "Inter";
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px; /* 150% */
  letter-spacing: 0.15px;
`;

export const SolidButton = styled.div`
  display: flex;
  padding: 16px 40px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  border-radius: 6px;

  background: ${({ disabled, secondary }) =>
    disabled
      ? "lightgray"
      : secondary
      ? "linear-gradient(90deg, #FF6400 0%, #FF6400 100%);"
      : "linear-gradient(90deg, #06a6ff 0%, #06a6ff 100%);"};

  color: #fff;
  text-align: center;
  font-feature-settings: "clig" off, "liga" off;
  /* Label */
  font-family: "Inter";
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px; /* 150% */
  letter-spacing: 0.15px;
`;

export const OutlinedButton = styled.div`
  display: flex;
  padding: 16px 40px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  border-radius: 6px;
  border: 1px solid #06a6ff;
  color: #06a6ff;
  text-align: center;
  font-feature-settings: "clig" off, "liga" off;
  font-family: "Manrope";
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px; /* 150% */
`;

export const MuchbetterLogo = styled.img`
  width: 197px;
  height: 43px;
`;

export const ActivationCode = styled.div`
  display: flex;
  padding: 12px 16px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  border-radius: 6px;
  border: 1px solid #000;
  background: #fff;
  color: #000;
  font-feature-settings: "clig" off, "liga" off;
  /* Headline/Medium */
  font-family: "Inter";
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: 36px; /* 128.571% */
  color: #000;
`;

export const InfoAlert = styled.div`
  max-width: 480px;
  position: absolute;
  background: rgba(9, 10, 15, 0.64);
  height: 100%;
  width: 100%;
  text-align: center;

  .inner-container {
    position: fixed;
    max-width: 360px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 40px 24px;
    border-radius: 8px;
    background-color: #fff;
    width: 90%;
    z-index: 9999;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 8px;

    h2 {
      ${H2}
    }

    form {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 24px;

      .label {
        color: #06a6ff;
        font-feature-settings: "clig" off, "liga" off;
        font-family: "Manrope";
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 16px; /* 133.333% */
        color: #06a6ff;
        text-align: left;
      }

      .input-grp {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
      }

      input {
        width: 100%;
        border: none;
        border-bottom: 1px solid #06a6ff;
        padding: 8px 0;
        font-size: 16px;
        &:focus {
          outline: none;
        }
      }

      .terms {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 8px;
        cursor: pointer;
      }
    }
  }
`;

export const QR = styled.img`
  height: 184px;
  width: 184px;
  margin: auto;
`;
