import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { StyledMain } from "../../styled/StyledMain";
import { MainContainer } from "../../styled/StyledMainContainer";

import { StyledContainer } from "./styled/Container";
import { SearchInput, Table, Th, Td, DownloadContainer } from "./styled/Table";
import {
  getPassengersAction,
  importPassengersAction,
} from "../../features/campaign-manager/coachTrackerSlice";
import { convertArrayToCSV, downloadCSV } from "../../utils/utils";
import downloadIcon from "./img/download.svg";

import CoachTrackerProtectedRoute from "../../components/CoachTrackerProtectedRoute";
import Spinner from "../../components/Spinner";
import usePrevious from "../../hooks/usePrevious";
import { decodeJwt } from "../../utils/cryptography";
import NavBarComponent from "../campaign-manager/components/NavBar";

const CoachTracker = () => {
  const dispatch = useDispatch();

  const {
    passengers,
    gettingPassengers,
    errorGettingPassengers,
    importingPassengers,
    errorImportingPassengers,
  } = useSelector((state) => state.coachTracker);

  const prevImportingPassengers = usePrevious(importingPassengers);

  useEffect(() => {
    const storedTokenDetails = localStorage.getItem("token");

    if (storedTokenDetails) {
      const tokenDetails = JSON.parse(storedTokenDetails);
      setIsAdmin(
        decodeJwt(tokenDetails.access_token).authorities.includes(
          "ROLE_CAMPAIGN_MANAGER"
        )
      );
    }

    dispatch(getPassengersAction());
  }, [dispatch]);

  useEffect(() => {
    if (prevImportingPassengers && !errorImportingPassengers) {
      setFile(null);
      alert("Successfully imported passengers");
    } else if (prevImportingPassengers && errorImportingPassengers) {
      setFile(null);
      alert("Error! Failed to import passengers");
    }
  }, [errorImportingPassengers, prevImportingPassengers]);

  const [searchTerms, setSearchTerms] = useState({
    serialNumber: "",
    firstName: "",
    lastName: "",
    outgoingCoach: "",
    returningCoach: "",
  });
  const [file, setFile] = useState();
  const [array, setArray] = useState([]);
  const [isAdmin, setIsAdmin] = useState(false);

  const handleSearch = (e) => {
    const { name, value } = e.target;
    setSearchTerms((prev) => ({ ...prev, [name]: value }));
  };

  const filteredData = passengers.filter((passenger) => {
    const { serialNumber, firstName, lastName, outgoingCoach, returningCoach } = searchTerms;

    const match = (field, term) => {
      if (!term) return true;
      field = field?.toLowerCase() || "";
      term = term.toLowerCase();
      return field.includes(term);
    };

    return (
      match(passenger.serialNumber, serialNumber) &&
      match(passenger.personalDetails?.firstName, firstName) &&
      match(passenger.personalDetails?.lastName, lastName) &&
      match(passenger.coachTracker?.outgoingCoach, outgoingCoach) &&
      match(passenger.coachTracker?.returningCoach, returningCoach)
    );
  });

  const handleExport = (data, filename) => {
    const csvContent = convertArrayToCSV(data);
    downloadCSV(csvContent, filename);
  };

  const fileReader = new FileReader();

  const handleOnChange = (e) => {
    setFile(e.target.files[0]);
  };

  const csvFileToArray = (string) => {
    string = string.replace(/\r\n/g, "\n").replace(/\r/g, "\n");

    const csvHeader = string.slice(0, string.indexOf("\n")).split(",");
    const csvRows = string.slice(string.indexOf("\n") + 1).split("\n");

    const array = csvRows.map((i) => {
      const values = i.split(",");
      const obj = csvHeader.reduce((object, header, index) => {
        object[header] = values[index];
        return object;
      }, {});
      return obj;
    });

    if (
      csvHeader.length === 3 &&
      csvHeader.includes("serialNumber") &&
      csvHeader.includes("firstName") &&
      csvHeader.includes("lastName")
    ) {
      dispatch(importPassengersAction(array));
    }

    setArray(array);
  };

  const handleOnSubmit = (e) => {
    e.preventDefault();

    if (file) {
      fileReader.onload = function (event) {
        const text = event.target.result;
        csvFileToArray(text);
      };

      fileReader.readAsText(file);
    }
  };

  if (gettingPassengers || importingPassengers) return <Spinner />;

  return (
    <StyledMain grayBg>
      <CoachTrackerProtectedRoute />
      <NavBarComponent />
      <MainContainer>
        <StyledContainer>
          <h2>Coach Tracker</h2>
          <div>
            <DownloadContainer>
              <p>{filteredData.length} passenger(s) found</p>
              <img
                src={downloadIcon}
                alt="download"
                onClick={() =>
                  handleExport(
                    filteredData.map((device) => ({
                      serialNumber: device.serialNumber || "",
                      firstName: device.personalDetails?.firstName || "",
                      lastName: device.personalDetails?.lastName || "",
                      outgoingCoach: device.coachTracker?.outgoingCoach || "",
                      returningCoach: device.coachTracker?.returningCoach || "",
                    })),
                    "passengers.csv"
                  )
                }
              />
            </DownloadContainer>
            {isAdmin && (
              <DownloadContainer>
                <p>Import Passengers</p>
                <form>
                  <input
                    type={"file"}
                    id={"csvFileInput"}
                    accept={".csv"}
                    onChange={handleOnChange}
                  />

                  <button
                    onClick={(e) => {
                      handleOnSubmit(e);
                    }}
                  >
                    Import Passengers
                  </button>
                </form>
              </DownloadContainer>
            )}
            <Table>
              <thead>
                <tr>
                  <Th>Serial Number</Th>
                  <Th>First Name</Th>
                  <Th>Last Name</Th>
                  <Th>Outgoing Coach</Th>
                  <Th>Returning Coach</Th>
                </tr>
                <tr>
                  <Td>
                    <SearchInput
                      type="text"
                      name="serialNumber"
                      placeholder="Search Serial Number"
                      value={searchTerms.serialNumber}
                      onChange={handleSearch}
                    />
                  </Td>
                  <Td>
                    <SearchInput
                      type="text"
                      name="firstName"
                      placeholder="Search First Name"
                      value={searchTerms.firstName}
                      onChange={handleSearch}
                    />
                  </Td>
                  <Td>
                    <SearchInput
                      type="text"
                      name="lastName"
                      placeholder="Search Last Name"
                      value={searchTerms.lastName}
                      onChange={handleSearch}
                    />
                  </Td>
                  <Td>
                    <SearchInput
                      type="text"
                      name="outgoingCoach"
                      placeholder="Search Outgoing"
                      value={searchTerms.outgoingCoach}
                      onChange={handleSearch}
                    />
                  </Td>
                  <Td>
                    <SearchInput
                      type="text"
                      name="returningCoach"
                      placeholder="Search Returning"
                      value={searchTerms.returningCoach}
                      onChange={handleSearch}
                    />
                  </Td>
                </tr>
              </thead>
              <tbody>
                {filteredData.map((item) => (
                  <tr key={item.serialNumber}>
                    <Td>{item.serialNumber}</Td>
                    <Td>{item.personalDetails?.firstName}</Td>
                    <Td>{item.personalDetails?.lastName}</Td>
                    <Td>{item.coachTracker?.outgoingCoach}</Td>
                    <Td>{item.coachTracker?.returningCoach}</Td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        </StyledContainer>
      </MainContainer>
    </StyledMain>
  );
};

export default CoachTracker;
