import styled from "styled-components";

export const theme = {
  colors: {
    backgroundOptions: "#f9f9fa",
    background: "#ffffff",
    divider: "#E0E0E0",
    highEmphasis: "#202020",
    medEmphasis: "#626262",
    surface: "#ffffff",
    primary: "#F8F9F9"
  }
};

export const fonts = {
  H1: {
    fontFamily: "Ubuntu",
    fontSize: "32px",
    fontWeight: 400,
    lineHeight: "40px",
    color: theme.colors.highEmphasis
  },
  H2: {
    fontFamily: "Ubuntu",
    fontSize: "28px",
    fontWeight: 400,
    lineHeight: "36px",
    color: theme.colors.highEmphasis
  },

  B1: {
    fontFamily: "Ubuntu",
    fontSize: "16px",
    lineHeight: "24px",
    fontWeight: 700,
    color: theme.colors.highEmphasis
  },
  B2: {
    fontFamily: "Ubuntu",
    fontSize: "16px",
    lineHeight: "24px",
    fontWeight: 400,
    color: theme.colors.medEmphasis
  }
};

export const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 480px;
  margin: auto;
  min-height: 100vh;
  background-color: ${theme.colors.background};
`;

export const NavBar = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid ${theme.colors.divider};
  padding: 8px 16px;
  .logo {
    height: 40px;
  }
  .balance-container {
    display: flex;
    gap: 8px;
  }
`;

export const H1 = styled.h1`
  ${fonts.H1}
`;

export const H2 = styled.h2`
  ${fonts.H2}
`;

export const B1 = styled.p`
  ${fonts.B1}
`;

export const B2 = styled.p`
  ${fonts.B2}
`;

export const Footer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 32px 16px 64px;
  background-color: ${theme.colors.primary};

  ${B1}, ${B2} {
    color: ${theme.colors.highEmphasis};
  }

  ${H2} {
    font-size: 24px;
  }

  a {
    text-decoration: underline;
    color: ${theme.colors.surface};
  }
  .powered-by {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    img {
      height: 24px;
    }
  }
`;
