import styled from "styled-components";

const COLORS = {
  medEmphasis: "#626262",
  highEmphasis: "#202020",
  divider: "#d8d8d8",
  surface: "#ffffff",
  background: "#f5f5f6",
  onPrimary: "#ffffff"
};

export const MainContainer = styled.section`
  padding-top: 16px;
  margin: auto;
  width: 100%;
  max-width: 480px;
  min-height: 100vh;
  background: ${COLORS.background};

  h2,
  p {
    font-family: "Manrope", sans-serif;
    font-style: normal;
    color: ${COLORS.highEmphasis};
  }

  span {
    font-family: "Manrope", sans-serif;
  }

  p {
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0.25px;
  }
`;

export const Nav = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0 16px 16px;

  img {
    width: 40px;
    height: 40px;
    object-fit: contain;
    &:last-of-type {
      cursor: pointer;
    }
  }
`;

export const NameSection = styled.div`
  max-width: 80%;
  text-align: center;
  margin: auto;
  h2 {
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
  }
`;

export const Photo = styled.div`
  overflow: hidden;
  margin: auto;

  img {
    border-radius: 8px;
    width: 120px;
    height: 120px;
    object-fit: cover;
  }
`;

export const DetailsContainer = styled.ul`
  position: relative;
  display: grid;
  padding: 16px 0;
  grid-template-columns: 1fr;
  border: 1px solid ${COLORS.divider};
  margin: 0 16px;
  background-color: ${COLORS.surface};
  border-radius: 12px;
  margin-bottom: ${({ hasPhoto }) => (hasPhoto ? "32px" : "64px")};
`;

export const Item = styled.li`
  border-bottom: 1px solid ${COLORS.divider};
  margin-left: 16px;
  padding: 16px 16px 16px 0;
  list-style: none;
  display: flex;
  justify-content: space-between;

  .address-lines {
    text-align: end;
    span {
      display: block;
    }
  }

  p {
    color: ${COLORS.medEmphasis};
  }
`;

export const SocialIcons = styled.div`
  display: flex;
  gap: 16px;
  img {
    width: 24px;
    height: 24px;
  }
`;

export const QR = styled.img`
  height: 184px;
  width: 184px;
  margin: auto;
`;

export const ErrorMessage = styled.div`
  color: red;
  margin: 64px auto;
  padding: 0 32px;
  text-align: center;
`;
