import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

import translationEN from "./en/translation.json";
import translationDE from "./de/translation.json";
import faqsEN from "./en/faqs.json";

export const defaultNS = "main";

export const resources = {
  en: {
    main: translationEN,
    faqs: faqsEN
  },
  de: {
    main: translationDE
  }
};

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    compatibilityJSON: "v3",
    ns: ["main", "common"],
    fallbackLng: "en",
    resources,
    interpolation: {
      escapeValue: false
    }
  });

export default i18n;
