import React from "react";
import { MasterCardDemoFonts } from "../mastercard-demo/styles/MasterCardDemoStyles";
import { useNavigate } from "react-router-dom";
import {
  Footer,
  HowToDonate,
  MainContainer,
  PitchesIntro
} from "./styles/Uefa";

import digiseqLogo from "./img/digiseq-logo.png";
import paymeSwissLogo from "./img/payme-swiss-logo.png";
import pitchesOfHopeBanner from "./img/pitches-of-hope-banner.webp";
import donationsIcon from "./img/donations.png";
import backIcon from "./img/pitches-back-icon.svg";
import kiosksIcon from "./img/kiosks.png";
import footballGameIcon from "./img/football-game.png";
import terminalsIcon from "./img/terminals.png";

const UefaPitchesOfHope = () => {
  const navigate = useNavigate();

  return (
    <>
      <MasterCardDemoFonts />
      <MainContainer>
        <div className="pitches-banner">
          <img
            className="banner-img"
            src={pitchesOfHopeBanner}
            alt="pitches of hope banner"
          />
          <img
            className="back-icon"
            src={backIcon}
            alt="back icon"
            onClick={() => navigate(-1)}
          />
        </div>

        <PitchesIntro>
          <img src={donationsIcon} alt="donations icon" />
          <h2>Pitches of Hope</h2>
          <p>
            By supporting the ‘Pitches of Hope‘ initiative you will help build
            new football pitches together with the UEFA Foundation and Save the
            Children in the region of Turkey affected by the recent earthquake.
          </p>
        </PitchesIntro>
        <p className="can-donate">You can support tonight via:</p>
        <HowToDonate>
          <img src={terminalsIcon} alt="donate via terminals" />
          <div className="details">
            <p className="title">“Tap to Donate” Terminals</p>
            <p>Direct donation via your preloaded key fob or card.</p>
          </div>
        </HowToDonate>
        <HowToDonate>
          <img src={kiosksIcon} alt="donate via kiosks" />
          <div className="details">
            <p className="title">Digital Kiosks</p>
            <p>
              Mastercard will donate 5 EUR on your behalf for each transaction
              you make at the kiosk.
            </p>
          </div>
        </HowToDonate>
        <HowToDonate>
          <img src={footballGameIcon} alt="donate via the football game" />
          <div className="details">
            <p className="title">Football Game</p>
            <p>
              Mastercard will donate 10 EUR on your behalf for each penalty kick
              you stop.
            </p>
          </div>
        </HowToDonate>

        <Footer>
          <p>Powered by</p>
          <img
            src={digiseqLogo}
            alt="digiseq-logo"
            onClick={() => window.open("https://www.digiseq.co.uk", "_blank")}
          />
          <p>Payment services provided by</p>
          <img
            src={paymeSwissLogo}
            alt="payme-swiss-logo"
            onClick={() => window.open("https://paymeswiss.com/", "_blank")}
          />
        </Footer>
      </MainContainer>
    </>
  );
};

export default UefaPitchesOfHope;
