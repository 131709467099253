import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { deliverPrizesAction } from "../../features/campaign-manager/scavengerHuntSlice";
import usePrevious from "../../hooks/usePrevious";
import Spinner from "../../components/Spinner";
import NavBarComponent from "./components/NavBar";
import ProtectedRoute from "../../components/ProtectedRoute";
import { Button, TextArea } from "./components/styled/SharedStyles";
import { SectionContainer } from "./styled/ScavengerHunt";
import { ErrorMsg, MainContainer } from "./styled/SharedStyles";

const ScavengerDeliverPrizes = () => {
  const dispatch = useDispatch();

  const { deliveringPrizes, errorDeliveringPrizes } = useSelector(
    (state) => state.scavengerHunt
  );

  const [serials, setSerials] = useState("");

  const prevDeliveringPrizes = usePrevious(deliveringPrizes);

  useEffect(() => {
    if (prevDeliveringPrizes && !errorDeliveringPrizes) {
      alert(
        "Prizes successfully delivered! Please check the results after 15min."
      );
    }
  }, [errorDeliveringPrizes, prevDeliveringPrizes]);

  const handleDeliverPrizes = () => {
    const parsedSerials = serials.split("\n").filter((e) => e !== "");
    dispatch(deliverPrizesAction(parsedSerials));
  };

  return (
    <MainContainer>
      <ProtectedRoute />
      <NavBarComponent hasBackArrow={true} title={"Deliver Prizes"} />
      <SectionContainer>
        <TextArea
          rows="20"
          placeholder="paste or type devices serials (one line each)"
          value={serials}
          onChange={(ev) => {
            setSerials(ev.target.value);
          }}
        />
        <Button
          disabled={!serials || deliveringPrizes}
          onClick={() => handleDeliverPrizes()}
        >
          Deliver Prizes
        </Button>
      </SectionContainer>

      {deliveringPrizes && <Spinner />}

      {errorDeliveringPrizes && (
        <ErrorMsg>
          {errorDeliveringPrizes ||
            "Oh no, we have an error, please refresh page or try again later"}
        </ErrorMsg>
      )}
    </MainContainer>
  );
};

export default ScavengerDeliverPrizes;
