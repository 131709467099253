import styled from "styled-components";

import bgImg from "../img/landing-bg.png";
import mixins from "./mixins";

export const MainContainer = styled.section`
  position: relative;
  background: url(${bgImg}) no-repeat center center fixed;
  -webkit-background-size: contain;
  -moz-background-size: contain;
  -o-background-size: contain;
  background-size: contain;
  max-width: 480px;
  width: 100%;
  min-height: 100vh;
  margin: auto;
  background-color: #e7e8f1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;

  *:not(input, textarea) {
    -moz-user-select: none; /* firefox */
    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* IE*/
    user-select: none; /* Standard syntax */
  }
`;

export const Welcome = styled.div`
  background-color: white;
  margin: 0 16px 56px;
  padding: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  img {
    width: 70.44px;
    height: 40px;
    margin-bottom: 16px;
  }

  h1 {
    ${mixins.H1}
    margin-bottom: 8px;
  }
  p {
    ${mixins.B1}
    color: #7a8799;
    margin-bottom: 24px;
  }
`;
