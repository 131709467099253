import styled, { createGlobalStyle } from "styled-components";

import sfProDisplayFontRegular from "../fonts/SFPRODISPLAYREGULAR.OTF";
import sfProDisplayFontBold from "../fonts/SFPRODISPLAYBOLD.OTF";
import bgImage from "../img/bg-muchbetter.svg";

export const MuchBetterFonts = createGlobalStyle`
    @font-face {
        font-family: 'SF Pro Display';
        src: local('SF Pro Display'),
        url(${sfProDisplayFontRegular}) format('opentype');
        font-weight: 400;
        font-style: normal;
    }

    @font-face {
        font-family: 'SF Pro Display';
        src: local('SF Pro Display'),
        url(${sfProDisplayFontRegular}) format('opentype');
        font-weight: 600;
        font-style: normal;
    }

    @font-face {
        font-family: 'SF Pro Display';
        src: local('SF Pro Display'),
        url(${sfProDisplayFontBold}) format('opentype');
        font-weight: 700;
        font-style: normal;
    }
`;

export const theme = {
  colors: {
    error: "#D92B16",
    background: "#171717",
    divider: "#E0E0E0",
    highEmphasis: "#202020",
    medEmphasis: "#626262",
    surface: "#ffffff",
    primary: "#0D72FF"
  }
};

export const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-image: url(${bgImage});
  gap: 16px;
  width: 100%;
  max-width: 480px;
  margin: auto;
  min-height: 100vh;
  background-color: ${theme.colors.background};
  padding: 40px 64px 64px;

  .logo {
    width: 217px;
    height: 47px;
  }

  .splash {
    width: 188px;
    height: 164px;
  }

  h1 {
    font-family: "SF Pro Display";
    font-size: 25px;
    font-style: normal;
    text-align: center;
    font-weight: 700;
    line-height: 22px; /* 88% */
    color: ${theme.colors.surface};
  }

  p,
  a {
    font-feature-settings: "clig" off, "liga" off;
    font-family: "SF Pro Display";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px; /* 157.143% */
    color: ${theme.colors.surface};
    text-align: center;
  }

  .label {
    text-align: left;
    align-self: flex-start;
    margin-bottom: -4px;
  }

  .value {
    background-color: ${theme.colors.surface};
    width: 100%;
    height: 39px;
    border-radius: 6px;
    padding: 8px;
    p {
      color: ${theme.colors.medEmphasis};
    }
  }

  .footer,
  a {
    font-size: 12px;
  }
`;
