import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { composeErrorMessage } from "../../utils/utils";
import ascotService from "./ascotService";

const initialState = {
  ascotDevices: null,
  groups: null,
  group: null,
  device: null,
  deviceRegistered: false,
  isError: false,
  isSuccess: false,
  isLoading: false,
  locationUpdated: false,
  message: ""
};

// get public ascotDevices
export const getAscotDevices = createAsyncThunk(
  "ascotDevices/getAllAscotDevices",
  async (_, thunkAPI) => {
    try {
      const response = await ascotService.getAscotDevices();

      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(composeErrorMessage(error));
    }
  }
);

export const getAscotDevice = createAsyncThunk(
  "ascotDevices/getAscotDevice",
  async (serial, thunkAPI) => {
    try {
      return await ascotService.getAscotDevice(serial);
    } catch (error) {
      return thunkAPI.rejectWithValue(composeErrorMessage(error));
    }
  }
);

export const registerAscotDevice = createAsyncThunk(
  "ascotDevices/registerAscotDevice",
  async (payload, thunkAPI) => {
    try {
      return await ascotService.registerAscotDevice(payload);
    } catch (error) {
      return thunkAPI.rejectWithValue(composeErrorMessage(error));
    }
  }
);

export const updateLocation = createAsyncThunk(
  "ascotDevices/updateLocation",
  async (payload, thunkAPI) => {
    try {
      return await ascotService.updateLocation(payload);
    } catch (error) {
      return thunkAPI.rejectWithValue(composeErrorMessage(error));
    }
  }
);

export const getGroup = createAsyncThunk(
  "ascotDevices/getGroup",
  async (name, thunkAPI) => {
    try {
      return await ascotService.getGroup(name);
    } catch (error) {
      return thunkAPI.rejectWithValue(composeErrorMessage(error));
    }
  }
);

export const getGroups = createAsyncThunk(
  "ascotDevices/getGroups",
  async (_, thunkAPI) => {
    try {
      return await ascotService.getGroups();
    } catch (error) {
      return thunkAPI.rejectWithValue(composeErrorMessage(error));
    }
  }
);

export const ascotSlice = createSlice({
  name: "ascot",
  initialState,
  reducers: {
    reset: () => initialState,
    resetAscotDevice: (state) => {
      state.device = null;
    },
    resetAscotDevices: (state) => {
      state.devices = null;
    },
    resetGroup: (state) => {
      state.group = null;
    },
    resetGroups: (state) => {
      state.groups = null;
    },
    resetLocationUpdate: (state) => {
      state.locationUpdated = false;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAscotDevices.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getAscotDevices.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.ascotDevices = action.payload;
      })
      .addCase(getAscotDevices.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(getAscotDevice.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getAscotDevice.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.device = action.payload;
      })
      .addCase(getAscotDevice.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(registerAscotDevice.pending, (state) => {
        state.isLoading = true;
        state.deviceRegistered = false;
      })
      .addCase(registerAscotDevice.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.deviceRegistered = true;
        state.device = action.payload;
      })
      .addCase(registerAscotDevice.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(updateLocation.pending, (state) => {
        state.isLoading = true;
        state.locationUpdated = false;
      })
      .addCase(updateLocation.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.locationUpdated = true;
      })
      .addCase(updateLocation.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(getGroups.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getGroups.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.groups = action.payload;
      })
      .addCase(getGroups.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(getGroup.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getGroup.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.group = action.payload;
      })
      .addCase(getGroup.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      });
  }
});

export const {
  reset,
  resetAscotDevice,
  resetAscotDevices,
  resetGroup,
  resetGroups,
  resetLocationUpdate
} = ascotSlice.actions;
export default ascotSlice.reducer;
