import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import VCard from "vcard-creator";

import { getBalanceAndPin } from "../../features/balance-and-pin/balanceAndPinSlice";
import {
  addBusinessCard,
  updateBusinessCard
} from "../../features/pay360/pay360Slice";
import usePrevious from "../../hooks/usePrevious";
import { getBusinessCardAction } from "../../features/business-card/businessCardSlice";
import { StyledSpinner } from "../../components/styled/Spinner";
import ExpandingContainer from "./ExpandingContainer";

import {
  ActivationCode,
  ButtonWithShadow,
  Footer,
  InfoAlert,
  MainContainer,
  MuchbetterLogo,
  Navbar,
  OutlinedButton,
  QR,
  SectionContent,
  SolidButton
} from "./styles/Pay360";

import digiseqLogo from "./img/digiseq-logo.png";
import pay360Logo from "./img/pay360-logo.png";
import muchBetterLogo from "./img/muchbetter-logo.png";
import copyIcon from "./img/copy-icon.svg";
import CheckboxIcon from "./img/unchecked.svg";
import CheckboxCheckedIcon from "./img/checked.svg";
import appStoreLogo from "./img/app-store.svg";
import gplayLogo from "./img/play-store.svg";

import Spinner from "../../components/Spinner";
import { formattedBalance } from "../../features/balance-and-pin/utils";

const Pay360 = () => {
  const [termsAccepted, setTermsAccepted] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const [qrData, setQrData] = useState("");
  const [expandSection, setExpandSection] = useState({
    usc: false,
    digiseq: false
  });
  const [formData, setFormData] = useState({
    fullName: "",
    company: "",
    phoneNumber: "",
    email: ""
  });
  const [searchParams] = useSearchParams();
  const serialNumber = searchParams.get("serialNumber");
  const dispatch = useDispatch();

  const { balanceAndPin, isLoading } = useSelector(
    (state) => state.balanceAndPin
  );

  const {
    addingBusinessCard,
    addBusinessCardError,
    updatingBusinessCard,
    updateBusinessCardError
  } = useSelector((state) => state.pay360);

  const { businessCard, errorGettingBusinessCard, gettingBusinessCard } =
    useSelector((state) => state.businessCard);

  const prevAddingBusinessCard = usePrevious(addingBusinessCard);
  const prevUpdatingBusinessCard = usePrevious(updatingBusinessCard);

  useEffect(() => {
    if (
      (prevAddingBusinessCard && !addBusinessCardError) ||
      (prevUpdatingBusinessCard && !updateBusinessCardError)
    ) {
      setShowForm(false);
    }
  }, [
    addBusinessCardError,
    prevAddingBusinessCard,
    prevUpdatingBusinessCard,
    showForm,
    updateBusinessCardError
  ]);

  useEffect(() => {
    if (!businessCard && serialNumber) {
      dispatch(getBusinessCardAction(serialNumber));
    }
  }, [businessCard, dispatch, serialNumber]);

  useEffect(() => {
    if (!balanceAndPin && serialNumber) {
      dispatch(
        getBalanceAndPin({
          serial: serialNumber,
          query: "?provider=much_better"
        })
      );
    }
  }, [balanceAndPin, serialNumber, dispatch]);

  useEffect(() => {
    if (businessCard && Object.keys(businessCard).length > 0) {
      setFormData({
        fullName: businessCard.name,
        company: businessCard.company,
        phoneNumber: businessCard.phone,
        email: businessCard.email
      });

      const vCard = new VCard();
      const nameParts = businessCard?.name.split(" ") || "";
      const firstName = nameParts.shift() || "";
      const lastName = nameParts.join(" ");
      vCard
        .addName(lastName, firstName, "", "", "")
        .addCompany(businessCard.company)
        .addEmail(businessCard.email)
        .addPhoneNumber(businessCard.phone, "WORK");

      setQrData(vCard.toString().replace(/\n/g, "%0A"));

      setTermsAccepted(true);
    }
  }, [businessCard]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleSubmit = () => {
    // Create FormData object
    const formDataToSend = new FormData();
    formDataToSend.append("name", formData.fullName);
    formDataToSend.append("company", formData.company);
    formDataToSend.append("phone", formData.phoneNumber);
    formDataToSend.append("email", formData.email);
    formDataToSend.append("serialNumber", serialNumber);
    formDataToSend.append("keepCurrentCampaign", true);

    for (const value of formDataToSend.values()) {
      console.log(value);
    }

    if (businessCard && Object.keys(businessCard).length > 0) {
      const updatedBusinessCard = {
        name: formData.fullName,
        company: formData.company,
        phone: formData.phoneNumber,
        email: formData.email
      };
      dispatch(
        updateBusinessCard({ businessCard: updatedBusinessCard, serialNumber })
      );
    } else {
      dispatch(addBusinessCard(formDataToSend));
    }
  };

  if (isLoading) return <Spinner />;

  return (
    <MainContainer>
      <Navbar>
        <img src={pay360Logo} alt="pay360" />
        <div className="balance">
          {balanceAndPin?.balanceDetails?.availableBalance ? (
            <p>
              {formattedBalance(
                balanceAndPin.balanceDetails.availableBalance,
                balanceAndPin?.balanceDetails.currency
              )}
            </p>
          ) : (
            <p>{"N/A"}</p>
          )}
        </div>
      </Navbar>
      <ExpandingContainer title={"Welcome!"}>
        <p>
          {
            "Welcome to PAY360 2024. Treat this keyfob as cash. It is pre-loaded for you to use as you like at any of the event’s Tap n Taste areas. Your keyfob will not work outside of the exhibition until the event is over."
          }
        </p>
        <p>
          {
            "The good news is, you can take this keyfob as a gift from us to use at any retailers once the event is over."
          }
        </p>
        <p>
          {
            "Any problems, please visit us at the information desk as you enter the exhibition."
          }
        </p>
      </ExpandingContainer>
      <ExpandingContainer title={"Business card"}>
        <p>
          {"Use your keyfob now and beyond PAY360 as a digital business card."}
        </p>
        {qrData && (
          <QR
            alt="qr"
            src={`https://chart.googleapis.com/chart?cht=qr&chs=200x200&chl=${qrData}`}
          />
        )}
        <ButtonWithShadow onClick={() => setShowForm(true)}>
          {businessCard ? "Edit your business card" : "Set up business card"}
        </ButtonWithShadow>
      </ExpandingContainer>
      <ExpandingContainer title={"TODAY'S SCHEDULE"}>
        <ButtonWithShadow
          onClick={() =>
            window.open("https://pay360event.com/agenda/", "_blank")
          }
        >
          {"Visit the Schedule"}
        </ButtonWithShadow>
      </ExpandingContainer>
      <ExpandingContainer title={"OUR CHARITY WORK"}>
        <p>
          Pay360 is supporting Teenage Cancer Trust{" "}
          <a
            href="https://www.teenagecancertrust.org/about-us"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://www.teenagecancertrust.org/about-us
          </a>{" "}
          by donating any left over balance to this worth cause. You do not have
          to hand your keyfob back to do so. The excess fund will simply be
          reverted back post PAY360.
        </p>
        <p>
          {"We will be in touch to let you know the total amount we raised."}
        </p>
      </ExpandingContainer>
      <ExpandingContainer secondary title={"DIGISEQ"}>
        <h2>
          {"Who Are "}
          <span>{"DIGISEQ"}</span>
          {" ?"}
        </h2>
        <p>
          {
            "DIGISEQ is an award-winning IoT service platform that securely provisions data into everyday wearables; enabling items, from rings to clothing, to deliver NFC payment, customer engagement functionality, access control and digital identity. We connect the ecosystem, serving as a centrepiece between banks, product creators, chip manufacturers and card bureaux, so that wearables can be simply and securely delivered into the hands of consumers."
          }
        </p>
        {!expandSection.digiseq && (
          <p
            className="show-more"
            onClick={() =>
              setExpandSection((sections) => ({
                ...sections,
                digiseq: !sections.digiseq
              }))
            }
          >
            show more
          </p>
        )}
        {expandSection.digiseq && (
          <>
            <p>
              <strong>Delivering the highest level of Security</strong>
            </p>
            <p>
              DIGISEQ’s RCOS™ over-the-air mobile personalisation and
              tokenisation service is leading the way in adopting the new SCP03
              standard, ensuring all provisioned payment data is fully protected
              to the highest available standards, while continuing to deliver
              the fastest self-provisioning service in the industry. SCP03
              protocol supports AES (Advanced Encryption Standard) based
              cryptography in lieu of TDEA (Triple Data Encryption Algorithm).
              The protocol protects bidirectional communication between the host
              and cards, mobile wallets and wearables.
            </p>
            <p>
              We also adhere to PSD2 directive for consumer initiated electronic
              payments, which mandates two factor authentication outside of the
              normal card present exemptions. With the support of online PIN, a
              transaction that exceeds the local contactless value or
              consecutive/cumulative transaction limit, will prompt the terminal
              to request the customer to enter the PIN (of the original card)
              therefore providing the exact same user experience as that of a
              card transaction.
            </p>
          </>
        )}
        {expandSection.digiseq && (
          <p
            className="show-more"
            onClick={() =>
              setExpandSection((sections) => ({
                ...sections,
                digiseq: !sections.digiseq
              }))
            }
          >
            show less
          </p>
        )}
      </ExpandingContainer>
      <ExpandingContainer secondary title={"UNIVERSAL SMART CARDS"}>
        <h2>
          {"Who Are "}
          <span>{"USC"}</span>
          {" ?"}
        </h2>
        <p>
          {
            "Universal leads the way in advancing the 'payment in wearables' market, providing top-tier solutions covering inlays and wearables. Our comprehensive services include provisioning, packaging, fulfilment, and shipping, ensuring a seamless experience for our clients."
          }
        </p>
        {!expandSection.usc && (
          <p
            className="show-more"
            onClick={() =>
              setExpandSection((sections) => ({
                ...sections,
                usc: !sections.usc
              }))
            }
          >
            show more
          </p>
        )}
        {expandSection.usc && (
          <>
            <p>
              For more information on our SmarTap® MasterCard Certified inlays,
              click{" "}
              <a
                href="https://www.usmartcards.co.uk/SmarTap"
                target="_blank"
                rel="noopener noreferrer"
              >
                here.
              </a>{" "}
              These inlays are available in various shapes and sizes to fit
              different form factors such as rings, key fobs, wristbands, tags,
              and watch straps. Engineered for optimal read range, strength, and
              versatility, we also maintain chip stock for quick and easy
              implementation of the Digiseq solution.
            </p>
            <p>
              Explore our RFWare range{" "}
              <a
                href="https://www.usmartcards.co.uk/RFWare"
                target="_blank"
                rel="noopener noreferrer"
              >
                here
              </a>{" "}
              for ready-to-use products powered by SmartTap if you need to get
              started quickly. These wearable products are offered in small
              quantities, enabling swift adoption by schemes or solutions.
              Crafted from sustainable materials like vegan leather, our
              selection includes key fobs, wristbands, and tags, such as our
              vegan leather Pay360 key fobs.
            </p>
            <p>
              With over twenty years of experience, our Technical Centre in
              Fareham provides comprehensive support services. This includes
              payment provisioning for the Digiseq ecosystem, fulfilment
              services including custom packaging and mailing, as well as full
              customization and branding options.
            </p>
          </>
        )}
        {expandSection.usc && (
          <p
            className="show-more"
            onClick={() =>
              setExpandSection((sections) => ({
                ...sections,
                usc: !sections.usc
              }))
            }
          >
            show less
          </p>
        )}
      </ExpandingContainer>
      {balanceAndPin?.activationCode && (
        <ExpandingContainer secondary title={"PAYMENT ACCOUNT"}>
          <SectionContent>
            <MuchbetterLogo src={muchBetterLogo} alt="much better" />
            <p>
              {
                "Want to continue using your keyring? Download MuchBetter and link it to your account!"
              }
            </p>
            <p>{"Activation code:"}</p>
            <ActivationCode>
              <span>{balanceAndPin.activationCode}</span>
              <img
                src={copyIcon}
                alt="copy"
                onClick={() =>
                  navigator.clipboard.writeText(balanceAndPin.activationCode)
                }
              />
            </ActivationCode>
            <SolidButton
              secondary
              onClick={() =>
                window.open(
                  "https://muchbetter.com/pay360-wearables-activation",
                  "_blank"
                )
              }
            >
              {"Find out more"}
            </SolidButton>
          </SectionContent>
        </ExpandingContainer>
      )}
      <ExpandingContainer secondary title={"MOBILE APP"}>
        <p>
          {
            "If you want to continue using business card and payments after the Pay360 event, you can download our mobile application Manage-Mii and link your bank to it!"
          }
        </p>
        <div className="stores">
          <img
            src={appStoreLogo}
            alt="app store logo"
            onClick={() =>
              window.open(
                "https://apps.apple.com/gb/app/manage-mii/id6446908503",
                "_blank"
              )
            }
          />
          <img
            src={gplayLogo}
            alt="google play logo"
            onClick={() =>
              window.open(
                "https://play.google.com/store/apps/details?id=com.digiseq.managemii",
                "_blank"
              )
            }
          />
        </div>
        <p>
          Already have the app?{" "}
          <a href={"managemii://"} target="_blank" rel="noopener noreferrer">
            Open Manage-Mii
          </a>
        </p>
      </ExpandingContainer>
      <Footer>
        <p>Powered by</p>
        <img src={digiseqLogo} alt="digiseq" />
      </Footer>
      {showForm && (
        <InfoAlert>
          <div className="inner-container">
            <h2>{"Business Card"}</h2>

            <form>
              <p>
                {businessCard?.name
                  ? "Update your details"
                  : "Please provide your details"}
              </p>
              <div className="input-grp">
                <div className="label">Full name</div>
                <input
                  type="text"
                  name="fullName"
                  value={formData.fullName}
                  onChange={handleInputChange}
                />
              </div>
              <div className="input-grp">
                <div className="label">Company</div>
                <input
                  type="text"
                  name="company"
                  value={formData.company}
                  onChange={handleInputChange}
                />
              </div>
              <div className="input-grp">
                <div className="label">Phone number</div>
                <input
                  type="tel"
                  name="phoneNumber"
                  value={formData.phoneNumber}
                  onChange={handleInputChange}
                />
              </div>
              <div className="input-grp">
                <div className="label">Email</div>
                <input
                  type="email"
                  name="email"
                  value={formData.email}
                  onChange={handleInputChange}
                />
              </div>
              <div
                className="terms"
                onClick={() => setTermsAccepted((state) => !state)}
              >
                <img
                  src={!termsAccepted ? CheckboxIcon : CheckboxCheckedIcon}
                  alt="checkbox"
                />
                {"I accept the Terms and Conditions."}
              </div>
              <SolidButton
                disabled={!termsAccepted}
                onClick={() => handleSubmit()}
              >
                Save
              </SolidButton>
              <OutlinedButton onClick={() => setShowForm(false)}>
                Cancel
              </OutlinedButton>
              {(addingBusinessCard || updatingBusinessCard) && (
                <StyledSpinner
                  style={{ margin: "0 auto", width: 30, height: 30 }}
                />
              )}
            </form>
          </div>
        </InfoAlert>
      )}
    </MainContainer>
  );
};

export default Pay360;
