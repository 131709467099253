import { Container } from "./styled/AppStoreLinks";
import appStoreLogo from "../assets/app-store.svg";
import gplayLogo from "../assets/play-store.svg";

const AppStoreLinks = ({ appStoreUrl, playStoreUrl }) => {
  return (
    <Container>
      <img
        src={appStoreLogo}
        alt="app-store"
        onClick={() => window.open(appStoreUrl, "_blank")}
      />
      <img
        src={gplayLogo}
        alt="play-store"
        onClick={() => window.open(playStoreUrl, "_blank")}
      />
    </Container>
  );
};

export default AppStoreLinks;
