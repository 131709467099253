import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
import "react-phone-number-input/style.css";

import {
  getBalanceAndPin,
  hasPersonalDetailsAction,
  savePersonalDetailsAction
} from "../../features/balance-and-pin/balanceAndPinSlice";
import {
  AfterEvent,
  EventInformation,
  Footer,
  GreenManifesto,
  InfoAlert,
  MainContainer,
  MastercardIntro,
  NavBar,
  Wearable
} from "./styles/RolandGarros";

import usePrevious from "../../hooks/usePrevious";

import Spinner from "../../components/Spinner";
import ExpandingContainer from "./ExpandingContainer";

import { validateName } from "../../utils/utils";
import { MasterCardDemoFonts } from "../mastercard-demo/styles/MasterCardDemoStyles";

import rrMastercardLogo from "./img/rr-mastercard-logo.svg";
import wearableImg from "./img/wearable.png";
import chevronRight from "./img/chevron-right.svg";
import bbLogo from "./img/bb.svg";
import appStoreLogo from "./img/app-store.svg";
import gplayLogo from "./img/play-store.svg";
import mmLogo from "./img/mm-logo.svg";
import digiseqLogo from "./img/digiseq-logo.png";
import paymeSwissLogo from "./img/payme-swiss-logo.png";
import personIcon from "./img/person.svg";
import phoneIcon from "./img/phone.svg";
import errorIcon from "./img/errorIcon.svg";
import copyIcon from "./img/copy-icon.svg";
import checkTermsIcon from "./img/check-terms.svg";
import checkedTermsIcon from "./img/checked-terms.svg";
import mastcardAnim from "./img/mastercard.gif";

const RolandGarrosPromoReady = () => {
  const [searchParams] = useSearchParams();
  const serialNumber = searchParams.get("serialNumber");
  const dispatch = useDispatch();

  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [nameError, setNameError] = useState("");
  const [phoneNotValid, setPhoneNotValid] = useState("");
  const [showUserRegistration, setShowUserRegistration] = useState(false);
  const [termsAccepted, setTermsAccepted] = useState(false);
  const [termsError, setTermsError] = useState(false);
  const [readMore, setReadMore] = useState(false);

  const {
    balanceAndPin,
    isLoading,
    hasPersonalDetails,
    gettingPersonalDetails,
    errorGettingPersonalDetails,
    savingPersonalDetails,
    errorSavingPersonalDetails
  } = useSelector((state) => state.balanceAndPin);

  const prevSavingPersonalDetails = usePrevious(savingPersonalDetails);

  useEffect(() => {
    dispatch(hasPersonalDetailsAction(serialNumber));
  }, [dispatch, serialNumber]);

  useEffect(() => {
    if (
      prevSavingPersonalDetails &&
      !errorSavingPersonalDetails &&
      serialNumber
    ) {
      dispatch(hasPersonalDetailsAction(serialNumber));
    }
  }, [
    dispatch,
    errorSavingPersonalDetails,
    prevSavingPersonalDetails,
    serialNumber
  ]);

  useEffect(() => {
    if (!balanceAndPin && serialNumber) {
      dispatch(getBalanceAndPin({ serial: serialNumber }));
    }
  }, [balanceAndPin, serialNumber, dispatch, hasPersonalDetails]);

  const handleUserRegistration = () => {
    if (!name || !validateName(name)) {
      setNameError(true);
    } else if (!isValidPhoneNumber(phone)) {
      setPhoneNotValid(true);
    } else {
      setShowUserRegistration(false);
      dispatch(
        savePersonalDetailsAction({
          serial: serialNumber,
          details: { fullName: name, phoneNumber: phone }
        })
      );
    }
  };

  const formattedBalance = (balanceDetails) => {
    const { availableBalance, currency } = balanceDetails;
    let iconPrefixed = true;
    let icon = "";

    switch (currency) {
      case "GBP":
        icon = "£";
        break;
      case "EUR":
        icon = "€";
        break;
      case "USD":
        icon = "$";
        break;
      default:
        icon = "";
    }

    if (iconPrefixed) {
      return icon + availableBalance;
    } else {
      return availableBalance + icon;
    }
  };

  if (isLoading || gettingPersonalDetails || savingPersonalDetails)
    return <Spinner />;

  return (
    <>
      <MasterCardDemoFonts />
      <MainContainer>
        <NavBar>
          <img src={rrMastercardLogo} alt="roland garos logo" />
          {hasPersonalDetails && (
            <div>
              Balance:{" "}
              {balanceAndPin?.balanceDetails?.availableBalance
                ? formattedBalance(balanceAndPin?.balanceDetails)
                : "N/A"}
            </div>
          )}
          {hasPersonalDetails === false && (
            <div
              className="view-balance"
              onClick={() => setShowUserRegistration(true)}
            >
              View Balance
            </div>
          )}
        </NavBar>
        <MastercardIntro>
          <img src={mastcardAnim} alt="mastercard-anim" />
          <h2>Mastercard Welcomes You to Roland Garros!</h2>
          <p>
            Scroll down to see the different functionality of your payment
            device.
          </p>
        </MastercardIntro>
        <Wearable className="wearable">
          <img src={wearableImg} alt="wearable" />
        </Wearable>
        https://www.mastercard.com/global/en/vision/corp-responsibility/sustainability.html
        <GreenManifesto>
          <h2>
            Made from Appleskin. Vegan, Durable, Waterproof and Eco-friendly.
          </h2>
          <p>{`It all begins in South Tyrol, in Italy, with the cultivation of apples destined for the food industry.`}</p>
          {readMore && (
            <p>
              {`The cultivated apples are used to make juices, jams, and other food
            products. The apple wastes (i.e. apple cores and peels) are turned
            into powder, which is then used to substitute oil derivatives (such
            as PU) with 100% organic industrial wastes. This results in a vegan
            and sustainable material able to replace real leather. Every Kg of
            apple residuals used to substitute PU means 5.28Kg of CO2 saved.`}
            </p>
          )}
          <p
            className="collapsable"
            onClick={() => setReadMore(!readMore)}
          >{`Read ${readMore ? "less" : "more"}`}</p>
        </GreenManifesto>
        <ExpandingContainer title="Event information">
          <EventInformation>
            <p>Get all the information you need on the Roland Garros app</p>
            <div className="stores">
              <img
                src={appStoreLogo}
                alt="app store logo"
                onClick={() =>
                  window.open(
                    "https://apps.apple.com/us/app/roland-garros-official/id1371584793",
                    "_blank"
                  )
                }
              />
              <img
                src={gplayLogo}
                alt="google play logo"
                onClick={() =>
                  window.open(
                    "https://play.google.com/store/apps/details?id=com.netcosports.androlandgarros&hl=en&gl=US",
                    "_blank"
                  )
                }
              />
            </div>
          </EventInformation>
        </ExpandingContainer>
        <ExpandingContainer title="Payment Account">
          <AfterEvent>
            {balanceAndPin?.activationCode && (
              <>
                <img className="bb-logo" src={bbLogo} alt="bourgeois boheme" />
                <p>
                  Want to continue enjoying unrivaled payment experience?
                  Upgrade to Bourgeois Bohème wallet!
                </p>
                <p>Your wearable activation code</p>
                <div className="activation-code">
                  <h2>{balanceAndPin?.activationCode}</h2>
                  <img
                    src={copyIcon}
                    alt="copy icon"
                    onClick={() =>
                      navigator.clipboard.writeText(
                        balanceAndPin.activationCode
                      )
                    }
                  />
                </div>

                <div
                  className="button"
                  onClick={() =>
                    window.open(
                      `https://events.bobofin.com/?token=${balanceAndPin?.activationCode}`,
                      "_blank"
                    )
                  }
                >
                  {"Join Bourgeois Bohème"}{" "}
                  <img src={chevronRight} alt="chevron right" />
                </div>
                <div className="stores">
                  <img
                    src={appStoreLogo}
                    alt="app store logo"
                    onClick={() =>
                      window.open(
                        "https://apps.apple.com/us/app/bourgeois-boheme/id1574430730",
                        "_blank"
                      )
                    }
                  />
                  <img
                    src={gplayLogo}
                    alt="google play logo"
                    onClick={() =>
                      window.open(
                        "https://play.google.com/store/apps/details?id=com.paymeswiss.bobofin",
                        "_blank"
                      )
                    }
                  />
                </div>
              </>
            )}

            {searchParams.get("upgradable") === "true" && (
              <>
                {balanceAndPin?.activationCode && (
                  <div className="or-separator">
                    <span className="line"></span>
                    <p>or</p>
                    <span className="line"></span>
                  </div>
                )}
                <img src={mmLogo} alt="manage-mii" />
                <p>Download the Manage-Mii app to connect your payment card.</p>
                <div className="stores">
                  <img
                    src={appStoreLogo}
                    alt="app store logo"
                    onClick={() =>
                      window.open(
                        "https://apps.apple.com/gb/app/manage-mii/id6446908503",
                        "_blank"
                      )
                    }
                  />
                  <img
                    src={gplayLogo}
                    alt="google play logo"
                    onClick={() =>
                      window.open(
                        "https://play.google.com/store/apps/details?id=com.digiseq.managemii",
                        "_blank"
                      )
                    }
                  />
                </div>
              </>
            )}
          </AfterEvent>
        </ExpandingContainer>
        <Footer>
          <p>Powered by</p>
          <img
            src={digiseqLogo}
            alt="digiseq-logo"
            onClick={() => window.open("https://www.digiseq.co.uk", "_blank")}
          />
          <p>Payment services provided by</p>
          <img
            src={paymeSwissLogo}
            alt="payme-swiss-logo"
            onClick={() => window.open("https://paymeswiss.com/", "_blank")}
          />
        </Footer>
        {showUserRegistration && (
          <InfoAlert>
            <div className="inner-container">
              <h2>View Balance</h2>
              <p>
                Please provide your details. It will be requested only once.
              </p>

              <div className={`input-container ${nameError && "input-error"}`}>
                <img src={personIcon} alt="person icon" />
                <input
                  required
                  placeholder="Full name"
                  value={name}
                  onChange={(ev) => {
                    setNameError("");
                    setName(ev.target.value);
                  }}
                />
              </div>
              <div
                className={`input-container ${phoneNotValid && "input-error"}`}
              >
                <img src={phoneIcon} alt="phone icon" />

                <PhoneInput
                  required
                  maxLength="20"
                  defaultCountry="GB"
                  placeholder="Mobile Number"
                  value={phone}
                  onChange={(value) => {
                    setPhoneNotValid(false);
                    setPhone(value);
                  }}
                />
                {phoneNotValid && <img src={errorIcon} alt="error icon" />}
              </div>
              {(nameError || phoneNotValid) && (
                <div className="error-msg">Please input valid information</div>
              )}
              <div className="terms">
                <img
                  onClick={() => {
                    setTermsError(false);
                    setTermsAccepted(!termsAccepted);
                  }}
                  src={termsAccepted ? checkedTermsIcon : checkTermsIcon}
                  alt="accept-terms-icon"
                />
                <p>
                  I accept the{" "}
                  <a
                    href="https://s3.eu-west-2.amazonaws.com/www.digiseq.co.uk/Terms_and_Conditions_Bourgeois_Boheme.pdf"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Terms and Conditions.
                  </a>
                </p>
              </div>
              {termsError && (
                <div className="error-msg">
                  Please accept terms and conditions.
                </div>
              )}
              <div
                className="button"
                onClick={() => {
                  if (!termsAccepted) {
                    setTermsError(true);
                  } else {
                    handleUserRegistration();
                  }
                }}
              >
                View Balance
              </div>
              <div
                onClick={() => setShowUserRegistration(false)}
                className="button button-secondary"
              >
                Cancel
              </div>
            </div>
          </InfoAlert>
        )}
      </MainContainer>
    </>
  );
};

export default RolandGarrosPromoReady;
