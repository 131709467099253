import React from "react";
import { StyledDetailsItem } from "../styled/Campaign";

import chevronRight from "../img/chevron-right.svg";

const DetailsItem = ({ name, detail, onClick, isLast, lines, icon }) => {
  return (
    <StyledDetailsItem onClick={onClick} isLast={isLast} lines={lines}>
      <span>{name}</span>
      <div>
        {detail} {onClick && <img src={chevronRight} alt="arrow right" />}
        {icon && !onClick && <div>{icon}</div>}
      </div>
    </StyledDetailsItem>
  );
};

export default DetailsItem;
