import styled from "styled-components";

export const MainContainer = styled.div`
  padding: 16px 0;
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 480px;
  margin: auto;
  min-height: 100vh;
  background-color: #000;

  h1 {
    font-family: "Gotham Rounded Light";
    font-size: 36px;
    font-style: normal;
    font-weight: 400;
    line-height: 110%; /* 39.6px */
    text-transform: uppercase;
  }

  h2 {
    font-family: "Gotham Rounded Light";
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 110%; /* 39.6px */
    text-transform: uppercase;
  }
`;

export const Navbar = styled.nav`
  padding: 0 22px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  padding-bottom: 30px;

  img {
    height: 42px;
    object-fit: contain;
  }

  .balance {
    p {
      color: white;
      text-align: right;
      font-family: "Gotham Rounded Light";
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      text-transform: uppercase;
    }
  }
`;

export const Hero = styled.div`
  .img-container {
    margin: 0 22px;
    position: relative;
  }
  img {
    object-fit: contain;
    width: 100%;
    aspect-ratio: 346/394;
  }

  h1 {
    color: white;
    position: absolute;
    bottom: 20px;
    left: 20px;
    width: 80%;
  }
`;

export const BottomSection = styled.div`
  z-index: 1;
  width: 100%;
  height: ${({ height }) => `${height}px`};
  margin-top: ${({ margin }) => `-${margin}px`};
  background-color: ${({ color }) => color};
`;

export const Explore = styled.div`
  width: 100%;
  background-color: #081634;
  p {
    color: white;
    font-family: "Gotham Rounded Light";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 130%;
    margin-bottom: 20px;
  }
  .inner {
    padding: 40px 22px 0;
    display: flex;
    flex-direction: column;
    gap: 8px;

    h2 {
      color: white;
      margin: 32px 0 14px;
    }
  }

  .img-container {
    width: 100%;
    margin-top: 40px;
  }
  img {
    object-fit: contain;
    width: 100%;
    aspect-ratio: 346/241;
  }
  .video-container {
    width: 100%;
    margin-top: 40px;
  }

  video {
    object-fit: contain;
    width: 100%;
    aspect-ratio: 3840/2160;
  }
`;

export const Button = styled.div`
  display: flex;
  height: 59px;
  justify-content: center;
  align-items: center;
  align-self: stretch;
  border-radius: 37.5px;
  background-color: ${({ secondary }) => (secondary ? "#C87B35" : "#FFF")};
  text-align: center;
  font-family: "Gotham Rounded Medium";
  font-size: 20px;
  font-style: normal;
  line-height: 100%; /* 20px */
  text-transform: uppercase;
  color: ${({ secondary }) => (secondary ? "#fff" : "#000")};
  margin-bottom: ${({ secondary }) => (secondary ? 0 : "16px")};
`;

export const Schedule = styled.div`
  background-color: white;

  .header {
    padding: 40px 22px 22px;
    background-color: #c87b35;
    margin: 0 22px 0;
    p {
      color: white;
      font-family: "Gotham Rounded Medium";
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 110%; /* 17.6px */
      text-transform: uppercase;
    }
  }
`;

export const List = styled.ul`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 40px;
  padding: 40px 22px;
  background-color: #081634;
  margin: 0 22px;

  .buttons {
    margin-top: -40px;
    padding-top: 40px;
    display: flex;
    flex-direction: column;
    gap: 40px;
  }
`;

export const Item = styled.li`
  display: flex;
  flex-direction: column;
  gap: 8px;
  border-bottom: ${({ isLast }) => (isLast ? "none" : "1px solid #C87B35")};

  p {
    font-family: "Gotham Rounded Light";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 130%;
    color: white;
  }
  .time {
    color: #c87b35;
  }
`;

export const YourJourney = styled.div`
  width: 100%;

  img {
    width: 100%;
    aspect-ratio: 390/383;
    object-fit: contain;
  }

  video {
    object-fit: contain;
    width: 100%;
    aspect-ratio: 1280/720;
  }

  .intro,
  .connect,
  .activation {
    padding: 30px 22px;
    display: flex;
    flex-direction: column;
    justify-content: left;
    gap: 30px;
    p,
    h2,
    h3 {
      color: white;
      font-family: "Gotham Rounded Light";
    }

    p {
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 130%;
    }

    h2 {
      font-size: 32px;
      font-style: normal;
      font-weight: 400;
      line-height: 110%;
      text-transform: uppercase;
      text-align: center;
      margin-bottom: 80px;
    }

    a {
      color: inherit;
      text-decoration: inherit;
    }
  }

  .intro {
    padding: 40px 22px;
    background-color: #c87b35;
  }

  .activation {
    background-color: #081634;
  }
  .intro {
    margin-bottom: -80px;
  }
  .connect {
    background-color: #c87b35;
    margin-top: -5px;
    display: flex;
    flex-direction: column;

    h3 {
      font-family: "Gotham Rounded Medium";
      font-size: 20px;
      font-style: normal;
      font-weight: 500;
      line-height: 110%;
    }
    h3.small {
      font-size: 16px;
    }
    .fobs {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0 24px;
      img {
        object-fit: contain;
        aspect-ratio: 300/241;
      }
    }
  }
  .activation {
    padding: 40px 22px;
    text-align: center;
    .copy {
      height: 21px;
      width: 21px;
      cursor: pointer;
    }
    .code {
      display: flex;
      padding: 16.5px 0px;
      justify-content: center;
      align-items: center;
      gap: 16px;
      flex: 1 0 0;
      align-self: stretch;
      border-radius: 15px;
      border: 1px solid #fff;
      color: #fff;
      span {
        font-family: "Gotham Rounded Medium";
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        line-height: 110%;
      }
    }
  }
`;

export const Footer = styled.footer`
  padding: 40px 22px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
  a {
    color: white;
    font-family: "Gotham Rounded Light";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 130%;
    text-decoration: inherit;
    cursor: pointer;
  }
  img {
    width: 215px;
  }

  .socials {
    display: flex;
    flex-direction: row;
    gap: 22px;
    margin-bottom: 16px;
    img {
      width: 32px;
      height: 32px;
      cursor: pointer;
    }
  }
`;

export const Alert = styled.div`
  max-width: 480px;
  position: absolute;
  background: rgba(240, 240, 240, 0.9);
  height: 100%;
  width: 100%;
  text-align: center;

  .inner-container {
    position: fixed;
    max-width: 343px;
    width: 90%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 40px 22px;
    border-radius: 20px;
    background-color: #000;
    z-index: 9999;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 40px;

    p,
    h2,
    .subtitle,
    .info,
    .error {
      font-family: "Gotham Rounded Light";
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }

    p,
    h2 {
      color: white;
      text-align: left;
    }

    .title {
      font-family: "Gotham Rounded Medium";
      font-size: 24px;
      font-style: normal;
      line-height: normal;
    }

    .subtitle {
      color: white;
    }

    .info,
    a,
    p,
    input {
      color: #ffffff80;
    }

    .form,
    .inputs,
    button,
    .form-group {
      display: flex;
      flex-direction: column;
    }
    .form {
      gap: 30px;
    }

    .inputs {
      gap: 16px;
    }

    input {
      ::placeholder {
        font-family: "Gotham Rounded Light";
      }
      :focus {
        outline: none;
      }
      border-radius: 14px;
      border: 1px solid rgba(255, 255, 255, 0.3);
      padding: 22px;
      background-color: black;
    }

    button {
      justify-content: center;
      align-items: center;
      font-family: "Gotham Rounded Medium";
      font-size: 16px;
      font-style: normal;
      line-height: normal;
      text-transform: uppercase;
      padding: 22px;
      background-color: white;
      border-radius: 14px;
      border: 1px solid #fff;
      background: #fff;
      cursor: pointer;
    }
    .logos {
      width: 215px;
      margin: auto;
    }
    .terms {
      display: flex;
      flex-direction: row;
      gap: 16px;
      justify-content: center;
      align-items: center;
      img,
      a {
        cursor: pointer;
      }
      a {
        color: inherit;
        text-decoration: underline;
      }
    }

    .error {
      color: red;
      font-size: 14px;
      margin-top: 8px;
    }
  }
`;
