import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { composeErrorMessage } from "../../utils/utils";
import transactionsService from "./transactionsService";

const initialState = {
  fetchingTransactions: false,
  transactions: null,
  fetchingTransactionsError: "",
  fetchingCarbonImpact: false,
  carbonImpact: null,
  fetchingCarbonImpactError: ""
};

export const getTransactionsAction = createAsyncThunk(
  "transactions/getTransactions",
  async (serial, thunkAPI) => {
    try {
      const response = await transactionsService.getTransactions(serial);

      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(composeErrorMessage(error));
    }
  }
);

export const getCarbonImpactAction = createAsyncThunk(
  "transactions/getCarbonImpact",
  async (serial, thunkAPI) => {
    try {
      const response = await transactionsService.getCarbonImpact(serial);

      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(composeErrorMessage(error));
    }
  }
);

const transactionsSlice = createSlice({
  name: "transactions",
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(getTransactionsAction.pending, (state) => {
        state.fetchingTransactions = true;
        state.transactions = null;
        state.fetchingTransactionsError = "";
      })
      .addCase(getTransactionsAction.fulfilled, (state, action) => {
        state.fetchingTransactions = false;
        state.transactions = action.payload;
      })
      .addCase(getTransactionsAction.rejected, (state, action) => {
        state.fetchingTransactions = false;
        state.fetchingTransactionsError = action.payload;
      })
      .addCase(getCarbonImpactAction.pending, (state) => {
        state.fetchingCarbonImpact = true;
        state.carbonImpact = null;
        state.fetchingCarbonImpactError = "";
      })
      .addCase(getCarbonImpactAction.fulfilled, (state, action) => {
        state.fetchingCarbonImpact = false;
        state.carbonImpact = action.payload;
      })
      .addCase(getCarbonImpactAction.rejected, (state, action) => {
        state.fetchingCarbonImpact = false;
        state.fetchingCarbonImpactError = action.payload;
      });
  }
});

export default transactionsSlice.reducer;
