import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  getEvents,
  resetError,
} from "../../features/event-manager/eventManagerSlice";
import { ErrorMsg, MainContainer } from "./styled/SharedStyles";
import {
  EventItem,
  EventItemDetails,
  EventsContainer,
  Header,
  ListContainer,
} from "./styled/EventManager";

import Spinner from "../../components/Spinner";
import ProtectedRoute from "../../components/ProtectedRoute";
import NavBarComponent from "../campaign-manager/components/NavBar";
import addButtonIcon from "./img/add-button.svg";
import arrowRight from "./img/arrow-right.svg";
import eventDefaultIcon from "./img/campaign-default-icon.svg";

const EventManager = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { events, error, isLoading, isError, message } = useSelector(
    (state) => state.events
  );

  useEffect(() => {
    if (!events) {
      dispatch(getEvents());
    }
    return () => {
      dispatch(resetError());
    };
  }, [events, dispatch]);

  if (isLoading) return <Spinner />;

  return (
    <MainContainer>
      <ProtectedRoute />
      <NavBarComponent leftText={"Back"} hasBackArrow to="/das-admin" />

      <EventsContainer>
        <Header>
          <h1>Events</h1>
          {/*
          <img
            src={addButtonIcon}
            onClick={() => navigate(`/event-manager/new-event`)}
            alt="add event"
          />
  */}
        </Header>
        <ListContainer>
          {events?.length > 0 &&
            events.map((e, i) => (
              <EventItem
                key={e.id}
                onClick={() => navigate(`/event-manager/${e.id}`)}
              >
                <img src={eventDefaultIcon} alt="event icon" />
                <EventItemDetails>
                  <div>
                    <div>{e.name}</div>
                    <div className="event-code">{`Code: ${e.eventCode}`}</div>
                  </div>
                  <img src={arrowRight} alt="arrow right" />
                </EventItemDetails>
              </EventItem>
            ))}
        </ListContainer>

        {events?.length === 0 && <p>No events available</p>}
        {error?.message && <p style={{ color: "red" }}>{error.message}</p>}
      </EventsContainer>
      {isError && (
        <ErrorMsg>
          {message ||
            "Oh no, we have an error, please refresh page or try again later"}
        </ErrorMsg>
      )}
    </MainContainer>
  );
};

export default EventManager;
