import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import lostModeService from "./lostModeService";

import { composeErrorMessage } from "../../utils/utils";

const initialState = {
  fetchingDevice: false,
  device: null,
  fetchingDeviceError: ""
};

export const fetchDeviceAction = createAsyncThunk(
  "lostMode/getDevice",
  async (serial, thunkAPI) => {
    try {
      const response = await lostModeService.fetchDevice(serial);

      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(composeErrorMessage(error));
    }
  }
);

export const lostModeSlice = createSlice({
  name: "lostMode",
  initialState,
  reducers: {
    reset: () => initialState
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchDeviceAction.pending, (state) => {
        state.fetchingDevice = true;
        state.fetchingDeviceError = null;
      })
      .addCase(fetchDeviceAction.fulfilled, (state, action) => {
        state.fetchingDevice = false;
        state.device = action.payload;
      })
      .addCase(fetchDeviceAction.rejected, (state, action) => {
        state.fetchingDevice = false;
        state.fetchingDeviceError = action.payload;
      });
  }
});

export const { reset } = lostModeSlice.actions;
export default lostModeSlice.reducer;
