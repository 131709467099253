import styled, { createGlobalStyle } from "styled-components";

import AvertaPELight from "./fonts/AvertaPE-Light.woff";
import AvertaPEExtrabold from "./fonts/AvertaPE-Extrabold.woff";

export const HugoBossFonts = createGlobalStyle`
    @font-face {
        font-family: 'Averta PE';
        src: local('Averta PE'),
        url(${AvertaPELight}) format('woff');
        font-weight: 300;
        font-style: normal;
    }

    @font-face {
        font-family: 'Averta PE';
        src: local('Averta PE'),
        url(${AvertaPEExtrabold}) format('woff');
        font-weight: 800;
        font-style: normal;
    }
`;

export const theme = {
  colors: {
    highEmphasis: "#000000",
    surface: "#ffffff",
    medEmphasis: "#626262",
    backgroundOptions: "#f9f9fa",
    background: "#F6F6F6",
    secondaryBackground: "#f0f0f0",
    divider: "#CCCCCC",
    primary: "#0052B4",
    danger: "#D80027"
  }
};

const fonts = {
  H1: {
    fontFamily: "Averta PE",
    fontSize: "38px",
    fontStyle: "normal",
    fontWeight: 800,
    lineHeight: "40px",
    color: theme.colors.highEmphasis,
    textAlign: "center"
  },
  H2: {
    fontFamily: "Averta PE",
    fontSize: "30px",
    fontStyle: "normal",
    fontWeight: 800,
    lineHeight: "32px",
    color: theme.colors.highEmphasis,
    textAlign: "center"
  },
  H3: {
    fontFamily: "Averta PE",
    fontSize: "24px",
    fontStyle: "normal",
    fontWeight: 800,
    lineHeight: "26px",
    color: theme.colors.highEmphasis,
    textAlign: "center"
  },
  B1: {
    fontFamily: "Averta PE",
    fontSize: "20px",
    fontStyle: "normal",
    fontWeight: 300,
    lineHeight: "24px",
    color: theme.colors.highEmphasis
  },
  B2: {
    fontFamily: "Averta PE",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 300,
    lineHeight: "16.79px",
    color: theme.colors.medEmphasis
  },
  B3: {
    fontFamily: "Averta PE",
    fontSize: "11px",
    fontStyle: "normal",
    fontWeight: 300,
    lineHeight: "16.79px",
    letterSpacing: "0.4px",
    color: theme.colors.medEmphasis
  }
};

export const H1 = styled.h1`
  ${fonts.H1}
`;

export const H2 = styled.h2`
  ${fonts.H2}
`;

export const H3 = styled.h3`
  ${fonts.H3}
`;

export const B1 = styled.p`
  ${fonts.B1}
`;

export const B2 = styled.p`
  ${fonts.B2}
`;

export const B3 = styled.p`
  ${fonts.B3}
`;

export const MainContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 480px;
  margin: auto;
  background-color: ${theme.colors.surface};

  .welcome-title {
    margin: 16px;
    text-align: left;
    text-transform: uppercase;
  }
`;

export const InnerContainer = styled.main`
  padding: 0 16px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 16px;
  padding-top: 24px;
`;

export const NavBar = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  align-items: center;
  background-color: ${theme.colors.highEmphasis};
  padding: 16px;
  .logo {
    height: 19px;
    margin: auto;
  }
`;

export const LanguageSelector = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 4px;
  ${B2} {
    color: ${theme.colors.surface};
  }

  .lang-select {
    display: flex;
    flex-direction: row;
    gap: 8px;
    justify-content: flex-end;
    cursor: pointer;
  }

  .lang-option {
    position: absolute;
    top: 20px;
    right: 20px;
  }
`;

export const Hero = styled.div`
  background-image: url(${({ bg }) => bg});
  background-size: 100% auto;
  background-repeat: no-repeat;
  aspect-ratio: 450/510;
  display: flex;
  justify-content: center;
  align-items: center;

  .inner {
    padding-top: 64px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 16px;
  }

  ${H1}, ${B2} {
    color: white;
    font-weight: 800;
    max-width: 80%;
  }
`;

export const HeroTextContainer = styled.div`
  padding: 24px 16px;
  border-bottom: 1px solid ${theme.colors.divider};
  ${B1} {
    color: ${theme.colors.medEmphasis};
    text-align: center;
    margin-bottom: 16px;
  }

  .show-more {
    text-decoration: underline;
    font-weight: 800;
    cursor: pointer;
    margin: 0 8px;
  }
`;

export const ClaimBanner = styled.div`
  padding: 36px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 30px;
`;

export const Product = styled.div`
  padding: 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;

  .img-container {
    margin: 0 64px;
    width: 100%;
    height: 100%;
    img {
      width: 100%;
      height: 100%;
      max-height: 300px;
      object-fit: contain;
    }
  }
`;

export const ProductList = styled.ul`
  list-style: none;
  padding: 16px;
  display: flex;
  flex-direction: column;
`;

export const ProductItem = styled.li`
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 16px;

  border-bottom: ${({ isLast }) =>
    !isLast ? `1px solid ${theme.colors.divider}` : "none"};
  .item {
    display: flex;
    flex-grow: 1;
    flex-direction: row;
    justify-content: space-between;
    cursor: pointer;
  }

  ${B2} {
    margin-bottom: 8px;
  }
`;

export const Specs = styled.div`
  ul {
    display: flex;
    flex-direction: column;
  }

  li {
    padding: 16px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid ${theme.colors.surface};
    ${B2} {
      margin-right: 16px;
      margin-bottom: 0;
    }
    ${B1} {
      text-align: right;
    }
  }

  .certified {
    display: flex;
    flex-direction: row;
    gap: 8px;
  }

  ${B1} {
    font-size: 16px;
  }
`;

export const ServiceList = styled.div`
  background-color: ${theme.colors.background};
  padding: 48px 16px;
  display: flex;
  flex-direction: column;
  gap: 32px;
`;

export const ServiceItem = styled.div`
  background-image: url(${({ bgImg }) => bgImg});
  background-size: 100% auto;
  background-repeat: no-repeat;
  aspect-ratio: 369/120;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  ${H3} {
    color: ${theme.colors.surface};
    text-align: center;
    max-width: 80%;
  }
`;

export const EventInfo = styled.div`
  margin: 8px 0;
  padding: 0 8px;
  background-image: url(${({ bgImg }) => bgImg});
  background-size: 100% auto;
  background-repeat: no-repeat;
  aspect-ratio: 369/90;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  ${H3} {
    color: ${theme.colors.surface};
  }
`;

export const Schedule = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px;

  img {
    width: 160px;
    height: 41px;
  }

  ul {
    display: flex;
    flex-direction: column;
  }

  li {
    padding: 16px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    background-color: ${theme.colors.background};
    border-bottom: 1px solid ${theme.colors.surface};
  }
`;

export const Footer = styled.div`
  padding-top: 64px;
  background-color: ${theme.colors.background};
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;

  ${B2} {
    color: ${theme.colors.highEmphasis};
    font-weight: 800;
  }

  .socials {
    display: flex;
    flex-direction: row;
    gap: 16px;
    justify-content: space-between;
    margin-bottom: 8px;
  }

  .app-stores {
    display: flex;
    flex-direction: row;
    gap: 36px;
    margin-bottom: 24px;
  }

  .links {
    padding: 16px 16px 64px;
    width: 100%;
    background-color: ${theme.colors.secondaryBackground};
    display: flex;
    flex-direction: column;
    text-align: center;
    gap: 8px;

    ${B3} {
      cursor: pointer;
      &:hover {
        text-decoration: underline;
      }
    }

    .row {
      display: flex;
      flex-direction: row;
      gap: 24px;
      justify-content: center;
    }
    .copyright {
      color: #4a4a4a;
      font-weight: 800;
      cursor: initial;
      &:hover {
        text-decoration: none;
      }
    }
  }
`;

export const InfoAlert = styled.div`
  max-width: 480px;
  position: absolute;
  background: rgba(9, 10, 15, 0.64);
  height: 100%;
  width: 100%;
  text-align: center;

  .inner-container {
    position: fixed;
    max-width: 450px;
    width: 90%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 32px 24px;
    background-color: ${theme.colors.surface};
    z-index: 9999;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 16px;

    .close-button {
      width: 16px;
      height: 16px;
      margin-bottom: 16px;
      align-self: flex-end;
      cursor: pointer;
    }

    ${H2} {
      max-width: 80%;
      text-align: left;
      font-size: 28px;
      line-height: 36px;
    }
    ${B1} {
      text-align: left;
    }

    .error {
      color: ${theme.colors.danger};
    }
  }

  .text-left {
    text-align: left;
  }

  .ticket-activated {
    border: 1px solid #28d781;
    height: 46px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    color: #02f076;

    text-align: center;
    font-feature-settings: "clig" off, "liga" off;
    font-family: Inter;
    font-size: 15.951px;
    font-style: normal;
    font-weight: 700;
    line-height: 16.79px;
  }

  .ski-ticket {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  .ski-warning {
    display: flex;
    flex-direction: row;
  }

  .link {
    text-decoration: underline;
    font-weight: 800;
    cursor: pointer;
  }
`;

export const FormContainer = styled.form`
  .forgot-password {
    text-decoration: underline;
    margin-bottom: 24px;
    cursor: pointer;
  }

  .error {
    color: ${theme.colors.danger};
    margin: 8px auto;
  }
`;

export const Button = styled.button`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 46px;
  cursor: pointer;
  background-color: ${theme.colors.highEmphasis};
  border: none;

  ${fonts.B2};
  font-size: 15.951px;
  font-style: normal;
  font-weight: 800;
  line-height: 16.79px;
  color: ${theme.colors.surface};
`;

export const InputGroup = styled.div`
  width: 100%;
  display: flex;
  gap: 24px;
  margin-bottom: 24px;
  flex-direction: ${({ row }) => (row ? "row" : "column")};
`;

export const Input = styled.input`
  width: 100%;
  border: none;
  height: 40px;

  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  padding: 0px 12px 6px;
  background-color: ${theme.colors.background};
  border-bottom: 1px solid ${theme.colors.highEmphasis};

  &::placeholder {
    color: ${theme.colors.highEmphasis};
    font-family: Inter;
    font-weight: 500;
    line-height: 16.79px;
  }
  :focus {
    outline: none;
  }
`;

export const Transactions = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 1px;
`;

export const Transaction = styled.li`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background-color: ${theme.colors.background};
  padding: 16px;
`;
