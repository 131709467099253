import React from "react";
import { useMediaQuery } from "react-responsive";
import { Link } from "react-router-dom";
import Logo from "./Logo";
import {
  Container,
  StyledFooter,
  Widget,
  LogoWidget,
  FooterBottom,
  TermsSection,
  Contact
} from "./styled/StyledFooter";

import arrowRightIcon from "./images/arrow-right-icon.svg";
const Footer = () => {
  const isMin768 = useMediaQuery({ query: "(min-width: 768px)" });
  const isMin1440 = useMediaQuery({ query: "(min-width: 1440px)" });

  return (
    <StyledFooter>
      <Container>
        <LogoWidget>
          <Logo isfooter="true" />
        </LogoWidget>

        <Widget>
          <h4>Explore</h4>
          <Link to="/">
            <h4>Homepage</h4>
          </Link>
          <Link to="/products">
            <h4>Products</h4>
          </Link>
          <Link to="/brands">
            <h4>Brands</h4>
          </Link>
          <Link to="/">
            <h4>Latest news</h4>
          </Link>
        </Widget>
        <Widget>
          <h4>Follow</h4>
          <h4>
            <a
              href="https://twitter.com/digiseqUK"
              target="_blank"
              rel="noopener noreferrer"
            >
              Twitter
            </a>
          </h4>
          <h4>
            <a
              href="https://www.instagram.com/digiseq_ltd"
              target="_blank"
              rel="noopener noreferrer"
            >
              Instagram
            </a>
          </h4>
          <h4>
            <a
              href="https://www.linkedin.com/company/digiseq"
              target="_blank"
              rel="noopener noreferrer"
            >
              Linkedin
            </a>
          </h4>
          {isMin768 && !isMin1440 && (
            <Contact>
              <Link to="/contact">
                <b>Contact</b> <img src={arrowRightIcon} alt="arrow right" />
              </Link>
            </Contact>
          )}
        </Widget>

        {(isMin1440 || (!isMin768 && !isMin1440)) && (
          <Widget>
            <Contact>
              <Link to="/contact">
                <b>Contact</b> <img src={arrowRightIcon} alt="arrow right" />
              </Link>
            </Contact>
          </Widget>
        )}
      </Container>
      <FooterBottom>
        <h4>© Manage-Mii</h4>
        <TermsSection>
          <h4>
            <a
              href="https://s3.eu-west-2.amazonaws.com/www.digiseq.co.uk/privacy-manage-mii.pdf"
              target="_blank"
              rel="noopener noreferrer"
            >
              Privacy
            </a>
          </h4>

          <h4>
            <a
              href="https://s3.eu-west-2.amazonaws.com/www.digiseq.co.uk/ManageMiiTerms.html"
              target="_blank"
              rel="noopener noreferrer"
            >
              {`Terms & Conditions`}
            </a>
          </h4>
        </TermsSection>
      </FooterBottom>
    </StyledFooter>
  );
};

export default Footer;
