import styled from "styled-components";

export const theme = {
  colors: {
    backgroundOptions: "#f9f9fa",
    background: "#ffffff",
    divider: "#d8d8d9",
    highEmphasis: "#202020",
    medEmphasis: "#626262",
    surface: "#ffffff"
  }
};

export const fonts = {
  H2: {
    fontFamily: "soleil",
    fontSize: "28px",
    fontWeight: 700,
    lineHeight: "36px",
    color: theme.colors.highEmphasis
  },
  H3: {
    fontFamily: "soleil",
    fontSize: "24px",
    fontWeight: 600,
    lineHeight: "32px",
    color: theme.colors.highEmphasis
  },
  B1: {
    fontFamily: "soleil",
    fontSize: "16px",
    lineHeight: "24px",
    fontWeight: 700,
    color: theme.colors.highEmphasis
  },
  B2: {
    fontFamily: "soleil",
    fontSize: "16px",
    lineHeight: "24px",
    fontWeight: 400,
    color: theme.colors.medEmphasis
  },
  B3: {
    fontFamily: "soleil",
    fontSize: "14px",
    lineHeight: "24px",
    fontWeight: 400,
    color: theme.colors.highEmphasis
  }
};

export const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 480px;
  margin: auto;
  min-height: 100vh;
  background-color: ${theme.colors.background};

  .logo {
    height: 24px;
  }
`;

export const NavBar = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid ${theme.colors.divider};
  padding: 16px;

  .logo {
    height: 24px;
  }
  .balance-container {
    display: flex;
    gap: 8px;
  }
`;

export const H2 = styled.h2`
  ${fonts.H2}
`;

export const H3 = styled.h3`
  ${fonts.H3}
`;

export const B1 = styled.p`
  ${fonts.B1}
`;

export const B2 = styled.p`
  ${fonts.B2}
`;

export const B3 = styled.p`
  ${fonts.B3}
`;

export const Footer = styled.div`
  margin-top: 64px;
  align-items: center;
  border: 1px none;
  display: flex;
  flex-direction: column;
  gap: 8px;
  justify-content: center;
  min-height: 375px;
  padding: 24px 16px 64px;
  background-color: ${theme.colors.backgroundOptions};
`;
